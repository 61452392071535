import { SupportPage } from '@amaui/api-utils';
import AmauiSubscription from '@amaui/subscription';

import { BaseService } from './base';
import { IQuerySubscription, ISelectedSubscription } from 'other';

class SupportPageService extends BaseService<SupportPage> {
  public queryItems = new AmauiSubscription<IQuerySubscription>();
  public selectedItems = new AmauiSubscription<ISelectedSubscription>();

  public constructor() {
    super('supportPages');

    this.queryObjectsInit(this.queryItems);

    this.selectedObjectsInit(this.selectedItems);
  }

}

export default new SupportPageService();
