import React from 'react';
import { Helmet } from 'react-helmet';

import { cleanValue } from '@amaui/utils';
import { Form, Line, Type, useConfirm, useMainProgress, useSnackbars } from '@amaui/ui-react';
import { classNames, style, useAmauiTheme } from '@amaui/style-react';
import { Integration } from '@amaui/api-utils';

import { Button, useSubscription } from 'ui';
import { IntegrationService } from 'services';
import { IQuerySubscription, getErrorMessage, googleAPILoad } from 'other';

const useStyle = style(theme => ({
  root: {

  },

  ...theme.classes(theme)
}), { name: 'amaui-app-route-OrganizationSettingsIntegrations' });

const OrganizationSettingsIntegrations = React.forwardRef((props: any, ref: any) => {
  const {
    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const theme = useAmauiTheme();
  const confirm = useConfirm();
  const snackbars = useSnackbars();
  const mainProgress = useMainProgress();

  const queryIntegrations = useSubscription<IQuerySubscription>(IntegrationService.queryIntegrations);

  const [loading, setLoading] = React.useState<any>();

  const refs = {
    GoogleAuth: React.useRef<any>()
  };

  const initGoogle = React.useCallback(async () => {
    // Google 
    // api 
    await googleAPILoad({
      access_type: 'offline',
      scope: 'openid profile email https://www.googleapis.com/auth/calendar.events'
    });

    refs.GoogleAuth.current = (window as any).gapi.auth2.getAuthInstance();
  }, []);

  const init = React.useCallback(async () => {
    mainProgress.start();

    const result = await IntegrationService.queryIntegrations.value!.query();

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }

    mainProgress.done();
  }, []);

  React.useEffect(() => {
    // init google 
    initGoogle();

    // init 
    init();
  }, []);

  const onGoogleClick = React.useCallback(async (provider: string, app: string) => {
    const prompt = () => {
      return new Promise((resolve, reject) => {
        if (refs.GoogleAuth.current) refs.GoogleAuth.current.grantOfflineAccess({ prompt: 'consent' }).then(resolve).catch(reject);
        else {
          initGoogle();
        }
      });
    };

    try {
      const response: any = await prompt();

      return onAdd(provider, app, response.code);
    }
    catch (error) {
      console.log('onGoogleClick', error);

      snackbars.add({
        primary: 'Something went wrong',
        color: 'error'
      });
    }
  }, []);

  const onAdd = React.useCallback(async (provider: string, app: string, code: string) => {
    if (!code) {
      snackbars.add({
        primary: 'Something went wrong',
        color: 'error'
      });
    }

    setLoading(true);

    if (provider === 'google') {
      if (app === 'google-calendar') {
        const result = await IntegrationService.add({
          provider,
          app,
          value: {
            props: {
              code
            }
          }
        });

        if (result.status >= 400) {
          snackbars.add({
            primary: getErrorMessage(result),
            color: 'error'
          });
        }
        else {
          snackbars.add({
            primary: 'Integration added',
            color: 'success'
          });

          // refetch 
          IntegrationService.queryIntegrations.value!.refetch();
        }
      }
    }

    setLoading(false);
  }, []);

  const onRemove = React.useCallback(async (value: Integration) => {
    const integrationName = cleanValue(value.app, { capitalize: true });

    const confirmed = await confirm.open({
      name: `Removing ${integrationName} integration`,
      description: `If this integration is removed, some of the application features won't work.`
    });

    if (!confirmed) return;

    const result = await IntegrationService.remove(value.id);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Integration for ${integrationName} removed`
      });

      // refetch
      IntegrationService.queryIntegrations.value!.refetch();
    }
  }, []);

  const integrations = React.useMemo(() => {
    return [
      { provider: 'google', app: 'google-calendar', name: 'Google Calendar', description: `Used for creating new Google Calendar events with Google Meet automatically, when your online meeting reservations are approved or auto approved.` }
    ];
  }, []);

  const integrationsActive = React.useMemo(() => {
    const value: any = {};

    (queryIntegrations.response || []).forEach((item: any) => {
      value[`${item.provider}-${item.app}`] = item;
    });

    return value;
  }, [queryIntegrations.response]);

  return <>
    <Helmet>
      <title>Integrations</title>
      <link rel='icon' type='image/svg' sizes='32x32' href={`/assets/svg/logos/${theme.palette.light ? 'light' : 'dark'}/logo-amaui.svg`} />
      <link rel='icon' type='image/svg' sizes='16x16' href={`/assets/svg/logos/${theme.palette.light ? 'light' : 'dark'}/logo-amaui.svg`} />
      <meta name='theme-color' content={theme.palette.light ? (theme.palette.color.amaui as any).main : theme.palette.color.amaui[20]} />
    </Helmet>

    <Line
      ref={ref}

      gap={4}

      fullWidth

      className={classNames([
        className,
        classes.wrapper
      ])}

      {...other}

      style={{
        maxWidth: theme.maxWidth,

        ...other.style
      }}
    >
      <Form
        name='Integrations'

        description='Integrations with providers, required for some of the app features to work.'
      >
        <Line
          gap={1}

          fullWidth
        >
          {queryIntegrations?.loaded && integrations.map((item, index) => {
            const integration = integrationsActive[`${item.provider}-${item.app}`];

            return (
              <Line
                key={index}

                fullWidth
              >
                <Line
                  gap={1}

                  fullWidth
                >
                  <Type
                    version='t2'
                  >
                    {item.name}
                  </Type>

                  <Type
                    version='b2'
                  >
                    {item.description}
                  </Type>
                </Line>

                <Button
                  version={integration ? 'outlined' : 'filled'}

                  color={integration ? 'error' : 'success'}

                  size='small'

                  onClick={() => !integration ? onGoogleClick(item.provider, item.app) : onRemove(integration)}

                  loading={loading}
                >
                  {integration ? 'Remove' : 'Add'}
                </Button>
              </Line>
            );
          })}
        </Line>
      </Form>
    </Line>
  </>;
});

export default OrganizationSettingsIntegrations;
