import React from 'react';
import { Outlet } from 'react-router';

import { Line, WindowSplit, useLocation, useMediaQuery } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';

import { RailMain, Add, Header, SidebarMain, WidgetMain, useSubscription } from 'ui';
import { AppService, StorageService } from 'services';
import { CONSTS } from 'other';
import { IAdd } from 'ui/layout/Add';
import { IRailMain } from 'ui/layout/RailMain';

const useStyle = style(theme => ({
  root: {
    height: '100vh',
    overflow: 'hidden',

    '&.amaui-Surface-root': {
      background: 'transparent'
    },

    '& .amaui-WindowSplit-root': {
      width: '100%',
      maxWidth: theme.maxWidth,
      padding: '16px 0',
      overflow: 'hidden auto'
    },

    '& .amaui-logo-amaui': {
      fill: theme.palette.light ? '' : theme.palette.color.amaui[20]
    },

    '& .amaui-logo-website': {
      fill: theme.palette.light ? '' : theme.palette.color.website[20]
    },

    '& .amaui-logo-resume': {
      fill: theme.palette.light ? '' : theme.palette.color.resume[20]
    },

    '& .amaui-logo-task': {
      fill: theme.palette.light ? '' : theme.palette.color.task[20]
    },

    '& .amaui-logo-note': {
      fill: theme.palette.light ? '' : theme.palette.color.note[20]
    },

    '& .amaui-logo-url-shortener': {
      fill: theme.palette.light ? '' : theme.palette.color.urlShortener[20]
    },

    '& .amaui-logo-blog': {
      fill: theme.palette.light ? '' : theme.palette.color.blog[20]
    },

    '& .amaui-logo-support': {
      fill: theme.palette.light ? '' : theme.palette.color.support[20]
    },

    '& .amaui-logo-chat': {
      fill: theme.palette.light ? '' : theme.palette.color.chat[20]
    },

    '& .amaui-logo-form': {
      fill: theme.palette.light ? '' : theme.palette.color.form[20]
    },

    '& .amaui-logo-reservation': {
      fill: theme.palette.light ? '' : theme.palette.color.reservation[20]
    },

    '& .amaui-logo-contact': {
      fill: theme.palette.light ? '' : theme.palette.color.contact[20]
    },

    '& .amaui-logo-work-day': {
      fill: theme.palette.light ? '' : theme.palette.color.workDay[20]
    },

    '& .amaui-logo-location': {
      fill: theme.palette.light ? '' : theme.palette.color.location[20]
    }
  },

  wrapper: {
    width: 'calc(100% - 144px)'
  },

  wrapperRailExpanded: {
    width: 'calc(100vw - 240px)',
    paddingInlineStart: 10
  },

  header: {
    flex: '0 0 auto',
    padding: '24px 40px',
    backgroundColor: 'wheat'
  },

  main: {
    display: 'flex',
    flexDirection: 'row',
    flex: '1 1 auto',
    height: 0,
    overflowY: 'auto'
  },

  footer: {
    flex: '0 0 auto',
    padding: '14px 40px'
  },

  divider: {
    '&.amaui-Divider-root': {
      width: '2px !important',
      background: theme.methods.palette.color.value('primary', 10),
      opacity: '0.07 !important'
    }
  },

  navigationRail: {

  }
}), { name: 'amaui-app-route-Root-Main' });

const Root = React.forwardRef((props: any, ref: any) => {
  const {
    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const mobile = useMediaQuery(`(max-width: ${CONSTS.mobile}px)`);
  const location = useLocation();

  const touch = useMediaQuery('(pointer: coarse)');

  const [add, setAdd] = React.useState<IAdd>();
  const [addSecondary, setAddSecondary] = React.useState<IAdd>();
  const [addTertiary, setAddTertiary] = React.useState<IAdd>();

  const [expanded, setExpanded] = React.useState(false);

  const railMainMethod = (value: any, setResponse: any) => {
    if (value.expand !== undefined) setExpanded(value.expand);

    // if (value.children !== undefined) setChildren(value.children);
  };

  useSubscription<IRailMain>(AppService.pages.railMain, railMainMethod);

  const refs = {
    add: React.useRef(add),
    addProps: React.useRef<any>(),
    addSecondary: React.useRef(addSecondary),
    addSecondaryProps: React.useRef<any>(),
    addTertiary: React.useRef(addTertiary),
    addTertiaryProps: React.useRef<any>()
  };

  refs.add.current = add;

  refs.addSecondary.current = addSecondary;

  refs.addTertiary.current = addTertiary;

  const addMethod = (value: any, setResponse: any) => {
    setAdd(value);
  };

  useSubscription<IAdd>(AppService.pages.add, addMethod);

  const addSecondaryMethod = (value: any, setResponse: any) => {
    setAddSecondary(value);
  };

  useSubscription<IAdd>(AppService.pages.addSecondary, addSecondaryMethod);

  const addTertiaryMethod = (value: any, setResponse: any) => {
    setAddTertiary(value);
  };

  useSubscription<IAdd>(AppService.pages.addTertiary, addTertiaryMethod);

  React.useEffect(() => {
    if (
      refs.add.current?.open &&
      ['inline'].includes(refs.add.current?.version as string) &&
      !['/tasks'].some(item => location.pathname.includes(item))
    ) {
      AppService.pages.add.emit({
        ...AppService.pages.add.value,

        open: false
      });
    }
  }, [location?.pathname]);

  const onChangeWindowSplit = React.useCallback((valueNew: any) => {
    StorageService.add('windowSplitPaddingStart', valueNew);
  }, []);

  const addInline = add?.version === 'inline';

  const addInlineOpen = addInline && add?.open;

  const addMidEntire = ['mid', 'entire'].includes(add?.version as string);

  const addSecondaryMidEntire = ['mid', 'entire'].includes(addSecondary?.version as string);

  const addTertiaryMidEntire = ['mid', 'entire'].includes(addTertiary?.version as string);

  refs.addProps.current = {
    ...add
  };

  refs.addSecondaryProps.current = {
    ...addSecondary
  };

  refs.addTertiaryProps.current = {
    ...addTertiary
  };

  const windowSplitPaddingStart = +(StorageService.get('windowSplitPaddingStart') !== undefined ? StorageService.get('windowSplitPaddingStart') || 30 : 30);

  return <>
    <Line
      ref={ref}

      gap={0}

      direction='column'

      flex

      fullWidth

      className={classNames([
        className,
        classes.root,
        'amaui-app-Root-Main'
      ])}

      {...other}
    >
      <Header />

      <Line
        gap={0}

        direction='row'

        justify='unset'

        align='unset'

        fullWidth

        flex
      >
        {!mobile && <RailMain />}

        <Line
          gap={0}

          direction='column'

          justify='unset'

          align='center'

          flex

          className={classNames([
            expanded ? classes.wrapperRailExpanded : classes.wrapper
          ])}
        >
          <WindowSplit
            valueDefault={windowSplitPaddingStart}

            paddingStart={54}

            paddingEnd={24}

            onChange={onChangeWindowSplit}

            noDivider={!addInlineOpen}

            noKeyboard

            Component='main'

            className={classes.main}

            DividerProps={{
              className: classes.divider
            }}
          >
            <Line
              gap={0}

              align='center'

              fullWidth

              flex
            >
              <Outlet />
            </Line>

            {addInlineOpen && (
              <Add
                {...refs.addProps.current}
              />
            )}
          </WindowSplit>
        </Line>
      </Line>

      {addMidEntire && (
        <Add
          {...refs.addProps.current}
        />
      )}

      {addSecondaryMidEntire && (
        <Add
          subscription={AppService.pages.addSecondary}

          {...refs.addSecondaryProps.current}
        />
      )}

      {addTertiaryMidEntire && (
        <Add
          subscription={AppService.pages.addTertiary}

          {...refs.addTertiaryProps.current}
        />
      )}
    </Line>

    {!touch && <WidgetMain />}

    {mobile ? <>
      <SidebarMain />
    </> : <></>}
  </>;
});

export default Root;
