import React from 'react';

import { is, textToInnerHTML } from '@amaui/utils';
import { Card, CardFooter, CardImage, CardMain, Checkbox, IconButton, Line, Menu, Type } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';
import { ITemplate, ITheme } from '@amaui/api-utils';

import IconMaterialMoreVertRounded from '@amaui/icons-material-rounded-react/IconMaterialMoreVert';

import ImagePlaceholder from 'assets/images/placeholder.png';

import { Tags } from 'ui';
import { getMediaUrl } from 'other';

const useStyle = style(theme => ({
  root: {
    '&.amaui-Card-root': {
      alignSelf: 'flex-start',
      width: '100vw',
      maxWidth: 259,
      outline: '0px solid',
      transition: theme.methods.transitions.make(['border-radius', 'outline'])
    },

    '&[data-selected="true"]': {
      outline: '2px solid !important',
      outlineColor: theme.methods.palette.color.value('secondary', 30)
    }
  },

  noMaxWidth: {
    '&.amaui-Card-root': {
      maxWidth: 'unset'
    }
  },

  name: {
    cursor: 'pointer',
    userSelect: 'none',
    marginBottom: 4
  },

  summary: {
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-line-clamp': 2,
    wordWrap: 'break-word',
    '-webkit-box-orient': 'vertical',
    maxHeight: 40,
    cursor: 'default',
    userSelect: 'none'
  },

  image: {
    '&.amaui-CardImage-root': {
      width: '100%',
      height: 'auto',
      maxWidth: 'unset',
      maxHeight: 240,
      objectFit: 'cover',
      paddingBottom: '0px !important',
      backgroundColor: theme.palette.background.primary.tertiary
    }
  },

  selected: {
    outline: '2px solid !important',
    outlineColor: theme.methods.palette.color.value('secondary', 30)
  }
}), { name: 'amaui-app-route-Templates' });


const Element = React.forwardRef((props: any, ref: any) => {
  const {
    value: valueObject,

    getMenuItems,

    itemProps,

    selectedTemplates,

    onOpen,

    select,

    selected: selected_,

    meta = true,

    simple,

    noMaxWidth,

    ...other
  } = props;

  const { classes } = useStyle();

  const object = valueObject as ITemplate;

  const name = object?.name;

  const summary = object?.description;

  const image = object?.image || (object as ITheme).media?.find(item => item.mime.includes('image'));

  const moreProps = is('function', itemProps) ? itemProps(object) : {};

  const selected = selected_ !== undefined ? selected_ : !!selectedTemplates?.isSelected(object?.id);

  const onContextMenuPrevent = React.useCallback((event: MouseEvent, values: any) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  return (
    <Card
      ref={ref}

      color='default'

      elevation={4}

      button={select}

      className={classNames([
        classes.root,
        selected && classes.selected,
        noMaxWidth && classes.noMaxWidth
      ])}

      {...other}

      {...moreProps}
    >
      <CardImage
        image={image ? getMediaUrl(image) : ImagePlaceholder}

        alt={name}

        shape='all'

        className={classes.image}
      />

      <CardMain
        gap={2}
      >
        {name && (
          <Type
            version='t2'

            onClick={() => !select && onOpen?.(object)}

            whiteSpace='pre-line'

            dangerouslySetInnerHTML={{
              __html: textToInnerHTML(name)
            }}

            className={classNames([
              classes.name
            ])}
          />
        )}

        {summary && (
          <Type
            version='b2'

            priority='secondary'

            whiteSpace='pre-line'

            dangerouslySetInnerHTML={{
              __html: textToInnerHTML(summary)
            }}

            className={classNames([
              classes.summary
            ])}
          />
        )}

        {meta && !simple && <>
          {!select && (
            <Line
              gap={2}

              direction='row'

              align='center'

              wrap='wrap'

              fullWidth
            >
              <Type
                version='b3'
              >
                <b>Active:</b> {object.active ? 'Yes' : 'No'}
              </Type>

              <Type
                version='b3'
              >
                <b>Archived:</b> {object.archived ? 'Yes' : 'No'}
              </Type>
            </Line>
          )}

          {!select && object.tags && (
            <Tags
              value={object.tags}

              justify='flex-start'

              fullWidth
            />
          )}
        </>}
      </CardMain>

      {!simple && !select && (
        <CardFooter
          justify='space-between'

          style={{
            padding: '0 16px 16px'
          }}
        >
          <Checkbox
            checked={selected}

            onChange={() => selectedTemplates?.select(object, true)}

            size='small'
          />

          {getMenuItems && (
            <Menu
              alignment='center'

              menuItems={getMenuItems(object)}

              onContextMenu={onContextMenuPrevent}

              ListProps={{
                size: 'small'
              }}
            >
              <IconButton
                color='inherit'

                size='small'

                onContextMenu={onContextMenuPrevent}
              >
                <IconMaterialMoreVertRounded />
              </IconButton>
            </Menu>
          )}
        </CardFooter>
      )}
    </Card>
  );
});

export default Element;
