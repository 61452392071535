import React from 'react';

import { Select } from '@amaui/ui-react';
import { ISelect } from '@amaui/ui-react/Select/Select';
import { classNames, style } from '@amaui/style-react';

const useStyle = style(theme => ({
  root: {

  }
}), { name: 'amaui-app-SelectSearch' });

const Element = React.forwardRef((props: ISelect, ref: any) => {
  const {
    className,

    ...other
  } = props;

  const { classes } = useStyle(props);

  return (
    <Select
      ref={ref}

      className={classNames([
        classes.root,
        className
      ])}

      fullWidth={{
        xxs: true,
        xs: true,
        default: false
      }}

      WrapperProps={{
        fullWidth: {
          xxs: true,
          xs: true,
          default: false
        }
      }}

      {...other}
    />
  );
});

Element.displayName = 'amaui-app-Select';

export default Element;
