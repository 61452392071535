import React from 'react';

import { FormPost, Page } from 'ui';
import { PostService } from 'services';

const Posts = React.forwardRef(() => {

  const properties = React.useMemo(() => ({
    name: { name: 'Name' },
    short_description: { name: 'Summary' },
    active: { name: 'Active' },
    archived: { name: 'Archived' },
    read_time: { name: 'Read time', type: 'duration' }
  }), []);

  return (
    <Page
      name='Posts'

      service={PostService}

      columns={[
        properties.name,
        properties.active,
        properties.archived
      ]}

      search={['active', 'archived']}

      options={['active', 'archived']}

      properties={properties as any}

      app='blog'

      collection='posts'

      route='/posts'

      Form={FormPost}

      updateEntire
    />
  );
});

export default Posts;
