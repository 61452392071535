import React from 'react';

import { NumericTextField } from '@amaui/ui-react';
import { INumericTextField } from '@amaui/ui-react/NumericTextField/NumericTextField';
import { classNames, style } from '@amaui/style-react';

const useStyle = style(theme => ({
  root: {
    '&.amaui-NumericTextField-root': {
      minWidth: theme.minWidthInput
    }
  },

  '@media only screen and (max-width: 768px)': {
    $root: {
      '&.amaui-NumericTextField-root': {
        minWidth: '100% !important'
      }
    }
  }
}), { name: 'amaui-app-NumericTextField' });

const Element = React.forwardRef((props: INumericTextField, ref: any) => {
  const {
    className,

    ...other
  } = props;

  const { classes } = useStyle(props);

  return (
    <NumericTextField
      ref={ref}

      version='outlined'

      className={classNames([
        classes.root,
        className
      ])}

      {...other}
    />
  );
});

Element.displayName = 'amaui-app-NumericTextField';

export default Element;
