import { Template } from '@amaui/api-utils';
import AmauiSubscription from '@amaui/subscription';

import { BaseService } from './base';
import { IQuerySubscription, ISelectedSubscription } from 'other';

class TemplateService extends BaseService<Template> {
  public queryItems = new AmauiSubscription<IQuerySubscription>();
  public selectedItems = new AmauiSubscription<ISelectedSubscription>();
  public queryTemplatesTheme = new AmauiSubscription<IQuerySubscription>();
  public selectedTemplatesTheme = new AmauiSubscription<ISelectedSubscription>();

  public constructor() {
    super('templates');

    this.queryObjectsInit(this.queryItems);

    this.selectedObjectsInit(this.selectedItems);

    this.queryObjectsInit(this.queryTemplatesTheme);

    this.selectedObjectsInit(this.selectedTemplatesTheme);
  }

}

export default new TemplateService();
