import React from 'react';

import { cleanValue, is, textToInnerHTML } from '@amaui/utils';
import { Checkbox, ColorTextField, FormRow, Label, Labels, Line, Medias, Radio, Radios, Rating, Table, TableBody, TableCell, TableHead, TableRow, Type } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';
import { IFormInput, IFormInputOption, IFormPollOption, IFormValue } from '@amaui/api-utils';
import { AmauiDate, format } from '@amaui/date';

import { formats } from 'other';

const useStyle = style(theme => ({
  root: {

  },

  wrapper: {
    maxWidth: theme.maxWidth
  }
}), { name: 'amaui-app-element-Response' });

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    object,

    parent,

    onClose,

    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const value = object;

  const form = parent;

  if (!value || !form) return null;

  const getOption = (item: IFormInputOption | IFormPollOption) => {
    let element: any;

    if (item.version === 'type') element = (
      <Type
        version='b2'
      >
        {item.value}
      </Type>
    );

    if (['image', 'audio', 'video'].includes(item.version as string)) element = (
      <Medias
        values={[item.value?.media || item.value].filter(Boolean).map((item_: any) => ({
          value: item_
        }))}

        noName
      />
    );

    return element;
  };

  const getInput = (valueInput: IFormInput, index: number) => {
    if (!valueInput) return null;

    const version = valueInput.version;

    const responseItem = value?.values?.find((item: any) => item.id === valueInput.id);

    const valueResponse = is('string', responseItem?.value) ? decodeURIComponent(responseItem?.value) : responseItem?.value;

    const values = (is('array', valueResponse) ? valueResponse : [valueResponse]).filter(Boolean);

    if (version !== 'static') {
      let response: any;

      // text 
      if (version === 'text') {
        response = valueResponse;
      }

      // number 
      if (version === 'number') {
        response = valueResponse;
      }

      // slider 
      if (version === 'slider') {
        response = valueResponse;
      }

      // radios 
      if (version === 'radios') {
        response = responseItem && (
          <Radios
            checkedDefault={values[0]}

            disabled
          >
            {(valueInput.options || []).map((option: IFormInputOption, index: number) => (
              <Label
                key={index}

                value={option.id}

                Component='div'
              >
                <Radio />

                {getOption(option)}
              </Label>
            ))}
          </Radios>
        );
      }

      // checkboxes
      if (version === 'checkboxes') {
        response = responseItem && (
          <Labels
            checkedDefault={values}

            disabled
          >
            {(valueInput.options || []).map((option: IFormInputOption, index: number) => (
              <Label
                key={index}

                value={option.id}

                Component='div'
              >
                <Checkbox />

                {getOption(option)}
              </Label>
            ))}
          </Labels>
        );
      }

      // multiple grid 
      if (version === 'multiple-grid') {
        response = responseItem && (
          <Table
            size='small'
          >
            <TableHead>
              <TableRow>
                <TableCell />

                {valueInput.columns?.map((column: string, index: number) => (
                  <TableCell
                    key={index}
                  >
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {valueInput.rows?.map((row: string, index: number) => (
                <TableRow
                  key={index}
                >
                  <TableCell>
                    {row}
                  </TableCell>

                  {valueInput.columns?.map((column: string, index_: number) => (
                    <TableCell
                      key={index_}
                    >
                      {valueInput.select_multiple ? (
                        <Checkbox
                          value={valueResponse?.includes(`${row}-${column}`)}

                          disabled
                        />
                      ) : (
                        <Radio
                          value={valueResponse?.includes(`${row}-${column}`)}

                          disabled
                        />
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        );
      }

      // date-time 
      if (version === 'date-time') {
        if (values[0]) response = `${format(new AmauiDate(values[0]), formats.entire)}`;

        if (values[1]) response += ` — ${format(new AmauiDate(values[1]), formats.entire)}`;
      }

      // date 
      if (version === 'date') {
        if (values[0]) response = `${format(new AmauiDate(values[0]), formats.date)}`;

        if (values[1]) response += ` — ${format(new AmauiDate(values[1]), formats.date)}`;
      }

      // time 
      if (version === 'time') {
        if (values[0]) response = `${format(new AmauiDate(values[0]), formats.time)}`;

        if (values[1]) response += ` — ${format(new AmauiDate(values[1]), formats.time)}`;
      }

      // select 
      if (version === 'select') {
        response = values?.join(', ');
      }

      // autocomplete 
      if (version === 'autocomplete') {
        response = values?.map((item: any) => item.name || item.label || item).join(', ');
      }

      // color 
      if (version === 'color') {
        response = responseItem && (
          <ColorTextField
            valueDefault={valueResponse}

            disabled
          />
        );
      }

      // rating 
      if (version === 'rating') {
        response = responseItem && (
          <Rating
            values={valueInput.rating_values || 5}

            precision={valueInput.rating_precision || 1}

            valueDefault={valueResponse}

            readOnly
          />
        );
      }

      // email 
      if (version === 'email') {
        response = valueResponse;
      }

      // address 
      if (version === 'address') {
        response = valueResponse;
      }

      // mobile 
      if (version === 'mobile') {
        response = valueResponse;
      }

      // confirm 
      if (version === 'confirm') {
        response = valueResponse ? 'Yes' : 'No';
      }

      // password 
      if (version === 'password') {
        response = valueResponse;
      }

      return (
        <FormRow
          key={valueInput.id || index}

          gap={1}

          name={valueInput.name || `Input ${cleanValue(valueInput.version as string)}`}

          description={(
            <Type
              version='b3'

              className={classes.type}

              dangerouslySetInnerHTML={{
                __html: textToInnerHTML(valueInput.description)
              }}
            />
          )}
        >
          {response ? is('simple', response) ? (
            <Type
              version='l2'

              color='secondary'

              className={classes.type}

              dangerouslySetInnerHTML={{
                __html: textToInnerHTML(response)
              }}
            />
          ) : response : 'No response'}
        </FormRow>
      );
    }

    return null;
  };

  const getForm = (valueForm: IFormValue) => {
    if (!valueForm) return null;

    const inputs = valueForm.inputs || [];

    return !!inputs.length && (
      <Line
        gap={1}

        fullWidth
      >
        {inputs.map((input: any, index: number) => getInput(input, index))}
      </Line>
    );
  };

  return (
    <Line
      ref={ref}

      direction='column'

      justify='unset'

      align='center'

      flex

      fullWidth

      className={classNames([
        className,
        classes.root
      ])}

      {...other}
    >
      <Line
        gap={3}

        align='center'

        fullWidth

        className={classes.wrapper}
      >
        {form.value.map((item: IFormValue, index: number) => (
          <Line
            key={index}

            fullWidth
          >
            <Type
              version='l1'
            >
              {item.name || `Step ${index + 1}`}
            </Type>

            {getForm(item)}
          </Line>
        ))}
      </Line>
    </Line>
  );
});

export default Element;
