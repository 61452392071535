import React from 'react';

import { Line } from '@amaui/ui-react';

import { FormTemplate, ObjectCard, Page } from 'ui';
import { TemplateService } from 'services';
import { IPageItemMethodOptions } from 'ui/layout/Page';

const cleanValue = (value_: string) => {
  let value = value_;

  ['-', '_'].forEach(filter => {
    const expression = `\\${filter}`;
    const regexp = new RegExp(expression, 'g');

    value = value ? value.replace(regexp, ' ') : '';
  });

  return `${value.charAt(0).toUpperCase()}${value.slice(1)}`;
};

const Templates = React.forwardRef((props: any) => {
  const {
    website,

    theme,

    version,

    page = true,

    itemProps: itemProps_,

    select,

    CardsProps,

    className,

    ...other
  } = props;

  const getItems = React.useCallback((options: IPageItemMethodOptions) => {
    const {
      queryItems,
      selectedItems,

      props: getItemProps,
      getMenuItems,

      onOpen
    } = options;

    return (
      <Line
        gap={2}

        direction='row'

        wrap='wrap'

        align='unset'

        style={{
          padding: 4
        }}

        {...CardsProps}
      >
        {(queryItems?.response || [])?.map((item: any, index: number) => {
          const itemProps = {
            ...getItemProps(item),
            ...itemProps_?.(item)
          };

          return (
            <ObjectCard
              key={index}

              value={item}

              getMenuItems={getMenuItems}

              itemProps={itemProps}

              selectedTemplates={selectedItems}

              onOpen={onOpen}

              select={select}

              {...itemProps}
            />
          );
        })}
      </Line>
    );
  }, [website, theme, itemProps_, version, page, select, CardsProps]);

  const properties = React.useMemo(() => ({
    name: { name: 'Name' },
    description: { name: 'Description' },
    version: { name: 'Version', method: (item: any) => cleanValue(item.version) },
    active: { name: 'Active' },
    archived: { name: 'Archived' }
  }), []);

  return (
    <Page
      name='Templates'

      service={TemplateService}

      columns={[
        properties.name,
        properties.version,
        properties.active,
        properties.archived
      ]}

      properties={properties}

      getItems={getItems}

      queryDefault={{
        ...((website || theme) && version === 'website' ? {
          ...(website && { websites: website?.id })
        } : {
          ...(theme && { themes: theme?.id })
        })
      }}

      search={[
        'active',
        'archived',
        {
          name: 'Version',
          property: 'version',
          default: 'all',
          validation: { name: 'Version', is: 'string', in: ['page', 'section'], value: 'all' },
          options: [
            { name: 'All', value: 'all' },
            { name: 'Page', value: 'page' },
            { name: 'Section', value: 'section' }
          ]
        }
      ]}

      options={['active', 'archived', 'copy']}

      app='website'

      collection='templates'

      route='/templates'

      Form={FormTemplate}

      onAddFormProps={{
        theme,
        website
      }}

      nested={!page}

      updateEntire

      {...other}
    />
  );
});

export default Templates;
