import React from 'react';

import { getCountry, stringToColor } from '@amaui/utils';
import { Line, ListItem, PieChart, Table, TableBody, TableCell, TableHead, TableRow, Type, useSnackbars } from '@amaui/ui-react';
import { classNames, style, useAmauiTheme } from '@amaui/style-react';
import { Analytic } from '@amaui/api-utils';
import { AmauiDate, format } from '@amaui/date';

import { Analytics, Page, Select, useSubscription } from 'ui';
import { AnalyticService, FormService } from 'services';
import { IQuerySubscription, getErrorMessage } from 'other';
import Response from './Response';

const useStyle = style(theme => ({
  root: {

  },

  pollOption: {
    padding: 20,
    borderRadius: 24,
    border: `2px solid transparent`,
    transition: theme.methods.transitions.make('border-color')
  },

  pollOptiontype: {
    borderColor: theme.palette.text.primary.primary
  },

  chart: {
    width: '100%'
  },

  name: {
    cursor: 'pointer'
  },

  ...theme.classes(theme)
}), { name: 'amaui-app-element-Responses' });

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    value,

    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const snackbars = useSnackbars();
  const theme = useAmauiTheme();

  const queryFormPollAnalytics = useSubscription<IQuerySubscription>(AnalyticService.queryFormPollAnalytics);

  const queryItemResponses = useSubscription<IQuerySubscription>(FormService.queryItemResponses);

  const [loaded, setLoaded] = React.useState(false);
  const [loading, setLoading] = React.useState<any>(false);
  const [analyticsPer, setAnalyticsPer] = React.useState<any>('');
  const [type, setType] = React.useState<any>({ name: 'Total', value: 'total' });
  const [countries, setCountries] = React.useState([]);

  const refs = {
    value: React.useRef(value),
    queryItemResponses: React.useRef(queryItemResponses)
  };

  refs.value.current = value;

  refs.queryItemResponses.current = queryItemResponses;

  const dates = React.useMemo(() => {
    const today = AmauiDate.utc;
    const myToday = new AmauiDate();

    return [
      { name: 'Total', value: '' },
      { name: format(myToday, `'Hour' HH'h'`), value: format(today, 'YYYY-MM-DD HH') },
      { name: 'Today', value: format(today, 'YYYY-MM-DD') },
      { name: 'Month', nameMore: format(today, 'MMM, YYYY'), value: format(today, 'YYYY-MM') },
      { name: 'Year', nameMore: format(today, 'YYYY'), value: format(today, 'YYYY') }
    ];
  }, []);

  const init = React.useCallback(async () => {
    if (refs.value.current) {
      // poll 
      if (refs.value.current.version === 'poll') {
        // Get countries only first 
        const resultCountries = await AnalyticService.queryPost({
          query: {
            user: null,
            version: `form-${refs.value.current?.id}-poll-responses-country-`,
            unit: 'total'
          }
        });

        if (resultCountries.status >= 400) {
          snackbars.add({
            color: 'error',
            primary: getErrorMessage(resultCountries)
          });
        }
        else {
          const analytics = resultCountries.response.response;

          const countries_ = analytics.map((item: any) => {
            const short = item.version.split('-').filter(Boolean).splice(-1)?.[0];

            return {
              ...item,

              value: short,

              country: getCountry(short)
            };
          });

          setCountries(countries_);
        }

        const result = await AnalyticService.queryFormPollAnalytics.value!.query({
          query: {
            query: {
              user: null,
              version: [`form-${refs.value.current?.id}-poll-responses`],
              date: dates.map(item => item.value)
            }
          }
        });

        if (result.status >= 400) {
          snackbars.add({
            color: 'error',
            primary: getErrorMessage(result)
          });
        }
      }
    }

    setLoaded(true);
  }, [dates]);

  React.useEffect(() => {
    // init 
    init();
  }, []);

  const getAnalytics = React.useCallback(async () => {
    setLoading(true);

    const result = await AnalyticService.queryFormPollAnalytics.value!.query({
      query: {
        query: {
          version: type?.value === 'total' ? `form-${refs.value.current?.id}-poll-responses` : `form-${refs.value.current?.id}-poll-responses-country-${(type as any)?.value}`,
          date: dates.map(item => item.value)
        }
      }
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }

    setLoading(false);
  }, [type]);

  React.useEffect(() => {
    if (loaded) getAnalytics();
  }, [type]);

  const optionsType = React.useMemo(() => {
    return [{ name: 'Total', value: 'total' }, ...countries];
  }, [countries]);

  const onTypeChange = React.useCallback((valueNew: any) => {
    setType(optionsType.find(item => item.value === valueNew));
  }, [optionsType]);

  const onChangeAnalyticsPer = React.useCallback((value: any) => {
    setAnalyticsPer(value);
  }, []);

  const getAnalytic = (date: string = 'analyticsPer'): any => {
    return (queryFormPollAnalytics?.response || []).find((item: Analytic) => item.date === date);
  };

  const getPieChart = () => {
    const analytic = getAnalytic(analyticsPer);

    if (analytic) {
      const options = Object.keys(analytic.value).map((item: any, index: number) => ({
        name: `Option ${index + 1}`,
        id: item
      }));

      return (
        <PieChart
          title={type?.value === 'total' ? 'Votes in total' : `Votes in ${(type as any).country.flag} ${(type as any).country.name}`}

          subtitle='In %'

          values={options.map(item => ({
            color: stringToColor(item.id) as any,
            name: item.name,
            values: [analytic.value[item.id]]
          }))}

          className={classNames([
            classes.chart,
            classes.pieChart,
            options.length === 1 && classes.pieChart100
          ])}
        />
      );
    }

    return null;
  };

  const getCountryTable = () => {
    const analytic = getAnalytic(analyticsPer);

    if (analytic) {
      const options = Object.keys(analytic.value).map((item: any, index: number) => ({
        name: `Option ${index + 1}`,
        id: item
      }));

      return (
        <Table
          size='small'
        >
          <TableHead>
            <TableRow>
              {['Name', 'Votes'].map((item: string, index: number) => (
                <TableCell
                  key={index}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {options.map((item: any, index: number) => (
              <TableRow
                key={index}
              >
                <TableCell
                  key={index}
                >
                  {item.name}
                </TableCell>

                <TableCell
                  key={index}
                >
                  {analytic.value[item.id]}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      );
    }

    return null;
  };

  const analytictype = getAnalytic(analyticsPer);

  const properties = React.useMemo(() => ({
    user: { name: 'User', method: (item: any) => item.user?.name || item.user?.id },
    form: { name: 'Form', method: (item: any) => item.form?.name || '' },
    archived: { name: 'Archived' }
  }), []);

  const responsesUI: any = {
    'poll': <>
      <Line
        gap={1}

        direction='row'

        align='center'

        justify='flex-end'

        fullWidth
      >
        <Select
          name='Date'

          valueDefault={''}

          value={analyticsPer}

          onChange={onChangeAnalyticsPer}

          MenuProps={{
            AppendProps: {
              switch: false
            }
          }}
        >
          {dates.map((item, index: number) => (
            <ListItem
              key={index}

              primary={(
                <Type
                  version='b3'
                >
                  {`${item.name} ${item.nameMore || ''}`.trim()}
                </Type>
              )}

              value={item.value}

              button
            />
          ))}
        </Select>

        <Select
          value={type?.value}

          onChange={onTypeChange}

          disabled={loading}
        >
          {optionsType.map((item: any, index: number) => (
            <ListItem
              key={index}

              start={item.value !== 'total' && (
                <Type
                  version='b2'
                >
                  {item.country.flag}
                </Type>
              )}

              primary={(
                <Type
                  version='b2'
                >
                  {item.value === 'total' ? item.name : item.country.name}
                </Type>
              )}

              value={item.value}

              startAlign='center'

              button
            />
          ))}
        </Select>
      </Line>

      <Analytics
        gap={3}

        align='center'

        direction='column'

        style={{
          padding: '16px 8px 8px'
        }}
      >
        {!analytictype && (
          <Line
            align='center'

            justify='center'

            style={{
              height: 140
            }}
          >
            <Type
              align='center'
            >
              No visits recorded at the moment
            </Type>
          </Line>
        )}

        {getPieChart()}

        {getCountryTable()}
      </Analytics>
    </>,

    'form': <>
      <Line
        ref={ref}

        gap={4}

        fullWidth

        className={classNames([
          className,
          classes.root,
          'amaui-overflow-y'
        ])}

        {...other}

        style={{
          maxWidth: theme.maxWidth,

          ...other.style
        }}
      >
        <Page
          name='Form responses'

          service={FormService}

          parent={value}

          columns={[
            properties.user,
            properties.archived
          ]}

          search={['archived']}

          options={['archived']}

          properties={properties}

          app='form'

          collection='formResponses'

          route='/forms'

          Form={Response}

          updateOneName='updateResponse'

          updateManyName='updateManyResponse'

          removeOneName='removeResponse'

          removeManyName='removeManyResponse'

          queryItemsName='queryItemResponses'

          selectedItemsName='selectedItemResponses'

          nested

          noTags

          noAdd

          noTextSearch
        />
      </Line>
    </>
  };

  if (!value) return null;

  return (
    <Line
      ref={ref}

      gap={3}

      direction='column'

      justify='unset'

      align='flex-start'

      flex

      fullWidth

      className={classNames([
        className,
        classes.root
      ])}

      {...other}
    >
      {loaded && <>
        <Line
          gap={2}

          fullWidth
        >
          {responsesUI[value.version]}
        </Line>
      </>}
    </Line>
  );
});

export default Element;
