import React from 'react';

import { cleanValue, is, textToInnerHTML } from '@amaui/utils';
import { FormRow, Line, ListItem, Properties, useForm, useSnackbars } from '@amaui/ui-react';
import { AmauiDate, format, set } from '@amaui/date';

import { ModalForm, ReadProperties, Select } from 'ui';
import { AppService, ReservationOptionService, ReservationService } from 'services';
import { formats, getErrorMessage } from 'other';

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    object: object_,

    onConfirm
  } = props;

  const snackbars = useSnackbars();

  const [object, setObject] = React.useState(object_);
  const [loading, setLoading] = React.useState<any>(false);
  const [reservationOptions, setReservationOptions] = React.useState([]);

  const optionsStatus = ['pending', 'auto-approved', 'approved', 'rejected', 'canceled'];

  const form = useForm({
    values: {
      'status': {
        name: 'Status',
        value: object?.status,
        in: optionsStatus
      }
    }
  });

  const refs = {
    form: React.useRef(form)
  };

  refs.form.current = form;

  const init = React.useCallback(async () => {
    const result = await ReservationOptionService.queryPost({
      query: {
        id: object.value?.reservation_options?.map((item: any) => item.id)
      }
    });

    if (result.status >= 400) {
      snackbars.add({
        primary: getErrorMessage(result),
        color: 'error'
      });
    }
    else {
      const reservationOptions_ = result.response.response || [];

      const reservationOptionsMap: any = {};

      reservationOptions_.forEach((item: any) => reservationOptionsMap[item.id] = item);

      object.value.reservation_options = object.value.reservation_options.map((item: any) => ({
        ...reservationOptionsMap[item.id],

        ...item
      }));

      setReservationOptions(reservationOptions_);
    }
  }, [object]);

  React.useEffect(() => {
    if (object) init();
  }, [object]);

  const onClose = React.useCallback(() => {
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });
  }, []);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();
  }, []);

  const onNext = React.useCallback(async (event: SubmitEvent) => {
    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const body = {
      ...refs.form.current.value
    };

    const result = !object?.id ? await ReservationService.add(body) : await ReservationService.update(object?.id, body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Reservation ${!object?.id ? 'added' : 'updated'}`
      });

      setObject(result.response.response);

      if (is('function', onConfirm)) onConfirm();

      if (!object?.id) onClose();
    }

    setLoading(false);
  }, [object, form, onConfirm, onClose]);

  const properties: any = [];

  if (object) {
    if (object.requestee?.name) properties.push({ name: 'Name', value: object.requestee.name });

    if (object.requestee?.email) properties.push({ name: 'Email', value: object.requestee.email, u: false });

    if (object.requestee?.mobile) properties.push({ name: 'Mobile', value: object.requestee.mobile });

    if (reservationOptions?.length) {
      if (object.value?.reservation_options) {
        properties.push({ name: 'Reservation options', value: object.value?.reservation_options.map((item: any) => `${textToInnerHTML(item.name)}: ${item.total}`).join(', ') });
      }
    }

    const date = new AmauiDate(object.value?.date);

    let start = new AmauiDate(object.value?.start);

    // update from, to to time's specific hours, minutes, seconds, milliseconds
    start = set(date.hour, 'hour', start);
    start = set(date.minute, 'minute', start);
    start = set(date.second, 'second', start);
    start = set(date.millisecond, 'millisecond', start);

    let end = new AmauiDate(object.value?.end);

    end = set(date.hour, 'hour', end);
    end = set(date.minute, 'minute', end);
    end = set(date.second, 'second', end);
    end = set(date.millisecond, 'millisecond', end);

    properties.push({ name: 'Date', value: `${format(start, formats.entire)} — ${format(end, formats.entire)}` });
  }

  const modal: any = {
    read: (
      <Line
        fullWidth
      >
        <ReadProperties
          object={object}

          values={[
            'status',

            ...properties
          ]}
        />
      </Line>
    ),

    write: <>
      <FormRow
        name='Info'

        fullWidth
      >
        <Properties
          version='row-20'

          values={properties}
        />
      </FormRow>

      <Select
        name='Status'

        value={form.values.status.value || ''}

        onChange={(valueNew: any) => form.onChange('status', valueNew)}

        error={!!form.values.status.error}

        helperText={form.values.status.error}
      >
        {optionsStatus.map((item: string, index: number) => (
          <ListItem
            key={item}

            primary={cleanValue(item, { capitalize: true })}

            value={item}

            button
          />
        ))}
      </Select>
    </>
  };

  return (
    <ModalForm
      {...props}

      object={object}

      name='Reservation'

      add={!object}

      {...modal}

      onSubmit={onSubmit}

      onNext={onNext}

      onClose={onClose}

      loading={loading}

      smaller
    />
  );
});

export default Element;
