import { SignIn } from '@amaui/api-utils';
import AmauiSubscription from '@amaui/subscription';
import { IAdditional } from '@amaui/sdk/other';

import { BaseService } from './base';
import { IQuerySubscription, ISelectedSubscription } from 'other';
import { AmauiService, AuthService } from 'services';

class SignInService extends BaseService<SignIn> {
  public queryItems = new AmauiSubscription<IQuerySubscription>();
  public selectedItems = new AmauiSubscription<ISelectedSubscription>();

  public constructor() {
    super('signIns');

    this.queryObjectsInit(this.queryItems);

    this.selectedObjectsInit(this.selectedItems);
  }

  public async inactivateAll(options?: IAdditional) {
    return AmauiService.sdk[this.route as 'signIns']?.inactivateAll(options);
  }

  public async removeAll(options?: IAdditional) {
    const response = await AmauiService.sdk[this.route as 'signIns']?.removeAll(options);

    AuthService.me();

    return response;
  }

}

export default new SignInService();
