import React from 'react';
import { Helmet } from 'react-helmet';

import { debounce, is, stringToColor, textToInnerHTML, unique, wait } from '@amaui/utils';
import { AutoComplete, Avatar, Badge, Chip, FormRow, IconButton, Line, ListItem, MenuItem, Modal, ModalHeader, ModalMain, MoreOptions, Slide, Tab, Tabs, TextField, Tooltip, Type, useConfirm, useForm, useLocation, useMediaQuery, useSnackbars } from '@amaui/ui-react';
import { classNames, style, useAmauiTheme } from '@amaui/style-react';
import { IChat, IMessage, User } from '@amaui/api-utils';
import { AmauiDate } from '@amaui/date';

import IconMaterialEditRounded from '@amaui/icons-material-rounded-react/IconMaterialEdit';
import IconMaterialArrowOutwardRounded from '@amaui/icons-material-rounded-react/IconMaterialArrowOutward';
import IconMaterialGroupsRounded from '@amaui/icons-material-rounded-react/IconMaterialGroups';
import IconMaterialOpenInNewRounded from '@amaui/icons-material-rounded-react/IconMaterialOpenInNew';
import IconMaterialArchiveRounded from '@amaui/icons-material-rounded-react/IconMaterialArchive';
import IconMaterialUnarchiveRounded from '@amaui/icons-material-rounded-react/IconMaterialUnarchive'
import IconMaterialChatRounded from '@amaui/icons-material-rounded-react/IconMaterialChat';
import IconMaterialForumRounded from '@amaui/icons-material-rounded-react/IconMaterialForum';
import IconMaterialArrowBackRounded from '@amaui/icons-material-rounded-react/IconMaterialArrowBack';
import IconMaterialDeleteRounded from '@amaui/icons-material-rounded-react/IconMaterialDelete';
import IconMaterialCloseRounded from '@amaui/icons-material-rounded-react/IconMaterialClose';

import { Button, List, Popup, useSubscription } from 'ui';
import { IQuerySubscription, ISignedIn, LOAD_MORE_LIMIT, getErrorMessage, getParamID, menuItemProps } from 'other';
import { AuthService, ChatService, OrganizationService, SocketService } from 'services';
import Chat from './Chat';

const useStyle = style(theme => ({
  root: {
    '& .amaui-Badge-badge': {
      padding: 0
    }
  },

  header: {
    padding: '4px 16px',
    flex: '0 0 auto'
  },

  modalMainRoot: {
    height: 0,
    padding: 0,
    overflow: 'hidden auto'
  },

  modalHeader: {
    '&.amaui-ModalHeader-root': {
      padding: 0
    }
  },

  surface: {
    padding: 0
  },

  sidebar: {
    paddingTop: 4,
    width: 270
  },

  sidebar_mobile: {
    paddingTop: 4
  },

  noObjects: {
    '&.amaui-Type-root': {
      padding: '32px 16px',
      userSelect: 'none'
    }
  },

  badge: {
    '&.amaui-Badge-root': {
      '& .amaui-Badge-badge': {
        bottom: '6px',
        right: '2px',
        border: `2px solid ${theme.palette.light ? theme.palette.color.primary[99] : theme.palette.color.primary[5]}`,
        height: 11,
        width: 11,
        borderRadius: '50%'
      }
    }
  },

  badgeLarge: {
    '&.amaui-Badge-root': {
      '& .amaui-Badge-badge': {
        bottom: '6px',
        right: '2px',
        border: `2px solid ${theme.palette.light ? theme.palette.color.primary[99] : theme.palette.color.primary[5]}`,
        height: 13,
        width: 13,
        borderRadius: '50%'
      }
    }
  },

  listChats: {
    flex: '1 1 auto',
    height: 0,
    overflow: 'hidden auto'
  },

  chatItem: {
    '& .amaui-ListItem-middle': {
      width: 0
    }
  },

  chatText: {
    '&.amaui-Type-root': {
      wordBreak: 'break-word',
      whiteSpace: 'nowrap',
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  },

  avatars: {
    position: 'relative',
    width: 40,
    height: 40,

    '& > *:nth-child(1)': {
      position: 'absolute',
      top: 0,
      left: 0,

      '& .amaui-Avatar-root': {
        border: `2px solid ${theme.palette.light ? theme.palette.color.primary[99] : theme.palette.color.primary[5]}`
      }
    },

    '& > *:nth-child(2)': {
      position: 'absolute',
      bottom: 0,
      right: 0,

      '& .amaui-Avatar-root': {
        border: `2px solid ${theme.palette.light ? theme.palette.color.primary[99] : theme.palette.color.primary[5]}`
      }
    }
  },

  chatWrapper: {
    padding: '0 0 12px'
  },

  chatHeader: {

  },

  newChatHeader: {
    padding: '0 8px'
  },

  messagesInvisible: {
    opacity: 0
  },

  messagesVisible: {
    opacity: 1
  },

  chatOptions: {
    padding: '4px 0 0'
  },

  chatOptions_mobile: {
    padding: '4px 8px 0'
  },

  updateChat: {
    padding: '16px 24px'
  }
}), { name: 'amaui-app-route-Chats' });

const Chats = React.forwardRef((props: any, ref: any) => {
  const {
    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const theme = useAmauiTheme();
  const touch = useMediaQuery('(pointer: coarse)');
  const snackbars = useSnackbars();
  const location = useLocation();
  const confirm = useConfirm();

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const queryUsersChat = useSubscription<IQuerySubscription>(OrganizationService.queryUsersChat);
  const queryChats = useSubscription<IQuerySubscription>(ChatService.queryChats);
  const queryMessages = useSubscription<IQuerySubscription>(ChatService.queryMessages);
  const online = useSubscription<string[]>(SocketService.online);
  // chat
  const socketsChatAdd = useSubscription<IChat>(SocketService.chatAdd);
  const socketsChatUpdate = useSubscription<IChat>(SocketService.chatUpdate);
  const socketsChatArchive = useSubscription<IChat>(SocketService.chatArchive);
  const socketsChatRemove = useSubscription<IChat>(SocketService.chatRemove);
  // message
  const socketsMessageAdd = useSubscription<IMessage>(SocketService.messageAdd);
  const socketsMessageRemove = useSubscription<IMessage>(SocketService.messageRemove);
  // message reaction
  const socketsMessageReactionAdd = useSubscription<IMessage>(SocketService.messageReactionAdd);
  const socketsMessageReactionRemove = useSubscription<IMessage>(SocketService.messageReactionRemove);

  const [open, setOpen] = React.useState<any>(false);
  const [tab, setTab] = React.useState<'chats' | 'people'>('chats');
  const [chat, setChat] = React.useState<IChat>();
  const [newChat, setNewChat] = React.useState<any>();
  const [replyTo, setReplyTo] = React.useState<IMessage>();
  const [loading, setLoading] = React.useState<any>(false);
  const [optionsUsers, setOptionsUsers] = React.useState([]);
  const [chats, setChats] = React.useState<IChat[]>([]);
  const [messages, setMessages] = React.useState<IMessage[]>([]);
  const [chatVersion, setChatVersion] = React.useState<'my' | 'archived' | 'unclaimed'>('my');
  const [chatUpdate, setChatUpdate] = React.useState(false);
  const [messagesVisible, setMessagesVisible] = React.useState(false);

  const participantMe = chat?.participants?.find(item => item?.id === signedIn?.user?.id);

  const form = useForm({
    values: {
      name: {
        name: 'Name',
        is: 'string'
      }
    },

    autoValidate: true
  });

  const refs = {
    inputRef: React.useRef<any>(),
    online: React.useRef(online),
    newChat: React.useRef(newChat),
    chat: React.useRef(chat),
    chats: React.useRef(chats),
    messages: React.useRef(messages),
    replyTo: React.useRef(replyTo),
    signedIn: React.useRef(signedIn),
    queryChats: React.useRef(queryChats),
    queryMessages: React.useRef(queryMessages),
    participantMe: React.useRef(participantMe),
    form: React.useRef(form),
    chatVersion: React.useRef(chatVersion),
    chatsElement: React.useRef<HTMLDivElement>(),
    messagesElement: React.useRef<HTMLDivElement>(),
    loading: React.useRef(loading),
    timeout: React.useRef<any>(),
    timeoutMessagesVisible: React.useRef<any>()
  };

  refs.form.current = form;

  refs.online.current = online;

  refs.newChat.current = newChat;

  refs.chat.current = chat;

  refs.chats.current = chats;

  refs.messages.current = messages;

  refs.replyTo.current = replyTo;

  refs.signedIn.current = signedIn;

  refs.queryChats.current = queryChats;

  refs.queryMessages.current = queryMessages;

  refs.participantMe.current = participantMe;

  refs.chatVersion.current = chatVersion;

  refs.loading.current = loading;

  const onOpen = React.useCallback(() => {
    setOpen(true);
  }, []);

  const onClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  const init = React.useCallback(async () => {
    let result = await OrganizationService.queryUsersChat.value!.query();

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }

    result = await ChatService.queryChats.value!.query({
      query: {
        query: {
          archived: false
        }
      }
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      const chats_ = result.response.response || [];

      const id = getParamID();

      if (id) {
        if (id === 'new') {
          setTab('people');
        }
        else {
          const chat = chats_.find((item: IChat) => id === item.id) || (id && (await ChatService.get(id)).response?.response);

          onChatClick(chat);
        }
      }
    }
  }, []);

  React.useEffect(() => {
    // init
    init();
  }, []);

  React.useEffect(() => {
    if (!messagesVisible) {
      clearTimeout(refs.timeoutMessagesVisible.current);

      refs.timeoutMessagesVisible.current = setTimeout(() => {
        setMessagesVisible(true);
      }, 240);
    }

  }, [messages, messagesVisible]);

  React.useEffect(() => {
    if (location.pathname === '/chats' && refs.chat.current) {
      setChat(null as any);
    }
  }, [location]);

  React.useEffect(() => {
    if (newChat && window.location.pathname !== '/chats/new') {
      window.history.pushState(undefined, '', '/chats/new');
    }
  }, [newChat]);

  // chat
  // add
  React.useEffect(() => {
    if (socketsChatAdd?.id) {
      const chatsNew = unique([...(refs?.chats?.current || []), socketsChatAdd], 'id');

      chatsNew.sort((a, b) => b.newest_activity_at - a.newest_activity_at);

      setChats(chatsNew);
    }
  }, [socketsChatAdd]);

  // update
  React.useEffect(() => {
    if (socketsChatUpdate?.id) {
      const chatsNew = [...(refs?.chats?.current || [])];

      const index = chatsNew.findIndex(item => item.id === socketsChatUpdate.id);

      if (index > -1) {
        chatsNew[index] = socketsChatUpdate;

        setChats(chatsNew);
      }
    }
  }, [socketsChatUpdate]);

  // archive
  React.useEffect(() => {
    if (socketsChatArchive?.id) {
      const socketChatParticipant = socketsChatArchive?.participants?.find(item => item.id === refs.signedIn.current?.user?.id);

      if (!socketChatParticipant) return;

      // if chatVersion my
      // and chat participant unarchived add
      if (
        (refs.chatVersion.current === 'my' && socketChatParticipant.archived) ||
        (refs.chatVersion.current === 'archived' && !socketChatParticipant.archived)
      ) {
        const chatsNew = [...(refs?.chats?.current || [])];

        const index = chatsNew.findIndex(item => item.id === socketsChatArchive.id);

        if (index > -1) {
          if (refs.chat.current?.id === chatsNew[index]?.id) {
            const newRoute = '/chats';

            // update route
            if (newRoute !== window.location.pathname) window.history.pushState(undefined, '', newRoute);
          }

          chatsNew.splice(index, 1);

          setChats(chatsNew);
        }
      }
      // else remove the chat from chats
      else {
        const chatsNew = unique([...(refs?.chats?.current || []), socketsChatArchive], 'id');

        chatsNew.sort((a, b) => b.newest_activity_at - a.newest_activity_at);

        setChats(chatsNew);
      }
    }
  }, [socketsChatArchive]);

  // remove
  React.useEffect(() => {
    if (socketsChatRemove?.id) {
      const chatsNew = [...(refs?.chats?.current || [])];

      const index = chatsNew.findIndex(item => item.id === socketsChatRemove.id);

      if (index > -1) {
        if (refs.chat.current?.id === chatsNew[index]?.id) {
          const newRoute = '/chats';

          // update route
          if (newRoute !== window.location.pathname) window.history.pushState(undefined, '', newRoute);
        }

        chatsNew.splice(index, 1);

        setChats(chatsNew);
      }
    }
  }, [socketsChatRemove]);

  // message
  // add
  React.useEffect(() => {
    if (socketsMessageAdd?.id) {
      const messageChat = socketsMessageAdd?.chat;

      // if from currently open chat
      // update messages
      if (messageChat?.id === refs.chat.current?.id) {
        const messagesNew = unique([...(refs?.messages?.current || []), socketsMessageAdd], 'id');

        messagesNew.sort((a, b) => a.made_at - b.made_at);

        setMessages(messagesNew);
      }

      // update chat
      let chatIndex = refs.chats.current?.findIndex(item => item.id === messageChat?.id);

      // if chatVersion archived
      // remove chat from chats
      if (chatVersion === 'archived' && chatIndex > -1) {
        const chatsNew = [...(refs?.chats?.current || [])];

        if (refs.chat.current?.id === chatsNew[chatIndex]?.id) {
          const newRoute = '/chats';

          // update route
          if (newRoute !== window.location.pathname) window.history.pushState(undefined, '', newRoute);
        }

        chatsNew.splice(chatIndex, 1);

        setChats(chatsNew);
      }
      // if from one of the chats
      // update chat newest activity
      else {
        const chatsNew = unique([...(refs?.chats?.current || []), messageChat], 'id');

        chatIndex = chatsNew?.findIndex(item => item.id === messageChat?.id);

        if (chatIndex > -1) {
          chatsNew[chatIndex].newest_activity = {
            version: 'message',
            value: socketsMessageAdd?.value
          };

          chatsNew[chatIndex].newest_activity_at = socketsMessageAdd?.made_at || AmauiDate.milliseconds;
        }

        // newest message is at the bottom
        chatsNew.sort((a, b) => b.newest_activity_at - a.newest_activity_at);

        setChats(chatsNew);
      }

      setTimeout(() => {
        if (refs.messagesElement.current) refs.messagesElement.current.scrollTo(0, refs.messagesElement.current.scrollHeight);
      }, 14);
    }
  }, [socketsMessageAdd]);

  // remove
  React.useEffect(() => {
    if (socketsMessageRemove?.id) {
      const messagesNew = [...(refs?.messages?.current || [])];

      const index = messagesNew.findIndex(item => item.id === socketsMessageRemove.id);

      if (index > -1) {
        messagesNew.splice(index, 1);

        setMessages(messagesNew);
      }
    }
  }, [socketsMessageRemove]);

  // message reaction
  // add
  React.useEffect(() => {
    if (socketsMessageReactionAdd?.id) {
      const messagesNew = [...(refs?.messages?.current || [])];

      const index = messagesNew.findIndex(item => item.id === socketsMessageReactionAdd.id);

      if (index > -1) {
        messagesNew.splice(index, 1, socketsMessageReactionAdd);

        setMessages(messagesNew);
      }
    }
  }, [socketsMessageReactionAdd]);

  // remove
  React.useEffect(() => {
    if (socketsMessageReactionRemove?.id) {
      const messagesNew = [...(refs?.messages?.current || [])];

      const index = messagesNew.findIndex(item => item.id === socketsMessageReactionRemove.id);

      if (index > -1) {
        messagesNew.splice(index, 1, socketsMessageReactionRemove);

        setMessages(messagesNew);
      }
    }
  }, [socketsMessageReactionRemove]);

  const onMyQuery = React.useCallback(async () => {
    if (refs.chatVersion.current === 'my') return;

    setLoading('query');

    const result = await ChatService.queryChats.value!.query({
      query: {
        query: {
          archived: false
        }
      }
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }

    setChatVersion('my');

    setLoading(false);
  }, []);

  const onArchivedQuery = React.useCallback(async () => {
    if (chatVersion === 'archived') return;

    setLoading('query');

    const result = await ChatService.queryChats.value!.query({
      query: {
        query: {
          archived: true
        }
      }
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }

    setChatVersion('archived');

    setLoading(false);
  }, [chatVersion]);

  const onUnclaimedQuery = React.useCallback(async () => {
    if (chatVersion === 'unclaimed') return;

    setLoading('query');

    const result = await ChatService.queryChats.value!.query({
      query: {
        query: {
          claimed: false
        }
      }
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }

    setChatVersion('unclaimed');

    setLoading(false);
  }, [chatVersion]);

  const getMessages = React.useCallback(async (reset = false) => {
    setLoading('messages');

    if (refs.chat.current) {
      if (reset) queryMessages.reset();

      await ChatService.queryMessages.value?.query({
        id: refs.chat.current?.id,

        query: {
          query: {
            limit: ChatService.paginationLimit
          }
        }
      });

      setTimeout(() => {
        if (refs.messagesElement.current) refs.messagesElement.current.scrollTo(0, refs.messagesElement.current.scrollHeight);

        setTimeout(() => {
          setLoading(false);
        }, 140);
      }, 14);
    }
  }, [queryMessages]);

  const initForm = React.useCallback(() => {
    if (refs.chat.current) {
      form.onChange([
        ['name', refs.chat.current?.name || '']
      ]);
    }
  }, [form]);

  // new chat, get messages
  React.useEffect(() => {
    initForm();

    getMessages(true);
  }, [chat?.id]);

  const onChatsTabClick = React.useCallback(() => {
    window.history.pushState(undefined, '', '/chats');

    if (refs.chat.current) setChat(null as any);

    if (refs.newChat.current) setNewChat(null as any);
  }, []);

  const onUpdateChat = React.useCallback(async () => {
    const valid = await form.validate();

    if (!valid || !refs.chat.current) return;

    const body = {
      name: refs.form.current.value.name?.trim() || null
    };

    const result = await SocketService.updateChat(
      {
        id: refs.chat.current?.id
      },
      body
    );

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Chat updated`
      });
    }

    // reset
    // refs.queryChats.current!.reset();

    // await wait(14);

    // refetch
    refs.queryChats.current!.refetch();
  }, [form]);

  // new chats
  const updateChats = React.useCallback(() => {
    const chatsNew = unique(queryChats.response, 'id') as IChat[];

    // newest message is at the bottom
    chatsNew.sort((a, b) => b.newest_activity_at! - a.newest_activity_at!);

    setChats(chatsNew);
  }, [queryChats]);

  const checkChats = (queryChats?.response as IChat[])?.reduce((result, item) => result += item.id + item.name, '');

  React.useEffect(() => {
    updateChats();
  }, [checkChats]);

  // new messages
  const updateMessages = React.useCallback(() => {
    const messagesNew = unique(queryMessages.response, 'id') as IMessage[];

    // newest message is at the bottom
    messagesNew.sort((a, b) => a.made_at! - b.made_at!);

    const loadMore = queryMessages?.previousBody?.query?.next;

    if (!loadMore) setMessagesVisible(false);

    setMessages(messagesNew);
  }, [queryMessages]);

  const checkMessages = (queryMessages?.response as IMessage[])?.reduce((result, item) => result += item.id, '');

  React.useEffect(() => {
    updateMessages();
  }, [queryMessages, checkMessages]);

  const onChangeTab = React.useCallback((valueNew: any) => {
    setTab(valueNew);
  }, []);

  const tabs = React.useMemo(() => [
    { label: 'Chats', icon: IconMaterialChatRounded, value: 'chats', props: { onClick: onChatsTabClick } },
    { label: 'People', icon: IconMaterialGroupsRounded, value: 'people' }
  ], []);

  const onQueryUsers = React.useMemo(() => {
    return debounce(async (value: string, init = false) => {
      if (!init) return;

      setLoading('users');

      const response = await OrganizationService.queryUsersPost({
        query: {
          text: value
        }
      });

      if (response.status >= 400) {
        snackbars.add({
          color: 'error',
          primary: getErrorMessage(response)
        });
      }
      else {
        setOptionsUsers(response.response.response?.filter((item: any) => item.id !== refs.signedIn.current.user?.id).map((item: any) => ({ ...item, value: item.id })));
      }

      setLoading(false);
    }, 140);
  }, []);

  const onQueryNewChat = React.useCallback(async (participants: any[]) => {
    setLoading('new-chat-query');

    const newChatParticipants = participants;

    let chatNew!: IChat;

    // get chat from chats
    if (!!refs.chats.current?.length) {
      chatNew = refs.chats.current.find((item: any) => (
        newChatParticipants.every(participant => !!item?.participant?.find((participant_: any) => participant?.id === participant_?.id))
      ))!;
    }

    // if no chat, query chat with participants
    if (!chatNew) {
      const result = await ChatService.queryPost({
        query: {
          participants: [refs.signedIn.current.user.id, ...newChatParticipants.map(item => item?.id || item)],
          participants_number: newChatParticipants.length + 1,
          removed: true
        },

        limit: 1
      });

      if (result.status === 200) {
        if (!!result.response.response?.length) chatNew = result.response.response[0];
      }
    }

    // chat, messages
    setChat(chatNew || null);

    if (!chatNew) queryMessages.reset();
    else {
      setChatUpdate(false);
    }

    setLoading(false);
  }, [queryMessages]);

  const onUpdateParticipants = React.useCallback(async (valueNew: any) => {
    const newChatParticipants = valueNew;

    queryMessages.reset();

    await wait(14);

    if (newChatParticipants.length >= 1) await onQueryNewChat(valueNew);

    setNewChat((previous: any) => ({
      ...previous,

      participants: newChatParticipants
    }));
  }, [newChat, queryMessages]);

  const onUserClick = React.useCallback(async (user: User) => {
    // reset
    // chat
    setChat(null as any);

    queryMessages.reset();

    const newChatParticipants = [user];

    setNewChat((previous: any) => ({
      ...previous,

      participants: newChatParticipants?.map(item => ({ ...item, value: item.id }))
    }));

    setMessages([]);

    if (!!newChatParticipants.length) await onQueryNewChat(newChatParticipants);

    if (touch) onClose();
  }, [chat, newChat, queryChats, queryMessages, touch, onClose]);

  const onChatClick = React.useCallback((value: IChat) => {
    if (!value) return;

    if (refs.chat.current?.id !== value?.id) {
      setChat(value);
      setNewChat(null as any);
      setChatUpdate(false);
      setMessagesVisible(false);
    }

    const newRoute = `/chats/${value.id}`;

    // update route
    if (newRoute !== window.location.pathname) window.history.pushState(undefined, '', newRoute);

    if (touch) onClose();
  }, [touch, onClose]);

  const tabsUI = (
    <Tabs
      value={tab}

      onChange={onChangeTab}

      justify='center'

      size='small'
    >
      {tabs.map((item, index) => (
        <Tab
          key={index}

          value={item.value as any}

          name={(
            <Line
              gap={1}

              direction='row'

              align='center'
            >
              <item.icon
                size='small'
              />

              <Type
                version='l3'
              >
                {item.label}
              </Type>
            </Line>
          )}

          {...item.props}
        />
      ))}
    </Tabs>
  );

  const autoCompleteProps: any = {
    autoWidth: false,

    MenuProps: {
      portal: true
    }
  };

  const onClear = React.useCallback(() => {
    setReplyTo(null as any);
  }, []);

  const onMessageAdd = React.useCallback(async (version: any = 'text', valueNew: any = undefined) => {
    if (valueNew) {
      setLoading('add-message');

      // using sockets
      let chat_ = refs.chat.current!;

      try {
        if (refs.newChat.current) {
          if (!chat_) {
            const participants = refs.newChat.current?.participants?.map((item: any) => ({
              id: item.id,
              name: item.name
            }));

            // if no chat, make a new chat
            const responseAddChat = await SocketService.addChat({ participants });

            if (responseAddChat.status >= 400) {
              snackbars.add({
                color: 'error',
                primary: getErrorMessage(responseAddChat)
              });
            }

            //  on chat response
            chat_ = responseAddChat.response.response;

            refs.chat.current = chat_;

            setChat(chat_);

            setChatUpdate(false);
          }

          setNewChat(null as any);
        }

        // unclaimed
        if (refs.chat.current && !refs.chat.current?.claimed) {
          const resultClaim = await ChatService.claim(refs.chat.current?.id);

          if (resultClaim.status >= 400) {
            snackbars.add({
              color: 'error',
              primary: getErrorMessage(resultClaim)
            });
          }
          else {
            chat_ = {
              ...refs.chat.current,

              ...resultClaim.response.response,

              claimed: true
            };

            setChat(previous => ({
              ...previous,

              ...chat_
            }));

            // my chats query 
            onMyQuery();

            // refetch
            refs.queryChats.current!.refetch();

            // emit
            await SocketService.updateWebsiteClaim(resultClaim.response.response);
          }
        }

        if (chat_) {
          // add a new message
          const messageBody: Partial<IMessage> = {
            version,
            value: is('string', valueNew) ? [
              {
                value: valueNew,
                version: 'type'
              }
            ] : valueNew,
            made_at: AmauiDate.milliseconds
          };

          if (refs.replyTo.current) messageBody.reply_to = {
            id: refs.replyTo.current.id
          };

          const responseAddMessage = await SocketService.addMessage(chat_, messageBody);

          if (responseAddMessage.status >= 400) {
            snackbars.add({
              color: 'error',
              primary: getErrorMessage(responseAddMessage)
            });
          }
          else {
            onClear();

            setTab('chats');

            setLoading(false);

            return true;
          }
        }
      }
      catch (error) {
        console.log('onMessageAdd', error);
      }

      setTab('chats');

      setLoading(false);
    }
  }, [replyTo]);

  const onRemoveMessage = React.useCallback(async (value: IMessage) => {
    const result = await SocketService.removeMessage({ id: refs.chat.current!.id, participants: refs.chat.current?.participants }, { id: value.id });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Message removed`
      });
    }

    // reset
    // refs.queryChats.current!.reset();

    // await wait(14);

    // refetch
    // refs.queryMessages.current!.refetch();
  }, []);

  const getMenuItemsMessage = (item: any, header = false) => {
    const IconProps: any = {
      color: 'inherit',
      size: 'small'
    };

    const values: any = [
      { primary: 'Remove', onClick: () => onRemoveMessage(item), start: <IconMaterialDeleteRounded {...IconProps} color='error' /> }
    ];

    return values.map((item_: any, index: number) => (
      <MenuItem
        key={index}

        {...menuItemProps(item_)}
      />
    ));
  };

  const onReactionAdd = React.useCallback(async (valueNew: IMessage, valueReaction: string) => {
    // unclaimed
    if (!refs.chat.current?.claimed) {
      const resultClaim = await ChatService.claim(refs.chat.current?.id);

      if (resultClaim.status >= 400) {
        snackbars.add({
          color: 'error',
          primary: getErrorMessage(resultClaim)
        });
      }
      else {
        setChat(previous => ({
          ...previous,

          claimed: true
        }));

        // refetch
        refs.queryChats.current!.refetch();

        // emit
        await SocketService.updateWebsiteClaim(resultClaim.response.response);
      }
    }

    const result = await SocketService.addMessageReaction(refs.chat.current!, valueNew, { value: valueReaction });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
  }, []);

  const onReactionRemove = React.useCallback(async (valueNew: IMessage, valueReaction: string) => {
    const result = await SocketService.removeMessageReaction(refs.chat.current!, valueNew, { value: valueReaction });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else { }
  }, []);

  const onReplyTo = React.useCallback((valueNew: IMessage) => {
    setReplyTo(valueNew);
  }, []);

  const onLoadMoreChats = React.useCallback(async () => {
    setLoading('query');

    const result = await ChatService.queryChats.value!.query({
      id: refs.chat.current?.id,

      query: {
        ...ChatService.queryChats.value!.previousQuery,

        next: refs.queryChats.current?.pagination?.next,
        previous: undefined,
        skip: undefined,
        total: undefined,

        loadMore: true
      }
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }

    setLoading(false);
  }, []);

  const loadMoreChats = React.useCallback(debounce(() => {
    const chats = refs.chatsElement.current as HTMLElement;

    const limit = chats.scrollTop;

    if (limit <= LOAD_MORE_LIMIT) {
      if (!refs.queryChats.current?.pagination?.hasNext) return;

      // If not in loading process at the moment
      if (!refs.loading.current) {
        clearTimeout(refs.timeout.current);

        refs.timeout.current = setTimeout(onLoadMoreChats, 14);
      }
    }
  }, 14), []);

  const onScrollChats = React.useCallback(loadMoreChats, []);

  const onLoadMore = React.useCallback(async () => {
    setLoading('query');

    const result = await ChatService.queryMessages.value!.query({
      id: refs.chat.current?.id,

      query: {
        ...ChatService.queryMessages.value!.previousQuery,

        next: refs.queryMessages.current?.pagination?.next,
        previous: undefined,
        skip: undefined,
        total: undefined,

        loadMore: true
      }
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }

    setLoading(false);
  }, []);

  const loadMore = React.useCallback(debounce(() => {
    const messages = refs.messagesElement.current as HTMLElement;

    const limit = messages.scrollTop;

    if (limit <= LOAD_MORE_LIMIT) {
      if (!refs.queryMessages.current?.pagination?.hasNext) return;

      // If not in loading process at the moment
      if (!refs.loading.current) {
        clearTimeout(refs.timeout.current);

        refs.timeout.current = setTimeout(onLoadMore, 14);
      }
    }
  }, 14), []);

  const onScroll = React.useCallback(loadMore, []);

  const onArchive = React.useCallback(async (value: IChat) => {
    const participantMeChat = value?.participants?.find((item: any) => item?.id === refs.signedIn.current?.user?.id);

    const result = await SocketService.archiveChat(
      {
        id: value?.id
      },
      {
        archived: !participantMeChat!.archived
      }
    );

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Chat ${participantMeChat!.archived ? 'unarchived' : 'archived'}`
      });
    }

    // reset
    // refs.queryChats.current!.reset();

    // await wait(14);

    // refetch
    // refs.queryChats.current!.refetch();
  }, []);

  const onRemove = React.useCallback(async (value: IChat) => {
    if (!(await confirm.open({ name: 'Removing a chat' }))) return;

    const result = await SocketService.removeChat(
      {
        id: value?.id
      },
      {
        removed: true
      }
    );

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Chat removed`
      });
    }

    // reset
    // refs.queryChats.current!.reset();

    // await wait(14);

    // refetch
    // refs.queryChats.current!.refetch();
  }, []);

  const getMenuItems = (item: any, header = false) => {
    const participantMeChat = item?.participants?.find((item: any) => item?.id === signedIn?.user?.id);

    const IconProps: any = {
      color: 'inherit',
      size: 'small'
    };

    const values: any = [
      ...(!header ?
        [
          { primary: 'Open', onClick: () => onChatClick(item), start: <IconMaterialOpenInNewRounded {...IconProps} /> }
        ] :
        [
          { primary: 'Edit', onClick: () => setChatUpdate(true), start: <IconMaterialEditRounded {...IconProps} /> }
        ]
      ),

      { primary: participantMeChat?.archived ? 'Unarchive' : 'Archive', onClick: () => onArchive(item), start: item.archived ? <IconMaterialArchiveRounded {...IconProps} /> : <IconMaterialUnarchiveRounded {...IconProps} /> },

      { primary: 'Remove', onClick: () => onRemove(item), start: <IconMaterialDeleteRounded {...IconProps} color='error' /> }
    ];

    return values.map((item_: any, index: number) => (
      <MenuItem
        key={index}

        {...menuItemProps(item_)}
      />
    ));
  };

  const getChatUI = React.useCallback((value: IChat, index: number, main = false) => {
    if (!value) return null;

    const participants = value.participants;

    const participantsOther = participants!.filter(item => item.id !== refs.signedIn.current.user.id);

    const group = participantsOther.length > 1;

    const first = participantsOther[0];

    const firstName = first?.name! || 'User';

    const avatar = !group ? (
      <Badge
        color='success'

        vertical='bottom'

        indicator={refs.online.current?.includes(first?.id)}

        element={value.origin === 'external' && (
          <IconButton
            color='primary'

            version='filled'

            size='small'

            style={{
              border: 'none'
            }}
          >
            <IconMaterialArrowOutwardRounded
              size='very small'
            />
          </IconButton>
        )}

        className={classes.badgeLarge}
      >
        <Avatar
          color={stringToColor(firstName)}
        >
          {firstName?.slice(0, 2)}
        </Avatar>
      </Badge>
    ) : (
      <Line
        className={classes.avatars}
      >
        {participantsOther.slice(0, 2).map((item, index_) => (
          <span
            key={index_}
          >
            <Badge
              color='success'

              vertical='bottom'

              indicator={refs.online.current?.includes(item?.id)}

              className={classes.badge}
            >
              <Avatar
                color={stringToColor(item.name!)}

                size='small'
              >
                {item.name?.slice(0, 2)}
              </Avatar>
            </Badge>
          </span>
        ))}
      </Line>
    );

    const propsChat: any = {};

    const mainProps: any = {};
    const asideStartProps: any = {};

    if (main) {
      propsChat['Component'] = 'div';
    }
    else {
      mainProps['onClick'] = () => onChatClick(value);

      asideStartProps['onClick'] = () => onChatClick(value);

      propsChat['button'] = true;
      propsChat['selected'] = value.id === refs.chat.current?.id;
    }

    return (
      <ListItem
        key={index}

        start={avatar}

        startAlign='center'

        primary={(
          <Type
            version='b2'

            className={classes.chatText}
          >
            {value.name || participantsOther.map(item => item.name || item.id).join(', ')}
          </Type>
        )}

        secondary={(
          <Type
            version='b3'

            priority='secondary'

            dangerouslySetInnerHTML={{
              __html: textToInnerHTML(value?.newest_activity?.value?.[0]?.value || '')
            }}

            className={classes.chatText}
          />
        )}

        end={(
          <MoreOptions
            menuItems={getMenuItems(value, main)}
          />
        )}

        size='small'

        MainProps={{
          ...mainProps
        }}

        AsideStartProps={{
          ...mainProps
        }}

        noBackground

        className={classes.chatItem}

        {...propsChat}
      />
    );
  }, []);

  const noChatUI = (
    <Line
      gap={1}

      align='center'

      justify='center'

      flex

      fullWidth
    >
      <IconMaterialChatRounded />

      <Type
        align='center'
      >
        Choose a chat or start a new one
      </Type>
    </Line>
  );

  const newChatUI = (
    <Chat
      new

      loading={loading}

      replyTo={replyTo}

      messages={messages}

      onMessageAdd={onMessageAdd}

      onReactionAdd={onReactionAdd}

      onReactionRemove={onReactionRemove}

      onReplyTo={onReplyTo}

      MessagesProps={{
        ref: refs.messagesElement,

        onScroll,

        MessageProps: {
          getMenuItemsMessage
        },

        className: classNames([
          classes[messagesVisible ? 'messagesVisible' : 'messagesInvisible']
        ])
      }}

      start={(
        <Line
          fullWidth

          className={classes.newChatHeader}
        >
          <AutoComplete
            name='Users'

            value={newChat?.participants}

            onChange={onUpdateParticipants}

            onChangeInput={(value: string) => onQueryUsers(value)}

            renderOption={(item: any, index: number, props: any) => (
              <ListItem
                key={index}

                {...props}

                startAlign='center'

                start={(
                  <Avatar
                    tonal

                    color='secondary'

                    size='small'
                  >
                    {item?.name?.slice(0, 1)}
                  </Avatar>
                )}

                primary={(
                  <Type
                    version='b2'
                  >
                    {item.name}
                  </Type>
                )}
              />
            )}

            renderChip={(item: any, valueChip: any, propsChip: any) => (
              <Chip
                {...propsChip}

                size='small'
              >
                {valueChip}
              </Chip>
            )}

            options={optionsUsers}

            chip

            multiple

            fullWidth

            clearInputOnSelect

            size='small'

            {...autoCompleteProps}
          />
        </Line>
      )}
    />
  );

  const onBackToChat = React.useCallback(() => {
    setChatUpdate(false);
  }, []);

  const chatMessagesUI = (
    <Chat
      loading={loading}

      replyTo={replyTo}

      messages={messages}

      onMessageAdd={onMessageAdd}

      onReactionAdd={onReactionAdd}

      onReactionRemove={onReactionRemove}

      onReplyTo={onReplyTo}

      MessagesProps={{
        ref: refs.messagesElement,

        onScroll,

        MessageProps: {
          getMenuItemsMessage
        },

        className: classNames([
          messagesVisible && classes.messagesVisible
        ])
      }}

      start={(
        <Line
          fullWidth

          className={classes.chatHeader}
        >
          {getChatUI(chat!, 0, true)}
        </Line>
      )}

      Messages={chatUpdate && (
        <Line
          flex

          fullWidth

          className={classes.updateChat}
        >
          <Tooltip
            name='Back to chat'

            position='right'
          >
            <IconButton
              onClick={onBackToChat}
            >
              <IconMaterialArrowBackRounded />
            </IconButton>
          </Tooltip>

          <FormRow
            name='Chat name'

            description={`Name you will see, instead of participants's names`}
          >
            <TextField
              name='Name'

              valueDefault={form.values['name'].value}

              error={!!form.values['name'].error}

              helperText={form.values['name'].error}

              onChange={(valueNew: any) => form.onChange('name', valueNew, undefined, { rerenderOnUpdate: false })}
            />
          </FormRow>
        </Line>
      )}

      Input={chatUpdate && (
        <Line
          direction='row'

          align='center'

          justify='flex-end'

          fullWidth
        >
          <Button
            onClick={onUpdateChat}

            disabled={!form.valid}
          >
            Update
          </Button>
        </Line>
      )}
    />
  );

  const chatUI = (!newChat && !chat) ? noChatUI : newChat ? newChatUI : chatMessagesUI;

  const chatOptionsUI = (
    <Line
      gap={1}

      direction='row'

      align='center'

      justify='flex-end'

      fullWidth

      className={classNames([
        classes[touch ? 'chatOptions_mobile' : 'chatOptions']
      ])}
    >
      <Chip
        onClick={onMyQuery}

        selected={chatVersion === 'my'}

        size='small'
      >
        My
      </Chip>

      <Chip
        onClick={onArchivedQuery}

        selected={chatVersion === 'archived'}

        size='small'
      >
        Archived
      </Chip>

      <Chip
        onClick={onUnclaimedQuery}

        selected={chatVersion === 'unclaimed'}

        size='small'
      >
        Unclaimed
      </Chip>
    </Line>
  );

  const chatsSidebar = queryChats.loaded && (!chats?.length ? (
    <Line
      gap={0}

      align='center'

      flex

      fullWidth
    >
      {chatOptionsUI}

      <Type
        version='b3'

        align='center'

        className={classes.noObjects}
      >
        No chats 🌱
      </Type>
    </Line>
  ) : (
    <Line
      gap={0}

      flex

      fullWidth
    >
      {chatOptionsUI}

      <List
        ref={refs.chatsElement}

        onScroll={onScrollChats}

        className={classes.listChats}

        noBackground
      >
        {chats?.map((item, index) => getChatUI(item, index))}
      </List>
    </Line>
  ));

  const users = queryUsersChat.response?.filter((item: any) => item.id !== signedIn.user?.id);

  const peopleSidebar = queryUsersChat.loaded && (!queryUsersChat.response?.length ? (
    <Type
      version='b3'

      align='center'

      className={classes.noObjects}
    >
      No users 😕
    </Type>
  ) : (
    <List
      noBackground
    >
      {users?.map((user: User, index: number) => (
        <ListItem
          key={index}

          onClick={() => onUserClick(user)}

          start={(
            <Badge
              color='success'

              vertical='bottom'

              indicator={online?.includes(user?.id)}

              className={classes.badge}
            >
              <Avatar
                color={stringToColor(user.name!)}

                size='small'
              >
                {user.name?.slice(0, 1)}
              </Avatar>
            </Badge>
          )}

          primary={(
            <Type
              version='b3'
            >
              {user.name}
            </Type>
          )}

          size='small'

          button
        />
      ))}
    </List>
  ));

  const mainSidebar = (
    <Line
      gap={0}

      justify='unset'

      align='unset'

      fullWidth

      flex

      className={classNames([
        classes[touch ? 'sidebar_mobile' : 'sidebar']
      ])}
    >
      {tab === 'chats' ? chatsSidebar : peopleSidebar}
    </Line>
  );

  const modal = (
    <Modal
      open={open}

      onClose={onClose}

      TransitionComponent={Slide}

      SurfaceProps={{
        tonal: true,
        color: 'primary',

        className: classNames([
          classes.surface
        ])
      }}

      fullScreen
    >
      <ModalHeader
        gap={0}

        className={classes.modalHeader}
      >
        <Line
          direction='row'

          justify='flex-end'

          align='center'

          fullWidth

          style={{
            padding: '12px 12px 0'
          }}
        >
          <Tooltip
            name='Close'
          >
            <IconButton
              onClick={onClose}

              style={{
                alignSelf: 'flex-end'
              }}
            >
              <IconMaterialCloseRounded />
            </IconButton>
          </Tooltip>
        </Line>

        {tabsUI}
      </ModalHeader>

      <ModalMain
        align='center'

        justify='unset'

        fullWidth

        className={classes.modalMainMenu}

        flex
      >
        <Line
          align='flex-start'

          justify='unset'

          flex

          fullWidth

          className={classes.modalMainRoot}
        >
          {mainSidebar}
        </Line>
      </ModalMain>
    </Modal>
  );

  const sidebar = (
    <Line
      gap={0}

      align='unset'

      justify='unset'

      flex

      fullWidth

      className={classNames([
        'amaui-Chats-sidebar',
        classes.sidebar
      ])}
    >
      {tabsUI}

      <Line
        flex

        fullWidth

        className={classes.chats}
      >
        {mainSidebar}
      </Line>
    </Line>
  );

  return <>
    <Helmet>
      <title>Chats</title>
      <link rel='icon' type='image/svg' sizes='32x32' href={`/assets/svg/logos/${theme.palette.light ? 'light' : 'dark'}/logo-chat.svg`} />
      <link rel='icon' type='image/svg' sizes='16x16' href={`/assets/svg/logos/${theme.palette.light ? 'light' : 'dark'}/logo-chat.svg`} />
      <meta name='theme-color' content={theme.palette.light ? (theme.palette.color.chat as any).main : theme.palette.color.chat[20]} />
    </Helmet>

    <Popup
      version='chat'
    />

    <Line
      ref={ref}

      gap={0}

      direction='column'

      justify='unset'

      align='unset'

      flex

      fullWidth

      className={classNames([
        className,
        classes.root
      ])}

      {...other}
    >
      {touch && (
        <Line
          direction='row'

          justify='flex-end'

          flex

          fullWidth

          className={classes.header}
        >
          <Tooltip
            name='Chats'
          >
            <IconButton
              onClick={onOpen}
            >
              <IconMaterialForumRounded />
            </IconButton>
          </Tooltip>
        </Line>
      )}

      <Line
        gap={0}

        direction='row'

        justify='unset'

        align='unset'

        flex

        fullWidth

        className={classes.main}
      >
        {!touch && sidebar}

        <Line
          gap={0}

          justify='unset'

          align='unset'

          fullWidth

          flex
        >
          {chatUI}
        </Line>
      </Line>

      {touch && modal}
    </Line>
  </>;
});

export default Chats;
