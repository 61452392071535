import React from 'react';

import { decodeObjectValue, encodeObjectValue, is } from '@amaui/utils';
import { useForm, useSnackbars } from '@amaui/ui-react';
import { ITemplate } from '@amaui/api-utils';

import { ModalForm, SelectAny, TextField } from 'ui';
import { AppService, TemplateService } from 'services';
import { getErrorMessage } from 'other';

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    version,

    object: object_,

    website,

    onConfirm
  } = props;

  const snackbars = useSnackbars();

  const [object, setObject] = React.useState(object_);
  const [loading, setLoading] = React.useState(false);

  const form = useForm({
    values: {
      'name': {
        name: 'Name',
        value: object?.name,
        required: true,
        max: 1400,
        messages: {
          min: 'Name has to be min 1 characters',
          max: 'Name can be max 1400 characters'
        }
      },
      'value': {
        name: 'Value',
        value: (object?.value || []).map((item: any) => ({
          ...item,

          props: decodeObjectValue(item.props)
        })),
        is: 'array'
      },
      'version': {
        name: 'Version',
        value: version || 'page',
        in: ['page', 'section']
      }
    }
  });

  const refs = {
    form: React.useRef(form)
  };

  refs.form.current = form;

  const onClose = React.useCallback(() => {
    AppService.pages.addSecondary.emit({
      ...AppService.pages.addSecondary.value,

      open: false
    });
  }, []);

  const init = React.useCallback(() => {
    if (object?.id) {
      refs.form.current.onChange([
        ['name', object?.name],
        ['version', version],
        ['value', (object?.value || []).map((item: any) => ({
          ...item,

          props: decodeObjectValue(item.props)
        }))]
      ]);
    }
  }, [object, version]);

  React.useEffect(() => {
    init();
  }, [object, version]);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();

    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const body: ITemplate = {
      ...refs.form.current.value,

      value: (refs.form.current.value.value || []).map((item: any) => ({
        ...item,

        props: is('string', item.props) ? item.props : encodeObjectValue(item.props)
      }))
    };

    if (website) {
      body.websites = [
        {
          id: website.id,
          name: website.name
        }
      ];
    }

    const result = await TemplateService.add(body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Template added`
      });

      setObject(result.response.response);

      if (is('function', onConfirm)) onConfirm();

      onClose();
    }

    setLoading(false);
  }, [object, form, onClose]);

  const modal: any = {
    write: <>
      <TextField
        name='Name'

        valueDefault={form.values['name'].value}

        error={form.values['name'].error}

        helperText={form.values['name'].error}

        onChange={(valueNew: any) => form.onChange('name', valueNew, undefined, { rerenderOnUpdate: false })}

        fullWidth
      />

      <SelectAny
        name='Version'

        options={['page', 'section']}

        valueDefault={form.values['version'].value}

        error={form.values['version'].error}

        helperText={form.values['version'].error}

        onChange={(valueNew: any) => form.onChange('version', valueNew)}
      />
    </>
  };

  return (
    <ModalForm
      {...props}

      name='Add template'

      add

      {...modal}

      onSubmit={onSubmit}

      onNext={onSubmit}

      onClose={onClose}

      loading={loading}

      smaller
    />
  );
});

export default Element;
