import React from 'react';

import { is } from '@amaui/utils';
import { Card, CardMain, Chip, Form, IconButton, Line, Modal, ModalFooter, ModalHeader, ModalMain, Tooltip, Type, useForm } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';

import IconMaterialAutoAwesomeRounded from '@amaui/icons-material-rounded-react/IconMaterialAutoAwesome';
import IconMaterialInterestsRounded from '@amaui/icons-material-rounded-react/IconMaterialInterests';
import IconMaterialToolsWrenchRounded from '@amaui/icons-material-rounded-react/IconMaterialToolsWrench';
import IconMaterialKeyboardArrowDownRounded from '@amaui/icons-material-rounded-react/IconMaterialKeyboardArrowDown';

import { Button, TextField } from 'ui';
import { Templates } from 'pages/main';
import { WebsiteService } from 'services';

const useStyle = style(theme => ({
  root: {

  },

  modalHeader: {
    '&.amaui-ModalHeader-root': {
      padding: 0
    }
  },

  modalMain: {
    position: 'relative',
    height: 0,
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: '24px',

    '& .amaui-TextField-border': {
      boxShadow: 'none'
    }
  },

  modalFooter: {
    padding: 12,
    oveflow: 'auto hidden',
    zIndex: 1
  },

  modalMainRoot: {
    maxWidth: theme.maxWidth
  },

  wrapper: {
    maxWidth: theme.maxWidth
  },

  padding: {
    padding: 12
  },

  noPadding: {
    '&.amaui-Surface-root': {
      padding: 0
    }
  },

  surface: {
    '&.amaui-Surface-root': {
      background: theme.palette.color.primary[theme.palette.light ? 99 : 5]
    }
  },

  card: {
    '&.amaui-Card-root': {
      width: '100%',
      outline: '0px solid',
      transition: theme.methods.transitions.make(['border-radius', 'outline'])
    }
  },

  selected: {
    outline: '2px solid !important',
    outlineColor: theme.methods.palette.color.value('secondary', 30)
  }
}), { name: 'amaui-app-PrePageBuilderModal' });

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    open,

    onAdd: onAdd_,

    onConfirm,

    onClose: onClose_,

    website,

    theme: objectTheme,

    onSelectTemplate,

    selectedTemplate,

    className,

    ...other
  } = props;

  const [version, setVersion] = React.useState<any>('custom');
  const [origin, setOrigin] = React.useState('my');
  const [selected, setSelected] = React.useState<any>(selectedTemplate);
  const [loading, setLoading] = React.useState(false);

  const { classes } = useStyle();

  const form = useForm({
    values: {
      input: {
        name: 'Input',
        min: 1,
        max: 240
      }
    }
  });

  const refs = {
    form: React.useRef(form)
  };

  refs.form.current = form;

  const onSelect = React.useCallback((item: any) => {
    const isSelected = selected?.id === item?.id;

    isSelected ? setSelected(null) : setSelected(item);

    if (is('function', onSelectTemplate)) onSelectTemplate(isSelected ? null : item);
  }, [selected, onSelectTemplate]);

  const onClose = React.useCallback(() => {
    setTimeout(() => {
      setVersion('custom');
      setOrigin('my');
      setSelected(null);
    }, 1400);

    if (is('function', onClose_)) onClose_();
  }, [onClose_]);

  const onNext = React.useCallback(() => {
    if (is('function', onConfirm)) onConfirm();

    if (is('function', onClose)) onClose();
  }, [onConfirm, onClose]);

  const onAdd = React.useCallback(async (input?: string) => {
    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    try {
      const input = refs.form.current.value.input;

      const result = await WebsiteService.addPageWithAI(website.id, { input });

      if (result.status >= 400) {

      }
      else {
        const response = result.response.response;

        if (is('function', onAdd_)) onAdd_(response);
      }
    }
    catch (error) { }

    setLoading(false);
  }, [form, website, onAdd_]);

  React.useEffect(() => {
    if (open) {
      refs.form.current.clear();
    }
  }, [open]);

  React.useEffect(() => {
    if (selectedTemplate?.id !== selected?.id) setSelected(selectedTemplate);
  }, [selectedTemplate]);

  const cards = [
    { name: 'Custom', value: 'custom', icon: IconMaterialToolsWrenchRounded },
    { name: 'Template', value: 'template', icon: IconMaterialInterestsRounded },

    ...(website ? [{ name: 'AI', value: 'ai', icon: IconMaterialAutoAwesomeRounded }] : [])
  ];

  const chipProps: any = {
    size: 'small'
  };

  return (
    <Modal
      open={open}

      onClose={onClose}

      minWidth='rg'

      fullScreen

      SurfaceProps={{
        tonal: true,
        color: 'primary',

        className: classNames([
          classes.surface,
          classes.noPadding
        ])
      }}

      className={classNames([
        className,
        classes.root
      ])}

      {...other}
    >
      <Line
        direction='row'

        justify='flex-end'

        align='center'

        className={classes.padding}
      >
        <Tooltip
          name='Close'
        >
          <IconButton
            color='inherit'

            onClick={onClose}
          >
            <IconMaterialKeyboardArrowDownRounded />
          </IconButton>
        </Tooltip>
      </Line>

      <ModalHeader
        className={classes.modalHeader}
      >
        {/* <ModalTitle>
          Select an option
        </ModalTitle> */}
      </ModalHeader>

      <ModalMain
        align='center'

        justify='unset'

        fullWidth

        className={classes.modalMain}
      >
        <Line
          align='center'

          justify='unset'

          flex

          fullWidth

          className={classes.modalMainRoot}
        >
          <Line
            gap={3}

            flex

            fullWidth
          >
            <Line
              gap={2}

              fullWidth
            >
              <Type
                version='t2'
              >
                Choose an option
              </Type>

              <Line
                gap={2}

                direction='row'

                align='center'

                wrap='wrap'

                fullWidth
              >
                {cards.map((item: any, index: number) => (
                  <Card
                    key={index}

                    color='default'

                    elevation={4}

                    onClick={() => setVersion(item.value)}

                    button

                    className={classNames([
                      classes.card,
                      item.value === version && classes.selected
                    ])}
                  >
                    <CardMain
                      gap={1.5}

                      align='center'
                    >
                      <item.icon
                        tonal

                        color='secondary'

                        size='large'
                      />

                      <Type
                        version='t2'

                        align='center'
                      >
                        {item.name}
                      </Type>
                    </CardMain>
                  </Card>
                ))}
              </Line>
            </Line>

            {version === 'template' && <>
              <Line
                gap={1}

                direction='row'

                align='center'
              >
                <Chip
                  onClick={() => setOrigin('my')}

                  selected={origin === 'my'}

                  {...chipProps}
                >
                  My templates
                </Chip>

                {objectTheme && (
                  <Chip
                    onClick={() => setOrigin('theme')}

                    selected={origin === 'theme'}

                    {...chipProps}
                  >
                    Theme
                  </Chip>
                )}
              </Line>

              <Templates
                version={origin === 'my' ? 'website' : 'theme'}

                theme={origin === 'theme' && objectTheme}

                page={false}

                select

                itemProps={(item: any) => ({
                  onClick: () => {
                    onSelect(item);
                  },

                  'data-selected': item?.id === selected?.id
                })}
              />
            </>}

            {website && version === 'ai' && <>
              <Form
                name='Make a page with AI'

                description='Describe what the page is about, and AI will make it for you. You can update it afterwards normally. '

                wrapper

                footer={(
                  <Button
                    onClick={onAdd}

                    loading={loading}
                  >
                    Make page with AI
                  </Button>
                )}
              >
                <TextField
                  name='What is the page about?'

                  placeholder='Making food'

                  value={form.values.input.value || ''}

                  onChange={(valueNew: any) => form.onChange('input', valueNew)}

                  error={!!form.values.input.error}

                  helperText={form.values.input.error}

                  fullWidth
                />
              </Form>
            </>}
          </Line>
        </Line>
      </ModalMain>

      <ModalFooter
        className={classes.modalFooter}
      >
        <Button
          version='text'

          color='inherit'

          size='small'

          onClick={onClose}
        >
          Close
        </Button>

        <Button
          size='small'

          onClick={onNext}
        >
          Next
        </Button>
      </ModalFooter>
    </Modal>
  );
});

export default Element;
