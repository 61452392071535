import React from 'react';
import { useNavigate } from 'react-router-dom';

import { hash } from '@amaui/utils';
import { IconButton, Line, List, ListItem, Tooltip, useLocation } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';

import IconMaterialContactsProductRounded from '@amaui/icons-material-rounded-react/IconMaterialContactsProduct';
import IconMaterialGroupsRounded from '@amaui/icons-material-rounded-react/IconMaterialGroups';
import IconMaterialEventAvailableRounded from '@amaui/icons-material-rounded-react/IconMaterialEventAvailable';
import IconMaterialViewListRounded from '@amaui/icons-material-rounded-react/IconMaterialViewList';
import IconMaterialEventRounded from '@amaui/icons-material-rounded-react/IconMaterialEvent';
import IconMaterialViewColumnRounded from '@amaui/icons-material-rounded-react/IconMaterialViewColumn';
import IconMaterialArticleRounded from '@amaui/icons-material-rounded-react/IconMaterialArticle';
import IconMaterialHelpCenterRounded from '@amaui/icons-material-rounded-react/IconMaterialHelpCenter';
import IconMaterialSunnyRounded from '@amaui/icons-material-rounded-react/IconMaterialSunny';
import IconMaterialCalendarMonthRounded from '@amaui/icons-material-rounded-react/IconMaterialCalendarMonth';
import IconMaterialNoteRounded from '@amaui/icons-material-rounded-react/IconMaterialNote';
import IconMaterialPersonRounded from '@amaui/icons-material-rounded-react/IconMaterialPerson';
import IconMaterialSpaceDashboardRounded from '@amaui/icons-material-rounded-react/IconMaterialSpaceDashboard';
import IconMaterialWbSunnyRounded from '@amaui/icons-material-rounded-react/IconMaterialWbSunny';
import IconMaterialWbTwilightRounded from '@amaui/icons-material-rounded-react/IconMaterialWbTwilight';
import IconMaterialCounter7Rounded from '@amaui/icons-material-rounded-react/IconMaterialCounter7';
import IconMaterialTaskAltRounded from '@amaui/icons-material-rounded-react/IconMaterialTaskAlt';
import IconMaterialCropPortraitRounded from '@amaui/icons-material-rounded-react/IconMaterialCropPortrait';
import IconMaterialFertileRounded from '@amaui/icons-material-rounded-react/IconMaterialFertile';
import IconMaterialAssignmentIndRounded from '@amaui/icons-material-rounded-react/IconMaterialAssignmentInd';
import IconMaterialAutoAwesomeMotionRounded from '@amaui/icons-material-rounded-react/IconMaterialAutoAwesomeMotion';
import IconMaterialAssignmentTurnedInRounded from '@amaui/icons-material-rounded-react/IconMaterialAssignmentTurnedIn';
import IconMaterialSettingsRounded from '@amaui/icons-material-rounded-react/IconMaterialSettings';
import IconMaterialImagesmodeRounded from '@amaui/icons-material-rounded-react/IconMaterialImagesmode';
import IconMaterialTagRounded from '@amaui/icons-material-rounded-react/IconMaterialTag';

import { AppService, AuthService } from 'services';
import { ISignedIn, addPagesOther } from 'other';
import { useSubscription } from 'ui';

const useStyle = style(theme => ({
  root: {
    position: 'relative',
    width: 72,
    height: '100%',
    padding: '14px 0',
    paddingBottom: 114,
    flex: '0 0 auto',
    // transition: theme.methods.transitions.make('width', { duration: 'sm' }),

    '& .amaui-NavigationItem-root': {
      position: 'relative'
    }
  },

  expanded: {
    width: 240
  }
}), { name: 'amaui-app-RailMain' });

export interface IRailMain {
  expand?: boolean;

  children?: any;
}

const RailMain = React.forwardRef((props: any, ref: any) => {
  const {
    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const navigate = useNavigate();
  const location = useLocation();

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const [expanded, setExpanded] = React.useState(false);

  const railMainMethod = (value: any, setResponse: any) => {
    if (value.expand !== undefined) setExpanded(value.expand);

    // if (value.children !== undefined) setChildren(value.children);
  };

  useSubscription<IRailMain>(AppService.pages.railMain, railMainMethod);

  const to = React.useCallback((value: string) => {
    navigate(value);
  }, []);

  const user = signedIn?.user;

  const is = signedIn?.user?.is;

  const pageMenus = React.useMemo(() => {

    return {
      task: is?.user ? [
        { label: 'Today', to: '/tasks', icon: <IconMaterialWbSunnyRounded /> },
        { label: 'Tomorrow', to: '/tasks/tomorrow', icon: <IconMaterialWbTwilightRounded /> },
        { label: 'Week', to: '/tasks/week', icon: <IconMaterialCounter7Rounded /> },
        { label: 'Month', to: '/tasks/month', icon: <IconMaterialCalendarMonthRounded /> },
        { label: 'Lists', to: '/tasks/lists', icon: <IconMaterialViewColumnRounded /> },
        { label: 'All', to: '/tasks/all', icon: <IconMaterialTaskAltRounded /> },
        { label: 'Assigned to me', to: '/tasks/assigned-to-me', icon: <IconMaterialPersonRounded /> }
      ] : [],
      note: is?.user ? [
        { label: 'My notes', to: '/notes', icon: <IconMaterialNoteRounded /> },
        { label: 'Mentions', to: '/notes/mentions', icon: <IconMaterialPersonRounded /> }
      ] : [],
      resume: is?.user ? [
        { label: 'Resumes', to: '/resumes', icon: <IconMaterialAssignmentIndRounded /> },

        { label: 'Portfolios', to: '/portfolios', icon: <IconMaterialAutoAwesomeMotionRounded /> },

        { label: 'Skills', to: '/skills', icon: <IconMaterialAssignmentTurnedInRounded /> }
      ] : [],
      website: is?.user ? [
        { label: 'Websites', to: '/websites', icon: <IconMaterialSpaceDashboardRounded /> },

        { label: 'Templates', to: '/templates', icon: <IconMaterialCropPortraitRounded /> },

        ...(is?.amaui ? [{ label: 'Themes', to: '/themes', icon: <IconMaterialFertileRounded /> }] : [])
      ] : [],
      support: is?.user ? [
        { label: 'Support pages', to: '/support-pages', icon: <IconMaterialHelpCenterRounded /> },

        { label: 'Support articles', to: '/support-articles', icon: <IconMaterialArticleRounded /> }
      ] : [],
      reservation: is?.user ? [
        { label: 'Reservations', to: '/reservations', icon: <IconMaterialEventAvailableRounded /> },

        { label: 'Availables', to: '/availables', icon: <IconMaterialEventRounded /> },

        { label: 'Reservation options', to: '/reservation-options', icon: <IconMaterialViewListRounded /> }
      ] : [],
      contact: is?.user ? [
        { label: 'Contacts', to: '/contacts', icon: <IconMaterialContactsProductRounded /> },

        { label: 'Contact groups', to: '/contact-groups', icon: <IconMaterialGroupsRounded /> }
      ] : []
    };
  }, [hash(is)]);

  let app = '';

  if (['/tasks'].some(item => location.pathname?.startsWith(item))) app = 'task';

  if (['/notes'].some(item => location.pathname?.startsWith(item))) app = 'note';

  if (['/resumes', '/portfolios', '/skills'].some(item => location.pathname?.startsWith(item))) app = 'resume';

  if (['/websites', '/templates', '/themes'].some(item => location.pathname?.startsWith(item))) app = 'website';

  if (['/support-pages', '/support-articles'].some(item => location.pathname?.startsWith(item))) app = 'support';

  if (['/availables', '/reservation-options', '/reservations'].some(item => location.pathname?.startsWith(item))) app = 'reservation';

  if (['/contacts', '/contact-groups'].some(item => location.pathname?.startsWith(item))) app = 'contact';

  let pages: any = (pageMenus as any)[app];

  if (!user?.is?.amaui) {
    pages = pages?.filter((page: any) => !['Themes'].includes(page.label));
  }

  const pagesOther = [
    ...(is?.user ? [{ label: 'Media', to: '/medias', icon: <IconMaterialImagesmodeRounded /> }] : []),

    ...(is?.user ? [{ label: 'Tags', to: '/tags', icon: <IconMaterialTagRounded /> }] : []),

    ...(user?.is?.admin ? [{ label: 'Organization settings', to: '/organization/settings', icon: <IconMaterialSettingsRounded /> }] : []),

    ...(user?.is?.amaui ? [{ label: 'Manage', to: '/manage/organizations', icon: <IconMaterialSunnyRounded /> }] : [])
  ];

  const Parent = expanded ? List : Line;

  const ParentProps: any = {
    gap: 1,
    fullWidth: true,

    ...(expanded ? {
      color: 'inherit',
      size: 'small'
    } : {
      align: 'center'
    })
  };

  const isSelected = (to: string) => {
    if (to === '/tasks') {
      return (
        location.pathname === '/tasks' ||
        location.pathname === '/tasks/add' ||
        !['all', 'today', 'tomorrow', 'week', 'lists', 'month', 'assigned-to-me'].some(item => location.pathname.startsWith(`/tasks/${item}`))
      );
    }

    if (to === '/notes') {
      return (
        location.pathname === '/notes' ||
        location.pathname === '/notes/add' ||
        !['mentions'].some(item => location.pathname.startsWith(`/notes/${item}`))
      );
    }

    for (const item of addPagesOther) {
      if (to === item) {
        return (
          location.pathname === item ||
          location.pathname === `${item}/add`
        );
      }
    }

    return location.pathname.startsWith(to);
  };

  const getItem = (item: any, index: number) => expanded ? (
    <ListItem
      key={index}

      color='inherit'

      onClick={() => to(item.to)}

      selected={isSelected(item.to)}

      primary={item.label}

      start={item.icon}

      shapePosition='end'

      button
    />
  ) : (
    <Tooltip
      key={index}

      name={item.label}

      position='right'
    >
      <IconButton
        color='inherit'

        onClick={() => to(item.to)}

        selected={isSelected(item.to)}
      >
        {item.icon}
      </IconButton>
    </Tooltip>
  );

  return (
    <Line
      ref={ref}

      direction='column'

      align='center'

      justify='space-between'

      className={classNames([
        className,
        classes.root,
        expanded && classes.expanded
      ])}

      {...other}
    >
      <span />

      {pages && (
        <Parent
          {...ParentProps}
        >
          {pages.map((item: any, index: number) => getItem(item, index))}
        </Parent>
      )}

      <Line
        gap={0}

        fullWidth
      >
        <Parent
          {...ParentProps}
        >
          {pagesOther.map((item: any, index: number) => getItem(item, index))}
        </Parent>
      </Line>
    </Line>
  );
});

export default RailMain;
