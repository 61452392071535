import React from 'react';

import { is } from '@amaui/utils';
import { FormRow, IconButton, Line, ListItem, Select, Tooltip, Type, useForm, useSnackbars } from '@amaui/ui-react';
import { ValidationError } from '@amaui/errors';

import IconMaterialRemove from '@amaui/icons-material-rounded-react/IconMaterialRemove';

import { AutoCompleteObjects, Button, List, ModalForm, SelectRoles, TextField } from 'ui';
import { AppService, InviteService, UserGroupService } from 'services';
import { getErrorMessage } from 'other';

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    onConfirm,

    onClose: onClose_
  } = props;

  const snackbars = useSnackbars();

  const [invites, setInvites] = React.useState<any>([]);
  const [loading, setLoading] = React.useState(false);

  const form = useForm({
    values: {
      'email': {
        name: 'Email',
        isValid: 'email',
        method: (value) => {
          const exists = refs.invites.current.find((item: any) => item?.to?.email === value?.trim());

          if (exists) throw new ValidationError(`You already added invite with this email`);
        },
        required: true
      },

      home_page: {
        name: 'Home page',
        is: 'string'
      },

      roles: {
        name: 'Roles',
        is: 'array',
        of: 'string',
        min: 1
      },

      user_groups: {
        name: 'User groups',
        is: 'array'
      }
    }
  });

  const refs = {
    invites: React.useRef<any>(),
    form: React.useRef(form)
  };

  refs.invites.current = invites;

  refs.form.current = form;

  const onClose = React.useCallback(() => {
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });

    if (is('function', onClose_)) onClose_();

    // clear 
    setTimeout(() => {
      refs.form.current.clear();
    }, 1400);
  }, [onClose_]);

  const onAdd = React.useCallback(async () => {
    const valid = await refs.form.current.validate();

    if (!(refs.form.current.values.email?.value && valid)) return;

    // add value to invites
    setInvites((previous: any) => {
      return [
        ...previous,

        { to: { email: refs.form.current.value.email.trim() } }
      ];
    });

    refs.form.current.onChange('email', null);
  }, []);

  const onAddRoles = React.useCallback((valueNew: any) => {
    refs.form.current.onChange('roles', valueNew);
  }, []);

  const onAddUserGroup = React.useCallback((valueNew: any) => {
    refs.form.current.onChange('user_groups', valueNew);
  }, []);

  const onRemove = React.useCallback((value: number) => {
    setInvites((previous: any) => {
      const invitesNew = [...previous];

      invitesNew.splice(value, 1);

      return invitesNew;
    });
  }, []);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();
  }, []);

  const onNext = React.useCallback(async () => {
    await refs.form.current.validate();

    setLoading(true);

    const value = refs.form.current.value;

    const result = await InviteService.addMany(
      {
        objects: invites.map((item: any) => ({
          ...item,

          ...(value.roles?.length && { roles: value.roles }),
          ...(value.user_groups?.length && { user_groups: value.user_groups })
        }))
      },
      {
        query: {
          home_page: value.home_page || ''
        }
      }
    );

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: 'Invites successfully sent!'
      });

      if (is('function', onConfirm)) onConfirm();

      onClose();
    }

    setLoading(false);
  }, [invites, onConfirm, onClose]);

  const modal: any = {
    write: <>
      <Line
        gap={1}

        fullWidth
      >
        <Line
          fullWidth
        >
          <Type
            version='b2'
          >
            Invite users to join your organization
          </Type>

          <Line
            fullWidth
          >
            <TextField
              name='Email'

              value={form.values['email'].value || ''}

              onChange={(valueNew: string) => form.onChange('email', valueNew)}

              endVerticalAlign='center'

              end={(
                <Button
                  color='primary'

                  size='small'

                  onClick={onAdd}
                >
                  Add email
                </Button>
              )}

              fullWidth
            />
          </Line>
        </Line>

        {!!invites.length && (
          <List
            paddingVertical='none'

            size='small'
          >
            {invites.map((item: any, index: number) => (
              <ListItem
                key={index}

                primary={item.to.email}

                size='small'

                end={(
                  <Tooltip
                    name='Remove'

                    color='secondary'
                  >
                    <IconButton
                      color='inherit'

                      size='small'

                      onClick={() => onRemove(index)}
                    >
                      <IconMaterialRemove />
                    </IconButton>
                  </Tooltip>
                )}
              />
            ))}
          </List>
        )}

        <Line
          fullWidth

          style={{
            marginTop: 8
          }}
        >
          <FormRow
            name='Roles'

            description='When users accept their invite, they will be automatically assigned these roles'
          >
            <SelectRoles
              value={refs.form.current.values.roles.value}

              onChange={onAddRoles}
            />
          </FormRow>

          <FormRow
            name='User groups'

            description='When users accept their invite, they will be automatically added to these user groups'
          >
            <AutoCompleteObjects
              name='User groups'

              value={refs.form.current.values.user_groups.value}

              onChange={onAddUserGroup}

              service={UserGroupService}

              multiple

              chip
            />
          </FormRow>

          <FormRow
            name='Home page'

            description={`Choose user's default home page`}
          >
            <Select
              name='Home page'

              value={form.values.home_page.value || ''}

              onChange={(valueNew: any) => form.onChange('home_page', valueNew)}

              options={[
                { name: 'Overview of apps', value: '' }
              ]}
            />
          </FormRow>
        </Line>
      </Line>
    </>
  };

  return (
    <ModalForm
      {...props}

      name='Invite'

      add

      {...modal}

      onSubmit={onSubmit}

      onNext={onNext}

      onClose={onClose}

      loading={loading}

      NextProps={{
        disabled: !invites.length
      }}

      smaller
    />
  );
});

export default Element;
