import React from 'react';

import { stringToColor } from '@amaui/utils';
import { Chip, Chips } from '@amaui/ui-react';
import { classNames, style, useAmauiTheme } from '@amaui/style-react';
import { ITag } from '@amaui/api-utils';

const useStyle = style(theme => ({
  root: {

  },

  root_items: {
    '&.amaui-Chips-root': {
      width: 140
    }
  },

  fullWidth: {
    '&.amaui-Chips-root': {
      width: '100%'
    }
  }
}), { name: 'amaui-app-Tags' });

const Tags = React.forwardRef((props: any, ref: any) => {
  const {
    form,

    value,

    fullWidth,

    className,

    children,

    ...other
  } = props;

  const theme = useAmauiTheme();

  const { classes } = useStyle();

  const onClickTag = React.useCallback((item: ITag) => {
    if (form) {
      form.onChange('tags', [
        {
          ...item,

          value: item.id || item._id
        }
      ]);
    }
  }, [form]);

  const tags = React.useMemo(() => {
    const items = (value || []);

    items.forEach((item: any) => {
      const palette = theme.methods.color(stringToColor(item.name));

      item.palette = palette;
      item.color = palette[50];
    });

    return items;
  }, [value]);

  return (
    <Chips
      ref={ref}

      gap={0.75}

      total={value?.length}

      max={3}

      justify='flex-end'

      wrap='wrap'

      size='small'

      AdditionalChipProps={{
        color: 'default'
      }}

      className={classNames([
        className,
        classes.root,
        fullWidth && classes.fullWidth,
        !!value?.length && !other.fullWidth && classes.root_items
      ])}

      {...other}
    >
      {tags.map((item: ITag) => {
        return (
          <Chip
            color={item.color}

            onClick={() => onClickTag(item)}

            style={{
              color: item.color,
              boxShadow: `${item.color} 0px 0px 0px 1px inset`
            }}
          >
            {item.name}
          </Chip>
        );
      })}
    </Chips>
  );
});

export default Tags;
