import { Notification } from '@amaui/api-utils';
import AmauiSubscription from '@amaui/subscription';
import { IAdditional, IResponse } from '@amaui/sdk/other';

import { BaseService } from './base';
import { IQuerySubscription, ISelectedSubscription } from 'other';
import { AmauiService } from 'services';

class NotificationService extends BaseService<Notification> {
  public queryNotifications = new AmauiSubscription<IQuerySubscription>();
  public selectedNotifications = new AmauiSubscription<ISelectedSubscription>();

  public get paginationLimit() { return 7; }

  public constructor() {
    super('notifications');

    this.queryObjectsInit(this.queryNotifications);

    this.selectedObjectsInit(this.selectedNotifications);
  }

  public async read(options?: IAdditional): Promise<IResponse> {
    return AmauiService.sdk.notifications.read(options);
  }

}

export default new NotificationService();
