import { Organization, User } from '@amaui/api-utils';
import { IAdditional } from '@amaui/sdk/other';
import { Query } from '@amaui/models';
import AmauiSubscription from '@amaui/subscription';

import { AmauiService } from 'services';
import { BaseService } from './base';
import { IQuerySubscription, ISelectedSubscription } from 'other';

class UserService extends BaseService<User> {
  public queryOrganizations = new AmauiSubscription<IQuerySubscription>();
  public selectedOrganizations = new AmauiSubscription<ISelectedSubscription>();
  public queryUsers = new AmauiSubscription<IQuerySubscription>();
  public selectedUsers = new AmauiSubscription<ISelectedSubscription>();
  public queryAuditLogs = new AmauiSubscription<IQuerySubscription>();
  public selectedAuditLogs = new AmauiSubscription<ISelectedSubscription>();
  public queryActivityLogs = new AmauiSubscription<IQuerySubscription>();
  public selectedActivityLogs = new AmauiSubscription<ISelectedSubscription>();

  public constructor() {
    super('manage');

    this.queryObjectsInit(this.queryOrganizations, 'queryOrganizationsPost');

    this.selectedObjectsInit(this.selectedOrganizations);

    this.queryObjectsInit(this.queryUsers, 'queryUsersPost');

    this.selectedObjectsInit(this.selectedUsers);

    this.queryObjectsInit(this.queryAuditLogs, 'queryAuditLogsPost');

    this.selectedObjectsInit(this.selectedAuditLogs);

    this.queryObjectsInit(this.queryActivityLogs, 'queryActivityLogsPost');

    this.selectedObjectsInit(this.selectedActivityLogs);
  }

  public async queryUsersPost(body?: Query, options?: IAdditional) {
    return AmauiService.sdk.manage.queryUsersPost(body, options);
  }

  public async getUser(id: string, options?: IAdditional) {
    return AmauiService.sdk.manage.getUser(id, options);
  }

  public async updateUser(id: string, body: Partial<User>, options?: IAdditional) {
    return AmauiService.sdk.manage.updateUser(id, body as any, options);
  }

  public async queryOrganizationsPost(body?: Query, options?: IAdditional) {
    return AmauiService.sdk.manage.queryOrganizationsPost(body, options);
  }

  public async getOrganization(id: string, options?: IAdditional) {
    return AmauiService.sdk.manage.getOrganization(id, options);
  }

  public async updateOrganization(id: string, body: Partial<Organization>, options?: IAdditional) {
    return AmauiService.sdk.manage.updateOrganization(id, body as any, options);
  }

  public async queryAuditLogsPost(body?: Query, options?: IAdditional) {
    return AmauiService.sdk.manage.queryAuditLogsPost(body, options);
  }

  public async getAuditLog(id: string, options?: IAdditional) {
    return AmauiService.sdk.manage.getAuditLog(id, options);
  }

  public async queryActivityLogsPost(body?: Query, options?: IAdditional) {
    return AmauiService.sdk.manage.queryActivityLogsPost(body, options);
  }

  public async getActivityLog(id: string, options?: IAdditional) {
    return AmauiService.sdk.manage.getActivityLog(id, options);
  }

}

export default new UserService();
