import React from 'react';

import { Form, FormRow, Line, SmartTextField, Type } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';
import { IMedia } from '@amaui/api-utils';

import IconMaterialImageRounded from '@amaui/icons-material-rounded-react/IconMaterialImage';

import { getMediaUrl, mediasToValue } from 'other';
import { useMedia } from '../../layout/Media';
import { textToInnerHTML } from '@amaui/utils';

const useStyle = style(theme => ({
  root: {

  },

  imagePreview: {
    width: 177,
    height: 100,
    backgroundColor: theme.palette.background.primary[theme.palette.light ? 'tertiary' : 'quaternary'],
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    borderRadius: 12,
    boxShadow: theme.shadows.values.default[1],
    cursor: 'pointer',
    userSelect: 'none',
    transition: theme.methods.transitions.make('transform', { duration: 'sm' }),

    '&:active': {
      transform: 'scale(0.97)'
    }
  },

  seoPreview: {
    width: 'clamp(0px, 670px, calc(100% - 48px))',
    padding: 24,
    borderRadius: 16,
    backgroundColor: theme.palette.background.default[theme.palette.light ? 'primary' : 'quaternary'],
    boxShadow: theme.shadows.values.default[1]
  },

  seoPreviewTitle: {
    fontFamily: 'arial, sans-serif',
    fontSize: 20,
    fontWeight: 400,
    color: theme.palette.light ? '#1a0dab' : '#8ab4f8'
  },

  seoPreviewDescription: {
    fontFamily: 'arial, sans-serif',
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.light ? '#4d5156' : '#bdc1c6'
  },

  seoPreviewImage: {
    width: 90,
    height: 92,
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    borderRadius: 8,
    flex: '0 0 auto'
  },

  seoPreviewDate: {
    color: theme.palette.light ? '#70757a' : '#9aa0a6'
  }
}), { name: 'amaui-app-OptionsSEO' });

const OptionsSEO = React.forwardRef((props: any, ref: any) => {
  const {
    app = 'amaui',

    values,

    onChange,

    className,

    children,

    ...other
  } = props;

  const { classes } = useStyle();

  const media = useMedia();

  const onSEOImageConfirm = React.useCallback((value: IMedia[]) => {
    const image = value[0];

    onChange('seo.image', !image ? null : mediasToValue({
      id: image.id,
      name: image.name,
      mime: image.mime,
      meta: image?.meta,
      versions: image?.versions
    }));
  }, [onChange]);

  const onOpenMedia = React.useCallback(() => {
    media.open({
      allowed: ['image'],
      filters: {
        mime: 'image'
      },
      selected: [values?.['seo.image']?.value].filter(Boolean),
      app,
      onConfirm: onSEOImageConfirm
    });
  }, [values, media, onSEOImageConfirm]);

  return (
    <Form
      ref={ref}

      name='SEO'

      description='Information for search engines to show your content in the search results properly'

      wrapper

      fullWidth

      className={classNames([
        className,
        classes.root
      ])}

      {...other}
    >
      <FormRow>
        <Line
          align='center'

          justify='center'

          onClick={onOpenMedia}

          style={{
            ...(values['seo.image'].value && {
              backgroundImage: `url(${getMediaUrl(values['seo.image'].value, 480)})`
            })
          }}

          className={classes.imagePreview}
        >
          {!values['seo.image'].value && (
            <IconMaterialImageRounded
              size={40}
            />
          )}
        </Line>

        <SmartTextField
          placeholder='Name'

          valueDefault={textToInnerHTML(values['seo.name']?.value)}

          onChange={(valueNew: any) => onChange('seo.name', valueNew)}

          error={!!values['seo.name'].error}

          helperText={values['seo.name'].error}

          additional={{
            version: 'h3'
          }}
        />

        <SmartTextField
          placeholder='Description'

          valueDefault={textToInnerHTML(values['seo.description']?.value || '')}

          onChange={(valueNew: any) => onChange('seo.description', valueNew)}

          error={!!values['seo.description'].error}

          helperText={values['seo.description'].error}

          minRows={4}

          multiline

          edit
        />
      </FormRow>

      <Line
        gap={2}

        fullWidth
      >
        <Type
          version='l2'

          priority='secondary'
        >
          Preview
        </Type>

        <Line
          gap={4.7}

          direction='row'

          align='flex-start'

          justify='space-between'

          className={classes.seoPreview}
        >
          <Line
            gap={0.5}
          >
            <Type
              className={classes.seoPreviewTitle}

              dangerouslySetInnerHTML={{
                __html: textToInnerHTML(values['seo.name']?.value) || 'Title example'
              }}
            />

            <Type
              className={classes.seoPreviewDescription}
            >
              <span className={classes.seoPreviewDate}>14.04.2024. — </span>

              <span
                dangerouslySetInnerHTML={{
                  __html: textToInnerHTML(values['seo.description']?.value) || 'Description example'
                }}
              />
            </Type>
          </Line>

          <Line
            style={{
              ...(values['seo.image'].value && {
                backgroundImage: `url(${getMediaUrl(values['seo.image']?.value, 480)})`
              })
            }}

            className={classes.seoPreviewImage}
          />
        </Line>
      </Line>
    </Form>
  );
});

export default OptionsSEO;
