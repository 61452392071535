import React from 'react';

import { is } from '@amaui/utils';
import { Checkbox, IconButton, Image, Line, ListItem, Menu, Type } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';

import IconMaterialPottedPlantRounded from '@amaui/icons-material-rounded-react/IconMaterialPottedPlant';
import IconMaterialMoreVertRounded from '@amaui/icons-material-rounded-react/IconMaterialMoreVert';

import { getMediaThumbnailURL, getMediaUrl } from 'other';

const useStyle = style(theme => ({
  root: {
    width: '100%',
    height: 284,
    outline: '0px solid',
    borderRadius: 12,
    transition: theme.methods.transitions.make(['outline']),

    '&[disabled]': {
      opacity: 0.54,
      cursor: 'default',
      userSelect: 'none',
      pointerEvents: 'none'
    },

    '& > *:nth-child(1)': {
      '& .amaui-ListItem-root': {
        background: 'transparent',
        padding: 0
      },

      '& .amaui-IconButton-root': {
        flex: '0 0 auto'
      }
    },

    '& > *:nth-child(2)': {
      height: 0,
      flex: '1 1 auto',
      paddingBottom: 0
    },

    '& img': {
      height: '100%',
      width: 'auto',
      objectFit: 'contain'
    },

    '& .amaui-Image-noImage-element': {
      maxWidth: 114
    }
  },

  version_regular: {
    // background: theme.palette.light ? theme.palette.color.secondary[95] : theme.palette.color.secondary[20]
  },

  version_simple: {
    background: 'transparent'
  },

  header: {
    flex: '0 0 auto'
  },

  header_version_regular: {
    padding: '24px 24px 0'
  },

  header_version_simple: {
    padding: '12px 12px 0'
  },

  main: {
    padding: '0 24px 24px'
  },

  mainNoPadding: {
    padding: 0
  },

  footer: {
    padding: '0 16px 16px',
    flex: '0 0 auto'
  },

  name: {
    width: '100%',
    maxHeight: 35,
    overflow: 'hidden',

    '&.amaui-Type-root': {
      lineHeight: '1.24'
    }
  },

  mime: {
    wordBreak: 'break-all',
    width: '100%',
    height: 15,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },

  selected: {
    outline: '2px solid !important',
    outlineColor: theme.methods.palette.color.value('secondary', 30)
  }
}), { name: 'amaui-app-route-element-MediaItem' });

const MediaItem = React.forwardRef((props: any, ref: any) => {
  const {
    media,

    version = 'regular',

    menuItems,

    noMenu,

    selectedObjects,

    select,

    options,

    SelectProps,

    className,

    ...other
  } = props;

  const { classes } = useStyle();

  let element: any;

  const {
    id,
    name,
    mime
  } = media || {};

  const allowed = (is('array', options?.allowed) ? options?.allowed : [options?.allowed]).filter(Boolean) || [];

  const image = mime?.startsWith('image/');

  const video = mime?.startsWith('video/');

  if (image || video) {
    element = (
      <Image
        src={image ? getMediaUrl({ id }, 480) : getMediaThumbnailURL(media)}

        alt={name}

        crossOrigin='anonymous'
      />
    );
  }
  else {
    element = (
      <Line
        align='center'

        justify='center'

        flex

        fullWidth

        className={classes.other}
      >
        <IconMaterialPottedPlantRounded
          size={54}
        />
      </Line>
    );
  }

  const onFooterClick = React.useCallback((event: MouseEvent) => {
    // event.stopPropagation();
  }, []);

  const selected = !!selectedObjects?.isSelected(media) || SelectProps?.checked;

  if (!!allowed.length) {
    if (!allowed.some((item: any) => mime.includes(item))) other.disabled = true;
  }

  return (
    <Line
      ref={ref}

      gap={1.4}

      className={classNames([
        className,
        classes.root,
        classes[`version_${version}`],
        selected && classes.selected
      ])}

      {...other}
    >
      <Line
        gap={version === 'regular' ? 1.4 : 1}

        direction='row'

        align='flex-start'

        fullWidth
      >
        <ListItem
          color='inherit'

          primary={(
            <Type
              version='l2'

              className={classes.name}
            >
              {name}
            </Type>
          )}

          secondary={(
            <Type
              version='b3'

              className={classes.mime}
            >
              {mime}
            </Type>
          )}

          Component='div'

          className={classNames([
            classes.header,
            classes[`header_version_${version}`],
            classes[`header_menu_${!noMenu ? 'with' : 'without'}`]
          ])}
        />
      </Line>

      <Line
        align='center'

        justify='center'

        fullWidth

        className={classNames([
          classes.main,
          version === 'simple' && classes.mainNoPadding
        ])}
      >
        {element}
      </Line>

      {!noMenu && (
        <Line
          direction='row'

          align='center'

          justify='space-between'

          fullWidth

          onClick={onFooterClick}

          className={classes.footer}
        >
          <Line
            direction='row'
          >
            {select && (
              <Checkbox
                size='small'

                {...SelectProps}
              />
            )}
          </Line>

          <Line
            gap={0.5}

            direction='row'
          >
            <Menu
              alignment='center'

              menuItems={menuItems}

              ListProps={{
                size: 'small'
              }}
            >
              <IconButton
                color='inherit'

                size='small'
              >
                <IconMaterialMoreVertRounded />
              </IconButton>
            </Menu>
          </Line>
        </Line>
      )}
    </Line>
  );
});

export default MediaItem;
