import React from 'react';

import { Button } from '@amaui/ui-react';
import { IButton } from '@amaui/ui-react/Button/Button';
import { classNames, style } from '@amaui/style-react';

const useStyle = style(theme => ({
  root: {

  }
}), { name: 'amaui-app-ClearSearch' });

const Element = React.forwardRef((props: IButton, ref: any) => {
  const {
    className,

    children,

    ...other
  } = props;

  const { classes } = useStyle(props);

  return (
    <Button
      ref={ref}

      version='text'

      color='info'

      size='small'

      className={classNames([
        classes.root,
        className
      ])}

      {...other}
    >
      {children || 'Clear'}
    </Button>
  );
});

Element.displayName = 'amaui-app-Button';

export default Element;
