import React from 'react';

import { Form, FormRow, Line, Medias, Switch } from '@amaui/ui-react';
import { classNames, style, useAmauiTheme } from '@amaui/style-react';
import { getObjectValue, setObjectValue } from '@amaui/utils';

import { SelectColor, SelectMedia, SelectSize } from './elements';

const useStyle = style(theme => ({
  root: {

  },

  preview: {
    position: 'relative',
    width: 154,
    height: 77,
    borderRadius: 12,
    overflow: 'hidden',
    boxShadow: theme.shadows.values.default[1]
  },

  type: {
    columnGap: '40px'
  },

  divider: {
    columnRule: `1px solid ${theme.palette.text.divider}`
  },

  h1: {
    ...theme.typography.values.h1,

    fontSize: `clamp(1.75rem, 1.5874vw, ${theme.typography.values.h1.fontSize})`
  },

  h2: {
    ...theme.typography.values.h2,

    fontSize: `clamp(1.5rem, 1.3394vw, ${theme.typography.values.h2.fontSize})`
  },

  h3: {
    ...theme.typography.values.h3,

    fontSize: `clamp(1.25rem, 1.1904vw, ${theme.typography.values.h3.fontSize})`
  },

  noData: {
    '& .amaui-FormRow-main': {
      display: 'none'
    }
  }
}), { name: 'amaui-app-OptionsSectionMedia' });

const OptionsSectionMedia = React.forwardRef((props: any, ref: any) => {
  const {
    value,

    element,

    index,

    onUpdate,

    className,

    children,

    ...other
  } = props;

  const { classes } = useStyle();

  const theme = useAmauiTheme();

  const refs = {
    value: React.useRef(value),
    index: React.useRef(index),
    onUpdate: React.useRef(onUpdate),
    element: React.useRef(element)
  };

  refs.value.current = value;

  refs.index.current = index;

  refs.onUpdate.current = onUpdate;

  refs.element.current = element;

  const update = React.useCallback((property: string, valueProperty: any) => {
    const valueNew = [...(refs.value.current || [])];

    if (refs.element.current) {
      setObjectValue(refs.element.current, property, valueProperty);

      refs.onUpdate.current(valueNew);
    }
  }, []);

  const enable = React.useCallback((valueSwitch: boolean, property: string, valueDefault: any) => {
    const valueNew = [...(refs.value.current || [])];

    if (refs.element.current) {
      setObjectValue(refs.element.current, property, !valueSwitch ? null : valueDefault);

      refs.onUpdate.current(valueNew);
    }
  }, []);

  const getItem = (item: any, index: number) => (
    <FormRow
      key={index}

      gap={2}

      name={item.name}

      start={!item.noSwitch && (
        <Switch
          tonal

          valueDefault={item.defaultSwitch !== undefined ? item.defaultSwitch : !!getObjectValue(element?.props, item.property)}

          onChange={(valueNew: any) => enable(valueNew, `props.${item.property}`, item.default)}

          size='small'
        />
      )}

      {...item?.props}

      className={classNames([
        item?.props?.className,
        (!item.show && [null, undefined].includes(getObjectValue(element?.props || {}, item.property))) && classes.noData
      ])}
    >
      {(item.show || ![null, undefined].includes(getObjectValue(element?.props || {}, item.property))) && item.value}
    </FormRow>
  );

  const palette = element?.props?.MediaProps?.color && (theme.palette.color[element?.props?.MediaProps?.color] || theme.methods.color(element?.props?.MediaProps?.color));

  const options: any = [
    // color 
    {
      name: 'Color',
      property: 'MediaProps.color',
      default: 'default',
      noSwitch: true,
      show: true,
      value: (
        <Line
          gap={1.5}

          direction='column'

          fullWidth
        >
          <SelectColor
            value={element?.props?.MediaProps?.color}

            onChange={(valueNew: any) => update('props.MediaProps.color', valueNew)}
          />

          <Line
            className={classes.preview}

            style={{
              backgroundColor: element?.props?.MediaProps?.color && theme.methods.palette.color.value(undefined as any, 95, true, palette)
            }}
          />
        </Line>
      )
    },

    // size 
    {
      name: 'Size',
      property: 'size',
      default: 'regular',
      noSwitch: true,
      show: true,
      value: (
        <SelectSize
          value={element?.props?.size || 'regular'}

          onChange={(valueNew: any) => update('props.size', valueNew)}
        />
      )
    }
  ];

  const onConfirmMedia = React.useCallback((valueNew: any) => {
    const media = valueNew;

    update(`props.value`, media || null);
  }, []);

  return (
    <Line
      fullWidth

      className={classNames([
        className,
        classes.root
      ])}

      {...other}
    >
      <Form
        size='small'

        wrapper
      >
        <Line
          fullWidth
        >
          <SelectMedia
            name='Media'

            value={element?.props?.values}

            selected={element?.props?.value}

            mime='all'

            onChange={onConfirmMedia}
          />

          {!!element?.props?.values?.length && (
            <Medias
              size='regular'

              values={element?.props?.values?.map((item: any) => ({
                value: item
              }))}
            />
          )}

          {options.map((item: any, index: number) => getItem(item, index))}
        </Line>
      </Form>
    </Line>
  );
});

export default OptionsSectionMedia;
