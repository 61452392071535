import React from 'react';

import { copy, decodeObjectValue, encodeObjectValue, is, isValid, textToInnerHTML } from '@amaui/utils';
import { Label, Line, Medias, SmartTextField, Switch, useForm, useSnackbars } from '@amaui/ui-react';
import { style } from '@amaui/style-react';
import { Page } from '@amaui/themes';
import { ITemplate } from '@amaui/api-utils';

import { ModalForm, PageBuilder, SelectMedia, TextField } from 'ui';
import { AppService, TemplateService } from 'services';
import { getErrorMessage } from 'other';

const useStyle = style(theme => ({
  root: {

  },

  ...theme.classes(theme)
}), { name: 'amaui-app-Template' });

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    website = [undefined, 'theme'].includes(props.version) ? {
      id: 'a',
      theme: props.object?.themes?.[0] || { id: 'a1', name: 'Modern' },
      placeholder: true,
      added_at: 1400
    } : undefined,

    theme = props.theme || props.object?.themes?.[0],

    object: object_,

    onConfirm,

    nested
  } = props;

  const { classes } = useStyle();

  const snackbars = useSnackbars();

  const [object, setObject] = React.useState(object_);
  const [tab, setTab] = React.useState(!object ? 'Options' : 'Template');
  const [loading, setLoading] = React.useState(false);

  const form = useForm({
    values: {
      'name': {
        name: 'Name',
        value: object?.name,
        max: 1400,
        messages: {
          min: 'Name has to be min 1 characters',
          max: 'Name can be max 1400 characters'
        }
      },
      'description': {
        name: 'Short description',
        value: object?.description,
        max: 4400,
        messages: {
          min: 'Short description has to be min 1 characters',
          max: 'Short description can be max 4400 characters'
        }
      },
      'version': {
        name: 'Version',
        value: object?.version || 'page',
        in: ['page', 'section']
      },
      'image': {
        name: 'Image',
        value: object?.image,
        is: 'object'
      },
      'value': {
        name: 'Content',
        value: copy(object?.value || []).map((item: any) => ({
          ...item,

          props: decodeObjectValue(item.props)
        })),
        is: 'array'
      },
      'active': {
        name: 'Active',
        value: object?.active !== undefined ? object?.active : true,
        is: 'boolean'
      },
      'archived': {
        name: 'Archived',
        value: object?.archived !== undefined ? object?.archived : false,
        is: 'boolean'
      }
    }
  });

  const refs = {
    value: React.useRef<HTMLElement>(),
    template: React.useRef<HTMLElement>(),
    form: React.useRef(form),
    props: React.useRef<any>(),
    onConfirm: React.useRef<any>(onConfirm),
    appService: React.useRef<any>()
  };

  refs.form.current = form;

  refs.onConfirm.current = onConfirm;

  refs.props.current = props;

  refs.appService.current = props.website || props.theme ? 'addSecondary' : 'add';

  const valueDefault = copy(object?.value);

  const init = React.useCallback(() => {
    if (object?.id) {
      refs.form.current.onChange([
        ['name', object?.name],
        ['description', object?.description],
        ['version', object?.version],
        ['active', object?.active],
        ['archived', object?.archived],
        ['value', (valueDefault || []).map((item: any) => ({
          ...item,

          props: decodeObjectValue(item.props)
        }))]
      ]);
    }
  }, [object, valueDefault]);

  React.useEffect(() => {
    // init
    init();
  }, []);

  const onClose = React.useCallback(() => {
    AppService.pages[(nested && nested !== 'add') ? 'addSecondary' : 'add'].emit({
      ...AppService.pages[(nested && nested !== 'add') ? 'addSecondary' : 'add'].value,

      open: false
    });
  }, [nested]);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();
  }, []);

  const onNext = React.useCallback(async () => {
    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const body: ITemplate = {
      ...refs.form.current.value,

      value: (refs.form.current.value.value || []).map((item: any) => ({
        ...item,

        props: encodeObjectValue(item.props)
      }))
    };

    if (!object?.id) {
      if (props.theme) body.themes = [
        {
          id: props.theme.id,
          name: props.theme.name
        }
      ];
      else if (props.website) body.websites = [
        {
          id: props.website.id,
          name: props.website.name
        }
      ];
    }

    const result = !object?.id ? await TemplateService.add(body) : await TemplateService.update(object?.id, body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Template ${!object?.id ? 'added' : 'updated'}`
      });

      setObject(result.response.response);

      if (is('function', onConfirm)) onConfirm();

      if (!object?.id) onClose();
    }

    setLoading(false);
  }, [website, object, isValid, form, onConfirm, onClose]);

  const onConfirmImage = React.useCallback((valueNew: any) => {
    form.onChange('image', valueNew);
  }, [form]);

  const onChangeTab = React.useCallback(async (value: any) => {
    await refs.form.current.validate();

    setTab(value);
  }, []);

  const onPageBuilderChange = React.useCallback((valueNew: any) => {
    form.onChange('value', valueNew);
  }, []);

  const modal: any = {
    'Template': <>
      <Line
        ref={refs.template}

        gap={0}

        align='unset'

        justify='unset'

        flex

        fullWidth
      >
        <PageBuilder
          mode='update'

          page={(
            <Page
              page={{
                value: form.value.value
              }}
            />
          )}

          value={form.value.value}

          onChange={onPageBuilderChange}

          object={object}

          website={{
            ...website,

            theme: theme || website?.theme
          }}

          template

          noSaveAsTemplate
        />
      </Line>
    </>,

    'Options': <>
      <Line
        fullWidth

        className={classes.page}
      >
        <Line
          fullWidth
        >
          <Line
            gap={1}

            fullWidth
          >
            <TextField
              name='Name'

              valueDefault={textToInnerHTML(form.values['name'].value)}

              onChange={(valueNew: any) => form.onChange('name', valueNew, undefined, { rerenderOnUpdate: false })}
            />

            <SmartTextField
              placeholder='Description'

              valueDefault={textToInnerHTML(form.values['description'].value || '')}

              onChange={(valueNew: any) => form.onChange('description', valueNew, undefined, { rerenderOnUpdate: false })}

              minRows={4}

              multiline

              edit
            />
          </Line>

          <SelectMedia
            value={form.value.image}

            selected={form.value.image}

            mime='image'

            onChange={onConfirmImage}
          />

          {form.value.image && (
            <Medias
              size='regular'

              values={[
                {
                  value: form.value.image
                }
              ]}
            />
          )}
        </Line>

        <Line
          gap={1}

          fullWidth
        >
          <Label
            checked={form.values.active?.value}

            onChange={(valueNew: boolean) => form.onChange('active', valueNew)}

            error={!!form.values.active.error}

            helperText={form.values.active.error}
          >
            <Switch />

            Active
          </Label>

          <Label
            checked={form.values.archived?.value}

            onChange={(valueNew: boolean) => form.onChange('archived', valueNew)}

            error={!!form.values.archived.error}

            helperText={form.values.archived.error}
          >
            <Switch />

            Archived
          </Label>
        </Line>
      </Line>
    </>
  };

  return (
    <ModalForm
      {...props}

      object={object}

      add

      tabDefault='Template'

      tab={tab}

      tabs={['Template', 'Options']}

      onChangeTab={onChangeTab}

      onSubmit={onSubmit}

      onNext={onNext}

      onClose={onClose}

      loading={loading}
    >
      {modal[tab]}
    </ModalForm>
  );
});

export default Element;
