import React from 'react';

import { useSnackbars } from '@amaui/ui-react';

import { FormPaymentMethod, Page, useSubscription } from 'ui';
import { AuthService, OrganizationService } from 'services';
import { ISignedIn, getErrorMessage } from 'other';

const cleanValue = (value_: string) => {
  let value = value_;

  ['-', '_'].forEach(filter => {
    const expression = `\\${filter}`;
    const regexp = new RegExp(expression, 'g');

    value = value ? value.replace(regexp, ' ') : '';
  });

  return `${value.charAt(0).toUpperCase()}${value.slice(1)}`;
};

const PaymentMethods = React.forwardRef(() => {
  const snackbars = useSnackbars();

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const onDefault = React.useCallback(async (item: any, options: any) => {
    options.setLoading(true);

    const result = await OrganizationService.updatePaymentMethod(signedIn?.organization?.id, {
      payment_method: {
        id: item.id
      },

      default: true
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Payment method updated`
      });
    }

    // refetch
    OrganizationService.queryPaymentMethods.value!.refetch(true);

    options.setLoading(false);
  }, [signedIn]);

  const properties = React.useMemo(() => ({
    card: {
      name: 'Card number',
      method: (item: any) => `**** **** **** ${item.card.last4}`
    },
    type: { name: 'Type', method: (item: any) => cleanValue(item.type) },
    default: { name: 'Default' }
  }), []);

  return (
    <Page
      name='Payment methods'

      service={OrganizationService}

      onRemoveMethod={(item: any) => OrganizationService.dettachPaymentMethod({ payment_method: item })}

      columns={[
        properties.card,
        properties.type,
        'default'
      ]}

      isEnabledAction={(item: any, action: any) => action === 'export' ? false : true}

      properties={properties}

      options={['default']}

      queryItemsName='queryPaymentMethods'

      onDefault={onDefault}

      app='amaui'

      collection='paymentMethods'

      route='/organization/settings/payment-methods'

      Form={FormPaymentMethod}

      onConfirmProps={[true]}

      noTextSearch

      noSearch

      noSelect

      noTotal

      noTags

      noUpdateProject

      isStripe
    />
  );
});

export default PaymentMethods;
