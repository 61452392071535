import React from 'react';
import { Helmet } from 'react-helmet';

import { debounce, hash, is, setObjectValue, stringify, textToInnerHTML, wait } from '@amaui/utils';
import { Calendar, CalendarMonth, Checkbox, Line, ListItem, PaginationItem, Type, useConfirm, useForm, useLocation, useMediaQuery, useSnackbars } from '@amaui/ui-react';
import { classNames, style, useAmauiTheme } from '@amaui/style-react';
import { ITask, Task } from '@amaui/api-utils';
import { AmauiDate, add, endOf, format, is as Is, remove, startOf } from '@amaui/date';
import AmauiSubscription from '@amaui/subscription';

import { Button, Limited, List, MoreMenu, Popup, SmartTextField, ObjectCalendar, TextField, FormTask, useSubscription, Page, NoResults } from 'ui';
import { AppService, AuthService, TaskService } from 'services';
import { IQuerySubscription, getErrorMessage, LOAD_MORE_LIMIT, ISelectedSubscription, ISignedIn, isLimited, getParamID } from 'other';

const useStyle = style(theme => ({
  root: {
    '& .amaui-app-Page-root': {
      paddingBottom: 0
    }
  },

  tasks: {
    overflowX: 'hidden',
    overflowY: 'auto',
    flex: '1 1 auto',
    height: 0
  },

  task: {

  },

  name: {
    flex: '1 1 auto',
    opacity: 0,
    // transition: theme.methods.transitions.make('opacity', { duration: 'xs' }),

    '&[data-loaded="true"]': {
      opacity: 1
    }
  },

  input: {
    '&.amaui-TextField-root': {
      flex: '0 0 auto'
    }
  },

  taskDone: {
    opacity: 0.4
  },

  end: {
    cursor: 'pointer',
    userSelect: 'none'
  }
}), { name: 'amaui-app-route-Tasks' });

const Tasks = React.forwardRef((props: any, ref: any) => {
  const {
    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const confirm = useConfirm();
  const theme = useAmauiTheme();
  const snackbars = useSnackbars();
  const location = useLocation();

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const queryTasks = useSubscription<IQuerySubscription>(TaskService.queryTasks);
  const selectedTasks = useSubscription<ISelectedSubscription>(TaskService.selectedTasks);

  const touch = useMediaQuery('(pointer: coarse)');

  const [loaded, setLoaded] = React.useState<any>(false);
  const [loading, setLoading] = React.useState<any>(false);
  const [moreMenu, setMoreMenu] = React.useState<any>();
  const [inputSubscription] = React.useState(new AmauiSubscription());

  const form = useForm({
    values: {
      name: {
        name: 'Name',
        is: 'string',
        min: 1,
        max: 1400,
        required: true
      }
    }
  });

  const refs = {
    root: React.useRef<HTMLInputElement>(),
    input: React.useRef<HTMLInputElement>(),
    tasks: React.useRef<HTMLDivElement>(),
    queryTasks: React.useRef(queryTasks),
    form: React.useRef(form),
    loading: React.useRef<any>(),
    formSearch: React.useRef<any>(),
    queryDefault: React.useRef<any>(),
    opened: React.useRef<any>(),
    timeout: React.useRef<any>()
  };

  refs.form.current = form;

  const today = new AmauiDate();
  const tomorrow = add(1, 'day', today);

  const queryDefaultAll = {
    done: false,
    lists_count: 0
  };

  const queryDefaultToday = {
    done: false,
    ends_at_to: endOf(today, 'day').milliseconds
  };

  const queryDefaultTomorrow = {
    done: false,
    ends_at_from: startOf(tomorrow, 'day').milliseconds,
    ends_at_to: endOf(tomorrow, 'day').milliseconds
  };

  const queryDefaultWeek = {
    done: false,
    ends_at_to: endOf(add(7, 'day', today), 'day').milliseconds
  };

  const queryDefaultAssignedToMe = {
    done: false,
    assignee_me: true
  };

  let queryDefault: any = {
    ...queryDefaultToday
  };

  const pages = {
    today: false,
    all: location.pathname.includes('/all'),
    tomorrow: location.pathname.includes('/tomorrow'),
    week: location.pathname.includes('/week'),
    assignedToMe: location.pathname.includes('/assigned-to-me')
  };

  pages.today = !(pages.all || pages.tomorrow || pages.week || pages.assignedToMe);

  let page: string = '';

  if (pages.all) {
    page = 'all';

    queryDefault = { ...queryDefaultAll };
  }

  if (pages.tomorrow) {
    page = 'tomorrow';

    queryDefault = { ...queryDefaultTomorrow };
  }

  if (pages.week) {
    page = 'week';

    queryDefault = { ...queryDefaultWeek };
  }

  if (pages.assignedToMe) {
    page = 'assigned-to-me';

    queryDefault = { ...queryDefaultAssignedToMe };
  }

  const formSearchValueDefault = {
    text: '',
    done: false,
    added_at: []
  };

  const formSearch = useForm({
    values: {
      text: {
        name: 'Text',
        is: 'string',
        value: ''
      },
      done: {
        name: 'Done',
        value: false,
        in: [true, false, 'all']
      },
      added_at: {
        name: 'Added at',
        value: []
      }
    },

    valueDefault: {
      ...formSearchValueDefault
    },

    autoValidate: true
  });

  refs.formSearch.current = formSearch;

  refs.queryTasks.current = queryTasks;

  refs.queryDefault.current = queryDefault;

  refs.loading.current = loading;

  const initInnerHTML = React.useCallback(() => {
    if (refs.tasks.current) {
      const elements = Array.from(refs.tasks.current!.querySelectorAll(`[data-type='title']`)) as HTMLElement[];

      const response = refs.queryTasks.current.response;

      for (const element of elements) {
        const task = response?.find((item: any) => item.id === element.dataset.id);

        if (task) {
          if ([undefined, 'update'].includes(task.update)) element.innerHTML = textToInnerHTML(task.name);

          element.dataset.loaded = 'true';
        }
      }
    }
  }, []);

  const init = React.useCallback(async () => {
    formSearch.clear();

    // if any task is previously open 
    // first close it, re-open if needed 
    onClose();

    await wait(14);

    const result = await TaskService.queryTasks.value!.query({
      query: {
        query: {
          ...queryDefaultAll,

          ...queryDefault
        }
      }
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else if (!['assigned-to-me'].includes(page)) {
      const id = getParamID();

      if (id) onOpen({ id });
    }

    setTimeout(() => {
      initInnerHTML();

      setLoaded(true);
    }, 14);
  }, [page, location, form]);

  const updated = hash(queryTasks.response);

  React.useEffect(() => {
    initInnerHTML();
  }, [updated]);

  React.useEffect(() => {
    const id = getParamID();

    if (id !== refs.opened.current?.id) onOpen({ id });
  }, [location]);

  const onSearch = React.useCallback(debounce(async () => {
    setLoading('query');

    const formSearchValue = refs.formSearch.current.value;

    const search: any = {};

    if (formSearchValue.text) search.text = formSearchValue.text;

    if (is('boolean', formSearchValue.done)) search.done = formSearchValue.done;

    if (formSearchValue.done === 'all') search.done = undefined;

    if (formSearchValue.added_at) {
      const [addedAtFrom, addedAtTo] = formSearchValue.added_at;

      if (addedAtFrom) search.added_at_from = addedAtFrom;

      if (addedAtTo) search.added_at_to = addedAtTo;
    }

    const result = await TaskService.queryTasks.value!.query({
      query: {
        ...TaskService.queryTasks.value!.previousQuery,

        query: {
          ...queryDefaultAll,

          ...refs.queryDefault.current,

          ...search
        },

        next: undefined,
        previous: undefined,
        skip: undefined,
        total: undefined
      }
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }

    setLoading(false);
  }, 140), []);

  React.useEffect(() => {
    if (loaded && queryTasks?.loaded) onSearch();
  }, [hash(formSearch.value)]);

  const onLoadMore = React.useCallback(async () => {
    setLoading('query');

    const result = await TaskService.queryTasks.value!.query({
      query: {
        ...TaskService.queryTasks.value!.previousQuery,

        next: refs.queryTasks.current?.pagination?.next,
        previous: undefined,
        skip: undefined,
        total: undefined,

        loadMore: true
      }
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }

    setLoading(false);
  }, []);

  const loadMore = React.useCallback(debounce(() => {
    const tasks = refs.tasks.current as HTMLElement;

    const limit = tasks.scrollHeight - (tasks.scrollTop + tasks.clientHeight);

    if (limit <= LOAD_MORE_LIMIT) {
      if (!refs.queryTasks.current?.pagination?.hasNext) return;

      // If not in loading process at the moment
      if (!refs.loading.current) {
        clearTimeout(refs.timeout.current);

        refs.timeout.current = setTimeout(onLoadMore, 14);
      }
    }
  }, 14), []);

  const onScroll = React.useCallback(loadMore, []);

  React.useEffect(() => {
    if (refs.tasks.current) (refs.tasks.current as HTMLElement).addEventListener('scroll', onScroll);

    return () => {
      if (refs.tasks.current) (refs.tasks.current as HTMLElement).removeEventListener('scroll', onScroll);
    };
  }, [page, location, refs.tasks.current]);

  const onClose = React.useCallback(() => {
    // initial
    // unopened
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });

    const previous = `/tasks${page ? `/${page}` : ''}`;

    if (previous !== location.pathname) {
      window.history.pushState(undefined, '', previous);
    }
  }, [page, location]);

  const onOpen = React.useCallback(async (item: any) => {
    const id = item?.id;

    if (!(id && !['add'].includes(id))) return;

    const object = id ? refs.queryTasks.current.response?.find((item: any) => id === item.id) || (id && (await TaskService.get(id)).response?.response) : null;

    if (!object) {
      refs.opened.current = null;

      onClose();

      return;
    }

    refs.opened.current = object;

    // if (task.id === AppService.pages.add.value?.id) return;

    AppService.pages.add.emit({
      id: object.id,
      hash: hash(object),
      open: true,
      version: touch ? 'entire' : 'inline',
      helpers: true,
      route: {
        to: `/tasks${page ? `/${page}` : ''}/${object.id}`,
        previous: `/tasks${page ? `/${page}` : ''}`
      },
      children: (
        <FormTask
          key={object.id}

          object={object}

          onRemove={() => onRemove(object)}

          onConfirm={() => TaskService.queryTasks.value!.refetch(true)}

          modal={touch}
        />
      )
    });
  }, [page, touch]);

  const cleanUp = React.useCallback(() => {
    TaskService.selectedTasks.value!.reset();
  }, []);

  React.useEffect(() => {
    const isSomeSelected = queryTasks.response?.some((item: any) => selectedTasks.isSelected(item));

    if (isSomeSelected) selectedTasks.updateMany(queryTasks.response);
  }, [stringify(queryTasks.response)]);

  React.useEffect(() => {
    // init
    init();

    return () => {
      cleanUp();
    };
  }, [page]);

  const onAdd = React.useCallback(async () => {
    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading('add');

    const body: Task = {
      ...refs.form.current.value
    };

    // auto today
    // ends value
    if (!body.ends_at) {
      body.ends_at = page === 'tomorrow' ? tomorrow.milliseconds : today.milliseconds;
    }

    const result = await TaskService.add(body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: 'Task added'
      });

      // refetch
      TaskService.queryTasks.value!.refetch(true);

      // clear
      refs.form.current.clear();

      inputSubscription.emit('clear');

      // focus
      setTimeout(() => {
        refs.input.current!.focus();
      }, 140);
    }

    setLoading(false);
  }, [page, inputSubscription]);

  const getValue = React.useCallback((valueNew: any) => {
    const value = { ...valueNew };

    const body: Partial<Task> = {
      name: value.name,
      done: value.done,
      ends_at: value.ends_at,
      repeat: value.repeat,
      reminders: value.reminders,
      priority: value.priority,
      media: value.media
    };

    // if (value.value !== undefined) {
    //   body.value = [
    //     {
    //       version: 'type',
    //       value: innerHTMLToText(value.value)
    //     }
    //   ];
    // }

    if (value.assignee !== undefined) {
      if (value.assignee?.id) body.assignee = [
        {
          id: value.assignee?.id,
          name: value.assignee?.name
        }
      ];
      else body.assignee = [];
    };

    body.reminders?.forEach((reminder: any) => {
      if (reminder?.remind_at) delete reminder.remind_at;
    });

    return body;
  }, []);

  const onUpdate = React.useCallback(debounce(async (id: string, task: Task, reset = false) => {
    const body = getValue(task);

    const result = await TaskService.update(id, body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      if (reset) {
        // refetch 
        // as it might have have new tasks updates 
        TaskService.queryTasks.value!.refetch();
      }
    }

    setLoading(false);
  }, 1400), []);

  const onChangeName = React.useCallback(async (task: Task, valueNew: string, event: InputEvent) => {
    const element = event?.target as HTMLInputElement;

    if (element) {
      const body = {
        ...task,

        name: valueNew
      };

      TaskService.task.emit(body as any);

      onUpdate(task.id, body);
    }
  }, [form]);

  const onChangeObjectCalendar = React.useCallback(async (task: Task, ...args: any) => {
    const body = {
      ...task,

      repeat: { ...task.repeat }
    };

    const valueArgs = is('array', args[0]) ? args[0] : [args];

    for (const arg of valueArgs) {
      const [property, value, nestedProperty] = arg;

      let propertyValue = property;

      if (nestedProperty) propertyValue += `.${nestedProperty}`;

      if (value !== undefined) setObjectValue(body, propertyValue, value);
    }

    TaskService.task.emit(body as any);

    onUpdate(task.id, body, true);
  }, [form]);

  const onDone = React.useCallback(async (task: ITask) => {
    setLoading(task.id);

    const body: Partial<Task> = {
      id: task.id,

      done: !task.done
    };

    TaskService.task.emit(body as any);

    onUpdate(task.id, body, true);
  }, []);

  const onKeyDown = React.useCallback((event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      onAdd();
    }
  }, [onAdd]);

  const onMoreMenuClose = React.useCallback(() => {
    setMoreMenu((previous: any) => ({
      ...previous,

      open: false
    }));
  }, []);

  const onRemove = React.useCallback(async (value: ITask) => {
    if (!(await confirm.open({ name: `Removing ${textToInnerHTML(value.name) || 'a'} task` }))) return;

    const result = await TaskService.remove(value.id);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Task removed`
      });
    }

    // reset
    TaskService.selectedTasks.value!.reset();

    // refresh
    TaskService.queryTasks.value!.refetch();
  }, []);

  const onStopPropagation = React.useCallback((event: MouseEvent) => {
    event.stopPropagation();
  }, []);

  const onContextMenu = React.useCallback((event: MouseEvent, values: any) => {
    event.preventDefault();

    const x = event.pageX;

    const y = event.pageY;

    const target = event.target;

    const anchor = {
      width: 14,
      height: 14,
      x,
      y
    };

    setMoreMenu({
      open: true,

      ...values,

      element: target,

      anchor
    });
  }, [onMoreMenuClose]);

  const priorityToColor = (value: any) => {
    if (value === 'low') return 'info';

    if (value === 'medium') return 'warning';

    if (value === 'important') return 'error';

    return 'primary';
  };

  const limited = isLimited(signedIn, 'task');

  const tasks = queryTasks?.response || [];

  // add
  // meta information
  tasks.forEach((task: Task) => {
    if (task.ends_at) {
      const now = new AmauiDate();
      const ends_at = new AmauiDate(task.ends_at);

      (task as any).date = format(ends_at, 'DD MMM');

      const starts = {
        now: startOf(now, 'day'),
        ends: startOf(ends_at, 'day')
      };

      (task as any).inThePast = Is(starts.ends, 'before', starts.now);

      (task as any).yesterday = format(remove(1, 'day', now), 'DD MMM YYYY') === format(ends_at, 'DD MMM YYYY');

      (task as any).today = format(now, 'DD MMM YYYY') === format(ends_at, 'DD MMM YYYY');

      (task as any).tomorrow = format(add(1, 'day', now), 'DD MMM YYYY') === format(ends_at, 'DD MMM YYYY');

      if ((task as any).yesterday) (task as any).date = `Yesterday`;

      if ((task as any).today) (task as any).date = `Today`;

      if ((task as any).tomorrow) (task as any).date = `Tomorrow`;
    }
  });

  tasks.sort((a: Task, b: Task) => {
    if (a.added_at > b?.added_at) return -1;

    if (a.added_at < b?.added_at) return 1;

    return 0;
  });

  const taskMoreMenu = tasks.find((item: any) => item.id === moreMenu?.object.id) || moreMenu?.object;

  const getItems = () => {

    return (
      <Line
        gap={1}

        align='flex-end'

        justify='unset'

        flex

        fullWidth
      >
        {limited && page !== 'assigned-to-me' && (
          <Limited
            name='Tasks'

            total={signedIn?.organizationPlan?.provided?.task?.total}

            tab='Task'
          />
        )}

        {!limited && page !== 'assigned-to-me' && queryTasks.loaded && (
          <TextField
            inputProps={{
              ref: refs.input
            }}

            name='Add a task, press enter to make it'

            valueDefault={form.values.name.value || ''}

            onChange={(valueNew: string) => form.onChange('name', valueNew, undefined, { rerenderOnUpdate: false })}

            onKeyDown={onKeyDown}

            subscription={inputSubscription}

            disabled={loading === 'add'}

            endVerticalAlign='center'

            end={(
              <Button
                color='primary'

                size='small'

                onClick={onAdd}

                loading={loading === 'add'}
              >
                Add
              </Button>
            )}

            className={classes.input}

            fullWidth
          />
        )}

        {!!tasks.length && (
          <List
            ref={refs.tasks}

            gap={1}

            size='small'

            className={classNames([
              'amaui-overflow-y',
              classes.tasks
            ])}

            flex

            fullWidth
          >
            {tasks.map((task: ITask, index: number) => {
              const date = (
                <Type
                  version='l3'

                  onClick={onStopPropagation}

                  color={!task.date ? 'default' : !task.inThePast ? 'success' : 'error'}

                  className={classes.end}
                >
                  {task.date}
                </Type>
              );

              return (
                <ListItem
                  key={task.id}

                  onClick={() => onOpen(tasks[index])}

                  onContextMenu={(event: MouseEvent) => onContextMenu(event, { object: task })}

                  start={(
                    <Checkbox
                      checked={task.done}

                      color={priorityToColor(task.priority?.name?.toLowerCase())}

                      onClick={(event: MouseEvent) => {
                        onStopPropagation(event);

                        onDone(task);
                      }}

                      size='small'
                    />
                  )}

                  end={task.date && touch ? date : (
                    <ObjectCalendar
                      value={task}

                      onChange={(...args: any) => onChangeObjectCalendar(task as any, ...args)}

                      onRemove={() => onRemove(task)}

                      RepeatProps={{
                        fromOptions: ['end', 'done']
                      }}
                    >
                      {date}
                    </ObjectCalendar>
                  )}

                  primary={(
                    <SmartTextField
                      placeholder='Name'

                      onChange={(valueNew: string, event: any) => onChangeName(task as any, valueNew, event)}

                      data-type='title'

                      data-id={task.id}

                      additional={{
                        version: 'l2'
                      }}
                    />
                  )}

                  InteractionProps={{
                    wave: false
                  }}

                  interaction

                  disabled={loading === task.id}

                  className={classNames([
                    classes.task,
                    task.done && classes.taskDone
                  ])}
                />
              );
            })}
          </List>
        )}

        {!tasks.length && <NoResults />}
      </Line>
    );
  };

  return <>
    <Helmet>
      <title>Tasks</title>
      <link rel='icon' type='image/svg' sizes='32x32' href={`/assets/svg/logos/${theme.palette.light ? 'light' : 'dark'}/logo-task.svg`} />
      <link rel='icon' type='image/svg' sizes='16x16' href={`/assets/svg/logos/${theme.palette.light ? 'light' : 'dark'}/logo-task.svg`} />
      <meta name='theme-color' content={theme.palette.light ? (theme.palette.color.task as any).main : theme.palette.color.task[20]} />
    </Helmet>

    <Popup
      version='task'
    />

    {/* Optimized opening  */}
    <Line
      className='amaui-hidden'
    >
      <Calendar />
      <CalendarMonth />
      <PaginationItem />
    </Line>

    <Line
      ref={ref}

      direction='column'

      flex

      fullWidth

      className={classNames([
        className,
        classes.root
      ])}

      {...other}

      style={{
        maxWidth: theme.maxWidth,

        ...other.style
      }}
    >
      <Page
        name='Tasks'

        service={TaskService}

        getItems={getItems}

        formSearch={formSearch}

        search={[
          {
            name: 'Done',
            property: 'done',
            default: false,
            validation: { name: 'Done', is: 'boolean', value: false },
            options: [
              { name: 'All', value: 'all' },
              { name: 'Done', value: true },
              { name: 'Not done', value: false }
            ]
          }
        ]}

        queryItemsName='queryTasks'

        selectedItemsName='selectedTasks'

        app='task'

        collection='tasks'

        route={`/tasks${page ? `/${page}` : ''}`}

        Form={FormTask}

        addVersion='inline'

        noOnInit

        noOnSearch

        noAdd

        noSelect

        noActions

        noPagination

        noLinearProgress
      />
    </Line>

    <MoreMenu
      open={!!moreMenu?.open}

      onClose={onMoreMenuClose}

      anchor={moreMenu?.anchor}

      name={moreMenu?.object && (
        <ObjectCalendar
          menuOpen

          menuOpenDefault

          value={taskMoreMenu}

          anchor={moreMenu?.anchor}

          onChange={(...args: any) => onChangeObjectCalendar(moreMenu?.object as any, ...args)}

          onRemove={() => onRemove(taskMoreMenu)}

          RepeatProps={{
            fromOptions: ['end', 'done']
          }}
        />
      )}

      element={moreMenu?.element}

      items={moreMenu?.items}

      ClickListenerProps={{
        contextMenu: false
      }}
    />
  </>;
});

export default Tasks;
