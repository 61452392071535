import React from 'react';
import { Helmet } from 'react-helmet';

import { textToInnerHTML } from '@amaui/utils';
import { Button, CalendarMonth, Chip, IconButton, Line, Select, Tooltip, Type, useConfirm, useLocation, useMediaQuery, useSnackbars } from '@amaui/ui-react';
import { classNames, style, useAmauiTheme } from '@amaui/style-react';
import { AmauiDate, add, endOf, format, months, remove, set, startOf } from '@amaui/date';
import { ITask } from '@amaui/api-utils';

import IconMaterialKeyboardArrowLeftRounded from '@amaui/icons-material-rounded-react/IconMaterialKeyboardArrowLeft';
import IconMaterialKeyboardArrowRightRounded from '@amaui/icons-material-rounded-react/IconMaterialKeyboardArrowRight';

import { FormTask, useSubscription } from 'ui';
import { IQuerySubscription, formats, getErrorMessage, getParamID } from 'other';
import { AppService, TaskService } from 'services';

const useStyle = style(theme => ({
  root: {

  },

  wrapperCalendarMonth: {
    overflow: 'auto hidden'
  },

  calendarMonth: {
    '&.amaui-CalendarMonth-root': {
      width: '100%',
      height: '100%',
      minHeight: 740,
      flex: '0 0 auto',
      minWidth: 1024,
      transition: theme.methods.transitions.make('opacity')
    },

    '& .amaui-CalendarMonth-weeks': {
      width: 'calc(100% - 2px)',
      height: 'calc(100% - 50px)',
      marginLeft: 1,
      gap: 0
    },

    '& .amaui-CalendarMonth-week': {
      flex: '1 1 auto'
    },

    '& .amaui-CalendarMonth-day': {
      height: 'unset',
      marginTop: -0.5,
      marginLeft: -0.5,
      border: `0.5px solid ${theme.palette.light ? '#dadada' : '#575757'}`
    },

    '& .amaui-CalendarMonth-day-out': {
      zIndex: -1
    },

    '& .amaui-CalendarMonth-day-name': {
      justifyContent: 'center',
      paddingInlineStart: 14,
      paddingTop: 14
    }
  },

  dayWrapper: {
    height: '100%'
  },

  dayWrapperToday: {
    background: theme.palette.background.primary[theme.palette.light ? 'tertiary' : 'quaternary']
  },

  day: {
    padding: 14,
    cursor: 'default',
    userSelect: 'none'
  },

  dayTasks: {
    height: 0,
    overflow: 'hidden auto',
    padding: '0 8px 12px'
  },

  dayTask: {
    padding: '4px 8px',
    color: theme.methods.palette.color.text(theme.palette.background.secondary.quaternary),
    background: theme.palette.background.secondary.quaternary,
    borderRadius: 4,
    cursor: 'pointer',
    transition: theme.methods.transitions.make('transform'),

    '&:active': {
      transform: 'scale(0.97)'
    }
  },

  dayTaskName: {
    width: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },

  month: {
    cursor: 'default',
    userSelect: 'none'
  },

  ...theme.classes(theme)
}), { name: 'amaui-app-route-TasksMonth' });

const TasksMonth = React.forwardRef((props: any, ref: any) => {
  const {
    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const confirm = useConfirm();
  const snackbars = useSnackbars();
  const theme = useAmauiTheme();
  const location = useLocation();

  const queryTasks = useSubscription<IQuerySubscription>(TaskService.queryTasks);

  const touch = useMediaQuery('(pointer: coarse)');

  const [value] = React.useState(new AmauiDate());
  const [date, setDate] = React.useState(new AmauiDate());
  const [loading] = React.useState(false);

  const refs = {
    queryTasks: React.useRef(queryTasks),
    date: React.useRef(date),
    tasksPerDay: React.useRef<Record<string, ITask[]>>({}),
    opened: React.useRef<any>()
  };

  refs.queryTasks.current = queryTasks;

  refs.date.current = date;

  const tasks = queryTasks.response || [];

  refs.tasksPerDay.current = {};

  tasks.forEach((task: ITask) => {
    if (!task.ends_at) return;

    const day = format(new AmauiDate(task.ends_at), 'DD-MM-YYYY');

    if (!refs.tasksPerDay.current[day]) refs.tasksPerDay.current[day] = [];

    refs.tasksPerDay.current[day].push(task);
  });

  React.useEffect(() => {
    const id = getParamID();

    if (id && id !== refs.opened.current?.id) onOpen({ id });
  }, [location]);

  const onClose = React.useCallback(() => {
    // initial
    // unopened
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });

    const previous = '/tasks/month';

    if (previous !== location.pathname) {
      window.history.pushState(undefined, '', previous);
    }
  }, [location]);

  const init = React.useCallback(async () => {
    const result = await TaskService.queryTasks.value!.query({
      query: {
        query: {
          done: false,
          lists_count: 0,
          ends_at_from: startOf(refs.date.current, 'month').milliseconds,
          ends_at_to: endOf(refs.date.current, 'month').milliseconds
        },

        limit: 'all'
      }
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      const tasks_ = result.response.response || [];

      const id = getParamID();

      if (id) {
        const task = tasks_.find((item: ITask) => id === item.id) || (id && (await TaskService.get(id)).response?.response);

        if (task) {
          onOpen(task);
        }
        else {
          onClose();
        }
      }
    }
  }, []);

  React.useEffect(() => {
    // init 
    init();
  }, [date]);

  const onRemove = React.useCallback(async (value: ITask) => {
    if (!(await confirm.open({ name: `Removing ${textToInnerHTML(value.name) || 'a'} task` }))) return;

    const result = await TaskService.remove(value.id);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Task removed`
      });
    }

    // reset
    TaskService.selectedTasks.value!.reset();

    // refresh
    TaskService.queryTasks.value!.refetch(true);
  }, []);

  const onOpen = React.useCallback(async (task_: ITask) => {
    const id = task_?.id;

    const object = id ? refs.queryTasks.current.response?.find((item: ITask) => id === item.id) || (id && (await TaskService.get(id)).response?.response) : null;

    if (!object) {
      refs.opened.current = null;

      onClose();

      return;
    }

    refs.opened.current = object;

    AppService.pages.add.emit({
      id: object.id,
      open: true,
      version: touch ? 'entire' : 'inline',
      route: {
        to: `/tasks/month/${object.id}`,
        previous: `/tasks/month`
      },
      children: (
        <FormTask
          key={object.id}

          object={object}

          onRemove={() => onRemove(object)}

          onConfirm={() => TaskService.queryTasks.value!.refetch(true)}

          modal={touch}
        />
      )
    });
  }, [touch]);

  const onAdd = React.useCallback(() => {
    AppService.pages.add.emit({
      open: true,
      version: touch ? 'entire' : 'inline',
      route: {
        to: `/tasks/month/add`,
        previous: `/tasks/month`
      },
      children: (
        <FormTask
          onConfirm={() => TaskService.queryTasks.value!.refetch(true)}

          singular='task'

          plural='tasks'
        />
      )
    });
  }, [AppService.pages.add, AppService.pages.addSecondary]);

  const cleanUp = React.useCallback(() => {
    TaskService.selectedTasks.value!.reset();
  }, []);

  React.useEffect(() => {
    // init
    init();

    return () => {
      cleanUp();
    };
  }, []);

  const refresh = React.useCallback(() => {
    // setValue(previous => new AmauiDate(previous));
  }, []);

  const updated = tasks?.reduce((result: any, item: any) => result += item.id, '');

  React.useEffect(() => {
    refresh();
  }, [updated]);

  const onPreviousCalendar = React.useCallback(() => {
    const valueNew = remove(1, 'month', refs.date.current);

    setDate(new AmauiDate(valueNew));
  }, []);

  const onNextCalendar = React.useCallback(() => {
    const valueNew = add(1, 'month', refs.date.current);

    setDate(new AmauiDate(valueNew));
  }, []);

  const onToday = React.useCallback(() => {
    setDate(AmauiDate.amauiDate);
  }, []);

  const renderDay = React.useCallback((value_: AmauiDate, propsDay: any, day: any, outside: boolean) => {
    const dayFormated = format(value_, 'DD-MM-YYYY');

    const tasksDay = refs.tasksPerDay.current[dayFormated];

    return (
      <Line
        gap={0}

        direction='column'

        align='unset'

        justify='unset'

        flex

        fullWidth

        className={classNames([
          classes.dayWrapper,
          day.today && classes.dayWrapperToday
        ])}
      >
        <Line
          direction='row'

          justify='flex-start'

          align='center'

          fullWidth

          className={classes.day}
        >
          <Type
            version='b3'
          >
            {format(value_, 'DD')}
          </Type>
        </Line>

        <Line
          gap={0.5}

          flex

          fullWidth

          className={classes.dayTasks}
        >
          {tasksDay?.map((task: ITask, index: number) => (
            <Line
              key={index}

              onClick={() => onOpen(task)}

              fullWidth

              className={classes.dayTask}
            >
              <Type
                version='b3'

                className={classes.dayTaskName}
              >
                {textToInnerHTML(task.name)}
              </Type>
            </Line>
          ))}
        </Line>
      </Line>
    );
  }, [onOpen]);

  const renderDayName = React.useCallback((order: number) => {
    const values: any = {
      1: 'Mon',
      2: 'Tue',
      3: 'Wed',
      4: 'Thu',
      5: 'Fri',
      6: 'Sat',
      7: 'Sun'
    };

    return values[order];
  }, []);

  const onChangeMonth = React.useCallback((valueNew: any) => setDate(set(valueNew, 'month', refs.date.current)), []);

  const onChangeYear = React.useCallback((valueNew: any) => setDate(set(valueNew, 'year', refs.date.current)), []);

  const monthOptions = React.useMemo(() => {
    return months.map((item, index: number) => ({
      name: item,
      value: index
    }));
  }, [months]);

  const yearOptions = React.useMemo(() => {
    const values = [];

    for (let i = 1970; i <= 2070; i++) values.push({ name: i, value: i });

    return values;
  }, []);

  const elementProps: any = {

  };

  return <>
    <Helmet>
      <title>Task calendar</title>
      <link rel='icon' type='image/svg' sizes='32x32' href={`/assets/svg/logos/${theme.palette.light ? 'light' : 'dark'}/logo-task.svg`} />
      <link rel='icon' type='image/svg' sizes='16x16' href={`/assets/svg/logos/${theme.palette.light ? 'light' : 'dark'}/logo-task.svg`} />
      <meta name='theme-color' content={theme.palette.light ? (theme.palette.color.task as any).main : theme.palette.color.task[20]} />
    </Helmet>

    <Line
      ref={ref}

      gap={0}

      direction='column'

      justify='unset'

      align='unset'

      flex

      fullWidth

      className={classNames([
        className,
        classes.root,
        classes.wrapper
      ])}

      {...other}
    >
      <Line
        gap={3}

        align='center'

        fullWidth
      >
        <Line
          gap={1}

          direction={{
            default: 'row',
            xxs: 'column',
            xs: 'column',
            sm: 'column'
          }}

          align='center'

          justify='space-between'

          fullWidth
        >
          <Type
            version='h3'

            align='center'
          >
            Task calendar
          </Type>

          <Button
            color='primary'

            version='filled'

            size='small'

            onClick={onAdd}
          >
            Add task
          </Button>
        </Line>

        <Line
          direction='row'

          align='center'

          justify='space-between'

          fullWidth

          style={{
            marginBottom: 8
          }}
        >
          <Tooltip
            name='Previous month'
          >
            <IconButton
              onClick={onPreviousCalendar}

              {...elementProps}
            >
              <IconMaterialKeyboardArrowLeftRounded />
            </IconButton>
          </Tooltip>

          <Line
            gap={1}

            align='center'

            fullWidth
          >
            <Line
              gap={1}

              align='center'

              fullWidth
            >
              <Line
                gap={1}

                direction={{
                  default: 'row',
                  xxs: 'column',
                  xs: 'column',
                  sm: 'column'
                }}

                align='center'
              >
                <Select
                  name='Month'

                  value={monthOptions.find(item => item.value === date.month - 1)?.value}

                  onChange={onChangeMonth}

                  options={monthOptions as any}

                  size='small'
                />

                <Select
                  name='Year'

                  value={yearOptions.find(item => item.value === date.year)?.value}

                  onChange={onChangeYear}

                  options={yearOptions as any}

                  size='small'
                />
              </Line>
            </Line>

            <Line
              gap={1}

              direction='row'

              align='center'

              className={classes.actions}
            >
              <Chip
                onClick={onToday}

                selected={format(date, formats.date) === format(AmauiDate.amauiDate, formats.date)}

                size='small'

                {...elementProps}
              >
                Today
              </Chip>
            </Line>
          </Line>

          <Tooltip
            name='Next month'
          >
            <IconButton
              onClick={onNextCalendar}

              {...elementProps}
            >
              <IconMaterialKeyboardArrowRightRounded />
            </IconButton>
          </Tooltip>
        </Line>
      </Line>

      <Line
        flex

        fullWidth

        className={classes.wrapperCalendarMonth}
      >
        <CalendarMonth
          value={value as any}

          calendar={date as any}

          renderDay={renderDay as any}

          renderDayName={renderDayName}

          size='large'

          dayNamesFull

          noTransition

          outside={false}

          disabled={loading}

          className={classes.calendarMonth}
        />
      </Line>
    </Line >
  </>;
});

export default TasksMonth;
