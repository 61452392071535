import React from 'react';

import { Form, FormRow, Label, Line, ListItem, Switch, Type, useForm, useSnackbars } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';

import { Button, Select, SelectAny, useSubscription } from 'ui';
import { AuthService, UserService } from 'services';
import { ISignedIn, getErrorMessage, getSettings } from 'other';
import { is } from '@amaui/utils';

const useStyle = style(theme => ({
  root: {

  },

  avatar: {
    width: 54,
    height: 54,
    borderRadius: '50%',
    transition: theme.methods.transitions.make('transform', { duration: 'xs' }),

    '&.amaui-Button-root': {
      cursor: 'pointer',
      userSelect: 'none'
    },

    '&:active': {
      transform: 'scale(0.94)'
    }
  },

  ...theme.classes(theme)
}), { name: 'amaui-app-route-SettingsApp' });

const languages = {
  speech: [
    ['Afrikaans', ['af-ZA']],
    ['Bahasa Indonesia', ['id-ID']],
    ['Bahasa Melayu', ['ms-MY']],
    ['Català', ['ca-ES']],
    ['Čeština', ['cs-CZ']],
    ['Deutsch', ['de-DE']],
    ['English', ['en-AU', 'Australia'],
      ['en-CA', 'Canada'],
      ['en-IN', 'India'],
      ['en-NZ', 'New Zealand'],
      ['en-ZA', 'South Africa'],
      ['en-GB', 'United Kingdom'],
      ['en-US', 'United States']],
    ['Español', ['es-AR', 'Argentina'],
      ['es-BO', 'Bolivia'],
      ['es-CL', 'Chile'],
      ['es-CO', 'Colombia'],
      ['es-CR', 'Costa Rica'],
      ['es-EC', 'Ecuador'],
      ['es-SV', 'El Salvador'],
      ['es-ES', 'España'],
      ['es-US', 'Estados Unidos'],
      ['es-GT', 'Guatemala'],
      ['es-HN', 'Honduras'],
      ['es-MX', 'México'],
      ['es-NI', 'Nicaragua'],
      ['es-PA', 'Panamá'],
      ['es-PY', 'Paraguay'],
      ['es-PE', 'Perú'],
      ['es-PR', 'Puerto Rico'],
      ['es-DO', 'República Dominicana'],
      ['es-UY', 'Uruguay'],
      ['es-VE', 'Venezuela']],
    ['Euskara', ['eu-ES']],
    ['Français', ['fr-FR']],
    ['Galego', ['gl-ES']],
    ['Hrvatski', ['hr_HR']],
    ['IsiZulu', ['zu-ZA']],
    ['Íslenska', ['is-IS']],
    ['Italiano', ['it-IT', 'Italia'],
      ['it-CH', 'Svizzera']],
    ['Magyar', ['hu-HU']],
    ['Nederlands', ['nl-NL']],
    ['Norsk bokmål', ['nb-NO']],
    ['Polski', ['pl-PL']],
    ['Português', ['pt-BR', 'Brasil'],
      ['pt-PT', 'Portugal']],
    ['Română', ['ro-RO']],
    ['Slovenčina', ['sk-SK']],
    ['Suomi', ['fi-FI']],
    ['Svenska', ['sv-SE']],
    ['Türkçe', ['tr-TR']],
    ['български', ['bg-BG']],
    ['Pусский', ['ru-RU']],
    ['Српски', ['sr-RS']],
    ['한국어', ['ko-KR']],
    ['中文', ['cmn-Hans-CN', '普通话 (中国大陆)'],
      ['cmn-Hans-HK', '普通话 (香港)'],
      ['cmn-Hant-TW', '中文 (台灣)'],
      ['yue-Hant-HK', '粵語 (香港)']],
    ['日本語', ['ja-JP']],
    ['Lingua latīna', ['la']]
  ]
};

const SettingsApp = React.forwardRef((props: any, ref: any) => {
  const {
    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const snackbars = useSnackbars();
  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const [loading, setLoading] = React.useState<any>(false);

  const form = useForm({
    values: {
      'settings.languages.app': {
        name: 'App language',
        is: 'string',
        value: getSettings(signedIn, 'settings.languages.app') || 'en-US'
      },
      'settings.languages.speech': {
        name: 'Speech language',
        is: 'string',
        value: getSettings(signedIn, 'settings.languages.speech') || 'en-US'
      },
      'settings.home_page': {
        name: 'App home page',
        value: getSettings(signedIn, 'settings.home_page') || '',
        is: 'string'
      },
      'settings.main': {
        name: 'App main page',
        value: getSettings(signedIn, 'settings.main') || '',
        is: 'string'
      },
      'settings.theme.palette.light': {
        name: 'Theme light',
        value: getSettings(signedIn, 'settings.theme.palette.light') || 'auto',
        is: 'string',
        in: ['light', 'dark', 'auto']
      },
      'settings.theme.options.motion': {
        name: 'Theme motion',
        value: !(getSettings(signedIn, 'settings.theme.options.motion') !== undefined ? getSettings(signedIn, 'settings.theme.options.motion') : true),
        is: 'boolean'
      },
      'settings.theme.palette.accessibility': {
        name: 'Theme accessibility',
        value: getSettings(signedIn, 'settings.theme.palette.accessibility') || 'regular',
        is: 'string',
        in: ['regular', 'colorblind', 'tritanopia']
      },
      'settings.theme.preference.visual_contrast.default': {
        name: 'Theme visual contrast',
        value: getSettings(signedIn, 'settings.theme.preference.visual_contrast.default') || 'regular',
        is: 'string',
        in: ['regular', 'low', 'high']
      },
      'settings.theme.typography.font_size.html': {
        name: 'Theme typography font size',
        value: getSettings(signedIn, 'settings.theme.typography.font_size.html') || 16,
        is: 'number'
      }
    },
    valueDefault: {
      settings: {
        theme: {
          palette: {
            light: getSettings(signedIn, 'settings.theme.palette.light') || 'light'
          }
        }
      }
    }
  });

  const refs = {
    form: React.useRef(form)
  };

  refs.form.current = form;

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();

    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading('theme');

    if (refs.form.current.value.settings?.theme?.options?.motion !== undefined) refs.form.current.value.settings.theme.options.motion = !refs.form.current.value.settings?.theme?.options?.motion;

    const result = await UserService.updatePersonalization(refs.form.current.value);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: result.response?.meta?.message || 'Updated'
      });

      // refresh user
      // in the entire app
      await AuthService.me();
    }

    setLoading(false);
  }, [form]);

  const themeLightOptions = React.useMemo(() => {
    return [
      { label: 'Light', value: 'light' },
      { label: 'Dark', value: 'dark' },
      { label: 'Auto', value: 'auto' }
    ];
  }, []);

  const themeVisualContrastOptions = React.useMemo(() => {
    return [
      { label: 'Regular', value: 'regular' },
      { label: 'Low', value: 'low' },
      { label: 'High', value: 'high' }
    ];
  }, []);

  const themePaletteAccessibilityOptions = React.useMemo(() => {
    return [
      { label: 'Regular', value: 'regular' },
      { label: 'Color blind', value: 'colorblind' },
      { label: 'Tritanopia', value: 'tritanopia' }
    ];
  }, []);

  const themeZoomOptions = React.useMemo(() => {
    return [
      { label: '75%', value: 12 },
      { label: '100%', value: 16 },
      { label: '125%', value: 20 },
      { label: '150%', value: 24 },
      { label: '175%', value: 28 },
      { label: '200%', value: 32 }
    ];
  }, []);

  const languageOptions = React.useMemo(() => {
    const results: any = [];

    languages.speech.forEach(([category, ...options]) => {
      (options as any).forEach((option: any) => {
        const value_ = is('string', option) ? option : option[0];
        const name_ = is('string', option) ? '' : option[1];

        results.push({ name: `${category}${name_ ? `, ${name_}` : ''} ${value_}`, value: value_ });
      });
    });

    return results;
  }, [languages]);

  return (
    <Line
      ref={ref}

      gap={4}

      fullWidth

      className={classNames([
        className,
        classes.root,
        classes.wrapper
      ])}

      {...other}
    >
      <Form
        onSubmit={onSubmit}

        name='App'

        description='App preferences'

        footer={(
          <Button
            loading={loading === 'theme'}

            type='submit'

            size='small'
          >
            Update
          </Button>
        )}
      >
        <FormRow
          name='Home page'

          description='Choose one of the predefined home pages'
        >
          <Select
            version='outlined'

            valueDefault={getSettings(signedIn, 'settings.home_page') || ''}

            value={form.values['settings.home_page'].value || ''}

            error={!!form.values['settings.home_page'].error}

            helperText={form.values['settings.home_page'].error}

            onChange={(valueNew: string) => form.onChange('settings.home_page', valueNew)}

            options={[
              { name: 'Overview of apps', value: '' }
            ]}
          />
        </FormRow>

        <FormRow
          name='Default page'

          description='What your default page is upon opening the app, it can be any of the apps'
        >
          <Select
            version='outlined'

            valueDefault={getSettings(signedIn, 'settings.main') || ''}

            value={form.values['settings.main'].value || ''}

            error={!!form.values['settings.main'].error}

            helperText={form.values['settings.main'].error}

            onChange={(valueNew: string) => form.onChange('settings.main', valueNew)}

            options={[
              { name: 'Home page', value: '' },
              { name: 'Task', value: 'task' },
              { name: 'Note', value: 'note' },
              { name: 'Blog', value: 'blog' },
              { name: 'Resume', value: 'resume' },
              { name: 'URL shortener', value: 'urlShortener' },
              { name: 'Website', value: 'website' },
              { name: 'Support', value: 'support' },
              { name: 'Chat', value: 'chat' },
              { name: 'Form', value: 'form' },
              { name: 'Reservation', value: 'reservation' },
              { name: 'Contact', value: 'contact' },
              { name: 'Work day', value: 'workDay' },
              { name: 'Location', value: 'location' }
            ]}
          />
        </FormRow>

        <Form
          name='Languages'

          size='small'

          wrapper
        >
          <FormRow
            name='App'

            description='App language'
          >
            <SelectAny
              name='Language'

              options={languageOptions}

              valueDefault={getSettings(signedIn, 'settings.languages.app') || 'en-US'}

              value={form.values['settings.languages.app'].value || ''}

              onChange={(valueNew: string) => form.onChange('settings.languages.app', valueNew)}
            />
          </FormRow>

          <FormRow
            name='Speech'

            description={(
              <Type
                version='b3'
              >
                Language used for <b>Speech to text</b> and <b>Text to speech</b> features
              </Type>
            )}
          >
            <SelectAny
              name='Language'

              options={languageOptions}

              valueDefault={getSettings(signedIn, 'settings.languages.speech') || 'en-US'}

              value={form.values['settings.languages.speech'].value || ''}

              onChange={(valueNew: string) => form.onChange('settings.languages.speech', valueNew)}
            />
          </FormRow>
        </Form>

        <Form
          name='Theme'

          size='small'

          wrapper
        >
          <FormRow
            name='Mode'

            description='Light / dark mode'
          >
            <Select
              version='outlined'

              valueDefault={getSettings(signedIn, 'settings.theme.palette.light') || ''}

              value={form.values['settings.theme.palette.light'].value || ''}

              error={!!form.values['settings.theme.palette.light'].error}

              helperText={form.values['settings.theme.palette.light'].error}

              onChange={(valueNew: string) => form.onChange('settings.theme.palette.light', valueNew)}
            >
              {themeLightOptions.map((item: any, index: number) => (
                <ListItem
                  key={index}

                  primary={item.label}

                  value={item.value}

                  button
                />
              ))}
            </Select>
          </FormRow>

          <Form
            name='Accessiblity'

            size='small'

            accordion

            wrapper
          >
            <Select
              name='Zoom'

              version='outlined'

              valueDefault={getSettings(signedIn, 'settings.theme.typography.font_size.html') || 16}

              value={form.values['settings.theme.typography.font_size.html'].value || ''}

              error={!!form.values['settings.theme.typography.font_size.html'].error}

              helperText={form.values['settings.theme.typography.font_size.html'].error}

              onChange={(valueNew: string) => form.onChange('settings.theme.typography.font_size.html', valueNew)}
            >
              {themeZoomOptions.map((item: any, index: number) => (
                <ListItem
                  key={index}

                  primary={item.label}

                  value={item.value}

                  button
                />
              ))}
            </Select>

            <Select
              name='Color blind options'

              version='outlined'

              valueDefault={getSettings(signedIn, 'settings.theme.palette.accessibility') || 'regular'}

              value={form.values['settings.theme.palette.accessibility'].value || ''}

              error={!!form.values['settings.theme.palette.accessibility'].error}

              helperText={form.values['settings.theme.palette.accessibility'].error}

              onChange={(valueNew: string) => form.onChange('settings.theme.palette.accessibility', valueNew)}
            >
              {themePaletteAccessibilityOptions.map((item: any, index: number) => (
                <ListItem
                  key={index}

                  primary={item.label}

                  value={item.value}

                  button
                />
              ))}
            </Select>

            <Select
              name='Contrast'

              version='outlined'

              valueDefault={getSettings(signedIn, 'settings.theme.preference.visual_contrast.default') || 'regular'}

              value={form.values['settings.theme.preference.visual_contrast.default'].value || ''}

              error={!!form.values['settings.theme.preference.visual_contrast.default'].error}

              helperText={form.values['settings.theme.preference.visual_contrast.default'].error}

              onChange={(valueNew: string) => form.onChange('settings.theme.preference.visual_contrast.default', valueNew)}
            >
              {themeVisualContrastOptions.map((item: any, index: number) => (
                <ListItem
                  key={index}

                  primary={item.label}

                  value={item.value}

                  button
                />
              ))}
            </Select>

            <Label
              checked={form.values['settings.theme.options.motion'].value}

              onChange={(valueNew: boolean) => form.onChange('settings.theme.options.motion', valueNew)}
            >
              <Switch />

              Reduced motion
            </Label>
          </Form>
        </Form>
      </Form>
    </Line>
  );
});

export default SettingsApp;
