import React from 'react';

import { Select } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';

const useStyle = style(theme => ({
  root: {
    '&.amaui-Select-root': {
      minWidth: 221
    }
  }
}), { name: 'amaui-app-SelectAny' });

const SelectAny = React.forwardRef((props: any, ref: any) => {
  const {
    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const selectProps: any = {
    size: 'small'
  };

  return (
    <Select
      ref={ref}

      {...selectProps}

      {...other}

      className={classNames([
        className,
        classes.root
      ])}
    />
  );
});

export default SelectAny;
