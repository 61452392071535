import React from 'react';

import { Page } from 'ui';
import { OrganizationService } from 'services';
import { Type } from '@amaui/ui-react';
import { AmauiDate, format } from '@amaui/date';
import { formats } from 'other';

const cleanValue = (value_: string) => {
  let value = value_;

  ['-', '_'].forEach(filter => {
    const expression = `\\${filter}`;
    const regexp = new RegExp(expression, 'g');

    value = value ? value.replace(regexp, ' ') : '';
  });

  return `${value.charAt(0).toUpperCase()}${value.slice(1)}`;
};

const Invoices = React.forwardRef(() => {

  const getApp = (invoice: any) => {
    return cleanValue(invoice?.subscription_details?.metadata?.app as string);
  };

  const properties = React.useMemo(() => ({
    app: {
      name: 'App',
      method: (item: any) => (
        <Type
          version='l2'

          onClick={() => window.open(item.hosted_invoice_url)}

          data-name
        >
          {getApp(item)}
        </Type>
      ),
      view: false
    },
    number: { name: 'Number', method: (item: any) => item.number },
    status: { name: 'Status', method: (item: any) => cleanValue(item.status) },
    created: {
      name: 'Date created',
      method: (item: any) => format(new AmauiDate(item.created * 1e3), formats.entire),
      view: false
    }
  }), []);

  return (
    <Page
      name='Invoices'

      service={OrganizationService}

      columns={[
        properties.app,
        properties.number,
        properties.status,
        properties.created
      ]}

      properties={properties}

      queryItemsName='queryInvoices'

      app='blog'

      collection='invoices'

      route='/organization/settings/invoices'

      isStripe

      noTags

      noSearch

      noManage

      noAdd

      noTotal

      noAddedAt
    />
  );
});

export default Invoices;
