import React from 'react';
import { Helmet } from 'react-helmet';

import { capitalize, getGoogleFontsURL, getObjectValue, imageToPalette, is, rgbToHex, textToInnerHTML } from '@amaui/utils';
import { AutoComplete, ColorTextField, Form, FormRow, Label, Line, ListItem, Medias, SmartTextField, Switch, Type, useForm, useSnackbars } from '@amaui/ui-react';
import { style } from '@amaui/style-react';
import { Theme } from '@amaui/api-utils';

import { ModalForm, Select, SelectMedia, TextField } from 'ui';
import { AppService, ThemeService } from 'services';
import { FONTS, getErrorMessage, getMediaUrl, mediaToObject } from 'other';
import { Templates } from 'pages/main';

const useStyle = style(theme => ({
  root: {

  },

  surface: {
    '&.amaui-Surface-root': {
      background: theme.palette.color.primary[theme.palette.light ? 99 : 5]
    }
  },

  ...theme.classes(theme)
}), { name: 'amaui-app-Theme' });

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    object: object_,

    onConfirm
  } = props;

  const { classes } = useStyle();

  const snackbars = useSnackbars();

  const [object, setObject] = React.useState(object_);
  const [tab, setTab] = React.useState('Theme');
  const [loading, setLoading] = React.useState(false);

  const form = useForm({
    values: {
      'name': {
        name: 'Name',
        value: object?.name,
        required: true,
        max: 1400,
        messages: {
          min: 'Name has to be min 1 characters',
          max: 'Name can be max 1400 characters'
        }
      },
      'description': {
        name: 'Description',
        value: object?.description,
        max: 4400,
        messages: {
          min: 'Description has to be min 1 characters',
          max: 'Description can be max 4400 characters'
        }
      },

      'default': {
        name: 'Default',
        value: object?.default,
        is: 'boolean'
      },
      'active': {
        name: 'Active',
        value: object?.active !== undefined ? object?.active : true,
        is: 'boolean'
      },

      'media': {
        name: 'Media',
        value: object?.media || [],
        is: 'array'
      },

      'options.theme.typography.font_family.primary': {
        name: 'Theme font family primary',
        value: getObjectValue(object, 'options.theme.typography.font_family.primary'),
        is: 'string'
      },
      'options.theme.typography.font_family.secondary': {
        name: 'Theme font family secondary',
        value: getObjectValue(object, 'options.theme.typography.font_family.secondary'),
        is: 'string'
      },
      'options.theme.typography.font_family.tertiary': {
        name: 'Theme font family tertiary',
        value: getObjectValue(object, 'options.theme.typography.font_family.tertiary'),
        is: 'string'
      },

      'options.theme.palette.light': {
        name: 'Theme light',
        value: getObjectValue(object, 'options.theme.palette.light') || 'auto',
        is: 'string',
        in: ['light', 'dark', 'auto']
      },

      'options.theme.palette.image': {
        name: 'Theme image',
        value: getObjectValue(object, 'options.theme.palette.image'),
        is: 'object'
      },

      'options.theme.palette.color.primary.main': {
        name: 'Theme color primary',
        value: getObjectValue(object, 'options.theme.palette.color.primary.main'),
        is: 'string'
      },
      'options.theme.palette.color.secondary.main': {
        name: 'Theme color secondary',
        value: getObjectValue(object, 'options.theme.palette.color.secondary.main'),
        is: 'string'
      },
      'options.theme.palette.color.tertiary.main': {
        name: 'Theme color tertiary',
        value: getObjectValue(object, 'options.theme.palette.color.tertiary.main'),
        is: 'string'
      },
      'options.theme.palette.color.quaternary.main': {
        name: 'Theme color quaternary',
        value: getObjectValue(object, 'options.theme.palette.color.quaternary.main'),
        is: 'string'
      }
    }
  });

  const refs = {
    form: React.useRef(form)
  };

  refs.form.current = form;

  const onClose = React.useCallback(() => {
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });
  }, []);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();
  }, []);

  const onNext = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();

    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const body: Theme = {
      ...refs.form.current.value
    };

    const result = !object?.id ? await ThemeService.add(body) : await ThemeService.update(object?.id, body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Theme ${!object?.id ? 'added' : 'updated'}`
      });

      setObject(result.response.response);

      if (is('function', onConfirm)) onConfirm();

      if (!object?.id) onClose();
    }

    setLoading(false);
  }, [object, form, onConfirm, onClose]);

  const onChangeTab = React.useCallback((value: any) => {
    setTab(value);
  }, []);

  const onOptionsThemeImageConfirm = React.useCallback(async (valueNew: any) => {
    const value = valueNew;

    const palette = await imageToPalette(getMediaUrl(value, 480), { amount: 4, size: 140, allowCrossOrigin: true });

    form.onChange([
      ['options.theme.palette.image', value],
      ['options.theme.palette.color.primary.main', rgbToHex(palette[0])],
      ['options.theme.palette.color.secondary.main', rgbToHex(palette[1])],
      ['options.theme.palette.color.tertiary.main', rgbToHex(palette[2])],
      ['options.theme.palette.color.quaternary.main', rgbToHex(palette[3])]
    ]);
  }, [form]);

  const themeLightOptions = React.useMemo(() => {
    return [
      { label: 'Light', value: 'light' },
      { label: 'Dark', value: 'dark' },
      { label: 'Auto', value: 'auto' }
    ];
  }, []);

  const fontFamilyOptions = Object.keys(FONTS).flatMap((key: string) => (FONTS as any)[key]).map((item: any) => ({ ...item, value: item.label }));

  const allFontFamilies = Object.keys(FONTS).flatMap(item => (FONTS as any)[item]);

  const getValueFontFamily = (valueFontFamily: string) => allFontFamilies.find((item: any) => item.label.includes(valueFontFamily));

  const typographyFontFamilies = [
    { name: 'primary', description: 'Used for headings, titles, labels, more important text' },
    { name: 'secondary', description: 'Used for paragraphs, less important text' },
    { name: 'tertiary', description: 'Used for ie. code examples' }
  ];

  const colorOptions = [
    { name: 'primary' },
    { name: 'secondary' },
    { name: 'tertiary' },
    { name: 'quaternary' }
  ];

  const modal: any = {
    'Theme': <>
      <Line
        fullWidth

        className={classes.page}
      >
        <Line
          gap={1}

          fullWidth
        >
          <TextField
            name='Name'

            valueDefault={textToInnerHTML(form.values['name'].value)}

            onChange={(valueNew: any) => form.onChange('name', valueNew, undefined, { rerenderOnUpdate: false })}
          />

          <SmartTextField
            placeholder='Description'

            valueDefault={textToInnerHTML(form.values['description'].value || '')}

            onChange={(valueNew: any) => form.onChange('description', valueNew, undefined, { rerenderOnUpdate: false })}

            minRows={4}

            multiline

            edit
          />
        </Line>

        <SelectMedia
          value={form.value.media}

          selected={form.value.media}

          mime='image'

          onChange={(valueNew: any) => form.onChange('media', valueNew)}

          multiple
        />

        {!!form.value.media?.length && (
          <Medias
            size='regular'

            values={form.value.media.map((item: any) => ({
              value: mediaToObject(item)
            }))}
          />
        )}
      </Line>
    </>,

    'Options': <>
      <Line
        gap={2}

        fullWidth

        className={classes.page}
      >
        <Form
          gap={2}
        >
          <Label
            checked={form.values.default?.value}

            onChange={(valueNew: boolean) => form.onChange('default', valueNew)}

            error={!!form.values.default.error}

            helperText={form.values.default.error}
          >
            <Switch />

            Default
          </Label>

          <Label
            checked={form.values.active?.value}

            onChange={(valueNew: boolean) => form.onChange('active', valueNew)}

            error={!!form.values.active.error}

            helperText={form.values.active.error}
          >
            <Switch />

            Active
          </Label>
        </Form>
      </Line>
    </>,

    'Style': <>
      <Line
        gap={2}

        fullWidth

        className={classes.page}
      >
        <FormRow
          name='Palette'
        >
          <Select
            name='Light'

            version='outlined'

            valueDefault={getObjectValue(object, 'options.theme.palette.light') || 'auto'}

            value={form.values['options.theme.palette.light'].value || ''}

            error={!!form.values['options.theme.palette.light'].error}

            helperText={form.values['options.theme.palette.light'].error}

            onChange={(valueNew: string) => form.onChange('options.theme.palette.light', valueNew)}
          >
            {themeLightOptions.map((item: any, index: number) => (
              <ListItem
                key={index}

                primary={item.label}

                value={item.value}

                button
              />
            ))}
          </Select>

          <FormRow
            description='Make the palette from an image'
          >
            <SelectMedia
              value={form.values[`options.theme.palette.image`].value}

              onChange={onOptionsThemeImageConfirm}
            />
          </FormRow>

          {colorOptions.map((item: any, index: number) => (
            <ColorTextField
              key={index}

              name={`${capitalize(item.name)} color`}

              value={form.values[`options.theme.palette.color.${item.name}.main`].value || ''}

              onChange={(valueNew: any) => form.onChange(`options.theme.palette.color.${item.name}.main`, valueNew)}
            />
          ))}
        </FormRow>

        <FormRow
          name='Typography'
        >
          {typographyFontFamilies.map((item: any, index: number) => (
            <FormRow
              key={index}

              name={(
                <Type
                  version='l3'
                >
                  {capitalize(item.name)} font
                </Type>
              )}

              description={item.description}
            >
              <AutoComplete
                value={getValueFontFamily(form.values[`options.theme.typography.font_family.${item.name}`].value)}

                onChange={(valueNew: any) => form.onChange(`options.theme.typography.font_family.${item.name}`, valueNew?.label)}

                valueInputDefault={form.values[`options.theme.typography.font_family.${item.name}`].value || ''}

                options={fontFamilyOptions}

                groupBy={(item: any) => item?.family}

                noOptions={false}

                renderOption={(item: any, index: number, props: any) => {

                  return (
                    <ListItem
                      key={index}

                      {...props}

                      primary={(
                        <Type
                          style={{
                            fontFamily: `${item.label}, ${item.family.replace(' ', '-')}`
                          }}
                        >
                          {item.label}
                        </Type>
                      )}

                      value={item.label}

                      button
                    />
                  );
                }}

                InputProps={{
                  style: {
                    fontFamily: form.values[`options.theme.typography.font_family.${item.name}`].value
                  }
                }}

                style={{
                  fontFamily: form.values[`options.theme.typography.font_family.${item.name}`].value
                }}
              />
            </FormRow>
          ))}
        </FormRow>
      </Line>
    </>,

    'Templates': (
      <Line
        className={classes.page}
      >
        <Templates
          theme={object}

          version='theme'

          page={false}

          website={{
            name: 'Website',
            options: form.value.options,
            added_at: 1114,

            theme: {
              id: object?.id || object?._id,
              name: object?.name
            }
          }}
        />
      </Line>
    )
  };

  const googleFonts: any = [];

  Object.keys(FONTS).forEach((item: string) => {
    (FONTS as any)[item].forEach((font: any) => {
      if (font.category === 'google') googleFonts.push({ ...font, weights: [400, 700] });
    });
  });

  return <>
    <Helmet>
      <link
        href={getGoogleFontsURL(googleFonts.map((item: any) => ({ ...item, name: item.label })))}
        rel='stylesheet'
      />
    </Helmet>

    <ModalForm
      {...props}

      object={object}

      add

      tabDefault='Theme'

      tab={tab}

      tabs={['Theme', 'Options', 'Style', 'Templates']}

      onChangeTab={onChangeTab}

      onSubmit={onSubmit}

      onNext={onNext}

      onClose={onClose}

      loading={loading}
    >
      {modal[tab]}
    </ModalForm>
  </>;
});

export default Element;
