import React from 'react';

import { FormInvites, Page } from 'ui';
import { InviteService } from 'services';

const Invites = React.forwardRef(() => {
  const isEnabledAction = React.useCallback((item: any, action: string) => {
    if (['active', 'export', 'remove'].includes(action) && item.resolved) return false;

    return true;
  }, []);

  const properties = React.useMemo(() => ({
    user: {
      name: 'User',
      method: (item: any) => item.to?.email || ''
    },
    resolved: {
      name: 'Status',
      method: (item: any) => !item.resolved ? 'Pending' : 'Resolved'
    },
    active: { name: 'Active' }
  }), []);

  return (
    <Page
      name='Invites'

      service={InviteService}

      columns={[
        properties.user,
        properties.resolved,
        properties.active
      ]}

      search={['active']}

      options={['active']}

      properties={properties}

      isEnabledAction={isEnabledAction}

      app='amaui'

      collection='users'

      route='/organization/settings/invites'

      Form={FormInvites}

      singular='invite'

      plural='invites'

      noOpen

      noTags

      noSelect

      noTextSearch

      noActions

      noUpdateProject
    />
  );
});

export default Invites;
