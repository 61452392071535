import React from 'react';
import ReactPDF from '@react-pdf/renderer';

import { capitalize, cleanValue, getID, innerHTMLToText, is, merge, slugify, stringify, textToInnerHTML } from '@amaui/utils';
import { Form, FormRow, IconButton, Interaction, Label, Line, Link, ListItem, Medias, Properties, Select, Switch, Tooltip, Type, useForm, useMediaQuery, useSnackbars } from '@amaui/ui-react';
import { AmauiTheme, classNames, style, useAmauiTheme } from '@amaui/style-react';
import { DefaultResumePDF } from '@amaui/themes';
import { AmauiDate, format } from '@amaui/date';
import { Website } from '@amaui/api-utils';

import IconMaterialAddRounded from '@amaui/icons-material-rounded-react/IconMaterialAdd';
import IconMaterialEditRounded from '@amaui/icons-material-rounded-react/IconMaterialEdit';
import IconMaterialRemoveRounded from '@amaui/icons-material-rounded-react/IconMaterialRemove';
import IconMaterialDeleteRounded from '@amaui/icons-material-rounded-react/IconMaterialDelete';

import ImagePDFDefault from 'assets/images/resume/pdf/pdf-Default.png';

import { AutoCompleteObjects, Experience, ModalForm, OptionsSEO, OptionsShare, OptionsWebsites, PageBuilder, SelectMedia, TextField } from 'ui';
import { AppService, ResumeService, SkillService, WebsiteService } from 'services';
import { CONTACT_OPTIONS, formats, getErrorMessage, getMediaUrl, readTime } from 'other';

const useStyle = style(theme => ({
  root: {
    '& .amaui-TextField-root': {
      minWidth: 'unset !important'
    }
  },

  surface: {
    '&.amaui-Surface-root': {
      background: theme.palette.color.primary[theme.palette.light ? 99 : 5]
    }
  },

  viewer: {
    width: '100%',
    flex: '1 1 auto'
  },

  preview: {
    marginTop: 24
  },

  objects: {
    marginTop: 24
  },

  imagePreview: {
    width: 177,
    height: 100,
    backgroundColor: theme.palette.background.primary[theme.palette.light ? 'tertiary' : 'quaternary'],
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    borderRadius: 12,
    cursor: 'pointer',
    userSelect: 'none',
    transition: theme.methods.transitions.make('transform', { duration: 'sm' }),

    '&:active': {
      transform: 'scale(0.97)'
    }
  },

  paddingRegular: {
    padding: 24
  },

  paddingSmall: {
    padding: 12
  },

  pdfDesign: {
    maxWidth: 240,
    cursor: 'pointer',
    userSelect: 'none',
    transition: theme.methods.transitions.make('transform', { duration: 'sm' }),

    '&:active': {
      transform: 'scale(0.97)'
    }
  },

  pdfDesignImage: {
    position: 'relative',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center top',
    aspectRatio: '3 / 4',
    borderRadius: theme.methods.shape.radius.value(3),
    border: '2px solid transparent',
    transition: theme.methods.transitions.make('boder')
  },

  pdfDesignImageSelected: {
    border: `2px solid ${theme.palette.text.secondary.primary}`
  },

  ...theme.classes(theme)
}), { name: 'amaui-app-Resume' });

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    object: object_,

    onConfirm
  } = props;

  const { classes } = useStyle();

  const snackbars = useSnackbars();
  const theme = useAmauiTheme();

  const touch = useMediaQuery('(pointer: coarse)');

  const [object, setObject] = React.useState(object_);
  const [tab, setTab] = React.useState(object ? 'Resume' : 'Options');
  const [openObject, setOpenObject] = React.useState<any>();
  const [loading, setLoading] = React.useState<any>(false);
  const [website, setWebsite] = React.useState<Website>();

  const form = useForm({
    values: {
      'name': {
        name: 'Name',
        value: object?.name,
        required: true,
        max: 1400,
        messages: {
          min: 'Name has to be min 1 characters',
          max: 'Name can be max 1400 characters'
        }
      },
      'short_description': {
        name: 'Summary',
        value: object?.short_description,
        max: 1400,
        messages: {
          min: 'Summary has to be min 1 characters',
          max: 'Summary can be max 1400 characters'
        }
      },
      'description': {
        name: 'Description',
        value: object?.description,
        max: 4400,
        messages: {
          min: 'Description has to be min 1 characters',
          max: 'Description can be max 4400 characters'
        }
      },
      'image': {
        name: 'Image',
        value: object?.image
      },
      'contacts': {
        name: 'Contacts',
        value: object?.contacts,
        is: 'array',
        of: 'object'
      },
      'value': {
        name: 'Value',
        value: object?.value,
        is: 'array',
        of: 'object'
      },
      'active': {
        name: 'Active',
        value: object?.active,
        is: 'boolean'
      },
      'archived': {
        name: 'Archived',
        value: object?.archived,
        is: 'boolean'
      },
      'seo.name': {
        name: 'SEO name',
        value: object?.seo?.name,
        min: 1,
        max: 70,
        messages: {
          min: 'SEO name has to be min 1 characters',
          max: 'SEO name can be max 70 characters'
        }
      },
      'seo.description': {
        name: 'SEO description',
        value: object?.seo?.description,
        min: 1,
        max: 164,
        messages: {
          min: 'SEO description has to be min 1 characters',
          max: 'SEO description can be max 164 characters'
        }
      },
      'seo.image': {
        name: 'SEO image',
        value: object?.seo?.image
      },
      'pdf_theme': {
        name: 'PDF theme',
        value: object?.pdf_theme || { name: 'Default' }
      },
      'websites': {
        name: 'Websites',
        value: object?.websites || [],
        is: 'array',
        of: 'object'
      },
      'options.share.active': {
        name: 'Options share active',
        value: object?.options?.share?.active,
        is: 'boolean'
      }
    }
  });

  const formSkills = useForm({
    values: {
      skills: {
        name: 'Skills',
        value: [],
        is: 'array',
        of: 'object'
      }
    }
  });

  const refs = {
    form: React.useRef<any>(),
    formSkills: React.useRef<any>(),
    autoCompleteInput: React.useRef<any>()
  };

  refs.form.current = form;

  refs.formSkills.current = formSkills;

  const init = React.useCallback(async () => {
    if (object?.id) {
      const valuesOther: any = [];
      const valuesSkills: any = [];

      if (is('array', object.value)) object.value.forEach((object_: any) => {
        object_?.version !== 'skill' ? valuesOther.push(object_) : valuesSkills.push(object_);
      });

      valuesOther.forEach((otherItem: any) => {
        if (is('array', otherItem?.skills)) otherItem.skills = otherItem.skills.map((otherItemSkill: any) => ({
          ...otherItemSkill,

          value: otherItemSkill?.id
        }));

        if (is('array', otherItem?.portfolios)) otherItem.portfolios = otherItem.portfolios.map((otherItemSkill: any) => ({
          ...otherItemSkill,

          value: otherItemSkill?.id
        }));
      });

      if (is('array', object?.websites)) object.websites = object.websites.map((objectWebsite: any) => ({
        ...objectWebsite,

        value: objectWebsite?.id
      }));

      refs.form.current.onChange([
        ['value', valuesOther],
        ['websites', object?.websites || []]
      ]);

      refs.formSkills.current.onChange('skills', valuesSkills?.map((skillItem: any) => ({
        ...skillItem,

        value: skillItem?.id
      })));
    }
  }, [object]);

  const initWebsite = React.useCallback(async () => {
    const id = refs.form.current.value.websites?.[0]?.id;

    if (!id) {
      setWebsite(null as any);

      return;
    }

    const response = await WebsiteService.get(id);

    if (response.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(response)
      });
    }
    else {
      setWebsite(response.response.response);
    }
  }, []);

  React.useEffect(() => {
    // init
    init();
  }, []);

  React.useEffect(() => {
    // init
    initWebsite();
  }, [refs.form.current.value.websites?.[0]?.id]);

  const onOpenObject = React.useCallback(async (item: any, version?: string) => {
    const index = item && form.values.value?.value?.findIndex((item_: any) => item_.id === item?.id);

    setOpenObject({
      open: true,
      item,
      version: version || item?.version,
      index
    });
  }, [form]);

  const onRemoveObject = React.useCallback((item: any) => {
    const valueNew = [...form.values.value.value || []];

    const index = valueNew.findIndex(item_ => item_.id === item.id);

    if (index > -1) {
      valueNew.splice(index, 1);

      form.onChange('value', valueNew);
    }
  }, [form]);

  const onCloseObject = React.useCallback(() => {
    setOpenObject((previous: any) => ({
      ...previous,

      open: false
    }));
  }, []);

  const onClose = React.useCallback(() => {
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });
  }, []);

  const onRefreshOptions = React.useCallback(async (override = true) => {
    await refs.form.current.validate();

    if (form.values.name?.value) {
      if (
        override ||
        !form.values.url?.value
      ) {
        form.onChange('url', slugify(form.values.name?.value));
      }

      if (
        override ||
        !form.values['seo.name']?.value
      ) {
        form.onChange('seo.name', form.values.name?.value);
      }
    }

    if (form.values.short_description?.value) {
      if (
        override ||
        !form.values['seo.description']?.value
      ) {
        form.onChange('seo.description', form.values.short_description?.value);
      }
    }
  }, [form]);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();
  }, []);

  const onNext = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();

    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const body = {
      ...refs.form.current.value
    };

    if (body.value) {
      body.value = [
        ...body.value,

        ...refs.formSkills.current.values.skills.value.map((item: any) => ({
          id: item.id,
          version: 'skill',

          name: item.name,
          description: item.description
        }))
      ];
    }

    if (is('array', body.websites)) body.websites = body.websites.map((item: any) => ({
      id: item.id,
      name: item.name
    }));

    const text = stringify(body);

    body.read_time = readTime(text);

    const result = !object?.id ? await ResumeService.add(body) : await ResumeService.update(object?.id, body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Resume ${!object?.id ? 'added' : 'updated'}`
      });

      setObject(result.response.response);

      if (is('function', onConfirm)) onConfirm();

      if (!object?.id) onClose();
    }

    setLoading(false);
  }, [object, form, formSkills, onConfirm, onClose]);

  const initForm = React.useCallback(() => {
    setTimeout(() => {
      // options
      onRefreshOptions(false);
    });
  }, [onRefreshOptions]);

  const onChangeTab = React.useCallback(async (value: any) => {
    await refs.form.current.validate();

    await refs.formSkills.current.validate();

    setTab(value);

    initForm();
  }, [initForm, website]);

  const objects = ['experience', 'project', 'education', 'language', 'award', 'interest', 'other'];

  const getObjects = (version: string) => {
    return (form.values.value.value || []).filter((item: any) => item?.version === version);
  };

  const onAddNewSkill = async (valueNew: string, form: any) => {
    if (!valueNew) return;

    setLoading('add-new-skill');

    const response = await SkillService.add({
      name: valueNew
    });

    if (response.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(response)
      });
    }
    else {
      const values = [...(form.values.skills.value || [])];

      const skill = response.response.response;

      values.push({
        id: skill.id,
        value: skill.id,

        name: skill.name,
        description: skill.description
      });

      form.onChange('skills', values);
    }

    setLoading('add-new-skill');
  };

  const getObjectItem = (item: any, index: number) => {
    const version = item?.version || '';

    const otherProperties = ['organization', 'position', 'url', 'employment_type', 'date', 'start', 'end', 'location', 'location_type', 'industry', 'proficiency', 'skills', 'portfolios'];

    const getName = (property: string) => {
      if (property === 'url') return 'URL';

      return cleanValue(property, { capitalize: true });
    };

    const getValue = (property: string) => {
      const valueProperty = item[property];

      if (['date', 'start', 'end'].includes(property)) {
        return valueProperty ? format(new AmauiDate(valueProperty), formats.date) : 'No date';
      }

      if (property === 'url') {
        return (
          <Link
            version='b3'

            href={valueProperty}

            target='blank'
          >
            {valueProperty}
          </Link>
        );
      }

      if (['portfolios', 'skills'].includes(property)) {
        return valueProperty.map((item: any) => textToInnerHTML(item.name)).join(', ');
      }

      return cleanValue(valueProperty, { capitalize: true });
    };

    return (
      <FormRow
        key={index}

        gap={1}

        MainProps={{
          gap: 3
        }}

        end={(
          <Line
            gap={0.5}

            direction='row'

            align='center'
          >
            <Tooltip
              name={`Update ${version.endsWith('s') ? version.slice(0, -1) : version}`}
            >
              <IconButton
                onClick={() => onOpenObject(item)}

                size='small'
              >
                <IconMaterialEditRounded />
              </IconButton>
            </Tooltip>

            <Tooltip
              name={`Remove ${version.endsWith('s') ? version.slice(0, -1) : version}`}
            >
              <IconButton
                onClick={() => onRemoveObject(item)}

                size='small'
              >
                <IconMaterialDeleteRounded />
              </IconButton>
            </Tooltip>
          </Line>
        )}
      >
        <Line
          gap={1}

          fullWidth
        >
          {item?.name && (
            <Type
              version='l2'

              dangerouslySetInnerHTML={{
                __html: textToInnerHTML(item.name)
              }}
            />
          )}

          {item?.short_description && (
            <Type
              version='b2'

              whiteSpace='pre-line'

              dangerouslySetInnerHTML={{
                __html: textToInnerHTML(item.short_description)
              }}
            />
          )}

          {item?.description && (
            <Type
              version='b3'

              whiteSpace='pre-line'

              style={{
                marginTop: 8
              }}

              dangerouslySetInnerHTML={{
                __html: textToInnerHTML(item.description)
              }}
            />
          )}
        </Line>

        <Properties
          size='small'

          values={otherProperties.filter(property => is('array', item[property]) ? item[property].length : item[property]).map(property => ({
            name: getName(property),
            value: getValue(property)
          }))}
        />
      </FormRow>
    );
  };

  const onAddContact = React.useCallback(() => {
    const contacts = [...form.values.contacts.value || []];

    contacts.push({
      id: getID(),
      version: 'Website',
      value: ''
    });

    form.onChange('contacts', contacts);
  }, [form]);

  const onRemoveContact = React.useCallback((id: number) => {
    const contacts = [...form.values.contacts.value || []];

    const index = contacts.findIndex(item => item.id === id);

    if (index > -1) {
      contacts.splice(index, 1);

      form.onChange('contacts', contacts);
    }
  }, [form]);

  const onSelectPDFDesign = React.useCallback((item: any) => {
    refs.form.current.onChange('pdf_theme', item?.name, 'name');
  }, []);

  let ElementPDF: any;

  if (form.value.pdf_theme) {
    if (form.value.pdf_theme.name === 'Default') ElementPDF = DefaultResumePDF;
  }

  const optionsPDFDesign = React.useMemo(() => {
    return [
      {
        name: 'Default',
        image: ImagePDFDefault,
        value: 'Default'
      }
    ];
  }, []);

  const textAll = stringify(form.value);

  const valueTheme = React.useMemo(() => {
    const palette: any = merge(
      {
        color: {
          amaui: {
            main: '#fafa00'
          }
        }
      },
      website?.options?.theme?.palette
    );

    palette.light = theme.palette.light;

    if (palette.image) palette.image = getMediaUrl({ id: palette.image?.id?.toString() });

    const value = new AmauiTheme({
      palette
    });

    return value;
  }, [website, theme?.palette?.light]);

  const modal: any = {
    'Resume': <>
      <Line
        gap={2}

        fullWidth

        className={classes.page}
      >
        <FormRow
          name='Image'

          MainProps={{
            gap: 1
          }}
        >
          {form.values['image'].value && (
            <Medias
              size='regular'

              values={[form.values['image'].value].filter(Boolean).map((item: any) => ({
                value: item
              }))}
            />
          )}

          <SelectMedia
            name='Image'

            value={form.values.image.value}

            mediaSelected={form.values.image.value}

            onChange={(valueNew: any) => form.onChange('image', valueNew)}
          />
        </FormRow>

        <FormRow
          fullWidth

          style={{
            marginBottom: 24
          }}
        >
          <TextField
            name='Name'

            valueDefault={textToInnerHTML(form.values['name'].value)}

            onChange={(valueNew: any) => form.onChange('name', valueNew, undefined, { rerenderOnUpdate: false })}
          />

          <TextField
            placeholder='Summary'

            valueDefault={textToInnerHTML(form.values['short_description'].value || '')}

            onChange={(valueNew: any) => form.onChange('short_description', innerHTMLToText(valueNew), undefined, { rerenderOnUpdate: false })}

            minRows={4}

            multiline

            fullWidth
          />

          <TextField
            placeholder='Description'

            valueDefault={textToInnerHTML(form.values['description'].value || '')}

            onChange={(valueNew: any) => form.onChange('description', innerHTMLToText(valueNew), undefined, { rerenderOnUpdate: false })}

            minRows={7}

            multiline

            fullWidth
          />
        </FormRow>

        <FormRow
          name='Contacts'

          HeaderProps={{
            align: 'center'
          }}

          end={(
            <Tooltip
              name='Add contact'
            >
              <IconButton
                onClick={onAddContact}
              >
                <IconMaterialAddRounded />
              </IconButton>
            </Tooltip>
          )}
        >
          {(form.values.contacts.value || []).map((item: any, index: number) => (
            <FormRow
              key={item.id}

              row

              end={(
                <Tooltip
                  name='Remove contact'
                >
                  <IconButton
                    onClick={() => onRemoveContact(item.id)}

                    size='small'
                  >
                    <IconMaterialRemoveRounded />
                  </IconButton>
                </Tooltip>
              )}
            >
              <Select
                name='Type'

                valueDefault={form.values.contacts.value[index].version}

                onChange={(valueNew: any) => form.onChange('contacts', valueNew, `${index}.version`)}
              >
                {CONTACT_OPTIONS.map((itemContact: any, index_: number) => (
                  <ListItem
                    key={index_}

                    primary={(
                      <Type
                        version='b3'
                      >
                        {itemContact}
                      </Type>
                    )}

                    value={itemContact}

                    size='small'

                    button
                  />
                ))}
              </Select>

              <TextField
                name='Name'

                valueDefault={form.values.contacts.value[index].name}

                onChange={(valueNew: any) => form.onChange('contacts', valueNew, `${index}.name`, { rerenderOnUpdate: false })}
              />

              <TextField
                name='Value'

                valueDefault={form.values.contacts.value[index].value}

                onChange={(valueNew: any) => form.onChange('contacts', valueNew, `${index}.value`, { rerenderOnUpdate: false })}
              />
            </FormRow>
          ))}
        </FormRow>

        <Form
          onSubmit={onSubmit}

          className={classes.objects}
        >
          {objects.map((item: string, index: number) => (
            <FormRow
              key={index}

              name={(
                <Type
                  version='t2'

                  className={classes.title}
                >
                  {capitalize(item)}
                </Type>
              )}

              end={(
                <Tooltip
                  name={`Add ${item.endsWith('s') ? item.slice(0, -1) : item}`}
                >
                  <IconButton
                    onClick={() => onOpenObject(undefined, item)}
                  >
                    <IconMaterialAddRounded />
                  </IconButton>
                </Tooltip>
              )}
            >
              {getObjects(item).map((item: any, index: number) => getObjectItem(item, index))}
            </FormRow>
          ))}

          <FormRow
            name={(
              <Type
                version='t2'
              >
                Skills
              </Type>
            )}
          >
            <AutoCompleteObjects
              name='Skills'

              value={formSkills.values.skills.value}

              onChange={(valueNew: any[]) => formSkills.onChange('skills', valueNew)}

              onChangeInput={(valueNew: string) => {
                refs.autoCompleteInput.current = valueNew;
              }}

              service={SkillService}

              noOptionsElement={refs.autoCompleteInput.current ? (
                <ListItem
                  start={(
                    <IconMaterialAddRounded
                      size='small'
                    />
                  )}

                  startAlign='center'

                  primary={(
                    <Type
                      version='b3'
                    >
                      Add '{refs.autoCompleteInput.current}' skill
                    </Type>
                  )}

                  onClick={() => onAddNewSkill(refs.autoCompleteInput.current, formSkills)}

                  size='small'

                  disabled={loading === 'add-new-skill'}

                  button
                />
              ) : undefined}

              noOptions={!!refs.autoCompleteInput.current}

              fullWidth

              multiple

              chip
            />
          </FormRow>
        </Form>
      </Line>
    </>,

    'Options': <>
      <Line
        fullWidth

        className={classes.page}
      >
        <Form
          gap={2}

          onSubmit={onSubmit}
        >
          <Label
            checked={form.values.active?.value}

            onChange={(valueNew: boolean) => form.onChange('active', valueNew)}

            error={!!form.values.active.error}

            helperText={form.values.active.error}
          >
            <Switch />

            Active
          </Label>

          <Label
            checked={form.values.archived?.value}

            onChange={(valueNew: boolean) => form.onChange('archived', valueNew)}

            error={!!form.values.archived.error}

            helperText={form.values.archived.error}
          >
            <Switch />

            Archived
          </Label>

          <OptionsWebsites
            description='Websites this resume can be visible at'

            value={form.values.websites.value}

            onChange={(valueNew: any[]) => form.onChange('websites', valueNew)}
          />

          <OptionsShare
            form={form}
          />
        </Form>
      </Line>
    </>,

    'Options PDF': <>
      <Line
        fullWidth

        className={classes.page}
      >
        <Form
          name='Design'

          description='Choose a design for your resume PDF'

          wrapper
        >
          <Line
            gap={1}

            direction='row'

            wrap='wrap'

            fullWidth

            className={classes.pdfDesigns}
          >
            {optionsPDFDesign.map((item, index) => (
              <Line
                key={index}

                gap={1.5}

                fullWidth

                onClick={() => onSelectPDFDesign(item)}

                className={classes.pdfDesign}
              >
                <Line
                  fullWidth

                  style={{
                    backgroundImage: `url(${item.image})`
                  }}

                  className={classNames([
                    classes.pdfDesignImage,
                    item.name === form.value.pdf_theme?.name && classes.pdfDesignImageSelected
                  ])}
                >
                  <Interaction />
                </Line>

                <Type
                  version='t3'
                >
                  {item.name}
                </Type>
              </Line>
            ))}
          </Line>
        </Form>
      </Line>
    </>,

    'SEO': <>
      <Line
        fullWidth

        className={classes.page}
      >
        <OptionsSEO
          app='resume'

          values={form.values}

          onChange={form.onChange}
        />
      </Line>
    </>,

    'Preview page': <>
      {(object?.id && object?.active && !object?.private) && (
        <PageBuilder
          mode='preview'

          url={`/resumes/${object.id}?short_name=${website?.short_name}`}

          website={website}
        />
      )}
    </>,

    'Preview PDF': <>
      {ElementPDF && (
        <ReactPDF.PDFViewer
          className={classes.viewer}
        >
          <ElementPDF
            theme={valueTheme}

            value={{
              ...object,

              ...refs.form.current.value,

              read_time: readTime(textAll),

              value: [
                ...(form.value?.value || []),

                ...(formSkills.value.skills || [])
              ]
            }}
          />
        </ReactPDF.PDFViewer>
      )}
    </>
  };

  return <>
    <ModalForm
      {...props}

      object={object}

      add

      tabDefault='Resume'

      tab={tab}

      tabs={['Resume', 'Options', ...(website ? ['Options PDF'] : []), 'SEO', ...(website ? [...((object?.id && object?.active && !object?.private) ? ['Preview page'] : []), ...((!touch && ElementPDF) ? ['Preview PDF'] : [])] : [])]}

      onChangeTab={onChangeTab}

      onSubmit={onSubmit}

      onNext={onNext}

      onClose={onClose}

      loading={loading}
    >
      {modal[tab]}
    </ModalForm>

    <Experience
      open={openObject}

      form={form}

      onClose={onCloseObject}
    />
  </>;
});

export default Element;
