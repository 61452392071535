import AmauiSDK from '@amaui/sdk';
import { IAmauiSDKOptions } from '@amaui/sdk/other';
import AmauiRequest from '@amaui/request';
import AmauiSub from '@amaui/subscription';
import { merge } from '@amaui/utils';

import config from 'config';
import { StorageService } from 'services';

class AmauiService {
  public initial = new AmauiSub();
  public sdk: AmauiSDK = undefined as any;

  public init(options_: IAmauiSDKOptions = {}): AmauiSDK {
    const options = merge(options_, {
      amaui_token: config.value.amaui_token,

      apis: {
        api: {
          url: config.value.apps.api.url
        },
        api_media_read: {
          url: config.value.apps.api_media_read.url
        },
        api_media_write: {
          url: config.value.apps.api_media_write.url
        }
      }
    } as Partial<IAmauiSDKOptions>);

    this.sdk = new AmauiSDK(options);

    AmauiRequest.interceptors.request.pre.subscribe((value: any) => {
      const deviceID = StorageService.get('device-id');

      if (deviceID) value.request.headers['Device-ID'] = deviceID;
    });

    return this.sdk;
  }

  public constructor() {
    this.init();
  }
}

export default new AmauiService();
