import { OverviewOfApps } from './Custom';

export const Element = () => {
  // const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  // const home_page = signedIn?.organization?.personalization?.settings?.home_page;

  return <OverviewOfApps />;
};

export default Element; 
