import React from 'react';

import { FormUserGroup, Page } from 'ui';
import { UserGroupService } from 'services';

const cleanValue = (value_: string) => {
  let value = value_;

  ['-', '_'].forEach(filter => {
    const expression = `\\${filter}`;
    const regexp = new RegExp(expression, 'g');

    value = value ? value.replace(regexp, ' ') : '';
  });

  return `${value.charAt(0).toUpperCase()}${value.slice(1)}`;
};

const UserGroups = React.forwardRef(() => {

  const properties = React.useMemo(() => ({
    name: { name: 'Name' },
    description: { name: 'Description' },
    active: { name: 'Active' },
    users: { name: 'Users', method: (item: any) => item.users?.length || '' },
    roles: { name: 'Roles', method: (item: any) => item.roles?.length ? item.roles.map((item_: any) => cleanValue(item_)).join(', ') : '' },
    color: { name: 'Color' }
  }), []);

  return (
    <Page
      name='User groups'

      service={UserGroupService}

      columns={[
        properties.name,
        properties.users,
        properties.roles,
        properties.color,
        properties.active
      ]}

      search={['active', 'color']}

      options={['active']}

      properties={properties}

      app='amaui'

      collection='userGroups'

      route='/organization/settings/user-groups'

      Form={FormUserGroup}

      noTags
    />
  );
});

export default UserGroups;
