import { Analytic } from '@amaui/api-utils';
import AmauiSubscription from '@amaui/subscription';

import { BaseService } from './base';
import { IQuerySubscription, ISelectedSubscription } from 'other';

class AnalyticService extends BaseService<Analytic> {
  public queryWebsiteAnalytics = new AmauiSubscription<IQuerySubscription>();
  public selectedWebsiteAnalytics = new AmauiSubscription<ISelectedSubscription>();

  public queryUrlShortenerAnalytics = new AmauiSubscription<IQuerySubscription>();
  public selectedUrlShortenerAnalytics = new AmauiSubscription<ISelectedSubscription>();

  public queryFormPollAnalytics = new AmauiSubscription<IQuerySubscription>();
  public selectedFormPollAnalytics = new AmauiSubscription<ISelectedSubscription>();

  public queryOverviewAccountingAnalytics = new AmauiSubscription<IQuerySubscription>();

  public constructor() {
    super('analytics');

    this.queryObjectsInit(this.queryWebsiteAnalytics);
    this.selectedObjectsInit(this.selectedWebsiteAnalytics);

    this.queryObjectsInit(this.queryUrlShortenerAnalytics);
    this.selectedObjectsInit(this.selectedUrlShortenerAnalytics);

    this.queryObjectsInit(this.queryFormPollAnalytics);
    this.selectedObjectsInit(this.selectedFormPollAnalytics);

    this.queryObjectsInit(this.queryOverviewAccountingAnalytics);
  }

}

export default new AnalyticService();
