import React from 'react';

import { Page, useSubscription } from 'ui';
import { AuthService, ManageService } from 'services';
import { ISignedIn } from 'other';

const Organizations = React.forwardRef(() => {
  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const isEnabledAction = React.useCallback((item: any, action: string) => {
    if (action === 'active') return item?.id !== signedIn?.organization?.id;

    return true;
  }, [signedIn]);

  const properties = React.useMemo(() => ({
    name: { name: 'Name' },
    short_name: { name: 'Short name' },
    amaui: { name: 'amaui' },
    'customer.id': { name: 'Customer ID' },
    'customer.email': { name: 'Customer email' },
    'owner.name': { name: 'Owner' },
    active: { name: 'Active' },
  }), []);

  return (
    <Page
      name='Organizations'

      service={ManageService}

      columns={[
        properties.name,
        properties.active
      ]}

      search={['active']}

      options={['active']}

      properties={properties}

      isEnabledAction={isEnabledAction}

      queryItemsName='queryOrganizations'

      selectedItemsName='selectedOrganizations'

      updateOneName='updateOrganization'

      app='amaui'

      collection='organizations'

      noSelect

      noAdd

      noTags

      noUsage

      noUpdateProject

      noRemove

      noActions
    />
  );
});

export default Organizations;
