import React from 'react';

import { Line, ListItem, NumericTextField, Select, Type } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';
import { is } from '@amaui/utils';

const useStyle = style(theme => ({
  root: {

  },

  numericTextField: {
    '& .amaui-TextField-input-wrapper': {
      width: 74
    }
  },

  select: {
    '&.amaui-Select-root': {
      minWidth: 'unset'
    }
  }
}), { name: 'amaui-app-NumericTextFieldWithUnit' });

const NumericTextFieldWithUnit = React.forwardRef((props: any, ref: any) => {
  const {
    label,

    min,

    max,

    value: value_,

    valueValue: valueValue_,

    onChangeValue: onChangeValue_,

    valueUnit: valueUnit_,

    onChangeUnit: onChangeUnit_,

    onChange: onChange_,

    size = 'small',

    ValueProps,

    NumberTextFieldProps,

    UnitProps,

    SelectProps,

    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const [value, setValue] = React.useState(valueValue_ !== undefined ? valueValue_ : '');
  const [valueUnit, setValueUnit] = React.useState<any>(valueUnit_ !== undefined ? valueUnit_ : 'px');

  const refs = {
    value: React.useRef(value),
    valueUnit: React.useRef(valueUnit)
  };

  refs.value.current = value;

  refs.valueUnit.current = valueUnit;

  React.useEffect(() => {
    const valueNew = String(value_ || '');

    const number = valueNew.split(/\D+/)[0];

    if (number) setValue(number);

    const unit = valueNew.split(/\d+/)[1];

    if (unit) setValueUnit(unit);
  }, [value_]);

  React.useEffect(() => {
    if (valueValue_ !== undefined && value !== valueValue_) setValue(valueValue_);
  }, [valueValue_]);

  React.useEffect(() => {
    if (valueUnit_ !== undefined && valueUnit !== valueUnit_) setValueUnit(valueUnit_);
  }, [valueUnit_]);

  const onChange = React.useCallback((valueNew: string = [undefined, ''].includes(refs.value.current) ? '' : `${refs.value.current || 0}${refs.valueUnit.current || 'px'}`) => {
    if (is('function', onChange_)) onChange_(valueNew);
  }, [onChange_]);

  const onChangeValue = React.useCallback((valueNew: string) => {
    if (!props.hasOwnProperty('valueValue')) setValue(valueNew || '');

    if (is('function', onChangeValue_)) onChangeValue_(valueNew || '');

    setTimeout(() => {
      onChange();
    }, 14);
  }, [onChangeValue_]);

  const onChangeUnit = React.useCallback((valueNew: string) => {
    if (!props.hasOwnProperty('valueUnit')) setValueUnit(valueNew);

    if (is('function', onChangeUnit_)) onChangeUnit_(valueNew);

    setTimeout(() => {
      onChange();
    }, 14);
  }, [onChangeUnit_]);

  const selectProps: any = {
    size,

    autoWidth: false,

    MenuProps: {
      portal: true,
      alignment: 'center'
    }
  };

  const unitOptions = [
    { name: 'px', value: 'px' },
    { name: '%', value: '%' },
    { name: 'vw', value: 'vw' },
    { name: 'vh', value: 'vh' },
    { name: 'em', value: 'em' },
    { name: 'rem', value: 'rem' }
  ];

  return (
    <Line
      ref={ref}

      gap={1}

      direction='row'

      align='unset'

      justify='unset'

      className={classNames([
        className,
        classes.root
      ])}

      {...other}
    >
      <NumericTextField
        name={label}

        value={value}

        onChange={onChangeValue}

        min={min}

        max={max}

        size={size}

        className={classes.numericTextField}

        {...NumberTextFieldProps}

        {...ValueProps}
      />

      <Select
        name='Unit'

        value={valueUnit}

        onChange={onChangeUnit}

        className={classes.select}

        {...selectProps}
      >
        {unitOptions.map((item: any, index: number) => (
          <ListItem
            key={index}

            primary={(
              <Type
                version='b3'
              >
                {item?.name}
              </Type>
            )}

            value={item.value}

            button
          />
        ))}
      </Select>
    </Line>
  );
});

export default NumericTextFieldWithUnit;
