import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Form, Line, Type, useForm, useQuery, useSnackbars } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';

import { Button, TextField } from 'ui';
import { AuthService } from 'services';
import { getDeviceAndLocation, getErrorMessage } from 'other';

const useStyle = style(theme => ({
  root: {},

  buttons: {
    marginTop: 30
  }
}), { name: 'amaui-app-route-SignUpConfirm' });

const SignUpConfirm = React.forwardRef((props: any, ref: any) => {
  const {
    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const query = useQuery();
  const navigate = useNavigate();
  const snackbars = useSnackbars();

  const [step, setStep] = React.useState(1);
  const [loading, setLoading] = React.useState(false);

  const formEmail = useForm({
    values: {
      'email': {
        name: 'email',
        isValid: 'email',
        required: true
      }
    }
  });

  const formOTP = useForm({
    values: {
      'otp': {
        name: 'OTP',
        required: true,
        capitalize: false
      }
    }
  });

  const refs = {
    amaui: React.useRef<any>(),
    formEmail: React.useRef(formEmail),
    formOTP: React.useRef(formOTP)
  };

  refs.formEmail.current = formEmail;

  refs.formOTP.current = formOTP;

  const onSubmitEmail = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();

    const valid = await formEmail.validate();

    if (!valid) return;

    setLoading(true);

    const result = await AuthService.signUpOTPResend({
      email: refs.formEmail.current.value.email
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      setStep(2);

      snackbars.add({
        primary: result.response?.response
      });
    }

    setLoading(false);
  }, [formEmail]);

  const signInUser = React.useCallback(async (value: string) => {
    // google modal close
    (window as any).google.accounts.id.cancel();

    await AuthService.init(value);

    // redirect to
    const redirect_to = query.get('redirect_to');

    if (redirect_to) navigate(redirect_to);
    else {
      // or to home
      navigate('/');
    }
  }, [query]);

  const onSubmitOTP = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();

    let valid = await formEmail.validate();

    if (!valid) return;

    valid = await formOTP.validate();

    if (!valid) return;

    setLoading(true);

    const add_website = query.get('add_website');

    const result = await AuthService.signUpOTP(
      {
        ...(await getDeviceAndLocation()),

        email: refs.formEmail.current.value.email,
        otp: refs.formOTP.current.value.otp
      },
      {
        query: {
          ...(add_website && { add_website })
        }
      }
    );

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      const response = result.response.response;

      await signInUser(response);

      snackbars.add({
        primary: 'Signed in succefully!'
      });
    }

    setLoading(false);
  }, [query, formEmail, formOTP]);

  const steps: any = {
    1: <>
      <Line
        gap={3}

        direction='column'

        align='center'
      >
        <Type
          version='h3'
        >
          Sign up confirm
        </Type>

        <Line
          gap={1}

          direction='column'

          align='center'
        >
          <Type
            version='t2'
          >
            Email
          </Type>

          <Type
            version='b3'

            style={{
              textAlign: 'center'
            }}
          >
            Provide us with your email, and we will send you a one time password to your email, enter it in the next step to confirm your sign up, and activate your account.
          </Type>
        </Line>
      </Line>

      <Line
        gap={3}

        fullWidth
      >
        <Form
          onSubmit={onSubmitEmail}
        >
          <TextField
            key='email'

            name='Email'

            type='email'

            valueDefault={formEmail.values['email'].value}

            error={formEmail.values['email'].error}

            helperText={formEmail.values['email'].error}

            onChange={(valueNew: any) => formEmail.onChange('email', valueNew, undefined, { rerenderOnUpdate: false })}

            fullWidth
          />

          <Line
            direction='row'

            align='center'

            justify='space-between'

            fullWidth

            className={classes.buttons}
          >
            <Button
              version='text'

              onClick={() => navigate('/sign-up')}
            >
              Sign up
            </Button>

            <Button
              type='submit'

              disabled={loading}
            >
              Next
            </Button>
          </Line>
        </Form>
      </Line>
    </>,

    2: <>
      <Line
        gap={3}

        direction='column'

        align='center'
      >
        <Type
          version='h3'
        >
          Sign up confirm
        </Type>

        <Line
          gap={1}

          direction='column'

          align='center'
        >
          <Type
            version='t2'
          >
            One time password
          </Type>

          <Type
            version='b3'

            style={{
              textAlign: 'center'
            }}
          >
            Enter the one time password we sent to your email, in the input. Once the code you enter is valid, your account will be ready to use.
          </Type>
        </Line>
      </Line>

      <Line
        gap={3}

        fullWidth
      >
        <Form
          onSubmit={onSubmitOTP}
        >
          <TextField
            key='otp'

            name='One time password'

            valueDefault={formOTP.values['otp'].value}

            error={formOTP.values['otp'].error}

            helperText={formOTP.values['otp'].error}

            onChange={(valueNew: any) => formOTP.onChange('otp', valueNew, undefined, { rerenderOnUpdate: false })}

            fullWidth
          />

          <Line
            direction='row'

            align='center'

            justify='space-between'

            fullWidth

            className={classes.buttons}
          >
            <Button
              version='text'

              onClick={() => setStep(1)}
            >
              Back
            </Button>

            <Button
              type='submit'

              disabled={loading}
            >
              Next
            </Button>
          </Line>
        </Form>
      </Line>
    </>
  };

  return (
    <Line
      ref={ref}

      gap={4}

      direction='column'

      align='center'

      flex

      fullWidth

      className={classNames([
        className,
        classes.root,
        'amaui-app-SignUpConfirm'
      ])}

      {...other}
    >
      {steps[step]}
    </Line>
  );
});

export default SignUpConfirm;
