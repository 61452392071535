import React from 'react';

import { capitalize, cleanValue, copy, getID, getObjectValue, innerHTMLToText, is, randomString, textToInnerHTML } from '@amaui/utils';
import { AvailabilityCalendar, DatePicker, DateTimeRangePicker, Form, FormRow, IconButton, Label, Line, Switch, TimeRangePicker, Tooltip, Type, useForm, useSnackbars } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';
import { AmauiDate, add, format } from '@amaui/date';

import IconMaterialRefreshRounded from '@amaui/icons-material-rounded-react/IconMaterialRefresh';
import IconMaterialContentCopyRounded from '@amaui/icons-material-rounded-react/IconMaterialContentCopy';
import IconMaterialAddRounded from '@amaui/icons-material-rounded-react/IconMaterialAdd';
import IconMaterialRemoveRounded from '@amaui/icons-material-rounded-react/IconMaterialRemove';

import { ModalForm, SelectAny, SmartTextField, TextField } from 'ui';
import { AppService, WorkDayService } from 'services';
import { formats, getErrorMessage, optionsTimeUnit } from 'other';

const useStyle = style(theme => ({
  root: {

  },

  times: {
    paddingBottom: '34vh !important'
  },

  ...theme.classes(theme)
}), { name: 'amaui-app-Available' });

const Element = React.forwardRef((props: any) => {
  const {
    object: object_,

    onConfirm
  } = props;

  const { classes } = useStyle();

  const snackbars = useSnackbars();

  const [object, setObject] = React.useState(object_);
  const [tab, setTab] = React.useState('Work days');
  const [loading, setLoading] = React.useState<any>(false);

  const form = useForm({
    values: {
      'name': {
        name: 'Name',
        value: object?.name,
        required: true,
        max: 1400,
        messages: {
          min: 'Name has to be min 1 characters',
          max: 'Name can be max 1400 characters'
        }
      },
      'description': {
        name: 'Description',
        value: object?.description,
        max: 4400,
        messages: {
          min: 'Description has to be min 1 characters',
          max: 'Description can be max 4400 characters'
        }
      },
      'times': {
        name: 'Times',
        is: 'object',
        value: object?.times || {
          weekly: {
            active: true,
            days: {
              1: { active: true },
              2: { active: true },
              3: { active: true },
              4: { active: true },
              5: { active: true },
              6: { active: false },
              7: { active: false }
            }
          },
          dates: {
            active: false
          }
        }
      },
      'all': {
        name: 'All',
        value: object?.all !== undefined ? object.all : false,
        is: 'boolean'
      },
      'password': {
        name: 'Password',
        value: object?.password,
        is: 'string'
      },
      'active': {
        name: 'Active',
        value: object?.active !== undefined ? object.active : true,
        is: 'boolean'
      },
      'archived': {
        name: 'Archived',
        value: object?.archived,
        is: 'boolean'
      }
    }
  });

  const refs = {
    form: React.useRef(form)
  };

  refs.form.current = form;

  const formOnChangeOptions: any = {
    rerenderOnUpdate: false
  };

  const onClose = React.useCallback(() => {
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });
  }, []);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();
  }, []);

  const onNext = React.useCallback(async () => {
    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const body = {
      ...refs.form.current.value
    };

    const result = !object?.id ? await WorkDayService.add(body) : await WorkDayService.update(object?.id, body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Work days ${!object?.id ? 'added' : 'updated'}`
      });

      setObject(result.response.response);

      if (is('function', onConfirm)) onConfirm();

      if (!object?.id) onClose();
    }

    setLoading(false);
  }, [object, form, onConfirm, onClose]);

  const onChangeTab = React.useCallback(async (value: any) => {
    setTab(value);
  }, []);

  const onStopPropagation = React.useCallback((event: MouseEvent) => {
    event.stopPropagation();
  }, []);

  const valueTimeDefaultObject = React.useMemo(() => {
    return {
      status: 'working'
    }
  }, []);

  const onMakePassword = React.useCallback(() => {
    refs.form.current.onChange('password', randomString(14));
  }, []);

  const onAddDate = React.useCallback(() => {
    const values = [...(refs.form.current.values.times.value?.dates?.values || [])];

    values.push({
      id: getID(),
      from: AmauiDate.milliseconds,
      to: add(1, 'hour', AmauiDate.amauiDate).milliseconds,

      ...valueTimeDefaultObject
    });

    refs.form.current.onChange('times', values, `dates.values`);
  }, []);

  const onRemoveDate = React.useCallback((index: number) => {
    const values = [...(refs.form.current.values.times.value?.dates?.values || [])];

    if (index > -1) values.splice(index, 1);

    refs.form.current.onChange('times', values, `dates.values`);
  }, []);

  const onAddDay = React.useCallback((path: string) => {
    const values = [...(getObjectValue(refs.form.current.values.times.value, `weekly.days.${path}.values`) || [])];

    values.push({
      id: getID(),
      from: AmauiDate.milliseconds,
      to: add(1, 'hour', AmauiDate.amauiDate).milliseconds,

      ...valueTimeDefaultObject
    });

    refs.form.current.onChange('times', values, `weekly.days.${path}.values`);
  }, []);

  const onRemoveDay = React.useCallback((index: number, path: string) => {
    const values = [...(getObjectValue(refs.form.current.values.times.value, `weekly.days.${path}.values`) || [])];

    if (index > -1) values.splice(index, 1);

    refs.form.current.onChange('times', values, `weekly.days.${path}.values`);
  }, []);

  const formAccordion: any = {
    AccordionProps: {
      mainPaddingVertical: 'end',

      WrapperHeaderProps: {
        gap: 1
      },

      ExpandProps: {
        removeOnExited: false
      }
    },
    AccordionMainProps: {
      gap: 1
    },
    accordion: true,
    wrapper: true,
    size: 'small'
  };

  const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

  const onCopyTimes = React.useCallback((path: string) => {
    const values = [...(getObjectValue(refs.form.current.values.times.value, `weekly.days.${path}.values`) || [])];

    const times = copy(refs.form.current.values.times.value);

    Object.values(times.weekly.days).forEach((day: any) => {
      day.values = values.map(item => ({
        ...item,

        id: getID()
      }));
    });

    refs.form.current.onChange('times', times);
  }, []);

  const optionsStatus = React.useMemo(() => {
    return [
      { name: 'Working', value: 'working' },
      { name: 'Not working', value: 'not-working' },
      { name: 'On a break', value: 'break' },
      { name: 'Scheduled', value: 'pending' },
      { name: 'Rescheduled', value: 'rescheduled' },
      { name: 'Cancelled', value: 'canceled' },
      { name: 'Other', value: 'other' }
    ]
  }, []);

  const optionsEntire = React.useMemo(() => {
    return [
      { name: 'None', value: null },

      ...optionsTimeUnit
    ]
  }, []);

  const itemToText = React.useCallback((item: any) => {
    if (item === 'pending') return 'scheduled';

    return item;
  }, []);

  const getTimeValue = (item: any, index: number, property: string, path: string) => {
    const pathMain = `${property}.${path}.${index}`;

    const weekly = property === 'weekly';

    let ElementDate = weekly ? TimeRangePicker : DateTimeRangePicker;

    const additional = [item.entire && `entire ${item.entire}`, item.status && cleanValue(itemToText(item.status), { capitalize: false })].filter(Boolean);

    return (
      <Form
        key={index + pathMain}

        name={(
          <Type>
            {item.from ? `${format(new AmauiDate(item.from), weekly ? formats.time : formats.entire)} — ${format(new AmauiDate(item.to), weekly ? formats.time : formats.entire)}${additional.length ? ` - ${additional.join(', ')}` : ''}` : 'No time selected'}
          </Type>
        )}

        size='small'

        end={(
          <Tooltip
            name='Remove date'
          >
            <IconButton
              onClick={(event: MouseEvent) => {
                event.stopPropagation();

                weekly ? onRemoveDay(index, path.replace('days.', '').replace('.values', '')) : onRemoveDate(index);
              }}

              size='small'
            >
              <IconMaterialRemoveRounded />
            </IconButton>
          </Tooltip>
        )}

        {...formAccordion}

        AccordionMainProps={{
          gap: 1.5,

          fullWidth: false
        }}
      >
        <ElementDate
          name='Time'

          color='themed'

          size='small'

          value={[item.from, item.to].filter(Boolean).map(item => new AmauiDate(item)) as any}

          onChange={(valueNew: any) => {
            const from = valueNew?.[0]?.milliseconds;
            const to = valueNew?.[1]?.milliseconds;

            form.onChange([
              ['times', from, `${pathMain}.from`],
              ['times', to, `${pathMain}.to`]
            ]);
          }}

          TooltipProps={{
            switch: true
          }}
        />

        <SmartTextField
          name='Description'

          valueDefault={textToInnerHTML(item.description || '')}

          onChange={(valueNew: any) => form.onChange('times', innerHTMLToText(valueNew), `${pathMain}.description`, formOnChangeOptions)}

          minRows={4}

          multiline

          edit
        />

        <FormRow
          name='Status'

          description='What is happening at this time'
        >
          <SelectAny
            valueDefault={item.status || null}

            onChange={(valueNew: any) => {
              form.onChange('times', valueNew, `${pathMain}.status`);
            }}

            options={optionsStatus}
          />
        </FormRow>

        {!weekly && <>
          <FormRow
            name='Entire'

            description='You can mark an entire day, month etc. as ie. working'
          >
            <SelectAny
              valueDefault={item.entire || null}

              onChange={(valueNew: any) => {
                form.onChange('times', valueNew, `${pathMain}.entire`);
              }}

              options={optionsEntire}
            />
          </FormRow>
        </>}
      </Form>
    );
  };

  const hasTimes = form.values.times.value.dates.values?.length || Object.values(form.values.times.value.weekly.days).flatMap((item: any) => item.values)?.length;

  const modal: any = {
    'Work days': <>
      <Line
        gap={4}

        flex

        fullWidth

        className={classes.page}
      >
        {!hasTimes ? (
          <Type
            version='t3'

            align='center'
          >
            Choose soome weekly pattern and/or dates
          </Type>
        ) : (
          <AvailabilityCalendar
            name={form.values.name.value}

            times={form.value.times}
          />
        )}
      </Line>
    </>,

    'Week': <>
      <Form
        gap={1.5}

        name='Weekly'

        description='Weekly repeating pattern of work times'

        size='small'

        start={(
          <Label
            checked={!!form.values.times.value?.weekly?.active}

            onChange={(valueNew: any) => form.onChange('times', valueNew, `weekly.active`)}

            onClick={onStopPropagation}
          >
            <Switch />
          </Label>
        )}

        className={classNames([
          classes.page,
          classes.times
        ])}

        wrapper
      >
        <Line
          gap={1}

          fullWidth
        >
          {days.map((day: string, index: number) => {
            const itemDay = form.values.times.value?.weekly?.days?.[index + 1];

            return (
              <Form
                key={day}

                name={`${capitalize(day)}${itemDay?.values?.length ? ` (${itemDay.values.length})` : ''}`}

                size='small'

                start={(
                  <Label
                    checkedDefault={itemDay?.active}

                    onChange={(valueNew: any) => form.onChange('times', valueNew, `weekly.days.${index + 1}.active`)}

                    onClick={onStopPropagation}
                  >
                    <Switch />
                  </Label>
                )}

                end={<>
                  <Tooltip
                    name='Copy to other days'
                  >
                    <IconButton
                      onClick={(event: MouseEvent) => {
                        event.stopPropagation();

                        onCopyTimes(`${index + 1}`)
                      }}
                    >
                      <IconMaterialContentCopyRounded />
                    </IconButton>
                  </Tooltip>

                  <Tooltip
                    name='Add time'
                  >
                    <IconButton
                      onClick={(event: MouseEvent) => {
                        event.stopPropagation();

                        onAddDay(`${index + 1}`)
                      }}
                    >
                      <IconMaterialAddRounded />
                    </IconButton>
                  </Tooltip>
                </>}

                {...formAccordion}

                AccordionMainProps={{
                  gap: 2,
                  style: {
                    paddingInlineStart: 16
                  }
                }}
              >
                {!!itemDay?.values?.length && (
                  <Line
                    gap={0}

                    fullWidth
                  >
                    {(itemDay?.values || []).map((item: any, index_: number) => getTimeValue(item, index_, 'weekly', `days.${index + 1}.values`))}
                  </Line>
                )}

                <FormRow
                  description='Which day onward to end the pattern, optionally'
                >
                  <DatePicker
                    name='Ends at'

                    valueDefault={itemDay.ends_at ? new AmauiDate(itemDay.ends_at) : undefined}

                    onChange={((valueNew: AmauiDate) => form.onChange('times', valueNew ? valueNew.milliseconds : null, `weekly.days.${index + 1}.ends_at`)) as any}
                  />
                </FormRow>
              </Form>
            );
          })}
        </Line>
      </Form>
    </>,

    'Dates': <>
      <Form
        name={`Dates${form.values.times.value?.dates?.values?.length ? ` (${form.values.times.value.dates.values.length})` : ''}`}

        description='Specific times of working or not working'

        size='small'

        start={(
          <Label
            checked={!!form.values.times.value?.dates?.active}

            onChange={(valueNew: any) => form.onChange('times', valueNew, `dates.active`)}

            onClick={onStopPropagation}
          >
            <Switch />
          </Label>
        )}

        end={(
          <Tooltip
            name='Add date'
          >
            <IconButton
              onClick={(event: MouseEvent) => {
                event.stopPropagation();

                onAddDate();
              }}
            >
              <IconMaterialAddRounded />
            </IconButton>
          </Tooltip>
        )}

        className={classNames([
          classes.page,
          classes.times
        ])}

        wrapper
      >
        <Line
          gap={0}

          fullWidth
        >
          {(form.values.times.value?.dates?.values || []).map((item: any, index: number) => getTimeValue(item, index, 'dates', `values`))}
        </Line>
      </Form>
    </>,

    'Options': <>
      <Line
        fullWidth

        className={classes.page}
      >
        <TextField
          name='Name'

          valueDefault={form.values['name'].value}

          error={form.values['name'].error}

          helperText={form.values['name'].error}

          onChange={(valueNew: any) => form.onChange('name', valueNew, undefined, formOnChangeOptions)}
        />

        <SmartTextField
          name='Description'

          valueDefault={textToInnerHTML(form.values['description'].value || '')}

          error={form.values['description'].error}

          helperText={form.values['description'].error}

          onChange={(valueNew: any) => form.onChange('description', innerHTMLToText(valueNew), undefined, formOnChangeOptions)}

          minRows={4}

          multiline

          edit
        />

        <FormRow
          name='Protect'

          description={`By setting a password, you can protect the calendar ie. viewing it in the public URL will require users to input password to see the calendar.`}

          end={(
            <Tooltip
              name='Make strong password'
            >
              <IconButton
                onClick={onMakePassword}
              >
                <IconMaterialRefreshRounded />
              </IconButton>
            </Tooltip>
          )}
        >
          <TextField
            name='Password'

            value={form.values['password'].value}

            error={form.values['password'].error}

            helperText={form.values['password'].error}

            onChange={(valueNew: any) => form.onChange('password', valueNew)}

            type='password'
          />
        </FormRow>

        <Label
          checked={form.values.active?.value}

          onChange={(valueNew: boolean) => form.onChange('active', valueNew)}

          error={!!form.values.active.error}

          helperText={form.values.active.error}
        >
          <Switch />

          Active
        </Label>

        <Label
          checked={form.values.archived?.value}

          onChange={(valueNew: boolean) => form.onChange('archived', valueNew)}

          error={!!form.values.archived.error}

          helperText={form.values.archived.error}
        >
          <Switch />

          Archived
        </Label>
      </Line>
    </>
  };

  return (
    <ModalForm
      {...props}

      object={object}

      add

      tabDefault='Work days'

      tab={tab}

      tabs={['Work days', 'Week', 'Dates', 'Options']}

      onChangeTab={onChangeTab}

      onSubmit={onSubmit}

      onNext={onNext}

      onClose={onClose}

      loading={loading}
    >
      {modal[tab]}
    </ModalForm>
  );
});

export default Element;
