import React from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import { getRedirectTo } from '@amaui/utils';
import { NotFound } from '@amaui/ui-react';

import { ISignedIn, getRootPage, lazy, wrapper } from 'other';
import { useSubscription } from 'ui';
import { AmauiService, AuthService } from 'services';

import RootAuth from './pages/auth/Root';
import SignIn from './pages/auth/SignIn';
import SignUp from './pages/auth/SignUp';
import SignUpConfirm from './pages/auth/SignUpConfirm';
import ForgotPassword from './pages/auth/ForgotPassword';

import Main from './pages/main/Main';
import MediasMedia from './pages/main/Medias/Media';
import MediasAdd from './pages/main/Medias/Add';

import Test from './pages/main/Test';

import SettingsInfo from './pages/main/UserSettings/Info';
import SettingsApp from './pages/main/UserSettings/App';
import SettingsSecurity from './pages/main/UserSettings/Security';
import SettingsNotifications from './pages/main/UserSettings/Notifications';
import SettingsSignIns from './pages/main/UserSettings/SignIns';
import SettingsManage from './pages/main/UserSettings/Manage';

import OrganizationSettingsInfo from './pages/main/OrganizationSettings/Info';
import OrganizationSettingsUsers from './pages/main/OrganizationSettings/Users';
import OrganizationSettingsUserGroups from './pages/main/OrganizationSettings/UserGroups';
import OrganizationSettingsInvites from './pages/main/OrganizationSettings/Invites';
import OrganizationSettingsPaymentMethods from './pages/main/OrganizationSettings/PaymentMethods';
import OrganizationSettingsInvoices from './pages/main/OrganizationSettings/Invoices';
import OrganizationSettingsIntegrations from './pages/main/OrganizationSettings/Integrations';
import OrganizationSettingsActivityLogs from './pages/main/OrganizationSettings/ActivityLogs';
import OrganizationSettingsManage from './pages/main/OrganizationSettings/Manage';

import OrganizationSettingsSubscriptionsAmaui from './pages/main/OrganizationSettings/Subscription';

import ManageUsers from './pages/main/Manage/Users';
import ManageOrganizations from './pages/main/Manage/Organizations';
import ManageAuditLogs from './pages/main/Manage/AuditLogs';

const RootMain = lazy(React.lazy(() => import('./pages/main/Root')));
const Tasks = lazy(React.lazy(() => import('./pages/main/Task/Tasks')));
const TasksMonth = lazy(React.lazy(() => import('./pages/main/Task/TasksMonth')));
const TasksLists = lazy(React.lazy(() => import('./pages/main/Task/TasksLists')));
const Notes = lazy(React.lazy(() => import('./pages/main/Notes')));
const NotesMentions = lazy(React.lazy(() => import('./pages/main/NotesMentions')));
const Posts = lazy(React.lazy(() => import('./pages/main/Posts')));
const Resumes = lazy(React.lazy(() => import('./pages/main/Resume/Resumes')));
const Portfolios = lazy(React.lazy(() => import('./pages/main/Resume/Portfolios')));
const Skills = lazy(React.lazy(() => import('./pages/main/Resume/Skills')));
const UrlShorteners = lazy(React.lazy(() => import('./pages/main/UrlShorteners')));
const Themes = lazy(React.lazy(() => import('./pages/main/Website/Themes')));
const Websites = lazy(React.lazy(() => import('./pages/main/Website/Websites')));
const Website = lazy(React.lazy(() => import('./pages/main/Website/Website')));
const Templates = lazy(React.lazy(() => import('./pages/main/Website/Templates')));
const Tags = lazy(React.lazy(() => import('./pages/main/Tags')));
const SupportPages = lazy(React.lazy(() => import('./pages/main/Support/SupportPages')));
const SupportArticles = lazy(React.lazy(() => import('./pages/main/Support/SupportArticles')));
const Chats = lazy(React.lazy(() => import('./pages/main/Chats/Chats')));
const Forms = lazy(React.lazy(() => import('./pages/main/Form/Forms')));
const Availables = lazy(React.lazy(() => import('./pages/main/Reservation/Availables')));
const ReservationOptions = lazy(React.lazy(() => import('./pages/main/Reservation/ReservationOptions')));
const Reservations = lazy(React.lazy(() => import('./pages/main/Reservation/Reservations')));
const Contacts = lazy(React.lazy(() => import('./pages/main/Contact/Contacts')));
const ContactGroups = lazy(React.lazy(() => import('./pages/main/Contact/ContactGroups')));
const WorkDays = lazy(React.lazy(() => import('./pages/main/WorkDays')));
const Locations = lazy(React.lazy(() => import('./pages/main/Locations')));

// medias
const MediasRoot = lazy(React.lazy(() => import('./pages/main/Medias')));

// settings
const SettingsRoot = lazy(React.lazy(() => import('./pages/main/UserSettings')));

// organization settings
const OrganizationSettingsRoot = lazy(React.lazy(() => import('./pages/main/OrganizationSettings')));

// manage
const ManageRoot = lazy(React.lazy(() => import('./pages/main/Manage')));

const Routing = React.forwardRef((props: any, ref: any) => {
  const {
    ...other
  } = props;

  const navigate = useNavigate();

  const signInMethod = (value: any, setResponse: any) => {
    // Sign out
    if (refs.signedIn.current && value === null) {
      // redirect to
      const redirect_to = getRedirectTo();

      let to = `/sign-in`;

      if (AuthService.redirect) to += `?redirect_to=${redirect_to}`;

      navigate(to);
    }

    setResponse([value]);
  };

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn, signInMethod);
  const initial = useSubscription(AmauiService.initial);

  const refs = {
    signedIn: React.useRef(signedIn)
  };

  refs.signedIn.current = signedIn;

  // Only show routes
  // when we have all the info
  // about the auth, user
  if (!initial) return null;

  const is = signedIn?.user?.is;

  return (
    <Routes
      ref={ref}

      {...other}
    >
      {AuthService.isAuthenticated && (
        <Route
          element={wrapper(RootMain)}
        >
          <Route
            index

            element={wrapper(Main)}
          />

          {is?.amaui && (
            <Route
              path='/test'

              element={wrapper(Test)}
            />
          )}

          {is?.user && (
            <Route
              element={wrapper(MediasRoot)}
            >
              <Route
                path='/medias'

                element={wrapper(MediasMedia)}
              />

              <Route
                path='/medias/add'

                element={wrapper(MediasAdd)}
              />
            </Route>
          )}

          {/* Tasks */}
          {is?.user && <>
            <Route
              path='/tasks'

              element={wrapper(Tasks)}
            />

            <Route
              path='/tasks/assigned-to-me'

              element={wrapper(Tasks)}
            />

            <Route
              path='/tasks/all'

              element={wrapper(Tasks)}
            />

            <Route
              path='/tasks/all/add'

              element={wrapper(Tasks)}
            />

            <Route
              path='/tasks/all/:id'

              element={wrapper(Tasks)}
            />

            <Route
              path='/tasks/month'

              element={wrapper(TasksMonth)}
            />

            <Route
              path='/tasks/month/:id'

              element={wrapper(TasksMonth)}
            />

            <Route
              path='/tasks/lists'

              element={wrapper(TasksLists)}
            />

            <Route
              path='/tasks/lists/:id'

              element={wrapper(TasksLists)}
            />

            <Route
              path='/tasks/tomorrow'

              element={wrapper(Tasks)}
            />

            <Route
              path='/tasks/tomorrow/add'

              element={wrapper(Tasks)}
            />

            <Route
              path='/tasks/tomorrow/:id'

              element={wrapper(Tasks)}
            />

            <Route
              path='/tasks/week'

              element={wrapper(Tasks)}
            />

            <Route
              path='/tasks/week/add'

              element={wrapper(Tasks)}
            />

            <Route
              path='/tasks/week/:id'

              element={wrapper(Tasks)}
            />

            <Route
              path='/tasks/add'

              element={wrapper(Tasks)}
            />

            <Route
              path='/tasks/:id'

              element={wrapper(Tasks)}
            />
          </>}

          {/* Notes */}
          {is?.user && <>
            <Route
              path='/notes'

              element={wrapper(Notes)}
            />

            <Route
              path='/notes/mentions'

              element={wrapper(NotesMentions)}
            />

            <Route
              path='/notes/mentions/:id'

              element={wrapper(NotesMentions)}
            />

            <Route
              path='/notes/add'

              element={wrapper(Notes)}
            />

            <Route
              path='/notes/:id'

              element={wrapper(Notes)}
            />
          </>}

          {/* Posts */}
          {is?.user && <>
            <Route
              path='/posts'

              element={wrapper(Posts)}
            />

            <Route
              path='/posts/add'

              element={wrapper(Posts)}
            />

            <Route
              path='/posts/:id'

              element={wrapper(Posts)}
            />
          </>}

          {/* Resumes */}
          {is?.user && <>
            <Route
              path='/resumes'

              element={wrapper(Resumes)}
            />

            <Route
              path='/resumes/add'

              element={wrapper(Resumes)}
            />

            <Route
              path='/resumes/:id'

              element={wrapper(Resumes)}
            />
          </>}

          {/* Portfolios */}
          {is?.user && <>
            <Route
              path='/portfolios'

              element={wrapper(Portfolios)}
            />

            <Route
              path='/portfolios/add'

              element={wrapper(Portfolios)}
            />

            <Route
              path='/portfolios/:id'

              element={wrapper(Portfolios)}
            />
          </>}

          {/* Skills */}
          {is?.user && <>
            <Route
              path='/skills'

              element={wrapper(Skills)}
            />

            <Route
              path='/skills/add'

              element={wrapper(Skills)}
            />

            <Route
              path='/skills/:id'

              element={wrapper(Skills)}
            />
          </>}

          {/* UrlShorteners */}
          {is?.user && <>
            <Route
              path='/url-shorteners'

              element={wrapper(UrlShorteners)}
            />

            <Route
              path='/url-shorteners/add'

              element={wrapper(UrlShorteners)}
            />

            <Route
              path='/url-shorteners/:id'

              element={wrapper(UrlShorteners)}
            />
          </>}

          {/* Websites */}
          {is?.user && <>
            <Route
              path='/websites'

              element={wrapper(Websites)}
            />

            <Route
              path='/websites/add'

              element={wrapper(Websites)}
            />

            <Route
              path='/websites/:id'

              element={wrapper(Website)}
            />

            <Route
              path='/websites/:id/pages/:id'

              element={wrapper(Website)}
            />
          </>}

          {/* Templates */}
          {is?.user && <>
            <Route
              path='/templates'

              element={wrapper(Templates)}
            />

            <Route
              path='/templates/add'

              element={wrapper(Templates)}
            />

            <Route
              path='/templates/:id'

              element={wrapper(Templates)}
            />
          </>}

          {/* Themes */}
          {is?.amaui && <>
            <Route
              path='/themes'

              element={wrapper(Themes)}
            />

            <Route
              path='/themes/add'

              element={wrapper(Themes)}
            />

            <Route
              path='/themes/:id'

              element={wrapper(Themes)}
            />
          </>}

          {/* Support pages */}
          {is?.user && <>
            <Route
              path='/support-pages'

              element={wrapper(SupportPages)}
            />

            <Route
              path='/support-pages/add'

              element={wrapper(SupportPages)}
            />

            <Route
              path='/support-pages/:id'

              element={wrapper(SupportPages)}
            />
          </>}

          {/* Support articles */}
          {is?.user && <>
            <Route
              path='/support-articles'

              element={wrapper(SupportArticles)}
            />

            <Route
              path='/support-articles/add'

              element={wrapper(SupportArticles)}
            />

            <Route
              path='/support-articles/:id'

              element={wrapper(SupportArticles)}
            />
          </>}

          {/* Chats */}
          {is?.user && <>
            <Route
              path='/chats'

              element={wrapper(Chats)}
            />

            <Route
              path='/chats/new'

              element={wrapper(Chats)}
            />

            <Route
              path='/chats/:id'

              element={wrapper(Chats)}
            />
          </>}

          {/* Forms */}
          {is?.user && <>
            <Route
              path='/forms'

              element={wrapper(Forms)}
            />

            <Route
              path='/forms/new'

              element={wrapper(Forms)}
            />

            <Route
              path='/forms/:id'

              element={wrapper(Forms)}
            />

            <Route
              path='/forms/:id/responses/:id_response'

              element={wrapper(Forms)}
            />
          </>}

          {/* Reservation */}
          {is?.user && <>
            {/* Availables */}
            <Route
              path='/availables'

              element={wrapper(Availables)}
            />

            <Route
              path='/availables/add'

              element={wrapper(Availables)}
            />

            <Route
              path='/availables/:id'

              element={wrapper(Availables)}
            />

            {/* Reservation options */}
            <Route
              path='/reservation-options'

              element={wrapper(ReservationOptions)}
            />

            <Route
              path='/reservation-options/add'

              element={wrapper(ReservationOptions)}
            />

            <Route
              path='/reservation-options/:id'

              element={wrapper(ReservationOptions)}
            />

            {/* Reservations */}
            <Route
              path='/reservations'

              element={wrapper(Reservations)}
            />

            <Route
              path='/reservations/add'

              element={wrapper(Reservations)}
            />

            <Route
              path='/reservations/:id'

              element={wrapper(Reservations)}
            />
          </>}

          {/* Contacts */}
          {is?.user && <>
            <Route
              path='/contacts'

              element={wrapper(Contacts)}
            />

            <Route
              path='/contacts/add'

              element={wrapper(Contacts)}
            />

            <Route
              path='/contacts/:id'

              element={wrapper(Contacts)}
            />

            <Route
              path='/contact-groups'

              element={wrapper(ContactGroups)}
            />

            <Route
              path='/contact-groups/add'

              element={wrapper(ContactGroups)}
            />

            <Route
              path='/contact-groups/:id'

              element={wrapper(ContactGroups)}
            />
          </>}

          {/* Work days */}
          {is?.user && <>
            <Route
              path='/work-days'

              element={wrapper(WorkDays)}
            />

            <Route
              path='/work-days/add'

              element={wrapper(WorkDays)}
            />

            <Route
              path='/work-days/:id'

              element={wrapper(WorkDays)}
            />
          </>}

          {/* Locations */}
          {is?.user && <>
            <Route
              path='/locations'

              element={wrapper(Locations)}
            />

            <Route
              path='/locations/add'

              element={wrapper(Locations)}
            />

            <Route
              path='/locations/:id'

              element={wrapper(Locations)}
            />
          </>}

          {/* Tags */}
          {is?.user && <>
            <Route
              path='/tags'

              element={wrapper(Tags)}
            />

            <Route
              path='/tags/add'

              element={wrapper(Tags)}
            />

            <Route
              path='/tags/:id'

              element={wrapper(Tags)}
            />
          </>}

          {/* Settings */}
          <Route
            element={wrapper(SettingsRoot)}
          >
            <Route
              path='/settings'

              element={wrapper(SettingsInfo)}
            />

            <Route
              path='/settings/app'

              element={wrapper(SettingsApp)}
            />

            <Route
              path='/settings/security'

              element={wrapper(SettingsSecurity)}
            />

            <Route
              path='/settings/notifications'

              element={wrapper(SettingsNotifications)}
            />

            <Route
              path='/settings/sign-ins'

              element={wrapper(SettingsSignIns)}
            />

            <Route
              path='/settings/sign-ins/:id'

              element={wrapper(SettingsSignIns)}
            />

            <Route
              path='/settings/manage'

              element={wrapper(SettingsManage)}
            />
          </Route>

          {is?.admin && (
            <Route
              element={wrapper(OrganizationSettingsRoot)}
            >
              <Route
                path='/organization/settings'

                element={wrapper(OrganizationSettingsInfo)}
              />

              <Route
                path='/organization/settings/users'

                element={wrapper(OrganizationSettingsUsers)}
              />

              <Route
                path='/organization/settings/users/:id'

                element={wrapper(OrganizationSettingsUsers)}
              />

              <Route
                path='/organization/settings/user-groups'

                element={wrapper(OrganizationSettingsUserGroups)}
              />

              <Route
                path='/organization/settings/user-groups/add'

                element={wrapper(OrganizationSettingsUserGroups)}
              />

              <Route
                path='/organization/settings/user-groups/:id'

                element={wrapper(OrganizationSettingsUserGroups)}
              />

              <Route
                path='/organization/settings/invites'

                element={wrapper(OrganizationSettingsInvites)}
              />
              <Route
                path='/organization/settings/invites/add'

                element={wrapper(OrganizationSettingsInvites)}
              />

              <Route
                path='/organization/settings/payment-methods'

                element={wrapper(OrganizationSettingsPaymentMethods)}
              />
              <Route
                path='/organization/settings/payment-methods/add'

                element={wrapper(OrganizationSettingsPaymentMethods)}
              />

              <Route
                path='/organization/settings/subscription'

                element={wrapper(OrganizationSettingsSubscriptionsAmaui)}
              />

              <Route
                path='/organization/settings/invoices'

                element={wrapper(OrganizationSettingsInvoices)}
              />

              <Route
                path='/organization/settings/integrations'

                element={wrapper(OrganizationSettingsIntegrations)}
              />

              <Route
                path='/organization/settings/activity-logs'

                element={wrapper(OrganizationSettingsActivityLogs)}
              />

              {is?.owner && (
                <Route
                  path='/organization/settings/manage'

                  element={wrapper(OrganizationSettingsManage)}
                />
              )}
            </Route>
          )}

          {is?.amaui && (
            <Route
              element={wrapper(ManageRoot)}
            >
              <Route
                path='/manage/users'

                element={wrapper(ManageUsers)}
              />

              <Route
                path='/manage/organizations'

                element={wrapper(ManageOrganizations)}
              />

              <Route
                path='/manage/audit-logs'

                element={wrapper(ManageAuditLogs)}
              />
            </Route>
          )}

          <Route
            path='*'

            element={(
              <NotFound
                to={getRootPage(signedIn)}

                ButtonProps={{
                  name: 'Back to home'
                }}
              />
            )}
          />
        </Route>
      )}

      {!AuthService.isAuthenticated && (
        <Route
          element={wrapper(RootAuth)}
        >
          <Route
            path='/sign-in'

            element={wrapper(SignIn)}
          />

          <Route
            path='/sign-up'

            element={wrapper(SignUp)}
          />

          <Route
            path='/sign-up/confirm'

            element={wrapper(SignUpConfirm)}
          />

          <Route
            path='/forgot-password'

            element={wrapper(ForgotPassword)}
          />

          <Route
            path='*'

            element={<Navigate to='/sign-in' />}
          />
        </Route>
      )}
    </Routes>
  );
});

export default Routing;
