/* eslint-disable import/no-anonymous-default-export */

class Config {
  public values = {
    amaui_token: '',

    apis: {
      api: {
        url: ''
      },
      api_media_read: {
        url: ''
      },
      api_media_write: {
        url: ''
      },
      public: {
        url: ''
      },
      websites: {
        url: ''
      },
      sockets: {
        url: ''
      },
      personal_trainer: {
        url: ''
      },
      gym_management: {
        url: ''
      }
    },

    services: {
      google: {
        client_id: ''
      },

      stripe: {
        public_key: ''
      }
    },

    resolveOnError: true
  };

  get value() {
    const env = process.env.NODE_ENV;

    return {
      amaui_token: process.env.REACT_APP_AMAUI_TOKEN || this.values.amaui_token,

      env,

      production: ['prod', 'production'].includes(env),

      apps: {
        api: {
          url: process.env.REACT_APP_APIS_API || this.values.apis.api.url
        },
        api_media_read: {
          url: process.env.REACT_APP_APIS_API_MEDIA_READ || this.values.apis.api_media_read.url
        },
        api_media_write: {
          url: process.env.REACT_APP_APIS_API_MEDIA_WRITE || this.values.apis.api_media_write.url
        },
        public: {
          url: process.env.REACT_APP_PUBLIC || this.values.apis.public.url
        },
        websites: {
          url: process.env.REACT_APP_WEBSITES || this.values.apis.websites.url,
          urlHost: (process.env.REACT_APP_WEBSITES || this.values.apis.websites.url).replace('https://', '').replace('http://', '')
        },
        sockets: {
          url: process.env.REACT_APP_APIS_SOCKETS || this.values.apis.sockets.url
        },
        personal_trainer: {
          url: process.env.REACT_APP_PERSONAL_TRAINER || this.values.apis.personal_trainer.url
        },
        gym_management: {
          url: process.env.REACT_APP_GYM_MANAGEMENT || this.values.apis.gym_management.url
        }
      },

      services: {
        google: {
          client_id: process.env.REACT_APP_SERVICES_GOOGLE_CLIENT_ID,

          map: {
            api_key: process.env.REACT_APP_SERVICES_GOOGLE_MAP_API_KEY
          }
        },

        stripe: {
          public_key: process.env.REACT_APP_SERVICE_STRIPE_PUBLIC_KEY
        },

        sentry: {
          dsn: process.env.REACT_APP_SERVICE_SENTRY_DSN
        }
      },

      webPush: {
        vapid: {
          public_key: process.env.REACT_APP_WEB_PUSH_VAPID_PUBLIC_KEY
        }
      }
    };
  }

  private array(value: string) {
    if (!value) return;

    return (value || '').split(',').map(item => item.trim()).filter(Boolean);
  }
}

export default new Config();
