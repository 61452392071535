import React from 'react';

import { Form, FormRow, Line, SmartTextField, Switch, TextField } from '@amaui/ui-react';
import { classNames, style, useAmauiTheme } from '@amaui/style-react';
import { getObjectValue, setObjectValue, textToInnerHTML } from '@amaui/utils';

import { SelectColor } from './elements';

const useStyle = style(theme => ({
  root: {

  },

  preview: {
    position: 'relative',
    width: 154,
    height: 77,
    borderRadius: 12,
    overflow: 'hidden',
    boxShadow: theme.shadows.values.default[1]
  },

  type: {
    columnGap: '40px'
  },

  divider: {
    columnRule: `1px solid ${theme.palette.text.divider}`
  },

  h1: {
    ...theme.typography.values.h1,

    fontSize: `clamp(1.75rem, 1.5874vw, ${theme.typography.values.h1.fontSize})`
  },

  h2: {
    ...theme.typography.values.h2,

    fontSize: `clamp(1.5rem, 1.3394vw, ${theme.typography.values.h2.fontSize})`
  },

  h3: {
    ...theme.typography.values.h3,

    fontSize: `clamp(1.25rem, 1.1904vw, ${theme.typography.values.h3.fontSize})`
  },

  noData: {
    '& .amaui-FormRow-main': {
      display: 'none'
    }
  }
}), { name: 'amaui-app-OptionsSectionContact' });

const OptionsSectionContact = React.forwardRef((props: any, ref: any) => {
  const {
    value,

    element,

    index,

    onUpdate,

    className,

    children,

    ...other
  } = props;

  const { classes } = useStyle();

  const theme = useAmauiTheme();

  const refs = {
    value: React.useRef(value),
    index: React.useRef(index),
    onUpdate: React.useRef(onUpdate),
    element: React.useRef(element)
  };

  refs.value.current = value;

  refs.index.current = index;

  refs.onUpdate.current = onUpdate;

  refs.element.current = element;

  const update = React.useCallback((property: string, valueProperty: any) => {
    const valueNew = [...(refs.value.current || [])];

    if (refs.element.current) {
      setObjectValue(refs.element.current, property, valueProperty);

      refs.onUpdate.current(valueNew);
    }
  }, []);

  const enable = React.useCallback((valueSwitch: boolean, property: string, valueDefault: any) => {
    const valueNew = [...(refs.value.current || [])];

    if (refs.element.current) {
      setObjectValue(refs.element.current, property, !valueSwitch ? null : valueDefault);

      refs.onUpdate.current(valueNew);
    }
  }, []);

  const palette = element?.props?.map?.overlay && (theme.palette.color[element?.props?.map?.overlay] || theme.methods.color(element?.props?.map?.overlay));

  const getItem = (item: any, index: number) => (
    <FormRow
      key={index}

      gap={2}

      name={item.name}

      start={!item.noSwitch && (
        <Switch
          tonal

          valueDefault={item.defaultSwitch !== undefined ? item.defaultSwitch : !!getObjectValue(element?.props, item.property)}

          onChange={(valueNew: any) => enable(valueNew, `props.${item.property}`, item.default)}

          size='small'
        />
      )}

      {...item?.props}

      className={classNames([
        item?.props?.className,
        (!item.show && [null, undefined].includes(getObjectValue(element?.props || {}, item.property))) && classes.noData
      ])}
    >
      {(item.show || ![null, undefined].includes(getObjectValue(element?.props || {}, item.property))) && item.value}
    </FormRow>
  );

  const options: any = [
    // map overlay  
    {
      name: 'Map overlay',
      property: 'map.overlay',
      default: 'primary',
      value: (
        <Line
          gap={1.5}

          direction='column'

          fullWidth
        >
          <SelectColor
            value={element?.props?.map?.overlay}

            onChange={(valueNew: any) => update('props.map.overlay', valueNew)}

            except={['default']}
          />

          <Line
            className={classes.preview}

            style={{
              backgroundColor: element?.props?.map?.overlay && theme.methods.palette.color.value(undefined as any, 95, true, palette)
            }}
          />
        </Line>
      )
    },

    // mapPosition 
    // {
    //   name: 'Map position',
    //   property: 'mapPosition',
    //   default: 'left',
    //   noSwitch: true,
    //   show: true,
    //   value: (
    //     <SelectAny
    //       value={element?.props?.mapPosition || 'left'}

    //       options={['top', 'bottom']}

    //       onChange={(valueNew: any) => update('props.mapPosition', valueNew)}
    //     />
    //   )
    // }
  ];

  return (
    <Line
      fullWidth

      className={classNames([
        className,
        classes.root
      ])}

      {...other}
    >
      <Form
        size='small'

        wrapper
      >
        <Line
          fullWidth
        >
          <FormRow
            fullWidth

            style={{
              marginBottom: 24
            }}
          >
            <SmartTextField
              placeholder='Heading'

              valueDefault={textToInnerHTML(element?.props?.heading) || ''}

              onChange={(valueNew: any) => update('props.heading', valueNew)}

              additional={{
                version: 't1'
              }}
            />

            <SmartTextField
              placeholder='Summary'

              valueDefault={textToInnerHTML(element?.props?.summary) || ''}

              onChange={(valueNew: any) => update('props.summary', valueNew)}

              minRows={3}

              multiline

              edit
            />
          </FormRow>

          <Line
            gap={1}

            fullWidth
          >
            <TextField
              name='Address'

              valueDefault={element?.props?.address || ''}

              onChange={(valueNew: any) => update('props.address', valueNew)}
            />

            <TextField
              name='Phone'

              valueDefault={element?.props?.phone || ''}

              onChange={(valueNew: any) => update('props.phone', valueNew)}

              type='tel'
            />

            <TextField
              name='Email'

              valueDefault={element?.props?.email || ''}

              onChange={(valueNew: any) => update('props.email', valueNew)}

              type='email'
            />
          </Line>

          <FormRow
            name='Map'

            description={`You have to provide your own Google Maps API key, latitude and longitude of the address, for map to show.`}
          >
            <TextField
              name='API key'

              valueDefault={element?.props?.map?.apiKey || ''}

              onChange={(valueNew: any) => update('props.map.apiKey', valueNew)}
            />

            <TextField
              name='Latitude'

              valueDefault={element?.props?.map?.latitude || ''}

              onChange={(valueNew: any) => update('props.map.latitude', valueNew)}
            />

            <TextField
              name='Longitude'

              valueDefault={element?.props?.map?.longitude || ''}

              onChange={(valueNew: any) => update('props.map.longitude', valueNew)}
            />
          </FormRow>

          {options.map((item: any, index: number) => getItem(item, index))}
        </Line>
      </Form>
    </Line>
  );
});

export default OptionsSectionContact;
