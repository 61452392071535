import { Resume } from '@amaui/api-utils';
import AmauiSubscription from '@amaui/subscription';
import { IAdditional, IResponse } from '@amaui/sdk/other';

import { BaseService } from './base';
import { IQuerySubscription, ISelectedSubscription } from 'other';
import { AmauiService } from 'services';

class ResumeService extends BaseService<Resume> {
  public queryItems = new AmauiSubscription<IQuerySubscription>();
  public selectedItems = new AmauiSubscription<ISelectedSubscription>();

  public constructor() {
    super('resumes');

    this.queryObjectsInit(this.queryItems);

    this.selectedObjectsInit(this.selectedItems);
  }

  public async read(id: string, options?: IAdditional): Promise<IResponse> {
    return AmauiService.sdk.resumes.read(id, { ...options, response: { type: 'blob' } });
  }

}

export default new ResumeService();
