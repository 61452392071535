import React from 'react';
import { Outlet } from 'react-router-dom';

import { Line } from '@amaui/ui-react';
import { classNames, style, useAmauiTheme } from '@amaui/style-react';

const useStyle = style(theme => ({
  root: {

  }
}), { name: 'amaui-app-route-Medias' });

const Medias = React.forwardRef((props: any, ref: any) => {
  const {
    className,

    ...other
  } = props;

  const theme = useAmauiTheme();

  const { classes } = useStyle();

  return (
    <Line
      ref={ref}

      flex

      fullWidth

      className={classNames([
        className,
        classes.root
      ])}

      {...other}

      style={{
        maxWidth: theme.maxWidth,

        ...other.style
      }}
    >
      <Outlet />
    </Line>
  );
});

export default Medias;
