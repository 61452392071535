import React from 'react';

import { FormRow } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';

import { WebsiteService } from 'services';
import { AutoCompleteObjects } from 'ui/elements';

const useStyle = style(theme => ({
  root: {

  }
}), { name: 'amaui-app-OptionsWebsites' });

const OptionsWebsites = React.forwardRef((props: any, ref: any) => {
  const {
    value,

    onChange,

    className,

    children,

    ...other
  } = props;

  const { classes } = useStyle();

  return (
    <FormRow
      ref={ref}

      name='Websites'

      description='Websites it can be visible at'

      className={classNames([
        className,
        classes.root
      ])}

      {...other}
    >
      <AutoCompleteObjects
        name='Websites'

        value={value}

        onChange={onChange}

        service={WebsiteService}

        closeOnSelect

        fullWidth

        multiple

        chip
      />
    </FormRow>
  );
});

export default OptionsWebsites;
