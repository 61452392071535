import React from 'react';

import { getFileName, is, wait } from '@amaui/utils';
import { DropZone, IconButton, Line, LinearProgress, Modal, ModalFooter, ModalHeader, ModalMain, ModalTitle, Slide, Tab, Tabs, Tooltip, useForm, useSnackbars } from '@amaui/ui-react';
import { style } from '@amaui/style-react';
import { IApp, IMedia } from '@amaui/api-utils';

import IconMaterialOpenInNewRounded from '@amaui/icons-material-rounded-react/IconMaterialOpenInNew';
import IconMaterialCloseRounded from '@amaui/icons-material-rounded-react/IconMaterialClose';

import { Button, ClearSearch, MoreMenu, Page, useSubscription } from 'ui';
import { AuthService, MediaService } from 'services';
import { APP, ISignedIn, getErrorMessage, getMediaUrl } from 'other';
import MediaContext from './Context';
import { MediaItems } from 'pages/main/Medias/Media';
import { IPageItemMethodOptions } from '../Page';

const useStyle = style(theme => ({
  root: {

  },

  modalMain: {
    padding: '20px 16px 0',
    minHeight: 'clamp(0px, 370px, 53vh)',
    overflowY: 'auto',
    overflowX: 'hidden'
  },

  modalHeader: {
    '&.amaui-ModalHeader-root': {
      position: 'relative',
      paddingBottom: 0
    }
  },

  modalFooter: {
    '&.amaui-ModalFooter-root': {
      padding: 16
    }
  },

  wrapperTitle: {
    padding: '12px 16px 0px 24px'
  },

  linearProgress: {
    '&.amaui-LinearProgress-root': {
      position: 'absolute',
      bottom: '-4px',
      left: '0px',
      width: '100%'
    }
  },

  surface: {
    '&.amaui-Surface-root': {
      padding: 0,
      background: theme.palette.color.primary[theme.palette.light ? 99 : 5]
    }
  },

  imageList: {
    '&.amaui-ImageList-root': {

      '& .amaui-ImageListItem-root': {
        overflow: 'hidden',

        // '&:active': {
        //   transform: 'scale(0.94)'
        // },

        '& img': {
          height: '100%',
          width: 'auto',
          objectFit: 'contain'
        }
      }
    }
  },

  imageListItem: {
    borderRadius: 24,
    outline: '0px solid',
    transition: theme.methods.transitions.make(['border-radius', 'outline']),

    '&[disabled]': {
      opacity: 0.4,
      cursor: 'default',
      pointerEvents: 'none'
    }
  },

  imageListItemSelected: {
    outline: '2px solid',
    outlineColor: theme.methods.palette.color.value('secondary', 30)
  },

  masonry: {
    padding: '2px'
  },

  table: {
    position: 'relative',
    minHeight: '47vh'
  }
}), { name: 'amaui-app-Media' });

export interface IMediaOptions {
  multiple?: boolean;

  app?: IApp;

  filters?: any;
  allowed?: string[];

  onSelect?: (value: IMedia[]) => any;
  onConfirm?: (selected: IMedia[]) => any;

  selected?: any[];
}

const Media = React.forwardRef((props: any, ref: any) => {
  const {
    selected: selected_ = [],

    children,

    ...other
  } = props;

  const { classes } = useStyle();

  const snackbars = useSnackbars();
  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const [openModal, setOpenModal] = React.useState(false);
  const [page, setPage] = React.useState<'media' | 'add'>('media');
  const [options, setOptions] = React.useState<IMediaOptions>({});
  const [loading, setLoading] = React.useState<any>(false);
  const [selected, setSelected] = React.useState<IMedia[]>(selected_.filter(Boolean));
  const [moreMenu, setMoreMenu] = React.useState<any>();

  const formSearchValueDefault = React.useMemo(() => {
    return {
      text: '',
      mime: 'image',
      apps: 'all',
      origin: 'internal',
      added_at: []
    };
  }, []);

  const formSearch = useForm({
    values: {
      text: {
        name: 'Text',
        is: 'string',
        value: ''
      },
      mime: {
        name: 'Type',
        value: 'image',
        in: ['all', 'image', 'audio', 'video']
      },
      apps: {
        name: 'App',
        value: 'all',
        in: APP
      },
      origin: {
        name: 'Origin',
        value: 'all',
        in: ['internal', 'external']
      },
      added_at: {
        name: 'Added at',
        value: []
      }
    },

    valueDefault: {
      ...formSearchValueDefault
    },

    autoValidate: true
  });

  const refs = {
    signedIn: React.useRef(signedIn),
    options: React.useRef<any>(),
    formSearch: React.useRef<any>(formSearch)
  };

  refs.signedIn.current = signedIn;

  refs.options.current = options;

  refs.formSearch.current = formSearch;

  const update = React.useCallback(async (options?: IMediaOptions) => {
    const {
      selected: selectedOption,

      ...otherOptions
    } = options || {};

    setSelected(selectedOption || []);

    if (options !== undefined) setOptions(otherOptions);

    if (options?.filters) {
      const value: any = [];

      Object.keys(options.filters).forEach((item: string) => {
        value.push([item, options.filters[item]]);
      });

      if (!!value.length) {
        refs.formSearch.current.onChange(value);

        await wait(140);
      }
    }
  }, []);

  const open = React.useCallback(async (options?: IMediaOptions) => {
    await update(options);

    if (options?.filters) {
      setOpenModal(true);
    }
  }, [update]);

  const close = React.useCallback(() => {
    setOpenModal(false);
  }, []);

  const cleanUp = React.useCallback(() => {
    MediaService.selectedItemsModal.value!.reset();
  }, []);

  const onClose = React.useCallback(() => {
    setOpenModal(false);

    cleanUp();
  }, []);

  const onConfirm = React.useCallback((value: IMedia) => {
    if (is('function', options.onConfirm)) options.onConfirm!(selected);

    onClose();
  }, [selected, options, onClose]);

  const onUnselectAll = React.useCallback(() => {
    setSelected([]);
  }, []);

  const onSelect = React.useCallback((value: IMedia) => {
    const multiple = options.multiple || false;

    let selectedNew = [...selected];

    const index = selectedNew.findIndex((item: any) => item?.id === value?.id);

    if (index > -1) selectedNew.splice(index, 1);
    else selectedNew.push(value);

    if (!multiple && selectedNew.length > 1) selectedNew = selectedNew.slice(-1);

    if (is('function', options.onSelect)) options.onSelect!(selectedNew);

    setSelected(selectedNew);
  }, [selected, options]);

  const onDropZoneChange = React.useCallback(async (value: File[]) => {
    setLoading('add-drop-zone');

    try {
      const media = value[0];

      const name = getFileName(media);

      // validate
      // 140 mb maximum
      if (media.size > (140 * 1e6)) {
        snackbars.add({
          color: 'error',
          primary: `Maximum allowed file size is 140 mb`
        });

        return;
      }

      // meta
      const meta: any = {};

      const result = await MediaService.add({
        name,

        meta,

        apps: [refs.options.current.app || 'amaui'],

        // media
        media
      });

      if (result.status >= 400) {
        snackbars.add({
          color: 'error',
          primary: getErrorMessage(result)
        });
      }
      else {
        snackbars.add({
          primary: 'Media added'
        });

        // requery 
        MediaService.queryItemsModal.value!.refetch.bind(MediaService.queryItemsModal.value)();

        // to media
        setPage('media');
      }
    }
    catch (error) { }

    setLoading(false);
  }, [options]);

  const value = React.useMemo(() => {
    return {
      open,
      update,
      close
    };
  }, []);

  const tabs = React.useMemo(() => {

    return [
      { label: 'Media', value: 'media' },
      { label: 'Add', value: 'add' }
    ];
  }, []);

  const onMoreMenuClose = React.useCallback(() => {
    setMoreMenu((previous: any) => ({
      ...previous,

      open: false
    }));
  }, []);

  const isSelected = (item: any) => !!selected?.find(media => {
    const ids = [media?.id, media?._id, media].filter(Boolean);

    return ids.includes(item?.id) || ids.includes(item?._id);
  });

  const getItems = React.useCallback((options: IPageItemMethodOptions) => {
    return (
      <MediaItems
        options={options}

        MediaItemProps={(item: any) => ({
          SelectProps: {
            checked: isSelected(item),

            onClick: () => onSelect(item)
          }
        })}
      />
    );
  }, [onSelect]);

  const IconProps: any = {
    color: 'inherit',
    size: 'small'
  };

  const properties = React.useMemo(() => ({
    name: { name: 'Name' },
    description: { name: 'Description' },
    mime: { name: 'Type' },
    origin: { name: 'Origin' }
  }), []);

  const queryDefault = React.useMemo(() => {
    const values: any = {};

    Object.keys(options?.filters || {}).forEach(item => {
      if (item === 'mime') {
        if (options.filters[item] !== 'all') values[item] = options.filters[item];
      }
      else values[item] = options.filters[item];
    });

    return values;
  }, [options?.filters]);

  const elements: any = {
    media: (
      <Line
        align='unset'

        justify='unset'

        flex

        fullWidth

        style={{
          minHeight: '100%'
        }}
      >
        <Page
          name='Medias'

          service={MediaService}

          getItems={getItems}

          columns={[
            properties.name
          ]}

          properties={properties}

          formSearch={formSearch}

          formSearchValueDefault={formSearchValueDefault}

          queryDefault={queryDefault}

          isEnabledAction={(item, action) => {
            if (['copy', 'export'].includes(action)) return false;

            return true;
          }}

          filtersPrimaryStart={!!selected?.length && (
            <ClearSearch
              onClick={onUnselectAll}
            >
              Unselect all
            </ClearSearch>
          )}

          search={[
            'apps',
            {
              name: 'Type',
              property: 'mime',
              default: 'all',
              validation: { name: 'Type', is: 'string', value: 'all' },
              options: [
                { name: 'All', value: 'all' },
                { name: 'Images', value: 'image' },
                { name: 'Audio', value: 'audio' },
                { name: 'Video', value: 'video' }
              ]
            },
            {
              name: 'Origin',
              property: 'origin',
              default: 'all',
              validation: { name: 'Origin', is: 'string', value: 'all' },
              options: [
                { name: 'All', value: 'all' },
                { name: 'Internal', value: 'internal' },
                { name: 'External', value: 'external' }
              ]
            }
          ]}

          getItemActions={(item: any, options: any) => [
            { primary: 'Open', onClick: () => window.open(getMediaUrl({ id: item?.id })), start: <IconMaterialOpenInNewRounded {...IconProps} /> }
          ]}

          queryItemsName='queryItemsModal'

          selectedItemsName='selectedItemsModal'

          app='amaui'

          collection='medias'

          nested

          noTags

          noUsage

          noAdd

          noUpdateProject

          noSelect

          noManage

          style={{
            padding: 0
          }}
        />
      </Line>
    ),

    add: (
      <Line
        align='center'

        justify='center'

        fullWidth
      >
        <DropZone
          color='inherit'

          onChange={onDropZoneChange as any}

          disabled={loading}
        />
      </Line>
    )
  };

  return (
    <MediaContext.Provider
      value={value}
    >
      {children}

      <Modal
        tonal

        color='default'

        minWidth='xl'

        freezeScroll

        open={openModal}

        onClose={onClose}

        TransitionComponent={Slide}

        SurfaceProps={{
          color: 'primary',
          tonal: true,
          className: classes.surface
        }}

        {...other}
      >
        <Line
          direction='row'

          justify='space-between'

          align='center'

          className={classes.wrapperTitle}
        >
          <ModalTitle>
            Media
          </ModalTitle>

          <Tooltip
            name='Close'
          >
            <IconButton
              color='inherit'

              onClick={onClose}
            >
              <IconMaterialCloseRounded />
            </IconButton>
          </Tooltip>
        </Line>

        <ModalHeader
          gap={0}

          direction='column'

          justify='center'

          align='center'

          className={classes.modalHeader}
        >
          <Tabs
            value={page}

            onChange={(valueNew: any) => setPage(valueNew)}

            justify='center'
          >
            {tabs.map((item: any, index: number) => (
              <Tab
                key={index}

                value={item.value}
              >
                {item.label}
              </Tab>
            ))}
          </Tabs>

          {loading && (
            <LinearProgress
              className={classes.linearProgress}
            />
          )}
        </ModalHeader>

        <ModalMain
          align='unset'

          justify={page === 'media' ? 'flex-start' : 'center'}

          className={classes.modalMain}
        >
          {elements[page]}
        </ModalMain>

        <ModalFooter
          className={classes.modalFooter}
        >
          <Button
            version='text'

            color='inherit'

            size='small'

            onClick={onClose}
          >
            Close
          </Button>

          <Button
            size='small'

            onClick={onConfirm}
          >
            Confirm
          </Button>
        </ModalFooter>
      </Modal>

      <MoreMenu
        open={!!moreMenu?.open}

        onClose={onMoreMenuClose}

        anchor={moreMenu?.anchor}

        element={moreMenu?.element}

        items={moreMenu?.items}
      />
    </MediaContext.Provider>
  );
});

export default Media;
