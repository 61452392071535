import React from 'react';
import { useNavigate } from 'react-router-dom';

import { to as to_ } from '@amaui/utils';
import { classNames, style } from '@amaui/style-react';
import { Badge, Divider, Expand, List, ListItem, ListSubheader, Menu, Type, useSnackbars } from '@amaui/ui-react';
import { ISwitch } from '@amaui/sdk/other';

import IconMaterialAccountBalanceWalletRounded from '@amaui/icons-material-rounded-react/IconMaterialAccountBalanceWallet';
import IconMaterialCloudRounded from '@amaui/icons-material-rounded-react/IconMaterialCloud';
import IconMaterialSettingsRounded from '@amaui/icons-material-rounded-react/IconMaterialSettings';
import IconMaterialOutputRounded from '@amaui/icons-material-rounded-react/IconMaterialOutput';
import IconMaterialKeyboardArrowDownRounded from '@amaui/icons-material-rounded-react/IconMaterialKeyboardArrowDown';
import IconMaterialPersonAddRounded from '@amaui/icons-material-rounded-react/IconMaterialPersonAdd';

import { FormOrganization, Avatar, useSubscription } from 'ui';
import { AppService, AuthService } from 'services';
import { ISignedIn, getDeviceAndLocation, getErrorMessage, getSettings } from 'other';

const useStyle = style(theme => ({
  root: {

  },

  avatar: {
    '&.amaui-Avatar-root.amaui-Button-root': {
      cursor: 'pointer',
      userSelect: 'none',
      // transition: theme.methods.transitions.make('transform', { duration: 'xs' }),

      // '&:active': {
      //   transform: 'scale(0.94)'
      // }
    }
  },

  menu: {
    maxHeight: '70vh',
    overflow: 'hidden auto',

    '&.amaui-Line-root': {
      width: '100vw',
      maxWidth: 240,
      borderRadius: theme.methods.shape.radius.value(4),
      boxShadow: '0px 4px 32px 0px rgba(0, 0, 0, 0.04)',
      background: theme.palette.light ? theme.palette.color.neutral[100] : theme.palette.color.neutral[20]
    },

    '& .amaui-ListItem-middle .amaui-Type-root': {
      whiteSpace: 'nowrap',
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },

    '& .amaui-ListItem-wrapper:not(.amaui-ListSubheader-root)': {
      background: 'none'
    }
  },

  menuSubheader: {
    '&.amaui-ListSubheader-root': {
      background: 'inherit'
    }
  },

  itemMain: {
    '& + .amaui-Divider-root': {
      marginTop: 0
    }
  },

  arrowDown: {
    transition: theme.methods.transitions.make('transform'),

    '&.open': {
      transform: 'rotate(-180deg)'
    }
  },

  expand: {
    width: '100%'
  },

  addColor: {
    color: theme.palette.light ? 'hsl(198.67deg 97.57% 54%)' : 'hsl(198.67deg 97.57% 84%)'
  },

  divider: {
    '&.amaui-Divider-root': {
      opacity: theme.palette.light ? 0.07 : undefined
    }
  }
}), { name: 'amaui-app-UserMenu' });

const UserMenu = React.forwardRef((props: any, ref: any) => {
  // const {
  //   className,

  //   ...other
  // } = props;

  const { classes } = useStyle();

  const snackbars = useSnackbars();
  const navigate = useNavigate();
  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const [open, setOpen] = React.useState(false);
  const [openOrganizations, setOpenOrganizations] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const onOpen = React.useCallback(() => {
    setOpen(true);
  }, []);

  const onClose = React.useCallback(() => {
    setOpen(false);
    setOpenOrganizations(false);
  }, []);

  const onSwitch = React.useCallback(async (body_: { organization?: any; project?: any; }) => {
    setLoading(true);

    const body: Partial<ISwitch> = {
      ...(await getDeviceAndLocation())
    };

    if (body_.organization) body.organization = { id: body_.organization.id };

    if (body_.project) body.project = { id: body_.project.id };

    const result = await AuthService.switch(body as any);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `${body.organization ? 'Organization' : 'Project'} switched`
      });

      const response = result.response.response;

      await AuthService.init(response);

      navigate('/');

      onClose();
    }

    setLoading(false);
  }, [onClose]);

  const to = React.useCallback((value: string) => {
    navigate(value);

    onClose();
  }, []);

  const onOrganizationsToggle = React.useCallback(() => {
    setOpenOrganizations(previous => !previous);
  }, []);

  const onAddOrganization = React.useCallback(() => {
    AppService.pages.addSecondary.emit({
      version: 'mid',
      open: true,
      title: 'Add organization',
      children: <FormOrganization />
    });

    onClose();
  }, [AppService.pages.addSecondary, onClose]);

  const onSignOut = React.useCallback(async () => {
    await AuthService.signOut();
  }, []);

  const user = signedIn?.user;

  const organizations = signedIn?.organizations || [];

  const organizationsMultiple = organizations.length > 1;

  const organizationSignedIn = organizations.find((item: any) => item.signed_in);

  const organizationsOther = (organizations || []).filter((item: any) => !item.signed_in);

  let initials = '';

  if (user) {
    const [firstName, lastName] = getSettings(signedIn, 'name')!.split(' ');

    initials += firstName.charAt(0);

    initials += ((lastName ? lastName.charAt(0) : firstName.charAt(1)) || '');
  }

  initials = initials?.toLowerCase();

  const IconProps: any = {
    size: 'small'
  };

  const ListItemProps: any = {
    color: 'primary',
    size: 'small'
  };

  const ListItemOrganizationProps: any = {
    ...ListItemProps
  };

  const getUserOrganization = (organization: any) => {
    const userOrganizations = signedIn.organizations;

    return userOrganizations.find(item => item.id === organization.id);
  };

  const logo = organizationSignedIn?.settings?.logos?.logo_light || organizationSignedIn?.settings?.logos?.favicon_light || organizationSignedIn?.settings?.logos?.logo_dark || organizationSignedIn?.settings?.logos?.favicon_dark;

  const organizationsUI = <>
    <ListSubheader
      {...ListItemProps}

      className={classNames([
        ListItemProps?.className,
        classes.menuSubheader
      ])}
    >
      <Type
        version='b3'

        priority='secondary'
      >
        Organizations
      </Type>
    </ListSubheader>

    <ListItem
      start={(
        <Avatar
          tonal

          color='secondary'

          size='small'

          media={logo}
        >
          {organizationSignedIn?.name?.slice(0, 1)}
        </Avatar>
      )}

      primary={(
        <Type
          version='b2'
        >
          {organizationSignedIn?.name}
        </Type>
      )}

      end={organizationsMultiple ? (
        <IconMaterialKeyboardArrowDownRounded
          className={classNames([classes.arrowDown, openOrganizations && 'open'])}
        />
      ) : undefined}

      onClick={organizationsMultiple ? onOrganizationsToggle : undefined}

      selected={openOrganizations}

      button={organizationsMultiple}

      {...ListItemOrganizationProps}
    />

    {organizationsMultiple && (
      <Expand
        in={openOrganizations}

        className={classes.expand}

        parent={{
          clientWidth: 240
        } as any}
      >
        <List
          paddingHorizontal='none'

          noBackground
        >
          {organizationsOther.map((item: any, index: number) => {
            const userOrganization = getUserOrganization(item);

            const notificationsUnread = (userOrganization?.projects as any[])?.reduce((result, item) => result += item.notifications_unread || 0, 0);

            return (
              <ListItem
                key={index}

                onClick={() => onSwitch({ organization: item })}

                start={(
                  <Badge
                    color='error'

                    indicator={!!notificationsUnread}
                  >
                    <Avatar
                      tonal

                      color='secondary'

                      size='small'

                      media={item?.settings?.images?.logo}
                    >
                      {item?.name?.slice(0, 1)}
                    </Avatar>
                  </Badge>
                )}

                primary={(
                  <Type
                    version='b2'
                  >
                    {item?.name}
                  </Type>
                )}

                shapePosition='both'

                button

                disabled={loading}

                {...ListItemOrganizationProps}
              />
            );
          })}
        </List>
      </Expand>
    )}

    <ListItem
      onClick={onAddOrganization}

      start={(
        <IconMaterialPersonAddRounded
          className={classes.addColor}

          {...IconProps}
        />
      )}

      primary={(
        <Type
          className={classes.addColor}
        >
          Add organization
        </Type>
      )}

      button

      className={classes.addColor}

      {...ListItemProps}
    />
  </>;

  const mediaUsage: any = {
    used: signedIn?.organizationPlan?.used?.media?.total || 0,
    provided: signedIn?.organizationPlan?.provided?.media?.total || 0
  };

  const menu = (
    <List
      className={classes.menu}
    >
      <ListItem
        color='primary'

        start={(
          <Avatar
            color='primary'

            media={getSettings(signedIn, 'settings.images.profile')}
          >
            {initials}
          </Avatar>
        )}

        primary={(
          <Type
            version='b2'
          >
            {getSettings(signedIn, 'name')}
          </Type>
        )}

        secondary={(
          <Type
            version='b3'

            priority='secondary'
          >
            {user?.email}
          </Type>
        )}

        className={classes.itemMain}
      />

      <Divider
        tonal={false}

        color='inverted'

        Component='li'

        className={classes.divider}
      />

      {organizationsUI}

      <Divider
        tonal={false}

        color='inverted'

        Component='li'

        className={classes.divider}
      />

      <ListItem
        start={(
          <IconMaterialCloudRounded
            tonal

            color='primary'

            size='small'
          />
        )}

        startAlign='center'

        primary={(
          <Type
            version='b3'
          >
            Storage used: {((mediaUsage.used / mediaUsage.provided) * 100).toFixed(1)}% of {to_(mediaUsage.provided, 'size-format') as any}
          </Type>
        )}

        style={{
          cursor: 'default',
          userSelect: 'none'
        }}

        {...ListItemProps}
      />

      <Divider
        tonal={false}

        color='inverted'

        Component='li'

        className={classes.divider}
      />

      {user?.is?.admin && <>
        <ListItem
          onClick={() => to('/organization/settings/subscription')}

          start={(
            <IconMaterialAccountBalanceWalletRounded
              {...IconProps}
            />
          )}

          primary='Manage subscription'

          button

          {...ListItemProps}
        />

        <ListItem
          onClick={() => to('/organization/settings')}

          start={(
            <IconMaterialSettingsRounded
              {...IconProps}
            />
          )}

          primary='Organization settings'

          button

          {...ListItemProps}
        />

        <Divider
          tonal={false}

          color='inverted'

          Component='li'

          className={classes.divider}
        />
      </>}

      <ListItem
        onClick={() => to('/settings')}

        start={(
          <IconMaterialSettingsRounded
            {...IconProps}
          />
        )}

        primary='Account settings'

        button

        {...ListItemProps}
      />

      <Divider
        tonal={false}

        color='inverted'

        Component='li'

        className={classes.divider}
      />

      <ListItem
        start={(
          <IconMaterialOutputRounded
            {...IconProps}
          />
        )}

        primary='Sign out'

        onClick={onSignOut}

        button

        {...ListItemProps}
      />
    </List>
  );

  return (
    <Menu
      open={open}

      onOpen={onOpen}

      onClose={onClose}

      name={menu}

      AppendProps={{
        offset: [0, 8],
        switch: false
      }}
    >
      <Avatar
        media={getSettings(signedIn, 'settings.images.profile')}

        className={classes.avatar}
      >
        {initials}
      </Avatar>
    </Menu>
  );
});

export default UserMenu;
