import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { capitalize, cleanValue, getCountry, getGoogleFontsURL, getObjectValue, imageToPalette, innerHTMLToText, is, merge, rgbToHex, slugify, stringToColor, stringify, textToInnerHTML } from '@amaui/utils';
import { AutoComplete, Checkbox, Chip, ColorTextField, Form, FormRow, IconButton, Label, Line, ListItem, MenuItem, ModalHeader, ModalMain, ModalTitle, PieChart, SmartTextField, Switch, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, TextField, Tooltip, Type, useConfirm, useForm, useSnackbars } from '@amaui/ui-react';
import { classNames, colors, style, useAmauiTheme } from '@amaui/style-react';
import { AmauiDate, format } from '@amaui/date';
import { Analytic, IMessage, Website as WebsiteModel } from '@amaui/api-utils';

import IconMaterialOpenInNewRounded from '@amaui/icons-material-rounded-react/IconMaterialOpenInNew';
import IconMaterialAddRounded from '@amaui/icons-material-rounded-react/IconMaterialAdd';
import IconMaterialArrowBackRounded from '@amaui/icons-material-rounded-react/IconMaterialArrowBack';
import IconMaterialRefreshRounded from '@amaui/icons-material-rounded-react/IconMaterialRefresh';
import IconMaterialRemoveRounded from '@amaui/icons-material-rounded-react/IconMaterialRemove';
import IconMaterialDeleteRounded from '@amaui/icons-material-rounded-react/IconMaterialDelete';

import { AnalyticValue, Analytics, Button, OptionsSEO, PageBuilder, Select, SelectAny, SelectMedia, Themes, useSubscription } from 'ui';
import { AnalyticService, AuthService, SocketService, WebsiteService } from 'services';
import { CONTACT_OPTIONS, FONTS, IQuerySubscription, ISelectedSubscription, ISignedIn, getErrorMessage, getMediaUrl, mediaToObject, menuItemProps } from 'other';
import { Menus } from '.';
import Pages from './Pages';
import Templates from './Templates';
import Chat from '../Chats/Chat';
import config from 'config';

const useStyle = style(theme => ({
  root: {

  },

  name: {
    cursor: 'pointer'
  },

  surface: {
    '&.amaui-Surface-root': {
      background: theme.palette.color.primary[theme.palette.light ? 99 : 5]
    }
  },

  modalMain: {

  },

  part: {
    padding: '24px 0 0'
  },

  chat: {
    minHeight: 340
  },

  ...theme.classes(theme)
}), { name: 'amaui-app-route-Website' });

const Website = React.forwardRef((props: any, ref: any) => {
  const {
    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const theme = useAmauiTheme();
  const navigate = useNavigate();
  const snackbars = useSnackbars();
  const confirm = useConfirm();

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const queryWebsitePages = useSubscription<IQuerySubscription>(WebsiteService.queryWebsitePages);
  const selectedWebsitePages = useSubscription<ISelectedSubscription>(WebsiteService.selectedWebsitePages);
  const queryWebsiteAnalytics = useSubscription<IQuerySubscription>(AnalyticService.queryWebsiteAnalytics);

  const [loading, setLoading] = React.useState<any>();
  const [website, setWebsite] = React.useState<any>();
  const [tab, setTab] = React.useState('pages');
  const [tabPreview, setTabPreview] = React.useState('Posts');
  const [showPreview, setShowPreview] = React.useState<any>();
  const [meta, setMeta] = React.useState<any>();
  const [analyticsPer, setAnalyticsPer] = React.useState<any>('');
  const [analyticsPage, setAnalyticsPage] = React.useState<any>();
  const [analyticsURL, setAnalyticsURL] = React.useState<any>();

  const form = useForm({
    values: {
      'name': {
        name: 'Name',
        required: true,
        max: 1400,
        messages: {
          min: 'Name has to be min 1 characters',
          max: 'Name can be max 1400 characters'
        }
      },
      'short_name': {
        name: 'Short name',
        required: true,
        is: 'string',
        isValid: 'domain-name'
      },
      'description': {
        name: 'Description',
        max: 4400,
        messages: {
          min: 'Description has to be min 1 characters',
          max: 'Description can be max 4400 characters'
        }
      },
      'contacts': {
        name: 'Contacts',
        is: 'array'
      },
      'domain': {
        name: 'Domain',
        is: 'string',
        isValid: 'url'
      },

      'theme': {
        name: 'Theme',
        is: 'object'
      },

      'active': {
        name: 'Active',
        is: 'boolean'
      },
      'archived': {
        name: 'Archived',
        is: 'boolean'
      },

      'menus': {
        name: 'Menus',
        is: 'array',
        of: 'object',
        value: []
      },

      'options.logos.logo_light': {
        name: 'Logo light',
        value: getObjectValue(website, 'options.logos.logo_light'),
        is: 'object'
      },
      'options.logos.logo_dark': {
        name: 'Logo dark',
        value: getObjectValue(website, 'options.logos.logo_dark'),
        is: 'object'
      },
      'options.logos.favicon_light': {
        name: 'Favicon light',
        value: getObjectValue(website, 'options.logos.favicon_light'),
        is: 'object'
      },
      'options.logos.favicon_dark': {
        name: 'Favicon dark',
        value: getObjectValue(website, 'options.logos.favicon_dark'),
        is: 'object'
      },

      'options.theme.ui.features': {
        name: 'Theme UI featuers',
        value: getObjectValue(website, 'options.theme.ui.features') || 'regular',
        is: 'string',
        in: ['simple', 'regular', 'complex']
      },

      'options.theme.typography.font_family.primary': {
        name: 'Theme font family primary',
        value: getObjectValue(website, 'options.theme.typography.font_family.primary'),
        is: 'string'
      },
      'options.theme.typography.font_family.secondary': {
        name: 'Theme font family secondary',
        value: getObjectValue(website, 'options.theme.typography.font_family.secondary'),
        is: 'string'
      },
      'options.theme.typography.font_family.tertiary': {
        name: 'Theme font family tertiary',
        value: getObjectValue(website, 'options.theme.typography.font_family.tertiary'),
        is: 'string'
      },

      'options.theme.palette.light': {
        name: 'Theme light',
        value: getObjectValue(website, 'options.theme.palette.light') || 'auto',
        is: 'string',
        in: ['light', 'dark', 'auto']
      },

      'options.theme.palette.image': {
        name: 'Theme image',
        value: getObjectValue(website, 'options.theme.palette.image'),
        is: 'object'
      },

      'options.theme.palette.color.primary.main': {
        name: 'Theme color primary',
        value: getObjectValue(website, 'options.theme.palette.color.primary.main'),
        is: 'string'
      },
      'options.theme.palette.color.secondary.main': {
        name: 'Theme color secondary',
        value: getObjectValue(website, 'options.theme.palette.color.secondary.main'),
        is: 'string'
      },
      'options.theme.palette.color.tertiary.main': {
        name: 'Theme color tertiary',
        value: getObjectValue(website, 'options.theme.palette.color.tertiary.main'),
        is: 'string'
      },
      'options.theme.palette.color.quaternary.main': {
        name: 'Theme color quaternary',
        value: getObjectValue(website, 'options.theme.palette.color.quaternary.main'),
        is: 'string'
      },

      'options.pages': {
        name: 'Pages',
        is: 'object'
      },

      'options.page_transition.active': {
        name: 'Theme page transition active',
        value: getObjectValue(website, 'options.page_transition.active'),
        is: 'boolean'
      },

      'options.objects': {
        name: 'Objects options',
        value: getObjectValue(website, 'options.objects') || {},
        is: 'object'
      },

      'options.apps': {
        name: 'Apps',
        is: 'object'
      },

      'seo.name': {
        name: 'SEO name',
        value: website?.seo?.name,
        min: 1,
        max: 70,
        messages: {
          min: 'SEO name has to be min 1 characters',
          max: 'SEO name can be max 70 characters'
        }
      },
      'seo.description': {
        name: 'SEO description',
        value: website?.seo?.description,
        min: 1,
        max: 164,
        messages: {
          min: 'SEO description has to be min 1 characters',
          max: 'SEO description can be max 164 characters'
        }
      },
      'seo.image': {
        name: 'SEO image',
        value: website?.seo?.image
      }
    }
  });

  const refs = {
    website: React.useRef<any>(),
    onAddPage: React.useRef<any>(),
    form: React.useRef<any>(),
    showPreviewTimeout: React.useRef<any>()
  };

  refs.website.current = website;

  refs.form.current = form;

  const dates = React.useMemo(() => {
    const today = AmauiDate.utc;
    const myToday = new AmauiDate();

    return [
      { name: 'Total', value: '' },
      { name: format(myToday, `'Hour' HH'h'`), value: format(today, 'YYYY-MM-DD HH') },
      { name: 'Today', value: format(today, 'YYYY-MM-DD') },
      { name: 'Month', nameMore: format(today, 'MMM, YYYY'), value: format(today, 'YYYY-MM') },
      { name: 'Year', nameMore: format(today, 'YYYY'), value: format(today, 'YYYY') }
    ];
  }, []);

  const getAnalytics = React.useCallback(async () => {
    if (website) {
      setLoading('analytics');

      let addition: any = '';

      if (analyticsPage) addition = `-page-${analyticsPage}`;

      if (analyticsURL) addition = `-url-/${analyticsURL}`;

      const result = await AnalyticService.queryWebsiteAnalytics.value!.query({
        query: {
          query: {
            user: null,
            version: [`websites-${website?.id}${addition}-visit`, `websites-${website?.id}${addition}-visit-location`],
            date: dates.map(item => item.value)
          }
        }
      });

      if (result.status >= 400) {
        snackbars.add({
          color: 'error',
          primary: getErrorMessage(result)
        });
      }

      setLoading(false);
    }
  }, [website, dates, analyticsPage, analyticsURL]);

  React.useEffect(() => {
    getAnalytics();
  }, [website, analyticsPage, analyticsURL]);

  const init = React.useCallback(async () => {
    const path = window.location.pathname.split('/pages').filter(Boolean)[0];

    const id = path.split('/').filter(Boolean).slice(-1)[0]?.replaceAll('/', '')

    const result = await WebsiteService.get(id);

    let website_: any;

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });

      navigate('/websites');

      return;
    }
    else {
      website_ = result.response.response;

      setWebsite(website_);

      initPages(website_);

      initForm(website_);
    }

    const responseMeta = await WebsiteService.meta(id);

    if (responseMeta.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(responseMeta)
      });
    }
    else {
      const meta_ = responseMeta.response.response;

      setMeta(meta_);
    }
  }, [form]);

  const initPages = React.useCallback(async (object = refs.website.current) => {
    setLoading(true);

    const result = await WebsiteService.queryWebsitePages.value!.query({ id: object.id })

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else { }

    setLoading(false);
  }, [website]);

  const initForm = React.useCallback((object = website) => {
    form.onChange([
      ['name', object?.name],
      ['short_name', object?.short_name],
      ['description', object?.description],

      ['contacts', object?.contacts],

      ['theme', object?.theme],

      ['active', object?.active],
      ['archived', object?.archived],

      ['menus', object?.menus],

      ['options.pages', object?.options?.pages || {}],

      ['options.logos.logo_light', getObjectValue(object, 'options.logos.logo_light')],
      ['options.logos.logo_dark', getObjectValue(object, 'options.logos.logo_dark')],
      ['options.logos.favicon_light', getObjectValue(object, 'options.logos.favicon_light')],
      ['options.logos.favicon_dark', getObjectValue(object, 'options.logos.favicon_dark')],

      ['options.theme.ui.features', getObjectValue(object, 'options.theme.ui.features')],

      ['options.theme.typography.font_family.primary', getObjectValue(object, 'options.theme.typography.font_family.primary')],
      ['options.theme.typography.font_family.secondary', getObjectValue(object, 'options.theme.typography.font_family.secondary')],
      ['options.theme.typography.font_family.tertiary', getObjectValue(object, 'options.theme.typography.font_family.tertiary')],

      ['options.theme.palette.light', getObjectValue(object, 'options.theme.palette.light') || 'light'],

      ['options.theme.palette.image', getObjectValue(object, 'options.theme.palette.image')],

      ['options.theme.palette.color.primary.main', getObjectValue(object, 'options.theme.palette.color.primary.main') || colors.yellow[500]],
      ['options.theme.palette.color.secondary.main', getObjectValue(object, 'options.theme.palette.color.secondary.main') || colors.lightgreen[500]],
      ['options.theme.palette.color.tertiary.main', getObjectValue(object, 'options.theme.palette.color.tertiary.main') || colors.amber[500]],
      ['options.theme.palette.color.quaternary.main', getObjectValue(object, 'options.theme.palette.color.quaternary.main') || colors.cyan[500]],

      ['options.objects', getObjectValue(object, 'options.objects') || {}],

      ['options.apps', getObjectValue(object, 'options.apps') || {}],

      ['seo.name', getObjectValue(object, 'seo.name')],
      ['seo.description', getObjectValue(object, 'seo.description')],
      ['seo.image', getObjectValue(object, 'seo.image')]
    ]);
  }, [form]);

  const cleanUp = React.useCallback(() => {
    WebsiteService.selectedWebsitePages.value!.reset();
    AnalyticService.selectedWebsiteAnalytics.value!.reset();
  }, []);

  React.useEffect(() => {
    const isSomeSelected = queryWebsitePages.response?.some((item: any) => selectedWebsitePages.isSelected(item));

    if (isSomeSelected) selectedWebsitePages.updateMany(queryWebsitePages.response);
  }, [stringify(queryWebsitePages.response)]);

  React.useEffect(() => {
    // init
    init();

    return () => {
      cleanUp();
    };
  }, []);

  const onNext = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();

    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const body: Partial<WebsiteModel> = {
      ...refs.form.current.value
    };

    let overrideOptions = false;

    if (website?.theme?.id !== body?.theme?.id) {
      overrideOptions = await confirm.open({
        name: 'Override options using theme options',
        description: `You updated the website theme, do you also want to import color palette, typeography from the theme?`
      });
    }

    if (body.options) {
      if (body.options.logos?.logo_light) body.options.logos.logo_light = mediaToObject(body.options.logos?.logo_light);

      if (body.options.logos?.logo_dark) body.options.logos.logo_dark = mediaToObject(body.options.logos?.logo_dark);

      if (body.options.logos?.favicon_light) body.options.logos.favicon_light = mediaToObject(body.options.logos?.favicon_light);

      if (body.options.logos?.favicon_dark) body.options.logos.favicon_dark = mediaToObject(body.options.logos?.favicon_dark);
    }

    if (body.theme) {
      if (overrideOptions) body.options = merge(body.options, body.theme.options || {});

      body.theme = {
        id: body.theme.id,
        name: body.theme.name
      };
    }

    if (body.menus) body.menus = body.menus.map((item: any) => {
      delete item.order;

      return item;
    });

    const result = await WebsiteService.update(website?.id, body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Website updated`
      });

      setWebsite(result.response.response);

      // Emit website messages
      await SocketService.updateWebsiteChat({
        short_name: refs.website.current?.short_name,

        options: {
          apps: {
            chat: {
              hover_text: body.options?.apps?.chat?.hover_text,
              messages: body.options?.apps?.chat?.messages
            }
          }
        }
      });
    }

    setLoading(false);
  }, [website, form]);

  const onRefreshOptions = React.useCallback(async (override = true) => {
    await refs.form.current.validate();

    if (form.values.name?.value) {
      if (
        override ||
        !form.values.short_name?.value
      ) {
        form.onChange('short_name', slugify(form.values.name?.value));
      }
    }
  }, [form]);

  const onChangeThemes = React.useCallback((valueNew: any) => {
    refs.form.current.onChange('theme', valueNew);
  }, []);

  const onChangeTab = React.useCallback((value: any) => {
    clearTimeout(refs.showPreviewTimeout.current);

    setShowPreview(false);

    setTab(value);

    refs.showPreviewTimeout.current = setTimeout(() => {
      setShowPreview(true);
    }, 14);
  }, []);

  const onChangeTabPreview = React.useCallback((value: any) => {
    clearTimeout(refs.showPreviewTimeout.current);

    setShowPreview(false);

    setTabPreview(cleanValue(value, { capitalize: true }));

    refs.showPreviewTimeout.current = setTimeout(() => {
      setShowPreview(true);
    }, 14);
  }, []);

  const onOptionsThemeImageConfirm = React.useCallback(async (valueNew: any) => {
    const value = valueNew;

    const palette = await imageToPalette(getMediaUrl(value, 480), { amount: 4, size: 140, allowCrossOrigin: true });

    form.onChange([
      ['options.theme.palette.image', value],
      ['options.theme.palette.color.primary.main', rgbToHex(palette[0])],
      ['options.theme.palette.color.secondary.main', rgbToHex(palette[1])],
      ['options.theme.palette.color.tertiary.main', rgbToHex(palette[2])],
      ['options.theme.palette.color.quaternary.main', rgbToHex(palette[3])]
    ]);
  }, [form]);

  const tabsPreview: any = [];

  const objects = ['posts', 'skills', 'portfolios', 'resumes', { name: 'support', value: 'support-pages', version: 'SupportPages' }, 'chat'];

  objects
    .filter((item: any) => form.values['options.pages'].value?.[item?.value || item?.name || item] && !['chat'].includes(item?.name || item))
    .forEach((item: any) => tabsPreview.push({ label: cleanValue(item?.name || item, { capitalize: true }), value: item?.version || cleanValue(item?.value || item, { capitalize: true }) }));

  const tabs = [
    { label: 'Pages', value: 'pages' },
    { label: 'Menus', value: 'menus' },
    { label: 'Options', value: 'options' },
    { label: 'Theme', value: 'theme' },
    { label: 'SEO', value: 'SEO' },
    { label: 'Apps', value: 'apps' },
    { label: 'Templates', value: 'templates' },
    { label: 'Analytics', value: 'analytics' },
    { label: 'Preview', value: 'preview', props: { disabled: !tabsPreview.length } }
  ];

  const elementPreview = (version: any) => {
    if (!showPreview) return null;

    let url = '';

    if (version === 'Posts') url = '/posts/all';
    else if (version === 'Skills') url = '/skills/all';
    else if (version === 'Portfolios') url = '/portfolios/all';
    else if (version === 'Resumes') url = '/resumes/all';
    else if (version === 'SupportPages') url = '/support-pages/all';

    return (
      <PageBuilder
        mode='preview'

        url={url}

        website={website}
      />
    );
  };

  const themeLightOptions = React.useMemo(() => {
    return [
      { label: 'Light', value: 'light' },
      { label: 'Dark', value: 'dark' },
      { label: 'Auto', value: 'auto' }
    ];
  }, []);

  const fontFamilyOptions = Object.keys(FONTS).flatMap((key: string) => (FONTS as any)[key]).map((item: any) => ({ ...item, value: item.label }));

  const allFontFamilies = Object.keys(FONTS).flatMap(item => (FONTS as any)[item]);

  const getValueFontFamily = (valueFontFamily: string) => allFontFamilies.find((item: any) => item.label.includes(valueFontFamily));

  const typographyFontFamilies = [
    { name: 'primary', description: 'Used for headings, titles, labels, more important text' },
    { name: 'secondary', description: 'Used for paragraphs, less important text' },
    { name: 'tertiary', description: 'Used for ie. code examples' }
  ];

  const colorOptions = [
    { name: 'primary' },
    { name: 'secondary' },
    { name: 'tertiary' },
    { name: 'quaternary' }
  ];

  const logosSelectMediaProps: any = {
    style: {
      backgroundSize: 'auto 77%',
      backgroundColor: theme.palette.background.default.primary
    }
  };

  const onChangeAnalyticsPer = React.useCallback((value: any) => {
    setAnalyticsPer(value);
  }, []);

  const onChangeAnalyticsWebsite = React.useCallback((value: any) => {
    setLoading('analytics');

    setAnalyticsPage('');

    setAnalyticsURL('');
  }, []);

  const onChangeAnalyticsPage = React.useCallback((value: any) => {
    setLoading('analytics');

    setAnalyticsPage(value);

    setAnalyticsURL('');
  }, []);

  const onChangeAnalyticsURL = React.useCallback((value: any) => {
    setLoading('analytics');

    setAnalyticsURL(value);

    setAnalyticsPage('');
  }, []);

  const getAnalytic = (version: string, date: string): any => {
    return (queryWebsiteAnalytics?.response || []).find((item: Analytic) => item.version === version && item.date === date);
  };

  const getPieChart = () => {
    let addition: any = '';

    if (analyticsPage) addition = `-page-${analyticsPage}`;

    if (analyticsURL) addition = `-url-/${analyticsURL}`;

    const analytic = getAnalytic(`websites-${website?.id}${addition}-visit-location`, analyticsPer);

    if (analytic) {
      const countries = Object.keys(analytic.value);

      return (
        <PieChart
          title='Clicks per country'

          subtitle='In %'

          values={countries.map(item => ({
            color: stringToColor(getCountry(item)?.name) as any,
            name: getCountry(item)?.name,
            values: [analytic.value[item]]
          }))}

          {...(countries.length === 1) && {
            PathProps: {
              style: {
                strokeWidth: 0
              }
            }
          }}

          className={classNames([
            classes.chart,
            classes.pieChart,
            countries.length === 1 && classes.pieChart100
          ])}
        />
      );
    }

    return null;
  };

  const getCountryTable = () => {
    let addition: any = '';

    if (analyticsPage) addition = `-page-${analyticsPage}`;

    if (analyticsURL) addition = `-url-/${analyticsURL}`;

    const analytic = getAnalytic(`websites-${website?.id}${addition}-visit-location`, analyticsPer);

    if (analytic) {
      const countries = Object.keys(analytic.value);

      return (
        <Table
          size='small'
        >
          <TableHead>
            <TableRow>
              {['Name', 'Visits'].map((item: string, index: number) => (
                <TableCell
                  key={index}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {countries.map((item: any, index: number) => (
              <TableRow
                key={index}
              >
                <TableCell
                  key={index}
                >
                  {getCountry(item)?.name}
                </TableCell>

                <TableCell
                  key={index}
                >
                  {analytic.value[item]}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      );
    }

    return null;
  };

  const onAddContact = React.useCallback((event: MouseEvent) => {
    event.stopPropagation();

    const contacts = [...form.values.contacts.value || []];

    contacts.push({ version: 'Website', value: '' });

    form.onChange('contacts', contacts);
  }, [form]);

  const onRemoveContact = React.useCallback((index: number) => {
    const contacts = [...form.values.contacts.value || []];

    contacts.splice(index, 1);

    form.onChange('contacts', contacts);
  }, [form]);

  const selectProps: any = {
    autoWidth: false,

    MenuProps: {
      portal: true
    }
  };

  const onMessageAdd = React.useCallback(async (type = 'initial', version = 'text', valueNew: any = undefined) => {
    if (valueNew) {
      const messages = form.values['options.apps'].value?.chat?.messages?.[type] || [];

      messages.push(is('string', valueNew) ? [{
        version: 'type',
        value: innerHTMLToText(valueNew)
      }] : valueNew);

      form.onChange('options.apps', messages, `chat.messages.${type}`);

      return true;
    }
  }, [form]);

  const onRemoveMessage = React.useCallback(async (type = 'initial', index: number) => {
    const messages = form.values['options.apps'].value?.chat?.messages?.[type] || [];

    if (index > -1) messages.splice(index, 1);

    form.onChange('options.apps', messages, `chat.messages.${type}`);
  }, []);

  const getMenuItemsMessage = (type = 'initial', index: number) => {
    const IconProps: any = {
      color: 'inherit',
      size: 'small'
    };

    const values: any = [
      { primary: 'Remove', onClick: () => onRemoveMessage(type, index), start: <IconMaterialDeleteRounded {...IconProps} color='error' /> }
    ];

    return values.map((item_: any, index: number) => (
      <MenuItem
        key={index}

        {...menuItemProps(item_)}
      />
    ));
  };

  let additionAnalytic: any = '';

  if (analyticsPage) additionAnalytic = `-page-${analyticsPage}`;

  if (analyticsURL) additionAnalytic = `-url-/${analyticsURL}`;

  const analyticSelected = getAnalytic(`websites-${website?.id}${additionAnalytic}-visit-location`, analyticsPer);

  const objectsOptions = ['page', 'posts', 'post', 'resumes', 'resume', 'portfolios', 'portfolio', 'skills', 'skill', 'support', 'forms', 'form'];

  const chatAllowed = ['media', 'audio_recording', 'emojis', 'reactions', 'support', 'about', 'image', 'audio', 'video', 'pdf'];

  const buttonProps: any = {
    size: 'small'
  };

  const modal: any = {
    'pages': <>
      <Line
        gap={2}

        fullWidth

        className={classes.part}
      >
        <Pages
          website={website}
        />
      </Line>
    </>,

    'menus': (
      <Line
        gap={2}

        fullWidth

        className={classes.part}
      >
        <Menus
          website={website}

          form={form}

          onNext={onNext}

          loading={loading}
        />
      </Line>
    ),

    'SEO': <>
      <Line
        gap={2}

        fullWidth

        className={classes.part}
      >
        <OptionsSEO
          app='blog'

          values={form.values}

          onChange={form.onChange}
        />
      </Line>
    </>,

    'options': (
      <Line
        gap={2}

        fullWidth

        className={classes.part}
      >
        <Form
          gap={4}

          onSubmit={onNext}

          footer={(
            <Button
              loading={loading}

              type='submit'

              {...buttonProps}
            >
              Update
            </Button>
          )}
        >
          <FormRow
            end={(
              <Tooltip
                name='Refresh'
              >
                <IconButton
                  onClick={onRefreshOptions}
                >
                  <IconMaterialRefreshRounded />
                </IconButton>
              </Tooltip>
            )}

            fullWidth
          >
            <TextField
              name='Name'

              valueDefault={textToInnerHTML(form.values['name'].value)}

              onChange={(valueNew: any) => form.onChange('name', valueNew, undefined, { rerenderOnUpdate: false })}
            />

            <SmartTextField
              placeholder='Description'

              valueDefault={textToInnerHTML(form.values['description'].value || '')}

              onChange={(valueNew: any) => form.onChange('description', valueNew, undefined, { rerenderOnUpdate: false })}

              minRows={4}

              multiline

              edit
            />

            <FormRow
              name='Short name'

              description={(
                <Type
                  version='b3'
                >
                  This is the subdomain your website will be at, it has to be unique, ie. https://<b>i-love-trees</b>.amaui.me
                </Type>
              )}
            >
              <TextField
                placeholder='i-love-trees'

                value={form.values.short_name?.value || ''}

                onChange={(valueNew: string) => form.onChange('short_name', valueNew, undefined, { rerenderOnUpdate: false })}

                error={!!form.values.short_name.error}

                helperText={form.values.short_name.error}

                fullWidth
              />
            </FormRow>

            <Form
              name='Contacts'

              HeaderProps={{
                align: 'center'
              }}

              end={(
                <Tooltip
                  name='Add contact'
                >
                  <IconButton
                    onClick={onAddContact}
                  >
                    <IconMaterialAddRounded />
                  </IconButton>
                </Tooltip>
              )}

              size='small'

              accordion

              wrapper
            >
              {(form.values.contacts.value || []).map((item: any, index: number) => (
                <FormRow
                  key={index}

                  row

                  end={(
                    <Tooltip
                      name='Remove contact'
                    >
                      <IconButton
                        onClick={() => onRemoveContact(index)}

                        size='small'
                      >
                        <IconMaterialRemoveRounded />
                      </IconButton>
                    </Tooltip>
                  )}
                >
                  <Select
                    name='Type'

                    value={form.values.contacts.value[index].version}

                    onChange={(valueNew: any) => form.onChange('contacts', valueNew, `${index}.version`)}
                  >
                    {CONTACT_OPTIONS.map((itemContact: any, index_: number) => (
                      <ListItem
                        key={index_}

                        primary={(
                          <Type
                            version='b3'
                          >
                            {itemContact}
                          </Type>
                        )}

                        value={itemContact}

                        size='small'

                        button
                      />
                    ))}
                  </Select>

                  <TextField
                    name='Name'

                    valueDefault={form.values.contacts.value[index].name}

                    onChange={(valueNew: any) => form.onChange('contacts', valueNew, `${index}.name`, { rerenderOnUpdate: false })}
                  />

                  <TextField
                    name='Value'

                    valueDefault={form.values.contacts.value[index].value}

                    onChange={(valueNew: any) => form.onChange('contacts', valueNew, `${index}.value`, { rerenderOnUpdate: false })}
                  />
                </FormRow>
              ))}
            </Form>

            <FormRow
              name='Domain'

              description={(
                <Type
                  version='b3'
                >
                  Domain you want to connect to the website. <br /><br />

                  Whereever you bought your domain, go the your domain provider, and create a new CNAME (Alias) records that point to your website's subdomain, ie. CNAME www -&gt; <b>i-love-trees</b>.amaui.me
                </Type>
              )}
            >
              <TextField
                placeholder='mywebsite.com'

                valueDefault={form.values.domain?.value || ''}

                onChange={(valueNew: string) => form.onChange('domain', valueNew, undefined, { rerenderOnUpdate: false })}

                error={!!form.values.domain.error}

                helperText={form.values.domain.error}

                fullWidth
              />
            </FormRow>
          </FormRow>

          <FormRow
            fullWidth
          >
            <Label
              checked={form.values.active?.value}

              onChange={(valueNew: boolean) => form.onChange('active', valueNew)}

              error={!!form.values.active.error}

              helperText={form.values.active.error}
            >
              <Switch />

              Active
            </Label>

            <Label
              checked={form.values.archived?.value}

              onChange={(valueNew: boolean) => form.onChange('archived', valueNew)}

              error={!!form.values.archived.error}

              helperText={form.values.archived.error}
            >
              <Switch />

              Archived
            </Label>
          </FormRow>

          <FormRow
            name={(
              <Type
                version='l1'
              >
                Options
              </Type>
            )}

            fullWidth
          >
            <FormRow
              name='Pages'

              description='Pages / features you want to allow to be viewed on your website'
            >
              <Select
                value={objects.filter((item: any) => form.values['options.pages'].value?.[item?.value || item]).map((item: any) => item?.value || item) as any}

                onChange={(valueNew: string[]) => {
                  const pages: any = {};

                  valueNew.forEach(item => pages[item] = true);

                  form.onChange('options.pages', pages);
                }}

                multiple

                fullWidth

                className={classes.select}
              >
                {objects.map((item: any, index: number) => (
                  <ListItem
                    key={index}

                    primary={capitalize(item?.name || item)}

                    start={(
                      <Checkbox
                        checked={!!form.values['options.pages'].value?.[item?.value || item]}

                        size='small'
                      />
                    )}

                    value={item?.value || item}

                    size='small'

                    button
                  />
                ))}
              </Select>
            </FormRow>

            <Form
              name='Logos'

              description='Images used for the logo in light, dark mode, etc.'

              size='small'

              accordion

              wrapper
            >
              <FormRow
                name={(
                  <Type
                    version='l3'
                  >
                    Logo light
                  </Type>
                )}

                description='Used with the light theme'
              >
                <SelectMedia
                  name=''

                  value={form.values['options.logos.logo_light'].value}

                  mediaSelected={form.values['options.logos.logo_light'].value}

                  onChange={(valueNew: any) => form.onChange('options.logos.logo_light', valueNew)}

                  {...logosSelectMediaProps}
                />
              </FormRow>

              <FormRow
                name={(
                  <Type
                    version='l3'
                  >
                    Logo dark
                  </Type>
                )}

                description='Used with the dark theme'
              >
                <SelectMedia
                  name=''

                  value={form.values['options.logos.logo_dark'].value}

                  mediaSelected={form.values['options.logos.logo_dark'].value}

                  onChange={(valueNew: any) => form.onChange('options.logos.logo_dark', valueNew)}

                  {...logosSelectMediaProps}
                />
              </FormRow>

              <FormRow
                name={(
                  <Type
                    version='l3'
                  >
                    Favicon light
                  </Type>
                )}

                description='Used in the browser tab with the light theme'
              >
                <SelectMedia
                  name=''

                  value={form.values['options.logos.favicon_light'].value}

                  mediaSelected={form.values['options.logos.favicon_light'].value}

                  onChange={(valueNew: any) => form.onChange('options.logos.favicon_light', valueNew)}

                  {...logosSelectMediaProps}
                />
              </FormRow>

              <FormRow
                name={(
                  <Type
                    version='l3'
                  >
                    Favicon dark
                  </Type>
                )}

                description='Used in the browser tab with the dark theme'
              >
                <SelectMedia
                  name=''

                  value={form.values['options.logos.favicon_dark'].value}

                  mediaSelected={form.values['options.logos.favicon_dark'].value}

                  onChange={(valueNew: any) => form.onChange('options.logos.favicon_dark', valueNew)}

                  {...logosSelectMediaProps}
                />
              </FormRow>
            </Form>

            <Form
              name='Theme'

              description='Theme options. Palette, typography, etc.'

              size='small'

              accordion

              wrapper
            >
              <FormRow
                name='Palette'
              >
                <Select
                  name='Light'

                  version='outlined'

                  valueDefault={getObjectValue(website, 'options.theme.palette.light') || 'auto'}

                  value={form.values['options.theme.palette.light'].value || ''}

                  error={!!form.values['options.theme.palette.light'].error}

                  helperText={form.values['options.theme.palette.light'].error}

                  onChange={(valueNew: string) => form.onChange('options.theme.palette.light', valueNew)}
                >
                  {themeLightOptions.map((item: any, index: number) => (
                    <ListItem
                      key={index}

                      primary={item.label}

                      value={item.value}

                      button
                    />
                  ))}
                </Select>

                <FormRow
                  description='Make the palette from an image'
                >
                  <SelectMedia
                    value={form.values[`options.theme.palette.image`].value}

                    onChange={onOptionsThemeImageConfirm}
                  />
                </FormRow>

                {colorOptions.map((item: any, index: number) => (
                  <ColorTextField
                    key={index}

                    name={`${capitalize(item.name)} color`}

                    value={form.values[`options.theme.palette.color.${item.name}.main`].value || ''}

                    onChange={(valueNew: any) => form.onChange(`options.theme.palette.color.${item.name}.main`, valueNew)}
                  />
                ))}
              </FormRow>

              <FormRow
                name='Typography'
              >
                {typographyFontFamilies.map((item: any, index: number) => (
                  <FormRow
                    key={index}

                    name={(
                      <Type
                        version='l3'
                      >
                        {capitalize(item.name)} font
                      </Type>
                    )}

                    description={item.description}
                  >
                    <AutoComplete
                      value={getValueFontFamily(form.values[`options.theme.typography.font_family.${item.name}`].value)}

                      onChange={(valueNew: any) => form.onChange(`options.theme.typography.font_family.${item.name}`, valueNew?.label)}

                      valueInputDefault={form.values[`options.theme.typography.font_family.${item.name}`].value || ''}

                      options={fontFamilyOptions}

                      groupBy={(item: any) => item?.family}

                      noOptions={false}

                      renderOption={(item: any, index: number, props: any) => {

                        return (
                          <ListItem
                            key={index}

                            {...props}

                            primary={(
                              <Type
                                style={{
                                  fontFamily: `${item.label}, ${item.family.replace(' ', '-')}`
                                }}
                              >
                                {item.label}
                              </Type>
                            )}

                            value={item.label}

                            button
                          />
                        );
                      }}

                      InputProps={{
                        style: {
                          fontFamily: form.values[`options.theme.typography.font_family.${item.name}`].value
                        }
                      }}

                      style={{
                        fontFamily: form.values[`options.theme.typography.font_family.${item.name}`].value
                      }}
                    />
                  </FormRow>
                ))}
              </FormRow>

              <FormRow
                name='UI features'

                description='If you want to have simpler UI with less extra decorative details or regular that comes with the theme.'
              >
                <SelectAny
                  name='UI features'

                  value={form.values[`options.theme.ui.features`].value || 'regular'}

                  options={['simple', 'regular']}

                  onChange={(valueNew: any) => form.onChange(`options.theme.ui.features`, valueNew)}
                />
              </FormRow>
            </Form>

            <Form
              name='Page transition'

              description='Transitions between page navigation.'

              size='small'

              accordion

              wrapper
            >
              <Label
                checked={form.values['options.page_transition.active']?.value !== undefined ? form.values['options.page_transition.active']?.value : true}

                onChange={(valueNew: boolean) => form.onChange('options.page_transition.active', valueNew)}

                error={!!form.values['options.page_transition.active'].error}

                helperText={form.values['options.page_transition.active'].error}
              >
                <Switch />

                Active
              </Label>
            </Form>

            <Form
              name='Objects'

              description='Options for pages, posts, portfolios, resumes, etc.'

              size='small'

              accordion

              wrapper
            >
              {objectsOptions.map((item: any, index: number) => (
                <Form
                  key={index}

                  name={capitalize(item)}

                  size='small'

                  accordion

                  wrapper
                >
                  <FormRow
                    ref={ref}

                    name='Share'

                    description={`${capitalize(item)} social media share options`}
                  >
                    <Label
                      checked={form.value?.options?.objects?.[item]?.share?.active !== undefined ? form.value?.options?.objects?.[item]?.share?.active : false}

                      onChange={(valueNew: any) => form.onChange('options.objects', valueNew, `${item}.share.active`)}
                    >
                      <Switch />

                      Active
                    </Label>
                  </FormRow>
                </Form>
              ))}
            </Form>
          </FormRow>
        </Form>
      </Line>
    ),

    'theme': (
      <FormRow
        gap={2}

        fullWidth

        footer={(
          <Button
            onClick={onNext}

            loading={loading}

            {...buttonProps}
          >
            Next
          </Button>
        )}

        className={classes.page}
      >
        <Themes
          selected={form.value.theme}

          onChange={onChangeThemes}
        />
      </FormRow>
    ),

    'apps': (
      <Line
        gap={2}

        fullWidth

        className={classes.part}
      >
        <Form
          gap={4}

          onSubmit={onNext}

          footer={(
            <Button
              loading={loading}

              type='submit'

              {...buttonProps}
            >
              Update
            </Button>
          )}
        >
          <Form
            openDefault

            name='Chat'

            description='Chat app options for this website'

            accordion

            wrapper
          >
            <FormRow
              name='Allowed features'

              description='What features you allow users to use in the website chat ie. upload media, only images, use reactions, etc.'
            >
              <Select
                name='Allowed'

                value={chatAllowed.filter((item: any) => form.values['options.apps'].value?.chat?.allowed?.[item]) as any}

                onChange={(valueNew: string[]) => {
                  const allowed: any = {};

                  valueNew.forEach(item => allowed[item] = true);

                  form.onChange('options.apps', allowed, 'chat.allowed');
                }}

                multiple

                fullWidth

                className={classes.select}
              >
                {chatAllowed.map((item: any, index: number) => (
                  <ListItem
                    key={index}

                    primary={cleanValue(item, { capitalize: true })}

                    start={(
                      <Checkbox
                        checked={!!form.values['options.apps'].value?.chat?.allowed?.[item]}

                        size='small'
                      />
                    )}

                    value={item}

                    size='small'

                    button
                  />
                ))}
              </Select>
            </FormRow>

            <FormRow
              name='Icon hover text'

              description='Text displayed when user hovers over the icon that opens the chat'
            >
              <TextField
                name='Text'

                valueDefault={form.values['options.apps'].value?.chat?.hover_text || ''}

                onChange={(valueNew: string) => form.onChange('options.apps', valueNew, 'chat.hover_text', { rerenderOnUpdate: false })}
              />
            </FormRow>

            <Form
              name='Messages'

              size='small'

              accordion

              wrapper
            >
              <FormRow
                name='Initial'

                description='Messages displayed the first messages in every chat. Usually used for introduction, greeting purpose.'
              >
                <Chat
                  onMessageAdd={(version: any, valueNew: any) => onMessageAdd('initial', version, valueNew)}

                  messages={(form.values['options.apps'].value?.chat?.messages?.initial || []).map((item: any) => ({
                    user: signedIn.user,
                    version: item?.[0]?.version === 'type' ? 'text' : 'media',
                    value: item
                  }) as Partial<IMessage>)}

                  MessagesProps={{
                    MessageProps: {
                      getMenuItemsMessage: (valueNew: any, index: number) => getMenuItemsMessage('initial', index)
                    }
                  }}

                  noReactions

                  noReplyTo

                  className={classes.chat}
                />
              </FormRow>

              <FormRow
                name='Pinned'

                description='Messages displayed above the icon that opens the chat. Usually used for important announcements'
              >
                <Chat
                  onMessageAdd={(version: any, valueNew: any) => onMessageAdd('pinned', version, valueNew)}

                  messages={(form.values['options.apps'].value?.chat?.messages?.pinned || []).map((item: any) => ({
                    user: signedIn.user,
                    version: item?.[0]?.version === 'type' ? 'text' : 'media',
                    value: item
                  }) as Partial<IMessage>)}

                  MessagesProps={{
                    MessageProps: {
                      getMenuItemsMessage: (valueNew: any, index: number) => getMenuItemsMessage('pinned', index)
                    }
                  }}

                  noReactions

                  noReplyTo

                  className={classes.chat}
                />
              </FormRow>
            </Form>
          </Form>
        </Form>
      </Line>
    ),

    'templates': (
      <Line
        gap={2}

        fullWidth

        className={classes.part}
      >
        <Templates
          version='website'

          website={website}

          page={false}
        />
      </Line>
    ),

    'analytics': <>
      <Line
        gap={2}

        fullWidth

        className={classes.part}
      >
        <FormRow
          gap={1}

          name='Visits'

          fullWidth
        >
          <Analytics
            align='flex-end'
          >
            {dates.map((item, index: number) => (
              <AnalyticValue
                key={index}

                name={`${item.name} ${item.nameMore || ''}`.trim()}

                value={getAnalytic(`websites-${website?.id}${additionAnalytic}-visit`, item.value)?.value || 0}
              />
            ))}
          </Analytics>
        </FormRow>

        <FormRow
          gap={1}

          name='Analytics'

          fullWidth

          style={{
            overflowX: 'hidden'
          }}
        >
          <Line
            gap={1}

            direction='row'

            align='center'

            justify='flex-end'

            fullWidth
          >
            {!!meta?.pages?.length && <>
              <Chip
                onClick={onChangeAnalyticsWebsite}

                selected={!analyticsPage && !analyticsURL}

                disabled={!analyticsPage && !analyticsURL}

                size='small'

                style={{
                  marginInlineEnd: 4
                }}
              >
                Website
              </Chip>

              <Select
                name='Page'

                value={analyticsPage || 'Select'}

                onChange={onChangeAnalyticsPage}

                {...selectProps}
              >
                {meta.pages.map((item: any, index: number) => (
                  <ListItem
                    key={index}

                    primary={(
                      <Type
                        version='b3'
                      >
                        {item.name}
                      </Type>
                    )}

                    value={item.id}

                    button
                  />
                ))}
              </Select>

              <Select
                name='URL'

                value={analyticsURL || 'Select'}

                onChange={onChangeAnalyticsURL}

                {...selectProps}
              >
                {meta.pages.map((item: any, index: number) => (
                  <ListItem
                    key={index}

                    primary={(
                      <Type
                        version='b3'
                      >
                        /{item.url}
                      </Type>
                    )}

                    value={item.url}

                    button
                  />
                ))}
              </Select>
            </>}

            <Select
              name='Date'

              valueDefault={''}

              value={analyticsPer}

              onChange={onChangeAnalyticsPer}

              {...selectProps}
            >
              {dates.map((item, index: number) => (
                <ListItem
                  key={index}

                  primary={(
                    <Type
                      version='b3'
                    >
                      {`${item.name} ${item.nameMore || ''}`.trim()}
                    </Type>
                  )}

                  value={item.value}

                  button
                />
              ))}
            </Select>
          </Line>

          <Analytics
            gap={2}

            align='center'

            direction='column'

            style={{
              padding: '16px 8px 8px'
            }}
          >
            {!analyticSelected && !loading && (
              <Line
                align='center'

                justify='center'

                style={{
                  height: 140
                }}
              >
                <Type
                  align='center'
                >
                  No visits recorded at the moment
                </Type>
              </Line>
            )}

            {getPieChart()}

            {getCountryTable()}
          </Analytics>
        </FormRow>
      </Line>
    </>,

    'preview': (
      <Line
        gap={0}

        flex

        fullWidth
      >
        <Tabs
          value={tabPreview}

          onChange={onChangeTabPreview}

          justify='center'
        >
          {tabsPreview.map((item: any, index: number) => (
            <Tab
              key={index}

              name={item.label}

              value={item.value}
            />
          ))}
        </Tabs>

        {elementPreview(cleanValue(tabPreview, { capitalize: true }))}
      </Line>
    )
  };

  const googleFonts: any = [];

  Object.keys(FONTS).forEach((item: string) => {
    (FONTS as any)[item].forEach((font: any) => {
      if (font.category === 'google') googleFonts.push({ ...font, weights: [400, 700] });
    });
  });

  return <>
    <Helmet>
      <title>{textToInnerHTML(website?.name) || 'Website'}</title>
      <link rel='icon' type='image/svg' sizes='32x32' href={`/assets/svg/logos/${theme.palette.light ? 'light' : 'dark'}/logo-website.svg`} />
      <link rel='icon' type='image/svg' sizes='16x16' href={`/assets/svg/logos/${theme.palette.light ? 'light' : 'dark'}/logo-website.svg`} />
      <meta name='theme-color' content={theme.palette.light ? (theme.palette.color.website as any).main : theme.palette.color.website[20]} />

      <link
        href={getGoogleFontsURL(googleFonts.map((item: any) => ({ ...item, name: item.label })))}
        rel='stylesheet'
      />
    </Helmet>

    <Line
      ref={ref}

      gap={0}

      flex

      fullWidth

      className={classNames([
        className,
        classes.wrapper
      ])}

      {...other}

      style={{
        maxWidth: theme.maxWidth,

        ...other.style
      }}
    >
      {website && <>
        <ModalHeader
          className={classes.modalHeader}
        >
          <Line
            gap={2}

            direction='row'

            align='center'

            justify='space-between'

            fullWidth
          >
            <Tooltip
              name='Websites'
            >
              <IconButton
                onClick={() => navigate('/websites')}
              >
                <IconMaterialArrowBackRounded />
              </IconButton>
            </Tooltip>

            <ModalTitle
              dangerouslySetInnerHTML={{
                __html: textToInnerHTML(website ? `${website.name}` : 'Website')
              }}
            />

            {website?.short_name ? (
              <Tooltip
                name='Open'
              >
                <IconButton
                  onClick={() => window.open(`http${config.value.apps.websites.url.includes('localhost') ? '' : 's'}://${website.short_name}.${config.value.apps.websites.url.replace('http://', '').replace('https://', '')}`, 'blank')}
                >
                  <IconMaterialOpenInNewRounded />
                </IconButton>
              </Tooltip>
            ) : <div />}
          </Line>

          <Tabs
            value={tab}

            onChange={onChangeTab}

            justify='center'
          >
            {tabs.map((item: any, index: number) => (
              <Tab
                key={index}

                name={item.label}

                value={item.value}

                {...item.props}
              />
            ))}
          </Tabs>
        </ModalHeader>

        <ModalMain
          align='center'

          justify='unset'

          flex

          fullWidth

          className={classes.modalMain}
        >
          <Line
            align='center'

            justify='unset'

            flex

            fullWidth

            className={classes.modalMainRoot}
          >
            {modal[tab]}
          </Line>
        </ModalMain>
      </>}
    </Line>
  </>;
});

export default Website;
