import { Form, FormResponse } from '@amaui/api-utils';
import { Query } from '@amaui/models';
import AmauiSubscription from '@amaui/subscription';
import { IAdditional, IRemoveMany, IResponse, IUpdateMany } from '@amaui/sdk/other';

import { BaseService } from './base';
import { IQuerySubscription, ISelectedSubscription } from 'other';
import { AmauiService } from 'services';

class FormService extends BaseService<Form> {
  public queryItems = new AmauiSubscription<IQuerySubscription>();
  public selectedItems = new AmauiSubscription<ISelectedSubscription>();
  public queryItemResponses = new AmauiSubscription<IQuerySubscription>();
  public selectedItemResponses = new AmauiSubscription<ISelectedSubscription>();

  public constructor() {
    super('forms');

    this.queryObjectsInit(this.queryItems);

    this.selectedObjectsInit(this.selectedItems);

    this.queryObjectsInit(this.queryItemResponses, 'queryResponsePost');

    this.selectedObjectsInit(this.selectedItemResponses);
  }

  // form responses
  public async addFormResponse(id: string, body: Partial<FormResponse>, options?: IAdditional): Promise<IResponse> {
    return AmauiService.sdk.forms.addResponse(id, body as any, options);
  }

  public async getResponse(id: string, id_form_response: string, options?: IAdditional): Promise<IResponse> {
    return AmauiService.sdk.forms.getResponse(id, id_form_response, options);
  }

  public async getMyResponse(id: string, options?: IAdditional): Promise<IResponse> {
    return AmauiService.sdk.forms.getMyResponse(id, options);
  }

  public async queryResponse(id: string, options?: IAdditional): Promise<IResponse> {
    return AmauiService.sdk.forms.queryResponse(id, options);
  }

  public async queryResponsePost(id: string, body?: Partial<Query>, options?: IAdditional): Promise<IResponse> {
    return AmauiService.sdk.forms.queryResponsePost(id, body, options);
  }

  public async updateResponse(id: string, id_form_response: string, body: any, options?: IAdditional): Promise<IResponse> {
    return AmauiService.sdk.forms.updateResponse(id, id_form_response, body, options);
  }

  public async updateManyResponse(id: string, body: IUpdateMany<any>, options?: IAdditional): Promise<IResponse> {
    return AmauiService.sdk.forms.updateManyResponse(id, body, options);
  }

  public async removeResponse(id: string, id_form_response: string, options?: IAdditional): Promise<IResponse> {
    return AmauiService.sdk.forms.removeResponse(id, id_form_response, options);
  }

  public async removeManyResponse(id: string, body: IRemoveMany<any>, options?: IAdditional): Promise<IResponse> {
    return AmauiService.sdk.forms.removeManyResponse(id, body, options);
  }

}

export default new FormService();
