import React from 'react';
import { useNavigate } from 'react-router-dom';

import { debounce } from '@amaui/utils';
import { style } from '@amaui/style-react';
import { Badge, IconButton, Interaction, Line, Menu, Tooltip, Type, useSnackbars } from '@amaui/ui-react';
import { INotification } from '@amaui/api-utils';

import IconMaterialNotificationsRounded from '@amaui/icons-material-rounded-react/IconMaterialNotifications';

import { useSubscription } from 'ui';
import { AuthService, NotificationService } from 'services';
import { IQuerySubscription, ISignedIn, LOAD_MORE_LIMIT, NOTIFICATION_VERSIONS, getErrorMessage } from 'other';

const useStyle = style(theme => ({
  root: {

  },

  menu: {
    padding: '0 0 12px',
    overflow: 'hidden',

    '&.amaui-Line-root': {
      width: '100vw',
      maxWidth: 240,
      borderRadius: theme.methods.shape.radius.value(2.5),
      boxShadow: '0px 4px 32px 0px rgba(0, 0, 0, 0.04)',
      background: theme.palette.light ? theme.palette.color.neutral[100] : theme.palette.color.neutral[20]
    },

    '& .amaui-ListItem-middle .amaui-Type-root': {
      whiteSpace: 'nowrap',
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },

    '& .amaui-ListItem-wrapper': {
      background: 'none'
    }
  },

  header: {
    padding: '24px 20px 12px',
    cursor: 'default',
    userSelect: 'none'
  },

  main: {
    maxHeight: 240,
    overflow: 'hidden auto'
  },

  notification: {
    position: 'relative',
    padding: '10px 24px 10px 20px',
    flex: '0 0 auto',
    cursor: 'pointer',
    userSelect: 'none',
    overflow: 'hidden'
  },

  unread: {
    position: 'absolute',
    top: '8px',
    right: '12px',
    width: 6,
    height: 6,
    background: theme.methods.palette.color.value('secondary', 40),
    borderRadius: '50%'
  },

  text: {
    '&.amaui-Type-root': {
      whiteSpace: 'initial',
      textWrap: 'wrap',
      wordBreak: 'break-word'
    }
  },

  textEmpty: {
    '&.amaui-Type-root': {
      padding: '16px 0px 16px',
      cursor: 'default',
      userSelect: 'none'
    }
  },

  divider: {
    '&.amaui-Divider-root': {
      opacity: theme.palette.light ? 0.07 : undefined
    }
  }
}), { name: 'amaui-app-Notifications' });

const Notifications = React.forwardRef((props: any, ref: any) => {
  const {
    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const snackbars = useSnackbars();
  const navigate = useNavigate();

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const queryNotifications = useSubscription<IQuerySubscription>(NotificationService.queryNotifications);

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState<any>(false);

  const refs = {
    items: React.useRef<HTMLDivElement>(),
    queryNotifications: React.useRef(queryNotifications),
    loading: React.useRef<any>(),
    timeout: React.useRef<any>()
  };

  refs.queryNotifications.current = queryNotifications;

  refs.loading.current = loading;

  const userOrganization = signedIn.organizations?.find(item => item.signed_in);

  const userOrganizationProject = userOrganization?.projects?.find((item: any) => item.id === signedIn.project.id);

  const notificationsUnread = userOrganizationProject?.notifications_unread || 0;

  const init = React.useCallback(async () => {
    const result = await NotificationService.queryNotifications.value!.query();

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
  }, []);

  const onRead = React.useCallback(async () => {
    const result = await NotificationService.read();

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }

    // Todo
    // optimize with a better way
    // to update unread value
    await AuthService.me();

    NotificationService.queryNotifications.value!.refetch();
  }, []);

  React.useEffect(() => {
    // init
    init();
  }, [signedIn]);

  const onLoadMore = React.useCallback(async () => {
    setLoading('query');

    const result = await NotificationService.queryNotifications.value!.query({
      query: {
        ...NotificationService.queryNotifications.value!.previousQuery,

        next: refs.queryNotifications.current?.pagination?.next,
        previous: undefined,
        skip: undefined,
        total: undefined,

        loadMore: true
      }
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }

    setLoading(false);
  }, []);

  const loadMore = React.useCallback(debounce(() => {
    const items = refs.items.current as HTMLElement;

    const limit = items.scrollHeight - (items.scrollTop + items.clientHeight);

    if (limit <= LOAD_MORE_LIMIT) {
      if (!refs.queryNotifications.current?.pagination?.hasNext) return;

      // If not in loading process at the moment
      if (!refs.loading.current) {
        clearTimeout(refs.timeout.current);

        refs.timeout.current = setTimeout(onLoadMore, 140);
      }
    }
  }, 14), []);

  const onScroll = React.useCallback(loadMore, []);

  React.useEffect(() => {
    if (refs.items.current) (refs.items.current as HTMLElement).addEventListener('scroll', onScroll);

    return () => {
      if (refs.items.current) (refs.items.current as HTMLElement).removeEventListener('scroll', onScroll);
    };
  }, [open, refs.items.current]);

  const onOpen = React.useCallback(() => {
    setOpen(true);
  }, []);

  const onClose = React.useCallback(() => {
    setOpen(false);

    if (!!notificationsUnread) onRead();
  }, [notificationsUnread, onRead]);

  const notifications = queryNotifications.response;

  const getNotificationText = React.useCallback((item: INotification) => {
    const from = item.from?.users?.[0];

    if (item.version === NOTIFICATION_VERSIONS.taskAssign) return `${from?.name || 'User'} assigned a task to you`;

    if (item.version === NOTIFICATION_VERSIONS.noteMention) return `${from?.name || 'User'} mentioned you in a note`;

    return '';
  }, []);

  const onClickNotification = React.useCallback((item: INotification) => {
    if (item.version === NOTIFICATION_VERSIONS.noteMention) navigate(`/notes/mentions`);

    if (item.version === NOTIFICATION_VERSIONS.taskAssign) navigate(`/tasks/assigned-to-me`);

    onClose();
  }, [onClose]);

  const getNotification = React.useCallback((item: INotification, index: number) => {
    const text = getNotificationText(item);

    if (!text) return null;

    return (
      <Line
        key={index}

        gap={0}

        direction='row'

        wrap='wrap'

        fullWidth

        onClick={() => onClickNotification(item)}

        className={classes.notification}
      >
        <Interaction />

        <Type
          version='b3'

          className={classes.text}
        >
          {text}
        </Type>

        {!item.read && <Line className={classes.unread} />}
      </Line>
    );
  }, [onClickNotification]);

  const menu = (
    <Line
      gap={0}

      className={classes.menu}

      fullWidth
    >
      <Line
        className={classes.header}

        fullWidth
      >
        <Type
          version='l2'
        >
          Notifications
        </Type>
      </Line>

      <Line
        ref={refs.items}

        gap={0}

        align='center'

        fullWidth

        className={classes.main}
      >
        {queryNotifications.loaded && !notifications?.length && (
          <Type
            version='b3'

            align='center'

            className={classes.textEmpty}
          >
            No notifications <span style={{ fontSize: '114%', marginLeft: 2 }}>✨</span>
          </Type>
        )}

        {notifications?.map((item: any, index: number) => getNotification(item, index))}
      </Line>
    </Line>
  );

  return (
    <Menu
      open={open}

      onOpen={onOpen}

      onClose={onClose}

      name={menu}

      // alignment='end'

      AppendProps={{
        offset: [0, 8]
      }}

      {...other}
    >
      <span>
        <Tooltip
          name='Notifications'
        >
          <IconButton>
            <Badge
              color='error'

              max={14}

              value={notificationsUnread >= 1 ? notificationsUnread : undefined}
            >
              <IconMaterialNotificationsRounded />
            </Badge>
          </IconButton>
        </Tooltip>
      </span>
    </Menu>
  );
});

export default Notifications;
