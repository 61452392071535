import React from 'react';

import { Form, FormRow, IconButton, Line, NumericTextField, SmartTextField, Switch, Tooltip } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';
import { arrayMoveItem, copy, getID, getObjectValue, setObjectValue, textToInnerHTML } from '@amaui/utils';

import IconMaterialKeyboardArrowUpRounded from '@amaui/icons-material-rounded-react/IconMaterialKeyboardArrowUp';
import IconMaterialKeyboardArrowDownRounded from '@amaui/icons-material-rounded-react/IconMaterialKeyboardArrowDown';
import IconMaterialAddRounded from '@amaui/icons-material-rounded-react/IconMaterialAdd';
import IconMaterialContentCopyRounded from '@amaui/icons-material-rounded-react/IconMaterialContentCopy';
import IconMaterialRemoveRounded from '@amaui/icons-material-rounded-react/IconMaterialRemove';

import { SelectMedia, SelectSize } from './elements';

const useStyle = style(theme => ({
  root: {

  },

  type: {
    columnGap: '40px'
  },

  preview: {
    position: 'relative',
    width: 154,
    height: 77,
    borderRadius: 12,
    overflow: 'hidden',
    boxShadow: theme.shadows.values.default[1]
  },

  backgroundImage: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  },

  backgroundVideo: {
    position: 'absolute',
    inset: '0',
    minHeight: '100%',
    minWidth: '100%',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: '1'
  },

  backgroundOverlay: {
    position: 'absolute',
    inset: '0',
    zIndex: '4'
  },

  backgroundOverlayBlur: {
    backdropFilter: 'blur(12px)'
  },

  divider: {
    columnRule: `1px solid ${theme.palette.text.divider}`
  },

  h1: {
    ...theme.typography.values.h1,

    fontSize: `clamp(1.75rem, 1.5874vw, ${theme.typography.values.h1.fontSize})`
  },

  h2: {
    ...theme.typography.values.h2,

    fontSize: `clamp(1.5rem, 1.3394vw, ${theme.typography.values.h2.fontSize})`
  },

  h3: {
    ...theme.typography.values.h3,

    fontSize: `clamp(1.25rem, 1.1904vw, ${theme.typography.values.h3.fontSize})`
  },

  noData: {
    '& .amaui-FormRow-main': {
      display: 'none'
    }
  }
}), { name: 'amaui-app-OptionsSectionReviews' });

const OptionsSectionReviews = React.forwardRef((props: any, ref: any) => {
  const {
    value,

    element,

    index,

    onUpdate,

    className,

    children,

    ...other
  } = props;

  const { classes } = useStyle();

  const refs = {
    value: React.useRef(value),
    index: React.useRef(index),
    onUpdate: React.useRef(onUpdate),
    element: React.useRef(element)
  };

  refs.value.current = value;

  refs.index.current = index;

  refs.onUpdate.current = onUpdate;

  refs.element.current = element;

  const update = React.useCallback((property: string, valueProperty: any) => {
    const valueNew = [...(refs.value.current || [])];

    if (refs.element.current) {
      setObjectValue(refs.element.current, property, valueProperty || null);

      refs.onUpdate.current(valueNew);
    }
  }, []);

  const enable = React.useCallback((valueSwitch: boolean, property: string, valueDefault: any) => {
    const valueNew = [...(refs.value.current || [])];

    if (refs.element.current) {
      setObjectValue(refs.element.current, property, !valueSwitch ? null : valueDefault);

      refs.onUpdate.current(valueNew);
    }
  }, []);

  const getItem = (item: any, index: number) => (
    <FormRow
      key={index}

      gap={2}

      name={item.name}

      start={!item.noSwitch && (
        <Switch
          tonal

          valueDefault={item.defaultSwitch !== undefined ? item.defaultSwitch : !!getObjectValue(element?.props, item.property)}

          onChange={(valueNew: any) => enable(valueNew, `props.${item.property}`, item.default)}

          size='small'
        />
      )}

      {...item?.props}

      className={classNames([
        item?.props?.className,
        (!item.show && [null, undefined].includes(getObjectValue(element?.props || {}, item.property))) && classes.noData
      ])}
    >
      {(item.show || ![null, undefined].includes(getObjectValue(element?.props || {}, item.property))) && item.value}
    </FormRow>
  );

  const onAddItem = React.useCallback((event: MouseEvent) => {
    event.stopPropagation();

    const values = refs.element.current?.props?.values || [];

    values.push({
      id: getID()
    });

    update('props.values', values);
  }, []);

  const onUpdateItem = React.useCallback((property: string, valueNew: any, index: number) => {
    const values = refs.element.current?.props?.values || [];

    if (index > -1) {
      setObjectValue(values[index], property, valueNew);
    }

    update('props.values', values);
  }, []);

  const onMoveItem = React.useCallback((index: number, indexNew: number, event: MouseEvent) => {
    event.stopPropagation();

    const values = refs.element.current?.props?.values || [];

    arrayMoveItem(values, index, indexNew);

    update('props.values', values);
  }, []);

  const onCopyItem = React.useCallback((index: number, event: MouseEvent) => {
    event.stopPropagation();

    const values = refs.element.current?.props?.values || [];

    const item = copy(values[index]);

    item.name = `${item.name || ''} copy`.trim();

    values.push(item);

    update('props.values', values);
  }, []);

  const onRemoveItem = React.useCallback((index: number, event: MouseEvent) => {
    event.stopPropagation();

    const values = refs.element.current?.props?.values || [];

    if (index > -1) values.splice(index, 1);

    update('props.values', values);
  }, []);

  const options: any = [
    // size 
    {
      name: 'Size',
      property: 'size',
      default: 'regular',
      noSwitch: true,
      show: true,
      value: (
        <SelectSize
          value={element?.props?.size || 'regular'}

          onChange={(valueNew: any) => update('props.size', valueNew)}
        />
      )
    }
  ];

  return (
    <Line
      fullWidth

      className={classNames([
        className,
        classes.root
      ])}

      {...other}
    >
      <Form
        size='small'

        wrapper
      >
        <Line
          fullWidth
        >
          {options.map((item: any, index: number) => getItem(item, index))}

          <Form
            name='Items'

            openDefault

            accordion

            wrapper

            end={(
              <Tooltip
                name='Add item'
              >
                <IconButton
                  onClick={onAddItem}
                >
                  <IconMaterialAddRounded />
                </IconButton>
              </Tooltip>
            )}
          >
            {(element?.props?.values || []).map((item: any, index: number) => {
              return (
                <Form
                  key={item.id}

                  gap={1}

                  name={item?.name ? textToInnerHTML(item.name) : `Item ${index + 1}`}

                  size='small'

                  fullWidth={false}

                  row

                  MainProps={{
                    direction: 'column'
                  }}

                  HeaderProps={{
                    align: 'center'
                  }}

                  end={(
                    <Line
                      gap={0}

                      direction='row'

                      align='center'
                    >
                      <Tooltip
                        name='Move up'
                      >
                        <IconButton
                          onClick={(event: MouseEvent) => onMoveItem(index, index - 1, event)}

                          size='small'

                          disabled={!index}
                        >
                          <IconMaterialKeyboardArrowUpRounded />
                        </IconButton>
                      </Tooltip>

                      <Tooltip
                        name='Move down'
                      >
                        <IconButton
                          onClick={(event: MouseEvent) => onMoveItem(index, index + 1, event)}

                          size='small'

                          disabled={index === value?.length - 1}
                        >
                          <IconMaterialKeyboardArrowDownRounded />
                        </IconButton>
                      </Tooltip>

                      <Tooltip
                        name='Copy item'
                      >
                        <IconButton
                          onClick={(event: MouseEvent) => onCopyItem(index, event)}

                          size='small'
                        >
                          <IconMaterialContentCopyRounded />
                        </IconButton>
                      </Tooltip>

                      <Tooltip
                        name='Remove item'
                      >
                        <IconButton
                          onClick={(event: MouseEvent) => onRemoveItem(index, event)}

                          size='small'
                        >
                          <IconMaterialRemoveRounded />
                        </IconButton>
                      </Tooltip>
                    </Line>
                  )}

                  accordion

                  wrapper
                >
                  <FormRow
                    fullWidth

                    style={{
                      marginBottom: 24
                    }}
                  >
                    <SelectMedia
                      value={item?.image}

                      selected={item?.image}

                      mime='image'

                      onChange={(valueNew: any) => update(`props.values.${index}.image`, valueNew)}
                    />

                    <SmartTextField
                      placeholder='Name'

                      valueDefault={textToInnerHTML(item?.name || '')}

                      onChange={(valueNew: string) => onUpdateItem('name', valueNew, index)}

                      additional={{
                        version: 't3'
                      }}

                      edit
                    />

                    <SmartTextField
                      placeholder='Meta'

                      valueDefault={textToInnerHTML(item?.meta || '')}

                      onChange={(valueNew: string) => onUpdateItem('meta', valueNew, index)}

                      additional={{
                        version: 'b3'
                      }}

                      edit
                    />

                    <SmartTextField
                      placeholder='Description'

                      valueDefault={textToInnerHTML(item?.description || '')}

                      onChange={(valueNew: string) => onUpdateItem('description', valueNew, index)}

                      additional={{
                        version: 'b2'
                      }}

                      multiline

                      edit
                    />

                    <NumericTextField
                      placeholder='Rating'

                      valueDefault={item.rating}

                      onChange={(valueNew: string) => onUpdateItem('rating', valueNew, index)}

                      min={0}

                      max={5}
                    />
                  </FormRow>
                </Form>
              );
            })}
          </Form>
        </Line>
      </Form>
    </Line>
  );
});

export default OptionsSectionReviews;
