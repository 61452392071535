import React from 'react';

import { Form, FormRow, Line, Switch, TextField } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';
import { getObjectValue, setObjectValue, textToInnerHTML } from '@amaui/utils';

import { SelectAny, SelectColor, SelectSize } from './elements';

const useStyle = style(theme => ({
  root: {

  }
}), { name: 'amaui-app-OptionsSectionMedia' });

const OptionsSectionMedia = React.forwardRef((props: any, ref: any) => {
  const {
    value,

    element,

    index,

    onUpdate,

    className,

    children,

    ...other
  } = props;

  const { classes } = useStyle();

  const refs = {
    value: React.useRef(value),
    index: React.useRef(index),
    onUpdate: React.useRef(onUpdate),
    element: React.useRef(element)
  };

  refs.value.current = value;

  refs.index.current = index;

  refs.onUpdate.current = onUpdate;

  refs.element.current = element;

  const update = React.useCallback((property: string, valueProperty: any) => {
    const valueNew = [...(refs.value.current || [])];

    if (refs.element.current) {
      setObjectValue(refs.element.current, property, valueProperty);

      refs.onUpdate.current(valueNew);
    }
  }, []);

  const enable = React.useCallback((valueSwitch: boolean, property: string, valueDefault: any) => {
    const valueNew = [...(refs.value.current || [])];

    if (refs.element.current) {
      setObjectValue(refs.element.current, property, !valueSwitch ? null : valueDefault);

      refs.onUpdate.current(valueNew);
    }
  }, []);

  const getItem = (item: any, index: number) => (
    <FormRow
      key={index}

      gap={2}

      name={item.name}

      start={!item.noSwitch && (
        <Switch
          tonal

          valueDefault={item.defaultSwitch !== undefined ? item.defaultSwitch : !!getObjectValue(element?.props, item.property)}

          onChange={(valueNew: any) => enable(valueNew, `props.${item.property}`, item.default)}

          size='small'
        />
      )}

      {...item?.props}

      className={classNames([
        item?.props?.className,
        (!item.show && [null, undefined].includes(getObjectValue(element?.props || {}, item.property))) && classes.noData
      ])}
    >
      {(item.show || ![null, undefined].includes(getObjectValue(element?.props || {}, item.property))) && item.value}
    </FormRow>
  );

  const options: any = [
    // size 
    {
      name: 'Size',
      property: 'size',
      default: 'regular',
      noSwitch: true,
      show: true,
      value: (
        <SelectSize
          value={element?.props?.ButtonProps?.size || 'regular'}

          onChange={(valueNew: any) => update('props.ButtonProps.size', valueNew)}
        />
      )
    }
  ];

  return (
    <Line
      fullWidth

      className={classNames([
        className,
        classes.root
      ])}

      {...other}
    >
      <Form
        size='small'

        wrapper
      >
        <Line
          fullWidth
        >
          <TextField
            name='Text'

            valueDefault={textToInnerHTML(element?.props?.text || '')}

            onChange={(valueNew: string) => update('props.text', valueNew)}
          />

          <SelectAny
            name='Version'

            value={element?.props?.ButtonProps?.version || 'filled'}

            options={['filled', 'outlined', 'text']}

            onChange={(valueNew: any) => update('props.ButtonProps.version', valueNew)}
          />

          <SelectColor
            name='Color'

            value={element?.props?.ButtonProps?.color || 'default'}

            onChange={(valueNew: any) => update('props.ButtonProps.color', valueNew)}
          />

          <FormRow
            name='To'

            description={`If you want to navigate to an existing website page`}
          >
            <TextField
              name='To'

              placeholder='/about'

              valueDefault={textToInnerHTML(element?.props?.to || '')}

              onChange={(valueNew: string) => update('props.to', valueNew)}

              type='url'
            />
          </FormRow>

          <FormRow
            name='Link'

            description={`If you want to navigate to an external website`}
          >
            <TextField
              name='Link'

              placeholder='https://amaui.me'

              valueDefault={textToInnerHTML(element?.props?.link || '')}

              onChange={(valueNew: string) => update('props.link', valueNew)}

              type='url'
            />
          </FormRow>

          {options.map((item: any, index: number) => getItem(item, index))}
        </Line>
      </Form>
    </Line>
  );
});

export default OptionsSectionMedia;
