import React from 'react';

import { AutoComplete } from '@amaui/ui-react';
import { IAutoComplete } from '@amaui/ui-react/AutoComplete/AutoComplete';
import { classNames, style } from '@amaui/style-react';

const useStyle = style(theme => ({
  root: {

  }
}), { name: 'amaui-app-AutoCompleteSearch' });

const Element = React.forwardRef((props: IAutoComplete, ref: any) => {
  const {
    className,

    ...other
  } = props;

  const { classes } = useStyle(props);

  return (
    <AutoComplete
      ref={ref}

      size='small'

      className={classNames([
        classes.root,
        className
      ])}

      fullWidth={{
        xxs: true,
        xs: true,
        default: false
      }}

      {...other}
    />
  );
});

Element.displayName = 'amaui-app-AutoComplete';

export default Element;
