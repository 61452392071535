import React from 'react';

import { copy, encodeObjectValue, is, isValid, slugify, textToInnerHTML } from '@amaui/utils';
import { IconButton, Line, SmartTextField, Tooltip, useForm, useSnackbars } from '@amaui/ui-react';
import { style } from '@amaui/style-react';
import { Page } from '@amaui/themes';

import IconMaterialRefreshRounded from '@amaui/icons-material-rounded-react/IconMaterialRefresh';

import { ModalForm, OptionsSEO, OptionsShare, OptionsWebsites, PageBuilder, TextField } from 'ui';
import { AppService, SupportArticleService } from 'services';
import { getErrorMessage, getTypeText, readTime } from 'other';

const useStyle = style(theme => ({
  root: {

  },

  ...theme.classes(theme)
}), { name: 'amaui-app-SupportArticle' });

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    object: object_,

    template,

    onConfirm
  } = props;

  const { classes } = useStyle();

  const snackbars = useSnackbars();

  const [object, setObject] = React.useState(object_);
  const [tab, setTab] = React.useState(object ? 'Support article' : 'Options');
  const [loading, setLoading] = React.useState<any>(false);

  const form = useForm({
    values: {
      'name': {
        name: 'Name',
        value: object?.name,
        max: 1400,
        messages: {
          min: 'Name has to be min 1 characters',
          max: 'Name can be max 1400 characters'
        }
      },
      'short_description': {
        name: 'Short description',
        value: object?.short_description,
        max: 4400,
        messages: {
          min: 'Short description has to be min 1 characters',
          max: 'Short description can be max 4400 characters'
        }
      },
      'value': {
        name: 'Content',
        value: copy(object ? object?.value : template?.value),
        is: 'array'
      },
      'seo.name': {
        name: 'SEO name',
        value: object?.seo?.name,
        min: 1,
        max: 70,
        messages: {
          min: 'SEO name has to be min 1 characters',
          max: 'SEO name can be max 70 characters'
        }
      },
      'seo.description': {
        name: 'SEO description',
        value: object?.seo?.description,
        min: 1,
        max: 164,
        messages: {
          min: 'SEO description has to be min 1 characters',
          max: 'SEO description can be max 164 characters'
        }
      },
      'seo.image': {
        name: 'SEO image',
        value: object?.seo?.image
      },
      'websites': {
        name: 'Websites',
        value: object?.websites,
        is: 'array',
        of: 'object'
      },
      'options.share.active': {
        name: 'Options share active',
        value: object?.options?.share?.active,
        is: 'boolean'
      }
    }
  });

  const refs = {
    value: React.useRef<HTMLElement>(),
    supportArticle: React.useRef<HTMLElement>(),
    form: React.useRef(form),
    onConfirm: React.useRef<any>(onConfirm)
  };

  refs.form.current = form;

  refs.onConfirm.current = onConfirm;

  const valueDefault = copy(object?.value);

  const init = React.useCallback(async () => {
    if (object?.id) {
      if (is('array', object?.websites)) object.websites = object.websites.map((objectWebsite: any) => ({
        ...objectWebsite,

        value: objectWebsite?.id
      }));

      refs.form.current.onChange([
        ['name', object?.name],
        ['short_description', object?.short_description],
        ['url', object?.url],
        ['seo.name', object?.seo?.name],
        ['seo.description', object?.seo?.description],
        ['seo.image', object?.seo?.image],
        ['value', (valueDefault || []).map((item: any) => ({
          ...item
        }))],
        ['websites', object.websites || []]
      ]);
    }
  }, [object, valueDefault]);

  React.useEffect(() => {
    // init
    init();
  }, []);

  const onClose = React.useCallback(() => {
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });
  }, []);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();
  }, []);

  const onNext = React.useCallback(async () => {
    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const body = {
      ...refs.form.current.value,

      value: (refs.form.current.value.value || []).map((item: any) => ({
        ...item,

        props: encodeObjectValue(item.props)
      }))
    };

    if (is('array', body.websites)) body.websites = body.websites.map((item: any) => ({
      id: item.id,
      name: item.name
    }));

    const text = getTypeText(body.value);

    body.read_time = readTime(text);

    const result = !object?.id ? await SupportArticleService.add(body) : await SupportArticleService.update(object?.id, body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `SupportArticle ${!object?.id ? 'added' : 'updated'}`
      });

      setObject(result.response.response);

      if (is('function', onConfirm)) onConfirm();

      if (!object?.id) onClose();
    }

    setLoading(false);
  }, [object, isValid, form, onConfirm, onClose]);

  const onRefreshOptions = React.useCallback(async (override = true) => {
    await refs.form.current.validate();

    if (refs.form.current.values.name?.value) {
      if (
        override ||
        !refs.form.current.values.url?.value
      ) {
        refs.form.current.onChange('url', slugify(refs.form.current.values.name?.value));
      }

      if (
        override ||
        !refs.form.current.values['seo.name']?.value
      ) {
        refs.form.current.onChange('seo.name', refs.form.current.values.name?.value);
      }
    }

    if (refs.form.current.values.short_description?.value) {
      if (
        override ||
        !refs.form.current.values['seo.description']?.value
      ) {
        refs.form.current.onChange('seo.description', refs.form.current.values.short_description?.value);
      }
    }
  }, []);

  const onChangeTab = React.useCallback((value: any) => {
    setTab(value);
  }, []);

  const onPageBuilderChange = React.useCallback((valueNew: any) => {
    form.onChange('value', valueNew);
  }, []);

  const modal: any = {
    'Support article': <>
      <Line
        ref={refs.supportArticle}

        gap={0}

        align='unset'

        justify='unset'

        flex

        fullWidth
      >
        <PageBuilder
          page={(
            <Page
              page={{
                value: form.values.value.value
              }}
            />
          )}

          value={form.values.value.value}

          onChange={onPageBuilderChange}

          object={object}

          website={form.value.websites?.[0]}
        />
      </Line>
    </>,

    'Options': <>
      <Line
        fullWidth

        className={classes.page}
      >
        <Line
          direction='row'

          align='center'

          justify='flex-end'

          fullWidth

          className={classes.paddingSmall}

          style={{
            paddingInline: 0
          }}
        >
          <Tooltip
            name='Refresh'
          >
            <IconButton
              onClick={onRefreshOptions}
            >
              <IconMaterialRefreshRounded />
            </IconButton>
          </Tooltip>
        </Line>

        <Line
          gap={1}

          fullWidth
        >
          <TextField
            name='Name'

            valueDefault={textToInnerHTML(form.values['name'].value)}

            onChange={(valueNew: any) => form.onChange('name', valueNew, undefined, { rerenderOnUpdate: false })}
          />

          <SmartTextField
            placeholder='Summary'

            valueDefault={textToInnerHTML(form.values['short_description'].value || '')}

            onChange={(valueNew: any) => form.onChange('short_description', valueNew, undefined, { rerenderOnUpdate: false })}

            minRows={4}

            multiline

            edit
          />
        </Line>

        <OptionsWebsites
          description='Websites this support article can be visible at'

          value={form.values.websites.value}

          onChange={(valueNew: any[]) => form.onChange('websites', valueNew)}
        />

        <OptionsShare
          form={form}
        />
      </Line>
    </>,

    'SEO': <>
      <Line
        fullWidth

        className={classes.page}
      >
        <OptionsSEO
          app='support'

          values={form.values}

          onChange={form.onChange}
        />
      </Line>
    </>
  };

  return (
    <ModalForm
      {...props}

      object={object}

      add

      tabDefault='Support article'

      tab={tab}

      tabs={['Support article', 'Options', 'SEO']}

      onChangeTab={onChangeTab}

      onSubmit={onSubmit}

      onNext={onNext}

      onClose={onClose}

      loading={loading}
    >
      {modal[tab]}
    </ModalForm>
  );
});

export default Element;
