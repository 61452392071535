import React from 'react';

import { FormRow, Line } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';
import { is } from '@amaui/utils';

import IconMaterialFolderRounded from '@amaui/icons-material-rounded-react/IconMaterialFolder';
import IconMaterialCheckRounded from '@amaui/icons-material-rounded-react/IconMaterialCheck';
import IconMaterialImageRounded from '@amaui/icons-material-rounded-react/IconMaterialImage';
import IconMaterialVideocamRounded from '@amaui/icons-material-rounded-react/IconMaterialVideocam';
import IconMaterialMusicNoteRounded from '@amaui/icons-material-rounded-react/IconMaterialMusicNote';

import { useMedia } from 'ui';
import { getMediaUrl, mediaToValue } from 'other';

const useStyle = style(theme => ({
  root: {

  },

  wrapper: {
    width: 104,
    height: 54,
    backgroundColor: theme.palette.background.primary[theme.palette.light ? 'tertiary' : 'quaternary'],
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    borderRadius: 12,
    boxShadow: theme.shadows.values.default[1],
    cursor: 'pointer',
    userSelect: 'none',
    transition: theme.methods.transitions.make('transform', { duration: 'sm' }),

    '&:active': {
      transform: 'scale(0.97)'
    }
  }
}), { name: 'amaui-app-SelectMedia' });

const SelectMedia = React.forwardRef((props: any, ref: any) => {
  const {
    value,

    onChange,

    name = 'Media',

    mime: mime_ = 'image',

    allowed,

    multiple,

    mediaSelected: mediaSelected_,

    selected: selected_,

    Icon = IconMaterialFolderRounded,
    IconSelected: IconSelected_,

    WrapperProps,

    className,

    ...other
  } = props;

  const media = useMedia();

  const { classes } = useStyle();

  const mime = is('array', mime_) ? mime_[0] : mime_;

  const selected = selected_ !== undefined ? ((is('array', selected_) ? selected_ : [selected_]).filter(Boolean)) : (is('array', mediaSelected_) ? mediaSelected_ : [mediaSelected_]).filter(Boolean);

  const mediaSelected = mediaSelected_ !== undefined ? mediaSelected_ : (value?.props?.url || value?.props?.urlSmall);

  const mediaID = mediaSelected_?.id || (is('string', mediaSelected) && mediaSelected?.split('/read')[0].split('medias/')[1]);

  const onOpenMedia = React.useCallback(() => {
    const filters: any = {};

    if (mime !== 'all') filters.mime = mime;

    media.open({
      allowed: allowed || (mime !== 'all' ? mime : undefined),
      multiple,
      filters,
      selected: [
        mediaID && {
          id: mediaID
        },

        value?.id,

        value?._id,

        ...(selected || [])
      ].filter(Boolean),
      onConfirm: (valueNew: any) => {
        const response = is('array', valueNew) ? valueNew.map((item: any) => mediaToValue(item)) : mediaToValue(valueNew);

        onChange(is('array', response) && !multiple ? response[0] : response);
      }
    });
  }, [value, mime, allowed, media, selected, multiple, mediaID, onChange]);

  React.useEffect(() => {
    const filters: any = {};

    if (mime !== 'all') filters.mime = mime;

    // update 
    media.update({
      allowed: allowed || (mime !== 'all' ? mime : undefined),
      multiple,
      filters,
      selected: [
        mediaID && {
          id: mediaID
        },

        value?.id,

        value?._id,

        ...(selected || [])
      ].filter(Boolean),
      onConfirm: (valueNew: any) => {
        const response = is('array', valueNew) ? valueNew.map((item: any) => mediaToValue(item)) : mediaToValue(valueNew);

        onChange(is('array', response) && !multiple ? response[0] : response);
      }
    });
  }, [value]);

  let IconSelected = IconSelected_;

  if (!IconSelected) {
    if (mediaSelected_?.mime?.includes('image')) IconSelected = IconMaterialImageRounded;
    else if (mediaSelected_?.mime?.includes('audio')) IconSelected = IconMaterialMusicNoteRounded;
    else if (mediaSelected_?.mime?.includes('video')) IconSelected = IconMaterialVideocamRounded;
    else IconSelected = IconMaterialCheckRounded;
  }

  const isMediaSelected = (mediaID || !!selected.length);

  const withImage = mime?.includes('image') && mediaID;

  return (
    <FormRow
      ref={ref}

      gap={1}

      description={name}

      onClick={onOpenMedia}

      fullWidth={false}

      className={classNames([
        className,
        classes.root
      ])}

      {...other}
    >
      <Line
        align='center'

        justify='center'

        WrapperProps

        className={classNames([
          WrapperProps?.className,
          classes.wrapper
        ])}

        style={{
          ...(withImage && {
            backgroundImage: `url(${getMediaUrl({ id: mediaID }, 480)})`
          }),

          ...WrapperProps?.style
        }}
      >
        {!withImage && <>
          {isMediaSelected && (
            <IconSelected
              size={24}
            />
          )}

          {!isMediaSelected && (
            <Icon
              size={24}
            />
          )}
        </>}
      </Line>
    </FormRow>
  );
});

export default SelectMedia;
