import React from 'react';

import { copy, debounce, decodeObjectValue, encodeObjectValue, is, isValid, slugify, stringToColor, textToInnerHTML } from '@amaui/utils';
import { FormRow, IconButton, Label, Line, ListItem, SmartTextField, Switch, Tooltip, Type, useForm, useSnackbars } from '@amaui/ui-react';
import { style } from '@amaui/style-react';
import { Page } from '@amaui/themes';
import { AmauiDate, format } from '@amaui/date';

import IconMaterialRefreshRounded from '@amaui/icons-material-rounded-react/IconMaterialRefresh';

import { Avatar, Button, List, ModalForm, OptionsSEO, OptionsShare, PageBuilder, TextField, useSubscription } from 'ui';
import { AppService, PageService, WebsiteService } from 'services';
import { IQuerySubscription, formats, getErrorMessage } from 'other';

const useStyle = style(theme => ({
  root: {

  },

  ...theme.classes(theme)
}), { name: 'amaui-app-Page' });

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    parent,

    website: website_,

    template,

    object: object_,

    onConfirm
  } = props;

  const { classes } = useStyle();

  const snackbars = useSnackbars();

  const queryPageVersions = useSubscription<IQuerySubscription>(PageService.queryPageVersions);

  const [object, setObject] = React.useState(object_);
  const [tab, setTab] = React.useState('Page');
  const [loading, setLoading] = React.useState<any>(false);

  const form = useForm({
    values: {
      'name': {
        name: 'Name',
        value: object?.name,
        max: 1400,
        messages: {
          min: 'Name has to be min 1 characters',
          max: 'Name can be max 1400 characters'
        }
      },
      'description': {
        name: 'Short description',
        value: object?.description,
        max: 4400,
        messages: {
          min: 'Short description has to be min 1 characters',
          max: 'Short description can be max 4400 characters'
        }
      },
      'value': {
        name: 'Content',
        value: copy((object ? object?.value : template?.value) || []).map((item: any) => ({
          ...item,

          props: decodeObjectValue(item.props)
        })),
        is: 'array'
      },
      'url': {
        name: 'URL',
        value: object?.url,
        isValid: 'domain-name'
      },
      'main': {
        name: 'Main',
        value: object?.main,
        is: 'boolean'
      },
      'active': {
        name: 'Active',
        value: object?.active,
        is: 'boolean'
      },
      'archived': {
        name: 'Archived',
        value: object?.archived,
        is: 'boolean'
      },
      'seo.name': {
        name: 'SEO name',
        value: object?.seo?.name,
        min: 1,
        max: 70,
        messages: {
          min: 'SEO name has to be min 1 characters',
          max: 'SEO name can be max 70 characters'
        }
      },
      'seo.description': {
        name: 'SEO description',
        value: object?.seo?.description,
        min: 1,
        max: 164,
        messages: {
          min: 'SEO description has to be min 1 characters',
          max: 'SEO description can be max 164 characters'
        }
      },
      'seo.image': {
        name: 'SEO image',
        value: object?.seo?.image
      },
      'options.share.active': {
        name: 'Options share active',
        value: object?.options?.share?.active,
        is: 'boolean'
      }
    }
  });

  const refs = {
    object: React.useRef(object),
    value: React.useRef<HTMLElement>(),
    page: React.useRef<HTMLElement>(),
    form: React.useRef(form),
    onConfirm: React.useRef<any>(onConfirm),
    queryPageVersions: React.useRef(queryPageVersions),
    loading: React.useRef<any>(),
    timeout: React.useRef<any>()
  };

  refs.object.current = object;

  refs.form.current = form;

  refs.onConfirm.current = onConfirm;

  refs.queryPageVersions.current = queryPageVersions;

  refs.loading.current = loading;

  const website = website_ || parent;

  const valueDefault = copy(object?.value);

  const init = React.useCallback(async () => {
    if (object?.id) {
      refs.form.current.onChange([
        ['name', object?.name],
        ['description', object?.description],
        ['url', object?.url],
        ['main', object?.main],
        ['active', object?.active],
        ['archived', object?.archived],
        ['seo.name', object?.seo?.name],
        ['seo.description', object?.seo?.description],
        ['seo.image', object?.seo?.image],
        ['value', (valueDefault || []).map((item: any) => ({
          ...item,

          props: decodeObjectValue(item.props)
        }))]
      ]);

      await queryPageVersions.query({
        id: object?.id
      });
    }
  }, [object, valueDefault]);

  React.useEffect(() => {
    // init
    init();
  }, []);

  const onClose = React.useCallback(() => {
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });
  }, []);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();
  }, []);

  const onNext = React.useCallback(async () => {
    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const body = {
      ...refs.form.current.value,

      value: (refs.form.current.value.value || []).map((item: any) => ({
        ...item,

        props: encodeObjectValue(item.props)
      }))
    };

    const result = !object?.id ? await WebsiteService.addPage(website.id, body) : await PageService.update(object?.id, body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Page ${!object?.id ? 'added' : 'updated'}`
      });

      setObject(result.response.response);

      if (is('function', onConfirm)) onConfirm();

      if (!object?.id) onClose();
    }

    setLoading(false);
  }, [website, object, isValid, form, onConfirm, onClose]);

  const onRefreshOptions = React.useCallback(async (override = true) => {
    await refs.form.current.validate();

    if (refs.form.current.values.name?.value) {
      if (
        override ||
        !refs.form.current.values.url?.value
      ) {
        refs.form.current.onChange('url', slugify(refs.form.current.values.name?.value));
      }

      if (
        override ||
        !refs.form.current.values['seo.name']?.value
      ) {
        refs.form.current.onChange('seo.name', refs.form.current.values.name?.value);
      }
    }

    if (refs.form.current.values.description?.value) {
      if (
        override ||
        !refs.form.current.values['seo.description']?.value
      ) {
        refs.form.current.onChange('seo.description', refs.form.current.values.description?.value);
      }
    }
  }, []);

  const onVersionClick = React.useCallback(async (version: any) => {
    setLoading(true);

    const result = await PageService.applyVersion(refs.object.current.id, version.id);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      const response = result.response.response;

      refs.form.current.onChange('value', response?.map((item: any) => ({
        ...item,

        props: decodeObjectValue(item.props)
      })));

      snackbars.add({
        primary: `Selected ${format(new AmauiDate(version.added_at), formats.entire)} version`
      });

      setTab('Page');
    }

    setLoading(false);
  }, []);

  const onLoadMore = React.useCallback(async () => {
    setLoading('query');

    const result = await PageService.queryPageVersions.value!.query({
      id: refs.object.current?.id,

      query: {
        ...PageService.queryPageVersions.value?.previousQuery,

        next: refs.queryPageVersions.current?.pagination?.next,
        previous: undefined,
        skip: undefined,
        total: undefined,

        loadMore: true
      }
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }

    setLoading(false);
  }, []);

  const loadMoreVersions = React.useCallback(debounce(() => {
    if (!refs.queryPageVersions.current?.pagination?.hasNext) return;

    // If not in loading process at the moment
    if (!refs.loading.current) {
      clearTimeout(refs.timeout.current);

      refs.timeout.current = setTimeout(onLoadMore, 140);
    }
  }, 14), []);

  const onChangeTab = React.useCallback((value: any) => {
    setTab(value);
  }, []);

  const onPageBuilderChange = React.useCallback((valueNew: any) => {
    form.onChange('value', valueNew);
  }, []);

  const modal: any = {
    'Page': <>
      <Line
        ref={refs.page}

        gap={0}

        align='unset'

        justify='unset'

        flex

        fullWidth
      >
        <PageBuilder
          page={(
            <Page
              page={{
                value: form.value.value
              }}
            />
          )}

          value={form.value.value}

          onChange={onPageBuilderChange}

          object={object}

          website={website}
        />
      </Line>
    </>,

    'SEO': <>
      <Line
        gap={2}

        fullWidth

        className={classes.page}
      >
        <OptionsSEO
          app='website'

          values={form.values}

          onChange={form.onChange}
        />
      </Line>
    </>,

    'Options': <>
      <Line
        gap={2}

        fullWidth

        className={classes.page}
      >
        <Line
          direction='row'

          align='center'

          justify='flex-end'

          fullWidth

          className={classes.paddingSmall}

          style={{
            paddingInline: 0
          }}
        >
          <Tooltip
            name='Refresh'
          >
            <IconButton
              onClick={onRefreshOptions}
            >
              <IconMaterialRefreshRounded />
            </IconButton>
          </Tooltip>
        </Line>

        <Line
          gap={1}

          fullWidth
        >
          <TextField
            name='Name'

            valueDefault={textToInnerHTML(form.values['name'].value)}

            onChange={(valueNew: any) => form.onChange('name', valueNew, undefined, { rerenderOnUpdate: false })}
          />

          <SmartTextField
            placeholder='Description'

            valueDefault={textToInnerHTML(form.values['description'].value || '')}

            onChange={(valueNew: any) => form.onChange('description', valueNew, undefined, { rerenderOnUpdate: false })}

            minRows={4}

            multiline

            edit
          />
        </Line>

        <FormRow
          name='URL'
          description='URL has to be unique within your project'
        >
          <TextField
            name='URL'

            placeholder='i-love-trees'

            valueDefault={form.values.url?.value || ''}

            onChange={(valueNew: string) => form.onChange('url', valueNew, undefined, { rerenderOnUpdate: false })}

            error={!!form.values.url.error}

            helperText={form.values.url.error}

            fullWidth
          />
        </FormRow>

        <Label
          checked={form.values.main?.value}

          onChange={(valueNew: boolean) => form.onChange('main', valueNew)}

          error={!!form.values.main.error}

          helperText={form.values.main.error}
        >
          <Switch />

          Main
        </Label>

        <Label
          checked={form.values.active?.value}

          onChange={(valueNew: boolean) => form.onChange('active', valueNew)}

          error={!!form.values.active.error}

          helperText={form.values.active.error}
        >
          <Switch />

          Active
        </Label>

        <Label
          checked={form.values.archived?.value}

          onChange={(valueNew: boolean) => form.onChange('archived', valueNew)}

          error={!!form.values.archived.error}

          helperText={form.values.archived.error}
        >
          <Switch />

          Archived
        </Label>

        <OptionsShare
          form={form}
        />
      </Line>
    </>,

    'Versions': <>
      <Line
        gap={2}

        align='center'

        fullWidth

        className={classes.page}
      >
        {!queryPageVersions.length && (
          <Type
            version='l2'

            align='center'
          >
            No versions
          </Type>
        )}

        {!!queryPageVersions.length && (
          <List
            noBackground
          >
            {queryPageVersions.response.map((item: any, index: number) => (
              <ListItem
                key={index}

                onClick={() => onVersionClick(item)}

                primary={(
                  <Type
                    version='l2'
                  >
                    {format(new AmauiDate(item.added_at), formats.entire)}
                  </Type>
                )}

                secondary={(
                  <ListItem
                    start={(
                      <Avatar
                        color={stringToColor(item.user.name)}

                        size='small'
                      >
                        {item.user.name?.slice(0, 1)}
                      </Avatar>
                    )}

                    primary={(
                      <Type
                        version='b2'
                      >
                        {item.user.name}
                      </Type>
                    )}

                    size='small'

                    noPadding

                    noBackground
                  />
                )}

                MainProps={{
                  style: {
                    gap: 4
                  }
                }}

                disabled={loading}

                noBackground

                button
              />
            ))}
          </List>
        )}

        {queryPageVersions?.pagination?.hasNext && (
          <Button
            version='text'

            onClick={loadMoreVersions}

            disabled={loading}
          >
            Load more
          </Button>
        )}
      </Line>
    </>,

    'Preview': <>
      {(object?.id && object?.active && object?.url) && (
        <PageBuilder
          mode='preview'

          url={object.main ? '/' : `/${object.url}`}

          website={website}
        />
      )}
    </>
  };

  return (
    <ModalForm
      {...props}

      object={object}

      tabDefault='Page'

      tab={tab}

      tabs={['Page', 'Options', 'SEO', 'Versions', ...((object?.id && object?.active && object?.url) ? ['Preview'] : [])]}

      onChangeTab={onChangeTab}

      onSubmit={onSubmit}

      onNext={onNext}

      onClose={onClose}

      loading={loading}

      add
    >
      {modal[tab]}
    </ModalForm>
  );
});

export default Element;
