import React from 'react';

import { Form, FormRow, Line, Medias, NumericTextField, Switch } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';
import { getObjectValue, setObjectValue } from '@amaui/utils';

import { SelectMedia, SelectSize } from './elements';

const useStyle = style(theme => ({
  root: {

  },

  type: {
    columnGap: '40px'
  },

  divider: {
    columnRule: `1px solid ${theme.palette.text.divider}`
  },

  h1: {
    ...theme.typography.values.h1,

    fontSize: `clamp(1.75rem, 1.5874vw, ${theme.typography.values.h1.fontSize})`
  },

  h2: {
    ...theme.typography.values.h2,

    fontSize: `clamp(1.5rem, 1.3394vw, ${theme.typography.values.h2.fontSize})`
  },

  h3: {
    ...theme.typography.values.h3,

    fontSize: `clamp(1.25rem, 1.1904vw, ${theme.typography.values.h3.fontSize})`
  },

  noData: {
    '& .amaui-FormRow-main': {
      display: 'none'
    }
  }
}), { name: 'amaui-app-OptionsSectionImageGallery' });

const OptionsSectionImageGallery = React.forwardRef((props: any, ref: any) => {
  const {
    value,

    element,

    index,

    onUpdate,

    className,

    children,

    ...other
  } = props;

  const { classes } = useStyle();

  const refs = {
    value: React.useRef(value),
    index: React.useRef(index),
    onUpdate: React.useRef(onUpdate),
    element: React.useRef(element)
  };

  refs.value.current = value;

  refs.index.current = index;

  refs.onUpdate.current = onUpdate;

  refs.element.current = element;

  const update = React.useCallback((property: string, valueProperty: any) => {
    const valueNew = [...(refs.value.current || [])];

    if (refs.element.current) {
      setObjectValue(refs.element.current, property, valueProperty);

      refs.onUpdate.current(valueNew);
    }
  }, []);

  const enable = React.useCallback((valueSwitch: boolean, property: string, valueDefault: any) => {
    const valueNew = [...(refs.value.current || [])];

    if (refs.element.current) {
      setObjectValue(refs.element.current, property, !valueSwitch ? null : valueDefault);

      refs.onUpdate.current(valueNew);
    }
  }, []);

  const getItem = (item: any, index: number) => (
    <FormRow
      key={index}

      gap={2}

      name={item.name}

      start={!item.noSwitch && (
        <Switch
          tonal

          valueDefault={item.defaultSwitch !== undefined ? item.defaultSwitch : !!getObjectValue(element?.props, item.property)}

          onChange={(valueNew: any) => enable(valueNew, `props.${item.property}`, item.default)}

          size='small'
        />
      )}

      {...item?.props}

      className={classNames([
        item?.props?.className,
        (!item.show && [null, undefined].includes(getObjectValue(element?.props || {}, item.property))) && classes.noData
      ])}
    >
      {(item.show || ![null, undefined].includes(getObjectValue(element?.props || {}, item.property))) && item.value}
    </FormRow>
  );

  const options: any = [
    // value  
    {
      name: 'First open image',
      property: 'value',
      default: 1,
      noSwitch: true,
      show: true,
      value: (
        <NumericTextField
          valueDefault={element?.props?.value !== undefined ? element?.props?.value + 1 : 1}

          onChange={(valueNew: any) => update('props.value', valueNew - 1)}

          min={1}

          max={element?.props?.values?.length || 1}
        />
      )
    },

    // size 
    {
      name: 'Size',
      property: 'ImageGalleryProps.size',
      default: 'regular',
      noSwitch: true,
      show: true,
      value: (
        <SelectSize
          value={element?.props?.ImageGalleryProps?.size || 'regular'}

          onChange={(valueNew: any) => update('props.ImageGalleryProps.size', valueNew)}
        />
      )
    }
  ];

  const onConfirmMedia = React.useCallback((valueNew: any) => {
    const media = valueNew;

    update(`props.values`, media || null);
  }, []);

  return (
    <Line
      fullWidth

      className={classNames([
        className,
        classes.root
      ])}

      {...other}
    >
      <Form
        size='small'

        wrapper
      >
        <Line
          fullWidth
        >
          <SelectMedia
            name='Images'

            value={element?.props?.values}

            selected={element?.props?.values}

            mime='image'

            onChange={onConfirmMedia}

            multiple
          />

          {!!element?.props?.values?.length && (
            <Medias
              size='regular'

              values={element?.props?.values?.map((item: any) => ({
                value: item
              }))}
            />
          )}

          {options.map((item: any, index: number) => getItem(item, index))}
        </Line>
      </Form>
    </Line>
  );
});

export default OptionsSectionImageGallery;
