import { SupportArticle } from '@amaui/api-utils';
import AmauiSubscription from '@amaui/subscription';

import { BaseService } from './base';
import { IQuerySubscription, ISelectedSubscription } from 'other';

class SupportArticleService extends BaseService<SupportArticle> {
  public queryItems = new AmauiSubscription<IQuerySubscription>();
  public querySupportArticlesWebsitePublic = new AmauiSubscription<IQuerySubscription>();
  public selectedItems = new AmauiSubscription<ISelectedSubscription>();

  public constructor() {
    super('supportArticles');

    this.queryObjectsInit(this.queryItems);

    this.selectedObjectsInit(this.selectedItems);

    this.queryObjectsInit(this.querySupportArticlesWebsitePublic, 'queryPostWebsitePublic');
  }

}

export default new SupportArticleService();
