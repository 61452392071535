import React from 'react';

import { capitalize, is, stringToColor, textToInnerHTML } from '@amaui/utils';
import { Badge, Emojis, IconButton, Line, List, ListItem, Medias, Menu, MoreOptions, Tab, Tabs, Type } from '@amaui/ui-react';
import { IEmoji } from '@amaui/ui-react/Emojis/Emojis';
import { classNames, style } from '@amaui/style-react';
import { IMessage } from '@amaui/api-utils';

import IconMaterialAddRounded from '@amaui/icons-material-rounded-react/IconMaterialAdd';
import IconMaterialMoodRounded from '@amaui/icons-material-rounded-react/IconMaterialMood';
import IconMaterialMoreHorizRounded from '@amaui/icons-material-rounded-react/IconMaterialMoreHoriz';
import IconMaterialReplyRounded from '@amaui/icons-material-rounded-react/IconMaterialReply';

import { Avatar, useSubscription } from 'ui';
import { ISignedIn } from 'other';
import { AuthService } from 'services';

const useStyle = style(theme => ({
  root: {

  },

  message: {
    '&:hover': {
      '& $options': {
        visibility: 'visible'
      }
    }
  },

  text: {
    '&.amaui-Type-root': {
      maxWidth: 540,
      wordBreak: 'break-word',
      padding: '10px 16px',
      background: theme.palette.background.default[theme.palette.light ? 'tertiary' : 'primary'],
      overflow: 'hidden'
    }
  },

  options: {
    visibility: 'hidden'
  },

  reactions: {
    padding: '8px 12px',
    borderRadius: 140,
    background: theme.palette.background.default[theme.palette.light ? 'primary' : 'tertiary'],
    boxShadow: theme.shadows.values.default[4]
  },

  reactionItem: {
    '&.amaui-Type-root': {
      fontSize: '1.5rem',
      color: '#fff',
      lineHeight: 1,
      borderRadius: '50%',
      padding: 4,
      cursor: 'pointer',
      userSelect: 'none',
      transition: theme.methods.transitions.make('transform', { duration: 'sm' }),

      '&:hover': {
        transform: 'scale(1.14)'
      },

      '&:active': {
        transform: 'scale(0.94)'
      }
    }
  },

  replyToReference: {

  },

  replyToReference_my: {

  },

  replyToMessageText: {
    '&.amaui-Type-root': {
      position: 'relative',
      maxWidth: 540,
      wordBreak: 'break-word',
      padding: '10px 16px',
      borderRadius: 24,
      background: theme.palette.color.neutral[theme.palette.light ? 90 : 20],
      marginInlineStart: 10,

      '&:before': {
        content: '""',
        width: 2,
        height: '100%',
        position: 'absolute',
        insetInlineStart: '-10px',
        top: 0,
        background: theme.palette.text.default.secondary
      }
    }
  },

  replyToMessageText_my: {
    '&.amaui-Type-root': {
      position: 'relative',
      maxWidth: 540,
      wordBreak: 'break-word',
      padding: '10px 16px',
      borderRadius: 24,
      background: theme.palette.color.neutral[theme.palette.light ? 90 : 20],
      marginInlineEnd: 10,

      '&:before': {
        content: '""',
        width: 2,
        height: '100%',
        position: 'absolute',
        insetInlineEnd: '-10px',
        top: 0,
        background: theme.palette.text.default.secondary
      }
    }
  },

  textWrapper: {
    position: 'relative'
  },

  ractionsInline: {
    position: 'absolute',
    padding: '10px 8px',
    background: theme.palette.background.default.tertiary,
    border: `2px solid ${theme.palette.light ? theme.palette.color.primary[99] : theme.palette.color.primary[5]}`,
    borderRadius: 140,
    bottom: '-16px',
    insetInlineStart: 0,
    cursor: 'pointer',
    userSelect: 'none',
    transition: theme.methods.transitions.make('transform', { duration: 'sm' })
  },

  ractionsInline_my: {
    position: 'absolute',
    padding: '10px 8px',
    background: theme.palette.background.default.tertiary,
    border: `2px solid ${theme.palette.light ? theme.palette.color.primary[99] : theme.palette.color.primary[5]}`,
    borderRadius: 140,
    bottom: '-16px',
    insetInlineEnd: 0,
    cursor: 'pointer',
    userSelect: 'none',
    transition: theme.methods.transitions.make('transform', { duration: 'sm' })
  },

  withReactions: {
    marginBottom: 16
  },

  inlineReaction: {
    lineHeight: 0
  },

  inlineReactionTotal: {
    lineHeight: 0
  },

  reactionItemSelected: {
    background: theme.palette.background.default.tertiary
  },

  reactionsModal: {
    width: '100vw',
    maxWidth: '240px',
    height: '100vh',
    maxHeight: '270px',
    background: theme.palette.background.default[theme.palette.light ? 'primary' : 'tertiary'],
    boxShadow: 'rgba(0, 0, 0, 0.07) 0px 4px 32px 0px',
    borderRadius: 16,
    overflow: 'hidden'
  },

  tabs: {
    '&.amaui-Tabs-root': {
      flex: '0 0 auto',
      marginTop: 8
    }
  },

  tab: {
    '&.amaui-Tab-root': {
      padding: 8,

      '& .amaui-Tab-type': {
        ...theme.typography.values.l2
      }
    }
  },

  list: {
    flex: '1 1 auto',
    height: 0,
    overflow: 'hidden auto'
  },

  badge: {
    '&.amaui-Badge-root': {
      width: '100%'
    }
  },

  messageOptions: {
    flex: '0 0 auto'
  }
}), { name: 'amaui-app-element-Message' });

const Message = React.forwardRef((props: any, ref: any) => {
  const {
    value,

    images,

    index,

    onReactionAdd: onReactionAdd_,

    onReactionRemove: onReactionRemove_,

    onReplyTo,

    getMenuItemsMessage,

    noOptions,
    noReactions,
    noReplyTo,

    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const [open, setOpen] = React.useState<any>(false);
  const [openReactions, setOpenReactions] = React.useState<any>(false);
  const [tab, setTab] = React.useState<string>('all');

  const onOpen = React.useCallback(() => {
    setOpen(true);
  }, []);

  const onClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  const onOpenReactions = React.useCallback(() => {
    setOpenReactions(true);
  }, []);

  const onCloseReactions = React.useCallback(() => {
    setOpenReactions(false);

    setTimeout(() => {
      setTab('all');
    }, 140);
  }, []);

  const onChangeTab = React.useCallback((valueNew: any) => {
    setTab(valueNew);
  }, []);

  const onReactionClick = React.useCallback((valueReaction: IEmoji | string) => {
    const unicode = (valueReaction as IEmoji)?.unicode || valueReaction;

    const exists = (value as IMessage).reactions?.find(item => (
      (item.value === unicode) &&
      (item.user.id === signedIn.user?.id)
    ));

    if (exists) {
      if (is('function', onReactionRemove_)) onReactionRemove_(value, unicode);
    }
    else {
      if (is('function', onReactionAdd_)) onReactionAdd_(value, unicode);
    }

    onClose();
  }, [value, signedIn, onReactionAdd_, onReactionRemove_, onClose]);

  if (!value) return null;

  const user = value.user;

  const my = value.my || (user?.id === signedIn?.user?.id);

  const withReplyTo = !!value?.reply_to?.value;

  const mainReactions = ['❤️', '😂', '😮', '😢', '🙏', '👍'];

  const allReactions: any = [];

  const myReactions: string[] = [];

  if (!!value?.reactions?.length) {
    (value as IMessage).reactions?.forEach(item => {
      const index = allReactions.findIndex((item_: any) => item_.value === item.value);

      if (item.user.id === signedIn.user.id) myReactions.push(item.value);

      if (index > -1) {
        allReactions[index].total++;
        allReactions[index].users.push(item.user);
      }
      else {
        allReactions.push({
          value: item.value,
          total: 1,
          users: [
            item.user
          ]
        });
      }
    });

    allReactions.sort((a: any, b: any) => b.total - a.total);
  }

  const reactions = (
    <Line
      gap={0}

      direction='row'

      align='center'

      className={classes.reactions}
    >
      {mainReactions.map((item, index: number) => (
        <Type
          key={index}

          onClick={() => onReactionClick(item as string)}

          className={classNames([
            classes.reactionItem,
            myReactions.includes(item) && classes.reactionItemSelected
          ])}
        >
          {item}
        </Type>
      ))}

      <Emojis
        onSelect={onReactionClick}

        selected={myReactions}

        className={classNames([
          'amaui-app-Message-Emojis'
        ])}
      >
        <IconButton
          size='small'
        >
          <IconMaterialAddRounded
            size='regular'
          />
        </IconButton>
      </Emojis>
    </Line>
  );

  const usersReaction: any = [];

  if (!!value.reactions?.length) {
    (value as IMessage).reactions?.filter((item: any) => tab === 'all' ? item : item.value === tab)?.forEach((item: any) => usersReaction.push({ value: item.value, user: item.user }));
  }

  const reactionOption = !noReactions && (
    <Menu
      open={open}

      onOpen={onOpen}

      onClose={onClose}

      name={reactions}

      includeParentQueries={['.amaui-app-Message-Emojis']}
    >
      <IconButton
        size='small'

        className={classNames([
          classes.options
        ])}
      >
        <IconMaterialMoodRounded />
      </IconButton>
    </Menu>
  );

  const replyToOption = !noReplyTo && (
    <IconButton
      size='small'

      onClick={() => onReplyTo(value)}

      className={classNames([
        classes.options
      ])}
    >
      <IconMaterialReplyRounded />
    </IconButton>
  );

  const reactionsModal = (
    <Line
      gap={0}

      className={classes.reactionsModal}
    >
      <Tabs
        value={tab}

        onChange={onChangeTab}

        size='small'

        initialLineUpdateTimeout={340}

        className={classes.tabs}
      >
        {['all', ...allReactions].map((item: any, index: number) => (
          <Tab
            key={index}

            name={['all'].includes(item) ? capitalize(item) : `${item?.total > 1 ? item.total : ''} ${item?.value || item}`}

            value={(item?.value || item) as any}

            className={classes.tab}
          />
        ))}
      </Tabs>

      <List
        className={classes.list}

        noBackground
      >
        {usersReaction.map((item: any, index: number) => (
          <ListItem
            key={index}

            onClick={item.user?.id === signedIn.user.id ? () => onReactionClick(item.value) : undefined}

            startAlign='center'

            start={(
              <Badge
                element={(
                  <Type
                    version='b1'
                  >
                    {item.value}
                  </Type>
                )}

                vertical='bottom'

                className={classes.badge}
              >
                <Avatar
                  color={stringToColor(item.user?.name)}

                  size='small'
                >
                  {item.user?.name?.slice(0, 2)}
                </Avatar>
              </Badge>
            )}

            primary={(
              <Type
                version='l2'
              >
                {item.user?.name}
              </Type>
            )}

            secondary={item.user?.id === signedIn.user.id && (
              <Type
                version='b3'

                priority='secondary'
              >
                Tap to remove
              </Type>
            )}

            button={item.user?.id === signedIn.user.id}

            noBackground
          />
        ))}
      </List>
    </Line>
  );

  const getValue = () => {
    const item = (value as IMessage).value![0];

    if (item.version === 'type') return (
      <Type
        version='b2'

        dangerouslySetInnerHTML={{
          __html: textToInnerHTML(item?.value)
        }}

        className={classNames([
          'amaui-app-Message-text',
          classes.text,
          withReplyTo && 'amaui-app-Message-text-with-reply-to'
        ])}
      />
    );

    if (['image', 'audio', 'video'].includes(item.version)) {
      return (
        <Medias
          values={{
            value: item.props
          }}

          noName
        />
      );
    }
  };

  const replyToValue = value.reply_to?.value?.[0];

  return (
    <Line
      gap={1}

      fullWidth

      className={classNames([
        className,
        classes.root,
        !!allReactions.length && classes.withReactions
      ])}

      {...other}
    >
      {withReplyTo && (
        <Line
          gap={0.5}

          align={my ? 'flex-end' : 'flex-start'}

          fullWidth

          className={classNames([
            classes[my ? 'replyToReference_my' : 'replyToReference']
          ])}
        >
          <Type
            version='b3'

            priority='secondary'

            align={my ? 'end' : 'start'}
          >
            Replied to {value.reply_to.user?.id === signedIn.user.id ? 'yourself' : value.reply_to.user?.name}
          </Type>

          <Type
            version='b2'

            dangerouslySetInnerHTML={{
              __html: textToInnerHTML(replyToValue?.version === 'type' ? replyToValue.value : 'Media')
            }}

            align={my ? 'end' : 'start'}

            className={classNames([
              classes[my ? 'replyToMessageText_my' : 'replyToMessageText']
            ])}
          />
        </Line>
      )}

      <Line
        gap={0.5}

        direction='row'

        align='center'

        justify={!my ? 'flex-start' : 'flex-end'}

        fullWidth

        className={classes.message}
      >
        {my && !noOptions && (
          <Line
            gap={0.5}

            direction='row'

            align='center'

            className={classes.messageOptions}
          >
            <MoreOptions
              menuItems={getMenuItemsMessage?.(value, index)}

              Icon={IconMaterialMoreHorizRounded}

              IconButtonProps={{
                className: classNames([
                  classes.options
                ])
              }}
            />

            {replyToOption}

            {reactionOption}
          </Line>
        )}

        <Line
          gap={0}

          className={classes.textWrapper}
        >
          {getValue()}

          {!!allReactions?.length && (
            <Menu
              open={openReactions}

              name={reactionsModal}

              onOpen={onOpenReactions}

              onClose={onCloseReactions}
            >
              <Line
                gap={0.5}

                direction='row'

                align='center'

                className={classNames([
                  classes[my ? 'ractionsInline_my' : 'ractionsInline']
                ])}
              >
                {allReactions.map((item: any, index: number) => (
                  <Line
                    gap={0.25}

                    direction='row'

                    align='center'
                  >
                    {item.total > 1 && (
                      <Type
                        version='b3'

                        className={classes.inlineReactionTotal}
                      >
                        {item.total}
                      </Type>
                    )}

                    <Type
                      version='b2'

                      className={classes.inlineReaction}
                    >
                      {item.value}
                    </Type>
                  </Line>
                ))}
              </Line>
            </Menu>
          )}
        </Line>

        {!my && !noOptions && (
          <Line
            gap={0.5}

            direction='row'

            align='center'

            className={classes.messageOptions}
          >
            {reactionOption}

            {replyToOption}
          </Line>
        )}
      </Line>
    </Line>
  );
});

export default Message;