import React from 'react';
import { useNavigate } from 'react-router-dom';

import { classNames, style, useAmauiTheme } from '@amaui/style-react';
import { IBaseElement } from '@amaui/ui-react/types';
import { Button, IconButton, Line, Menu, Tooltip, Type } from '@amaui/ui-react';

import IconMaterialInfoRounded from '@amaui/icons-material-rounded-react/IconMaterialInfo';

const useStyle = style(theme => ({
  root: {

  },

  menu: {
    width: '100vw',
    maxWidth: 170,
    background: theme.palette.light ? theme.palette.background.default.primary : theme.palette.background.primary.quaternary,
    borderRadius: theme.methods.shape.radius.value(3),
    boxShadow: theme.shadows.values.default[1],
    padding: 24,

    '& .amaui-Type-root': {
      whiteSpace: 'normal'
    }
  }
}), { name: 'amaui-app-Limited' });

export interface ILimited extends IBaseElement {
  name: string;
  total: any;
}

const Limited: React.FC<ILimited> = React.forwardRef((props, ref: any) => {
  const {
    name,

    total,

    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const theme = useAmauiTheme();
  const navigate = useNavigate();

  const onClick = React.useCallback(() => {
    navigate(`/organization/settings/subscription`);
  }, []);

  const getName = React.useCallback(() => {
    if (name.includes('URL')) return 'URL shorteners';

    return name?.toLowerCase();
  }, [name]);

  return (
    <Menu
      alignment='center'

      name={(
        <Line
          gap={1.5}

          fullWidth

          className={classes.menu}
        >
          <Line
            gap={1}

            fullWidth
          >
            <Type
              version='l2'
            >
              Upgrade
            </Type>

            <Type
              version='b3'
            >
              You used all of the {total} {getName()} you were provided. To unlock more, you can upgrade your plan.
            </Type>
          </Line>

          <Button
            tonal

            color={theme.palette.light ? 'primary' : 'inverted'}

            version='filled'

            onClick={onClick}

            size='small'
          >
            Upgrade
          </Button>
        </Line>
      )}
    >
      <span>
        <Tooltip
          name='Upgrade'
        >
          <IconButton
            ref={ref}

            className={classNames([
              className,
              classes.root
            ])}

            {...other}
          >
            <IconMaterialInfoRounded />
          </IconButton>
        </Tooltip>
      </span>
    </Menu>
  );
});

export default Limited;
