import { Task } from '@amaui/api-utils';
import AmauiSubscription from '@amaui/subscription';

import { BaseService } from './base';
import { IQuerySubscription, ISelectedSubscription } from 'other';

class TaskService extends BaseService<Task> {
  public queryTasks = new AmauiSubscription<IQuerySubscription>();
  public selectedTasks = new AmauiSubscription<ISelectedSubscription>();
  public task = new AmauiSubscription<Task>();

  public get paginationLimit() { return 24; }

  public constructor() {
    super('tasks');

    this.queryObjectsInit(this.queryTasks);

    this.selectedObjectsInit(this.selectedTasks);
  }

}

export default new TaskService();
