import React from 'react';

import { capitalize, cleanValue, getObjectValue, getUserLocalInfo, innerHTMLToText, is, parse, textToInnerHTML } from '@amaui/utils';
import { IApp, IAppObject, IContact, IMedia, IValue } from '@amaui/api-utils';
import { IResponse } from '@amaui/sdk/other';

import config from 'config';

import { RouteWrapper } from 'ui';
import { ISignedIn } from './types';
import { Type } from '@amaui/ui-react';
import { AmauiDate, format } from '@amaui/date';
import { colors } from '@amaui/style-react';

export const featureOptions = ['media', 'tag', 'list', 'filter'];

export const featureAppOptions = ['task', 'note', 'post', 'resume', 'urlShortener', 'website', 'support', 'chat', 'form', 'reservation', 'contact', 'location', 'workDay'];

export const FONT_FAMILY = {
  primary: ['Montserrat', 'Helvetica', 'Helvetica Neue', '-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'sans-serif'].join(', '),
  secondary: ['DM Sans', 'Helvetica', 'Helvetica Neue', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'sans-serif'].join(', '),
  tertiary: ['Roboto Mono', 'monospace'].join(', ')
};

export const lazy = (Element: any) => (props: any) => (
  <React.Suspense fallback={<></>}>
    <Element {...props} />
  </React.Suspense>
);

export const wrapper = (Element: any) => (
  <RouteWrapper
    fallback
  >
    <Element />
  </RouteWrapper>
);

export const googleLibrary: any = {
  oauth2: '',
  callback: ''
};

export const routeToAppName = (value: string) => {
  if (value?.startsWith('/websites')) return 'website';

  if (value?.startsWith('/resumes')) return 'resume';

  if (value?.startsWith('/tasks')) return 'task';

  if (value?.startsWith('/notes')) return 'note';

  if (value?.startsWith('/url-shorteners')) return 'urlShortener';

  if (value?.startsWith('/blogs')) return 'blog';

  if (value?.startsWith('/work-days')) return 'workDay';

  return 'amaui';
};

export const getMediaThumbnailURL = (value: IMedia) => {
  return `${config.value.apps.api_media_read.url}/medias/${value?.id}/read?thumbnail=true`;
};

export const getMediaUrl = (value: IMedia, version?: 140 | 480 | 1280 | 1920): string => {
  if (!value) return '';

  if (is('string', value)) return value as unknown as string;

  if (is('string', value?.urlSmall)) return value.urlSmall;

  if (is('string', value?.url)) return value.url;

  if (value?.id) {
    let url = `${config.value.apps.api_media_read.url}/medias/${value?.id}/read`;

    if (version) url += `?version=${version}`;

    return url;
  }

  if (is('string', value.url)) return value.url;

  return '';
};

export const getErrorMessage = (result: IResponse) => result?.response?.meta?.message || 'Error occured';

export const keyboardStandardCommands = ['a', 'c', 'v', 'y', 'z'];

export const keyboardStyleCommands = ['b', 'i', 'u'];

export const CONSTS = {
  mobile: 1024
};

// export const textAreaToString = (value: string) => JSON.stringify(value).slice(1, -1).replaceAll(/\\\\/g, '\\');

export const stringToHTML = (value: string) => {
  const textArea = window.document.createElement('textarea');

  textArea.innerHTML = value;

  return textArea.value.replaceAll(/\\n/g, '&#10;');
}

export const formats = {
  entire: 'DD MMM, YYYY [at] h:mm a',
  date: 'DD MMM, YYYY',
  time: 'h:mm a'
};

export const PAGINATION_LIMIT = 14;

export const LOAD_MORE_LIMIT = 300;

export const getMediaAudioMeta = (value: Blob): Promise<{ duration: number; mime: string; }> => {
  return new Promise((resolve, reject) => {
    const audio = window.document.createElement('audio');

    audio.addEventListener('loadedmetadata', () => {
      resolve({ duration: audio.duration, mime: value.type })
    });

    const url = URL.createObjectURL(value);

    audio.src = url;
  });
};

export const getMediaAudioMetaFromFile = (file: File): Promise<{ duration: number; mime?: string; }> => {
  return new Promise((resolve, reject) => {
    const audio = window.document.createElement('audio');

    audio.preload = 'metadata';

    audio.addEventListener('loadedmetadata', () => {
      const duration = audio.duration;

      window.URL.revokeObjectURL(audio.src);

      resolve({ duration });
    });

    const url = URL.createObjectURL(file);

    audio.src = url;
  });
};

export const getMediaVideoMetaFromFile = (file: File): Promise<{ duration: number; mime?: string; }> => {
  return new Promise((resolve, reject) => {
    const video = window.document.createElement('video');

    video.preload = 'metadata';

    video.addEventListener('loadedmetadata', () => {
      const duration = video.duration;

      window.URL.revokeObjectURL(video.src);

      resolve({ duration });
    });

    const url = URL.createObjectURL(file);

    video.src = url;
  });
};

export const getSettings = (signedIn: ISignedIn, value: string) => {
  const result = getObjectValue(signedIn?.organization?.personalization, value);

  return result !== undefined ? result : getObjectValue(signedIn?.user, value);
};

export const FONTS = {
  serif: [
    { label: 'Lora', family: 'serif', category: 'google' },
    { label: 'Playfair Display', family: 'serif', category: 'google' },
    { label: 'Merriweather', family: 'serif', category: 'google' },
    { label: 'Roboto Slab', family: 'serif', category: 'google' },
    { label: 'PT Serif', family: 'serif', category: 'google' },

    { label: 'Times New Roman', family: 'serif', category: 'web-safe' },
    { label: 'Georgia', family: 'serif', category: 'web-safe' },
    { label: 'Garamond', family: 'serif', category: 'web-safe' },
    { label: 'Courier New', family: 'serif', category: 'web-safe' },
    { label: 'Brush Script MT', family: 'serif', category: 'web-safe' }
  ],

  'sans-serif': [
    { label: 'Open Sans', family: 'sans serif', category: 'google' },
    { label: 'Roboto', family: 'sans serif', category: 'google' },
    { label: 'DM Sans', family: 'sans serif', category: 'google' },
    { label: 'Lato', family: 'sans serif', category: 'google' },
    { label: 'Montserrat', family: 'sans serif', category: 'google' },
    { label: 'Poppins', family: 'sans serif', category: 'google' },

    { label: 'Arial', family: 'sans serif', category: 'web-safe' },
    { label: 'Verdana', family: 'sans serif', category: 'web-safe' },
    { label: 'Helvetica', family: 'sans serif', category: 'web-safe' },
    { label: 'Tahoma', family: 'sans serif', category: 'web-safe' },
    { label: 'Trebuchet MS', family: 'sans serif', category: 'web-safe' }
  ],

  'mono': [
    { label: 'Roboto Mono', family: 'monospace', category: 'google' },
    { label: 'DM Mono', family: 'monospace', category: 'google' },

    { label: 'monospace', family: 'monospace', category: 'web-safe' }
  ]
};

export const mediaToObject = (media: IMedia): any => {
  if (!media) return media;

  const object: Partial<IMedia> = {};

  if (media.id !== undefined) object.id = media.id;

  if (media.name !== undefined) object.name = media.name;

  if (media.mime !== undefined) object.mime = media.mime;

  if (media.duration !== undefined) object.duration = media.duration;

  // if (media.original !== undefined) object.original = media.original;

  if (media.resolution !== undefined) object.resolution = media.resolution;

  // if (media.added_at !== undefined) object.added_at = media.added_at;

  // if (media.updated_at !== undefined) object.updated_at = media.updated_at;

  if (media.meta && ['audio', 'video'].some((item: any) => object?.mime?.startsWith(item))) {
    object.meta = {};

    if (media.meta.resolution !== undefined) object.meta.resolution = media.meta.resolution;

    // if (media.meta.height !== undefined) object.meta.height = media.meta.height;

    // if (media.meta.width !== undefined) object.meta.width = media.meta.width;

    if (media.meta.duration !== undefined) object.meta.duration = media.meta.duration;

    if (media.meta.size !== undefined) object.meta.size = media.meta.size;

    if (media.meta.type !== undefined) object.meta.type = media.meta.type;
  }

  object.url = getMediaUrl(object);

  if (object.mime?.startsWith('image')) object.urlSmall = getMediaUrl(object, 480);

  return object;
};

export const mediaToValue = (value: any) => {
  if (!value) return value;

  const object = mediaToObject(value);

  // only for audio, video 
  if (value.versions && ['audio', 'video'].some((item: any) => object?.mime?.startsWith(item))) object.versions = value.versions.map((item: any) => mediaToObject(item));

  if (value.thumbnails && ['audio', 'video'].some((item: any) => object?.mime?.startsWith(item))) object.thumbnails = value.thumbnails.map((item: any) => mediaToObject(item));

  return object;
};

export const mediasToValue = (value: any) => {
  if (!value) return value;

  if (is('array', value)) return value.map((item: any) => mediaToValue(item));

  if (is('object', value)) return mediaToValue(value);
};

export const NOTIFICATION_VERSIONS = {
  noteMention: 'note-mention',
  taskAssign: 'task-assign'
};

export const caret: any = {};

if (window.getSelection && document.createRange) {
  // Saves caret position(s)
  caret.save = function (container: any) {
    const selection = window.getSelection();

    if (!(selection && selection.rangeCount > 0)) return;

    const range = window.getSelection()?.getRangeAt(0);

    if (!range) return;

    const preSelectionRange = range.cloneRange();

    preSelectionRange.selectNodeContents(container);
    preSelectionRange.setEnd(range.startContainer, range.startOffset);

    const start = preSelectionRange.toString().length;

    return {
      start: start,
      end: start + range.toString().length
    };
  };

  // Restores caret position(s)
  caret.restore = function (container: any, savedElement: any) {
    let charIndex = 0, range = document.createRange();

    range.setStart(container, 0);
    range.collapse(true);

    let nodeStack = [container], node, foundStart = false, stop = false;

    while (!stop && (node = nodeStack.pop())) {
      if (node.nodeType === 3) {
        const nextCharIndex = charIndex + node.length;

        if (!foundStart && savedElement.start >= charIndex && savedElement.start <= nextCharIndex) {
          range.setStart(node, savedElement.start - charIndex);
          foundStart = true;
        }

        if (foundStart && savedElement.end >= charIndex && savedElement.end <= nextCharIndex) {
          range.setEnd(node, savedElement.end - charIndex);
          stop = true;
        }

        charIndex = nextCharIndex;
      } else {
        let i = node.childNodes.length;

        while (i--) {
          nodeStack.push(node.childNodes[i]);
        }
      }
    }

    const selection = window.getSelection();

    selection?.removeAllRanges();
    selection?.addRange(range);
  }
}
else if ((process as any).window && (window.document as any).selection && (window.document.body as any).createTextRange) {
  // Saves caret position(s)
  caret.save = function (container: any) {
    const selectedTextRange = (window.document as any).selection.createRange();
    const preSelectionTextRange = (window.document.body as any).createTextRange();

    preSelectionTextRange.moveToElementText(container);
    preSelectionTextRange.setEndPoint("EndToStart", selectedTextRange);

    const start = preSelectionTextRange.text.length;

    return {
      start: start,
      end: start + selectedTextRange.text.length
    };
  };

  // Restores caret position(s)
  caret.restore = function (container: any, savedElement: any) {
    const textRange = (window.document.body as any).createTextRange();

    textRange.moveToElementText(container);
    textRange.collapse(true);
    textRange.moveEnd("character", savedElement.end);
    textRange.moveStart("character", savedElement.start);
    textRange.select();
  };
}

export const addStyle = (value: string, id?: string) => {
  const styleElement = window.document.querySelector(`#${id}`);

  if (styleElement) styleElement.remove();

  const head = window.document.head;

  const style = window.document.createElement('style');

  style.innerHTML = value;

  head.appendChild(style);
};

export const getDeviceAndLocation: any = async () => {
  const device = parse(window.localStorage.getItem('amaui-app_device-id'));

  const location = await getUserLocalInfo();

  return {
    device,
    location
  };
};

export const APP = ['amaui', 'task', 'note', 'blog', 'resume', 'urlShortener', 'website', 'support', 'chat', 'reservation', 'contact', 'workDay', 'location'];

export const appToName = (app: IApp) => {
  if (['amaui'].includes(app)) return app;

  if (app === 'urlShortener') return 'URL shortener';

  return cleanValue(app, { capitalize: true });
};

export const objectsNameCleanUp = (value: string): string => value?.replace(/[-_]/g, ' ');

export const CONTACT_OPTIONS = ['Email', 'Mobile', 'Phone', 'Website', 'Linkedin', 'Instagram', 'Tiktok', 'Youtube', 'Address', 'Other'];

export const menuItemProps = (value: any) => ({
  button: true,
  size: 'small',

  menuCloseOnClick: true,

  ...value,

  primary: (
    <Type
      version='b3'
    >
      {value.primary}
    </Type>
  )
});

export const getTypeText = (value: IValue[]): string | string[] => {
  return value?.filter(item => ['section text media'].includes(item.version?.toLowerCase())).reduce((result, item) => {
    const propsDecoded = is('string', item.props) ? getObjectValue(item.props || '') : item.props;

    result += (propsDecoded?.title || '') + (propsDecoded?.description || '');

    return result;
  }, '');
};

export const readTime = (value_: string | string[]) => {
  const value = is('array', value_) ? value_ : [value_] as any;

  const words = [];

  value.forEach((item: any) => words.push(...item.split(' ')));

  // 4 words per second
  const readTimeValue = words.length / 4;

  return readTimeValue;
};

export const googleAPILoad = (data: any = {}) => {
  const gapi = (window as any).gapi;

  if (!gapi) return;

  return new Promise((resolve, reject) => {
    const params = {
      client_id: config.value.services.google.client_id,

      ux_mode: 'popup',
      plugin_name: 'amaui',

      ...data
    };

    gapi.load('auth2', () => {
      const GoogleAuth = gapi.auth2.getAuthInstance();

      if (!GoogleAuth) {
        gapi.auth2.init(params).then(
          (response: any) => {
            const signedIn = response?.isSignedIn?.get();

            if (signedIn) resolve(response?.currentUser?.get());
          },
          (error: any) => {
            resolve(false);
          }
        );
      }
      else {
        GoogleAuth.then(
          () => {
            const signedIn = GoogleAuth.isSignedIn?.get();

            if (signedIn) return resolve(GoogleAuth.currentUser.get());
            else return resolve(false);
          },
          (error: any) => {
            resolve(false);
          }
        );
      }
    });
  });
};

export const isLimited = (signedIn: ISignedIn, value: IAppObject) => (
  signedIn?.organizationPlan?.provided?.[value]?.total !== 'unlimited' && ((signedIn?.organizationPlan?.used?.[value]?.total || 0) as any) >= ((signedIn?.organizationPlan?.provided?.[value]?.total || 0) as any)
);

export const getParamID = (split = '/', location_ = window.location): any => {
  const id = location_.pathname.split(split).filter(Boolean).slice(-1)[0]?.replaceAll('/', '');

  if (id?.length >= 24 && !['add'].includes(id)) return id;

  return null;
};

export const getElementText = (value: any) => {
  const element = window.document.createElement('div');

  element.innerHTML = textToInnerHTML(value);

  return element.innerText || element.textContent;
};

export const importVCards = (value_: string) => {
  const values: any = [];

  let value = value_
    .split('END:VCARD')
    .map(item => item.split(/[\n\r]/).filter(item => !!item && item !== 'END:VCARD').slice(2, -1)).filter(item => !!item.length);

  value.forEach(vCard => {
    const object: any = {};

    const properties: any = [];

    vCard.forEach(item => {
      let itemValue = item.trim();

      // Name 
      if (itemValue.startsWith('FN:')) object.name = itemValue.replace('FN:', '');

      // Nickname 
      if (itemValue.startsWith('NICKNAME:')) object.nickname = itemValue.replace('NICKNAME:', '');

      // Description 
      if (itemValue.startsWith('NOTE:')) {
        const [, ...other] = itemValue.split(':');

        itemValue = other.join(':');

        if (!object.description) {
          itemValue = innerHTMLToText(itemValue);

          object.description = itemValue;
        }
        else {
          properties.push({
            version: 'custom',
            value: itemValue
          });
        }
      }

      // Email 
      if (itemValue.startsWith('EMAIL;')) {
        const [, ...other] = itemValue.split(':');

        itemValue = other.join(':');

        properties.push({
          version: 'email',
          value: itemValue
        });
      }

      // Mobile, tel, fax 
      if (itemValue.startsWith('TEL;')) {
        const [meta, ...other] = itemValue.split(':');

        itemValue = other.join(':');

        properties.push({
          version: !meta.toLowerCase().includes('fax') ? 'mobile' : 'fax',
          value: itemValue
        });
      }

      // URL 
      if (itemValue.startsWith('URL;')) {
        const [, ...other] = itemValue.split(':');

        itemValue = other.join(':');

        properties.push({
          version: 'url',
          value: itemValue
        });
      }

      // Address 
      if (itemValue.startsWith('ADR;')) {
        const [, ...other] = itemValue.split(':');

        itemValue = other.join(':').replace(/;/g, ' ').trim();

        properties.push({
          version: 'address',
          value: itemValue
        });
      }

      // Title 
      if (itemValue.startsWith('TITLE:')) {
        const [, ...other] = itemValue.split(':');

        itemValue = other.join(':');

        properties.push({
          version: 'title',
          value: itemValue
        });
      }

      // Company
      if (itemValue.startsWith('ORG:')) {
        const [, ...other] = itemValue.split(':');

        itemValue = other.join(':');

        properties.push({
          version: 'company',
          value: itemValue
        });
      }

      // Birthday
      if (itemValue.startsWith('BDAY:')) {
        const [, ...other] = itemValue.split(':');

        itemValue = other.join(':');

        properties.push({
          version: 'birthday',
          value: new AmauiDate(new Date(`${itemValue.slice(4, 6)}-${itemValue.slice(6, 8)}-${itemValue.slice(0, 4)}`)).milliseconds
        });
      }

      // Date 
      if (itemValue.startsWith('REV:')) {
        const [, ...other] = itemValue.split(':');

        itemValue = other.join(':');

        properties.push({
          version: 'date',
          value: new AmauiDate(new Date(itemValue)).milliseconds
        });
      }
    });

    const objectProperties: any = {};

    properties.forEach((item: any) => {
      let property: any;
      const version = item.version;

      if (['mobile', 'tel', 'fax'].includes(version)) property = 'tels';
      else if (['email'].includes(version)) property = 'emails';
      else if (['title'].includes(version)) property = 'titles';
      else if (['url'].includes(version)) property = 'urls';
      else if (['address'].includes(version)) property = 'addresses';
      else if (['company'].includes(version)) property = 'companies';
      else if (['birthday'].includes(version)) property = 'birthdays';
      else if (['date'].includes(version)) property = 'dates';
      else if (['custom'].includes(version)) property = 'custom';

      if (!objectProperties[property]) objectProperties[property] = [];

      objectProperties[property].push(item);
    });

    object.properties = objectProperties;

    values.push(object);
  });

  return values;
};

export const exportVCard = (items: IContact[]) => {
  const values: any = [];

  items.forEach(contact => {
    let value = `BEGIN:VCARD
VERSION:3.0\n`;

    // Full name 
    if (contact.name) {
      value += `FN:${contact.name}\n`;

      const nameParts = contact.name.split(' ').slice(0, 4);

      value += `N:${nameParts.join(';')}${';'.repeat(4 - nameParts.length)}\n`;
    }

    // Nickname 
    if (contact.nickname) value += `NICKNAME:${contact.nickname}\n`;

    const description = contact.description ? getElementText(contact.description) : '';

    if (description) value += `NOTE:${description}\n`;

    Object.keys(contact.properties || {}).forEach(key => {
      const values: any = contact.properties?.[key] || [];

      values.forEach((item: any) => {
        const version = item.version!;

        // Email 
        if (version === 'email') value += `EMAIL;TYPE=INTERNET:${item.value}\n`;

        // Mobile, tel, fax 
        if (['mobile', 'tel', 'fax'].includes(version)) value += `TEL;TYPE=${['mobile', 'tel'].includes(version) ? 'CELL' : 'FAX'}:${item.value}\n`;

        // URL 
        if (version === 'url') value += `URL;TYPE=WORK:${item.value}\n`;

        // Address 
        if (version === 'address') value += `ADR;TYPE=home:;;${item.value};;;;\n`;

        // Title 
        if ((version as any) === 'title') value += `TITLE:${item.value}\n`;

        // Company
        if (version === 'company') value += `ORG:${item.value}\n`;

        // Birthday
        if (version === 'birthday') value += `BDAY:${format(new AmauiDate(item.value), `YYYYMMDD`)}\n`;

        // Date 
        if (version === 'date') value += `REV:${new AmauiDate(item.value).iso}\n`;

        // Custom 
        if (version === 'custom') value += `NOTE:${item.value}\n`;
      });
    });

    // End 
    value += `CATEGORIES:myContacts
END:VCARD`;

    values.push(value);
  });

  return values.join('\n');
};

export const getRootPage = (signedIn: any) => {
  const features = signedIn?.user.is;

  if (getSettings(signedIn, 'settings.main') === 'task' && features?.app_task) return '/tasks';

  if (getSettings(signedIn, 'settings.main') === 'note' && features?.app_note) return '/notes';

  if (getSettings(signedIn, 'settings.main') === 'blog' && features?.app_blog) return '/posts';

  if (getSettings(signedIn, 'settings.main') === 'resume' && features?.app_resume) return '/resumes';

  if (getSettings(signedIn, 'settings.main') === 'urlShortener' && features?.app_urlShortener) return '/url-shorteners';

  if (getSettings(signedIn, 'settings.main') === 'website' && features?.app_website) return '/websites';

  if (getSettings(signedIn, 'settings.main') === 'support' && features?.app_support) return '/support-pages';

  if (getSettings(signedIn, 'settings.main') === 'chat' && features?.app_chat) return '/chats';

  if (getSettings(signedIn, 'settings.main') === 'form' && features?.app_form) return '/forms';

  if (getSettings(signedIn, 'settings.main') === 'reservation' && features?.app_reservation) return '/reservations';

  if (getSettings(signedIn, 'settings.main') === 'contact' && features?.app_contact) return '/contacts';

  if (getSettings(signedIn, 'settings.main') === 'workDay' && features?.app_workDay) return '/work-days';

  if (getSettings(signedIn, 'settings.main') === 'location' && features?.app_location) return '/locations';

  return '/';
};

export const collections = ['activityLogs', 'auditLogs', 'analytics', 'availables', 'chats', 'contacts', 'contactGroups', 'events', 'filters', 'forms', 'formResponses', 'integrations', 'invites', 'lists', 'locations', 'medias', 'messages', 'notes', 'notifications', 'organizationPlans', 'organizations', 'otps', 'pages', 'plans', 'portfolios', 'posts', 'projects', 'reservations', 'reservationOptions', 'resumes', 'signIns', 'skills', 'supportArticles', 'supportPages', 'tags', 'tasks', 'templates', 'themes', 'urlShorteners', 'userGroups', 'users', 'versions', 'websites', 'workDays'];

export const nameFromCollection = (value: string, single = true, capitalize_ = true) => {
  let name = value.replace(/([a-z0-9])([A-Z])/g, '$1 $2');

  name = name.toLowerCase();

  if (single) name = name.endsWith('s') ? name.slice(0, -1) : name;

  if (name.includes('url')) name = name.replace('url', 'URL');

  return capitalize_ ? capitalize(name) : name;
};

export const itemFromCollection = (value: string) => {
  let name = value;

  if (name.endsWith('s')) name = name.slice(0, -1);

  return name;
};

export const addPagesOther = ['/resumes', '/portfolios', '/skills', '/websites', '/templates', '/themes', '/support-pages', '/support-articles', '/availables', '/reservation-options', '/reservations', '/contacts', '/contact-groups', '/work-days'];

export const colorOptions: any = {};

Object.keys(colors).forEach(item => {
  const color = (colors[item as 'green'])['A200'] || (colors[item as 'green'])['400'] || colors[item as 'white'];

  colorOptions[item] = {
    name: cleanValue(item, { capitalize: true }),
    color,
    value: item
  };
});

export const optionsLinks = ['youtube', 'vimeo', 'instagram', 'tiktok', 'other'].map(item => ({
  name: cleanValue(item, { capitalize: true }),
  value: item
}));

export const optionsTimeUnit = [
  { name: 'Minute', value: 'minute' },
  { name: 'Hour', value: 'hour' },
  { name: 'Day', value: 'day' },
  { name: 'Week', value: 'week' },
  { name: 'Month', value: 'month' },
  { name: 'Year', value: 'year' }
];

export const dates = ['added_at', 'updated_at', 'received_at', 'removed_at', 'resolved_at', 'starts_at', 'ends_at', 'created'];

export const booleans = ['active', 'archived', 'pinned', 'private', 'default', 'main', 'amaui', 'google', 'resolved'];

export const references = ['contacts', 'contact_groups', 'users'];

export const geolocationPositionOptions = {
  enableHighAccuracy: true,
  timeout: 14e3,
  maximumAge: 4e3
};

export const getCurrentPosition: () => Promise<GeolocationPosition> = () => new Promise(resolve => {
  navigator.geolocation.getCurrentPosition(resolve as any, () => resolve(false as any), geolocationPositionOptions);
});

const itemToURLMap: any = {
  media: '/medias',
  task: '/tasks',
  note: '/notes',
  blog: {
    post: '/posts'
  },
  resume: {
    resume: '/resumes',
    portfolio: '/portfolios',
    skill: '/skills'
  },
  urlShortener: '/url-shorteners',
  website: {
    website: '/websites',
    template: '/templates'
  },
  support: {
    supportPage: '/support-pages',
    supportArticle: '/support-articles'
  },
  chat: '/chats',
  form: '/forms',
  reservation: {
    reservation: '/reservations',
    available: '/availables',
    reservationOption: '/reservation-options'
  },
  contact: {
    contact: '/contacts',
    contactGroup: '/contact-groups'
  },
  workDay: '/work-days',
  location: '/locations'
};

export const getAppRootURL = (app: any, url: any, signedIn: ISignedIn): string => {
  const features: any = signedIn.user.is;

  if (!features[app]) {
    const value = itemToURLMap[app.replace('app_', '')];

    if (is('string', value)) return value;

    for (const item of Object.keys(value)) {
      if (features[item]) return value[item];
    }
  }

  return url;
}; 
