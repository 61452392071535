import React from 'react';

import { debounce, is } from '@amaui/utils';
import { AutoComplete, Chip, FormRow, ListItem, Type, useForm, useSnackbars } from '@amaui/ui-react';
import { ITagsUpdate } from '@amaui/sdk/other';

import IconMaterialAddRounded from '@amaui/icons-material-rounded-react/IconMaterialAdd';

import { ModalForm } from 'ui';
import { AppService, PostService, TagService } from 'services';
import { getErrorMessage } from 'other';

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    objects = [],

    app,

    service,

    onConfirm
  } = props;

  const snackbars = useSnackbars();

  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState<any>(false);

  const form = useForm({
    values: {
      add: {
        name: 'Tags to add',
        is: 'array',
        of: 'object'
      },
      remove: {
        name: 'Tags to remove',
        is: 'array',
        of: 'object'
      }
    }
  });

  const apps = [app];

  const refs = {
    apps: React.useRef(apps),
    form: React.useRef(form),
    autoCompleteInput: React.useRef<any>()
  };

  refs.apps.current = apps;

  refs.form.current = form;

  const queryTags = React.useMemo(() => {
    return debounce(async (value: string) => {
      setLoading('tags');

      const response = await TagService.queryPost({
        query: {
          text: value,

          ...(refs.apps.current && { apps: refs.apps.current })
        }
      });

      if (response.status >= 400) {
        snackbars.add({
          color: 'error',
          primary: getErrorMessage(response)
        });
      }
      else {
        setOptions(response.response.response?.map((item: any) => ({ ...item, value: item.id })));
      }

      setLoading(false);
    }, 140);
  }, []);

  const onAddNewTag = async (valueNew: string) => {
    if (!valueNew) return;

    setLoading('add-new-tag');

    const response = await TagService.add({
      name: valueNew,
      apps: refs.apps.current
    });

    if (response.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(response)
      });
    }
    else {
      const values = [...(refs.form.current.values.add.value || [])];

      const tag = response.response.response;

      values.push({
        id: tag.id,
        value: tag.id,

        name: tag.name,
        color: tag.color
      });

      refs.form.current.onChange('add', values);
    }

    await queryTags('');

    setLoading('add-new-tag');
  };

  const init = React.useCallback(() => {
    queryTags();
  }, [queryTags]);

  React.useEffect(() => {
    init();
  }, []);

  const onClose = React.useCallback(() => {
    // clean up
    form.clear();

    AppService.pages.addTertiary.emit({
      ...AppService.pages.addTertiary.value,

      open: false
    });
  }, []);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();

    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const body: ITagsUpdate = {
      ids: objects.map((item: any) => item.id)
    };

    if (refs.form.current.value.add?.length) body.add = refs.form.current.value.add.map((item: any) => item.id);

    if (refs.form.current.value.remove?.length) body.remove = refs.form.current.value.remove.map((item: any) => item.id);

    const result = await (service as typeof PostService).tags(body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: 'Tags updated'
      });

      onClose();

      if (is('function', onConfirm)) onConfirm();
    }

    setLoading(false);
  }, [form, objects, service, onConfirm, onClose]);

  const tags: any = {};

  objects.forEach((item: any) => {
    if (is('array', item.tags)) {
      item.tags.forEach((tag: any) => {
        if (!tags[tag.id]) tags[tag.id] = tag;
      });
    }
  });

  const tagIDs = Object.keys(tags);

  const tagIDsAllInclude = tagIDs
    .map((id: any) => {
      if (objects.every((object: any) => !!object.tags?.find((tag_: any) => tag_.id === id))) return id;

      return null;
    })
    .filter(Boolean);

  const optionsAdd = options
    .filter((option: any) => !tagIDsAllInclude.includes(option.id))
    .map((option: any) => ({
      ...option,

      value: option.id
    }));

  const optionsRemove = Object.keys(tags)
    .map((item: any) => tags[item])
    .map((option: any) => ({
      ...option,

      value: option.id
    }));

  const autoCompleteProps: any = {
    autoWidth: false,

    MenuProps: {
      portal: true
    }
  };

  const modal: any = {
    write: <>
      <FormRow
        name='Add'
      >
        <AutoComplete
          name='Tags to add'

          value={form.values.add.value}

          onChange={(valueNew: any[]) => form.onChange('add', valueNew)}

          onChangeInput={(valueNew: string) => {
            refs.autoCompleteInput.current = valueNew;

            queryTags(valueNew);
          }}

          renderOption={(item: any, index: number, props: any) => (
            <ListItem
              key={index}

              {...props}

              primary={(
                <Chip
                  color={item.color}

                  size='small'
                >
                  {item.name}
                </Chip>
              )}
            />
          )}

          renderChip={(item: any, valueChip: any, propsChip: any) => {
            return (
              <Chip
                {...propsChip}

                color={item?.color}

                size='small'
              >
                {valueChip}
              </Chip>
            );
          }}

          noOptionsElement={refs.autoCompleteInput.current ? (
            <ListItem
              start={(
                <IconMaterialAddRounded
                  size='small'
                />
              )}

              startAlign='center'

              primary={(
                <Type
                  version='b3'
                >
                  Add '{refs.autoCompleteInput.current}' tag
                </Type>
              )}

              onClick={() => onAddNewTag(refs.autoCompleteInput.current)}

              size='small'

              disabled={loading === 'add-new-tag'}

              button
            />
          ) : undefined}

          noOptions={!!refs.autoCompleteInput.current}

          options={optionsAdd}

          clearInputOnSelect

          fullWidth

          multiple

          chip

          {...autoCompleteProps}
        />
      </FormRow>

      <FormRow
        name='Remove'
      >
        <AutoComplete
          name='Tags to remove'

          value={form.values.remove.value}

          onChange={(valueNew: any[]) => form.onChange('remove', valueNew)}

          renderOption={(item: any, index: number, props: any) => (
            <ListItem
              key={index}

              {...props}

              primary={(
                <Chip
                  color={item.color}

                  size='small'
                >
                  {item.name}
                </Chip>
              )}
            />
          )}

          renderChip={(item: any, valueChip: any, propsChip: any) => {
            return (
              <Chip
                {...propsChip}

                color={item?.color}

                size='small'
              >
                {valueChip}
              </Chip>
            );
          }}

          options={optionsRemove}

          clearInputOnSelect

          fullWidth

          multiple

          chip

          {...autoCompleteProps}
        />
      </FormRow>
    </>
  };

  return (
    <ModalForm
      {...props}

      name='Update tags'

      add

      {...modal}

      onSubmit={onSubmit}

      onNext={onSubmit}

      onClose={onClose}

      loading={loading}

      smaller
    />
  );
});

export default Element;
