import React from 'react';

import { Button, Type, useConfirm, useSnackbars, useSubscription } from '@amaui/ui-react';

import { FormSignIn, Page } from 'ui';
import { AuthService, SignInService, StorageService } from 'services';
import { ISignedIn, getErrorMessage } from 'other';

const cleanValue = (value_: string) => {
  let value = value_;

  ['-', '_'].forEach(filter => {
    const expression = `\\${filter}`;
    const regexp = new RegExp(expression, 'g');

    value = value ? value.replace(regexp, ' ') : '';
  });

  return `${value.charAt(0).toUpperCase()}${value.slice(1)}`;
};

const SignIns = React.forwardRef(() => {
  const confirm = useConfirm();
  const snackbars = useSnackbars();

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const refs = {
    current: React.useRef(StorageService.get('device-id'))
  };

  const onInactivateAll = React.useCallback(async () => {
    const confirmed = await confirm.open({
      name: 'Inactivate all sign ins',
      description: `You are about to inactivate all sign ins, are you sure?`
    });

    if (!confirmed) return;

    const result = await SignInService.inactivateAll();

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `All sign ins inactivated`
      });
    }

    // refetch
    SignInService.queryItems.value!.refetch();
  }, []);

  const onRemoveAll = React.useCallback(async () => {
    const confirmed = await confirm.open({
      name: 'Remove all sign ins',
      description: `You are about to remove all sign ins, are you sure?`
    });

    if (!confirmed) return;

    const result = await SignInService.removeAll();

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `All sign ins removed`
      });
    }

    // refetch
    SignInService.queryItems.value!.refetch();
  }, []);

  const buttonPropsMoreActions: any = {
    version: 'text',
    color: 'inherit',
    size: 'small'
  };

  const properties = React.useMemo(() => {
    const isCurrent = (item: any) => (refs.current.current === item.device && signedIn?.project?.id === item.project?.id);

    return {
      device: {
        name: 'Device',
        method: (item: any, options: any) => {
          const location = item.locations?.[0];

          return (
            <Type
              version='l2'

              onClick={() => options.onOpen(item)}

              data-name
            >
              {location ? `${cleanValue(location?.os?.platform || '')} ${cleanValue(location?.browser?.name || '')}`.trim() || 'Unknown device' : 'Unknown device'}
            </Type>
          );
        },
        view: false
      },
      last_location: {
        name: 'Last location',
        method: (item: any) => {
          const location = item.locations?.[0];

          return location ? `${location?.country?.name || ''}${location?.city ? `, ${location.city.name}` : ''}`.trim() || 'Unknown location' : 'Unknown location';
        }
      },
      current: {
        name: 'Current sign in',
        method: (item: any) => (
          <Type
            color={isCurrent(item) ? 'success' : 'inherit'}
          >
            {isCurrent(item) ? 'Yes' : 'No'}
          </Type>
        ),
        view: false
      },
      project: { name: 'Project', property: 'project.name' },
      organization: { name: 'Organization', property: 'organization.name' },
      active: { name: 'Active' }
    };
  }, []);

  return (
    <Page
      name='Sign ins'

      service={SignInService}

      columns={[
        properties.device,
        properties.last_location,
        properties.current,
        properties.project,
        properties.organization,
        properties.active
      ]}

      properties={properties as any}

      getMoreActions={() => <>
        <Button
          onClick={onInactivateAll}

          {...buttonPropsMoreActions}
        >
          Sign out all devices
        </Button>

        <Button
          onClick={onRemoveAll}

          {...buttonPropsMoreActions}
        >
          Remove all sign ins
        </Button>
      </>}

      search={['active']}

      options={['active']}

      app='amaui'

      collection='signIns'

      route='/settings/sign-ins'

      Form={FormSignIn}

      noTags

      noUpdateProject
    />
  );
});

export default SignIns;
