import React from 'react';

import { IconButton, Line, List, ListItem, Menu, Tooltip, useConfirm, useSnackbars } from '@amaui/ui-react';
import { IConfirmOpen } from '@amaui/ui-react/Confirm/Confirm';
import { classNames, style } from '@amaui/style-react';
import { View } from '@amaui/api-utils';

import IconMaterialCheckCircleRounded from '@amaui/icons-material-rounded-react/IconMaterialCheckCircle';
import IconMaterialViewColumnRounded from '@amaui/icons-material-rounded-react/IconMaterialViewColumn';
import IconMaterialEditRounded from '@amaui/icons-material-rounded-react/IconMaterialEdit';
import IconMaterialAddRounded from '@amaui/icons-material-rounded-react/IconMaterialAdd';
import IconMaterialDeleteRounded from '@amaui/icons-material-rounded-react/IconMaterialDelete';

import { FormView, Button, useSubscription } from 'ui';
import { AppService, ViewService } from 'services';
import { IQuerySubscription, getErrorMessage } from 'other';

const useStyle = style(theme => ({
  root: {

  },

  add: {
    '&.amaui-Button-root': {
      marginTop: '8px !important'
    }
  },

  addEmpty: {
    '&.amaui-Button-root': {
      marginTop: 0
    }
  },

  list: {
    '&.amaui-List-root': {
      minWidth: 214,
      maxWidth: '270px !important',
      maxHeight: 240,
      overflowY: 'auto',

      '& .amaui-ListItem-middle': {
        margin: 0
      }
    }
  }
}), { name: 'amaui-app-Views' });

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    model,

    query,

    properties,

    // className,

    // ...other
  } = props;

  const { classes } = useStyle();

  const snackbars = useSnackbars();
  const confirm = useConfirm();

  const [loading, setLoading] = React.useState(false);

  const queryItems = useSubscription<IQuerySubscription>(ViewService.queryItems);

  const onConfirm = React.useCallback(() => {
    ViewService.queryItems.value!.refetch.bind(ViewService.queryItems.value)();
  }, []);

  const onSelect = React.useCallback(async (item: any, selected = true) => {
    setLoading(true);

    const body = {
      value: {
        ...item?.value,

        selected
      }
    };

    const result = await ViewService.update(item?.id, body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `View updated`
      });

      // refresh 
      onConfirm();
    }

    setLoading(false);
  }, [model]);

  const onRemove = React.useCallback(async (view: View, event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    const confirmed = await (confirm.open as (value: IConfirmOpen) => Promise<any>)({
      name: 'Remove a view',
      description: 'Are you sure you want to remove this view?'
    });

    if (!confirmed) return;

    const result = await ViewService.remove(view.id);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else onConfirm();
  }, []);

  const onAdd = React.useCallback(() => {
    AppService.pages.addTertiary.emit({
      open: true,
      version: 'mid',
      minWidth: 'rg',
      children: (
        <FormView
          onConfirm={onConfirm}

          model={model}

          properties={properties}
        />
      )
    });
  }, [AppService.pages.addTertiary, query, model, properties]);

  const onUpdate = React.useCallback((item: any) => {
    AppService.pages.addTertiary.emit({
      open: true,
      version: 'mid',
      minWidth: 'rg',
      children: (
        <FormView
          onConfirm={onConfirm}

          object={item}

          model={model}

          properties={properties}
        />
      )
    });
  }, [AppService.pages.addTertiary, query, model, properties]);

  const listItemProps: any = {
    menuCloseOnClick: true,

    PrimaryProps: {
      version: 'b3'
    }
  };

  const iconButtonProps: any = {
    size: 'small',
    disabled: loading
  };

  const menuList = (queryItems?.response || []).map((item: any, index: number) => {
    const selected = item.value?.selected;

    return (
      <ListItem
        key={index}

        primary={item?.name}

        end={<>
          <Tooltip
            name={selected ? 'Unselect' : 'Select'}
          >
            <IconButton
              onClick={() => onSelect(item, !selected)}

              {...iconButtonProps}
            >
              <IconMaterialCheckCircleRounded color={selected ? 'success' : 'inherit'} />
            </IconButton>
          </Tooltip>

          <Tooltip
            name='Update'

            color='secondary'
          >
            <IconButton
              onClick={() => onUpdate(item)}

              {...iconButtonProps}
            >
              <IconMaterialEditRounded />
            </IconButton>
          </Tooltip>

          <Tooltip
            name='Remove'

            color='secondary'
          >
            <IconButton
              onClick={(event: any) => onRemove(item, event)}

              {...iconButtonProps}
            >
              <IconMaterialDeleteRounded />
            </IconButton>
          </Tooltip>
        </>}

        {...listItemProps}
      />
    );
  });

  return <>
    <Menu
      name={(methodItem: any) => (
        <Line
          gap={0}

          align='center'

          fullWidth
        >
          {!!menuList.length && (
            <List
              menu

              className={classes.list}

              size='small'
            >
              {menuList.map((item: any, index: number) => (
                React.cloneElement(item, methodItem(item, index))
              ))}
            </List>
          )}

          <Button
            size='small'

            color='default'

            onClick={onAdd}

            className={classNames([
              classes.add,
              classes.addEmpty
            ])}
          >
            <IconMaterialAddRounded
              size='small'
            />

            Add new view
          </Button>
        </Line>
      )}

      ListProps={{
        className: classes.list,
        size: 'small'
      }}
    >
      <span>
        <Tooltip
          name='Views'
        >
          <IconButton
            size='small'
          >
            <IconMaterialViewColumnRounded />
          </IconButton>
        </Tooltip>
      </span>
    </Menu>
  </>;
});

export default Element;
