import React from 'react';

import { arrayMoveItem, copy, is, isValid, slugify, textToInnerHTML } from '@amaui/utils';
import { IconButton, Label, Line, List, ListItem, SmartTextField, Switch, Tooltip, Type, useForm, useSnackbars } from '@amaui/ui-react';
import { style } from '@amaui/style-react';

import IconMaterialKeyboardArrowUpRounded from '@amaui/icons-material-rounded-react/IconMaterialKeyboardArrowUp';
import IconMaterialKeyboardArrowDownRounded from '@amaui/icons-material-rounded-react/IconMaterialKeyboardArrowDown';
import IconMaterialAddRounded from '@amaui/icons-material-rounded-react/IconMaterialAdd';
import IconMaterialDeleteRounded from '@amaui/icons-material-rounded-react/IconMaterialDelete';
import IconMaterialRemoveRounded from '@amaui/icons-material-rounded-react/IconMaterialRemove';
import IconMaterialRefreshRounded from '@amaui/icons-material-rounded-react/IconMaterialRefresh';

import { AutoCompleteObjects, ModalForm, OptionsSEO, OptionsShare, OptionsWebsites, PageBuilder, TextField } from 'ui';
import { AppService, SupportArticleService, SupportPageService } from 'services';
import { getErrorMessage } from 'other';

const useStyle = style(theme => ({
  root: {

  },

  supportPage: {
    height: 0,
    overflow: 'hidden auto',
    maxWidth: theme.maxWidth,
    padding: 24
  },

  section: {
    padding: '16px 0',
    overflow: 'hidden'
  },

  sectionName: {
    width: '100%'
  },

  ...theme.classes(theme)
}), { name: 'amaui-app-SupportPage' });

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    object: object_,

    // template,

    onConfirm
  } = props;

  const { classes } = useStyle();

  const snackbars = useSnackbars();

  const [object, setObject] = React.useState(object_);
  const [tab, setTab] = React.useState(object ? 'Support page' : 'Options');
  const [loading, setLoading] = React.useState<any>(false);

  const form = useForm({
    values: {
      'name': {
        name: 'Name',
        value: object?.name,
        max: 1400,
        messages: {
          min: 'Name has to be min 1 characters',
          max: 'Name can be max 1400 characters'
        }
      },
      'short_description': {
        name: 'Short description',
        value: object?.short_description,
        max: 4400,
        messages: {
          min: 'Short description has to be min 1 characters',
          max: 'Short description can be max 4400 characters'
        }
      },
      'sections': {
        name: 'Sections',
        value: object?.sections,
        is: 'array'
      },
      'seo.name': {
        name: 'SEO name',
        value: object?.seo?.name,
        min: 1,
        max: 70,
        messages: {
          min: 'SEO name has to be min 1 characters',
          max: 'SEO name can be max 70 characters'
        }
      },
      'seo.description': {
        name: 'SEO description',
        value: object?.seo?.description,
        min: 1,
        max: 164,
        messages: {
          min: 'SEO description has to be min 1 characters',
          max: 'SEO description can be max 164 characters'
        }
      },
      'seo.image': {
        name: 'SEO image',
        value: object?.seo?.image
      },
      'websites': {
        name: 'Websites',
        value: object?.websites,
        is: 'array',
        of: 'object'
      },
      'options.share.active': {
        name: 'Options share active',
        value: object?.options?.share?.active,
        is: 'boolean'
      }
    }
  });

  const refs = {
    root: React.useRef<HTMLElement>(),
    value: React.useRef<HTMLElement>(),
    supportPage: React.useRef<HTMLElement>(),
    form: React.useRef(form),
    onConfirm: React.useRef<any>(onConfirm)
  };

  refs.form.current = form;

  refs.onConfirm.current = onConfirm;

  const valueDefault = copy(object?.value);

  const initInnerHTML = React.useCallback(() => {
    if (refs.supportPage.current) {
      const elements = Array.from(refs.supportPage.current!.querySelectorAll(`[data-version='title']`)) as HTMLElement[];

      const sections = refs.form.current.values.sections?.value || [];

      elements.forEach((element: any, index: number) => {
        const section = sections[element.dataset.index];

        if (section) {
          element.innerHTML = textToInnerHTML(section.name);

          element.dataset.loaded = 'true';
        }
      });
    }
  }, []);

  const init = React.useCallback(() => {
    if (object?.id) {
      if (is('array', object?.websites)) object.websites = object.websites.map((objectWebsite: any) => ({
        ...objectWebsite,

        value: objectWebsite?.id
      }));

      refs.form.current.onChange([
        ['name', object?.name],
        ['short_description', object?.short_description],
        ['url', object?.url],
        ['sections', (object?.sections || []).map((item: any) => ({
          ...item,

          articles: (item.articles || []).map((supportArticle: any) => ({
            ...supportArticle,

            name: textToInnerHTML(supportArticle?.name),

            value: supportArticle.id
          }))
        }))],
        ['seo.name', object?.seo?.name],
        ['seo.description', object?.seo?.description],
        ['seo.image', object?.seo?.image],
        ['websites', object.websites || []]
      ]);
    }

    setTimeout(() => {
      initInnerHTML();
    }, 14);
  }, [object, valueDefault]);

  React.useEffect(() => {
    // init
    init();
  }, []);

  const onClose = React.useCallback(() => {
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });
  }, []);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();
  }, []);

  const onNext = React.useCallback(async () => {
    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const body = {
      ...refs.form.current.value
    };

    if (is('array', body.sections)) body.sections = body.sections.map((item: any) => ({
      id: item.id,
      name: item.name,
      articles: (item.articles || []).filter(Boolean).map((supportArticle: any) => ({
        id: supportArticle?.id,
        name: supportArticle?.name
      })),
      props: item.props
    }));

    if (is('array', body.websites)) body.websites = body.websites.map((item: any) => ({
      id: item.id,
      name: item.name
    }));

    const result = !object?.id ? await SupportPageService.add(body) : await SupportPageService.update(object?.id, body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Support page ${!object?.id ? 'added' : 'updated'}`
      });

      setObject(result.response.response);

      if (is('function', onConfirm)) onConfirm();

      if (!object?.id) onClose();
    }

    setLoading(false);
  }, [object, isValid, form, onConfirm, onClose]);

  const onRefreshOptions = React.useCallback(async (override = true) => {
    await refs.form.current.validate();

    if (refs.form.current.values.name?.value) {
      if (
        override ||
        !refs.form.current.values.url?.value
      ) {
        refs.form.current.onChange('url', slugify(refs.form.current.values.name?.value));
      }

      if (
        override ||
        !refs.form.current.values['seo.name']?.value
      ) {
        refs.form.current.onChange('seo.name', refs.form.current.values.name?.value);
      }
    }

    if (refs.form.current.values.short_description?.value) {
      if (
        override ||
        !refs.form.current.values['seo.description']?.value
      ) {
        refs.form.current.onChange('seo.description', refs.form.current.values.short_description?.value);
      }
    }
  }, []);

  const onAddSection = React.useCallback(() => {
    const sections = copy((refs.form.current.values.sections.value || []));

    const section = {
      name: `Section ${sections.length + 1}`,
      articles: [],
      props: {
        openDefault: false
      }
    };

    sections.push(section);

    refs.form.current.onChange('sections', sections);

    setTimeout(() => {
      initInnerHTML();
    }, 14);
  }, []);

  const onChangeSectionName = React.useCallback((valueNew: any, index: number) => {
    const sections = copy((refs.form.current.values.sections.value || []));

    if (sections[index]) {
      sections[index].name = valueNew;
    }

    refs.form.current.onChange('sections', sections);
  }, []);

  const onChangeSectionProps = React.useCallback((index: number, name: string, valueNew: any) => {
    const sections = copy((refs.form.current.values.sections.value || []));

    const section = sections[index];

    if (section) {
      if (!section.props) section.props = {};

      section.props[name] = valueNew;
    }

    refs.form.current.onChange('sections', sections);
  }, []);

  const onAddSupportArticle = React.useCallback((index: number, valueNew: any[]) => {
    const sections = copy((refs.form.current.values.sections.value || []));

    if (index > -1) sections[index].articles = valueNew;

    refs.form.current.onChange('sections', sections);
  }, []);

  const onMoveSupportArticle = React.useCallback((indexSection: number, index: number, up = true) => {
    const sections = copy((refs.form.current.values.sections.value || []));

    const section = sections[indexSection];

    if (section) arrayMoveItem(section.articles, index, up ? index - 1 : index + 1);

    refs.form.current.onChange('sections', sections);

    setTimeout(() => {
      initInnerHTML();
    }, 14);
  }, []);

  const onRemoveSupportArticle = React.useCallback((indexSection: number, index: number) => {
    const sections = copy((refs.form.current.values.sections.value || []));

    const section = sections[indexSection];

    if (section) {
      if (index > -1) section.articles.splice(index, 1);
    }

    refs.form.current.onChange('sections', sections);

    setTimeout(() => {
      initInnerHTML();
    }, 14);
  }, []);

  const onMove = React.useCallback((index: number, up = true) => {
    const sections = copy((refs.form.current.values.sections.value || []));

    if (index > -1) arrayMoveItem(sections, index, up ? index - 1 : index + 1);

    refs.form.current.onChange('sections', sections);

    setTimeout(() => {
      initInnerHTML();
    }, 14);
  }, []);

  const onRemove = React.useCallback((index: number) => {
    const sections = copy((refs.form.current.values.sections.value || []));

    if (index > -1) sections.splice(index, 1);

    refs.form.current.onChange('sections', sections);

    setTimeout(() => {
      initInnerHTML();
    }, 14);
  }, []);

  const onChangeTab = React.useCallback(async (value: any) => {
    await refs.form.current.validate();

    setTab(value);
  }, []);

  const iconButtonProps: any = {

  };

  const iconButtonSupportArticleProps: any = {
    size: 'small'
  };

  const modal: any = {
    'Support page': <>
      <Line
        ref={refs.supportPage}

        align='unset'

        justify='unset'

        flex

        fullWidth

        className={classes.page}
      >
        <Line
          gap={1}

          direction='row'

          align='center'

          justify='flex-end'

          fullWidth
        >
          <Tooltip
            name='Add section'
          >
            <IconButton
              onClick={onAddSection}
            >
              <IconMaterialAddRounded />
            </IconButton>
          </Tooltip>
        </Line>

        <Line
          gap={1.5}

          fullWidth
        >
          {form.values.sections.value?.map((item: any, index: number) => (
            <Line
              key={index}

              gap={2}

              fullWidth

              className={classes.section}
            >
              <Line
                gap={1}

                direction={{
                  xxs: 'column',
                  xs: 'column',
                  sm: 'column',
                  default: 'row'
                }}

                align='flex-start'

                justify='space-between'

                fullWidth
              >
                <SmartTextField
                  placeholder='Title'

                  valueDefault={textToInnerHTML(item.name) || ''}

                  onChange={(valueNew: any) => onChangeSectionName(valueNew as any, index)}

                  additional={{
                    version: 't2'
                  }}
                />

                <Line
                  gap={0}

                  direction='row'

                  align='center'

                  justify='flex-end'

                  style={{
                    flex: '0 0 auto'
                  }}
                >
                  <Label
                    checked={!!form.values.sections.value?.[index]?.props?.openDefault}

                    onChange={(valueNew: any) => onChangeSectionProps(index, 'openDefault', valueNew)}

                    style={{
                      marginRight: 8
                    }}
                  >
                    <Switch />

                    Open by default
                  </Label>

                  <Tooltip
                    name='Move up'
                  >
                    <IconButton
                      onClick={() => onMove(index)}

                      disabled={index === 0}

                      {...iconButtonProps}
                    >
                      <IconMaterialKeyboardArrowUpRounded />
                    </IconButton>
                  </Tooltip>

                  <Tooltip
                    name='Move down'
                  >
                    <IconButton
                      onClick={() => onMove(index, false)}

                      disabled={form.values.sections.value?.length - 1 === index}

                      {...iconButtonProps}
                    >
                      <IconMaterialKeyboardArrowDownRounded />
                    </IconButton>
                  </Tooltip>

                  <Tooltip
                    name='Remove'
                  >
                    <IconButton
                      onClick={() => onRemove(index)}

                      {...iconButtonProps}
                    >
                      <IconMaterialDeleteRounded />
                    </IconButton>
                  </Tooltip>
                </Line>
              </Line>

              <Line
                gap={1}

                fullWidth
              >
                <AutoCompleteObjects
                  name='Support articles'

                  value={form.values.sections.value?.[index]?.articles}

                  onChange={(valueNew: any[]) => onAddSupportArticle(index, valueNew)}

                  service={SupportArticleService}

                  multiple

                  chip
                />

                {!!form.values.sections.value?.[index]?.articles?.filter(Boolean)?.length && (
                  <Line
                    fullWidth
                  >
                    <List
                      noBackground
                    >
                      {form.values.sections.value?.[index]?.articles?.filter(Boolean)?.map((supportArticle: any, index_: number) => (
                        <ListItem
                          key={index}

                          primary={(
                            <Type
                              version='l3'
                            >
                              {supportArticle?.name}
                            </Type>
                          )}

                          end={(
                            <Line
                              gap={0}

                              direction='row'

                              align='center'
                            >
                              <Tooltip
                                name='Move up'
                              >
                                <IconButton
                                  onClick={() => onMoveSupportArticle(index, index_)}

                                  disabled={index_ === 0}

                                  {...iconButtonSupportArticleProps}
                                >
                                  <IconMaterialKeyboardArrowUpRounded />
                                </IconButton>
                              </Tooltip>

                              <Tooltip
                                name='Move down'
                              >
                                <IconButton
                                  onClick={() => onMoveSupportArticle(index, index_, false)}

                                  disabled={form.values.sections.value?.[index]?.articles?.length - 1 === index_}

                                  {...iconButtonSupportArticleProps}
                                >
                                  <IconMaterialKeyboardArrowDownRounded />
                                </IconButton>
                              </Tooltip>

                              <Tooltip
                                name='Remove'
                              >
                                <IconButton
                                  onClick={() => onRemoveSupportArticle(index, index_)}

                                  {...iconButtonSupportArticleProps}
                                >
                                  <IconMaterialRemoveRounded />
                                </IconButton>
                              </Tooltip>
                            </Line>
                          )}

                          size='small'

                          button

                          noBackground
                        />
                      ))}
                    </List>
                  </Line>
                )}
              </Line>
            </Line>
          ))}
        </Line>
      </Line>
    </>,

    'Options': <>
      <Line
        fullWidth

        className={classes.page}
      >
        <Line
          direction='row'

          align='center'

          justify='flex-end'

          fullWidth

          className={classes.paddingSmall}

          style={{
            paddingInline: 0
          }}
        >
          <Tooltip
            name='Refresh'
          >
            <IconButton
              onClick={onRefreshOptions}
            >
              <IconMaterialRefreshRounded />
            </IconButton>
          </Tooltip>
        </Line>

        <Line
          gap={1}

          fullWidth
        >
          <TextField
            name='Name'

            valueDefault={textToInnerHTML(form.values['name'].value)}

            onChange={(valueNew: any) => form.onChange('name', valueNew, undefined, { rerenderOnUpdate: false })}
          />

          <SmartTextField
            placeholder='Description'

            valueDefault={textToInnerHTML(form.values['short_description'].value || '')}

            onChange={(valueNew: any) => form.onChange('short_description', valueNew, undefined, { rerenderOnUpdate: false })}

            minRows={4}

            multiline

            edit
          />
        </Line>

        <OptionsWebsites
          description='Websites this support page can be visible at'

          value={form.values.websites.value}

          onChange={(valueNew: any[]) => form.onChange('websites', valueNew)}
        />

        <OptionsShare
          form={form}
        />
      </Line>
    </>,

    'SEO': <>
      <Line
        fullWidth

        className={classes.page}
      >
        <OptionsSEO
          app='blog'

          values={form.values}

          onChange={form.onChange}
        />
      </Line>
    </>,

    'Preview': <>
      {(object?.id && object?.active) && (
        <PageBuilder
          mode='preview'

          url={`/support-pages/${object.id}`}

          website={form.value.websites?.[0]}
        />
      )}
    </>
  };

  return (
    <ModalForm
      {...props}

      object={object}

      add

      tabDefault='Support page'

      tab={tab}

      tabs={['Support page', 'Options', 'SEO', ...((object?.id && object?.active) ? ['Preview'] : [])]}

      onChangeTab={onChangeTab}

      onSubmit={onSubmit}

      onNext={onNext}

      onClose={onClose}

      loading={loading}
    >
      {modal[tab]}
    </ModalForm>
  );
});

export default Element;
