import React from 'react';

import { FormWebsite, Page } from 'ui';
import { WebsiteService } from 'services';

const Websites = React.forwardRef(() => {

  const properties = React.useMemo(() => ({
    name: { name: 'Name' },
    short_description: { name: 'Summary' },
    description: { name: 'Description' },
    domain: { name: 'Domain' },
    'theme.name': { name: 'Theme' },
    active: { name: 'Active' },
    archived: { name: 'Archived' }
  }), []);

  return (
    <Page
      name='Websites'

      service={WebsiteService}

      columns={[
        properties.name,
        properties.active,
        properties.archived
      ]}

      search={['active', 'archived']}

      options={['active', 'archived']}

      properties={properties}

      app='website'

      collection='websites'

      route='/websites'

      Form={FormWebsite}

      onOpenNavigate

      updateEntire
    />
  );
});

export default Websites;
