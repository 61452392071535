import React from 'react';
import audioFix from 'webm-duration-fix';

import { insertTextAtSelection, is, startSelection, textToInnerHTML } from '@amaui/utils';
import { AudioRecorder, Emojis, IconButton, Line, Tooltip, Type, useMediaQuery, useSnackbars } from '@amaui/ui-react';
import { IEmoji } from '@amaui/ui-react/Emojis/Emojis';
import { classNames, style } from '@amaui/style-react';
import { AmauiDate, format } from '@amaui/date';
import { IMedia, IMessage } from '@amaui/api-utils';

import IconMaterialMoodRounded from '@amaui/icons-material-rounded-react/IconMaterialMood';
import IconMaterialImageRounded from '@amaui/icons-material-rounded-react/IconMaterialImage';
import IconMaterialCloseRounded from '@amaui/icons-material-rounded-react/IconMaterialClose';

import { Button, Limited, SmartTextField, useMedia, useSubscription } from 'ui';
import { ISignedIn, formats, getErrorMessage, getMediaUrl, isLimited, mediasToValue } from 'other';
import { AuthService, MediaService } from 'services';
import Messages from './Messages';

const useStyle = style(theme => ({
  root: {
    padding: '4px 0 12px',

    '& .amaui-TextField-icon': {
      paddingBlock: 12
    }
  },

  input: {

  },

  input_mobile: {
    padding: '0px 12px'
  },

  replyTo: {
    border: `1px solid ${theme.palette.text.default.secondary}`,
    padding: '4px 8px 17px 12px',
    borderBottom: 'none',
    borderRadius: '12px 12px 0 0',
    marginBottom: -7
  },

  replyToMessage: {
    '&.amaui-Type-root': {
      width: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  },

  more: {
    flex: '0 0 auto'
  }
}), { name: 'amaui-app-element-Chat' });

const Chat = React.forwardRef((props: any, ref: any) => {
  const {
    new: new_,

    messages,

    onMessageAdd: onMessageAdd_,

    start,

    end,

    replyTo: replyTo_,

    loading: loading_,

    Messages: Messages_,
    Input: Input_,

    mediaAllowed,

    noInput,
    noReactions,
    noReplyTo,

    noEmojis,
    noAudioRecording,
    noMedia,

    onReactionAdd,
    onReactionRemove,
    onReplyTo,

    MessagesProps,

    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const touch = useMediaQuery('(pointer: coarse)');
  const snackbars = useSnackbars();
  const media = useMedia();

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const [message, setMessage] = React.useState('');
  const [replyTo, setReplyTo] = React.useState<IMessage>(replyTo_);
  const [loading, setLoading] = React.useState<any>(loading_ !== undefined ? loading_ : false);

  const refs = {
    inputRef: React.useRef<any>(),
    signedIn: React.useRef(signedIn),
    message: React.useRef(message)
  };

  refs.signedIn.current = signedIn;

  refs.message.current = message;

  React.useEffect(() => {
    if (loading_ !== undefined && loading_ !== loading) setLoading(loading_);
  }, [loading_]);

  React.useEffect(() => {
    if (replyTo_ !== undefined && replyTo_ !== replyTo) setReplyTo(replyTo_);
  }, [replyTo_]);

  const onChangeMessage = React.useCallback((valueNew: string) => {
    setMessage(valueNew);
  }, []);

  const onClear = React.useCallback(() => {
    setMessage('');
  }, []);

  const onClearReplyTo = React.useCallback(() => {
    setReplyTo(null as any);
  }, []);

  const onMessageAdd = React.useCallback(async (version: string, valueNew: any) => {
    if (is('function', onMessageAdd_)) {
      const response = await onMessageAdd_(version, valueNew);

      if (response) onClear();
    }
  }, [onMessageAdd_]);

  const onMessageKeyDown = React.useCallback((event: KeyboardEvent) => {
    if (event.key === 'Enter' && !(event.metaKey || event.ctrlKey || event.altKey || event.shiftKey)) {
      onMessageAdd('text', refs.message.current);
    }
  }, []);

  const onEmojisSelect = React.useCallback(async (emoji: IEmoji) => {
    if (!message) {
      setMessage(textToInnerHTML(emoji.unicode));

      setTimeout(() => {
        startSelection(refs.inputRef.current);
      }, 14);
    }
    else {
      const selection = window.getSelection();

      if (selection?.anchorNode?.parentElement !== refs.inputRef.current) startSelection(refs.inputRef.current);

      insertTextAtSelection(emoji.unicode);
    }
  }, [message]);

  const messagesUI = (
    <Messages
      messages={messages}

      {...MessagesProps}

      MessageProps={{
        noReactions,
        noReplyTo,

        onReactionAdd,
        onReactionRemove,
        onReplyTo,

        ...MessagesProps?.MessageProps,

      }}
    />
  );

  const messageValid = !!(message?.trim());

  const replyToValue = replyTo?.value?.[0];

  const replyToUI = replyTo && (
    <Line
      gap={0}

      fullWidth

      className={classes.replyTo}
    >
      <Line
        gap={1}

        direction='row'

        align='center'

        justify='space-between'

        fullWidth
      >
        <Type
          version='b3'

          priority='secondary'
        >
          Replying to {replyTo?.user?.id === signedIn.user.id ? 'yourself' : replyTo.user!.name}
        </Type>

        <Tooltip
          name='Clear reply to'
        >
          <IconButton
            onClick={onClearReplyTo}

            size='small'
          >
            <IconMaterialCloseRounded />
          </IconButton>
        </Tooltip>
      </Line>

      <Type
        version='b2'

        dangerouslySetInnerHTML={{
          __html: textToInnerHTML(replyToValue?.version === 'type' ? replyToValue.value : 'Media')
        }}

        className={classes.replyToMessage}
      />
    </Line>
  );

  const onAudioRecorderConfirm = React.useCallback(async (value: Blob) => {
    setLoading('voice recording');

    const media = await audioFix(value);

    // validate
    // 140 mb maximum
    if (media.size > (140 * 1e6)) {
      snackbars.add({
        color: 'error',
        primary: `Maximum allowed file size is 140 mb`
      });

      return;
    }

    const name = `Voice recording, ${format(new AmauiDate(), formats.entire)}`;

    // meta
    const meta: any = {};

    const result = await MediaService.add({
      name,
      meta,

      app: 'note',

      // media
      media
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      const mediaMongo = result?.response?.response;

      const value = [
        {
          version: 'audio',
          props: {
            id: mediaMongo.id,

            ...(mediasToValue({
              id: mediaMongo?.id,
              name,
              mime: media.type,
              meta: mediaMongo?.meta,
              versions: mediaMongo?.versions,
              added_at: AmauiDate.milliseconds
            })),

            duration: mediaMongo?.meta?.duration
          }
        }
      ];

      onMessageAdd('media', value);
    }

    setLoading(false);
  }, []);

  const onMediaConfirm = React.useCallback((valueNew: IMedia) => {
    let value: any = [];

    const item = valueNew;

    if (item) {
      const url = item ? getMediaUrl(item) : '';

      if (item.mime?.includes('image')) value = [
        {
          version: 'image',
          props: {
            id: item.id,

            name: item.name,

            url: url,
            urlSmall: item ? getMediaUrl(item, 480) : ''
          }
        }
      ];

      if (item.mime?.includes('audio')) value = [
        {
          version: 'audio',
          props: {
            id: item.id,

            name: item.name,

            url,

            ...(mediasToValue({
              mime: item?.mime,
              meta: item?.meta,
              versions: item?.versions
            })),

            duration: item?.meta?.duration
          }
        }
      ];

      if (item.mime?.includes('video')) value = [
        {
          version: 'video',
          props: {
            id: item.id,

            name: item.name,

            url,

            ...(mediasToValue({
              mime: item?.mime,
              meta: item?.meta,
              versions: item?.versions,
              thumbnails: item?.thumbnails
            })),

            duration: item?.meta?.duration
          }
        }
      ];

      onMessageAdd('media', value);
    }
  }, [onMessageAdd]);

  const onMedia = React.useCallback(() => {
    media.open({
      allowed: mediaAllowed || ['image', 'audio', 'video'],
      filters: {
        mime: 'all'
      },
      multiple: false,
      selected: [],
      onConfirm: onMediaConfirm
    });
  }, [media, onMediaConfirm]);

  const SmartTextFieldUI = (
    <SmartTextField
      version='outlined'

      value={message}

      onChange={onChangeMessage}

      onKeyDown={onMessageKeyDown}

      name='Message'

      minRows={1}

      maxRows={4}

      enabled={messageValid || undefined}

      inputProps={{
        ref: refs.inputRef
      }}

      additional={{
        version: 'outlined'
      }}

      start={(!noEmojis) && (
        <Line
          gap={1}

          direction='row'

          align='center'

          justify='flex-start'
        >
          {!noEmojis && (
            <Emojis
              position='top'

              alignment='start'

              onSelect={onEmojisSelect}
            >
              <IconButton
                size='small'
              >
                <IconMaterialMoodRounded />
              </IconButton>
            </Emojis>
          )}
        </Line>
      )}

      end={(
        <Line
          gap={1}

          direction='row'

          align='center'

          justify='flex-end'
        >
          {(!noAudioRecording || !noMedia) && (
            <Line
              gap={0.25}

              direction='row'

              align='center'

              className={classes.more}
            >
              {!noAudioRecording && (
                <AudioRecorder
                  onConfirm={onAudioRecorderConfirm}

                  disabled={loading}
                />
              )}

              {!noMedia && (
                <Tooltip
                  name='Media'
                >
                  <IconButton
                    onClick={onMedia}

                    size='small'
                  >
                    <IconMaterialImageRounded />
                  </IconButton>
                </Tooltip>
              )}
            </Line>
          )}

          <Button
            onClick={() => onMessageAdd('text', message)}

            size='small'

            disabled={!messageValid}
          >
            Send
          </Button>
        </Line>
      )}

      noEnter

      disabled={loading === 'add-message'}

      className={classes.input}
    />
  );

  const limited = isLimited(signedIn, new_ ? 'chat' : 'message');

  return (
    <Line
      ref={ref}

      gap={1}

      align='center'

      justify='center'

      flex

      fullWidth

      className={classNames([
        className,
        classes.root
      ])}

      {...other}
    >
      {start}

      {/* Messages  */}
      <Line
        flex

        fullWidth

        className={classes.messagesWrapper}
      >
        {Messages_ || messagesUI}
      </Line>

      {/* Input  */}
      {!noInput && (
        Input_ || (
          <Line
            gap={0}

            fullWidth

            className={classNames([
              classes[touch ? 'input_mobile' : 'input']
            ])}
          >
            {/* reply to */}
            {replyToUI}

            {limited && (
              <Line
                direction='row'

                align='center'

                justify='flex-end'

                fullWidth
              >
                <Limited
                  name={new_ ? 'Chats' : 'Messages'}

                  total={signedIn?.organizationPlan?.provided?.[new_ ? 'chat' : 'message']?.total}

                  tab='Chat'
                />
              </Line>
            )}

            {/* input  */}
            {!limited && SmartTextFieldUI}
          </Line>
        )
      )}

      {end}
    </Line>
  );
});

export default Chat;