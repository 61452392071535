import React from 'react';

import { innerHTMLToText, is, textToInnerHTML } from '@amaui/utils';
import { FormRow, Label, Line, Medias, Switch, useForm, useSnackbars } from '@amaui/ui-react';

import { LocationForm, ModalForm, ReadProperties, SelectAColor, SelectMedia, SmartTextField, TextField } from 'ui';
import { AppService, LocationService } from 'services';
import { getErrorMessage } from 'other';

const Element = React.forwardRef((props: any) => {
  const {
    object: object_,

    onConfirm
  } = props;

  const snackbars = useSnackbars();

  const [object, setObject] = React.useState(object_);
  const [loading, setLoading] = React.useState<any>(false);

  const form = useForm({
    values: {
      'name': {
        name: 'Name',
        value: object?.name,
        required: true,
        max: 1400,
        messages: {
          min: 'Name has to be min 1 characters',
          max: 'Name can be max 1400 characters'
        }
      },
      'description': {
        name: 'Description',
        value: object?.description,
        max: 4400,
        messages: {
          min: 'Description has to be min 1 characters',
          max: 'Description can be max 4400 characters'
        }
      },
      'location': {
        name: 'Location',
        is: 'object',
        value: object?.location
      },
      'coordinates': {
        name: 'Coordinates',
        is: 'string',
        value: object?.location?.coordinates?.join(', ')
      },
      'color': {
        name: 'Color',
        value: object?.color || null
      },
      'active': {
        name: 'Active',
        value: object?.active !== undefined ? object.active : true,
        is: 'boolean'
      },
      'archived': {
        name: 'Archived',
        value: object?.archived,
        is: 'boolean'
      },
      image: {
        name: 'Image',
        is: 'object',
        value: object?.image
      },
      'media': {
        name: 'Media',
        value: [...object?.media || []],
        is: 'array',
        of: 'object'
      }
    }
  });

  const refs = {
    form: React.useRef(form)
  };

  refs.form.current = form;

  const onClose = React.useCallback(() => {
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });
  }, []);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();
  }, []);

  const onNext = React.useCallback(async () => {
    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const body = {
      ...refs.form.current.value
    };

    if (body.coordinates) {
      body.location.coordinates = body.coordinates.split(',').filter(Boolean).map((item: any) => Number(item.trim()));
    }

    delete body.coordinates;

    const result = !object?.id ? await LocationService.add(body) : await LocationService.update(object?.id, body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Location ${!object?.id ? 'added' : 'updated'}`
      });

      setObject(result.response.response);

      if (is('function', onConfirm)) onConfirm();

      if (!object?.id) onClose();
    }

    setLoading(false);
  }, [object, form, onConfirm, onClose]);

  const modal: any = {
    read: (
      <Line
        fullWidth
      >
        <ReadProperties
          object={object}

          values={[
            'color',

            'image',

            'description',

            { name: 'Location', value: object?.location ? object?.location?.name : '' },

            'media',
            'active',
            'archived'
          ]}
        />
      </Line>
    ),

    write: <>
      <FormRow
        gap={2}

        fullWidth
      >
        <Line
          gap={1}

          fullWidth
        >
          <TextField
            name='Name'

            valueDefault={form.values['name'].value}

            error={form.values['name'].error}

            helperText={form.values['name'].error}

            onChange={(valueNew: any) => form.onChange('name', valueNew, undefined, { rerenderOnUpdate: false })}
          />

          <SmartTextField
            name='Description'

            valueDefault={textToInnerHTML(form.values['description'].value || '')}

            error={form.values['description'].error}

            helperText={form.values['description'].error}

            onChange={(valueNew: any) => form.onChange('description', innerHTMLToText(valueNew), undefined, { rerenderOnUpdate: false })}

            minRows={4}

            multiline

            edit
          />

          <SelectAColor
            valueDefault={form.values['color'].value}

            error={!!form.values['color'].error}

            helperText={form.values['color'].error}

            onChange={(valueNew: any) => form.onChange('color', valueNew, undefined, { rerenderOnUpdate: false })}
          />
        </Line>

        <SelectMedia
          name='Image'

          value={form.values['image'].value}

          mediaSelected={form.values['image'].value}

          onChange={(valueNew: any) => form.onChange('image', valueNew)}
        />

        <LocationForm
          form={form}
        />

        <SelectMedia
          name='Media'

          value={form.values.media.value}

          mediaSelected={form.values.media.value}

          mime='all'

          onChange={(valueNew: any) => form.onChange('media', valueNew)}

          multiple
        />

        <Medias
          values={form.values.media.value?.map((item: any) => ({
            value: item
          }))}
        />
      </FormRow>
    </>,

    more: <>
      <Label
        checked={form.values.active?.value}

        onChange={(valueNew: boolean) => form.onChange('active', valueNew)}

        error={!!form.values.active.error}

        helperText={form.values.active.error}
      >
        <Switch />

        Active
      </Label>

      <Label
        checked={form.values.archived?.value}

        onChange={(valueNew: boolean) => form.onChange('archived', valueNew)}

        error={!!form.values.archived.error}

        helperText={form.values.archived.error}
      >
        <Switch />

        Archived
      </Label>
    </>
  };

  return (
    <ModalForm
      {...props}

      object={object}

      add={!object}

      {...modal}

      onSubmit={onSubmit}

      onNext={onNext}

      onClose={onClose}

      loading={loading}

      smaller
    />
  );
});

export default Element;
