import { Invite } from '@amaui/api-utils';
import AmauiSubscription from '@amaui/subscription';
import { IAdditional, IResponse } from '@amaui/sdk/other';

import { BaseService } from './base';
import { IQuerySubscription, ISelectedSubscription } from 'other';
import { AmauiService } from 'services';

class InviteService extends BaseService<Invite> {
  public queryItems = new AmauiSubscription<IQuerySubscription>();
  public selectedItems = new AmauiSubscription<ISelectedSubscription>();
  public invite: Invite = undefined as any;

  public constructor() {
    super('invites');

    this.queryObjectsInit(this.queryItems);

    this.selectedObjectsInit(this.selectedItems);
  }

  public async getPublic(id: string, options?: IAdditional): Promise<IResponse> {
    return AmauiService.sdk.invites.getPublic(id, options);
  }

}

export default new InviteService();
