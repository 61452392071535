import React from 'react';

import { TextField } from '@amaui/ui-react';
import { ITextField } from '@amaui/ui-react/TextField/TextField';
import { classNames, style } from '@amaui/style-react';

const useStyle = style(theme => ({
  root: {

  }
}), { name: 'amaui-app-TextSearch' });

const Element = React.forwardRef((props: ITextField, ref: any) => {
  const {
    className,

    ...other
  } = props;

  const { classes } = useStyle(props);

  return (
    <TextField
      ref={ref}

      className={classNames([
        classes.root,
        className
      ])}

      {...other}
    />
  );
});

Element.displayName = 'amaui-app-TextField';

export default Element;
