import React from 'react';

import { is, textToInnerHTML } from '@amaui/utils';
import { Slide } from '@amaui/ui-react';
import { IconButton, Line, Modal, ModalHeader, ModalTitle, Surface, Tooltip } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';
import { TMethod } from '@amaui/models';
import AmauiSubscription from '@amaui/subscription';

import IconMaterialKeyboardArrowDownRounded from '@amaui/icons-material-rounded-react/IconMaterialKeyboardArrowDown';

import { AppService } from 'services';

const useStyle = style(theme => ({
  root: {
    '&.amaui-Surface-root': {
      background: 'transparent'
    }
  },

  root_modal: {

  },

  header: {
    position: 'relative',
    padding: '12px 12px 12px 24px',
    minHeight: 40,
    maxWidth: theme.maxWidth,

    '&.amaui-ModalHeader-root': {
      padding: 0
    }
  },

  headerName: {
    wordBreak: 'break-word',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },

  padding: {
    padding: 24
  },

  paddingSmall: {
    padding: 12
  },

  noPadding: {
    '&.amaui-Surface-root': {
      padding: 0
    }
  },

  surface: {
    '&.amaui-Surface-root': {
      background: theme.palette.color.primary[theme.palette.light ? 99 : 5]
    }
  },

  version_inline: {
    width: '100%'
  }
}), { name: 'amaui-app-page-Add' });

export interface IAdd {
  id?: string;

  hash?: string;

  version?: 'inline' | 'mid' | 'entire';

  type?: 'add' | 'addSecondary' | 'addTertiary';

  name?: any;

  title?: any;

  subscription?: AmauiSubscription;

  onConfirm?: TMethod;
  onClose?: TMethod;

  open?: boolean;
  helpers?: boolean;

  to?: string;

  route?: {
    previous: any;
    to: any;
  };

  [p: string]: any;
}

const Element = React.forwardRef((props: IAdd, ref: any) => {
  const {
    open: open_,

    id,

    hash,

    version = 'inline',

    name,

    title,

    to,
    route,
    helpers = !props.children,

    subscription = AppService.pages.add,

    noPadding = true,

    onConfirm,
    onClose: onClose_,

    props: moreProps,

    className,

    children,

    ...other
  } = props;

  const { classes } = useStyle();

  const [open, setOpen] = React.useState<any>(false);

  const refs = {
    route: React.useRef(route)
  };

  refs.route.current = route;

  const onOpen = React.useCallback(() => {
    setOpen(true);
  }, []);

  const onClose = React.useCallback(() => {
    setOpen(false);

    subscription.emit({
      ...subscription.value,

      open: false
    });

    // update
    // url
    if (route) {
      if (window.location.pathname !== route.previous) window.history.pushState(undefined, '', route.previous);
    }

    if (is('function', onClose_)) onClose_!();
  }, [route, subscription, onClose_]);

  React.useEffect(() => {
    if (route) {
      // update
      // url
      if (open) {
        if (window.location.pathname !== route.to) window.history.pushState(undefined, '', route.to);
      }
      else {
        if (window.location.pathname !== route.previous) window.history.pushState(undefined, '', route.previous);
      }
    }
  }, [open, route?.to, route?.previous]);

  React.useEffect(() => {
    if (open_ !== undefined && open_ !== open) setOpen(open_);
  }, [open_]);

  if (
    (!open && version === 'inline') ||
    (!['inline', 'mid', 'entire'].includes(version))
  ) return null;

  // inline
  if (version === 'inline') return (
    <Line
      ref={ref}

      direction='column'

      align='center'

      justify='space-between'

      color='primary'

      tonal

      Component={Surface}

      className={classNames([
        className,
        classes.root,
        classes[`version_${version}`]
      ])}

      {...other}
    >
      {React.cloneElement(children, {
        ...moreProps,

        onClose: () => {
          onClose();

          if (is('function', children.props.onClose)) children.props.onClose();
        }
      })}
    </Line>
  );

  // modal
  return (
    <Modal
      ref={ref}

      open={open}

      minWidth='rg'

      fullScreen={version === 'entire'}

      onOpen={onOpen}

      onClose={onClose}

      TransitionComponent={Slide}

      SurfaceProps={{
        tonal: true,
        color: 'primary',

        className: classNames([
          classes.surface,
          noPadding && classes.noPadding
        ])
      }}

      className={classNames([
        className,
        classes.root_modal
      ])}

      {...other}
    >
      {helpers && (
        <ModalHeader
          gap={0}
        >
          <Line
            direction='row'

            align='center'

            justify='space-between'

            fullWidth

            className={classNames([
              classes.header
            ])}
          >
            <ModalTitle
              className={classes.headerName}

              dangerouslySetInnerHTML={{
                __html: textToInnerHTML(name)
              }}
            />

            <Line
              gap={0}

              align='center'

              justify='center'

              direction='row'
            >
              <Tooltip
                name='Close'
              >
                <IconButton
                  color='inherit'

                  onClick={onClose}
                >
                  <IconMaterialKeyboardArrowDownRounded />
                </IconButton>
              </Tooltip>
            </Line>
          </Line>
        </ModalHeader>
      )}

      {children}
    </Modal>
  );
});

export default Element;
