import React from 'react';

import { FormRow, Image, Line, Type } from '@amaui/ui-react';
import { style } from '@amaui/style-react';

import ImageSectionTimeline from 'assets/images/website/features/SectionTimeline.jpg';
import ImageSectionContact from 'assets/images/website/features/SectionContact.jpg';
import ImageSectionImageGallery from 'assets/images/website/features/SectionImageGallery.jpg';
import ImageSectionLogos from 'assets/images/website/features/SectionLogos.jpg';
import ImageSectionCards from 'assets/images/website/features/SectionCards.jpg';
import ImageSectionBoxes from 'assets/images/website/features/SectionBoxes.jpg';
import ImageSectionTextMedia from 'assets/images/website/features/SectionTextMedia.jpg';
import ImageSectionMedia from 'assets/images/website/features/SectionMedia.jpg';
import ImageSectionCarousel from 'assets/images/website/features/SectionCarousel.jpg';
import ImageSectionWatch from 'assets/images/website/features/SectionWatch.jpg';
import ImageSectionAction from 'assets/images/website/features/SectionAction.jpg';
import ImageSectionReviews from 'assets/images/website/features/SectionReviews.jpg';
import ImageLinks from 'assets/images/website/features/Links.jpg';
import ImageForm from 'assets/images/website/features/Form.jpg';
import ImageReservations from 'assets/images/website/features/Reservations.jpg';
import ImagePost from 'assets/images/website/features/Post.jpg';
import ImageSkill from 'assets/images/website/features/Skill.jpg';
import ImagePortfolio from 'assets/images/website/features/Portfolio.jpg';
import ImageResume from 'assets/images/website/features/Resume.jpg';
import ImageSupportPage from 'assets/images/website/features/SupportPage.jpg';

import { TextSearch } from 'ui';

const useStyle = style(theme => ({
  root: {
    padding: '16px 24px',
    maxHeight: 'clamp(240px, 350px, 40vh)'
  },

  elements: {
    '& > * > *': {
      flex: '0 0 auto'
    }
  },

  main: {
    minHeight: 154,
    overflowY: 'hidden',
    overflowX: 'auto',
    padding: '4px 4px 0'
  },

  element: {
    padding: 4,
    overflow: 'hidden',
    cursor: 'pointer',
    userSelect: 'none',
    transition: theme.methods.transitions.make('transform'),

    '& > img': {
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.07)',
      borderRadius: 24,
      width: 114,
      height: 'auto'
    },

    '& > p': {

    },

    '&:active': {
      transform: 'scale(0.94)'
    }
  }
}), { name: 'amaui-app-Elements' });

const elementOptions = [
  { name: 'Text, media', value: 'Section text media', image: ImageSectionTextMedia },
  { name: 'Action', value: 'Section action', image: ImageSectionAction },
  { name: 'Media', value: 'Section media', image: ImageSectionMedia },
  { name: 'Boxes', value: 'Section boxes', image: ImageSectionBoxes },
  { name: 'Cards', value: 'Section cards', image: ImageSectionCards },
  { name: 'Carousel', value: 'Section carousel', image: ImageSectionCarousel },
  { name: 'Reviews', value: 'Section reviews', image: ImageSectionReviews },
  { name: 'Contact', value: 'Section contact', image: ImageSectionContact },
  { name: 'Image gallery', value: 'Section image gallery', image: ImageSectionImageGallery },
  { name: 'Logos', value: 'Section logos', image: ImageSectionLogos },
  { name: 'Timeline', value: 'Section timeline', image: ImageSectionTimeline },
  { name: 'Watch', value: 'Section watch', image: ImageSectionWatch },
  { name: 'Links', value: 'Links', image: ImageLinks },

  { name: 'Form', value: 'Form', image: ImageForm },
  { name: 'Reservation', value: 'Reservation', image: ImageReservations },

  { name: 'Post', value: 'Post', image: ImagePost },
  { name: 'Skill', value: 'Skill', image: ImageSkill },
  { name: 'Portfolio', value: 'Portfolio', image: ImagePortfolio },
  { name: 'Resume', value: 'Resume', image: ImageResume },
  { name: 'Support page', value: 'Support page', image: ImageSupportPage }
];

const Elements = React.forwardRef((props: any, ref: any) => {
  const {
    onAddUI,

    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const [elementSearch, setElementSearch] = React.useState('');

  const onElementSearch = React.useCallback((value: string) => {
    setElementSearch(value);
  }, []);

  const onElementSearchClear = React.useCallback(() => {
    setElementSearch('');
  }, []);

  React.useEffect(() => {
    onElementSearchClear();
  }, []);

  const elements = React.useMemo(() => {
    const valueNew = [...elementOptions].filter(item => item.name.toLowerCase().includes(elementSearch.toLowerCase()));

    return valueNew;
  }, [elementSearch]);

  const formRowElementsProps: any = {
    className: classes.elements,

    HeaderProps: {
      align: 'center',

      style: {
        marginBottom: -4
      }
    },

    MainProps: {
      direction: 'row',
      align: 'center',
      className: classes.main
    }
  };

  return (
    <Line
      gap={3}

      direction='column'

      fullWidth

      className={classes.root}

      {...other}
    >
      <FormRow
        start={(
          <TextSearch
            placeholder='Search'

            value={elementSearch}

            onChange={onElementSearch}

            clear
          />
        )}

        {...formRowElementsProps}
      >
        {elements?.map((item: any, index: number) => (
          <Line
            key={index}

            gap={1.4}

            align='center'

            onClick={() => onAddUI(item.value || item.name.toLowerCase())}

            className={classes.element}
          >
            <Image
              src={item.image}
            />

            <Type
              version='b3'

              priority='secondary'
            >
              {item.name}
            </Type>
          </Line>
        ))}
      </FormRow>
    </Line>
  );
});

export default Elements;
