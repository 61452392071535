import { Project } from '@amaui/api-utils';
import AmauiSubscription from '@amaui/subscription';

import { BaseService } from './base';
import { IQuerySubscription, ISelectedSubscription } from 'other';

class ProjectService extends BaseService<Project> {
  public queryItems = new AmauiSubscription<IQuerySubscription>();
  public selectedItems = new AmauiSubscription<ISelectedSubscription>();

  public constructor() {
    super('projects');

    this.queryObjectsInit(this.queryItems);

    this.selectedObjectsInit(this.selectedItems);
  }

}

export default new ProjectService();
