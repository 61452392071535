import React from 'react';

import { textToInnerHTML } from '@amaui/utils';
import { Frame, Line, useSnackbars, useSubscription } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';

import { ThemeService } from 'services';
import { IQuerySubscription, getErrorMessage } from 'other';
import { ObjectCard } from 'ui';
import config from 'config';

const useStyle = style(theme => ({
  root: {
    '& .amaui-app-Theme-wrapper': {
      minHeight: '540px !important'
    }
  },

  items: {
    padding: 4,
    overflow: 'auto hidden',

    '& > *': {
      '&.amaui-Card-root': {
        flex: '0 0 auto',
        cursor: 'pointer',
        transition: theme.methods.transitions.make('border-radius'),

        '&:active': {
          borderRadius: theme.methods.shape.radius.value(6)
        }
      }
    }
  },

  ...theme.classes(theme)
}), { name: 'amaui-app-Themes' });

const Themes = React.forwardRef((props: any, ref: any) => {
  const {
    selected,

    onChange,

    className
  } = props;

  const { classes } = useStyle();

  const snackbars = useSnackbars();

  const queryThemesPublic = useSubscription<IQuerySubscription>(ThemeService.queryThemesPublic);

  const init = React.useCallback(async () => {
    const result = await ThemeService.queryThemesPublic.value!.query();

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
  }, []);

  React.useEffect(() => {
    // init
    init();
  }, []);

  const url = config.value.apps.websites.url;
  const urlHost = config.value.apps.websites.urlHost;

  return (
    <Line
      ref={ref}

      gap={4}

      fullWidth

      className={classNames([
        className,
        classes.root
      ])}
    >
      <Line
        gap={2}

        direction='row'

        align='unset'

        fullWidth

        className={classNames([
          classes.items
        ])}
      >
        {(queryThemesPublic?.response || [])?.map((item: any, index: number) => (
          <ObjectCard
            key={index}

            value={item}

            selected={item.id === selected?.id}

            onClick={() => onChange({ ...item, id: item.id, name: textToInnerHTML(item.name) })}

            simple
          />
        ))}
      </Line>

      {selected && (
        <Frame
          url={`http${url.includes('localhost') ? '' : 's'}://${selected?.name?.toLowerCase()}.${urlHost}`}

          style={{
            height: 570
          }}
        />
      )}
    </Line>
  );
});

export default Themes;
