import React from 'react';

import { Line, Type } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';

const useStyle = style(theme => ({
  root: {
    padding: '24px 24px 16px'
  }
}), { name: 'amaui-app-route-Empty' });

const Empty = React.forwardRef((props: any, ref: any) => {
  const {
    className,

    ...other
  } = props;

  const { classes } = useStyle();

  return (
    <Line
      ref={ref}

      gap={2}

      direction='column'

      justify='center'

      align='center'

      flex

      fullWidth

      className={classNames([
        className,
        classes.root
      ])}

      {...other}
    >
      <Type
        version='t1'

        align='center'
      >
        No features to use 🫠
      </Type>

      <Type
        version='b2'

        align='center'
      >
        It seems you were added to the organization, but haven't been assigned any feature to use in this project. <br />

        Please contact your organization admin, to add you to the some of the features. Good luck!
      </Type>
    </Line>
  );
});

export default Empty;
