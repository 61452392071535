import React from 'react';

import { Link, useSubscription } from '@amaui/ui-react';

import { FormWorkDay, Page } from 'ui';
import { AuthService, WorkDayService } from 'services';
import config from 'config';
import { ISignedIn } from 'other';

const WorkDays = React.forwardRef(() => {
  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const properties = React.useMemo(() => ({
    name: { name: 'Name' },
    description: { name: 'Description' },
    all: { name: 'All', type: 'boolean' },
    protected: { name: 'Protected', method: (item: any) => item.password ? 'Yes' : 'No' },
    active: { name: 'Active' },
    archived: { name: 'Archived' },
    url: {
      name: 'URL',
      method: (item: any) => (
        <Link
          href={`${config.value.apps.public.url}/organizations/${signedIn.organization.id}/work-days/${item.id}`}

          target='blank'
        >
          Public URL
        </Link>
      ),
      view: false
    }
  }), [signedIn]);

  return (
    <Page
      name='Work days'

      service={WorkDayService}

      columns={[
        properties.name,
        properties.all,
        properties.active,
        properties.archived,
        properties.url,
        properties.protected
      ]}

      search={['active', 'archived']}

      options={['active', 'archived']}

      properties={properties as any}

      app='workDay'

      collection='workDays'

      route='/work-days'

      singular='Work days'

      Form={FormWorkDay}

      updateEntire
    />
  );
});

export default WorkDays;
