import React from 'react';

import { cleanValue, getID, innerHTMLToText, is, textToInnerHTML, wait } from '@amaui/utils';
import { DatePicker, FormRow, IconButton, Line, ListItem, Modal, ModalFooter, ModalHeader, ModalMain, ModalTitle, Select, Tooltip, Type, useForm, useSnackbars } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';
import { Resume } from '@amaui/api-utils';
import { AmauiDate } from '@amaui/date';

import IconMaterialAddRounded from '@amaui/icons-material-rounded-react/IconMaterialAdd';
import IconMaterialCloseRounded from '@amaui/icons-material-rounded-react/IconMaterialClose';

import { AutoCompleteObjects, Button, SmartTextField, TextField } from 'ui';
import { PortfolioService, SkillService } from 'services';
import { getErrorMessage } from 'other';

const useStyle = style(theme => ({
  root: {

  },

  modalMainObject: {
    position: 'relative',
    overflowX: 'hidden',
    overflowY: 'auto'
  },

  modalMainRoot: {
    // maxWidth: theme.maxWidth,
    // padding: '0 4px 40px'
  },

  surface: {
    '&.amaui-Surface-root': {
      background: theme.palette.color.primary[theme.palette.light ? 99 : 5]
    }
  }
}), { name: 'amaui-app-Experience' });

const Experience = React.forwardRef((props: any, ref: any) => {
  const {
    open,

    form,

    onClose,

    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const snackbars = useSnackbars();

  const formObject = useForm({
    values: {
      version: {
        name: 'Version',
        is: 'string',
        required: true,
        in: ['experience', 'project', 'education', 'language', 'skills', 'award', 'interest', 'other']
      },
      'name': {
        name: 'Name',
        required: true,
        max: 1400,
        messages: {
          min: 'Name has to be min 1 characters',
          max: 'Name can be max 1400 characters'
        }
      },
      'short_description': {
        name: 'Summary',
        max: 1400,
        messages: {
          min: 'Summary has to be min 1 characters',
          max: 'Summary can be max 1400 characters'
        }
      },
      'description': {
        name: 'Description',
        max: 4400,
        messages: {
          min: 'Description has to be min 1 characters',
          max: 'Description can be max 4400 characters'
        }
      },
      url: {
        name: 'URL',
        isValid: 'url'
      },
      'organization': {
        name: 'Organization',
        max: 1400,
        messages: {
          min: 'Organization has to be min 1 characters',
          max: 'Organization can be max 1400 characters'
        }
      },
      'position': {
        name: 'Position',
        max: 1400,
        messages: {
          min: 'Position has to be min 1 characters',
          max: 'Position can be max 1400 characters'
        }
      },
      'employment_type': {
        name: 'Employment type',
        is: 'string',
        in: ['full-time', 'part-time', 'self-employed', 'freelance', 'contract', 'internship', 'apprenticeship', 'seasonal']
      },
      'date': {
        name: 'Date',
        is: 'number'
      },
      'start': {
        name: 'Start date',
        is: 'number'
      },
      'end': {
        name: 'End date',
        is: 'number'
      },
      'location': {
        name: 'Location',
        max: 1400,
        messages: {
          min: 'Location has to be min 1 characters',
          max: 'Location can be max 1400 characters'
        }
      },
      'location_type': {
        name: 'Location',
        is: 'string',
        in: ['on-site', 'hybrid', 'remote']
      },
      'industry': {
        name: 'Industry',
        max: 1400,
        messages: {
          min: 'Industry has to be min 1 characters',
          max: 'Industry can be max 1400 characters'
        }
      },
      'proficiency': {
        name: 'Proficiency',
        max: 1400,
        messages: {
          min: 'Proficiency has to be min 1 characters',
          max: 'Proficiency can be max 1400 characters'
        }
      },
      'skills': {
        name: 'Skills',
        value: [],
        is: 'array',
        of: 'object'
      },
      'portfolios': {
        name: 'Portfolios',
        value: [],
        is: 'array',
        of: 'object'
      }
    }
  });

  const formSkills = useForm({
    values: {
      skills: {
        name: 'Skills',
        value: [],
        is: 'array',
        of: 'object'
      }
    }
  });

  const [loading, setLoading] = React.useState<any>(false);

  const refs = {
    form: React.useRef<any>(),
    formObject: React.useRef<any>(),
    formSkills: React.useRef<any>(),
    autoCompleteInput: React.useRef<any>()
  };

  refs.form.current = form;

  refs.formObject.current = formObject;

  refs.formSkills.current = formSkills;

  const init = React.useCallback(async () => {
    const {
      item,
      version,
      index
    } = open || {};

    if (open?.open) {
      // clear
      // form object
      formObject.clear();

      await wait(14);

      formObject.onChange('version', version || item?.version);

      if (index > -1) {
        Object.keys(item).forEach(key => {
          if (['skill', 'portfolio'].includes(key)) refs.formObject.current.onChange(key, item[key]?.map((item_: any) => ({ ...item_, value: item_.id })));
          refs.formObject.current.onChange(key, item[key]);
        });
      }
    }
  }, [open, formObject]);

  React.useEffect(() => {
    init();
  }, [open]);

  const onNextObject = React.useCallback(async () => {
    setLoading('object');

    try {
      const valid = await formObject.validate();

      if (!valid) {
        setLoading(false);

        return;
      }

      const body: Resume = {
        ...formObject.value
      };

      if (is('array', body.skills)) body.skills = body.skills.map((item: any) => ({
        id: item?.id,

        name: item.name
      }));

      if (is('array', body.portfolios)) body.portfolios = body.portfolios.map((item: any) => ({
        id: item?.id,

        name: item.name
      }));

      if (!body.id) body.id = getID();

      const valueNew = [...refs.form.current.values.value.value || []];

      if (open?.index !== undefined) valueNew[open.index] = body;
      else valueNew.push(body);

      refs.form.current.onChange('value', valueNew);
    }
    catch (error) { }

    setLoading(false);

    onClose();
  }, [open, formObject, onClose]);

  const onAddNewSkill = async (valueNew: string, form: any) => {
    if (!valueNew) return;

    setLoading('add-new-skill');

    const response = await SkillService.add({
      name: valueNew
    });

    if (response.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(response)
      });
    }
    else {
      const values = [...(refs.form.current.values.skills.value || [])];

      const skill = response.response.response;

      values.push({
        id: skill.id,
        value: skill.id,

        name: skill.name,
        description: skill.description
      });

      refs.form.current.onChange('skills', values);
    }

    setLoading('add-new-skill');
  };

  return <>
    <Modal
      open={open?.open}

      onClose={onClose}

      minWidth='lg'

      maxWidth='lg'

      SurfaceProps={{
        tonal: true,
        color: 'primary',

        className: classNames([
          classes.surface
        ])
      }}

      {...other}
    >
      <ModalHeader>
        <Tooltip
          name='Close'
        >
          <IconButton
            onClick={onClose}

            style={{
              alignSelf: 'flex-end'
            }}
          >
            <IconMaterialCloseRounded />
          </IconButton>
        </Tooltip>

        <ModalTitle>
          {open?.index === undefined ? 'Add' : 'Update'} {open?.version.endsWith('s') ? open?.version.slice(0, -1) : open?.version}
        </ModalTitle>
      </ModalHeader>

      <ModalMain
        align='center'

        justify='unset'

        fullWidth

        className={classes.modalMainObject}

        style={{
          padding: '24px 0'
        }}
      >
        <Line
          align='unset'

          justify='unset'

          flex

          fullWidth

          className={classes.modalMainRoot}
        >
          {['experience', 'project', 'education', 'language', 'interest', 'award', 'other'].includes(open?.version) && (
            <TextField
              name='Name'

              value={formObject.values['name'].value}

              error={formObject.values['name'].error}

              helperText={formObject.values['name'].error}

              onChange={(valueNew: any) => formObject.onChange('name', valueNew)}
            />
          )}

          {['experience', 'project', 'education', 'language', 'interest', 'award', 'other'].includes(open?.version) && (
            <TextField
              name='Summary'

              value={textToInnerHTML(formObject.values['short_description'].value) || ''}

              error={formObject.values['short_description'].error}

              helperText={formObject.values['short_description'].error}

              onChange={(valueNew: any) => formObject.onChange('short_description', innerHTMLToText(valueNew))}

              minRows={2}

              maxRows={4}

              multiline
            />
          )}

          {['experience', 'project', 'award', 'other'].includes(open?.version) && (
            <SmartTextField
              name='Description'

              value={textToInnerHTML(formObject.values['description'].value) || ''}

              error={formObject.values['description'].error}

              helperText={formObject.values['description'].error}

              onChange={(valueNew: any) => formObject.onChange('description', innerHTMLToText(valueNew))}

              minRows={4}

              multiline

              edit
            />
          )}

          {['language'].includes(open?.version) && (
            <TextField
              name='Proficiency'

              value={formObject.values['proficiency'].value}

              error={formObject.values['proficiency'].error}

              helperText={formObject.values['proficiency'].error}

              onChange={(valueNew: any) => formObject.onChange('proficiency', valueNew)}
            />
          )}

          {['experience', 'project', 'education', 'award'].includes(open?.version) && (
            <TextField
              name='Organization'

              value={formObject.values['organization'].value}

              error={formObject.values['organization'].error}

              helperText={formObject.values['organization'].error}

              onChange={(valueNew: any) => formObject.onChange('organization', valueNew)}
            />
          )}

          {['experience', 'project'].includes(open?.version) && (
            <TextField
              name='Position'

              value={formObject.values['position'].value}

              error={formObject.values['position'].error}

              helperText={formObject.values['position'].error}

              onChange={(valueNew: any) => formObject.onChange('position', valueNew)}
            />
          )}

          {['experience', 'project', 'education'].includes(open?.version) && (
            <TextField
              name='Industry'

              value={formObject.values['industry'].value}

              error={formObject.values['industry'].error}

              helperText={formObject.values['industry'].error}

              onChange={(valueNew: any) => formObject.onChange('industry', valueNew)}
            />
          )}

          {['experience', 'project'].includes(open?.version) && (
            <TextField
              name='URL'

              value={formObject.values['url'].value}

              error={formObject.values['url'].error}

              helperText={formObject.values['url'].error}

              onChange={(valueNew: any) => formObject.onChange('url', valueNew)}

              type='url'
            />
          )}

          {['experience'].includes(open?.version) && (
            <Select
              name='Employment type'

              value={formObject.values['employment_type'].value}

              onChange={(valueNew: any) => formObject.onChange('employment_type', valueNew)}

              fullWidth
            >
              {['full-time', 'part-time', 'self-employed', 'freelance', 'contract', 'internship', 'apprenticeship', 'seasonal'].map((item: any, index: number) => (
                <ListItem
                  key={index}

                  size='small'

                  primary={cleanValue(item, { capitalize: true })}

                  value={item}

                  button
                />
              ))}
            </Select>
          )}

          {['experience', 'project', 'education'].includes(open?.version) && (
            <TextField
              name='Location'

              value={formObject.values['location'].value}

              error={formObject.values['location'].error}

              helperText={formObject.values['location'].error}

              onChange={(valueNew: any) => formObject.onChange('location', valueNew)}
            />
          )}

          {['experience'].includes(open?.version) && (
            <Select
              name='Location type'

              value={formObject.values['location_type'].value}

              onChange={(valueNew: any) => formObject.onChange('location_type', valueNew)}

              fullWidth
            >
              {['on-site', 'hybrid', 'remote'].map((item: any, index: number) => (
                <ListItem
                  key={index}

                  size='small'

                  primary={cleanValue(item, { capitalize: true })}

                  value={item}

                  button
                />
              ))}
            </Select>
          )}

          {['award', 'other'].includes(open?.version) && (
            <DatePicker
              name='Date'

              value={(formObject.values['date'].value ? new AmauiDate(formObject.values['date'].value) : null) as any}

              onChange={((valueNew: AmauiDate) => formObject.onChange('date', valueNew?.milliseconds)) as any}

              error={formObject.values['date'].error}

              helperText={formObject.values['date'].error}

              fullWidth
            />
          )}

          {['experience', 'project', 'education'].includes(open?.version) && (
            <FormRow
              direction='row'

              row

              fullWidth
            >
              <DatePicker
                name='Start'

                value={(formObject.values['start'].value ? new AmauiDate(formObject.values['start'].value) : null) as any}

                onChange={((valueNew: AmauiDate) => formObject.onChange('start', valueNew?.milliseconds)) as any}

                error={formObject.values['start'].error}

                helperText={formObject.values['start'].error}

                max={new AmauiDate() as any}
              />

              <DatePicker
                name='End'

                value={(formObject.values['end'].value ? new AmauiDate(formObject.values['end'].value) : null) as any}

                onChange={((valueNew: AmauiDate) => formObject.onChange('end', valueNew?.milliseconds)) as any}

                error={formObject.values['end'].error}

                helperText={formObject.values['end'].error}

                max={new AmauiDate() as any}
              />
            </FormRow>
          )}

          {['experience', 'project', 'education'].includes(open?.version) && (
            <AutoCompleteObjects
              name='Skills'

              value={formObject.values.skills.value}

              onChange={(valueNew: any[]) => formObject.onChange('skills', valueNew)}

              onChangeInput={(valueNew: string) => {
                refs.autoCompleteInput.current = valueNew;
              }}

              service={SkillService}

              noOptionsElement={refs.autoCompleteInput.current ? (
                <ListItem
                  start={(
                    <IconMaterialAddRounded
                      size='small'
                    />
                  )}

                  startAlign='center'

                  primary={(
                    <Type
                      version='b3'
                    >
                      Add '{refs.autoCompleteInput.current}' skill
                    </Type>
                  )}

                  onClick={() => onAddNewSkill(refs.autoCompleteInput.current, formObject)}

                  size='small'

                  disabled={loading === 'add-new-skill'}

                  button
                />
              ) : undefined}

              noOptions={!!refs.autoCompleteInput.current}

              fullWidth

              multiple

              chip
            />
          )}

          {['experience', 'project', 'education'].includes(open?.version) && (
            <AutoCompleteObjects
              name='Portfolios'

              value={formObject.values.portfolios.value}

              onChange={(valueNew: any[]) => formObject.onChange('portfolios', valueNew)}

              service={PortfolioService}

              fullWidth

              multiple

              chip
            />
          )}
        </Line>
      </ModalMain>

      <ModalFooter>
        <Button
          version='text'

          color='inherit'

          onClick={onClose}
        >
          Close
        </Button>

        <Button
          onClick={onNextObject}

          loading={loading === 'object'}
        >
          Next
        </Button>
      </ModalFooter>
    </Modal>
  </>;
});

export default Experience;
