import React from 'react';
import audioFix from 'webm-duration-fix';

import { FormNote, NoResults, Page, Tags } from 'ui';
import { AuthService, MediaService, NoteService } from 'services';

import { textToInnerHTML } from '@amaui/utils';
import { AudioRecorder, Card, CardFooter, CardHeader, CardMain, Checkbox, IconButton, Interaction, Line, ListItem, Masonry, Medias, Menu, TextToSpeech, Tooltip, Type, useSnackbars, useSubscription } from '@amaui/ui-react';
import { IBaseElement } from '@amaui/ui-react/types';
import { classNames, style, useAmauiTheme } from '@amaui/style-react';
import { AmauiDate, format } from '@amaui/date';
import { Note } from '@amaui/api-utils';

import IconMaterialArchiveRounded from '@amaui/icons-material-rounded-react/IconMaterialArchive';
import IconMaterialPushPinRounded from '@amaui/icons-material-rounded-react/IconMaterialPushPin';
import IconMaterialMoreVertRounded from '@amaui/icons-material-rounded-react/IconMaterialMoreVert';

import { IPageItemMethodOptions } from 'ui/layout/Page';
import { ISignedIn, colorOptions, formats, getElementText, getErrorMessage, mediasToValue } from 'other';

const useStyle = style(theme => ({
  root: {

  },

  note: {
    '&.amaui-Card-root': {
      overflow: 'hidden',
      width: 304,
      height: 400,
      flex: '0 0 auto',
      outline: '0px solid',
      transition: theme.methods.transitions.make(['border-radius', 'outline'])
    },

    '& .amaui-Button-root': {
      flex: '0 0 auto'
    }
  },

  noteHeader: {
    '&.amaui-CardHeader-root': {
      position: 'relative',
      flex: '0 0 auto',
      padding: '14px 24px',
      cursor: 'pointer',
      userSelect: 'none'
    }
  },

  noteMain: {
    '&.amaui-CardMain-root': {
      height: 0,
      paddingBlock: 0,
      overflowY: 'auto'
    }
  },

  noteFooter: {
    '&.amaui-CardFooter-root': {
      padding: 12
    }
  },

  name: {
    maxHeight: 40,
    overflow: 'hidden'
  },

  audio: {
    maxWidth: '100%',
    maxHeight: 40
  },

  selected: {
    outline: '2px solid !important',
    outlineColor: theme.methods.palette.color.value('secondary', 30)
  },

  table: {
    position: 'relative',
    height: 0,
    overflow: 'hidden auto',
    padding: '2px 2px'
  }
}), { name: 'amaui-app-route-Notes' });

export interface INoteItems extends IBaseElement {
  options: IPageItemMethodOptions;
}

export const NoteItems: React.FC<INoteItems> = React.forwardRef((props: any, ref: any) => {
  const {
    options,

    mention,

    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const theme = useAmauiTheme();
  const snackbars = useSnackbars();

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const {
    queryItems,
    queryItemsPinned,

    selectedItems,

    formSearch,

    loading,
    setLoading,

    getMenuItems,

    onOpen,
    onPin,

    props: itemPropsMethod
  } = options;

  const notesUnpinned = queryItems?.response || [];
  const notesPinned = queryItemsPinned?.response || [];

  const onAudioRecorderConfirm = React.useCallback(async (note: Note, value: Blob) => {
    setLoading('voice recording');

    const media = await audioFix(value);

    // validate
    // 140 mb maximum
    if (media.size > (140 * 1e6)) {
      snackbars.add({
        color: 'error',
        primary: `Maximum allowed file size is 140 mb`
      });

      return;
    }

    const name = `Voice recording, ${format(new AmauiDate(), formats.entire)}`;

    // meta
    const meta: any = {};

    const result = await MediaService.add({
      name,
      meta,

      app: 'note',

      // media
      media
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      const mediaMongo = result?.response?.response;

      // Update the note
      const medias = note.media || [];

      medias.push(mediasToValue({
        id: mediaMongo?.id,
        name,
        mime: media.type,
        meta: mediaMongo?.meta,
        versions: mediaMongo?.versions,
        added_at: AmauiDate.milliseconds
      }));

      await NoteService.update(note.id, { media: medias });

      snackbars.add({
        primary: 'Voice recording added'
      });

      // refetch
      NoteService.queryItems.value!.refetch();
      NoteService.queryItemsPinned.value!.refetch();
    }

    setLoading(false);
  }, []);

  const propsOther: any = {
    size: 'small'
  };

  const getNotes = React.useCallback((response: any) => {

    return (
      <Masonry
        gap={2}

        columns={{
          xxs: 1,
          xs: 1,
          sm: 2,
          md: 3,
          lg: 4,
          default: 5
        }}

        NoMasonryProps={{
          flex: true,

          style: {
            overflow: 'hidden auto',
            height: 0,
            padding: '2px 2px',
            flex: 'unset'
          }
        }}
      >
        {response?.map((item: any, index: number) => {
          const itemProps = itemPropsMethod(item);

          const selected = !!selectedItems.isSelected(item);

          const color = colorOptions[item.color]?.color;

          return (
            <Card
              key={index}

              color={color || theme.palette.background.primary.quaternary}

              tonal={false}

              {...itemProps}

              InteractionProps={{
                pulse: false
              }}

              className={classNames([
                classes.note,
                selected && classes.selected
              ])}
            >
              <CardHeader
                onClick={() => onOpen(item)}

                button

                className={classes.noteHeader}
              >
                <>
                  <Interaction />

                  <ListItem
                    tonal

                    color='inherit'

                    primary={(
                      <Type
                        version='l2'

                        dangerouslySetInnerHTML={{
                          __html: textToInnerHTML(item.name)
                        }}
                      />
                    )}

                    Component='div'

                    className={classes.name}

                    noPadding
                  />
                </>
              </CardHeader>

              <CardMain
                flex

                className={classes.noteMain}
              >
                <Type
                  dangerouslySetInnerHTML={{
                    __html: textToInnerHTML(item.value?.[0]?.value || '')
                  }}
                />

                <Medias
                  values={item.media?.map((itemMedia: any) => ({
                    value: itemMedia
                  }))}

                  noName
                />

                {!!item.tags?.length && (
                  <Tags
                    value={item.tags}

                    justify='flex-start'

                    fullWidth

                    form={formSearch}
                  />
                )}
              </CardMain>

              <CardFooter
                gap={0.5}

                justify='space-between'

                className={classes.noteFooter}
              >
                {!mention && (
                  <Line
                    gap={0.5}

                    direction='row'

                    align='center'
                  >
                    <Checkbox
                      checked={selected}

                      color={color ? theme.methods.palette.color.text(color) : undefined}

                      tonal={!color}

                      onChange={() => selectedItems.select(item, true)}

                      size='small'

                      style={{
                        color: color ? theme.methods.palette.color.text(color) : undefined
                      }}
                    />

                    {item.pinned && (
                      <Tooltip
                        name='Unpin'
                      >
                        <IconButton
                          color='inherit'

                          size='small'

                          onClick={() => onPin(item)}

                          disabled={loading}
                        >
                          <IconMaterialPushPinRounded
                            size='small'
                          />
                        </IconButton>
                      </Tooltip>
                    )}

                    {item.archived && (
                      <Tooltip
                        name='Archived'
                      >
                        <span
                          style={{
                            lineHeight: 0
                          }}
                        >
                          <IconMaterialArchiveRounded
                            size='small'
                          />
                        </span>
                      </Tooltip>
                    )}
                  </Line>
                )}

                {!mention && (
                  <Line
                    gap={0.5}

                    direction='row'
                  >
                    {!!item.value?.[0]?.value?.length && (
                      <TextToSpeech
                        read={getElementText(textToInnerHTML(item.value?.[0]?.value))}

                        language={signedIn?.organization?.personalization?.settings?.languages?.speech}

                        {...propsOther}
                      />
                    )}

                    <AudioRecorder
                      onConfirm={(value: Blob) => onAudioRecorderConfirm(item, value)}

                      disabled={loading}

                      {...propsOther}
                    />

                    <Menu
                      menuItems={getMenuItems(item)}

                      ListProps={{
                        ...propsOther
                      }}
                    >
                      <IconButton
                        color='inherit'

                        {...propsOther}
                      >
                        <IconMaterialMoreVertRounded />
                      </IconButton>
                    </Menu>
                  </Line>
                )}
              </CardFooter>
            </Card>
          );
        })}
      </Masonry>
    );
  }, [selectedItems]);

  return (
    <Line
      gap={2.4}

      align='unset'

      justify='unset'

      flex

      fullWidth

      className={classNames([
        className,
        classes.root
      ])}

      {...other}
    >
      {(queryItems.loaded && !(!!notesUnpinned.length || !!notesPinned.length)) && (
        <NoResults />
      )}

      {!!notesPinned?.length && (
        <Line
          align='unset'

          justify='unset'

          fullWidth

          style={{
            flex: '0 0 auto'
          }}
        >
          <Type
            version='t3'
          >
            Pinned
          </Type>

          {getNotes(notesPinned)}
        </Line>
      )}

      {!!notesUnpinned?.length && (
        <Line
          align='unset'

          justify='unset'

          flex

          fullWidth
        >
          <Type
            version='t3'
          >
            Notes
          </Type>

          {getNotes(notesUnpinned)}
        </Line>
      )}
    </Line>
  );
});

const Notes = React.forwardRef(() => {

  const getItems = React.useCallback((options: IPageItemMethodOptions) => {
    return (
      <NoteItems
        options={options}
      />
    );
  }, []);

  const properties = React.useMemo(() => ({
    name: { name: 'Name' },
    archived: { name: 'Archived' },
    pinned: { name: 'Pinned' },
    mentions: { name: 'Mentions', method: (item: any) => item.mentions?.length || '' },
    color: { name: 'Color' }
  }), []);

  return (
    <Page
      name='Notes'

      service={NoteService}

      getItems={getItems}

      columns={[
        properties.name,
        properties.archived
      ]}

      search={['archived', 'color', 'tags']}

      options={['archived']}

      properties={properties as any}

      app='note'

      collection='notes'

      route='/notes'

      Form={FormNote}

      noViews

      pinned
    />
  );
});

export default Notes;
