import React from 'react';

import { classNames, style } from '@amaui/style-react';
import { IBaseElement } from '@amaui/ui-react/types';
import { IconButton, Line, Modal, Slide, Tooltip, Type, useSubscription } from '@amaui/ui-react';
import { IApp } from '@amaui/api-utils';

import IconMaterialCloseRounded from '@amaui/icons-material-rounded-react/IconMaterialClose';

import { ISignedIn } from 'other';
import { AuthService, UserService } from 'services';

const useStyle = style(theme => ({
  root: {

  },

  surface: {
    padding: 0
  },

  video: {
    width: '100%',
    height: 314,
    borderRadius: theme.methods.shape.radius.value(3),
    boxShadow: theme.shadows.values.default[16]
  },

  header: {
    padding: '24px 24px 0'
  },

  main: {
    padding: '4px 40px 40px'
  }
}), { name: 'amaui-app-Popup' });

export interface IPopup extends IBaseElement {
  version: IApp;
}

const Popup: React.FC<IPopup> = React.forwardRef((props, ref: any) => {
  const {
    version,

    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const [open, setOpen] = React.useState(false);
  const [, setLoaded] = React.useState(false);

  const init = React.useCallback(async () => {
    const popups: any = signedIn?.user?.popups! || {};

    if (version && !popups[version as any]) {
      setOpen(true);

      // Update user 
      popups[version] = true;

      await UserService.update(signedIn.user.id, { popups });

      // Update me 
      await AuthService.me();
    }

    setLoaded(true);
  }, [signedIn, version]);

  React.useEffect(() => {
    // init 
    init();
  }, [version]);

  const onOpen = React.useCallback(() => {
    setOpen(true);
  }, []);

  const onClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  const main = React.useMemo(() => {
    let name = `Welcome to ${version}s`;
    let description = `Quick video about features you can use. ✨`;

    if (version === 'blog') {
      name = `Welcome to blog`
    }
    else if (version === 'urlShortener') {
      name = 'Welcome to URL shorteners';
    }
    else if (version === 'support') {
      name = `Welcome to support`
    }
    else if (version === 'chat') {
      name = `Welcome to chat`
    }

    return (
      <Line
        gap={3.4}

        fullWidth

        className={classes.main}
      >
        <Line
          gap={0.5}

          align='center'

          fullWidth
        >
          <Type
            version='h3'

            align='center'
          >
            {name} 😊
          </Type>

          <Type
            version='b2'

            align='center'
          >
            {description}
          </Type>
        </Line>

        <iframe
          className={classes.video}
          src="https://www.youtube.com/embed/1XCcFjGpWsw?si=_yNZEfhUio6td6MG"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        />
      </Line>
    );
  }, [version]);

  return (
    <Modal
      open={open}

      minWidth='rg'

      onOpen={onOpen}

      onClose={onClose}

      TransitionComponent={Slide}

      SurfaceProps={{
        gap: 2,
        tonal: true,
        color: 'primary',

        className: classNames([
          classes.surface
        ])
      }}

      {...other}
    >
      <Line
        direction='row'

        justify='flex-end'

        align='center'

        className={classes.header}
      >
        <Tooltip
          name='Close'
        >
          <IconButton
            color='inherit'

            onClick={onClose}
          >
            <IconMaterialCloseRounded />
          </IconButton>
        </Tooltip>
      </Line>

      {main}
    </Modal>
  );
});

export default Popup;
