import React from 'react';

import { Form, FormRow, Line, useForm, useSnackbars } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';
import { IMedia } from '@amaui/api-utils';

import { Avatar, Button, PropertiesContact, TextField, useMedia, useSubscription } from 'ui';
import { AuthService, UserService } from 'services';
import { ISignedIn, getErrorMessage, getSettings } from 'other';

const useStyle = style(theme => ({
  root: {

  },

  avatar: {
    width: 54,
    height: 54,
    borderRadius: '50%',
    transition: theme.methods.transitions.make('transform', { duration: 'xs' }),

    '&.amaui-Button-root': {
      cursor: 'pointer',
      userSelect: 'none'
    },

    '&:active': {
      transform: 'scale(0.94)'
    }
  },

  forms: {
    paddingBottom: 40
  },

  ...theme.classes(theme)
}), { name: 'amaui-app-route-SettingsInfo' });

const SettingsInfo = React.forwardRef((props: any, ref: any) => {
  const {
    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const media = useMedia();
  const snackbars = useSnackbars();
  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const [loading, setLoading] = React.useState<any>(false);

  const form = useForm({
    values: {
      'name': {
        name: 'Name',
        value: getSettings(signedIn, 'name'),
        is: 'string',
        min: 2,
        max: 1400,
        messages: {
          min: 'Name must be min 2 characters',
          max: 'Name can be max 1400 characters'
        },
        required: true
      },
      'properties': {
        name: 'Properties',
        is: 'object',
        value: getSettings(signedIn, 'properties') || {}
      },
      'settings.images.profile': {
        name: 'Profile image',
        value: getSettings(signedIn, 'settings.images.profile'),
        is: 'object'
      }
    },
    valueDefault: {
      name: getSettings(signedIn, 'name'),
      settings: {
        images: {
          profile: getSettings(signedIn, 'settings.images.profile')
        }
      }
    }
  });

  const refs = {
    form: React.useRef(form)
  };

  refs.form.current = form;

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();

    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading('info');

    const result = await UserService.updatePersonalization(refs.form.current.value);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: result.response?.meta?.message || 'Updated'
      });

      // refresh user
      // in the entire app
      await AuthService.me();
    }

    setLoading(false);
  }, [form]);

  const onImageProfileConfirm = React.useCallback((value: IMedia) => {
    const image = value;

    if (image) {
      form.onChange('settings.images.profile', image);
    }
  }, [form]);

  const onOpenMediaImageProfile = React.useCallback(() => {
    media.open({
      allowed: ['image'],
      filters: {
        mime: 'image'
      },
      selected: [form.values['settings.images.profile']?.value].filter(Boolean),
      onConfirm: onImageProfileConfirm
    });
  }, [media, onImageProfileConfirm]);

  let initials = '';

  if (signedIn) {
    const [firstName, lastName] = getSettings(signedIn, 'name')!.split(' ');

    initials += firstName.charAt(0);

    initials += ((lastName ? lastName.charAt(0) : firstName.charAt(1)) || '');
  }

  initials = initials?.toLowerCase();

  return (
    <Line
      ref={ref}

      gap={4}

      fullWidth

      className={classNames([
        className,
        classes.root,
        classes.wrapper
      ])}

      {...other}
    >
      <Form
        onSubmit={onSubmit}

        name='Info'
        description='Basic user information'

        footer={(
          <Button
            loading={loading === 'info'}

            type='submit'

            size='small'
          >
            Update
          </Button>
        )}
      >
        <FormRow
          name='Profile image'
        >
          <Avatar
            size='large'

            media={form.values['settings.images.profile']?.value}

            onClick={onOpenMediaImageProfile}

            className={classes.avatar}
          >
            {initials}
          </Avatar>
        </FormRow>

        <FormRow
          name='Name'
        >
          <TextField
            name='Name'

            valueDefault={getSettings(signedIn, 'name') || ''}

            error={!!form.values['name'].error}

            helperText={form.values['name'].error}

            onChange={(valueNew: string) => form.onChange('name', valueNew, undefined, { rerenderOnUpdate: false })}
          />
        </FormRow>

        <PropertiesContact
          form={form}

          versions={['tels', 'emails', 'urls', 'addresses', 'custom']}
        />
      </Form>
    </Line>
  );
});

export default SettingsInfo;
