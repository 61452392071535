import React from 'react';

import { ListItem, Select, Type } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';

const useStyle = style(theme => ({
  root: {

  }
}), { name: 'amaui-app-SelectAlign' });

const SelectAlign = React.forwardRef((props: any, ref: any) => {
  const {
    value,

    onChange,

    direction,

    vertical,

    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const optionsVertically: any = [
    { name: 'Start', value: 'flex-start' },
    { name: 'Center', value: 'center' },
    { name: 'Stretch', value: 'stretch' },
    { name: 'End', value: 'flex-end' }
  ];

  const optionsHorizontally: any = [
    { name: 'Start', value: 'flex-start' },
    { name: 'Center', value: 'center' },
    { name: 'Space between', value: 'space-between' },
    { name: 'Space evenly', value: 'space-evenly' },
    { name: 'Space around', value: 'space-around' },
    { name: 'Stretch', value: 'stretch' },
    { name: 'End', value: 'flex-end' }
  ];

  const selectProps: any = {
    size: 'small',

    autoWidth: false,

    MenuProps: {
      portal: true
    },

    ListProps: {
      style: {
        maxHeight: 240,
        overflowY: 'auto',
        overflowX: 'hidden'
      }
    }
  };

  const property = ((vertical && direction === 'row') || (!vertical && direction === 'column')) ? 'align' : 'justify';

  return (
    <Select
      ref={ref}

      name={`Align ${vertical ? 'vertically' : 'horizontally'}`}

      value={value?.props?.[property] || 'flex-start'}

      onChange={(valueNew: string) => onChange(property, valueNew)}

      {...selectProps}

      {...other}

      className={classNames([
        className,
        classes.root
      ])}
    >
      {(property === 'align' ? optionsVertically : optionsHorizontally).map((item: any, index: number) => (
        <ListItem
          key={index}

          primary={(
            <Type
              version='b3'
            >
              {item.name}
            </Type>
          )}

          value={item.value}

          size='small'

          button
        />
      ))}
    </Select>
  );
});

export default SelectAlign;
