import React from 'react';

import { Form, FormRow, Line, SmartTextField, Switch, TextField } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';
import { getObjectValue, setObjectValue, textToInnerHTML } from '@amaui/utils';

import { SelectAny, SelectColor, SelectMedia, SelectSize } from './elements';

const useStyle = style(theme => ({
  root: {

  },

  type: {
    columnGap: '40px'
  },

  divider: {
    columnRule: `1px solid ${theme.palette.text.divider}`
  },

  h1: {
    ...theme.typography.values.h1,

    fontSize: `clamp(1.75rem, 1.5874vw, ${theme.typography.values.h1.fontSize})`
  },

  h2: {
    ...theme.typography.values.h2,

    fontSize: `clamp(1.5rem, 1.3394vw, ${theme.typography.values.h2.fontSize})`
  },

  h3: {
    ...theme.typography.values.h3,

    fontSize: `clamp(1.25rem, 1.1904vw, ${theme.typography.values.h3.fontSize})`
  },

  noData: {
    '& .amaui-FormRow-main': {
      display: 'none'
    }
  }
}), { name: 'amaui-app-OptionsSectionTextMedia' });

const OptionsSectionTextMedia = React.forwardRef((props: any, ref: any) => {
  const {
    value,

    element,

    index,

    onUpdate,

    className,

    children,

    ...other
  } = props;

  const { classes } = useStyle();

  const refs = {
    value: React.useRef(value),
    index: React.useRef(index),
    onUpdate: React.useRef(onUpdate),
    element: React.useRef(element)
  };

  refs.value.current = value;

  refs.index.current = index;

  refs.onUpdate.current = onUpdate;

  refs.element.current = element;

  const update = React.useCallback((property: string, valueProperty: any) => {
    const valueNew = [...(refs.value.current || [])];

    if (refs.element.current) {
      setObjectValue(refs.element.current, property, valueProperty);

      refs.onUpdate.current(valueNew);
    }
  }, []);

  const enable = React.useCallback((valueSwitch: boolean, property: string, valueDefault: any) => {
    const valueNew = [...(refs.value.current || [])];

    if (refs.element.current) {
      setObjectValue(refs.element.current, property, !valueSwitch ? null : valueDefault);

      refs.onUpdate.current(valueNew);
    }
  }, []);

  const getItem = (item: any, index: number) => (
    <FormRow
      key={index}

      gap={2}

      name={item.name}

      start={!item.noSwitch && (
        <Switch
          tonal

          valueDefault={item.defaultSwitch !== undefined ? item.defaultSwitch : !!getObjectValue(element?.props, item.property)}

          onChange={(valueNew: any) => enable(valueNew, `props.${item.property}`, item.default)}

          size='small'
        />
      )}

      {...item?.props}

      className={classNames([
        item?.props?.className,
        (!item.show && [null, undefined].includes(getObjectValue(element?.props || {}, item.property))) && classes.noData
      ])}
    >
      {(item.show || ![null, undefined].includes(getObjectValue(element?.props || {}, item.property))) && item.value}
    </FormRow>
  );

  const options: any = [
    // title size  
    {
      name: 'Title size',
      property: 'TitleProps.version',
      default: element?.props?.size === 'large' ? 'h1' : element?.props?.size === 'regular' ? 'h2' : 'h3',
      noSwitch: true,
      show: true,
      value: (
        <SelectAny
          options={[
            { name: 'Display large', value: 'd1' },
            { name: 'Display regular', value: 'd2' },
            { name: 'Display small', value: 'd3' },

            { name: 'Heading large', value: 'h1' },
            { name: 'Heading regular', value: 'h2' },
            { name: 'Heading small', value: 'h3' }
          ]}

          value={element?.props?.TitleProps?.version || (element?.props?.size === 'large' ? 'h1' : element?.props?.size === 'regular' ? 'h2' : 'h3')}

          onChange={(valueNew: any) => update('props.TitleProps.version', valueNew)}
        />
      )
    },

    // size 
    {
      name: 'Size',
      property: 'size',
      default: 'regular',
      noSwitch: true,
      show: true,
      value: (
        <SelectSize
          value={element?.props?.size || 'regular'}

          onChange={(valueNew: any) => update('props.size', valueNew)}
        />
      )
    },

    // columns 
    {
      name: 'Text columns',
      property: 'DescriptionProps.columns',
      default: 1,
      noSwitch: true,
      show: true,
      value: (
        <SelectAny
          value={element?.props?.DescriptionProps?.columns || 1}

          options={[1, 2, 3, 4, 5, 6, 7]}

          onChange={(valueNew: any) => update('props.DescriptionProps.columns', valueNew)}
        />
      )
    },

    // Description divider
    {
      name: 'Text column divider',
      property: 'DescriptionProps.divider',
      default: true,
      props: {
        className: classes.noData
      }
    },

    // mediaPosition
    {
      name: 'Media position',
      property: 'mediaPosition',
      default: 'top',
      noSwitch: true,
      show: true,
      value: (
        <SelectAny
          value={element?.props?.mediaPosition || 'left'}

          options={['top', 'left', 'right', 'bottom']}

          onChange={(valueNew: any) => update('props.mediaPosition', valueNew)}
        />
      )
    }
  ];

  const onConfirmMedia = React.useCallback((valueNew: any) => {
    const media = valueNew;

    let mediaObject: any;

    if (media) {
      mediaObject = media;
    }

    update(`props.media`, mediaObject || null);
  }, []);

  return (
    <Line
      fullWidth

      className={classNames([
        className,
        classes.root
      ])}

      {...other}
    >
      <Form
        size='small'

        wrapper
      >
        <Line
          fullWidth
        >
          <Line
            gap={7}

            fullWidth
          >
            <SmartTextField
              placeholder='Title'

              valueDefault={textToInnerHTML(element?.props?.title || '')}

              onChange={(valueNew: string) => update('props.title', valueNew)}

              additional={{
                version: 'h2',
                className: classNames([
                  classes[element?.props?.size === 'large' ? 'h1' : ['regular', undefined, null].includes(element?.props?.size) ? 'h2' : 'h3']
                ])
              }}

              multiline

              edit
            />

            <Line
              gap={4}

              fullWidth
            >
              <SmartTextField
                placeholder='Text'

                valueDefault={textToInnerHTML(element?.props?.description || '')}

                onChange={(valueNew: string) => update('props.description', valueNew)}

                additional={{
                  version: element?.props?.size === 'large' ? 'b1' : ['regular', undefined, null].includes(element?.props?.size) ? 'b2' : 'b3'
                }}

                className={classNames([
                  classes.type,
                  element?.props?.DescriptionProps?.divider && classes.divider
                ])}

                style={{
                  columnCount: element?.props?.DescriptionProps?.columns || 1
                }}

                multiline

                edit
              />
            </Line>
          </Line>

          <SelectMedia
            name='Image, audio or video'

            value={element?.props?.media}

            mediaSelected={element?.props?.media}

            mime='all'

            allowed={['image', 'audio', 'video']}

            onChange={onConfirmMedia}
          />

          <FormRow
            name='Action'

            fullWidth
          >
            <TextField
              name='Text'

              valueDefault={textToInnerHTML(element?.props?.button?.text || '')}

              onChange={(valueNew: string) => update('props.button.text', valueNew)}
            />

            <SelectAny
              name='Version'

              value={element?.props?.button?.props?.version || 'filled'}

              options={['filled', 'outlined', 'text']}

              onChange={(valueNew: any) => update('props.button.props.version', valueNew)}
            />

            <SelectColor
              name='Color'

              value={element?.props?.button?.props?.color || 'primary'}

              onChange={(valueNew: any) => update('props.button.props.color', valueNew)}
            />

            <FormRow
              name='To'

              description={`If you want to navigate to an existing website page`}
            >
              <TextField
                name='To'

                placeholder='/about'

                valueDefault={textToInnerHTML(element?.props?.button?.to || '')}

                onChange={(valueNew: string) => update('props.to', valueNew)}

                type='url'
              />
            </FormRow>

            <FormRow
              name='Link'

              description={`If you want to navigate to an external website`}
            >
              <TextField
                name='Link'

                placeholder='https://amaui.me'

                valueDefault={textToInnerHTML(element?.props?.button?.link || '')}

                onChange={(valueNew: string) => update('props.link', valueNew)}

                type='url'
              />
            </FormRow>
          </FormRow>

          {options.map((item: any, index: number) => getItem(item, index))}
        </Line>
      </Form>
    </Line>
  );
});

export default OptionsSectionTextMedia;
