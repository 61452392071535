import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Line, Type, useForm, useSnackbars } from '@amaui/ui-react';

import { ModalForm, TextField } from 'ui';
import { AppService, AuthService, OrganizationService } from 'services';
import { getDeviceAndLocation, getErrorMessage } from 'other';

const Element = React.forwardRef(() => {
  const navigate = useNavigate();
  const snackbars = useSnackbars();

  const [loading, setLoading] = React.useState(false);

  const form = useForm({
    values: {
      'organization.name': {
        name: 'Organization name',
        min: 2,
        max: 1400,
        messages: {
          min: 'Organization name has to be min 2 characters',
          max: 'Organization name can be max 1400 characters'
        },
        required: true
      }
    }
  });

  const refs = {
    form: React.useRef(form)
  };

  refs.form.current = form;

  const onClose = React.useCallback(() => {
    // clean up
    form.clear();

    AppService.pages.addSecondary.emit({
      ...AppService.pages.addSecondary.value,

      open: false
    });
  }, []);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();

    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const body = {
      ...(await getDeviceAndLocation()),

      ...refs.form.current.value
    };

    const result = await OrganizationService.add(body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: 'Added organization'
      });

      const response = result.response.response;

      await AuthService.init(response.amaui_token);

      navigate('/');

      onClose();
    }

    setLoading(false);
  }, [form, onClose]);

  const modal: any = {
    write: <>
      <Type
        version='b2'
      >
        Make a new organization. You will be subscribed to the free plan, same as when you sign up.
      </Type>

      <Line
        fullWidth
      >
        <TextField
          name='Organization name'

          onChange={(valueNew: string) => form.onChange('organization.name', valueNew, undefined, { rerenderOnUpdate: false })}

          error={!!form.values['organization.name'].error}

          helperText={form.values['organization.name'].error}

          fullWidth
        />
      </Line>
    </>
  };

  return (
    <ModalForm
      name='Add organization'

      add

      {...modal}

      onSubmit={onSubmit}

      onNext={onSubmit}

      onClose={onClose}

      loading={loading}

      smaller
    />
  );
});

export default Element;
