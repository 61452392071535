import React from 'react';

import { FormContactGroup, Page } from 'ui';
import { ContactGroupService } from 'services';

const ContactGroups = React.forwardRef(() => {

  const properties = React.useMemo(() => ({
    name: { name: 'Name' },
    description: { name: 'Description' },
    version: { name: 'Version' },
    active: { name: 'Active' },
    archived: { name: 'Archived' },
    contacts: { name: 'Contacts', property: 'contacts', method: (item: any) => item.contacts?.length || 0 },
    color: { name: 'Color' }
  }), []);

  return (
    <Page
      name='Contact groups'

      service={ContactGroupService}

      columns={[
        properties.name,
        properties.contacts,
        properties.color,
        properties.active,
        properties.archived
      ]}

      search={['active', 'archived', 'color']}

      options={['active', 'archived']}

      properties={properties as any}

      app='contact'

      collection='contactGroups'

      route='/contact-groups'

      Form={FormContactGroup}
    />
  );
});

export default ContactGroups;
