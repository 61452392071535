import React from 'react';

import { is } from '@amaui/utils';
import { Line, ListItem, Select, Type } from '@amaui/ui-react';
import { ISelect } from '@amaui/ui-react/Select/Select';
import { classNames, style } from '@amaui/style-react';

import { colorOptions } from 'other';

const useStyle = style(theme => ({
  root: {

  },

  ...theme.classes(theme)
}), { name: 'amaui-app-SelectAColor' });

const Element: React.FC<ISelect> = React.forwardRef((props: ISelect, ref: any) => {
  const {
    multiple,

    noAny,

    className,

    ...other
  } = props;

  const { classes } = useStyle(props);

  const options = React.useMemo(() => {
    return [
      ...(!noAny ? [{ name: 'Any color', value: null }] : []),

      ...Object.keys(colorOptions).map(item => colorOptions[item])
    ];
  }, [colorOptions, noAny]);

  return (
    <Select
      ref={ref}

      name='Color'

      renderValues={value => {
        const valueNew = (is('array', value) ? value : [value] as any);

        const optionsToUse = options.filter(item => valueNew.includes(item.value)) as any;

        if (!optionsToUse?.length) return 'Choose a color';

        return (
          <Line
            gap={1.5}

            direction='row'

            align='center'
          >
            {optionsToUse.map((item: any, index: number) => (
              <Line
                key={index}

                gap={1.5}

                direction='row'

                align='center'

                style={{
                  height: 20,
                  paddingLeft: 2
                }}
              >
                <Line
                  className={classNames([
                    classes.palette,
                    !item.color && classes.emptyColor
                  ])}

                  style={{
                    background: item.color
                  }}
                />

                <Type
                  version='b3'
                >
                  {item.name}{index !== optionsToUse.length - 1 ? ',' : ''}
                </Type>
              </Line>
            ))}
          </Line>
        );
      }}

      multiple={multiple}

      className={classNames([
        className,
        classes.root
      ])}

      {...other}
    >
      {options.map((item: any, index: number) => (
        <ListItem
          key={index}

          start={(
            <Line
              className={classNames([
                classes.palette,
                !item.color && classes.emptyColor
              ])}

              style={{
                background: item.color
              }}
            />
          )}

          startAlign='center'

          primary={(
            <Type
              version='b3'
            >
              {item.name}
            </Type>
          )}

          value={item.value}

          size='small'

          button
        />
      ))}
    </Select>
  );
});

Element.displayName = 'amaui-app-SelectAColor';

export default Element;
