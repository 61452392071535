import React from 'react';

import { capitalize, is } from '@amaui/utils';
import { Form, Line, Type, useConfirm, useSnackbars } from '@amaui/ui-react';
import { style } from '@amaui/style-react';
import { AmauiDate, format } from '@amaui/date';

import { Button, ModalForm, ReadProperties, useSubscription } from 'ui';
import { AppService, AuthService, SignInService, StorageService } from 'services';
import { ISignedIn, formats, getErrorMessage } from 'other';

const useStyle = style(theme => ({
  root: {

  },

  locations: {
    maxHeight: 140,
    overflow: 'hidden auto'
  }
}), { name: 'amaui-app-SignIn' });

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    object: object_,

    onConfirm,

    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const snackbars = useSnackbars();
  const confirm = useConfirm();

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const [object] = React.useState(object_);
  const [loading, setLoading] = React.useState<any>(false);

  const refs = {
    current: React.useRef(StorageService.get('device-id'))
  };

  const onClose = React.useCallback(() => {
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });
  }, []);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();
  }, []);

  const onInactivate = React.useCallback(async (event: SubmitEvent) => {
    const valid = object.active;

    if (!valid) return;

    setLoading(true);

    const result = await SignInService.update(object?.id, { active: false });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Sign in inactivated`
      });

      if (is('function', onConfirm)) onConfirm();
    }

    setLoading(false);
  }, [object, onClose]);

  const onRemove = React.useCallback(async (event: SubmitEvent) => {
    if (!(await confirm.open({ name: 'Removing a sign in' }))) return;

    setLoading(true);

    const result = await SignInService.remove(object?.id);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Sign in removed`
      });

      if (is('function', onConfirm)) onConfirm();

      onClose();
    }

    setLoading(false);
  }, [object, onClose]);

  const current = object?.device === refs.current.current && signedIn?.project?.id === object?.project?.id;

  const location = object.locations?.[0];

  const modal: any = {
    read: (
      <Line
        fullWidth

        className={classes.page}
      >
        <ReadProperties
          object={object}

          values={[
            { name: 'Device', value: `${capitalize(location?.os?.platform || '')} ${capitalize(location?.browser?.name || '')}`.trim() || 'Unknown' },
            { name: 'Latest location', value: `${location?.country?.name || ''}${location?.city ? `, ${location.city.name}` : ''}`.trim() || 'Unknown' },
            { name: 'Current sign in', value: current ? 'Yes' : 'No' },
            {
              name: 'Locations',
              value: (
                <Form
                  name='Sign in history'

                  description='Sign in location history'

                  size='small'

                  AccordionMainProps={{
                    gap: 0.5,

                    className: classes.locations
                  }}

                  accordion

                  wrapper
                >
                  {object?.locations?.map((location_: any, index: number) => (
                    <Line
                      key={index}

                      gap={1.5}

                      direction='row'

                      align='center'
                    >
                      <Type>
                        {`${location_?.country?.name || ''}${location_?.city ? `, ${location_.city.name}` : ''}`.trim() || 'Unknown'}
                      </Type>

                      {location_.added_at && (
                        <Type
                          priority='secondary'

                          version='b3'
                        >
                          {format(new AmauiDate(location_.added_at), formats.entire)}
                        </Type>
                      )}
                    </Line>
                  ))}
                </Form>
              )
            }
          ]}
        />
      </Line>
    )
  };

  return (
    <ModalForm
      {...props}

      name='Sign in'

      {...modal}

      onSubmit={onSubmit}

      onClose={onClose}

      loading={loading}

      smaller

      footerRightEnd={[
        <Button
          version='outlined'

          color='error'

          size='small'

          onClick={onRemove}

          disabled={loading}
        >
          Remove
        </Button>,

        <Button
          version='outlined'

          color='default'

          size='small'

          onClick={onInactivate}

          disabled={!object.active || loading}
        >
          Inactivate
        </Button>
      ]}

      {...other}
    />
  );
});

export default Element;
