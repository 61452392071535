import React from 'react';

import { FormRow, Label, Switch } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';

const useStyle = style(theme => ({
  root: {

  }
}), { name: 'amaui-app-OptionsShare' });

const OptionsShare = React.forwardRef((props: any, ref: any) => {
  const {
    form,

    className,

    children,

    ...other
  } = props;

  const { classes } = useStyle();

  return (
    <FormRow
      ref={ref}

      name='Share'

      description='Social media share options'

      className={classNames([
        className,
        classes.root
      ])}

      {...other}
    >
      <Label
        checked={form.values['options.share.active']?.value !== undefined ? form.values['options.share.active']?.value : false}

        onChange={(valueNew: any) => form.onChange('options.share.active', valueNew)}
      >
        <Switch />

        Active
      </Label>
    </FormRow>
  );
});

export default OptionsShare;
