import { Available } from '@amaui/api-utils';
import AmauiSubscription from '@amaui/subscription';
import { IAdditional } from '@amaui/sdk/other';

import { BaseService } from './base';
import { IQuerySubscription, ISelectedSubscription } from 'other';
import { AmauiService } from 'services';

class AvailableService extends BaseService<Available> {
  public queryItems = new AmauiSubscription<IQuerySubscription>();
  public selectedItems = new AmauiSubscription<ISelectedSubscription>();

  public constructor() {
    super('availables');

    this.queryObjectsInit(this.queryItems);

    this.selectedObjectsInit(this.selectedItems);
  }

  public async reservations(id: string, options?: Partial<IAdditional>) {
    return AmauiService.sdk[this.route as 'availables']?.reservations(id, options);
  }

}

export default new AvailableService();
