import React from 'react';

import { Form, Forms, Line, useSnackbars } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';

import { Button } from 'ui';
import { UserService } from 'services';
import { getErrorMessage } from 'other';
import config from 'config';

const useStyle = style(theme => ({
  root: {

  },

  ...theme.classes(theme)
}), { name: 'amaui-app-route-UserSettingsNotifications' });

const UserSettingsNotifications = React.forwardRef((props: any, ref: any) => {
  const {
    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const snackbars = useSnackbars();

  const [loading, setLoading] = React.useState<any>(false);
  const [loaded, setLoaded] = React.useState<any>(false);
  const [subscription, setSubscription] = React.useState<PushSubscription>();

  const init = React.useCallback(async () => {
    if (!('serviceWorker' in navigator)) return;

    const registration = await window.navigator.serviceWorker.ready;

    const browserSubscription = await registration.pushManager.getSubscription();

    if (browserSubscription) setSubscription(browserSubscription);

    setLoaded(true);
  }, []);

  React.useEffect(() => {
    // init
    init();
  }, []);

  const onSubscribe = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();

    const valid = 'serviceWorker' in navigator && !subscription;

    if (!valid) return;

    setLoading(true);

    try {
      const registration = await window.navigator.serviceWorker.ready;

      const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: config.value.webPush.vapid.public_key
      });

      const result = await UserService.pushSubscribe({
        push: {
          value: subscription.toJSON() as any
        }
      });

      if (result.status >= 400) {
        snackbars.add({
          color: 'error',
          primary: getErrorMessage(result)
        });
      }
      else {
        snackbars.add({
          primary: `Push notifications enabled on this device`
        });
      }
    }
    catch (error) {
      console.error('Push notification enable', error);

      snackbars.add({
        color: 'error',
        primary: `Push notifications enable error`
      });
    }

    setLoading(false);
  }, [subscription]);

  const onUnsubscribe = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();

    const valid = 'serviceWorker' in navigator && subscription;

    if (!valid) return;

    setLoading(true);

    try {
      await subscription.unsubscribe();

      const result = await UserService.pushUnsubscribe({
        push: {
          value: subscription.toJSON() as any
        }
      });

      if (result.status >= 400) {
        snackbars.add({
          color: 'error',
          primary: getErrorMessage(result)
        });
      }
      else {
        snackbars.add({
          primary: `Push notifications disabled on this device`
        });

        setSubscription(null as any);
      }
    }
    catch (error) {
      console.error('Push notification unsubscribe', error);

      snackbars.add({
        color: 'error',
        primary: `Push notifications disable error`
      });
    }

    setLoading(false);
  }, [subscription]);

  return (
    <Line
      ref={ref}

      gap={4}

      fullWidth

      className={classNames([
        className,
        classes.root,
        classes.wrapper
      ])}

      {...other}
    >
      <Forms>
        <Form
          name='Notifications'

          wrapper
        >
          <Form
            name='Push notifications'

            description='Manage push notifications on this device'

            size='small'

            wrapper
          >
            {loaded && subscription && (
              <Button
                onClick={onUnsubscribe}

                size='small'

                disabled={loading}
              >
                Disable
              </Button>
            )}

            {loaded && !subscription && (
              <Button
                onClick={onSubscribe}

                size='small'

                disabled={loading}
              >
                Enable
              </Button>
            )}
          </Form>
        </Form>
      </Forms>
    </Line>
  );
});

export default UserSettingsNotifications;
