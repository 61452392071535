import React from 'react';
import { useNavigate } from 'react-router-dom';

import { is } from '@amaui/utils';
import { Expand, IconButton, Line, Tab, Tabs, Tooltip, useLocation, useMediaQuery } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';

import IconMaterialMenuRounded from '@amaui/icons-material-rounded-react/IconMaterialMenu';
import IconMaterialArrowBackRounded from '@amaui/icons-material-rounded-react/IconMaterialArrowBack';

import { AppsMenu, Notifications, UserMenu, useSubscription } from 'ui';
import { AppService, AuthService } from 'services';
import { CONSTS, ISignedIn, getRootPage } from 'other';
import { IRailMain } from './RailMain';

import { ReactComponent as Logo } from 'assets/svg/logo-light.svg';

const useStyle = style(theme => ({
  root: {
    flex: '0 0 auto',
    zIndex: 14
  },

  header: {
    position: 'relative',
    height: 57,
    padding: '0px 16px',
    flex: '0 0 auto'
  },

  pageMenus: {
    padding: '0px 16px',
    flex: '0 0 auto',
    backgroundColor: theme.palette.light ? theme.palette.color.primary[99] : theme.palette.color.primary[5],
    boxShadow: `0px 4px 4px rgba(${theme.palette.light ? '0, 0, 0, 0.04' : '255, 255, 255, 0.07'})`
  },

  logo: {
    '& > svg': {
      width: 40,
      height: 'auto',
      fill: theme.palette.light ? '' : theme.palette.color.amaui[20],
      cursor: 'pointer',
      userSelect: 'none',
      transition: theme.methods.transitions.make('transform', { duration: 'xs' }),

      '&:active': {
        transform: 'scale(0.94)'
      }
    }
  },

  tabs: {

  }
}), { name: 'amaui-app-Header' });

const Header = React.forwardRef((props: any, ref: any) => {
  const {
    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const navigate = useNavigate();
  const location = useLocation();

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const mobile = useMediaQuery(`(max-width: ${CONSTS.mobile}px)`);

  const [page, setPage] = React.useState<any>();
  const [expanded, setExpanded] = React.useState<any>(false);

  const railMainMethod = (value: any, setResponse: any) => {
    if (value.expand !== undefined) setExpanded(value.expand);
  };

  useSubscription<IRailMain>(AppService.pages.railMain, railMainMethod);

  const refs = {
    root: React.useRef<any>()
  };

  const routes = React.useMemo(() => {

    return {
      'medias': [
        { label: 'Media', to: '/medias' },
        { label: 'Add', to: '/medias/add' }
      ],

      'settings': [
        { label: 'Info', to: '/settings' },
        { label: 'App', to: '/settings/app' },
        { label: 'Security', to: '/settings/security' },
        { label: 'Notifications', to: '/settings/notifications' },
        { label: 'Sign ins', to: '/settings/sign-ins' },
        { label: 'Manage', to: '/settings/manage' }
      ],

      'organization/settings': [
        { label: 'Info', to: '/organization/settings' },
        { label: 'Users', to: '/organization/settings/users' },
        { label: 'User groups', to: '/organization/settings/user-groups' },
        { label: 'Invites', to: '/organization/settings/invites' },
        { label: 'Payment methods', to: '/organization/settings/payment-methods' },
        { label: 'Subscription', to: '/organization/settings/subscription' },
        { label: 'Invoices', to: '/organization/settings/invoices' },
        { label: 'Integrations', to: '/organization/settings/integrations' },
        { label: 'Activity logs', to: '/organization/settings/activity-logs' },

        ...(signedIn?.user?.is?.owner ? [
          { label: 'Manage', to: '/organization/settings/manage' }
        ] : [])
      ],

      'manage': [
        { label: 'Organizations', to: '/manage/organizations' },
        { label: 'Users', to: '/manage/users' },
        { label: 'Audit logs', to: '/manage/audit-logs' }
      ]
    };
  }, [signedIn]);

  React.useEffect(() => {
    const pathname = location.pathname;

    let page_ = null;

    if (pathname.startsWith('/medias')) page_ = 'medias';

    if (pathname.startsWith('/settings')) page_ = 'settings';

    if (pathname.startsWith('/organization/settings')) page_ = 'organization/settings';

    if (pathname.startsWith('/manage')) page_ = 'manage';

    setPage(page_);
  }, [location]);

  const onSidebarMainOpen = React.useCallback(() => {
    AppService.pages.sidebarMain.emit({
      ...AppService.pages.sidebarMain.value,

      open: true
    });
  }, []);

  const onRailMainExpand = React.useCallback(() => {
    AppService.pages.railMain.emit({
      ...AppService.pages.railMain.value,

      expand: !AppService.pages.railMain.value?.expand
    });
  }, []);

  const isActive = React.useCallback((value: any, valueItem: any) => {
    if (
      (value.startsWith('/organization/settings/projects') && (valueItem === '/organization/settings/projects')) ||
      (value.startsWith('/organization/settings/user-groups') && (valueItem === '/organization/settings/user-groups')) ||
      (value.startsWith('/organization/settings/invites') && (valueItem === '/organization/settings/invites')) ||
      (value.startsWith('/organization/settings/payment-methods') && (valueItem === '/organization/settings/payment-methods')) ||
      (value.startsWith('/organization/settings/subscription') && (valueItem === '/organization/settings/subscription'))
    ) return true;

    if (
      (value.startsWith('/settings/sign-ins') && (valueItem === '/settings/sign-ins'))
    ) return true;

    return value === valueItem;
  }, []);

  const menus = (routes as any)[page] as { label: string; to: string; }[];

  return (
    <Line
      ref={(item: any) => {
        refs.root.current = item;

        if (ref) {
          if (is('function', ref)) ref(item)
          else ref.current = item;
        }
      }}

      gap={0}

      direction='column'

      justify='unset'

      align='unset'

      fullWidth

      className={classNames([
        className,
        classes.root
      ])}

      {...other}
    >
      {/* Header */}
      <Line
        gap={2}

        direction='row'

        justify='space-between'

        align='center'

        fullWidth

        className={classNames([
          classes.header
        ])}
      >
        <Line
          gap={2}

          direction='row'

          align='center'
        >
          <Tooltip
            name={mobile ? 'Menu' : expanded ? 'Unexpand' : 'Expand'}

            touch={false}

            longPress
          >
            <IconButton
              size='regular'

              onClick={mobile ? onSidebarMainOpen : onRailMainExpand}
            >
              <IconMaterialMenuRounded />
            </IconButton>
          </Tooltip>

          <Line
            className={classes.logo}
          >
            <Logo
              onClick={() => navigate(getRootPage(signedIn))}
            />
          </Line>
        </Line>

        <Line
          gap={1}

          direction='row'

          align='center'
        >
          <AppsMenu />

          <Notifications />

          <UserMenu />
        </Line>
      </Line>

      {/* Page menus */}
      <Expand
        in={!!page}

        parent={refs.root.current}
      >
        <Line
          gap={0}

          direction='row'

          justify='unset'

          align='center'

          fullWidth

          className={classNames([
            classes.pageMenus
          ])}
        >
          <Tooltip
            name='Back'
          >
            <IconButton
              onClick={() => navigate('/')}

              size='small'
            >
              <IconMaterialArrowBackRounded />
            </IconButton>
          </Tooltip>

          <Tabs
            value={location.pathname}

            isActive={isActive}

            size='small'

            noDivider

            className={classes.tabs}
          >
            {menus?.map((item: any, index: number) => (
              <Tab
                key={item.to}

                onClick={() => navigate(item.to)}

                value={item.to}
              >
                {item.label}
              </Tab>
            ))}
          </Tabs>
        </Line>
      </Expand>
    </Line>
  );
});

export default Header;
