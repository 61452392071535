import React from 'react';
import { useNavigate } from 'react-router-dom';

import { getFileName } from '@amaui/utils';
import { DropZone, Line, useSnackbars } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';

import { MediaService } from 'services';
import { getErrorMessage } from 'other';

const useStyle = style(theme => ({
  root: {

  },

  dropZone: {
    '&.amaui-DropZone-root': {
      '&.amaui-Surface-root': {
        width: 'calc(100% - 4px)',
        marginInlineStart: 2
      }
    }
  },

  ...theme.classes(theme)
}), { name: 'amaui-app-route-MediasAdd' });

const MediasAdd = React.forwardRef((props: any, ref: any) => {
  const {
    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const snackbars = useSnackbars();
  const navigate = useNavigate();

  const [loading, setLoading] = React.useState<any>(false);

  const onDropZoneChange = React.useCallback(async (value: File[]) => {
    setLoading('add-drop-zone');

    try {
      const media = value[0];

      const name = getFileName(media);

      // validate
      // 140 mb maximum
      if (media.size > (140 * 1e6)) {
        snackbars.add({
          color: 'error',
          primary: `Maximum allowed file size is 140 mb`
        });

        return;
      }

      // meta
      const meta: any = {};

      const result = await MediaService.add({
        name,

        meta,

        app: 'amaui',

        // media
        media
      });

      if (result.status >= 400) {
        snackbars.add({
          color: 'error',
          primary: getErrorMessage(result)
        });
      }
      else {
        snackbars.add({
          primary: 'Media added'
        });

        navigate('/medias');
      }
    }
    catch (error) { }

    setLoading(false);
  }, []);

  return (
    <Line
      ref={ref}

      gap={4}

      align='center'

      justify='flex-start'

      fullWidth

      className={classNames([
        className,
        classes.root,
        classes.wrapper
      ])}

      {...other}
    >
      <DropZone
        color='inherit'

        onChange={onDropZoneChange as any}

        disabled={loading}

        className={classes.dropZone}
      />
    </Line>
  );
});

export default MediasAdd;
