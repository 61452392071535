import React from 'react';

import { FormUrlShortener, Page } from 'ui';
import { UrlShortenerService } from 'services';

const URLShorteners = React.forwardRef(() => {

  const properties = React.useMemo(() => ({
    name: { name: 'Name' },
    description: { name: 'Description' },
    url: { name: 'URL' },
    'to.url': { name: 'To URL' },
    active: { name: 'Active' },
    archived: { name: 'Archived' }
  }), []);

  return (
    <Page
      name='URL shorteners'

      service={UrlShortenerService}

      columns={[
        properties.name,
        properties.active,
        properties.archived
      ]}

      search={['active', 'archived']}

      options={['active', 'archived']}

      properties={properties}

      app='urlShortener'

      collection='urlShorteners'

      route='/url-shorteners'

      Form={FormUrlShortener}
    />
  );
});

export default URLShorteners;
