import React from 'react';

import { cleanValue, is } from '@amaui/utils';
import { Line, useForm, useSnackbars } from '@amaui/ui-react';

import { ModalForm, ReadProperties, SelectRoles, useSubscription } from 'ui';
import { AppService, AuthService, OrganizationService } from 'services';
import { getErrorMessage, ISignedIn } from 'other';

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    object: object_,

    onConfirm
  } = props;

  const snackbars = useSnackbars();
  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const [object, setObject] = React.useState(object_);
  const [loading, setLoading] = React.useState<any>(false);

  const userOrganization = object?.organizations?.find((item_: any) => item_.id === signedIn.organization.id);

  const form = useForm({
    values: {
      'roles': {
        name: 'Roles',
        value: userOrganization?.roles,
        is: 'array',
        of: 'string',
        min: 1
      }
    }
  });

  const refs = {
    form: React.useRef(form)
  };

  refs.form.current = form;

  const onClose = React.useCallback(() => {
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });
  }, []);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();
  }, []);

  const onNext = React.useCallback(async (event: SubmitEvent) => {
    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const body = {
      ...refs.form.current.value
    };

    const result = await OrganizationService.updateUser(object?.id, body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `User ${!object?.id ? 'added' : 'updated'}`
      });

      setObject(result.response.response);

      if (is('function', onConfirm)) onConfirm();

      if (!object?.id) onClose();
    }

    setLoading(false);
  }, [object, form, onConfirm, onClose]);

  const modal: any = {
    read: (
      <Line
        fullWidth
      >
        <ReadProperties
          object={object}

          values={[
            {
              name: 'Roles',
              method: (item: any) => {
                const userOrganization = item.organizations?.find((item_: any) => item_.id === signedIn.organization.id);

                return userOrganization?.roles?.length ? userOrganization.roles.map((item_: any) => cleanValue(item_, { capitalize: true })).join(', ') : '';
              },
              u: false
            }
          ]}
        />
      </Line>
    ),

    write: <>
      <SelectRoles
        value={form.values.roles?.value}

        onChange={(valueNew: boolean) => form.onChange('roles', valueNew)}

        error={!!form.values.roles.error}

        helperText={form.values.roles.error}
      />
    </>
  };

  return (
    <ModalForm
      {...props}

      object={object}

      add={!object}

      {...modal}

      onSubmit={onSubmit}

      onNext={onNext}

      onClose={onClose}

      loading={loading}

      smaller
    />
  );
});

export default Element;
