import React from 'react';

import { is } from '@amaui/utils';
import { classNames, style } from '@amaui/style-react';
import { IBaseElement } from '@amaui/ui-react/types';

const useStyle = style(theme => ({
  root: {
    width: '100%',
    height: '100%'
  }
}), { name: 'amaui-app-IFrameWebsites' });

export interface IIFrameWebsites extends IBaseElement {
  url: string;
}

const IFrameWebsites: React.FC<IIFrameWebsites> = React.forwardRef((props, ref: any) => {
  const {
    url,

    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const refs = {
    root: React.useRef<any>()
  };

  if (!url) return null;

  return (
    <iframe
      ref={(item: any) => {
        refs.root.current = item;

        if (ref) {
          if (is('function', ref)) ref(item)
          else ref.current = item;
        }
      }}

      title='iframe-websites'

      src={url}

      className={classNames([
        className,
        classes.root
      ])}

      {...other}
    />
  );
});

export default IFrameWebsites;
