import React from 'react';
import audioFix from 'webm-duration-fix';

import { innerHTMLToText, is, textToInnerHTML } from '@amaui/utils';
import { AudioRecorder, IconButton, Line, Medias, SpeechToText, TextToSpeech, Tooltip, Type, useForm, useSnackbars, useSubscription } from '@amaui/ui-react';
import { AmauiDate, format } from '@amaui/date';
import { IMedia } from '@amaui/api-utils';

import IconMaterialArchiveRounded from '@amaui/icons-material-rounded-react/IconMaterialArchive';
import IconMaterialFolderOpenRounded from '@amaui/icons-material-rounded-react/IconMaterialFolderOpen';

import { Color, ModalForm, SelectAColor, SmartTextField, Tags, useMedia } from 'ui';
import { AppService, AuthService, MediaService, NoteService } from 'services';
import { ISignedIn, formats, getElementText, getErrorMessage, mediasToValue } from 'other';

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    object: object_,

    onConfirm
  } = props;

  const snackbars = useSnackbars();
  const media = useMedia();

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const [object, setObject] = React.useState(object_);
  const [loading, setLoading] = React.useState<any>(false);

  const form = useForm({
    values: {
      'name': {
        name: 'Name',
        value: object?.name ? textToInnerHTML(object?.name) : '',
        required: true,
        max: 1400,
        messages: {
          min: 'Name has to be min 1 characters',
          max: 'Name can be max 1400 characters'
        }
      },
      'value': {
        name: 'Description',
        value: object?.value?.[0]?.value ? textToInnerHTML(object?.value?.[0]?.value) : '',
        max: 14000,
        messages: {
          min: 'Description has to be min 1 characters',
          max: 'Description can be max 14000 characters'
        }
      },
      'color': {
        name: 'Color',
        value: object?.color || null,
      },
      'media': {
        name: 'Media',
        value: [...object?.media || []],
        is: 'array',
        of: 'object'
      }
    },
    valueDefault: {
      name: object?.name,
      value: object?.value?.[0]?.value,
      media: []
    }
  });

  const refs = {
    name: React.useRef<HTMLElement>(),
    value: React.useRef<HTMLElement>(),
    form: React.useRef(form),
    original: React.useRef<any>()
  };

  refs.form.current = form;

  const init = React.useCallback(() => {
    if (object?.id) {
      form.onChange('media', [...(object.media || [])]);
    }
  }, [form, object]);

  React.useEffect(() => {
    // init
    init();
  }, []);

  const onClose = React.useCallback(() => {
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });
  }, []);

  const isValid = form.value.name || form.value.value;

  const onNext = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();

    const valid = await refs.form.current.validate();

    if (!valid || !isValid) return;

    setLoading(true);

    const body = {
      name: innerHTMLToText(refs.form.current.value.name),

      value: [
        {
          version: 'type',
          value: innerHTMLToText(refs.form.current.value.value)
        }
      ],

      color: refs.form.current.value.color,

      media: refs.form.current.value.media
    };

    const result = !object?.id ? await NoteService.add(body) : await NoteService.update(object?.id, body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Note ${!object?.id ? 'added' : 'updated'}`
      });

      setObject(result.response.response);

      if (is('function', onConfirm)) onConfirm();

      if (!object?.id) onClose();
    }

    setLoading(false);
  }, [object, isValid, form, onConfirm, onClose]);

  const onAddMediaConfirm = React.useCallback((value: IMedia[]) => {
    const valueNew = [...value].map(media => mediasToValue({
      id: media.id,
      name: media.name,
      mime: media.mime,
      meta: media.meta,
      versions: media.versions,
      thumbnails: media.thumbnails,
      added_at: AmauiDate.milliseconds
    }));

    form.onChange('media', valueNew);
  }, [form]);

  const onOpenMedia = React.useCallback(() => {
    media.open({
      allowed: [],
      filters: {
        mime: 'all'
      },
      selected: (form.values['media']?.value || []).filter(Boolean),
      multiple: true,
      onConfirm: onAddMediaConfirm
    });
  }, [media, onAddMediaConfirm]);

  const onAudioRecorderConfirm = React.useCallback(async (value: Blob) => {
    setLoading('voice recording');

    const media = await audioFix(value);

    // validate
    // 140 mb maximum
    if (media.size > (140 * 1e6)) {
      snackbars.add({
        color: 'error',
        primary: `Maximum allowed file size is 140 mb`
      });

      return;
    }

    const name = `Voice recording, ${format(new AmauiDate(), formats.entire)}`;

    // meta
    const meta: any = {};

    const result = await MediaService.add({
      name,

      meta,

      app: 'note',

      // media
      media
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      const mediaMongo = result?.response?.response;

      // Update the note
      const medias = form.values.media.value || [];

      medias.push(mediasToValue({
        id: mediaMongo?.id,
        name,
        mime: media.type,
        meta: mediaMongo?.meta,
        versions: mediaMongo?.versions,
        added_at: AmauiDate.milliseconds
      }));

      form.onChange('media', medias);
    }

    setLoading(false);
  }, [form, onConfirm]);

  const onSpeechToTextStart = React.useCallback(() => {
    refs.original.current = textToInnerHTML(refs.form.current.values.value.value);
  }, []);

  const onSpeechToTextStop = React.useCallback(() => {
    refs.original.current = '';
  }, []);

  const onSpeechToTextUpdate = React.useCallback((valueNew: any) => {
    refs.value.current!.innerHTML = refs.original.current;

    const div = window.document.createElement('div');

    div.innerHTML = valueNew;

    refs.value.current!.append(div);

    refs.form.current.onChange('value', innerHTMLToText(refs.value.current!.innerHTML));
  }, []);

  const modal: any = {
    read: <>
      {object?.value && (
        <Type
          version='b2'

          dangerouslySetInnerHTML={{
            __html: textToInnerHTML(object.value?.[0]?.value)
          }}

          style={{
            whiteSpace: 'normal'
          }}
        />
      )}

      {!!object?.media?.length && (
        <Medias
          values={object.media.map((item: any) => ({
            value: item
          }))}

          noName
        />
      )}
    </>,

    write: <>
      <Line
        gap={2}

        justify='unset'

        align='unset'

        flex

        fullWidth
      >
        <SmartTextField
          ref={refs.name}

          placeholder='Title'

          valueDefault={textToInnerHTML(form.values.name.value)}

          onChange={(valueNew: string) => form.onChange('name', valueNew)}

          additional={{
            version: 'l1'
          }}

          style={{
            whiteSpace: 'normal'
          }}
        />

        <SmartTextField
          ref={refs.value}

          placeholder='Note'

          valueDefault={textToInnerHTML(form.values.value.value)}

          onChange={(valueNew: string) => form.onChange('value', valueNew)}

          edit

          multiline

          mention
        />
      </Line>

      <Medias
        values={form.values.media.value?.map((item: any) => ({
          value: item
        }))}
      />
    </>
  };

  const propsOther: any = {
    size: 'small'
  };

  return (
    <ModalForm
      {...props}

      object={object}

      add={!object}

      {...modal}

      onSubmit={onNext}

      onNext={onNext as any}

      onClose={onClose}

      loading={loading}

      smaller

      footerLeftEnd={<>
        <SelectAColor
          valueDefault={form.values['color'].value}

          error={!!form.values['color'].error}

          helperText={form.values['color'].error}

          onChange={(valueNew: any) => form.onChange('color', valueNew, undefined)}
        />
      </>}

      footerRightStart={<>
        <SpeechToText
          onData={onSpeechToTextUpdate}

          onChange={onSpeechToTextUpdate}

          onStart={onSpeechToTextStart}

          onStop={onSpeechToTextStop}

          language={signedIn?.organization?.personalization?.settings?.languages?.speech}

          {...propsOther}
        />

        {!!form.value.value?.length && (
          <TextToSpeech
            read={getElementText(textToInnerHTML(form.value.value))}

            language={signedIn?.organization?.personalization?.settings?.languages?.speech}

            {...propsOther}
          />
        )}

        <AudioRecorder
          onConfirm={(value: Blob) => onAudioRecorderConfirm(value)}

          disabled={loading === 'voice recording'}

          {...propsOther}
        />

        <Tooltip
          name='Add media'
        >
          <IconButton
            onClick={onOpenMedia}

            {...propsOther}
          >
            <IconMaterialFolderOpenRounded />
          </IconButton>
        </Tooltip>
      </>}

      footerLeftStartRead={<>
        {object?.color && (
          <Color
            color={object.color}
          />
        )}

        {object?.archived && (
          <Tooltip
            name='Archived'
          >
            <span
              style={{
                lineHeight: 0
              }}
            >
              <IconMaterialArchiveRounded
                size='small'
              />
            </span>
          </Tooltip>
        )}

        {object?.tags?.length && (
          <Tags
            value={object.tags}

            style={{
              width: 'auto'
            }}
          />
        )}
      </>}

      footerRightStartRead={<>
        {!!form.value.value?.length && (
          <TextToSpeech
            read={getElementText(textToInnerHTML(form.value.value))}

            {...propsOther}
          />
        )}
      </>}
    />
  );
});

export default Element;
