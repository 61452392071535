import React from 'react';

import { Select } from '@amaui/ui-react';
import { ISelect } from '@amaui/ui-react/Select/Select';
import { classNames, style } from '@amaui/style-react';

const useStyle = style(theme => ({
  root: {
    '&.amaui-Select-wrapper': {
      '& .amaui-ListItem-text-primary': {
        whiteSpace: 'nowrap'
      }
    }
  },

  wrapper: {
    '&.amaui-Select-wrapper': {
      minWidth: '140px',

      '& > *': {
        width: '100%'
      }
    }
  },

  '@media only screen and (max-width: 768px)': {
    $wrapper: {
      '&.amaui-Select-wrapper': {
        minWidth: '100% !important',

        '& > *': {
          width: '100%'
        }
      }
    }
  }
}), { name: 'amaui-app-Select' });

const Element: React.FC<ISelect> = React.forwardRef((props: ISelect, ref: any) => {
  const {
    MenuProps,
    WrapperProps,

    className,

    ...other
  } = props;

  const { classes } = useStyle(props);

  return (
    <Select
      ref={ref}

      version='outlined'

      autoWidth={false}

      MenuProps={{
        portal: true,

        ...MenuProps,

        ListProps: {
          style: {
            maxHeight: 240,
            overflow: 'hidden auto'
          },

          ...MenuProps?.ListProps
        }
      }}

      className={classNames([
        classes.root,
        className
      ])}

      WrapperProps={{
        className: classes.wrapper,

        ...WrapperProps
      }}

      {...other}
    />
  );
});

Element.displayName = 'amaui-app-Select';

export default Element;
