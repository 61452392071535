import React from 'react';

import { FormForm, Page } from 'ui';
import { FormService } from 'services';

const Forms = React.forwardRef(() => {

  const properties = React.useMemo(() => ({
    name: { name: 'Name' },
    description: { name: 'Description' },
    version: { name: 'Version' },
    progress: { name: 'Progress' },
    active: { name: 'Active' },
    archived: { name: 'Archived' }
  }), []);

  return (
    <Page
      name='Forms'

      service={FormService}

      columns={[
        properties.name,
        properties.active,
        properties.archived
      ]}

      search={['active', 'archived']}

      options={['active', 'archived']}

      properties={properties}

      app='form'

      collection='forms'

      route='/forms'

      Form={FormForm}

      updateEntire
    />
  );
});

export default Forms;
