import React from 'react';

import { Form, FormRow, Forms, Image, Line, Type, useForm, useSnackbars } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';
import { ValidationError } from '@amaui/errors';

import { Button, TextField, useSubscription } from 'ui';
import { AuthService, UserService } from 'services';
import { ISignedIn, getErrorMessage } from 'other';

const useStyle = style(theme => ({
  root: {

  },

  ...theme.classes(theme)
}), { name: 'amaui-app-route-UserSettingsSecurity' });

const UserSettingsSecurity = React.forwardRef((props: any, ref: any) => {
  const {
    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const snackbars = useSnackbars();

  const [loading, setLoading] = React.useState<any>(false);
  const [MFAAppImage, setMFAAppImage] = React.useState<any>();

  const form = useForm({
    values: {
      'password': {
        name: 'password',
        isValid: 'password',
        messages: {
          isValid: 'Password must have min 7 characters, with at least one lowercase, uppercase and a number character'
        },
        required: true
      },
      'password_confirm': {
        name: 'password confirm',
        required: true,
        method: [
          (value, { form: formCurrent }) => {
            const password = formCurrent.values['password'];

            if (value !== password.value) throw new ValidationError(`Password confirm has to match the password`)
          }
        ]
      }
    }
  });

  const refs = {
    form: React.useRef(form)
  };

  refs.form.current = form;

  const onSubmitPassword = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();

    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading('password');

    const result = await UserService.updatePassword({
      user: {
        settings: {
          security: {
            password: refs.form.current.value.password
          }
        }
      }
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: result.response?.meta?.message || 'Updated'
      });

      // clean up
      refs.form.current.clear();
    }

    setLoading(false);
  }, []);

  const onActivateMFAApp = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();

    setLoading('mfa-app-activate');

    const result = await UserService.mfaAppActivate();

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: result.response?.meta?.message || 'MFA using authenticator app activated'
      });

      setMFAAppImage(result.response.response);

      await AuthService.me();
    }

    setLoading(false);
  }, [form]);

  const onInactivateMFAApp = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();

    setLoading('mfa-app-inactivate');

    const result = await UserService.mfaAppInactivate();

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: result.response?.meta?.message || 'MFA using authenticator app inactivated'
      });

      setMFAAppImage('');

      await AuthService.me();
    }

    setLoading(false);
  }, [form]);

  const MFAApp = signedIn?.user?.settings?.security?.mfa?.app;

  return (
    <Line
      ref={ref}

      gap={4}

      fullWidth

      className={classNames([
        className,
        classes.root,
        classes.wrapper
      ])}

      {...other}
    >
      <Forms>
        <Form
          onSubmit={onSubmitPassword}

          name='Password'

          description='Update to a new password'

          footer={(
            <Button
              loading={loading === 'password'}

              type='submit'

              size='small'
            >
              Update
            </Button>
          )}

          size='small'
        >
          <FormRow>
            <TextField
              key='password'

              name='Password'

              type='password'

              value={form.values['password'].value}

              error={form.values['password'].error}

              helperText={form.values['password'].error}

              onChange={(valueNew: any) => form.onChange('password', valueNew)}
            />

            <TextField
              key='password_confirm'

              name='Password Confirm'

              type='password'

              value={form.values['password_confirm'].value}

              error={form.values['password_confirm'].error}

              helperText={form.values['password_confirm'].error}

              onChange={(valueNew: any) => form.onChange('password_confirm', valueNew)}
            />
          </FormRow>
        </Form>

        <Form
          name='Multi factor authentication (MFA)'

          description='Make your authentication more secure, with additional authentication, using an authenticator app ie. Google Authenticator app'

          wrapper

          size='small'
        >
          {MFAAppImage && (
            <Line
              align='flex-start'

              fullWidth
            >
              <Line
                gap={0.5}

                fullWidth
              >
                <Type
                  version='l2'
                >
                  QR Code for authenticator app
                </Type>

                <Type
                  version='b3'
                >
                  Use app like Google Authenticator app, and scan the QR code.
                </Type>
              </Line>

              <Image
                src={MFAAppImage}

                alt=''

                align='start'
              />
            </Line>
          )}

          {!MFAApp && !MFAAppImage && (
            <Button
              onClick={onActivateMFAApp}

              size='small'

              disabled={loading === 'mfa-app-activate'}
            >
              Enable MFA Authentication
            </Button>
          )}

          {MFAApp && (
            <Button
              onClick={onInactivateMFAApp}

              size='small'

              disabled={loading === 'mfa-app-inactivate'}
            >
              Remove MFA Authentication
            </Button>
          )}
        </Form>
      </Forms>
    </Line>
  );
});

export default UserSettingsSecurity;
