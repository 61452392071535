import React from 'react';
import { Helmet } from 'react-helmet';

import { Try, arrayMoveItem, copy, debounce, decodeObjectValue, download, fileToValue, getGoogleFontsURL, getObjectValue, is, merge, parse, textToInnerHTML } from '@amaui/utils';
import { Card, CardMain, Chip, FileChoose, Frame, IconButton, Line, Slide, Type, useSnackbars } from '@amaui/ui-react';
import { AmauiTheme, Theme, classNames, style, useAmauiTheme } from '@amaui/style-react';
import { IPage, ITemplate, ITheme, IValue, Website } from '@amaui/api-utils';
import { IBaseElement } from '@amaui/ui-react/types';
import { AmauiDate, format } from '@amaui/date';

// import IconMaterialSaveRounded from '@amaui/icons-material-rounded-react/IconMaterialSave';
import IconMaterialDownloadRounded from '@amaui/icons-material-rounded-react/IconMaterialDownload';
import IconMaterialUploadRounded from '@amaui/icons-material-rounded-react/IconMaterialUpload';
import IconMaterialInterestsRounded from '@amaui/icons-material-rounded-react/IconMaterialInterests';
import IconMaterialCrop75Rounded from '@amaui/icons-material-rounded-react/IconMaterialCrop75';
import IconMaterialKeyboardArrowDownRounded from '@amaui/icons-material-rounded-react/IconMaterialKeyboardArrowDown';

import { FormTemplateAdd, Button, Elements, PrePageBuilderModal } from 'ui';
import { FONTS, FONT_FAMILY, formats, getErrorMessage, getMediaUrl } from 'other';
import { AppService, WebsiteService } from 'services';
import { Templates } from 'pages/main';
import ModalUpdate from './ModalUpdate';
import config from 'config';

const Default = React.lazy(() => import('@amaui/themes/themes/Default/Default'));
const Modern = React.lazy(() => import('@amaui/themes/themes/Modern/Modern'));
const Lady = React.lazy(() => import('@amaui/themes/themes/Lady/Lady'));
const Sport = React.lazy(() => import('@amaui/themes/themes/Sport/Sport'));

const useStyle = style(theme => ({
  root: {

  },

  main: {
    overflowY: 'hidden',
    overflowX: 'auto',
    padding: '4px 4px 0'
  },

  optionsForms: {
    padding: '16px 24px',
    maxHeight: 'clamp(240px, 350px, 40vh)',
    overflowY: 'auto'
  },

  options: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    maxHeight: '60vh',
    zIndex: 1400000000,
    background: theme.palette.background.primary[theme.palette.light ? 'secondary' : 'tertiary'],
    boxShadow: theme.palette.light ? '0px 4px 24px rgba(0, 0, 0, 0.04)' : '0px 4px 24px rgba(255, 255, 255, 0.14)'
  },

  optionsHeader: {
    padding: 12
  },

  optionsMain: {
    overflow: 'hidden auto'
  },

  optionsVersion: {
    padding: '2px 24px'
  },

  card: {
    '&.amaui-Card-root': {
      width: '100%',
      maxWidth: 240,
      outline: '0px solid',
      transition: theme.methods.transitions.make(['border-radius', 'outline'])
    },

    '& .amaui-CardMain-root': {
      padding: '20px 24px'
    }
  },

  selected: {
    outline: '2px solid !important',
    outlineColor: theme.methods.palette.color.value('secondary', 30)
  },

  pageBuilderHeader: {
    overflow: 'auto hidden',
    padding: '12px 24px'
  },

  pageBuilderMain: {
    '&.amaui-Line-root': {
      border: '0.5px solid rgba(0, 0, 0, 0.14)',
      borderRadius: 16,
      boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.05), 0px 2px 1px -1px rgba(0, 0, 0, 0.02), 0px 1px 3px 0px rgba(0, 0, 0, 0.08)',
      height: 0,
      margin: '0 4px 8px',
      minHeight: '15vh',
      overflow: 'hidden',
      width: 'calc(100% - 8px)',
      overflowY: 'auto'
    }
  },

  pageBuilderHeaderWrapper: {
    overflow: 'auto hidden'
  }
}), { name: 'amaui-app-PageBuilder' });

export interface IPageBuilder extends IBaseElement {
  mode?: 'update' | 'preview';

  page?: Partial<IPage>;

  value?: IValue[];

  onChange?: (value: IValue[]) => any;

  url?: string;
}

const PageBuilder: React.FC<IPageBuilder> = React.forwardRef((props, ref: any) => {
  const {
    mode = 'update',

    page,

    value: value_,

    onChange: onChange_,

    url,

    version,

    object,

    website: website_,

    template: template_,

    noSaveAsTemplate,

    className,

    children,

    ...other
  } = props;

  const snackbars = useSnackbars();
  const theme = useAmauiTheme();

  const { classes } = useStyle();

  const [value, setValue] = React.useState(value_ !== undefined ? value_ : []);
  const [openUpdate, setOpenUpdate] = React.useState<any>();
  const [openOptions, setOpenOptions] = React.useState<any>();
  const [versionUI, setVersionUI] = React.useState<any>('ui');
  const [origin, setOrigin] = React.useState('my');
  const [selectedTemplate, setSelectedTemplate] = React.useState<ITemplate>();
  // const [showAll, setShowAll] = React.useState(false);
  const [website, setWebsite] = React.useState<Website>();
  const [template, setTemplate] = React.useState(template_);
  const [openPreAdd, setOpenPreAdd] = React.useState(false);

  const refs = {
    value: React.useRef(value),
    pageBuilder: React.useRef<any>(),
    openOptions: React.useRef(openOptions),
    theme: React.useRef(theme),
    template: React.useRef<any>()
  };

  refs.value.current = value;

  refs.openOptions.current = openOptions;

  refs.theme.current = theme;

  const init = React.useCallback(async () => {
    const id = website_?.id || website_?._id;

    if (!website_ || website_?.added_at || website_?.placeholder) {
      if (website_?.added_at) {
        setWebsite(website_);
      }

      return;
    }

    if (!id) return;

    const response = await WebsiteService.get(website_?.id);

    if (response.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(response)
      });
    }
    else {
      setWebsite(response.response.response);
    }
  }, [website_]);

  const onOpenPreAdd = React.useCallback(() => {
    refs.template.current = null;

    setOpenPreAdd(true);
  }, []);

  const onClosePreAdd = React.useCallback(() => {
    refs.template.current = null;

    setOpenPreAdd(false);
  }, []);

  const onAddPreAdd = React.useCallback((aiPage: any) => {
    const value_ = (is('array', aiPage?.value) ? aiPage?.value : [aiPage?.value])!.map((item: any) => ({
      ...item,

      props: decodeObjectValue(item?.props)
    }));

    onUpdate(value_);

    setOpenPreAdd(false);
  }, []);

  const onConfirmPreAdd = React.useCallback(() => {
    if (refs.template.current) {
      const templateNew = refs.template.current;

      setTemplate(templateNew);

      const value_ = (is('array', templateNew.value) ? templateNew.value : [templateNew.value])!.map((item: any) => ({
        ...item,

        props: decodeObjectValue(item?.props)
      }));

      onUpdate(value_);
    }

    refs.template.current = null;
  }, []);

  const onSelectTemplatePreAdd = React.useCallback((item: any) => {
    refs.template.current = item;
  }, []);

  React.useEffect(() => {
    // init
    init();
  }, [website_]);

  React.useEffect(() => {
    if (template !== template_) setTemplate(template_);
  }, [template_]);

  const onOptionsClose = React.useCallback(() => {
    setTimeout(() => {
      setVersionUI('ui');
      setOrigin('my');
      setSelectedTemplate(null as any);
    }, 700);

    setOpenOptions((previous: any) => ({
      ...previous,

      open: false
    }));
  }, []);

  React.useEffect(() => {
    if (value_ !== undefined && value !== value_) setValue(value_);
  }, [value_]);

  React.useEffect(() => {
    onOptionsClose();
  }, [mode]);

  const initTheme = (withTimeout = true) => {
    const initMethod = () => {
      const theme = window.document.body.querySelector('.amaui-theme-widget-Theme') as HTMLIFrameElement;

      if (!theme) return;

      // Add initial text value
      // to all the Type elements
      const elements: any = Array.from(theme.querySelectorAll(`[data-version=Type]`));

      elements.forEach((element: HTMLElement) => {
        const path = element.dataset.path;

        const valueElement = getObjectValue(refs.value.current, `${path}.elements`);

        if (valueElement !== undefined) element.innerHTML = textToInnerHTML(valueElement);
      });
    };

    initMethod();

    if (withTimeout) setTimeout(initMethod, 140);
  };

  const onOpenUpdate = React.useCallback(() => {
    setOpenUpdate((previous: any) => ({
      ...previous,

      open: true
    }));
  }, []);

  const onCloseUpdate = React.useCallback(() => {
    setOpenUpdate((previous: any) => ({
      ...previous,

      open: false
    }));
  }, []);

  const onChange = React.useCallback((valueNew: any) => {
    if (!props.hasOwnProperty('value')) setValue(valueNew);

    if (is('function', onChange_)) onChange_!(valueNew);
  }, [initTheme, onChange_]);

  const onUpdate = React.useCallback(debounce((valueNew: any) => {
    onChange(valueNew);
  }, 14), []);

  const onAddTemplate = React.useCallback((newPage = false) => {
    if (selectedTemplate) {
      const value_ = (is('array', selectedTemplate.value) ? selectedTemplate.value : [selectedTemplate.value])!.map((item: any) => ({
        ...item,

        props: decodeObjectValue(item?.props)
      }));

      if (newPage) onUpdate(value_);
      else {
        const valueNew = refs.value.current || [];

        valueNew.push(...value_);

        onUpdate(valueNew);
      }
    }
  }, [selectedTemplate]);

  const onAddUI = React.useCallback((version: string) => {
    const element = {
      version,
      props: {}
    };

    const version_ = version?.toLowerCase();

    if (['links'].includes(version_)) {
      element.props = {
        name: 'My name',
        short_description: 'My summary'
      };
    }
    else if (['section boxes'].includes(version_)) {
      element.props = {};
    }
    else if (['section cards'].includes(version_)) {
      element.props = {};
    }
    else if (['section carousel'].includes(version_)) {
      element.props = {};
    }
    else if (['section reviews'].includes(version_)) {
      element.props = {};
    }
    else if (['section contact'].includes(version_)) {
      element.props = {};
    }
    else if (['section image gallery'].includes(version_)) {
      element.props = {};
    }
    else if (['section media'].includes(version_)) {
      element.props = {
        MediaProps: {
          color: 'primary'
        }
      };
    }
    else if (['section logos'].includes(version_)) {
      element.props = {};
    }
    else if (['section text media'].includes(version_)) {
      element.props = {
        mediaPosition: 'left',
        TitleProps: {
          version: 'h1'
        },
        backgroundColor: 'default',
        title: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry' s standard dummy text ever yup.`,
        description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.

  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`
      };
    }
    else if (['section timeline'].includes(version_)) {
      element.props = {};
    }
    else if (['section watch'].includes(version_)) {
      element.props = {
        WatchProps: {
          color: 'primary',
          size: 'regular',
          version: 'modern'
        }
      };
    }
    else if (['section action'].includes(version_)) {
      element.props = {
        size: 'regular',
        text: 'Button',

        ButtonProps: {
          tonal: true,
          color: 'primary',
          size: 'regular',
          version: 'filled'
        }
      };
    }
    else if (['post', 'skill', 'portfolio', 'resume', 'support page'].includes(version_)) {
      element.props = {
        inline: true
      };
    }

    const valueNew = [...refs.value.current];

    if (refs.openOptions.current.replace !== undefined) valueNew[refs.openOptions.current.replace] = element;
    else valueNew.push(element);

    onUpdate(valueNew);
  }, []);

  // const onReset = React.useCallback(() => {
  //   onUpdate([]);
  // }, []);

  const onAdd = React.useCallback((event: MouseEvent) => {
    event.stopPropagation();

    setOpenOptions({
      open: true,
      openVersion: 'elements'
    });
  }, []);

  const onMove = React.useCallback((to: 'up' | 'down', index: number) => {
    const length = refs.value.current?.length;

    if (
      (to === 'up' && index > 0) ||
      (to === 'down' && index < length - 1)
    ) {
      const valueNew = [...(refs.value.current || [])];

      arrayMoveItem(valueNew, index, to === 'up' ? index - 1 : index + 1);

      onUpdate(valueNew);
    }
  }, [onUpdate]);

  const onCopy = React.useCallback((index: number) => {
    const valueNew = [...(refs.value.current || [])];

    const item = copy(valueNew[index]);

    valueNew.push(item);

    onUpdate(valueNew);
  }, [onUpdate]);

  const onRemove = React.useCallback((index: number) => {
    if (index > -1) {
      const valueNew = [...(refs.value.current || [])];

      valueNew.splice(index, 1);

      onUpdate(valueNew);
    }
  }, [onUpdate]);

  const onMiniMenu = React.useCallback((version: string, index: number, event: MouseEvent) => {
    event.stopPropagation();

    if (version === 'update') {
      setOpenUpdate((previous: any) => ({
        ...previous,

        version,
        index,

        open: true
      }));
    }

    if (version === 'copy') onCopy(index);

    if (version === 'replace') {
      setOpenOptions({
        open: true,
        openVersion: 'elements',
        replace: index
      });
    }

    if (version === 'move-up') onMove('up', index);

    if (version === 'move-down') onMove('down', index);

    if (version === 'save') onSaveAsTemplate(refs.value.current[index], 'section');

    if (version === 'remove') onRemove(index);
  }, [onMove]);

  // Website theme 
  let ThemeElement: any;

  if (website?.theme?.name === 'Default') ThemeElement = <Default />;
  else if (website?.theme?.name === 'Modern') ThemeElement = <Modern />;
  else if (website?.theme?.name === 'Lady') ThemeElement = <Lady />;
  else if (website?.theme?.name === 'Sport') ThemeElement = <Sport />;
  else ThemeElement = <Modern />;

  const onAddNewTemplate = React.useCallback((valueNew = refs.value.current, version: 'page' | 'section' = 'page') => {
    AppService.pages.addSecondary.emit({
      open: true,
      version: 'mid',
      minWidth: 'rg',
      children: (
        <FormTemplateAdd
          version={version}

          object={{
            value: is('array', valueNew) ? valueNew : [valueNew]
          }}

          website={website}
        />
      )
    });
  }, [AppService.pages.addSecondary, website]);

  const onSaveAsTemplate = React.useCallback((element: any, version: 'page' | 'section' = 'page') => {
    onAddNewTemplate(element, version);
  }, [onAddNewTemplate]);

  const onSelect = React.useCallback((item: any) => {
    const isSelected = selectedTemplate?.id === item?.id;

    isSelected ? setSelectedTemplate(null as any) : setSelectedTemplate(item);
  }, [selectedTemplate]);

  const chipProps: any = {
    size: 'small'
  };

  const optionsElements: any = {
    ui: (
      <Line
        fullWidth
      >
        {['elements'].includes(openOptions?.openVersion) && (
          <Elements
            onAddUI={onAddUI}
          />
        )}
      </Line>
    ),

    templates: (
      <Line
        fullWidth
      >
        <Line
          gap={1}

          direction='row'

          align='center'

          style={{
            padding: '0 24px'
          }}
        >
          <Chip
            onClick={() => setOrigin('my')}

            selected={origin === 'my'}

            {...chipProps}
          >
            My templates
          </Chip>

          {website && (
            <Chip
              onClick={() => setOrigin('website')}

              selected={origin === 'website'}

              {...chipProps}
            >
              Website
            </Chip>
          )}

          {website?.theme && (
            <Chip
              onClick={() => setOrigin('theme')}

              selected={origin === 'theme'}

              {...chipProps}
            >
              Theme
            </Chip>
          )}
        </Line>

        <Templates
          version={['my', 'website'].includes(origin) ? 'website' : 'theme'}

          website={origin === 'website' && website}

          theme={origin === 'theme' && website?.theme}

          page={false}

          select

          itemProps={(item: any) => ({
            onClick: () => {
              onSelect(item);
            },

            'data-selected': item?.id === selectedTemplate?.id
          })}

          style={{
            minHeight: '30vh',
            maxWidth: 'unset',
            paddingInline: 24
          }}
        />
      </Line>
    )
  };

  const onImport = React.useCallback(async (files: any) => {
    if (!!files.length) {
      let valueImported: any = await fileToValue(files[0], 'text');

      if (valueImported) {
        valueImported = Try(() => parse(valueImported));

        if (is('array', valueImported)) onChange(valueImported);
      }
    }
  }, []);

  const onExport = React.useCallback(async (value_: any = refs.value.current) => {
    const toRemove = ['id', '_id', 'user', 'organization', 'project', 'added_at', 'updated_at'];

    toRemove.forEach(item => delete object[item]);

    download(`Page ${format(AmauiDate.amauiDate, formats.entire)}`, JSON.stringify(value_, null, 2), 'application/json');
  }, []);

  // const onChangeShowAll = React.useCallback((valueNew: any) => setShowAll(valueNew), []);

  const cards = [
    { name: 'Elements', value: 'ui', icon: IconMaterialCrop75Rounded },
    { name: 'Templates', value: 'templates', icon: IconMaterialInterestsRounded }
  ];

  const googleFonts: any = [];

  Object.keys(FONTS).forEach((item: string) => {
    (FONTS as any)[item].forEach((font: any) => {
      if (font.category === 'google') googleFonts.push({ ...font, weights: [400, 700] });
    });
  });

  const valueTheme = React.useMemo(() => {
    const getFallbacks = (value: any) => {
      for (const item of Object.keys(FONTS)) {
        const font = (FONTS as any)[item].find((itemFont: any) => itemFont?.label?.toLowerCase().includes(value?.toLowerCase()));

        if (font) {
          if (font.family === 'serif') return ['Times New Roman', 'Georgia', 'Garamond', 'serif'];
          else if (font.family === 'sans serif') return ['Helvetica', 'Helvetica Neue', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'sans-serif'];
          else if (font.family === 'monospace') return ['Courier New', 'Courier', 'monospace'];
        }
      }

      return [];
    };

    const options = website?.options || website?.theme?.options;

    const fonts = {
      primary: 'Montserrat',
      secondary: 'DM Sans',
      tertiary: 'Roboto Mono'
    };

    const font_family = copy(FONT_FAMILY);

    if (options?.theme?.typography?.font_family?.primary) {
      const fontFamily = options.theme.typography.font_family.primary;

      font_family.primary = [`${fontFamily?.includes(' ') ? `"${fontFamily}"` : fontFamily}`, ...getFallbacks(fontFamily)].join(',');

      fonts.primary = fontFamily;
    }

    if (options?.theme?.typography?.font_family?.secondary) {
      const fontFamily = options.theme.typography.font_family.secondary;

      font_family.secondary = [`${fontFamily?.includes(' ') ? `"${fontFamily}"` : fontFamily}`, ...getFallbacks(fontFamily)].join(',');

      fonts.secondary = fontFamily;
    }

    if (options?.theme?.typography?.font_family?.tertiary) {
      const fontFamily = options.theme.typography.font_family.tertiary;

      font_family.tertiary = [`${fontFamily?.includes(' ') ? `"${fontFamily}"` : fontFamily}`, ...getFallbacks(fontFamily)].join(',');

      fonts.tertiary = fontFamily;
    }

    const palette: any = merge(
      {
        color: {
          amaui: {
            main: '#fafa00'
          }
        }
      },
      options?.theme?.palette
    );

    palette.light = refs.theme.current.palette.light;

    if (palette.image) palette.image = getMediaUrl({ id: palette.image?.id?.toString() });

    const typography = {
      font_family: {
        primary: font_family.primary,
        secondary: font_family.secondary,
        tertiary: font_family.tertiary,
      },

      values: {
        d1: {
          fontFamily: font_family.primary, fontWeight: 700
        },

        d2: {
          fontFamily: font_family.primary, fontWeight: 700
        },

        d3: {
          fontFamily: font_family.primary, fontWeight: 700
        },

        h1: { fontFamily: font_family.primary, fontWeight: 700 },

        h2: {
          fontFamily: font_family.primary, fontWeight: 700
        },

        h3: { fontFamily: font_family.primary, fontWeight: 700 },

        t1: { fontFamily: font_family.primary, fontWeight: 700 },

        t2: { fontFamily: font_family.primary, fontWeight: 700 },

        t3: { fontFamily: font_family.primary, fontWeight: 700 },

        l1: {
          fontFamily: font_family.secondary,
          fontWeight: 700
        },

        l2: {
          fontFamily: font_family.secondary,
          fontWeight: 700
        },

        l3: {
          fontFamily: font_family.secondary,
          fontWeight: 700
        },

        b1: { fontFamily: font_family.secondary },

        b2: { fontFamily: font_family.secondary },

        b3: { fontFamily: font_family.secondary },

        m1: { fontFamily: font_family.tertiary },

        m2: { fontFamily: font_family.tertiary },

        m3: { fontFamily: font_family.tertiary },
      }
    };

    const ui = {
      elements: {
        amauiModal: {
          props: {
            default: {
              size: 'regular'
            }
          }
        },

        amauiModalMain: {
          props: {
            default: {
              align: 'center'
            }
          }
        },

        amauiButton: {
          props: {
            default: {
              color: 'inherit'
            }
          }
        },

        amauiFab: {
          props: {
            default: {
              color: 'primary'
            }
          }
        },

        amauiIconButton: {
          props: {
            default: {
              color: 'inherit'
            }
          }
        },

        amauiTooltip: {
          props: {
            default: {
              tonal: true,
              color: 'secondary'
            }
          }
        },

        amauiAutoComplete: {
          props: {
            default: {
              version: 'outlined'
            }
          }
        },

        amauiTextField: {
          props: {
            default: {
              version: 'outlined'
            }
          }
        },

        amauiSelect: {
          props: {
            default: {
              version: 'outlined'
            }
          }
        },

        amauiType: {
          props: {
            default: {
              color: 'inherit'
            }
          }
        },

        amauiDivider: {
          props: {
            default: {
              tonal: false
            }
          }
        }
      },

      features: (website?.options?.theme?.ui as any)?.features || 'regular'
    };

    const value = new AmauiTheme({
      palette,

      typography,

      ui,

      classes: (theme: ITheme) => ({
        textNotFound: {
          margin: '40px 0'
        }
      })
    });

    return value;
  }, [website, theme?.palette?.light]);

  // if (!website) return null;

  const modeUpdate = mode === 'update' && (
    <Line
      gap={0}

      flex

      fullWidth
    >
      <Line
        gap={0}

        direction='row'

        align='center'

        justify='flex-end'

        fullWidth

        className={classNames([
          classes.pageBuilderHeaderWrapper
        ])}
      >
        <Line
          gap={0}

          direction='row'

          align='center'

          justify='flex-start'

          className={classNames([
            classes.pageBuilderHeader
          ])}
        >
          {!noSaveAsTemplate && (
            <Button
              onClick={() => onAddNewTemplate()}

              version='text'

              size='small'
            >
              Save as template
            </Button>
          )}

          <FileChoose
            size='small'

            onChange={onImport}

            accept='application/json'

            start={null}

            version='text'
          >
            <IconMaterialUploadRounded size='small' /> Import
          </FileChoose>

          <Button
            version='text'

            size='small'

            onClick={() => onExport()}

            disabled={!value?.length}
          >
            <IconMaterialDownloadRounded size='small' /> Export
          </Button>

          <Button
            onClick={onOpenPreAdd}

            version='text'

            size='small'
          >
            Start with...
          </Button>

          <Button
            onClick={onAdd}

            size='small'
          >
            Add to page
          </Button>
        </Line>
      </Line>

      <Line
        flex

        fullWidth

        className={classNames([
          classes.pageBuilderMain
        ])}
      >
        {React.cloneElement(ThemeElement, {
          children: page && React.cloneElement(page as any, {
            mode,

            website,

            // showAll,

            onMiniMenu,

            'data-theme': true
          })
        })}
      </Line>
    </Line>
  );

  const modePreview = mode === 'preview' && (
    <Frame
      url={!url?.startsWith('http') ? `http${config.value.apps.websites.url.includes('localhost') ? '' : 's'}://${website?.short_name}.${config.value.apps.websites.urlHost}${url?.startsWith('/') ? '' : '/'}${url}` : url!}
    />
  );

  return <>
    {/* Fonts */}
    <Helmet>
      <link
        href={getGoogleFontsURL(googleFonts.map((item: any) => ({ ...item, name: item.label })))}
        rel='stylesheet'
      />
    </Helmet>

    <Line
      gap={0}

      flex

      fullWidth

      className={classNames([
        classes.root
      ])}

      {...other}
    >
      <Theme
        value={valueTheme}
      >
        {modeUpdate}

        {modePreview}

        {/* Update */}
        {mode === 'update' && <>
          <ModalUpdate
            open={openUpdate?.open}

            onOpen={onOpenUpdate}

            onClose={onCloseUpdate}

            value={value}

            onUpdate={onUpdate}

            {...openUpdate}
          />
        </>}
      </Theme>

      {/* Options */}
      {openOptions && (
        <Slide
          in={openOptions?.open}
        >
          <Line
            gap={0}

            fullWidth

            className={classes.options}
          >
            <Line
              gap={1}

              direction='row'

              align='center'

              justify='flex-end'

              fullWidth

              className={classes.optionsHeader}
            >
              {openOptions?.openVersion === 'elements' && versionUI === 'templates' && <>
                <Button
                  version='filled'

                  size='small'

                  onClick={() => onAddTemplate()}

                  disabled={!selectedTemplate}
                >
                  Add
                </Button>

                <Button
                  version='filled'

                  size='small'

                  onClick={() => onAddTemplate(true)}

                  disabled={!selectedTemplate}
                >
                  Update entire page
                </Button>
              </>}

              <IconButton
                onClick={onOptionsClose}
              >
                <IconMaterialKeyboardArrowDownRounded />
              </IconButton>
            </Line>

            <Line
              gap={2}

              flex

              fullWidth

              className={classes.optionsMain}
            >
              {openOptions?.openVersion === 'elements' && (
                <Line
                  gap={2}

                  direction='row'

                  align='center'

                  fullWidth

                  className={classes.optionsVersion}
                >
                  {cards.map((item: any, index: number) => (
                    <Card
                      key={index}

                      color='default'

                      elevation={4}

                      onClick={() => setVersionUI(item.value)}

                      button

                      className={classNames([
                        classes.card,
                        item.value === versionUI && classes.selected
                      ])}
                    >
                      <CardMain
                        gap={0.75}

                        align='center'
                      >
                        <item.icon
                          tonal

                          color='secondary'

                          size='medium'
                        />

                        <Type
                          version='l2'

                          align='center'
                        >
                          {item.name}
                        </Type>
                      </CardMain>
                    </Card>
                  ))}
                </Line>
              )}

              <Line
                fullWidth
              >
                {optionsElements[versionUI]}
              </Line>
            </Line>
          </Line>
        </Slide>
      )}
    </Line>

    <PrePageBuilderModal
      open={openPreAdd}

      website={website}

      theme={website?.theme}

      onAdd={onAddPreAdd}

      onConfirm={onConfirmPreAdd}

      onSelectTemplate={onSelectTemplatePreAdd}

      onClose={onClosePreAdd}
    />
  </>;
});

export default PageBuilder;
