import React from 'react';

import { is, textToInnerHTML } from '@amaui/utils';
import { DatePicker, FormRow, Line, Medias, SmartTextField, useForm, useSnackbars } from '@amaui/ui-react';
import { style } from '@amaui/style-react';
import { AmauiDate } from '@amaui/date';

import { ModalForm, OptionsSEO, OptionsShare, OptionsWebsites, PageBuilder, SelectMedia, TextField } from 'ui';
import { AppService, PortfolioService } from 'services';
import { getErrorMessage } from 'other';

const useStyle = style(theme => ({
  root: {

  },

  ...theme.classes(theme)
}), { name: 'amaui-app-Portfolio' });

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    object: object_,

    onConfirm
  } = props;

  const { classes } = useStyle();

  const snackbars = useSnackbars();

  const [object, setObject] = React.useState(object_);
  const [tab, setTab] = React.useState('Portfolio');
  const [loading, setLoading] = React.useState<any>(false);

  const form = useForm({
    values: {
      'name': {
        name: 'Name',
        value: object?.name,
        required: true,
        max: 1400,
        messages: {
          min: 'Name has to be min 1 characters',
          max: 'Name can be max 1400 characters'
        }
      },
      'description': {
        name: 'Description',
        value: object?.description,
        max: 4400,
        messages: {
          min: 'Description has to be min 1 characters',
          max: 'Description can be max 4400 characters'
        }
      },
      'url': {
        name: 'URL',
        value: object?.url,
        isValid: 'url'
      },
      'value.organization': {
        name: 'Organization',
        value: object?.value?.organization,
        max: 1400,
        messages: {
          min: 'Organization has to be min 1 characters',
          max: 'Organization can be max 1400 characters'
        }
      },
      'value.activity': {
        name: 'Activity',
        value: object?.value?.activity,
        max: 1400,
        messages: {
          min: 'Activity has to be min 1 characters',
          max: 'Activity can be max 1400 characters'
        }
      },
      'start': {
        name: 'Start date',
        value: object?.start || undefined,
        is: 'number'
      },
      'end': {
        name: 'End date',
        value: object?.end || undefined,
        is: 'number'
      },
      'media': {
        name: 'Media',
        value: [...object?.media || []],
        is: 'array',
        of: 'object'
      },
      'seo.name': {
        name: 'SEO name',
        value: object?.seo?.name,
        min: 1,
        max: 70,
        messages: {
          min: 'SEO name has to be min 1 characters',
          max: 'SEO name can be max 70 characters'
        }
      },
      'seo.description': {
        name: 'SEO description',
        value: object?.seo?.description,
        min: 1,
        max: 164,
        messages: {
          min: 'SEO description has to be min 1 characters',
          max: 'SEO description can be max 164 characters'
        }
      },
      'seo.image': {
        name: 'SEO image',
        value: object?.seo?.image
      },
      'websites': {
        name: 'Websites',
        value: object?.websites,
        is: 'array',
        of: 'object'
      },
      'options.share.active': {
        name: 'Options share active',
        value: object?.options?.share?.active,
        is: 'boolean'
      }
    }
  });

  const refs = {
    form: React.useRef(form)
  };

  refs.form.current = form;

  const init = React.useCallback(async () => {
    // init form
    initForm();
  }, [object]);

  const initForm = React.useCallback(() => {
    if (is('array', object?.websites)) object.websites = object.websites.map((objectWebsite: any) => ({
      ...objectWebsite,

      value: objectWebsite?.id
    }));

    form.onChange([
      ['seo.name', object?.seo?.name],
      ['seo.description', object?.seo?.description],
      ['seo.image', object?.seo?.image],
      ['websites', object?.websites || []]
    ]);
  }, [object, form]);

  React.useEffect(() => {
    // init
    init();
  }, []);

  const onClose = React.useCallback(() => {
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });
  }, []);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();
  }, []);

  const onNext = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();

    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const body = {
      ...refs.form.current.value
    };

    if (!body.start) body.start = null;

    if (!body.end) body.end = null;

    if (is('array', body.websites)) body.websites = body.websites.map((item: any) => ({
      id: item.id,
      name: item.name
    }));

    const result = !object?.id ? await PortfolioService.add({
      ...body,

      apps: ['resume']
    }) : await PortfolioService.update(object?.id, body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Portfolio ${!object?.id ? 'added' : 'updated'}`
      });

      setObject(result.response.response);

      if (is('function', onConfirm)) onConfirm();

      if (!object?.id) onClose();
    }

    setLoading(false);
  }, [object, form, onConfirm, onClose]);

  const onChangeTab = React.useCallback(async (value: any) => {
    await refs.form.current.validate();

    setTab(value);
  }, []);

  const modal: any = {
    'Portfolio': <>
      <Line
        gap={2}

        fullWidth

        className={classes.page}
      >
        <FormRow
          gap={2}

          fullWidth
        >
          <Line
            gap={1}

            fullWidth
          >
            <TextField
              name='Name'

              valueDefault={textToInnerHTML(form.values['name'].value)}

              onChange={(valueNew: any) => form.onChange('name', valueNew, undefined, { rerenderOnUpdate: false })}
            />

            <SmartTextField
              placeholder='Description'

              valueDefault={textToInnerHTML(form.values['description'].value || '')}

              onChange={(valueNew: any) => form.onChange('description', valueNew, undefined, { rerenderOnUpdate: false })}

              minRows={4}

              multiline

              edit
            />
          </Line>

          <TextField
            name='URL'

            valueDefault={form.values['url'].value}

            error={form.values['url'].error}

            helperText={form.values['url'].error}

            onChange={(valueNew: any) => form.onChange('url', valueNew, undefined, { rerenderOnUpdate: false })}

            type='url'

            fullWidth
          />

          <TextField
            name='Organization'

            valueDefault={form.values['value.organization'].value}

            error={form.values['value.organization'].error}

            helperText={form.values['value.organization'].error}

            onChange={(valueNew: any) => form.onChange('value.organization', valueNew, undefined, { rerenderOnUpdate: false })}

            fullWidth
          />

          <TextField
            name='Activity'

            valueDefault={form.values['value.activity'].value}

            error={form.values['value.activity'].error}

            helperText={form.values['value.activity'].error}

            onChange={(valueNew: any) => form.onChange('value.activity', valueNew, undefined, { rerenderOnUpdate: false })}

            fullWidth
          />

          <FormRow
            direction='row'

            row

            fullWidth
          >
            <DatePicker
              name='Start'

              value={form.values['start'].value ? new AmauiDate(form.values['start'].value) : form.values['start'].value}

              error={form.values['start'].error}

              helperText={form.values['start'].error}

              onChange={(valueNew: any) => form.onChange('start', (valueNew as AmauiDate)?.milliseconds)}

              max={new AmauiDate() as any}
            />

            <DatePicker
              name='End'

              value={form.values['end'].value ? new AmauiDate(form.values['end'].value) : form.values['end'].value}

              error={form.values['end'].error}

              helperText={form.values['end'].error}

              onChange={(valueNew: any) => form.onChange('end', (valueNew as AmauiDate)?.milliseconds)}

              max={new AmauiDate() as any}
            />
          </FormRow>

          <SelectMedia
            name='Media'

            value={form.values.media.value}

            mediaSelected={form.values.media.value}

            mime='all'

            onChange={(valueNew: any) => form.onChange('media', valueNew)}

            multiple
          />

          <Medias
            values={form.values.media.value?.map((item: any) => ({
              value: item
            }))}
          />
        </FormRow>
      </Line>
    </>,

    'Options': <>
      <Line
        gap={2}

        fullWidth

        className={classes.page}
      >
        <OptionsWebsites
          description='Websites this portfolio can be visible at'

          value={form.values.websites.value}

          onChange={(valueNew: any[]) => form.onChange('websites', valueNew)}
        />

        <OptionsShare
          form={form}
        />
      </Line>
    </>,

    'SEO': <>
      <Line
        gap={2}

        fullWidth

        className={classes.page}
      >
        <OptionsSEO
          app='resume'

          values={form.values}

          onChange={form.onChange}
        />
      </Line>
    </>,

    'Preview': <>
      {(object?.id && object?.active && !object?.private) && (
        <PageBuilder
          mode='preview'

          url={`/portfolios/${object.id}`}

          website={form.value.websites?.[0]}
        />
      )}
    </>
  };

  return (
    <ModalForm
      {...props}

      object={object}

      add

      tabDefault='Portfolio'

      tab={tab}

      tabs={['Portfolio', 'Options', 'SEO', ...((object?.id && object?.active && !object?.private) ? ['Preview'] : [])]}

      onChangeTab={onChangeTab}

      onSubmit={onSubmit}

      onNext={onNext}

      onClose={onClose}

      loading={loading}
    >
      {modal[tab]}
    </ModalForm>
  );
});

export default Element;
