import { Integration } from '@amaui/api-utils';
import AmauiSubscription from '@amaui/subscription';

import { BaseService } from './base';
import { IQuerySubscription, ISelectedSubscription } from 'other';

class IntegrationService extends BaseService<Integration> {
  public queryIntegrations = new AmauiSubscription<IQuerySubscription>();
  public selectedIntegrations = new AmauiSubscription<ISelectedSubscription>();

  public constructor() {
    super('integrations');

    this.queryObjectsInit(this.queryIntegrations);

    this.selectedObjectsInit(this.selectedIntegrations);
  }

}

export default new IntegrationService();
