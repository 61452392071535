import React from 'react';

import { ListItem, Select, Type } from '@amaui/ui-react';
import { classNames, style, useAmauiTheme } from '@amaui/style-react';

const useStyle = style(theme => ({
  root: {

  }
}), { name: 'amaui-app-SelectDirection' });

const SelectDirection = React.forwardRef((props: any, ref: any) => {
  const {
    className,

    ...other
  } = props;

  const theme = useAmauiTheme();

  const { classes } = useStyle();

  const items = [0, 0.5, 1, 2, 3, 4, 5, 7, 8, 9, 10, 15, 25, 50, 100];

  const options: any = [];

  items.forEach(item => options.push({ name: `${item * theme.space.unit}px`, value: item }));

  const selectProps: any = {
    size: 'small',

    autoWidth: false,

    MenuProps: {
      portal: true
    },

    ListProps: {
      style: {
        maxHeight: 240,
        overflowY: 'auto',
        overflowX: 'hidden'
      }
    }
  };

  return (
    <Select
      ref={ref}

      name='Gap'

      {...selectProps}

      {...other}

      className={classNames([
        className,
        classes.root
      ])}
    >
      {options.map((item: any, index: number) => (
        <ListItem
          key={index}

          primary={(
            <Type
              version='b3'
            >
              {item.name}
            </Type>
          )}

          value={item.value}

          size='small'

          button
        />
      ))}
    </Select>
  );
});

export default SelectDirection;
