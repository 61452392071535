import React from 'react';

import { IconButton, Tooltip } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';
import { removeObjectValue } from '@amaui/utils';

import IconMaterialRefreshRounded from '@amaui/icons-material-rounded-react/IconMaterialRefresh';

const useStyle = style(theme => ({
  root: {
    cursor: 'pointer',
    userSelect: 'none',
    transition: theme.methods.transitions.make('transform'),

    '&:active': {
      transform: 'scale(0.94)'
    }
  }
}), { name: 'amaui-app-Reset' });

const Reset = React.forwardRef((props: any, ref: any) => {
  const {
    value,

    onChange,

    values,

    properties,

    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const onClick = React.useCallback(() => {
    const valueNew = { ...value };

    properties.forEach((item: any) => removeObjectValue(valueNew, item));

    onChange('', valueNew, '');
  }, [value, onChange, properties]);

  return (
    <Tooltip
      name='Reset'
    >
      <IconButton
        ref={ref}

        size='small'

        onClick={onClick}

        className={classNames([
          className,
          classes.root
        ])}

        {...other}
      >
        <IconMaterialRefreshRounded
          size='small'
        />
      </IconButton>
    </Tooltip>
  );
});

export default Reset;
