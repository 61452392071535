import React from 'react';
import { useNavigate } from 'react-router-dom';

import { hash } from '@amaui/utils';
import { style } from '@amaui/style-react';
import { Divider, IconButton, Interaction, Line, List, ListItem, Menu, Tooltip, Type } from '@amaui/ui-react';

import IconMaterialOpenInNewRounded from '@amaui/icons-material-rounded-react/IconMaterialOpenInNew';
import IconMaterialAppsRounded from '@amaui/icons-material-rounded-react/IconMaterialApps';

import { ReactComponent as LogoWebsite } from 'assets/svg/logos/logo-website.svg';
import { ReactComponent as LogoResume } from 'assets/svg/logos/logo-resume.svg';
import { ReactComponent as LogoTasks } from 'assets/svg/logos/logo-task.svg';
import { ReactComponent as LogoNotes } from 'assets/svg/logos/logo-note.svg';
import { ReactComponent as LogoUrlShortener } from 'assets/svg/logos/logo-urlShortener.svg';
import { ReactComponent as LogoBlog } from 'assets/svg/logos/logo-blog.svg';
import { ReactComponent as LogoSupport } from 'assets/svg/logos/logo-support.svg';
import { ReactComponent as LogoChat } from 'assets/svg/logos/logo-chat.svg';
import { ReactComponent as LogoForm } from 'assets/svg/logos/logo-form.svg';
import { ReactComponent as LogoReservation } from 'assets/svg/logos/logo-reservation.svg';
import { ReactComponent as LogoContact } from 'assets/svg/logos/logo-contact.svg';
import { ReactComponent as LogoWorkDay } from 'assets/svg/logos/logo-workDay.svg';
import { ReactComponent as LogoLocation } from 'assets/svg/logos/logo-location.svg';
import { ReactComponent as LogoGYMManagement } from 'assets/svg/logos/logo-gym-management.svg';
import { ReactComponent as LogoPersonalTrainer } from 'assets/svg/logos/logo-personal-trainer.svg';

import { useSubscription } from 'ui';
import { AuthService, StorageService } from 'services';
import { ISignedIn, getAppRootURL } from 'other';
import config from 'config';

const useStyle = style(theme => ({
  root: {

  },

  menu: {
    height: '70vh',
    maxHeight: '384px',
    overflow: 'hidden auto',

    '&.amaui-Line-root': {
      width: '100vw',
      maxWidth: 330,
      borderRadius: theme.methods.shape.radius.value(4),
      boxShadow: '0px 4px 32px 0px rgba(0, 0, 0, 0.04)',
      background: theme.palette.light ? theme.palette.color.neutral[100] : theme.palette.color.neutral[20]
    }
  },

  mainApps: {
    padding: 16
  },

  mainLogo: {
    height: 44,
    color: theme.palette.text.default.primary
  },

  items: {
    padding: '12px 16px'
  },

  item: {
    position: 'relative',
    width: 94,
    padding: 8,
    borderRadius: 8,
    cursor: 'pointer',
    transition: theme.methods.transitions.make('transform'),
    userSelect: 'none',

    '& svg': {
      width: 41
    },

    '& .amaui-Type-root': {
      width: '100%',
      whiteSpace: 'normal',
      wordBreak: 'break-word'
    },

    '&:active': {
      transform: 'scale(0.94)'
    }
  }
}), { name: 'amaui-app-AppsMenu' });

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const navigate = useNavigate();

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const [open, setOpen] = React.useState(false);

  const onOpen = React.useCallback(() => {
    setOpen(true);
  }, []);

  const onClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  const to = React.useCallback((value: string) => {
    navigate(value);

    onClose();
  }, []);

  const is = signedIn?.user?.is;

  const apps = React.useMemo(() => {
    return [
      ...(is?.user ? [{ name: 'Website', logo: LogoWebsite, to: getAppRootURL('app_website', '/websites', signedIn), props: { className: 'amaui-logo-website' } }] : []),

      ...(is?.user ? [{ name: 'Chat', logo: LogoChat, to: getAppRootURL('app_chat', '/chats', signedIn), props: { className: 'amaui-logo-chat' } }] : []),

      ...(is?.user ? [{ name: 'URL Shortener', logo: LogoUrlShortener, to: getAppRootURL('app_urlShortener', '/url-shorteners', signedIn), props: { className: 'amaui-logo-url-shortener' } }] : []),

      ...(is?.user ? [{ name: 'Reservation', logo: LogoReservation, to: getAppRootURL('app_reservation', '/reservations', signedIn), props: { className: 'amaui-logo-reservation' } }] : []),

      ...(is?.user ? [{ name: 'Form', logo: LogoForm, to: getAppRootURL('app_form', '/forms', signedIn), props: { className: 'amaui-logo-form' } }] : []),

      ...(is?.user ? [{ name: 'Support', logo: LogoSupport, to: getAppRootURL('app_support', '/support-pages', signedIn), props: { className: 'amaui-logo-support' } }] : []),

      ...(is?.user ? [{ name: 'Location', logo: LogoLocation, to: getAppRootURL('app_location', '/locations', signedIn), props: { className: 'amaui-logo-location' } }] : []),

      ...(is?.user ? [{ name: 'Tasks', logo: LogoTasks, to: getAppRootURL('app_task', '/tasks', signedIn), props: { className: 'amaui-logo-task' } }] : []),

      ...(is?.user ? [{ name: 'Notes', logo: LogoNotes, to: getAppRootURL('app_note', '/notes', signedIn), props: { className: 'amaui-logo-note' } }] : []),

      ...(is?.user ? [{ name: 'Blog', logo: LogoBlog, to: getAppRootURL('app_blog', '/posts', signedIn), props: { className: 'amaui-logo-blog' } }] : []),

      ...(is?.user ? [{ name: 'Contact', logo: LogoContact, to: getAppRootURL('app_contact', '/contacts', signedIn), props: { className: 'amaui-logo-contact' } }] : []),

      ...(is?.user ? [{ name: 'Work days', logo: LogoWorkDay, to: getAppRootURL('app_workDay', '/work-days', signedIn), props: { className: 'amaui-logo-workDay' } }] : []),

      ...(is?.user ? [{ name: 'Resume', logo: LogoResume, to: getAppRootURL('app_resume', '/resumes', signedIn), props: { className: 'amaui-logo-resume' } }] : [])
    ];
  }, [hash(is)]);

  const onOpenLink = React.useCallback((url: string) => {
    window.open(url);
  }, []);

  const mainApps = React.useMemo(() => {
    return [
      { name: 'Personal Trainer', logo: LogoPersonalTrainer, to: `${config.value.apps.personal_trainer.url}?amaui_token=${StorageService.get('token')}` },
      { name: 'GYM Management', logo: LogoGYMManagement, to: `${config.value.apps.gym_management.url}?amaui_token=${StorageService.get('token')}` }
    ];
  }, []);

  const menu = (
    <Line
      gap={0}

      className={classes.menu}

      fullWidth
    >
      <Line
        gap={1.75}

        fullWidth

        style={{
          paddingBottom: 8
        }}
      >
        <Type
          version='t3'

          style={{
            padding: '24px 16px 0'
          }}
        >
          Main apps
        </Type>

        <List
          gap={1.75}

          fullWidth

          paddingVertical='none'

          noBackground
        >
          {mainApps.map((item, index) => (
            <ListItem
              key={index}

              onClick={() => onOpenLink(item.to)}

              primary={(
                <Line
                  gap={1}

                  direction='row'

                  align='center'
                >
                  <item.logo
                    className={classes.mainLogo}
                  />

                  <IconMaterialOpenInNewRounded
                    color='default'

                    size='small'
                  />
                </Line>
              )}

              size='small'

              button

              noBackground
            />
          ))}
        </List>
      </Line>

      <Divider />

      <Line
        gap={2}

        fullWidth

        className={classes.items}
      >
        <Type
          version='t3'
        >
          More apps
        </Type>

        <Line
          gap={0}

          direction='row'

          justify='flex-start'

          wrap='wrap'

          fullWidth
        >
          {apps?.map(item => (
            <Line
              key={item.name}

              gap={1}

              align='center'

              onClick={() => to(item.to)}

              className={classes.item}
            >
              <item.logo />

              <Type
                version='b3'

                align='center'
              >
                {item.name}
              </Type>

              <Interaction />
            </Line>
          ))}
        </Line>
      </Line>
    </Line>
  );

  return (
    <Menu
      open={open}

      onOpen={onOpen}

      onClose={onClose}

      name={menu}

      AppendProps={{
        offset: [0, 8],
        switch: false
      }}

      {...other}
    >
      <span>
        <Tooltip
          name='Apps'
        >
          <IconButton>
            <IconMaterialAppsRounded />
          </IconButton>
        </Tooltip>
      </span>
    </Menu>
  );
});

export default Element;
