import { Contact } from '@amaui/api-utils';
import AmauiSubscription from '@amaui/subscription';

import { BaseService } from './base';
import { IQuerySubscription, ISelectedSubscription } from 'other';

class ContactService extends BaseService<Contact> {
  public queryItems = new AmauiSubscription<IQuerySubscription>();
  public queryItemsPinned = new AmauiSubscription<IQuerySubscription>();
  public selectedItems = new AmauiSubscription<ISelectedSubscription>();

  public get paginationLimit() { return 1400; }

  public constructor() {
    super('contacts');

    this.queryObjectsInit(this.queryItems);

    this.queryObjectsInit(this.queryItemsPinned);

    this.selectedObjectsInit(this.selectedItems);
  }

}

export default new ContactService();
