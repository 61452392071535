import React from 'react';

import { Line, ListItem, Select, Type } from '@amaui/ui-react';
import { classNames, style, useAmauiTheme } from '@amaui/style-react';
import { capitalize } from '@amaui/utils';

const useStyle = style(theme => ({
  root: {

  },

  palette: {
    width: 17,
    height: 17,
    boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.07), 0px 2px 1px -1px rgba(0, 0, 0, 0.04), 0px 1px 3px 0px rgba(0, 0, 0, 0.11)',
    borderRadius: '50%'
  }
}), { name: 'amaui-app-SelectColor' });

const SelectColor = React.forwardRef((props: any, ref: any) => {
  const {
    except = [],

    className,

    ...other
  } = props;

  const theme = useAmauiTheme();

  const { classes } = useStyle();

  const versions = ['default', 'neutral', 'primary', 'secondary', 'tertiary', 'quaternary', 'info', 'success', 'warning', 'error'].filter(item => !except.includes(item));

  const colorOptions: any = [];

  versions.forEach((version: any) => {
    colorOptions.push({
      name: capitalize(version),
      color: version === 'default' ? theme.palette.text.default.primary : (theme.palette.color as any)[version]?.main,
      value: version
    });
  });

  const selectProps: any = {
    size: 'small',

    autoWidth: false,

    MenuProps: {
      portal: true
    },

    ListProps: {
      style: {
        maxHeight: 240,
        overflowY: 'auto',
        overflowX: 'hidden'
      }
    }
  };

  return (
    <Select
      ref={ref}

      name='Color'

      {...selectProps}

      {...other}

      className={classNames([
        className,
        classes.root
      ])}
    >
      {colorOptions.map((item: any, index: number) => (
        <ListItem
          key={index}

          start={(
            <Line
              className={classes.palette}

              style={{
                background: item.color
              }}
            />
          )}

          startAlign='center'

          primary={(
            <Type
              version='b3'
            >
              {item.name}
            </Type>
          )}

          value={item.value}

          size='small'

          button
        />
      ))}
    </Select>
  );
});

export default SelectColor;
