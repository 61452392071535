import React from 'react';

import { is } from '@amaui/utils';
import { IconButton, Line, List, ListItem, Menu, Tooltip, useConfirm, useSnackbars } from '@amaui/ui-react';
import { IConfirmOpen } from '@amaui/ui-react/Confirm/Confirm';
import { classNames, style } from '@amaui/style-react';
import { Filter } from '@amaui/api-utils';

import IconMaterialFilterAltRounded from '@amaui/icons-material-rounded-react/IconMaterialFilterAlt';
import IconMaterialAddRounded from '@amaui/icons-material-rounded-react/IconMaterialAdd';
import IconMaterialDeleteRounded from '@amaui/icons-material-rounded-react/IconMaterialDelete';

import { FormFilter, Button, useSubscription } from 'ui';
import { AppService, FilterService } from 'services';
import { IQuerySubscription, getErrorMessage } from 'other';

const useStyle = style(theme => ({
  root: {

  },

  add: {
    '&.amaui-Button-root': {
      marginTop: '8px !important'
    }
  },

  addEmpty: {
    '&.amaui-Button-root': {
      marginTop: 0
    }
  },

  list: {
    '&.amaui-List-root': {
      minWidth: 154,
      maxWidth: '170px !important',
      maxHeight: 240,
      overflowY: 'auto',

      '& .amaui-ListItem-middle': {
        margin: 0
      }
    }
  }
}), { name: 'amaui-app-Filters' });

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    onChange: onChange_,

    model,

    query,

    // className,

    // ...other
  } = props;

  const { classes } = useStyle();

  const snackbars = useSnackbars();
  const confirm = useConfirm();

  const queryItems = useSubscription<IQuerySubscription>(FilterService.queryItems);
  // const selectedFilters = useSubscription<ISelectedSubscription>(FilterService.selectedItems);

  const init = React.useCallback(async () => {
    const result = await FilterService.queryItems.value!.query({
      query: {
        query: {
          model
        }
      }
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
  }, [model]);

  React.useEffect(() => {
    // init
    init();
  }, [model]);

  const onRemove = React.useCallback(async (filter: Filter, event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    const confirmed = await (confirm.open as (value: IConfirmOpen) => Promise<any>)({
      name: 'Remove a filter',
      description: 'Are you sure you want to remove this filter?'
    });

    if (!confirmed) return;

    const result = await FilterService.remove(filter.id);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else FilterService.queryItems.value!.refetch();
  }, []);

  const onChange = React.useCallback((value: Filter) => {
    if (is('function', onChange_)) onChange_(value);
  }, [onChange_]);

  const onAddFilter = React.useCallback(() => {
    AppService.pages.addTertiary.emit({
      open: true,
      version: 'mid',
      minWidth: 'rg',
      children: (
        <FormFilter
          onConfirm={FilterService.queryItems.value!.refetch.bind(FilterService.queryItems.value)}

          model={model}

          query={query}
        />
      )
    });
  }, [AppService.pages.addTertiary, query, model]);

  const ListItemProps: any = {
    menuCloseOnClick: true,

    PrimaryProps: {
      version: 'b3'
    }
  };

  const menuList = (queryItems?.response || []).map((item: any, index: number) => (
    <ListItem
      key={index}

      primary={item?.name}

      onClick={() => onChange(item)}

      end={(
        <Tooltip
          name='Remove'

          color='secondary'
        >
          <IconButton
            onClick={(event: any) => onRemove(item, event)}

            size='small'
          >
            <IconMaterialDeleteRounded />
          </IconButton>
        </Tooltip>
      )}

      button

      {...ListItemProps}
    />
  ));

  return <>
    <Menu
      name={(methodItem: any) => (
        <Line
          gap={0}

          align='center'

          fullWidth
        >
          {!!menuList.length && (
            <List
              color='default'

              menu

              className={classes.list}

              size='small'
            >
              {menuList.map((item: any, index: number) => (
                React.cloneElement(item, methodItem(item, index))
              ))}
            </List>
          )}

          <Button
            size='small'

            color='default'

            onClick={onAddFilter}

            className={classNames([
              classes.add,
              classes.addEmpty
            ])}
          >
            <IconMaterialAddRounded
              size='small'
            />

            Add new filter
          </Button>
        </Line>
      )}

      ListProps={{
        className: classes.list,
        size: 'small'
      }}
    >
      <span>
        <Tooltip
          name='Saved filters'
        >
          <IconButton
            size='small'
          >
            <IconMaterialFilterAltRounded />
          </IconButton>
        </Tooltip>
      </span>
    </Menu>
  </>;
});

export default Element;
