import React from 'react';

import { is, merge, textToInnerHTML } from '@amaui/utils';
import { Label, Line, SmartTextField, Switch, useForm, useSnackbars } from '@amaui/ui-react';
import { style } from '@amaui/style-react';
import { Website } from '@amaui/api-utils';

import { ModalForm, TextField, Themes } from 'ui';
import { AppService, WebsiteService } from 'services';
import { getErrorMessage } from 'other';

const useStyle = style(theme => ({
  root: {

  },

  surface: {
    '&.amaui-Surface-root': {
      background: theme.palette.color.primary[theme.palette.light ? 99 : 5]
    }
  },

  ...theme.classes(theme)
}), { name: 'amaui-app-Website' });

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    object: object_,

    onConfirm
  } = props;

  const { classes } = useStyle();

  const snackbars = useSnackbars();

  const [object, setObject] = React.useState(object_);
  const [tab, setTab] = React.useState('Website');
  const [loading, setLoading] = React.useState(false);

  const form = useForm({
    values: {
      'name': {
        name: 'Name',
        value: object?.name,
        required: true,
        max: 1400,
        messages: {
          min: 'Name has to be min 1 characters',
          max: 'Name can be max 1400 characters'
        }
      },
      'short_name': {
        name: 'Short name',
        value: object?.short_name,
        required: true,
        is: 'string',
        isValid: 'domain-name'
      },
      'description': {
        name: 'Description',
        value: object?.description,
        max: 4400,
        messages: {
          min: 'Description has to be min 1 characters',
          max: 'Description can be max 4400 characters'
        }
      },

      'theme': {
        name: 'Theme',
        value: object?.theme,
        is: 'object'
      },

      'active': {
        name: 'Active',
        value: object?.active,
        is: 'boolean'
      },
      'archived': {
        name: 'Archived',
        value: object?.archived,
        is: 'boolean'
      }
    }
  });

  const refs = {
    form: React.useRef(form)
  };

  refs.form.current = form;

  const onClose = React.useCallback(() => {
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });
  }, []);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();
  }, []);

  const onNext = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();

    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const body: Website = {
      ...refs.form.current.value,

      options: {
        theme: {
          palette: {
            light: 'auto'
          }
        }
      }
    };

    if (body.theme) {
      body.options = merge(body.options, body.theme.options || {});

      body.theme = {
        id: body.theme.id,
        name: body.theme.name
      };
    }

    const result = !object?.id ? await WebsiteService.add(body) : await WebsiteService.update(object?.id, body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Website ${!object?.id ? 'added' : 'updated'}`
      });

      setObject(result.response.response);

      if (is('function', onConfirm)) onConfirm();

      if (!object?.id) onClose();
    }

    setLoading(false);
  }, [object, form, onConfirm, onClose]);

  const onChangeThemes = React.useCallback((valueNew: any) => {
    refs.form.current.onChange('theme', valueNew);
  }, []);

  const onChangeTab = React.useCallback((value: any) => {
    setTab(value);
  }, []);

  const modal: any = {
    'Website': <>
      <Line
        className={classes.page}

        fullWidth
      >
        <Line
          fullWidth
        >
          <TextField
            name='Name'

            valueDefault={textToInnerHTML(form.values['name'].value)}

            onChange={(valueNew: any) => form.onChange('name', valueNew, undefined, { rerenderOnUpdate: false })}
          />

          <SmartTextField
            placeholder='Description'

            valueDefault={textToInnerHTML(form.values['description'].value || '')}

            onChange={(valueNew: any) => form.onChange('description', valueNew, undefined, { rerenderOnUpdate: false })}

            minRows={4}

            multiline

            edit
          />
        </Line>

        <TextField
          name='Short name'

          placeholder='i-love-trees'

          valueDefault={form.values.short_name?.value || ''}

          onChange={(valueNew: string) => form.onChange('short_name', valueNew, undefined, { rerenderOnUpdate: false })}

          error={!!form.values.short_name.error}

          helperText={form.values.short_name.error}

          fullWidth
        />

        <Line
          gap={1}

          fullWidth
        >
          <Label
            checked={form.values.active?.value}

            onChange={(valueNew: boolean) => form.onChange('active', valueNew)}

            error={!!form.values.active.error}

            helperText={form.values.active.error}
          >
            <Switch />

            Active
          </Label>

          <Label
            checked={form.values.archived?.value}

            onChange={(valueNew: boolean) => form.onChange('archived', valueNew)}

            error={!!form.values.archived.error}

            helperText={form.values.archived.error}
          >
            <Switch />

            Archived
          </Label>
        </Line>
      </Line>
    </>,

    'Theme': <>
      <Line
        className={classes.page}

        fullWidth
      >
        <Themes
          selected={form.value.theme}

          onChange={onChangeThemes}
        />
      </Line>
    </>
  };

  return (
    <ModalForm
      {...props}

      object={object}

      add

      name='Add new website'

      tabDefault='Website'

      tab={tab}

      tabs={['Website', 'Theme']}

      onChangeTab={onChangeTab}

      onSubmit={onSubmit}

      onNext={onNext}

      onClose={onClose}

      loading={loading}
    >
      {modal[tab]}
    </ModalForm>
  );
});

export default Element;
