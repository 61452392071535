import React from 'react';

import { innerHTMLToText, is, textToInnerHTML } from '@amaui/utils';
import { FormRow, Line, ListItem, useForm, useSnackbars } from '@amaui/ui-react';

import { ModalForm, ReadProperties, Select, SelectAColor, SmartTextField, TextField } from 'ui';
import { AppService, TagService } from 'services';
import { APP, appToName, getErrorMessage } from 'other';

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    object: object_,

    onConfirm
  } = props;

  const snackbars = useSnackbars();

  const [object, setObject] = React.useState(object_);
  const [loading, setLoading] = React.useState<any>(false);

  const form = useForm({
    values: {
      'name': {
        name: 'Name',
        value: object?.name,
        required: true,
        max: 1400,
        messages: {
          min: 'Name has to be min 1 characters',
          max: 'Name can be max 1400 characters'
        }
      },
      'description': {
        name: 'Description',
        value: object?.description,
        max: 4400,
        messages: {
          min: 'Description has to be min 1 characters',
          max: 'Description can be max 4400 characters'
        }
      },
      'apps': {
        name: 'App',
        value: object?.apps,
        is: 'array',
        of: 'string',
        in: APP
      },
      'color': {
        name: 'Color',
        value: object?.color || null
      }
    }
  });

  const refs = {
    form: React.useRef(form)
  };

  refs.form.current = form;

  const onClose = React.useCallback(() => {
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });
  }, []);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();
  }, []);

  const onNext = React.useCallback(async (event: SubmitEvent) => {
    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const body = {
      ...refs.form.current.value
    };

    const result = !object?.id ? await TagService.add(body) : await TagService.update(object?.id, body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Tag ${!object?.id ? 'added' : 'updated'}`
      });

      setObject(result.response.response);

      if (is('function', onConfirm)) onConfirm();

      if (!object?.id) onClose();
    }

    setLoading(false);
  }, [object, form, onConfirm, onClose]);

  const modal: any = {
    write: <>
      <FormRow
        gap={2}

        fullWidth
      >
        <TextField
          name='Name'

          valueDefault={form.values['name'].value}

          error={form.values['name'].error}

          helperText={form.values['name'].error}

          onChange={(valueNew: any) => form.onChange('name', valueNew, undefined, { rerenderOnUpdate: false })}
        />

        <SmartTextField
          name='Description'

          valueDefault={textToInnerHTML(form.values['description'].value || '')}

          error={form.values['description'].error}

          helperText={form.values['description'].error}

          onChange={(valueNew: any) => form.onChange('description', innerHTMLToText(valueNew), undefined, { rerenderOnUpdate: false })}

          minRows={4}

          multiline

          edit
        />

        <Select
          name='Apps'

          value={form.values.apps.value}

          error={!!form.values.apps.error}

          helperText={form.values.apps.error}

          onChange={(valueNew: string) => form.onChange('apps', valueNew)}

          multiple
        >
          {APP.map((item: any, index: number) => (
            <ListItem
              key={index}

              primary={appToName(item)}

              value={item}

              button
            />
          ))}
        </Select>

        <SelectAColor
          valueDefault={form.values['color'].value}

          error={!!form.values['color'].error}

          helperText={form.values['color'].error}

          onChange={(valueNew: any) => form.onChange('color', valueNew, undefined, { rerenderOnUpdate: false })}
        />
      </FormRow>
    </>,

    read: (
      <Line
        fullWidth
      >
        <ReadProperties
          object={object}

          values={[
            'color',
            'description',

            { name: 'Apps', value: object?.apps?.length ? object.apps.map((item: any) => appToName(item)).join(', ') : '' }
          ]}
        />
      </Line>
    )
  };

  return (
    <ModalForm
      {...props}

      object={object}

      add={!object}

      {...modal}

      onSubmit={onSubmit}

      onNext={onNext}

      onClose={onClose}

      loading={loading}

      smaller
    />
  );
});

export default Element;
