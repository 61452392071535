import React from 'react';

import { Page } from 'ui';
import { AuditLogService } from 'services';

const cleanValue = (value_: string) => {
  let value = value_;

  ['-', '_'].forEach(filter => {
    const expression = `\\${filter}`;
    const regexp = new RegExp(expression, 'g');

    value = value ? value.replace(regexp, ' ') : '';
  });

  return `${value.charAt(0).toUpperCase()}${value.slice(1)}`;
};

const AuditLogs = React.forwardRef(() => {

  const properties = React.useMemo(() => ({
    version: { name: 'Version', method: (item: any) => cleanValue(item.version) }
  }), []);

  return (
    <Page
      name='Audit logs'

      service={AuditLogService}

      columns={[
        properties.version
      ]}

      properties={properties}

      app='amaui'

      collection='auditLogs'

      route='/audit-logs'

      noSelect

      noTags

      noAdd

      noUpdateProject

      noRemove
    />
  );
});

export default AuditLogs;
