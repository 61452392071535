import React from 'react';

import { Menu } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';

const useStyle = style(theme => ({
  root: {

  }
}), { name: 'amaui-app-MoreMenu' });

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    items,

    element,

    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const onContextMenu = React.useCallback((event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  return (
    <Menu
      alignment='start'

      menuItems={items}

      onContextMenu={onContextMenu}

      {...other}

      include={[element].filter(Boolean)}

      includeParentQueries={['.amaui-ObjectCalendar-root', '.amaui-repeat', '.amaui-repeat-end', '.amaui-time', '.amaui-reminder']}

      portal

      ignoreNonExisting

      ListProps={{
        color: 'themed',
        size: 'small',

        ...other?.ListProps
      }}

      className={classNames([
        '.amaui-MoreMenu-root',
        classes.root
      ])}
    />
  );
});

export default Element;
