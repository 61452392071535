import React from 'react';

import { is, textToInnerHTML } from '@amaui/utils';
import { Form, IconButton, Line, ModalFooter, ModalHeader, ModalMain, ModalTitle, Tab, Tabs, Tooltip } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';
import { IForm } from '@amaui/ui-react/Form/Form';
import { TPropsAny } from '@amaui/themes/types';

import IconMaterialCropPortraitRounded from '@amaui/icons-material-rounded-react/IconMaterialCropPortrait';
import IconMaterialEditRounded from '@amaui/icons-material-rounded-react/IconMaterialEdit';
import IconMaterialKeyboardArrowDownRounded from '@amaui/icons-material-rounded-react/IconMaterialKeyboardArrowDown';
import IconMaterialDeleteRounded from '@amaui/icons-material-rounded-react/IconMaterialDelete';

import { Button } from 'ui';

const useStyle = style(theme => ({
  root: {

  },

  root_smaller: {
    maxHeight: '90vh'
  },

  header: {
    position: 'relative',
    padding: '12px 12px 4px 24px',
    minHeight: 40,
    maxWidth: theme.maxWidth,

    '&.amaui-ModalHeader-root': {
      padding: 0
    }
  },

  headerName: {
    wordBreak: 'break-word',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'normal'
  },

  main: {
    height: 0,
    overflow: 'hidden auto'
  },

  main_smaller: {
    position: 'relative',
    padding: '4px 24px 24px',
    minHeight: 84,
    maxHeight: 'calc(100vh - 240px)',
    maxWidth: theme.maxWidth,
    overflow: 'hidden auto'
  },

  modalFooter: {
    overflow: 'auto hidden',
    zIndex: 1,

    '&.amaui-ModalFooter-root': {
      padding: 12
    }
  },

  footer: {
    maxWidth: theme.maxWidth
  }
}), { name: 'amaui-app-ModalForm' });

export interface IModalForm extends IForm {
  object?: any;

  name?: string;

  tabDefault?: string;
  tab?: string;

  tabs?: string[];

  onNext?: (event?: any) => any;
  onClose?: () => any;

  loading?: boolean;

  onSubmit?: (event: SubmitEvent) => any;
  onChangeTab?: (value: string) => any;
  onChangeMode?: (value: string) => any;

  onRemove?: any;

  add?: boolean;
  smaller?: boolean;

  read?: any;
  write?: any;
  update?: any;
  more?: any;
  actions?: any;

  noUpdate?: boolean;

  footer?: boolean;

  footerLeftStart?: any;
  footerLeftEnd?: any;

  footerRightStart?: any;
  footerRightEnd?: any;

  footerLeftStartRead?: any;
  footerLeftEndRead?: any;

  footerRightStartRead?: any;
  footerRightEndRead?: any;

  WrapperProps?: any;
  MoreProps?: any;
  NextProps?: TPropsAny;
  MainProps?: TPropsAny;
  ModalMainProps?: TPropsAny;
  CloseProps?: TPropsAny;
}

const ModalForm: React.FC<IModalForm> = React.forwardRef((props, ref: any) => {
  const {
    object,

    name: name_,

    tabDefault,
    tab: tab_,

    tabs,

    loading,

    add,

    footer = true,

    onSubmit,
    onNext,
    onClose,

    onRemove,

    onChangeTab: onChangeTab_,
    onChangeMode,

    footerLeftStart,
    footerLeftEnd,

    footerRightStart,
    footerRightEnd,

    footerLeftStartRead,
    footerLeftEndRead,

    footerRightStartRead,
    footerRightEndRead,

    className,

    read,
    write,
    update,
    more,

    actions,

    smaller,

    singular,

    noWrite,

    style,

    children,

    MoreProps,
    NextProps,
    MainProps,
    ModalMainProps,
    CloseProps
  } = props;

  const { classes } = useStyle();

  const [tab, setTab] = React.useState<string>(tabDefault!);
  const [mode, setMode] = React.useState<any>(add ? 'update' : 'read');

  const refs = {
    mode: React.useRef(mode)
  };

  refs.mode.current = mode;

  React.useEffect(() => {
    if (tab !== tab_) setTab(tab_!);
  }, [tab_]);

  const onChangeTab = React.useCallback((value: any) => {
    if (!props.hasOwnProperty('tab')) setTab(value);

    if (is('function', onChangeTab_)) onChangeTab_!(value);
  }, [onChangeTab_]);

  const onModeToggle = React.useCallback(() => {
    const valueNew = refs.mode.current === 'read' ? 'update' : 'read';

    setMode(valueNew);

    if (is('function', onChangeMode)) onChangeMode!(valueNew);
  }, [onChangeMode]);

  let name = name_ !== undefined ? name_ : object?.name;

  if (mode === 'update') name = !object ? `Add new${singular ? ` ${singular}` : ''}` : `Update${singular ? ` ${singular}` : ''}`;

  const buttonsHeaderProps: any = {
    size: 'regular'
  };

  const buttonsFooterProps: any = {
    size: 'small'
  };

  return (
    <Form
      gap={0}

      onSubmit={onSubmit}

      flex

      className={classNames([
        className,
        classes.root
      ])}

      style={style}
    >
      <ModalHeader
        gap={0}
      >
        <Line
          direction='row'

          align='center'

          justify='space-between'

          fullWidth

          className={classNames([
            classes.header
          ])}
        >
          <ModalTitle
            className={classes.headerName}

            dangerouslySetInnerHTML={{
              __html: textToInnerHTML(name)
            }}
          />

          <Line
            gap={0}

            align='center'

            justify='center'

            direction='row'
          >
            {actions}

            {(write || update) && !add && !noWrite && (
              <Tooltip
                name={mode === 'read' ? 'Update' : 'View'}
              >
                <IconButton
                  color='inherit'

                  onClick={onModeToggle}

                  {...buttonsHeaderProps}
                >
                  {mode === 'read' ? <IconMaterialEditRounded /> : <IconMaterialCropPortraitRounded />}
                </IconButton>
              </Tooltip>
            )}

            <Tooltip
              name='Close'
            >
              <IconButton
                color='inherit'

                onClick={onClose}

                {...buttonsHeaderProps}
              >
                <IconMaterialKeyboardArrowDownRounded />
              </IconButton>
            </Tooltip>
          </Line>
        </Line>

        {tabs?.length && (
          <Tabs
            value={tab}

            onChange={onChangeTab}

            // center 
            justify='flex-start'

            size='small'
          >
            {tabs.map((item: any, index: number) => (
              <Tab
                key={index}

                name={item}

                value={item}
              />
            ))}
          </Tabs>
        )}
      </ModalHeader>

      <ModalMain
        align='center'

        justify='unset'

        fullWidth

        {...ModalMainProps}
      >
        <Line
          align='unset'

          justify='unset'

          flex

          fullWidth

          {...MainProps}

          className={classNames([
            MainProps?.className,
            classes[`main${smaller ? '_smaller' : ''}`]
          ])}
        >
          {children !== undefined ? children : <>
            {(read || write || update) && (
              <Line
                fullWidth
              >
                {mode === 'read' ? read : write || update}
              </Line>
            )}

            {mode === 'update' && more && (
              <Form
                name='More'

                openDefault

                accordion

                wrapper

                AccordionMainProps={{
                  gap: 1.5,

                  style: {
                    marginTop: 8
                  }
                }}

                {...MoreProps}
              >
                {more}
              </Form>
            )}
          </>}
        </Line>
      </ModalMain>

      {mode === 'read' && (footerLeftStartRead || footerLeftEndRead || footerRightStartRead || footerRightEndRead) && (
        <ModalFooter
          direction='row'

          align='center'

          justify='center'

          className={classNames([
            classes.modalFooter
          ])}
        >
          <Line
            direction='row'

            align='center'

            justify='space-between'

            fullWidth

            className={classNames([
              classes.footer
            ])}
          >
            <Line
              gap={1}

              direction='row'

              align='center'
            >
              {footerLeftStartRead}

              {footerLeftEndRead}
            </Line>

            <Line
              gap={1}

              direction='row'

              align='center'
            >
              {footerRightStartRead}

              {footerRightEndRead}
            </Line>
          </Line>
        </ModalFooter>
      )}

      {footer && mode === 'update' && (
        <ModalFooter
          direction='row'

          align='center'

          justify='center'

          className={classNames([
            classes.modalFooter
          ])}
        >
          <Line
            direction='row'

            align='center'

            justify='space-between'

            fullWidth

            className={classNames([
              classes.footer
            ])}
          >
            <Line
              gap={1}

              direction='row'

              align='center'
            >
              {footerLeftStart}

              <Button
                version='text'

                color='inherit'

                onClick={onClose}

                disabled={loading}

                {...buttonsFooterProps}

                {...CloseProps}
              >
                Close
              </Button>

              {onRemove && (
                <Tooltip
                  name='Remove'
                >
                  <IconButton
                    size='small'

                    onClick={onRemove}
                  >
                    <IconMaterialDeleteRounded />
                  </IconButton>
                </Tooltip>
              )}

              {footerLeftEnd}
            </Line>

            <Line
              gap={1}

              direction='row'

              align='center'
            >
              {footerRightStart}

              {onNext && (
                <Button
                  onClick={onNext}

                  loading={loading}

                  {...buttonsFooterProps}

                  {...NextProps}
                >
                  {(add && !object) ? 'Add' : 'Update'}
                </Button>
              )}

              {footerRightEnd}
            </Line>
          </Line>
        </ModalFooter>
      )}
    </Form>
  );
});

export default ModalForm;
