import React from 'react';

import { download, fileToValue, is } from '@amaui/utils';
import { Checkbox, FileChoose, IconButton, Line, List, ListItem, ListSubheader, Menu, Tooltip, Type, useMediaQuery, useSnackbars } from '@amaui/ui-react';
import { capitalize, style } from '@amaui/style-react';
import { AmauiDate, format } from '@amaui/date';
import { Contact, IContact } from '@amaui/api-utils';

import IconMaterialIosShareRounded from '@amaui/icons-material-rounded-react/IconMaterialIosShare';
import IconMaterialContactPageRounded from '@amaui/icons-material-rounded-react/IconMaterialContactPage';
import IconMaterialPushPinRounded from '@amaui/icons-material-rounded-react/IconMaterialPushPin';
import IconMaterialMoreVertRounded from '@amaui/icons-material-rounded-react/IconMaterialMoreVert';

import { FormContact, NoResults, Page } from 'ui';
import { ContactService } from 'services';
import { IPageItemMethodOptions } from 'ui/layout/Page';
import { exportVCard, formats, getErrorMessage, importVCards } from 'other';

const useStyle = style(theme => ({
  root: {

  },

  name: {
    cursor: 'pointer'
  },

  table: {
    height: 0,
    overflow: 'hidden auto'
  },

  legend: {
    '&.amaui-Surface-root': {
      backgroundColor: 'transparent',
      borderBottom: `1px solid ${theme.palette.color.primary.main}`
    },

    '&.amaui-ListItem-wrapper': {
      zIndex: 4,

      '& .amaui-Type-root': {
        marginInlineStart: -2
      }
    }
  },

  item: {
    '&.amaui-ListItem-root': {
      zIndex: 1
    },

    '& .amaui-ListItem-start': {
      marginInlineStart: -12
    },

    '& .amaui-ListItem-end': {
      marginInlineEnd: -12
    }
  }
}), { name: 'amaui-app-route-Contacts' });

const Contacts = React.forwardRef(() => {
  const { classes } = useStyle();

  const snackbars = useSnackbars();
  const desktop = useMediaQuery('(min-width: 1024px)');

  const onExportAsVCard = React.useCallback(async (value: any = ContactService.selectedItems.value!.objects) => {
    const values = is('array', value) ? value : [value];

    const response = exportVCard(values);

    download(`amaui contacts ${format(AmauiDate.amauiDate, formats.entire)}.vcf`, response, 'text/vcf');

    snackbars.add({
      primary: `Contacts exported to vCard`
    });
  }, []);

  const onImportAsVCard = React.useCallback(async (files: any, options: any) => {
    const {
      setLoading
    } = options;

    if (!!files.length) {
      let value: any = await fileToValue(files[0], 'text');

      if (value) {
        const imports = importVCards(value);

        if (!imports.length) return;

        setLoading('import');

        const objects = imports.map((item: any) => ({
          ...item,

          apps: ['contact']
        }));

        const result = await ContactService.addMany({ objects });

        if (result.status >= 400) {
          snackbars.add({
            color: 'error',
            primary: getErrorMessage(result)
          });
        }
        else {
          snackbars.add({
            primary: `Contacts imported`
          });
        }

        // reset
        ContactService.selectedItems.value!.reset();

        // refetch
        ContactService.queryItems.value!.refetch();
        ContactService.queryItemsPinned.value!.refetch();

        setLoading(false);
      }
    }
  }, []);

  const onContextMenuPrevent = React.useCallback((event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const onStopPropagation = React.useCallback((event: MouseEvent) => {
    event.stopPropagation();
  }, []);

  const getItems = React.useCallback((options: IPageItemMethodOptions) => {
    const {
      queryItems,
      queryItemsPinned,

      selectedItems,

      loading,

      getMenuItems,

      onOpen,
      onPin,

      props: itemPropsMethod
    } = options;

    const contactsUnpinned = queryItems?.response || [];
    const contactsPinned = queryItemsPinned?.response || [];

    const initialsUnpinned: any = {};

    contactsUnpinned.forEach((contact: any) => {
      const initial = contact.initials?.[0] || 'other';

      if (!initialsUnpinned[initial]) initialsUnpinned[initial] = [];

      initialsUnpinned[initial].push(contact);
    });

    const initialsPinned: any = {};

    contactsPinned.forEach((contact: any) => {
      const initial = contact.initials?.[0] || 'other';

      if (!initialsPinned[initial]) initialsPinned[initial] = [];

      initialsPinned[initial].push(contact);
    });

    const lettersUnpinned = Object.keys(initialsUnpinned).sort((a, b) => {
      if (a === 'other' || b === 'other') return -1;

      return a.localeCompare(b);
    });

    const lettersPinned = Object.keys(initialsPinned).sort((a, b) => {
      if (a === 'other' || b === 'other') return -1;

      return a.localeCompare(b);
    });

    const getContacts = (contacts: IContact[], letters: any, initials: any,) => {
      if (!contacts?.length) return null;

      return (
        <Line
          gap={0}
        >
          {letters.map((letter: any) => (
            <List
              key={letter}

              size='small'

              paddingVertical='none'
            >
              <ListSubheader
                color='primary'

                size='small'

                className={classes.legend}
              >
                {capitalize(letter)}
              </ListSubheader>

              {initials[letter].map((item: Contact) => {
                const itemProps = itemPropsMethod(item);

                return (
                  <ListItem
                    key={item.id}

                    onClick={() => onOpen(item)}

                    {...itemProps}

                    primary={(
                      <Type>
                        {item.name}
                      </Type>
                    )}

                    start={(
                      <Checkbox
                        color='primary'

                        checked={!!selectedItems.isSelected(item)}

                        onChange={() => selectedItems.select(item, true)}

                        onClick={onStopPropagation}

                        size='small'
                      />
                    )}

                    end={<>
                      {item.pinned && (
                        <Tooltip
                          name='Unpin'
                        >
                          <IconButton
                            color='inherit'

                            size='small'

                            onClick={(event: MouseEvent) => {
                              onStopPropagation(event);

                              onPin(item);
                            }}

                            disabled={loading === 'pin'}
                          >
                            <IconMaterialPushPinRounded
                              size='small'
                            />
                          </IconButton>
                        </Tooltip>
                      )}

                      <Menu
                        menuItems={getMenuItems(item)}

                        onClick={onStopPropagation}

                        onContextMenu={onContextMenuPrevent}

                        ListProps={{
                          size: 'small'
                        }}
                      >
                        <IconButton
                          color='inherit'

                          size='small'

                          onClick={onStopPropagation}

                          onContextMenu={onContextMenuPrevent}
                        >
                          <IconMaterialMoreVertRounded />
                        </IconButton>
                      </Menu>
                    </>}

                    selected={selectedItems.isSelected(item)}

                    size='small'

                    button

                    className={classes.item}
                  />
                );
              })}
            </List>
          ))}
        </Line>
      );
    };

    return (
      <Line
        gap={2.4}

        align='unset'

        justify='unset'

        flex

        fullWidth

        className={desktop && classes.table}
      >
        {(queryItems.loaded && !(!!contactsUnpinned.length || !!contactsPinned.length)) && (
          <NoResults />
        )}

        {!!contactsPinned?.length && (
          <Line
            align='unset'

            justify='unset'

            fullWidth

            style={{
              flex: '0 0 auto'
            }}
          >
            <Type
              version='t3'
            >
              Pinned
            </Type>

            {getContacts(contactsPinned, lettersPinned, initialsPinned)}
          </Line>
        )}

        {!!contactsUnpinned?.length && (
          <Line
            align='unset'

            justify='unset'

            flex

            fullWidth
          >
            <Type
              version='t3'
            >
              Contacts
            </Type>

            {getContacts(contactsUnpinned, lettersUnpinned, initialsUnpinned)}
          </Line>
        )}
      </Line>
    );
  }, []);

  const properties = React.useMemo(() => ({
    name: { name: 'Name' },
    nickname: { name: 'Nickname' },
    initials: { name: 'Initials' },
    description: { name: 'Description' },
    version: { name: 'Version' },
    active: { name: 'Active' },
    archived: { name: 'Archived' },
    pinned: { name: 'Pinned' },
    color: { name: 'Color' }
  }), []);

  return (
    <Page
      name='Contacts'

      service={ContactService}

      getItems={getItems}

      columns={[
        properties.name,
        properties.color,
        properties.active,
        properties.archived
      ]}

      search={['active', 'archived', 'color']}

      options={['active', 'archived']}

      properties={properties}

      getItemActions={(item: any) => {
        const IconProps: any = {
          color: 'inherit',
          size: 'small'
        };

        const values = [
          { primary: 'Export as vCard', onClick: () => onExportAsVCard(item), start: <IconMaterialIosShareRounded {...IconProps} /> },
        ];

        return values;
      }}

      getManyActions={(options: any) => {
        const {
          selectedItems
        } = options;

        const IconProps: any = {
          color: 'inherit',
          size: 'small'
        };

        const enabled: any = {};

        selectedItems.objects?.forEach((item: IContact) => {
          enabled.export = true;
        });

        const values = [
          { primary: 'Export as vCard', onClick: () => onExportAsVCard(), disabled: !enabled.export, start: <IconMaterialIosShareRounded {...IconProps} /> }
        ];

        return values;
      }}

      getMoreActions={(options: any) => {
        const {
          loading
        } = options;

        return (
          <FileChoose
            size='small'

            onChange={(...args) => onImportAsVCard(...args, options)}

            accept='text/vcard'

            start={(
              <IconMaterialContactPageRounded />
            )}

            version='text'

            disabled={loading === 'import'}
          >
            <span style={{ textTransform: 'none' }}>Import vCard</span>
          </FileChoose>
        );
      }}

      app='contact'

      collection='contacts'

      route='/contacts'

      Form={FormContact}

      pinned
    />
  );
});

export default Contacts;

