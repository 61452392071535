import React from 'react';

import { arrayMoveItem, capitalize, getID, wait } from '@amaui/utils';
import { Chip, Form, FormRow, IconButton, Line, ListItem, Modal, ModalFooter, ModalHeader, ModalMain, ModalTitle, TextField, Tooltip, Type, useForm } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';

import IconMaterialAddRounded from '@amaui/icons-material-rounded-react/IconMaterialAdd';
import IconMaterialContentCopyRounded from '@amaui/icons-material-rounded-react/IconMaterialContentCopy';
import IconMaterialKeyboardArrowUpRounded from '@amaui/icons-material-rounded-react/IconMaterialKeyboardArrowUp';
import IconMaterialKeyboardArrowDownRounded from '@amaui/icons-material-rounded-react/IconMaterialKeyboardArrowDown';
import IconMaterialRemoveRounded from '@amaui/icons-material-rounded-react/IconMaterialRemove';
import IconMaterialCloseRounded from '@amaui/icons-material-rounded-react/IconMaterialClose';

import { Button, List, Select } from 'ui';

const useStyle = style(theme => ({
  root: {
    paddingBottom: 40
  },

  name: {
    cursor: 'pointer'
  },

  modalMainMenu: {
    position: 'relative',
    overflowX: 'hidden',
    overflowY: 'auto'
  },

  modalMainRoot: {
    position: 'relative',
    padding: '0 4px'
  },

  surface: {
    '&.amaui-Surface-root': {
      background: theme.palette.color.primary[theme.palette.light ? 99 : 5]
    }
  }
}), { name: 'amaui-app-Menus' });

const Menus = React.forwardRef((props: any, ref: any) => {
  const {
    website,

    form,

    onNext,

    loading,

    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const [openMenu, setOpenMenu] = React.useState<any>();

  const formMenu = useForm({
    values: {
      'name': {
        name: 'Name',
        required: true,
        max: 1400,
        messages: {
          min: 'Name has to be min 1 characters',
          max: 'Name can be max 1400 characters'
        }
      },

      'version': {
        name: 'Version',
        is: 'string',
        in: ['regular']
      },
      'placement': {
        name: 'Placement',
        is: 'string',
        in: ['header', 'mobile', 'aside', 'footer']
      },
      'position': {
        name: 'Position',
        is: 'string',
        in: ['start', 'middle', 'end']
      },

      'active': {
        name: 'Active',
        is: 'boolean'
      },

      'elements.start': {
        name: 'Elements start',
        is: 'array',
        of: 'object'
      },
      'elements.middle': {
        name: 'Elements middle',
        is: 'array',
        of: 'object'
      },
      'elements.end': {
        name: 'Elements end',
        is: 'array',
        of: 'object'
      }
    }
  });

  const refs = {
    website: React.useRef<any>(),
    formMenu: React.useRef<any>()
  };

  refs.website.current = website;

  refs.formMenu.current = formMenu;

  const onCloseMenu = React.useCallback(() => {
    setOpenMenu((previous: any) => ({
      ...previous,

      open: false
    }));
  }, []);

  const onNextMenu = React.useCallback(async () => {
    try {
      const valid = await formMenu.validate();

      if (!valid) return;

      const body = {
        id: getID(),

        version: 'regular',
        placement: 'header',
        position: 'middle',

        active: false,

        elements: {
          start: [],
          middle: [],
          end: []
        },

        ...refs.formMenu.current.value,
      };

      if (body.elements.start) body.elements.start = body.elements.start.map((item: any) => {
        delete item.order;

        return item;
      });

      if (body.elements.middle) body.elements.middle = body.elements.middle.map((item: any) => {
        delete item.order;

        return item;
      });

      if (body.elements.end) body.elements.end = body.elements.end.map((item: any) => {
        delete item.order;

        return item;
      });

      const valueNew = [...form.values.menus.value || []];

      if (openMenu?.index !== undefined) valueNew[openMenu.index] = body;
      else valueNew.push(body);

      form.onChange('menus', valueNew);
    }
    catch (error) { }

    onCloseMenu();
  }, [openMenu, form, formMenu, onCloseMenu]);

  const onAddMenuItem = React.useCallback(() => {
    const menuItems = [...(formMenu.values['elements.middle'].value || [])];

    menuItems.push({
      id: getID(),
      version: 'to',
      name: '',
      value: '',
      emphasis: 'regular',
      props: {}
    });

    formMenu.onChange('elements.middle', menuItems);
  }, [formMenu]);

  const onRemoveMenuItem = React.useCallback((item: any) => {
    const menuItems = [...(formMenu.values['elements.middle'].value || [])];

    const index = menuItems.findIndex(item_ => item_.id === item.id);

    if (index > -1) menuItems.splice(index, 1);

    formMenu.onChange('elements.middle', menuItems);
  }, [formMenu]);

  const onMoveMenuItem = React.useCallback((index: number, indexNew: number) => {
    const menuItems = [...(formMenu.values['elements.middle'].value || [])];

    arrayMoveItem(menuItems, index, indexNew);

    formMenu.onChange('elements.middle', menuItems);
  }, [formMenu]);

  const onActivate = React.useCallback((item: any, event: MouseEvent) => {
    event.stopPropagation();

    let menus = [...(form.values.menus.value || [])];

    const index = item.order;

    menus = menus.map((itemMenu: any) => {
      if (item.placement === itemMenu.placement) {
        itemMenu.active = false;
      }

      return itemMenu;
    });

    menus[index].active = true;

    form.onChange('menus', menus);
  }, [form]);

  const menuVersions: any = {};

  (form.values.menus.value || []).forEach((item: any, index: number) => {
    if (!menuVersions[item.placement]) menuVersions[item.placement] = [];

    item.order = index;

    menuVersions[item.placement].push(item);
  });

  const selectProps: any = {
    autoWidth: false,

    MenuProps: {
      portal: true
    }
  };

  const buttonProps: any = {
    size: 'small'
  };

  let modal = (
    <Modal
      open={openMenu?.open}

      onClose={onCloseMenu}

      minWidth='lg'

      maxWidth='lg'

      SurfaceProps={{
        tonal: true,
        color: 'primary',

        className: classNames([
          classes.surface
        ])
      }}
    >
      <ModalHeader>
        <Tooltip
          name='Close'
        >
          <IconButton
            onClick={onCloseMenu}

            style={{
              alignSelf: 'flex-end'
            }}
          >
            <IconMaterialCloseRounded />
          </IconButton>
        </Tooltip>

        <ModalTitle>
          {!openMenu?.item ? 'Add menu' : `Update ${openMenu.item.name}`}
        </ModalTitle>
      </ModalHeader>

      <ModalMain
        align='center'

        justify='unset'

        fullWidth

        className={classes.modalMainMenu}

        style={{
          padding: '24px 0'
        }}
      >
        <Line
          align='flex-start'

          justify='unset'

          flex

          fullWidth

          className={classes.modalMainRoot}
        >
          <TextField
            name='Name'

            valueDefault={formMenu.values['name'].value}

            error={!!formMenu.values['name'].error}

            helperText={formMenu.values['name'].error}

            onChange={(valueNew: any) => formMenu.onChange('name', valueNew, undefined, { rerenderOnUpdate: false })}

            fullWidth
          />

          <Select
            name='Placement'

            value={formMenu.values.placement.value || 'header'}

            onChange={(valueNew: any) => formMenu.onChange('placement', valueNew)}

            {...selectProps}
          >
            {[
              { name: 'Header', value: 'header' },
              { name: 'Mobile', value: 'mobile' },
              { name: 'Aside', value: 'aside' },
              { name: 'Footer', value: 'footer' }
            ].map((item, index: number) => (
              <ListItem
                key={index}

                primary={item.name}

                value={item.value}

                button
              />
            ))}
          </Select>

          <FormRow
            name='Menu items'

            description='Items in the menu text, link to a page, external link, call to action button, etc.'

            endAlign='center'

            end={(
              <Tooltip
                name='Add menu item'
              >
                <IconButton
                  onClick={() => onAddMenuItem()}
                >
                  <IconMaterialAddRounded />
                </IconButton>
              </Tooltip>
            )}

            fullWidth
          >
            {formMenu.values['elements.middle'].value?.map((itemMenu: any, indexItemMenu: number) => (
              <FormRow
                key={itemMenu.id}

                MainProps={{
                  gap: 1
                }}

                end={(
                  <Line
                    gap={0.5}

                    direction='row'

                    align='center'
                  >
                    <Tooltip
                      name='Move up'
                    >
                      <IconButton
                        onClick={() => onMoveMenuItem(indexItemMenu, indexItemMenu - 1)}

                        disabled={!indexItemMenu}

                        size='small'
                      >
                        <IconMaterialKeyboardArrowUpRounded />
                      </IconButton>
                    </Tooltip>

                    <Tooltip
                      name='Move down'
                    >
                      <IconButton
                        onClick={() => onMoveMenuItem(indexItemMenu, indexItemMenu + 1)}

                        disabled={indexItemMenu === (formMenu.values['elements.middle'].value?.length - 1)}

                        size='small'
                      >
                        <IconMaterialKeyboardArrowDownRounded />
                      </IconButton>
                    </Tooltip>

                    <Tooltip
                      name='Remove menu item'
                    >
                      <IconButton
                        onClick={() => onRemoveMenuItem(itemMenu)}

                        size='small'
                      >
                        <IconMaterialRemoveRounded />
                      </IconButton>
                    </Tooltip>
                  </Line>
                )}

                row

                fullWidth
              >
                <Select
                  name='Version'

                  value={itemMenu?.version || 'to'}

                  onChange={(valueNew: any) => formMenu.onChange('elements.middle', valueNew, `${indexItemMenu}.version`)}

                  {...selectProps}
                >
                  {[
                    { name: 'To', value: 'to' },
                    { name: 'Link', value: 'link' },
                    { name: 'Text', value: 'text' }
                  ].map((item, index: number) => (
                    <ListItem
                      key={index}

                      primary={item.name}

                      value={item.value}

                      button
                    />
                  ))}
                </Select>

                <Select
                  name='Emphasis'

                  value={itemMenu?.emphasis || 'regular'}

                  onChange={(valueNew: any) => formMenu.onChange('elements.middle', valueNew, `${indexItemMenu}.emphasis`)}

                  {...selectProps}
                >
                  {[
                    { name: 'Regular', value: 'regular' },
                    { name: 'Important', value: 'primary' }
                  ].map((item, index: number) => (
                    <ListItem
                      key={index}

                      primary={item.name}

                      value={item.value}

                      button
                    />
                  ))}
                </Select>

                {itemMenu?.version !== 'text' && (
                  <TextField
                    name='Name'

                    valueDefault={itemMenu?.name || ''}

                    onChange={(valueNew: any) => formMenu.onChange('elements.middle', valueNew, `${indexItemMenu}.name`, { rerenderOnUpdate: false })}
                  />
                )}

                <TextField
                  name='Value'

                  valueDefault={itemMenu?.value || ''}

                  onChange={(valueNew: any) => formMenu.onChange('elements.middle', valueNew, `${indexItemMenu}.value`, { rerenderOnUpdate: false })}
                />
              </FormRow>
            ))}
          </FormRow>
        </Line>
      </ModalMain>

      <ModalFooter>
        <Button
          version='text'

          color='inherit'

          onClick={onCloseMenu}

          {...buttonProps}
        >
          Close
        </Button>

        <Button
          onClick={onNextMenu}

          loading={loading === 'menu'}

          {...buttonProps}
        >
          Next
        </Button>
      </ModalFooter>
    </Modal>
  );

  const onOpenMenu = React.useCallback(async (item?: any) => {
    // clear
    // form object
    refs.formMenu.current.clear();

    await wait(14);

    const index = item?.order;

    if (item) {
      refs.formMenu.current.onChange([
        ['name', item.name],
        ['version', item.version],
        ['placement', item.placement],
        ['position', item.position],
        ['active', item.active],
        ['elements.start', item.elements?.start || []],
        ['elements.middle', item.elements?.middle || []],
        ['elements.end', item.elements?.end || []]
      ]);
    }

    setOpenMenu({
      open: true,
      item,
      index
    });
  }, [form]);

  const onCopyMenu = React.useCallback((item: any, event: MouseEvent) => {
    event.stopPropagation();

    const valueNew = [...form.values.menus.value || []];

    const object = {
      ...item,

      id: getID(),

      active: false,

      name: `${item.name} copy`
    };

    valueNew.push(object);

    form.onChange('menus', valueNew);
  }, [form, formMenu]);

  const onRemoveMenu = React.useCallback((item: any, event: MouseEvent) => {
    event.stopPropagation();

    const valueNew = [...form.values.menus.value || []];

    const index = valueNew.findIndex(item_ => item_.id === item.id);

    if (index > -1) valueNew.splice(index, 1);

    form.onChange('menus', valueNew);
  }, [form, formMenu]);

  const listProps: any = {

  };

  return <>
    <Line
      gap={2}

      flex

      fullWidth

      className={classes.root}

      {...other}
    >
      <Form
        end={(
          <Tooltip
            name='Add menu'
          >
            <IconButton
              onClick={() => onOpenMenu()}
            >
              <IconMaterialAddRounded />
            </IconButton>
          </Tooltip>
        )}

        footer={(
          <Button
            onClick={onNext}

            loading={loading}

            disabled={!form.value?.menus?.length}

            {...buttonProps}
          >
            Update
          </Button>
        )}

        wrapper

        style={{
          flex: '1 1 auto'
        }}
      >
        <Line
          flex

          fullWidth
        >
          {Object.keys(menuVersions).map((menuVersion: string) => (
            <FormRow
              key={menuVersion}

              name={capitalize(menuVersion)}

              fullWidth
            >
              <List
                {...listProps}
              >
                {menuVersions[menuVersion].map((item: any, index: number) => (
                  <ListItem
                    key={item.id}

                    primary={(
                      <Type
                        version='b2'
                      >
                        {item.name}
                      </Type>
                    )}

                    onClick={() => onOpenMenu(item)}

                    end={(
                      <Line
                        gap={0.5}

                        direction='row'

                        align='center'
                      >
                        <Chip
                          onClick={(event: MouseEvent) => onActivate(item, event)}

                          size='small'

                          disabled={item.active}
                        >
                          {item.active ? 'Selected' : 'Select'}
                        </Chip>

                        <Tooltip
                          name='Make a menu copy'
                        >
                          <IconButton
                            size='small'

                            onClick={(event: MouseEvent) => onCopyMenu(item, event)}
                          >
                            <IconMaterialContentCopyRounded />
                          </IconButton>
                        </Tooltip>

                        <Tooltip
                          name='Remove menu'
                        >
                          <IconButton
                            size='small'

                            onClick={(event: MouseEvent) => onRemoveMenu(item, event)}
                          >
                            <IconMaterialRemoveRounded />
                          </IconButton>
                        </Tooltip>
                      </Line>
                    )}

                    size='small'

                    button
                  />
                ))}
              </List>
            </FormRow>
          ))}
        </Line>
      </Form>
    </Line>

    {modal}
  </>;
});

export default Menus;
