import React from 'react';

import { ListItem, Select, Type } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';
import { cleanValue } from '@amaui/utils';

const useStyle = style(theme => ({
  root: {

  }
}), { name: 'amaui-app-SelectSize' });

const SelectSize = React.forwardRef((props: any, ref: any) => {
  const {
    options,

    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const sizes = options || ['small', 'regular', 'large'];

  const sizeOptions: any = [];

  sizes.forEach((item: any) => sizeOptions.push({ name: cleanValue(item?.name || item, { capitalize: true }), value: item?.value !== undefined ? item.value : item }));

  const selectProps: any = {
    size: 'small',

    autoWidth: false,

    MenuProps: {
      portal: true
    },

    ListProps: {
      style: {
        maxHeight: 240,
        overflowY: 'auto',
        overflowX: 'hidden'
      }
    }
  };

  return (
    <Select
      ref={ref}

      name='Size'

      {...selectProps}

      {...other}

      className={classNames([
        className,
        classes.root
      ])}
    >
      {sizeOptions.map((item: any, index: number) => (
        <ListItem
          key={index}

          primary={(
            <Type
              version='b3'
            >
              {item.name}
            </Type>
          )}

          value={item.value}

          size='small'

          button
        />
      ))}
    </Select>
  );
});

export default SelectSize;
