import React from 'react';

import { Checkbox, Form, FormRow, Label, Line, Modal, Slide, Switch, Tab, Tabs } from '@amaui/ui-react';
import { classNames, style, useAmauiTheme } from '@amaui/style-react';

import { ModalForm, OptionsForm, OptionsLinks, OptionsPortfolio, OptionsPost, OptionsReservation, OptionsResume, OptionsSectionAction, OptionsSectionBoxes, OptionsSectionCards, OptionsSectionCarousel, OptionsSectionContact, OptionsSectionImageGallery, OptionsSectionLogos, OptionsSectionMedia, OptionsSectionReviews, OptionsSectionTextMedia, OptionsSectionTimeline, OptionsSectionWatch, OptionsSkill, OptionsSupportPage, SelectColor, SelectMedia, SelectSize, SmartTextField } from 'ui';
import { cleanValue, getObjectValue, setObjectValue, textToInnerHTML } from '@amaui/utils';

import { getMediaUrl } from 'other';

const useStyle = style(theme => ({
  root: {
    '&.amaui-Surface-root': {
      background: theme.palette.color.primary[theme.palette.light ? 99 : 5],
      padding: 0
    }
  },

  update: {
    padding: '24px 24px 140px',
    height: 0,
    overflow: 'hidden auto',
    maxWidth: theme.maxWidth
  },

  preview: {
    position: 'relative',
    width: 154,
    height: 77,
    borderRadius: 12,
    overflow: 'hidden',
    boxShadow: theme.shadows.values.default[1]
  },

  backgroundImage: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  },

  backgroundVideo: {
    position: 'absolute',
    inset: '0',
    minHeight: '100%',
    minWidth: '100%',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: '1'
  },

  backgroundOverlay: {
    position: 'absolute',
    inset: '0',
    zIndex: '4'
  },

  backgroundOverlayBlur: {
    backdropFilter: 'blur(12px)'
  },

  noData: {
    '& .amaui-FormRow-main': {
      display: 'none'
    }
  },

  options: {
    paddingBottom: 16
  }
}), { name: 'amaui-app-ModalUpdate' });

const ModalUpdate = React.forwardRef((props: any, ref: any) => {
  const {
    value,

    open,

    index,

    version,

    onUpdate,

    onClose,

    children,

    ...other
  } = props;

  const theme = useAmauiTheme();

  const { classes } = useStyle();

  const [tab, setTab] = React.useState('Element');

  const element = value[index];

  const refs = {
    value: React.useRef(value),
    index: React.useRef(index),
    onUpdate: React.useRef(onUpdate),
    element: React.useRef(element)
  };

  refs.value.current = value;

  refs.index.current = index;

  refs.onUpdate.current = onUpdate;

  refs.element.current = element;

  React.useEffect(() => {
    if (open) {
      setTab('Element');
    }
  }, [open]);

  const tabs = ['Element', 'Style'];

  const onChangeTab = React.useCallback((valueNew: any) => {
    setTab(valueNew);
  }, []);

  const update = React.useCallback((property: string, valueProperty: any) => {
    const valueNew = [...(refs.value.current || [])];

    if (refs.element.current) {
      setObjectValue(refs.element.current, property, valueProperty || null);

      refs.onUpdate.current(valueNew);
    }
  }, []);

  const enable = React.useCallback((valueSwitch: boolean, property: string, valueDefault: any) => {
    const valueNew = [...(refs.value.current || [])];

    if (refs.element.current) {
      setObjectValue(refs.element.current, property, !valueSwitch ? null : valueDefault);

      refs.onUpdate.current(valueNew);
    }
  }, []);

  const getElementName = React.useCallback((item: any) => {
    switch (item?.version || item) {
      default:
        return cleanValue(item?.version?.replace('Section', ''), { capitalize: true });
    }
  }, []);

  const getDefault = (property: string, valueOther: any) => {
    if (['padding', 'maxWidth'].includes(property)) {
      if (['Section boxes', 'Section carousel'].includes(version)) {
        return false;
      }
    }

    if (['maxWidth'].includes(property)) {
      if (['Section cards'].includes(version)) {
        return false;
      }
    }

    return valueOther;
  };

  const paletteBackgroundColor = element?.props?.backgroundColor && (theme.palette.color[element?.props?.backgroundColor] || theme.methods.color(element?.props?.backgroundColor));

  const paletteBackgroundGradient1 = element?.props?.backgroundGradient && (theme.palette.color[element?.props?.backgroundGradient![0]] || theme.methods.color(element?.props?.backgroundGradient![0]));
  const paletteBackgroundGradient2 = element?.props?.backgroundGradient && (theme.palette.color[element?.props?.backgroundGradient![1]] || theme.methods.color(element?.props?.backgroundGradient![1]));
  const paletteBackgroundGradient3 = element?.props?.backgroundGradient && (theme.palette.color[element?.props?.backgroundGradient![2]] || theme.methods.color(element?.props?.backgroundGradient![2]));

  const paletteOverlay = element?.props?.overlay && (theme.palette.color[element.props.overlay] || theme.methods.color(element.props.overlay));

  const urlImage = getMediaUrl(element?.props?.backgroundImage);

  const urlVideo = getMediaUrl(element?.props?.backgroundVideo, element?.props?.overlayBlur ? 140 : undefined);

  const responsiveOptions = [
    { name: 'Smallest 170px to 319px', value: 'xxs' },
    { name: 'Extra small 320px to 479px', value: 'xs' },
    { name: 'Small 480px to 639px', value: 'sm' },
    { name: 'Medium 640px to 1023px', value: 'md' },
    { name: 'Large 1024px to 1439px', value: 'lg' },
    { name: 'Extra large 1440px to 1919px', value: 'xl' },
    { name: 'Largest 1920px to 2559px', value: 'xxl' }
  ];

  const optionsDetails: any = [
    // title 
    {
      name: 'Title',
      property: 'AdditionalProps.title',
      default: 'Title',
      value: (
        <SmartTextField
          placeholder='Title'

          valueDefault={textToInnerHTML(element?.props?.AdditionalProps?.title || '')}

          onChange={(valueNew: string) => update('props.AdditionalProps.title', valueNew)}

          additional={{
            version: element?.props?.size === 'large' ? 'h1' : ['regular', undefined, null].includes(element?.props?.size) ? 'h2' : 'h3'
          }}

          edit
        />
      )
    },

    // description 
    {
      name: 'Description',
      property: 'AdditionalProps.description',
      default: 'Description',
      value: (
        <SmartTextField
          placeholder='Description'

          valueDefault={textToInnerHTML(element?.props?.AdditionalProps?.description || '')}

          onChange={(valueNew: string) => update('props.AdditionalProps.description', valueNew)}

          additional={{
            version: element?.props?.size === 'large' ? 'b1' : ['regular', undefined, null].includes(element?.props?.size) ? 'b2' : 'b3'
          }}

          edit

          multiline

          minRows={3}
        />
      )
    },

    // reveal 
    {
      name: 'Reveal',
      description: 'Nicely fade section into the view, when user scrolls to it.',
      property: 'reveal',
      default: true,
      props: {
        className: classes.noData
      },
      value: undefined
    },

    // themed 
    // {
    //   name: 'Themed',
    //   description: 'If themed colors will follow light/dark theme pattern, otherwise they will show up as pure colors',
    //   property: 'themed',
    //   default: true,
    //   defaultSwitch: true,
    //   props: {
    //     className: classes.noData
    //   },
    //   value: undefined
    // }
  ];

  const optionsSpace: any = [
    // size 
    {
      name: 'Size',
      property: 'size',
      default: 'regular',
      noSwitch: true,
      show: true,
      value: (
        <SelectSize
          value={element?.props?.size || 'regular'}

          onChange={(valueNew: any) => update('props.size', valueNew)}
        />
      )
    },

    // padding 
    {
      name: 'Padding',
      property: 'padding',
      default: getDefault('padding', 'regular'),
      noSwitch: true,
      show: true,
      value: (
        <SelectSize
          value={element?.props?.padding !== undefined ? element?.props?.padding : 'regular'}

          options={['extra_small', 'small', 'regular', 'large', 'extra_large', 'extra_extra_large', { name: 'None', value: false }]}

          onChange={(valueNew: any) => update('props.padding', valueNew)}
        />
      )
    },

    // maxWidth 
    {
      name: 'Max width',
      property: 'maxWidth',
      default: getDefault('maxWidth', 'regular'),
      noSwitch: true,
      show: true,
      value: (
        <SelectSize
          value={element?.props?.maxWidth !== undefined ? element?.props?.maxWidth : 'regular'}

          options={['extra_small', 'small', 'regular', 'large', 'extra_large', 'extra_extra_large', { name: 'None', value: false }]}

          onChange={(valueNew: any) => update('props.maxWidth', valueNew)}
        />
      )
    },

    // margin 
    {
      name: 'Margin',
      property: 'margin',
      default: null,
      show: true,
      value: (
        <SelectSize
          value={element?.props?.margin !== undefined ? element?.props?.margin : false}

          options={['extra_small', 'small', 'regular', 'large', 'extra_large', 'extra_extra_large', { name: 'None', value: false }]}

          onChange={(valueNew: any) => update('props.margin', valueNew)}
        />
      )
    },

    // marginVertical 
    {
      name: 'Margin vertical',
      property: 'marginVertical',
      default: null,
      show: true,
      value: (
        <SelectSize
          value={element?.props?.marginVertical !== undefined ? element?.props?.marginVertical : false}

          options={['extra_small', 'small', 'regular', 'large', 'extra_large', 'extra_extra_large', { name: 'None', value: false }]}

          onChange={(valueNew: any) => update('props.marginVertical', valueNew)}
        />
      )
    },

    // show 
    {
      name: 'Show',
      description: `Section is always visible by default, you can uncheck on what display sizes you don't want the section to be visible`,
      property: 'show',
      default: true,
      defaultSwitch: true,
      show: [true, undefined].includes(element?.props?.show),
      value: (
        <Line
          gap={0}
        >
          {responsiveOptions.map((item, index) => (
            <Label
              key={index}

              checked={!![true, undefined].includes(element?.props?.show?.[item?.value])}

              onChange={(valueNew: any) => {
                const valueNewShow = { ...refs.element.current?.props?.show };

                valueNewShow[item.value] = valueNew;

                update('props.show', valueNewShow);
              }}
            >
              <Checkbox />

              {item.name}
            </Label>
          ))}
        </Line>
      )
    }
  ];

  const optionsBackground: any = [
    // backgroundColor 
    {
      name: 'Background color',
      property: 'backgroundColor',
      default: 'default',
      value: (
        <Line
          gap={1.5}

          direction='column'

          fullWidth
        >
          <SelectColor
            value={element?.props?.backgroundColor}

            onChange={(valueNew: any) => update('props.backgroundColor', valueNew)}
          />

          <Line
            className={classes.preview}

            style={{
              backgroundColor: element?.props?.backgroundColor && theme.methods.palette.color.value(undefined as any, 95, true, paletteBackgroundColor)
            }}
          />
        </Line>
      )
    },

    // backgroundGradient
    {
      name: 'Background gradient',
      property: 'backgroundGradient',
      default: ['primary', 'secondary', 'warning'],
      value: (
        <Line
          gap={1.5}

          direction='column'

          fullWidth
        >
          <Line
            direction='row'

            align='center'

            fullWidth
          >
            <SelectColor
              value={element?.props?.backgroundGradient?.[0]}

              onChange={(valueNew: any) => update(`props.backgroundGradient.0`, valueNew)}

              except={['default']}
            />

            <SelectColor
              value={element?.props?.backgroundGradient?.[1]}

              onChange={(valueNew: any) => update(`props.backgroundGradient.1`, valueNew)}

              except={['default']}
            />

            <SelectColor
              value={element?.props?.backgroundGradient?.[2]}

              onChange={(valueNew: any) => update(`props.backgroundGradient.2`, valueNew)}

              except={['default']}
            />
          </Line>

          <Line
            className={classes.preview}

            style={{
              backgroundImage: element?.props?.backgroundGradient && `linear-gradient(${theme.palette.light ? '130deg' : '330deg'}, ${theme.methods.palette.color.colorToRgb(paletteBackgroundGradient1.main, 0.14)} 0%, ${theme.methods.palette.color.colorToRgb(paletteBackgroundGradient2.main, 0.14)} 40%, ${theme.methods.palette.color.colorToRgb(paletteBackgroundGradient3.main, 0.14)} 100%)`
            }}
          />
        </Line>
      ),
    },

    // backgroundImage
    {
      name: 'Background image',
      property: 'backgroundImage',
      default: true,
      show: true,
      value: (
        <Line
          gap={1.5}

          direction='column'

          fullWidth
        >
          <SelectMedia
            value={element?.props?.backgroundImage}

            onChange={(valueNew: any) => update('props.backgroundImage', valueNew)}
          />

          {urlImage && (
            <Line
              className={classNames([
                classes.preview,
                classes.backgroundImage
              ])}

              style={{
                backgroundImage: element?.props?.backgroundImage && `url('${urlImage}')`
              }}
            />
          )}
        </Line>
      )
    },

    // backgroundVideo
    {
      name: 'Background video',
      property: 'backgroundVideo',
      default: true,
      show: true,
      value: (
        <Line
          gap={1.5}

          direction='column'

          fullWidth
        >
          <SelectMedia
            value={element?.props?.backgroundVideo}

            mime='video'

            onChange={(valueNew: any) => update('props.backgroundVideo', valueNew)}
          />

          {urlVideo && (
            <Line
              className={classes.preview}
            >
              {element?.props?.backgroundVideo?.id && (
                <video
                  autoPlay
                  muted
                  loop

                  className={classes.backgroundVideo}
                >
                  <source
                    src={urlVideo}
                  />
                </video>
              )}
            </Line>
          )}
        </Line>
      )
    },

    // overlay    
    {
      name: 'Overlay',
      property: 'overlay',
      default: 'secondary',
      value: (
        <Line
          gap={1.5}

          direction='column'

          fullWidth
        >
          <SelectColor
            value={element?.props?.overlay}

            onChange={(valueNew: any) => update('props.overlay', valueNew)}

            except={['default']}
          />

          <Line
            className={classNames([
              classes.preview,
              classes.backgroundImage
            ])}

            style={{
              backgroundColor: element?.props?.backgroundGradient && theme.methods.palette.color.value(undefined as any, 95, true, paletteBackgroundColor),

              backgroundImage: element?.props?.backgroundImage ? `url('${urlImage}')` : element?.props?.backgroundGradient && `linear-gradient(${theme.palette.light ? '130deg' : '330deg'}, ${theme.methods.palette.color.colorToRgb(paletteBackgroundGradient1.main, 0.14)} 0%, ${theme.methods.palette.color.colorToRgb(paletteBackgroundGradient2.main, 0.14)} 40%, ${theme.methods.palette.color.colorToRgb(paletteBackgroundGradient3.main, 0.14)} 100%)`
            }}
          >
            {element?.props?.backgroundVideo?.id && (
              <video
                autoPlay
                muted
                loop

                className={classes.backgroundVideo}
              >
                <source
                  src={urlVideo}
                />
              </video>
            )}

            {element?.props?.overlay && (
              <div
                className={classNames([
                  classes.backgroundOverlay
                ])}

                style={{
                  backgroundColor: theme.methods.palette.color.alpha(theme.methods.palette.color.value(undefined as any, 95, true, paletteOverlay), 0.74)
                }}
              />
            )}
          </Line>
        </Line>
      )
    },

    // overlayBlur 
    {
      name: 'Overlay blur',
      property: 'overlayBlur',
      default: true,
      value: (
        <Line
          gap={1.5}

          direction='column'

          fullWidth
        >
          {element?.props?.overlay && (
            <Line
              className={classNames([
                classes.preview,
                classes.backgroundImage
              ])}

              style={{
                backgroundColor: element?.props?.backgroundGradient && theme.methods.palette.color.value(undefined as any, 95, true, paletteBackgroundColor),

                backgroundImage: element?.props?.backgroundImage ? `url('${urlImage}')` : element?.props?.backgroundGradient && `linear-gradient(${theme.palette.light ? '130deg' : '330deg'}, ${theme.methods.palette.color.colorToRgb(paletteBackgroundGradient1.main, 0.14)} 0%, ${theme.methods.palette.color.colorToRgb(paletteBackgroundGradient2.main, 0.14)} 40%, ${theme.methods.palette.color.colorToRgb(paletteBackgroundGradient3.main, 0.14)} 100%)`
              }}
            >
              {element?.props?.backgroundVideo?.id && (
                <video
                  autoPlay
                  muted
                  loop

                  className={classes.backgroundVideo}
                >
                  <source
                    src={urlVideo}
                  />
                </video>
              )}

              {element?.props?.overlay && (
                <div
                  className={classNames([
                    classes.backgroundOverlay,
                    classes.backgroundOverlayBlur
                  ])}

                  style={{
                    backgroundColor: theme.methods.palette.color.alpha(theme.methods.palette.color.value(undefined as any, 95, true, paletteOverlay), 0.74)
                  }}
                />
              )}
            </Line>
          )}
        </Line>
      )
    }
  ];

  const getItem = (item: any, index: number) => (
    <FormRow
      key={index}

      gap={2}

      name={item.name}

      start={!item.noSwitch && (
        <Switch
          tonal

          valueDefault={item.defaultSwitch !== undefined ? item.defaultSwitch : !!getObjectValue(element?.props, item.property)}

          onChange={(valueNew: any) => enable(valueNew, `props.${item.property}`, item.default)}

          size='small'
        />
      )}

      {...item?.props}

      className={classNames([
        item?.props?.className,
        (!item.show && [null, undefined].includes(getObjectValue(element?.props || {}, item.property))) && classes.noData
      ])}
    >
      {(item.show || ![null, undefined].includes(getObjectValue(element?.props || {}, item.property))) && item.value}
    </FormRow>
  );

  const getOptions = () => {
    const propsElementUpdate = {
      index,
      value: refs.value.current,
      onUpdate,
      element: refs.element.current
    };

    switch (element?.version) {
      case 'Section text media':
        return <OptionsSectionTextMedia  {...propsElementUpdate} />;

      case 'Section media':
        return <OptionsSectionMedia  {...propsElementUpdate} />;

      case 'Section boxes':
        return <OptionsSectionBoxes  {...propsElementUpdate} />;

      case 'Section cards':
        return <OptionsSectionCards  {...propsElementUpdate} />;

      case 'Section carousel':
        return <OptionsSectionCarousel  {...propsElementUpdate} />;

      case 'Section reviews':
        return <OptionsSectionReviews  {...propsElementUpdate} />;

      case 'Section contact':
        return <OptionsSectionContact  {...propsElementUpdate} />;

      case 'Section image gallery':
        return <OptionsSectionImageGallery  {...propsElementUpdate} />;

      case 'Section logos':
        return <OptionsSectionLogos  {...propsElementUpdate} />;

      case 'Section timeline':
        return <OptionsSectionTimeline  {...propsElementUpdate} />;

      case 'Section watch':
        return <OptionsSectionWatch  {...propsElementUpdate} />;

      case 'Section action':
        return <OptionsSectionAction  {...propsElementUpdate} />;

      case 'Links':
        return <OptionsLinks  {...propsElementUpdate} />;

      case 'Form':
        return <OptionsForm  {...propsElementUpdate} />;

      case 'Reservation':
        return <OptionsReservation  {...propsElementUpdate} />;

      case 'Post':
        return <OptionsPost  {...propsElementUpdate} />;

      case 'Skill':
        return <OptionsSkill  {...propsElementUpdate} />;

      case 'Portfolio':
        return <OptionsPortfolio  {...propsElementUpdate} />;

      case 'Resume':
        return <OptionsResume  {...propsElementUpdate} />;

      case 'Support page':
        return <OptionsSupportPage  {...propsElementUpdate} />;

      default:
        return null;
    }
  };

  const modal: any = {
    'Element': <>
      {getOptions()}
    </>,

    'Style': <>
      <Line
        fullWidth

        flex
      >
        <Form
          name='Details'

          size='small'

          wrapper

          accordion
        >
          <Line
            fullWidth

            className={classes.page}
          >
            {optionsDetails.map((item: any, index: number) => getItem(item, index))}
          </Line>
        </Form>

        <Form
          name='Space'

          size='small'

          wrapper

          accordion
        >
          <Line
            fullWidth

            className={classes.page}
          >
            {optionsSpace.map((item: any, index: number) => getItem(item, index))}
          </Line>
        </Form>

        <Form
          name='Background'

          size='small'

          wrapper

          accordion
        >
          <Line
            fullWidth

            className={classes.page}
          >
            {optionsBackground.map((item: any, index: number) => getItem(item, index))}
          </Line>
        </Form>
      </Line>
    </>
  };

  return (
    <Modal
      open={open}

      fullScreen

      onClose={onClose}

      TransitionComponent={Slide}

      SurfaceProps={{
        tonal: true,
        color: 'primary',

        className: classNames([
          classes.root
        ])
      }}

      {...other}
    >
      <ModalForm
        {...props}

        name={`Section ${index + 1}: ${getElementName(value[index])}`}

        MainProps={{
          align: 'center'
        }}

        onClose={onClose}

        footer={false}
      >
        <Line
          gap={4}

          flex

          fullWidth

          className={classNames([
            classes.update
          ])}
        >
          <Tabs
            value={tab}

            justify='center'

            onChange={onChangeTab}

            className={classes.tabs}
          >
            {tabs.map((item: any, index: number) => (
              <Tab
                key={index}

                value={item}
              >
                {item}
              </Tab>
            ))}
          </Tabs>

          {modal[tab]}
        </Line>
      </ModalForm>
    </Modal>
  );
});

export default ModalUpdate;
