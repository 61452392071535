import React from 'react';

import { arrayMoveItem, decodeObjectValue, encodeObjectValue, is } from '@amaui/utils';
import { FormRow, IconButton, Line, List, ListItem, Tooltip, Type, useForm, useSnackbars } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';

import IconMaterialKeyboardArrowRightRounded from '@amaui/icons-material-rounded-react/IconMaterialKeyboardArrowRight';
import IconMaterialKeyboardArrowLeftRounded from '@amaui/icons-material-rounded-react/IconMaterialKeyboardArrowLeft';
import IconMaterialKeyboardArrowUpRounded from '@amaui/icons-material-rounded-react/IconMaterialKeyboardArrowUp';
import IconMaterialKeyboardArrowDownRounded from '@amaui/icons-material-rounded-react/IconMaterialKeyboardArrowDown';

import { ModalForm, TextField } from 'ui';
import { AppService, ViewService } from 'services';
import { getErrorMessage } from 'other';

const useStyle = style(theme => ({
  root: {

  },

  lists: {
    '& > *': {
      flex: '1 1 auto'
    }
  },

  ...theme.classes(theme)
}), { name: 'amaui-app-Views' });

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    object: object_,

    model,

    properties: properties_,

    onConfirm
  } = props;

  const { classes } = useStyle();

  const snackbars = useSnackbars();

  const [object, setObject] = React.useState(object_);
  const [loading, setLoading] = React.useState(false);

  const form = useForm({
    values: {
      'name': {
        name: 'Name',
        value: object?.name,
        required: true,
        max: 1400,
        messages: {
          min: 'Name has to be min 1 characters',
          max: 'Name can be max 1400 characters'
        }
      },
      columns: {
        name: 'Columns',
        is: 'array',
        value: is('string', object?.value?.columns) ? decodeObjectValue(object?.value?.columns) : object_?.value?.columns || []
      }
    }
  });

  const properties = Object.keys(properties_ || {}).map(item => properties_[item]).filter(item => [undefined, true].includes(item.view));

  const refs = {
    form: React.useRef(form),
    properties: React.useRef<any>(properties),
    propertiesToUse: React.useRef<any>(),
    propertiesUsed: React.useRef<any>()
  };

  refs.form.current = form;

  refs.properties.current = properties;

  refs.propertiesUsed.current = form.values.columns.value;

  refs.propertiesToUse.current = properties.filter((item: any) => !refs.propertiesUsed.current.find((itemUsed: any) => itemUsed.name === item.name));

  const onClose = React.useCallback(() => {
    AppService.pages.addTertiary.emit({
      ...AppService.pages.addTertiary.value,

      open: false
    });
  }, []);

  React.useEffect(() => {
    if (object !== object_) {
      const objectColumns = is('string', object_?.value?.columns) ? decodeObjectValue(object_?.value?.columns) : object_?.value?.columns || [];

      refs.form.current.onChange('columns', objectColumns);
    }
  }, [object_]);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();

    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const value_ = refs.form.current.value;

    const body = {
      name: value_.name,

      model,

      value: {
        selected: object?.value?.selected,

        columns: encodeObjectValue((value_.columns || []).map((item: any) => {
          if (item.method) item.method = item.method.toString();

          return item;
        }))
      }
    };

    const result = !object?.id ? await ViewService.add(body) : await ViewService.update(object?.id, body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `View ${!object?.id ? 'added' : 'updated'}`
      });

      setObject({
        ...result.response.response,

        value: decodeObjectValue(result.response.response)
      });

      if (is('function', onConfirm)) onConfirm();

      onClose();
    }

    setLoading(false);
  }, [model, object, form, onClose]);

  const onRemoveAll = React.useCallback(() => {
    const columnsNew: any = [];

    form.onChange('columns', columnsNew);
  }, []);

  const onUseAll = React.useCallback(() => {
    const columnsNew = [...refs.properties.current];

    form.onChange('columns', columnsNew);
  }, []);

  const onMove = React.useCallback((index: number, indexNew: number) => {
    const columnsNew = [...refs.form.current.values.columns.value];

    arrayMoveItem(columnsNew, index, indexNew);

    form.onChange('columns', columnsNew);
  }, []);

  const onUse = React.useCallback((item: any, use = true) => {
    const columnsNew = [...refs.form.current.values.columns.value];

    const index = columnsNew.findIndex((itemUsed: any) => itemUsed.name === item.name);

    const exists = index > -1;

    if (
      (use && exists) ||
      (!use && !exists)
    ) return;

    if (use) {
      columnsNew.push(item);
    }
    else {
      columnsNew.splice(index, 1);
    }

    form.onChange('columns', columnsNew);
  }, []);

  const listProps: any = {
    size: 'small',
    paddingVertical: 'none',
    paddingHorizontal: 'none',
    noBackground: true
  };

  const listItemProps: any = {
    size: 'small',
    noPadding: true,
    noBackground: true
  };

  const iconButtonProps: any = {
    size: 'small'
  };

  const modal: any = {
    read: <>
      <Line
        fullWidth
      >
        <FormRow
          name='Properties'
        >
          <List
            {...listProps}
          >
            {object?.value?.columns?.map((item: any) => (
              <ListItem
                key={item.name}

                primary={(
                  <Type
                    version='b3'
                  >
                    {item.name}
                  </Type>
                )}

                {...listItemProps}
              />
            ))}
          </List>
        </FormRow>
      </Line>
    </>,

    write: <>
      <Line
        gap={3}

        fullWidth
      >
        <TextField
          name='Name'

          valueDefault={form.values['name'].value}

          error={form.values['name'].error}

          helperText={form.values['name'].error}

          onChange={(valueNew: any) => form.onChange('name', valueNew, undefined, { rerenderOnUpdate: false })}

          fullWidth
        />

        <Line
          gap={1}

          direction='row'

          align='flex-start'

          fullWidth

          className={classNames([
            classes.lists
          ])}
        >
          <FormRow
            name='Select'

            end={(
              <Tooltip
                name='Use all'
              >
                <IconButton
                  onClick={onUseAll}

                  {...iconButtonProps}
                >
                  <IconMaterialKeyboardArrowRightRounded />
                </IconButton>
              </Tooltip>
            )}
          >
            <List
              {...listProps}
            >
              {refs.propertiesToUse.current.map((item: any, index: number) => (
                <ListItem
                  key={item.name}

                  primary={(
                    <Type
                      version='b3'
                    >
                      {item.name}
                    </Type>
                  )}

                  end={<>
                    <Tooltip
                      name='Use'
                    >
                      <IconButton
                        onClick={() => onUse(item)}

                        {...iconButtonProps}
                      >
                        <IconMaterialKeyboardArrowRightRounded />
                      </IconButton>
                    </Tooltip>
                  </>}

                  {...listItemProps}
                />
              ))}
            </List>
          </FormRow>

          <FormRow
            name='Use'

            end={(
              <Tooltip
                name='Remove all'
              >
                <IconButton
                  onClick={onRemoveAll}

                  {...iconButtonProps}
                >
                  <IconMaterialKeyboardArrowLeftRounded />
                </IconButton>
              </Tooltip>
            )}
          >
            <List
              {...listProps}
            >
              {refs.propertiesUsed.current.map((item: any, index: number) => (
                <ListItem
                  key={item.name}

                  size='small'

                  primary={(
                    <Type
                      version='b3'
                    >
                      {item.name}
                    </Type>
                  )}

                  end={<>
                    <Tooltip
                      name='Remove'
                    >
                      <IconButton
                        onClick={() => onUse(item, false)}

                        {...iconButtonProps}
                      >
                        <IconMaterialKeyboardArrowLeftRounded />
                      </IconButton>
                    </Tooltip>

                    <Tooltip
                      name='Up'
                    >
                      <IconButton
                        onClick={() => onMove(index, index - 1)}

                        disabled={index === 0}

                        {...iconButtonProps}
                      >
                        <IconMaterialKeyboardArrowUpRounded />
                      </IconButton>
                    </Tooltip>

                    <Tooltip
                      name='Down'
                    >
                      <IconButton
                        onClick={() => onMove(index, index + 1)}

                        disabled={index === (refs.propertiesToUse.current.length - 1)}

                        {...iconButtonProps}
                      >
                        <IconMaterialKeyboardArrowDownRounded />
                      </IconButton>
                    </Tooltip>
                  </>}

                  {...listItemProps}
                />
              ))}
            </List>
          </FormRow>
        </Line>
      </Line>
    </>
  };

  return (
    <ModalForm
      {...props}

      object={object}

      add={!object}

      {...modal}

      onSubmit={onSubmit}

      onNext={onSubmit}

      onClose={onClose}

      loading={loading}

      smaller
    />
  );
});

export default Element;
