import React from 'react';

import { ErrorBoundary } from 'ui';

const RouteWrapper = React.forwardRef((props: any, ref: any) => {
  const {
    children,

    ...other
  } = props;

  return (
    <ErrorBoundary
      ref={ref}

      {...other}
    >
      {children}
    </ErrorBoundary>
  );
});

export default RouteWrapper;
