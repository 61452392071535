import React from 'react';
import { Helmet } from 'react-helmet';

import { getGoogleFontsURL } from '@amaui/utils';
import { Accordion, Line } from '@amaui/ui-react';
import { Lady, Modern, Sport } from '@amaui/themes';

import { FONTS } from 'other';

const Test = React.forwardRef((props: any, ref: any) => {
  const {
    className,

    ...other
  } = props;

  const themes = [
    { name: 'Lady', element: Lady },
    { name: 'Modern', element: Modern },
    { name: 'Sport', element: Sport }
  ];

  const googleFonts: any = [];

  Object.keys(FONTS).forEach((item: string) => {
    (FONTS as any)[item].forEach((font: any) => {
      if (font.category === 'google') googleFonts.push({ ...font, weights: [400, 700] });
    });
  });

  return <>
    {/* Fonts */}
    <Helmet>
      <link
        href={getGoogleFontsURL(googleFonts.map((item: any) => ({ ...item, name: item.label })))}
        rel='stylesheet'
      />
    </Helmet>

    <Line
      ref={ref}

      flex

      fullWidth

      style={{
        padding: '40px 0 140px'
      }}
    >
      {themes.map((item: any, index) => (
        <Accordion
          key={index}

          openDefault={index === 2}

          primary={item.name}

          mainPaddingHorizontal='none'

          mainPaddingVertical='none'

          noBackground
        >
          {React.cloneElement(<item.element />, {
            gap: 4,

            ...other
          })}
        </Accordion>
      ))}
    </Line>
  </>;
});

export default Test;
