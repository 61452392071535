import React from 'react';

import { Form, Line, Type, useConfirm, useSnackbars } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';

import { Button } from 'ui';
import { AuthService, UserService } from 'services';
import { getErrorMessage } from 'other';

const useStyle = style(theme => ({
  root: {

  },

  remove: {
    padding: 24,
    borderRadius: 24,
    backgroundColor: theme.methods.palette.color.colorToRgb(theme.methods.palette.color.value('error', 50), theme.palette.light ? 4 : 14)
  },

  ...theme.classes(theme)
}), { name: 'amaui-app-route-UserSettingsManage' });

const UserSettingsManage = React.forwardRef((props: any, ref: any) => {
  const {
    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const confirm = useConfirm();
  const snackbars = useSnackbars();

  const [loading, setLoading] = React.useState<any>(false);

  const onRemove = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();

    setLoading('remove');

    const confirmed = await confirm.open({
      name: 'Removing the account',
      description: `Are you sure you want to remove your account?`,

      buttons: {
        positive: {
          text: 'Remove'
        },
        negative: {
          text: 'Cancel'
        }
      },

      ButtonPositiveProps: {
        color: 'error'
      }
    });

    if (!confirmed) {
      setLoading(false);

      return;
    }

    const result = await UserService.remove();

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: result.response?.meta?.message || 'Removed'
      });

      // user sign out
      await AuthService.signOut(false);
    }

    setLoading(false);
  }, []);

  return (
    <Line
      ref={ref}

      gap={4}

      fullWidth

      className={classNames([
        className,
        classes.root,
        classes.wrapper
      ])}

      {...other}
    >
      <Form
        name='Remove account'

        description={(
          <Type
            version='b2'

            style={{
              marginTop: 4
            }}
          >
            Removing the account, we will remove all organizations you are owner of, and all the content created within those organizations, including, tasks, notes, resumes, websites, etc. <br /><br />

            All organization subscriptions will be removed, for each of them. <br /><br />

            This action cannot be undone.
          </Type>
        )}

        className={classes.remove}
      >
        <Button
          version='outlined'

          color='error'

          onClick={onRemove}

          size='small'

          disabled={loading}
        >
          Remove account
        </Button>
      </Form>
    </Line>
  );
});

export default UserSettingsManage;
