import React from 'react';

import { IconButton, TextField, Tooltip } from '@amaui/ui-react';
import { ITextField } from '@amaui/ui-react/TextField/TextField';
import { classNames, style } from '@amaui/style-react';

import IconMaterialAbcRounded from '@amaui/icons-material-rounded-react/IconMaterialAbc';
import IconMaterialPasswordRounded from '@amaui/icons-material-rounded-react/IconMaterialPassword';

const useStyle = style(theme => ({
  root: {
    '&.amaui-TextField-root': {
      minWidth: theme.minWidthInput
    },

    '& .amaui-TextField-icon-end': {
      paddingBlock: 0,
      paddingTop: 8
    }
  },

  '@media only screen and (max-width: 768px)': {
    $root: {
      '&.amaui-TextField-root': {
        // minWidth: '100% !important'
      }
    }
  }
}), { name: 'amaui-app-TextField' });

const Element: React.FC<ITextField> = React.forwardRef((props: ITextField, ref: any) => {
  const {
    valueDefault,

    value: value_,

    onChange: onChange_,

    end,

    className,

    ...other
  } = props;

  const { classes } = useStyle(props);

  const [value, setValue] = React.useState(valueDefault !== undefined ? valueDefault : value_);
  const [visible, setVisible] = React.useState(false);

  React.useEffect(() => {
    if (value_ !== undefined && value !== value_) setValue(value_);
  }, [value, value_]);

  const onChange = React.useCallback((value: any) => {
    setValue(value);

    if (onChange_) onChange_(value);
  }, [onChange_]);

  const toggleVisible = React.useCallback(() => {
    setVisible(item => !item);
  }, []);

  const password = other.type === 'password';

  const iconButtonPassword = (
    <Tooltip
      name={visible ? 'Hide password' : 'View password'}

      color='inverted'
    >
      <IconButton
        onClick={toggleVisible}

        color='default'

        size='small'
      >
        {visible ? <IconMaterialPasswordRounded /> : <IconMaterialAbcRounded />}
      </IconButton>
    </Tooltip>
  );

  return (
    <TextField
      ref={ref}

      version='outlined'

      valueDefault={valueDefault}

      value={value}

      end={end}

      onChange={onChange}

      className={classNames([
        classes.root,
        className
      ])}

      {...other}

      {...(password && {
        type: visible ? 'text' : 'password',

        end: [
          ...(end as any || []),

          ...(value ? [iconButtonPassword] : [])
        ]
      })}
    />
  );
});

Element.displayName = 'amaui-app-TextField';

export default Element;
