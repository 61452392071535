import { List } from '@amaui/api-utils';
import AmauiSubscription from '@amaui/subscription';

import { BaseService } from './base';
import { IQuerySubscription, ISelectedSubscription } from 'other';

class ListService extends BaseService<List> {
  public queryListsTasks = new AmauiSubscription<IQuerySubscription>();
  public selectedListsTasks = new AmauiSubscription<ISelectedSubscription>();

  public get paginationLimit() { return 140; }

  public constructor() {
    super('lists');

    this.queryObjectsInit(this.queryListsTasks);

    this.selectedObjectsInit(this.selectedListsTasks);
  }

}

export default new ListService();
