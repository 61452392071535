import React from 'react';

import { Type } from '@amaui/ui-react';
import { IUser } from '@amaui/api-utils';

import { FormUser, Page, useSubscription } from 'ui';
import { AuthService, OrganizationService } from 'services';
import { ISignedIn } from 'other';

const cleanValue = (value_: string) => {
  let value = value_;

  ['-', '_'].forEach(filter => {
    const expression = `\\${filter}`;
    const regexp = new RegExp(expression, 'g');

    value = value ? value.replace(regexp, ' ') : '';
  });

  return `${value.charAt(0).toUpperCase()}${value.slice(1)}`;
};

const Users = React.forwardRef(() => {
  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const isEnabledAction = React.useCallback((item: any, action: string) => {
    if (
      ['export'].includes(action) ||
      (['open', 'active', 'remove'].includes(action) && item.id === signedIn.user.id)
    ) return false;

    return true;
  }, [signedIn]);

  const properties = React.useMemo(() => ({
    name: {
      name: 'Name',
      method: (item: any, { openProps }: any) => (
        <Type
          version={item.id === signedIn.user.id ? 'b2' : 'l2'}

          disabled={item.id === signedIn.user.id}

          {...openProps}
        >
          {item.name}{item.id === signedIn.user.id && ' (me)'}
        </Type>
      ),
      view: false
    },
    roles: {
      name: 'Roles',

      method: (item: IUser) => {
        const userOrganization = item.organizations?.find((item_: any) => item_.id === signedIn.organization.id);

        return userOrganization?.roles?.length ? userOrganization.roles.map((item_: any) => cleanValue(item_)).join(', ') : '';
      }
    },
    active: {
      name: 'Active',
      method: (item: IUser) => {
        const userOrganization = item.organizations?.find((item_: any) => item_.id === signedIn.organization.id);

        return userOrganization?.active ? 'Yes' : 'No';
      },
      view: false
    },
    email: { name: 'Email' },
    mobile: { name: 'Mobile' },
    google: { name: 'google' }
  }), [signedIn]);

  return (
    <Page
      name='Users'

      service={OrganizationService}

      updateOneName='updateUser'

      removeOneName='removeUser'

      Form={FormUser}

      columns={[
        properties.name,
        properties.roles,
        properties.active
      ]}

      search={['active']}

      options={['active']}

      properties={properties as any}

      isEnabledAction={isEnabledAction}

      queryItemsName='queryUsers'

      selectedItemsName='selectedUsers'

      app='amaui'

      collection='users'

      route='/organization/settings/users'

      noTags

      noSelect

      noActions

      noUpdateProject

      noAdd
    />
  );
});

export default Users;
