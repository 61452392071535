import React from 'react';
import audioFix from 'webm-duration-fix';

import { arrayMoveItem, capitalize, cleanValue, copy, getID, getObjectValue, innerHTMLToText, is, setObjectValue, textToInnerHTML } from '@amaui/utils';
import { AudioRecorder, DatePicker, DateTimePicker, Form, FormRow, IconButton, Label, Line, ListItem, Medias, Slider, SmartTextField, Switch, TimePicker, Tooltip, Type, useForm, useSnackbars } from '@amaui/ui-react';
import { style } from '@amaui/style-react';
import { AmauiDate, format } from '@amaui/date';
import { IMedia } from '@amaui/api-utils';

import IconMaterialAddRounded from '@amaui/icons-material-rounded-react/IconMaterialAdd';
import IconMaterialKeyboardArrowUpRounded from '@amaui/icons-material-rounded-react/IconMaterialKeyboardArrowUp';
import IconMaterialKeyboardArrowDownRounded from '@amaui/icons-material-rounded-react/IconMaterialKeyboardArrowDown';
import IconMaterialContentCopyRounded from '@amaui/icons-material-rounded-react/IconMaterialContentCopy';
import IconMaterialRemoveRounded from '@amaui/icons-material-rounded-react/IconMaterialRemove';

import { Button, ModalForm, NumericTextField, OptionsWebsites, Select, TextField, useMedia } from 'ui';
import { AppService, FormService, MediaService } from 'services';
import { formats, getErrorMessage, mediasToValue } from 'other';
import FormResponses from 'pages/main/Form/Responses';

const useStyle = style(theme => ({
  root: {

  },

  input: {
    '&.amaui-Form-root': {
      '&.amaui-Surface-root': {
        background: theme.palette.background.primary.secondary,
        borderRadius: 24
      }
    }
  },

  inputs: {
    '& > *': {
      paddingBottom: 12,
      borderBottom: `1px solid ${theme.palette.text.primary.secondary}`,
      borderRadius: '0 !important',

      '&:last-child': {
        paddingBotton: 0,
        borderBottom: 'none'
      }
    }
  },

  ...theme.classes(theme)
}), { name: 'amaui-app-Form' });

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    object: object_,

    onConfirm
  } = props;

  const { classes } = useStyle();

  const snackbars = useSnackbars();
  const media = useMedia();

  const [object, setObject] = React.useState(object_);
  const [tab, setTab] = React.useState(!object ? 'Options' : 'Form');
  const [loading, setLoading] = React.useState<any>(false);

  const form = useForm({
    values: {
      'name': {
        name: 'Name',
        value: object?.name,
        required: true,
        max: 1400,
        messages: {
          min: 'Name has to be min 1 characters',
          max: 'Name can be max 1400 characters'
        }
      },
      'description': {
        name: 'Description',
        value: object?.description,
        max: 4400,
        messages: {
          min: 'Description has to be min 1 characters',
          max: 'Description can be max 4400 characters'
        }
      },
      'version': {
        name: 'Version',
        value: object?.version || 'form',
        is: 'string',
        in: ['form', 'poll']
      },
      'value': {
        name: 'Value',
        value: object?.value || [],
        is: 'array',
        of: 'object'
      },
      'poll_options': {
        name: 'Poll options',
        value: object?.poll_options || [],
        is: 'array',
        of: 'object'
      },
      'progress': {
        name: 'Progress',
        value: object?.progress || false,
        is: 'boolean'
      },
      'websites': {
        name: 'Websites',
        value: object?.websites,
        is: 'array',
        of: 'object'
      }
    }
  });

  const refs = {
    form: React.useRef(form)
  };

  refs.form.current = form;

  const formOnChangeOptions: any = {
    rerenderOnUpdate: false
  };

  const init = React.useCallback(async () => {
    // init form
    initForm();
  }, [object]);

  const initForm = React.useCallback(() => {
    if (is('array', object?.websites)) object.websites = object?.websites.map((objectWebsite: any) => ({
      ...objectWebsite,

      value: objectWebsite?.id
    }));

    form.onChange([
      ['websites', object?.websites || []]
    ]);
  }, [object, form]);

  React.useEffect(() => {
    // init
    init();
  }, []);

  const onClose = React.useCallback(() => {
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });
  }, []);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();
  }, []);

  const onNext = React.useCallback(async (event: SubmitEvent) => {
    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const body = {
      ...refs.form.current.value
    };

    if (is('array', body.websites)) body.websites = body.websites.map((item: any) => ({
      id: item.id,
      name: item.name
    }));

    body.value = (body.value || []).map((step: any) => {
      (step.inputs || []).map((item: any) => {
        if (item.description) item.description = innerHTMLToText(item.description);

        return item;
      });

      return step;
    });

    const result = !object?.id ? await FormService.add(body) : await FormService.update(object?.id, body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Form ${!object?.id ? 'added' : 'updated'}`
      });

      setObject(result.response.response);

      if (is('function', onConfirm)) onConfirm();

      if (!object?.id) onClose();
    }

    setLoading(false);
  }, [object, form, onConfirm, onClose]);

  const onChangeTab = React.useCallback(async (value: any) => {
    await refs.form.current.validate();

    setTab(value);
  }, []);

  const onAddStep = React.useCallback(() => {
    const value = [...(refs.form.current.values.value.value || [])];

    value.push({
      id: getID(),
      name: 'Step',
      version: 'step',
      inputs: [],
      start: {},
      end: {}
    });

    refs.form.current.onChange('value', value);
  }, []);

  const onMoveStep = React.useCallback((index: number, indexNew: number) => {
    const value = [...(refs.form.current.values.value.value || [])];

    arrayMoveItem(value, index, indexNew);

    refs.form.current.onChange('value', value);
  }, []);

  const onCopyStep = React.useCallback((index: number) => {
    const value = [...(refs.form.current.values.value.value || [])];

    const item = copy(value[index]);

    item.id = getID();
    item.name = `${item.name || ''} Copy`.trim();

    value.splice(index + 1, 0, item);

    refs.form.current.onChange('value', value);
  }, []);

  const onRemoveStep = React.useCallback((id: number) => {
    const value = [...(refs.form.current.values.value.value || [])];

    const index = value.findIndex(item => item.id === id);

    if (index > -1) value.splice(index, 1);

    refs.form.current.onChange('value', value);
  }, []);

  const onAddInput = React.useCallback((index: number) => {
    const value = [...(refs.form.current.values.value.value || [])];

    value[index].inputs.push({
      id: getID(),
      version: 'text'
    });

    refs.form.current.onChange('value', value);
  }, []);

  const onMoveInput = React.useCallback((index: number, indexInput: number, indexInputNew: number) => {
    const value = [...(refs.form.current.values.value.value || [])];

    arrayMoveItem(value[index].inputs, indexInput, indexInputNew);

    refs.form.current.onChange('value', value);
  }, []);

  const onCopyInput = React.useCallback((index: number, indexInput: number) => {
    const value = [...(refs.form.current.values.value.value || [])];

    const item = copy(value[index].inputs[indexInput]);

    item.id = getID();
    item.name = `${item.name || ''} Copy`.trim();

    value[index].inputs.splice(indexInput + 1, 0, item);

    refs.form.current.onChange('value', value);
  }, []);

  const onRemoveInput = React.useCallback((index: number, indexInput: number) => {
    const value = [...(refs.form.current.values.value.value || [])];

    value[index].inputs.splice(indexInput, 1);

    refs.form.current.onChange('value', value);
  }, []);

  const onAddInputOption = React.useCallback((index: number, indexInput: number) => {
    const value = [...(refs.form.current.values.value.value || [])];

    if (!value[index].inputs[indexInput].options) value[index].inputs[indexInput].options = [];

    value[index].inputs[indexInput].options.push({
      id: getID(),
      version: 'type',
      value: undefined
    });

    refs.form.current.onChange('value', value);
  }, []);

  const onRemoveInputOption = React.useCallback((index: number, indexInput: number, indexOption: number) => {
    const value = [...(refs.form.current.values.value.value || [])];

    value[index].inputs[indexInput].options.splice(indexOption, 1);

    refs.form.current.onChange('value', value);
  }, []);

  const onAddInputSelectOption = React.useCallback((index: number, indexInput: number) => {
    const value = [...(refs.form.current.values.value.value || [])];

    if (!value[index].inputs[indexInput].select_options) value[index].inputs[indexInput].select_options = [];

    value[index].inputs[indexInput].select_options.push({
      group: '',
      name: '',
      value: ''
    });

    refs.form.current.onChange('value', value);
  }, []);

  const onRemoveInputSelectOption = React.useCallback((index: number, indexInput: number, indexOption: number) => {
    const value = [...(refs.form.current.values.value.value || [])];

    value[index].inputs[indexInput].select_options.splice(indexOption, 1);

    refs.form.current.onChange('value', value);
  }, []);

  const onAddMultipleGridItem = React.useCallback((index: number, indexInput: number, property = 'rows') => {
    const value = [...(refs.form.current.values.value.value || [])];

    if (!value[index].inputs[indexInput][property]) value[index].inputs[indexInput][property] = [];

    value[index].inputs[indexInput][property].push(`${property === 'rows' ? 'Row' : 'Column'} ${value[index].inputs[indexInput][property].length}`);

    refs.form.current.onChange('value', value);
  }, []);

  const onRemoveMultipleGridItem = React.useCallback((index: number, indexInput: number, indexItem: number, property = 'rows') => {
    const value = [...(refs.form.current.values.value.value || [])];

    value[index].inputs[indexInput][property].splice(indexItem, 1);

    refs.form.current.onChange('value', value);
  }, []);

  const onAddPollOption = React.useCallback(() => {
    const pollOptions = [...(refs.form.current.values.poll_options.value || [])];

    pollOptions.push({
      id: getID(),
      version: 'type',
      value: {}
    });

    refs.form.current.onChange('poll_options', pollOptions);
  }, []);

  const onRemovePollOption = React.useCallback((index: number) => {
    const pollOptions = [...(refs.form.current.values.poll_options.value || [])];

    pollOptions.splice(index, 1);

    refs.form.current.onChange('poll_options', pollOptions);
  }, []);

  const onAudioRecorderConfirm = React.useCallback(async (value: Blob, index: number) => {
    setLoading('voice recording');

    const media = await audioFix(value);

    // validate
    // 140 mb maximum
    if (media.size > (140 * 1e6)) {
      snackbars.add({
        color: 'error',
        primary: `Maximum allowed file size is 140 mb`
      });

      return;
    }

    const name = `Voice recording, ${format(new AmauiDate(), formats.entire)}`;

    // meta
    const meta: any = {};

    const result = await MediaService.add({
      name,
      meta,

      app: 'note',

      // media
      media
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      const mediaMongo = result?.response?.response;

      const value = {
        id: mediaMongo.id,

        ...(mediasToValue({
          id: mediaMongo?.id,
          name,
          mime: media.type,
          meta: mediaMongo?.meta,
          versions: mediaMongo?.versions,
          added_at: AmauiDate.milliseconds
        })),

        duration: mediaMongo?.meta?.duration
      };

      const pollOptions = [...(refs.form.current.values.poll_options.value || [])];

      if (!pollOptions[index].value) pollOptions[index].value = {};

      pollOptions[index].value.media = value;

      refs.form.current.onChange('poll_options', pollOptions);
    }

    setLoading(false);
  }, []);

  const onMediaConfirm = React.useCallback((valueNew: IMedia, index: number, type: string = 'image') => {
    const value = valueNew;

    const pollOptions = [...(refs.form.current.values.poll_options.value || [])];

    if (!pollOptions[index].value) pollOptions[index].value = {};

    pollOptions[index].value.media = value;

    refs.form.current.onChange('poll_options', pollOptions);
  }, []);

  const onAudioRecorderConfirmValue = React.useCallback(async (value: Blob, path: string) => {
    setLoading('voice recording');

    const media = await audioFix(value);

    // validate
    // 140 mb maximum
    if (media.size > (140 * 1e6)) {
      snackbars.add({
        color: 'error',
        primary: `Maximum allowed file size is 140 mb`
      });

      return;
    }

    const name = `Voice recording, ${format(new AmauiDate(), formats.entire)}`;

    // meta
    const meta: any = {};

    const result = await MediaService.add({
      name,
      meta,

      app: 'note',

      // media
      media
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      const mediaMongo = result?.response?.response;

      const value = {
        id: mediaMongo.id,

        ...(mediasToValue({
          id: mediaMongo?.id,
          name,
          mime: media.type,
          meta: mediaMongo?.meta,
          versions: mediaMongo?.versions,
          added_at: AmauiDate.milliseconds
        })),

        duration: mediaMongo?.meta?.duration
      };

      const value_ = [...(refs.form.current.values.value.value || [])];

      setObjectValue(value_, path, value);

      refs.form.current.onChange('value', value_);
    }

    setLoading(false);
  }, []);

  const onMedia = React.useCallback((index: number, type: string = 'image') => {
    media.open({
      allowed: [type],
      filters: {
        mime: type
      },
      selected: [refs.form.current.values.poll_options.value[index]?.value?.media],
      onConfirm: (valueNew) => onMediaConfirm(valueNew, index, type)
    });
  }, [media, onMediaConfirm]);

  const onMediaConfirmValue = React.useCallback((valueNew: IMedia, path: string, type: string = 'image') => {
    const value = valueNew;

    const value_ = [...(refs.form.current.values.value.value || [])];

    setObjectValue(value_, path, value);

    refs.form.current.onChange('value', value_);
  }, []);

  const onMediaValue = React.useCallback((path: string, type: string = 'image') => {
    media.open({
      allowed: [type],
      filters: {
        mime: type
      },
      selected: [getObjectValue(refs.form.current.values.value.value, path)],
      onConfirm: (valueNew) => onMediaConfirmValue(valueNew, path, type)
    });
  }, [media, onMediaConfirmValue]);

  const onStopPropagation = React.useCallback((event: MouseEvent) => {
    event.stopPropagation();
  }, []);

  const allInputProps: any = {
    size: 'small'
  };

  const formRowProps: any = {
    HeaderProps: {
      align: 'center'
    }
  };

  const formProps: any = {
    HeaderProps: {
      align: 'center'
    },

    WrapperHeaderProps: {
      align: 'center'
    }
  };

  const formElements: any = {
    'form': <>
      <Form
        name='Steps'

        end={[
          <Tooltip
            name='Add step'
          >
            <IconButton
              onClick={onAddStep}
            >
              <IconMaterialAddRounded />
            </IconButton>
          </Tooltip>
        ]}

        gap={2}

        wrapper

        {...formProps}
      >
        <Line
          gap={4}

          fullWidth
        >
          {form.values.value.value.map((item: any, index: number) => (
            <Form
              key={item.id}

              name={(
                <TextField
                  name='Step name'

                  valueDefault={item.name || ''}

                  onChange={(valueNew: any) => form.onChange('value', valueNew, `${index}.name`, formOnChangeOptions)}

                  placeholder={`Step ${index + 1}`}

                  onClick={onStopPropagation}
                />
              )}

              end={[
                <Line
                  gap={0}

                  direction='row'

                  align='center'

                  onClick={onStopPropagation}
                >
                  <Tooltip
                    name='Move step up'
                  >
                    <IconButton
                      onClick={() => {
                        onMoveStep(index, index - 1);
                      }}

                      disabled={!index}
                    >
                      <IconMaterialKeyboardArrowUpRounded />
                    </IconButton>
                  </Tooltip>

                  <Tooltip
                    name='Move step down'
                  >
                    <IconButton
                      onClick={() => onMoveStep(index, index + 1)}

                      disabled={form.value.value?.length - 1 === index}
                    >
                      <IconMaterialKeyboardArrowDownRounded />
                    </IconButton>
                  </Tooltip>

                  <Tooltip
                    name='Copy step'
                  >
                    <IconButton
                      onClick={() => onCopyStep(index)}
                    >
                      <IconMaterialContentCopyRounded />
                    </IconButton>
                  </Tooltip>

                  <Tooltip
                    name='Remove step'
                  >
                    <IconButton
                      onClick={() => onRemoveStep(item.id)}
                    >
                      <IconMaterialRemoveRounded />
                    </IconButton>
                  </Tooltip>
                </Line>
              ]}

              AccordionProps={{
                ExpandProps: {
                  removeOnExited: false
                }
              }}

              accordion

              wrapper
            >
              <Form
                name='Inputs'

                description={`Inputs in this form's step`}

                end={[
                  <Tooltip
                    name='Add input'
                  >
                    <IconButton
                      onClick={(event: MouseEvent) => {
                        event.stopPropagation();

                        onAddInput(index);
                      }}
                    >
                      <IconMaterialAddRounded />
                    </IconButton>
                  </Tooltip>
                ]}

                AccordionProps={{
                  ExpandProps: {
                    removeOnExited: false
                  }
                }}

                AccordionMainProps={{
                  gap: 1.4,
                  className: classes.inputs
                }}

                size='small'

                accordion

                wrapper
              >
                {form.values.value.value[index].inputs.map((itemInput: any, indexInput: number) => (
                  <Form
                    key={itemInput.id + indexInput}

                    name={(
                      <Select
                        name='Version'

                        valueDefault={itemInput.version}

                        onChange={(valueNew: any) => form.onChange('value', valueNew, `${index}.inputs.${indexInput}.version`)}

                        onClick={onStopPropagation}

                        MenuProps={{
                          onClick: onStopPropagation
                        }}

                        {...allInputProps}
                      >
                        {['text', 'number', 'slider', 'radios', 'checkboxes', 'multiple-grid', 'date-time', 'date', 'time', 'select', 'autocomplete', 'static', 'color', 'rating', 'email', 'address', 'mobile', 'confirm', 'password'].map((version, i) => (
                          <ListItem
                            key={i}

                            primary={(
                              <Type
                                version='b3'
                              >
                                {cleanValue(version, { capitalize: true })}
                              </Type>
                            )}

                            value={version}

                            button
                          />
                        ))}
                      </Select>
                    )}

                    end={[
                      <Line
                        gap={0}

                        direction='row'

                        align='center'

                        onClick={onStopPropagation}
                      >
                        <Tooltip
                          name='Move input up'
                        >
                          <IconButton
                            onClick={() => onMoveInput(index, indexInput, indexInput - 1)}

                            size='small'

                            disabled={!indexInput}
                          >
                            <IconMaterialKeyboardArrowUpRounded />
                          </IconButton>
                        </Tooltip>

                        <Tooltip
                          name='Move input down'
                        >
                          <IconButton
                            onClick={() => onMoveInput(index, indexInput, indexInput + 1)}

                            size='small'

                            disabled={item.inputs.length - 1 === indexInput}
                          >
                            <IconMaterialKeyboardArrowDownRounded />
                          </IconButton>
                        </Tooltip>

                        <Tooltip
                          name='Copy input'
                        >
                          <IconButton
                            onClick={() => onCopyInput(index, indexInput)}

                            size='small'
                          >
                            <IconMaterialContentCopyRounded />
                          </IconButton>
                        </Tooltip>

                        <Tooltip
                          name='Remove input'
                        >
                          <IconButton
                            onClick={() => onRemoveInput(index, indexInput)}

                            size='small'
                          >
                            <IconMaterialRemoveRounded />
                          </IconButton>
                        </Tooltip>
                      </Line>
                    ]}

                    AccordionProps={{
                      ExpandProps: {
                        removeOnExited: false
                      }
                    }}

                    AccordionMainProps={{
                      gap: 1.4
                    }}

                    EndProps={{
                      gap: 0,
                      direction: 'row',
                      align: 'center'
                    }}

                    size='small'

                    accordion

                    wrapper

                    className={classes.input}

                    {...formRowProps}
                  >
                    <TextField
                      name='Name'

                      valueDefault={itemInput.name}

                      onChange={(valueNew: any) => form.onChange('value', valueNew, `${index}.inputs.${indexInput}.name`, formOnChangeOptions)}

                      {...allInputProps}
                    />

                    <SmartTextField
                      name='Description'

                      valueDefault={textToInnerHTML(itemInput.description || '')}

                      onChange={(valueNew: any) => form.onChange('value', innerHTMLToText(valueNew), `${index}.inputs.${indexInput}.description`, formOnChangeOptions)}

                      minRows={4}

                      multiline

                      edit

                      {...allInputProps}
                    />

                    {!['static'].includes(itemInput.version) && <>
                      <TextField
                        name='Helper text'

                        valueDefault={textToInnerHTML(itemInput.helper_text || '')}

                        onChange={(valueNew: any) => form.onChange('value', innerHTMLToText(valueNew), `${index}.inputs.${indexInput}.helper_text`, formOnChangeOptions)}

                        {...allInputProps}
                      />

                      <Label
                        checkedDefault={itemInput.required}

                        onChange={(valueNew: any) => form.onChange('value', valueNew, `${index}.inputs.${indexInput}.required`, formOnChangeOptions)}

                        {...allInputProps}
                      >
                        <Switch />

                        Required
                      </Label>
                    </>}

                    {['static'].includes(itemInput.version) && <>
                      <Line
                        gap={1}
                      >
                        {itemInput.static_image?.id && (
                          <Medias
                            values={[itemInput.static_image].map((item: any) => ({
                              value: item
                            }))}
                          />
                        )}

                        <Button
                          color='inherit'

                          version='outlined'

                          onClick={() => onMediaValue(`${index}.inputs.${indexInput}.static_image`, 'image')}

                          size='small'
                        >
                          Choose image
                        </Button>
                      </Line>

                      <Line
                        gap={1}
                      >
                        {itemInput.static_audio?.id && (
                          <Medias
                            values={[itemInput.static_audio].map((item: any) => ({
                              value: item
                            }))}
                          />
                        )}

                        <Line
                          gap={1}

                          direction='row'

                          align='center'
                        >
                          <Button
                            color='inherit'

                            version='outlined'

                            onClick={() => onMediaValue(`${index}.inputs.${indexInput}.static_audio`, 'audio')}

                            size='small'
                          >
                            Choose audio
                          </Button>

                          <AudioRecorder
                            onConfirm={(valueNew: any) => onAudioRecorderConfirmValue(valueNew, `${index}.inputs.${indexInput}.static_audio`)}

                            disabled={loading}
                          />
                        </Line>
                      </Line>

                      <Line
                        gap={1}
                      >
                        {itemInput.static_video?.id && (
                          <Medias
                            values={[itemInput.static_video].map((item: any) => ({
                              value: item
                            }))}
                          />
                        )}

                        <Button
                          color='inherit'

                          version='outlined'

                          onClick={() => onMediaValue(`${index}.inputs.${indexInput}.static_video`, 'video')}

                          size='small'
                        >
                          Choose video
                        </Button>
                      </Line>
                    </>}

                    {['multiple-grid'].includes(itemInput.version) && <>
                      <FormRow
                        name='Rows'

                        end={[
                          <Tooltip
                            name='Add row'
                          >
                            <IconButton
                              onClick={() => onAddMultipleGridItem(index, indexInput)}

                              size='small'
                            >
                              <IconMaterialAddRounded />
                            </IconButton>
                          </Tooltip>
                        ]}

                        fullWidth

                        wrapper

                        size='small'

                        {...formRowProps}
                      >
                        {itemInput.rows?.map((item: string, indexRow: number) => (
                          <TextField
                            key={indexRow}

                            valueDefault={item || ''}

                            onChange={(valueNew: any) => form.onChange('value', valueNew, `${index}.inputs.${indexInput}.rows.${indexRow}`, formOnChangeOptions)}

                            end={(
                              <Tooltip
                                name='Remove row'
                              >
                                <IconButton
                                  onClick={() => onRemoveMultipleGridItem(index, indexInput, indexRow)}

                                  size='small'
                                >
                                  <IconMaterialRemoveRounded />
                                </IconButton>
                              </Tooltip>
                            )}

                            {...allInputProps}
                          />
                        ))}
                      </FormRow>

                      <FormRow
                        name='Columns'

                        end={[
                          <Tooltip
                            name='Add column'
                          >
                            <IconButton
                              onClick={() => onAddMultipleGridItem(index, indexInput, 'columns')}

                              size='small'
                            >
                              <IconMaterialAddRounded />
                            </IconButton>
                          </Tooltip>
                        ]}

                        fullWidth

                        wrapper

                        size='small'

                        {...formRowProps}
                      >
                        {itemInput.columns?.map((item: string, indexColumn: number) => (
                          <TextField
                            key={indexColumn}

                            valueDefault={item || ''}

                            onChange={(valueNew: any) => form.onChange('value', valueNew, `${index}.inputs.${indexInput}.columns.${indexColumn}`, formOnChangeOptions)}

                            end={(
                              <Tooltip
                                name='Remove column'
                              >
                                <IconButton
                                  onClick={() => onRemoveMultipleGridItem(index, indexInput, indexColumn, 'columns')}

                                  size='small'
                                >
                                  <IconMaterialRemoveRounded />
                                </IconButton>
                              </Tooltip>
                            )}

                            {...allInputProps}
                          />
                        ))}
                      </FormRow>
                    </>}

                    {['confirm'].includes(itemInput.version) && <>
                      <TextField
                        name='Confirm text'

                        valueDefault={itemInput.confirm_text}

                        onChange={(valueNew: any) => form.onChange('value', valueNew, `${index}.inputs.${indexInput}.confirm_text`, formOnChangeOptions)}

                        {...allInputProps}
                      />
                    </>}

                    {['rating'].includes(itemInput.version) && <>
                      <NumericTextField
                        name='Rating values'

                        valueDefault={itemInput.rating_values !== undefined ? itemInput.rating_values : 5}

                        onChange={(valueNew: any) => form.onChange('value', valueNew, `${index}.inputs.${indexInput}.rating_values`, formOnChangeOptions)}

                        min={2}

                        max={100}

                        {...allInputProps}
                      />

                      <FormRow
                        name='Rating precision'

                        size='small'
                      >
                        <Slider
                          valueDefault={itemInput.rating_precision !== undefined ? itemInput.rating_precision : 1}

                          onChange={(valueNew: any) => form.onChange('value', valueNew, `${index}.inputs.${indexInput}.rating_precision`)}

                          min={0.25}

                          max={1}

                          marks={[
                            { value: 0.25, label: '0.25' },
                            { value: 0.5, label: '0.5' },
                            { value: 0.75, label: '0.75' },
                            { value: 1, label: '1' }
                          ]}

                          onlyMarks

                          labels

                          size='small'

                          style={{
                            width: '100vw',
                            maxWidth: 240,
                            marginBottom: 27
                          }}
                        />
                      </FormRow>
                    </>}

                    {['autocomplete'].includes(itemInput.version) && (
                      <Select
                        name='Autocomplete version'

                        valueDefault={itemInput.autocomplete_version !== undefined ? itemInput.autocomplete_version : 'regular'}

                        onChange={(valueNew: any) => form.onChange('value', valueNew, `${index}.inputs.${indexInput}.autocomplete_version`)}
                      >
                        {[['regular', 'countries'].map((versionOption: any, indexVersion: number) => (
                          <ListItem
                            key={indexVersion}

                            primary={(
                              <Type
                                version='b2'
                              >
                                {capitalize(versionOption)}
                              </Type>
                            )}

                            value={versionOption}

                            button
                          />
                        ))]}
                      </Select>
                    )}

                    {['date-time', 'date', 'time'].includes(itemInput.version) && (
                      <Label
                        checkedDefault={itemInput.range}

                        onChange={(valueNew: any) => form.onChange('value', valueNew, `${index}.inputs.${indexInput}.range`)}

                        {...allInputProps}
                      >
                        <Switch />

                        Range
                      </Label>
                    )}

                    {['multiple-grid', 'select', 'autocomplete'].includes(itemInput.version) && (
                      <Label
                        checkedDefault={itemInput.select_multiple}

                        onChange={(valueNew: any) => form.onChange('value', valueNew, `${index}.inputs.${indexInput}.select_multiple`)}

                        {...allInputProps}
                      >
                        <Switch />

                        Select multiple
                      </Label>
                    )}

                    {['select', 'autocomplete'].includes(itemInput.version) && <>
                      <Label
                        checkedDefault={itemInput.select_chips}

                        onChange={(valueNew: any) => form.onChange('value', valueNew, `${index}.inputs.${indexInput}.select_chips`)}

                        {...allInputProps}
                      >
                        <Switch />

                        Select chips
                      </Label>

                      {!((itemInput.version === 'autocomplete' && itemInput.autocomplete_version === 'countries')) && (
                        <Form
                          name={`${capitalize(itemInput.version)} options`}

                          end={[
                            <Tooltip
                              name={`Add ${itemInput.version} option`}
                            >
                              <IconButton
                                onClick={() => onAddInputSelectOption(index, indexInput)}

                                size='small'
                              >
                                <IconMaterialAddRounded />
                              </IconButton>
                            </Tooltip>
                          ]}

                          fullWidth

                          wrapper

                          size='small'

                          style={{
                            marginTop: 12
                          }}
                        >
                          {itemInput.select_options?.map((itemOption: any, indexOption: number) => (
                            <FormRow
                              key={index}

                              name={(
                                <Type
                                  version='l2'
                                >
                                  Input {itemInput.version} option {indexOption + 1}
                                </Type>
                              )}

                              end={[
                                <Tooltip
                                  name={`Remove ${itemInput.version} option`}
                                >
                                  <IconButton
                                    onClick={() => onRemoveInputSelectOption(index, indexInput, indexOption)}

                                    size='small'
                                  >
                                    <IconMaterialRemoveRounded />
                                  </IconButton>
                                </Tooltip>
                              ]}

                              {...formRowProps}
                            >
                              <TextField
                                name='Name'

                                valueDefault={itemOption.name || ''}

                                onChange={(valueNew: any) => form.onChange('value', valueNew, `${index}.inputs.${indexInput}.select_options.${indexOption}.name`, formOnChangeOptions)}

                                {...allInputProps}
                              />

                              <TextField
                                name='Value'

                                valueDefault={itemOption.value || ''}

                                onChange={(valueNew: any) => form.onChange('value', valueNew, `${index}.inputs.${indexInput}.select_options.${indexOption}.value`, formOnChangeOptions)}

                                {...allInputProps}
                              />

                              <TextField
                                name='Group'

                                valueDefault={itemOption.group || ''}

                                onChange={(valueNew: any) => form.onChange('value', valueNew, `${index}.inputs.${indexInput}.select_options.${indexOption}.group`, formOnChangeOptions)}

                                {...allInputProps}
                              />
                            </FormRow>
                          ))}
                        </Form>
                      )}
                    </>}

                    {['radios', 'checkboxes'].includes(itemInput.version) && (
                      <Form
                        name='Options'

                        end={[
                          <Tooltip
                            name={`Add ${itemInput.version === 'checkboxes' ? 'checkbox' : 'radio'} option`}
                          >
                            <IconButton
                              onClick={() => onAddInputOption(index, indexInput)}

                              size='small'
                            >
                              <IconMaterialAddRounded />
                            </IconButton>
                          </Tooltip>
                        ]}

                        fullWidth

                        wrapper

                        size='small'

                        style={{
                          marginTop: 12
                        }}
                      >
                        {itemInput.options?.map((itemOption: any, indexOption: number) => (
                          <FormRow
                            key={index}

                            start={(
                              <Type
                                version='l2'
                              >
                                Input option {indexOption + 1}
                              </Type>
                            )}

                            name={(
                              <Select
                                name='Version'

                                valueDefault={itemOption.version || 'type'}

                                onChange={(valueNew: any) => form.onChange('value', valueNew, `${index}.inputs.${indexInput}.options.${indexOption}.version`)}
                              >
                                {[['type', 'image', 'audio', 'video'].map((versionOption: any, indexVersion: number) => (
                                  <ListItem
                                    key={indexVersion}

                                    primary={(
                                      <Type
                                        version='b2'
                                      >
                                        {capitalize(versionOption)}
                                      </Type>
                                    )}

                                    value={versionOption}

                                    button
                                  />
                                ))]}
                              </Select>
                            )}

                            end={[
                              <Tooltip
                                name={`Remove ${itemInput.version === 'checkboxes' ? 'checkbox' : 'radio'} option`}
                              >
                                <IconButton
                                  onClick={() => onRemoveInputOption(index, indexInput, indexOption)}

                                  size='small'
                                >
                                  <IconMaterialRemoveRounded />
                                </IconButton>
                              </Tooltip>
                            ]}

                            {...formRowProps}
                          >
                            {itemOption.version === 'type' && (
                              <TextField
                                name='Option'

                                valueDefault={itemOption.value || ''}

                                onChange={(valueNew: any) => form.onChange('value', valueNew, `${index}.inputs.${indexInput}.options.${indexOption}.value`, formOnChangeOptions)}
                              />
                            )}

                            {itemOption.value?.id && (
                              <Medias
                                values={[itemOption.value].filter(Boolean).map((item: any) => ({
                                  value: item
                                }))}
                              />
                            )}

                            <Line
                              gap={1}

                              direction='row'

                              align='center'
                            >
                              {itemOption.version !== 'type' && (
                                <Button
                                  color='inherit'

                                  version='outlined'

                                  onClick={() => onMediaValue(`${index}.inputs.${indexInput}.options.${indexOption}.value`, itemOption.version)}

                                  size='small'
                                >
                                  Choose {itemOption.version}
                                </Button>
                              )}

                              {itemOption.version === 'audio' && (
                                <AudioRecorder
                                  onConfirm={(valueNew: any) => onAudioRecorderConfirmValue(valueNew, `${index}.inputs.${indexInput}.options.${indexOption}.value`)}

                                  disabled={loading}
                                />
                              )}
                            </Line>
                          </FormRow>
                        ))}
                      </Form>
                    )}

                    {['slider'].includes(itemInput.version) && <>
                      <TextField
                        name='Label at the start'

                        valueDefault={itemInput.label_start}

                        onChange={(valueNew: any) => form.onChange('value', valueNew, `${index}.inputs.${indexInput}.label_start`, formOnChangeOptions)}

                        {...allInputProps}
                      />

                      <TextField
                        name='Label at the end'

                        valueDefault={itemInput.label_end}

                        onChange={(valueNew: any) => form.onChange('value', valueNew, `${index}.inputs.${indexInput}.label_end`, formOnChangeOptions)}

                        {...allInputProps}
                      />

                      <Select
                        name='Orientation'

                        valueDefault={itemInput.orientation !== undefined ? itemInput.orientation : 'horizontal'}

                        onChange={(valueNew: any) => form.onChange('value', valueNew, `${index}.inputs.${indexInput}.orientation`)}

                        {...allInputProps}
                      >
                        {['vertical', 'horizontal'].map((version, i) => (
                          <ListItem
                            key={i}

                            primary={(
                              <Type
                                version='b3'
                              >
                                {capitalize(version)}
                              </Type>
                            )}

                            value={version}

                            button
                          />
                        ))}
                      </Select>
                    </>}

                    {['text', 'number'].includes(itemInput.version) && <>
                      <FormRow
                        name='Prefix'

                        description='Prefix for the input'
                      >
                        <TextField
                          name='Text'

                          valueDefault={itemInput.prefix}

                          onChange={(valueNew: any) => form.onChange('value', valueNew, `${index}.inputs.${indexInput}.prefix`, formOnChangeOptions)}

                          {...allInputProps}
                        />
                      </FormRow>

                      <FormRow
                        name='Sufix'

                        description='Sufix for the input'
                      >
                        <TextField
                          name='Text'

                          valueDefault={itemInput.sufix}

                          onChange={(valueNew: any) => form.onChange('value', valueNew, `${index}.inputs.${indexInput}.sufix`, formOnChangeOptions)}

                          {...allInputProps}
                        />
                      </FormRow>
                    </>}

                    {['text'].includes(itemInput.version) && <>
                      <Label
                        checkedDefault={itemInput.multiline}

                        helperText='Larger input in multiple lines'

                        onChange={(valueNew: any) => form.onChange('value', valueNew, `${index}.inputs.${indexInput}.multiline`)}

                        {...allInputProps}
                      >
                        <Switch />

                        Multiline
                      </Label>

                      {itemInput.multiline && <>
                        <NumericTextField
                          name='Min rows'

                          valueDefault={itemInput.minRows}

                          onChange={(valueNew: any) => form.onChange('value', valueNew, `${index}.inputs.${indexInput}.minRows`, formOnChangeOptions)}

                          min={0}

                          {...allInputProps}
                        />

                        <NumericTextField
                          name='Max rows'

                          valueDefault={itemInput.maxRows}

                          onChange={(valueNew: any) => form.onChange('value', valueNew, `${index}.inputs.${indexInput}.maxRows`, formOnChangeOptions)}

                          min={0}

                          {...allInputProps}
                        />
                      </>}
                    </>}

                    {['text', 'number', 'slider'].includes(itemInput.version) && <>
                      <NumericTextField
                        name={`Min ${itemInput.version === 'text' ? 'characters' : 'value'}`}

                        valueDefault={itemInput.min}

                        onChange={(valueNew: any) => form.onChange('value', valueNew, `${index}.inputs.${indexInput}.min`, formOnChangeOptions)}

                        min={0}

                        {...allInputProps}
                      />

                      <NumericTextField
                        name={`Max ${itemInput.version === 'text' ? 'characters' : 'value'}`}

                        valueDefault={itemInput.max}

                        onChange={(valueNew: any) => form.onChange('value', valueNew, `${index}.inputs.${indexInput}.max`, formOnChangeOptions)}

                        min={0}

                        {...allInputProps}
                      />
                    </>}

                    {['date-time'].includes(itemInput.version) && <>
                      <DateTimePicker
                        name='Min date time'

                        valueDefault={itemInput.min_date ? new AmauiDate(itemInput.min_date) : itemInput.min_date}

                        onChange={(valueNew: any) => form.onChange('value', valueNew ? valueNew.milliseconds : null, `${index}.inputs.${indexInput}.min_date`)}

                        {...allInputProps}
                      />

                      <DateTimePicker
                        name='Max date time'

                        valueDefault={itemInput.max_date ? new AmauiDate(itemInput.max_date) : itemInput.max_date}

                        onChange={(valueNew: any) => form.onChange('value', valueNew ? valueNew.milliseconds : null, `${index}.inputs.${indexInput}.max_date`)}

                        {...allInputProps}
                      />
                    </>}

                    {['date'].includes(itemInput.version) && <>
                      <DatePicker
                        name='Min date'

                        valueDefault={itemInput.min_date ? new AmauiDate(itemInput.min_date) : itemInput.min_date}

                        onChange={(valueNew: any) => form.onChange('value', valueNew ? valueNew.milliseconds : null, `${index}.inputs.${indexInput}.min_date`)}

                        {...allInputProps}
                      />

                      <DatePicker
                        name='Max date'

                        valueDefault={itemInput.max_date ? new AmauiDate(itemInput.max_date) : itemInput.max_date}

                        onChange={(valueNew: any) => form.onChange('value', valueNew ? valueNew.milliseconds : null, `${index}.inputs.${indexInput}.max_date`)}

                        {...allInputProps}
                      />
                    </>}

                    {['time'].includes(itemInput.version) && <>
                      <TimePicker
                        name='Min time'

                        valueDefault={itemInput.min_date ? new AmauiDate(itemInput.min_date) : itemInput.min_date}

                        onChange={(valueNew: any) => form.onChange('value', valueNew ? valueNew.milliseconds : null, `${index}.inputs.${indexInput}.min_date`)}

                        {...allInputProps}
                      />

                      <TimePicker
                        name='Max time'

                        valueDefault={itemInput.max_date ? new AmauiDate(itemInput.max_date) : itemInput.max_date}

                        onChange={(valueNew: any) => form.onChange('value', valueNew ? valueNew.milliseconds : null, `${index}.inputs.${indexInput}.max_date`)}

                        {...allInputProps}
                      />
                    </>}
                  </Form>
                ))}
              </Form>

              {['start', 'end'].map((item_: any) => (
                <Form
                  key={item_}

                  name={capitalize(item_)}

                  start={(
                    <Label
                      checkedDefault={form.values.value.value?.[index]?.[item_]?.active}

                      onChange={(valueNew: any) => form.onChange('value', valueNew, `${index}.${item_}.active`)}

                      onClick={onStopPropagation}
                    >
                      <Switch />
                    </Label>
                  )}

                  description={item_ === 'start' ? `View displayed to the user before he sees the form within that step.` : `View user sees after he fills out the form in the step, and clicks next.`}

                  size='small'

                  AccordionProps={{
                    WrapperHeaderProps: {
                      gap: 1
                    },

                    ExpandProps: {
                      removeOnExited: false
                    }
                  }}

                  accordion

                  wrapper
                >
                  <FormRow
                    fullWidth

                    style={{
                      marginBottom: 24
                    }}
                  >
                    <SmartTextField
                      placeholder='Name'

                      valueDefault={textToInnerHTML(form.values.value.value?.[index]?.[item_]?.name)}

                      onChange={(valueNew: any) => form.onChange('value', valueNew, `${index}.${item_}.name`, formOnChangeOptions)}

                      additional={{
                        version: 't2'
                      }}
                    />

                    <SmartTextField
                      placeholder='Summary'

                      valueDefault={textToInnerHTML(form.values.value.value?.[index]?.[item_]?.short_description)}

                      onChange={(valueNew: any) => form.onChange('value', valueNew, `${index}.${item_}.short_description`, formOnChangeOptions)}

                      minRows={4}

                      multiline

                      edit
                    />

                    <SmartTextField
                      placeholder='Description'

                      valueDefault={textToInnerHTML(form.values.value.value?.[index]?.[item_]?.description)}

                      onChange={(valueNew: any) => form.onChange('value', valueNew, `${index}.${item_}.description`, formOnChangeOptions)}

                      minRows={7}

                      multiline

                      edit
                    />
                  </FormRow>

                  <Line
                    gap={1}
                  >
                    {form.values.value.value?.[index]?.[item_]?.image?.id && (
                      <Medias
                        values={[form.values.value.value?.[index]?.[item_]?.image].map((item: any) => ({
                          value: item
                        }))}
                      />
                    )}

                    <Button
                      color='inherit'

                      version='outlined'

                      onClick={() => onMediaValue(`${index}.${item_}.image`, 'image')}

                      size='small'
                    >
                      Choose image
                    </Button>
                  </Line>

                  <Line
                    gap={1}
                  >
                    {form.values.value.value?.[index]?.[item_]?.audio?.id && (
                      <Medias
                        values={[form.values.value.value?.[index]?.[item_]?.audio].map((item: any) => ({
                          value: item
                        }))}
                      />
                    )}

                    <Line
                      gap={1}

                      direction='row'

                      align='center'
                    >
                      <Button
                        color='inherit'

                        version='outlined'

                        onClick={() => onMediaValue(`${index}.${item_}.audio`, 'audio')}

                        size='small'
                      >
                        Choose audio
                      </Button>

                      <AudioRecorder
                        onConfirm={(valueNew: any) => onAudioRecorderConfirmValue(valueNew, `${index}.${item_}.audio`)}

                        disabled={loading}
                      />
                    </Line>
                  </Line>

                  <Line
                    gap={1}
                  >
                    {form.values.value.value?.[index]?.[item_]?.video?.id && (
                      <Medias
                        values={[form.values.value.value?.[index]?.[item_]?.video].map((item: any) => ({
                          value: item
                        }))}
                      />
                    )}

                    <Button
                      color='inherit'

                      version='outlined'

                      onClick={() => onMediaValue(`${index}.${item_}.video`, 'video')}

                      size='small'
                    >
                      Choose video
                    </Button>
                  </Line>

                  {item_ === 'end' && <>
                    <FormRow
                      name='On success'

                      description={`If it's the final step, once user completes the entire form, success event happens.`}
                    >
                      <Label
                        checkedDefault={form.values.value.value?.[index]?.[item_]?.on_success === 'redirect'}

                        onChange={(valueNew: any) => form.onChange('value', valueNew ? 'redirect' : 'regular', `${index}.${item_}.on_success`)}
                      >
                        <Switch />

                        Redirect
                      </Label>

                      {form.values.value.value?.[index]?.[item_]?.on_success === 'redirect' && (
                        <FormRow
                          name='Redirect to'

                          description={`Either an absolute url ie. https://google.com, or to a page within the website ie. /about.`}
                        >
                          <TextField
                            name='URL'

                            valueDefault={form.values.value.value?.[index]?.[item_]?.on_success_redirect_to || ''}

                            onChange={(valueNew: any) => form.onChange('value', valueNew, `${index}.${item_}.on_success_redirect_to`, formOnChangeOptions)}

                            type='url'
                          />
                        </FormRow>
                      )}
                    </FormRow>
                  </>}
                </Form>
              ))
              }
            </Form>
          ))}
        </Line>
      </Form>
    </>,

    'poll': <>
      <Form
        name='Poll options'

        end={[
          <Tooltip
            name='Add poll option'
          >
            <IconButton
              onClick={onAddPollOption}
            >
              <IconMaterialAddRounded />
            </IconButton>
          </Tooltip>
        ]}

        gap={2}

        wrapper

        {...formProps}
      >
        {form.values.poll_options.value.map((item: any, index: number) => (
          <FormRow
            key={item.id}

            start={(
              <Type
                version='l2'
              >
                Option {index + 1}
              </Type>
            )}

            name={(
              <Select
                name='Version'

                valueDefault={item.version || 'type'}

                onChange={(valueNew: any) => form.onChange('poll_options', valueNew, `${index}.version`)}
              >
                {[['type', 'image', 'audio', 'video'].map((item: any, index_: number) => (
                  <ListItem
                    key={index_}

                    primary={(
                      <Type
                        version='b2'
                      >
                        {capitalize(item)}
                      </Type>
                    )}

                    value={item}

                    button
                  />
                ))]}
              </Select>
            )}

            end={[
              <Tooltip
                name='Remove poll option'
              >
                <IconButton
                  onClick={() => onRemovePollOption(index)}
                >
                  <IconMaterialRemoveRounded />
                </IconButton>
              </Tooltip>
            ]}

            {...formRowProps}
          >
            <TextField
              name='Option'

              valueDefault={item.value?.type || ''}

              onChange={(valueNew: any) => form.onChange('poll_options', valueNew, `${index}.value.type`, formOnChangeOptions)}
            />

            {item.value?.media?.id && (
              <Medias
                values={[item.value?.media].map((item: any) => ({
                  value: item
                }))}
              />
            )}

            <Line
              gap={1}

              direction='row'

              align='center'
            >
              {item.version !== 'type' && (
                <Button
                  color='inherit'

                  version='outlined'

                  onClick={() => onMedia(index, item.version)}

                  size='small'
                >
                  Choose {item.version}
                </Button>
              )}

              {item.version === 'audio' && (
                <AudioRecorder
                  onConfirm={(valueNew: any) => onAudioRecorderConfirm(valueNew, index)}

                  disabled={loading}
                />
              )}
            </Line>
          </FormRow>
        ))}
      </Form>
    </>
  };

  const modal: any = {
    'Form': <>
      <Line
        gap={2}

        fullWidth

        className={classes.page}
      >
        <FormRow
          fullWidth

          style={{
            marginBottom: 24
          }}
        >
          <TextField
            name='Name'

            valueDefault={textToInnerHTML(form.values['name'].value)}

            onChange={(valueNew: any) => form.onChange('name', valueNew, undefined, { rerenderOnUpdate: false })}
          />

          <SmartTextField
            placeholder='Description'

            valueDefault={textToInnerHTML(form.values['description'].value || '')}

            onChange={(valueNew: any) => form.onChange('description', valueNew, undefined, { rerenderOnUpdate: false })}

            minRows={4}

            multiline

            edit
          />
        </FormRow>

        <Select
          name='Version'

          valueDefault={form.values.version.value || 'form'}

          onChange={(valueNew: any) => form.onChange('version', valueNew)}
        >
          {['form', 'poll'].map((item: any, index: number) => (
            <ListItem
              key={index}

              primary={(
                <Type
                  version='b2'
                >
                  {capitalize(item)}
                </Type>
              )}

              value={item}

              button
            />
          ))}
        </Select>

        {formElements[form.values.version.value]}
      </Line>
    </>,

    'Options': <>
      <Line
        gap={2}

        fullWidth

        className={classes.page}
      >
        {form.values.version.value === 'form' && (
          <FormRow
            name='Progress'

            description='When there are multiple steps, shows progress bar, indiciating steps completed to the user.'
          >
            <Label
              checkedDefault={form.values.progress.value}

              onChange={(valueNew: any) => form.onChange('progress', valueNew)}
            >
              <Switch />

              Progress
            </Label>
          </FormRow>
        )}

        <OptionsWebsites
          description='Websites this form can be visible at'

          value={form.values.websites.value}

          onChange={(valueNew: any[]) => form.onChange('websites', valueNew)}
        />
      </Line>
    </>,

    'Responses': <>
      <Line
        gap={2}

        flex

        fullWidth

        className={classes.page}
      >
        <FormResponses
          value={{
            id: object?.id,

            ...refs.form.current.value
          }}
        />
      </Line>
    </>
  };

  return (
    <ModalForm
      {...props}

      object={object}

      add

      tabDefault='Form'

      tab={tab}

      tabs={['Form', 'Options', 'Responses']}

      onChangeTab={onChangeTab}

      onSubmit={onSubmit}

      onNext={onNext}

      onClose={onClose}

      loading={loading}
    >
      {modal[tab]}
    </ModalForm>
  );
});

export default Element;
