import React from 'react';

import { SpeedDial, SpeedDialItem, Switch, Tooltip } from '@amaui/ui-react';
import { classNames, style, useAmauiTheme } from '@amaui/style-react';

import { StorageService } from 'services';

import IconMaterialNestEcoLeafRounded from '@amaui/icons-material-rounded-react/IconMaterialNestEcoLeaf';
import IconMaterialFormatTextdirectionLToRRounded from '@amaui/icons-material-rounded-react/IconMaterialFormatTextdirectionLToR';
import IconMaterialFormatTextdirectionRToLRounded from '@amaui/icons-material-rounded-react/IconMaterialFormatTextdirectionRToL';

const useStyle = style(theme => ({
  root: {

  },

  speedDial: {
    '&.amaui-SpeedDial-root': {
      insetInlineEnd: 8,
      bottom: '8px'
    },

    '& .amaui-SpeedDial-icon-wrapper': {
      '& svg': {
        transform: 'rotate(15deg)'
      }
    }
  }
}), { name: 'amaui-app-WidgetAside' });

const WidgetAside = React.forwardRef((props: any, ref: any) => {
  const {
    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const theme = useAmauiTheme();

  const update = async (version = 'light', value: any = true) => {
    switch (version) {
      case 'light':
        theme.updateWithRerender({
          palette: {
            light: value
          }
        });

        StorageService.add('light', value);

        theme.inited = true;

        break;

      case 'direction':
        window.document.body.dir = value ? 'ltr' : 'rtl';

        theme.updateWithRerender({
          direction: value ? 'ltr' : 'rtl'
        });

        StorageService.add('direction', value);

        break;

      default:
        break;
    }
  };

  return (
    <SpeedDial
      tooltipname='Preferences'

      color='primary'

      position='bottom'

      alignment='end'

      direction='top'

      Icon={IconMaterialNestEcoLeafRounded}

      className={classNames([
        classes.root,
        classes.speedDial
      ])}

      {...other}
    >
      {/* <SpeedDialItem
          render={({ onBlur, onFocus, TooltipProps, ...otherItem }: any) => (
            <Tooltip
              name={theme.palette.light ? 'Light theme' : 'Dark theme'}

              nowrap

              {...TooltipProps}
            >
              <Switch
                {...otherItem}

                size='small'

                onBlur={onBlur}

                onFocus={onFocus}

                color='tertiary'

                OnIcon={<IconMaterialLightModeRounded />}

                OffIcon={<IconMaterialDarkModeRounded />}

                checked={theme.palette.light}

                onChange={(valueNew: boolean) => update('light', valueNew)}
              />
            </Tooltip>
          )}
        /> */}

      <SpeedDialItem
        render={({ onBlur, onFocus, TooltipProps, ...otherItem }: any) => (
          <Tooltip
            name={theme.direction === 'ltr' ? 'Left to right' : 'Right to left'}

            nowrap

            {...TooltipProps}
          >
            <Switch
              {...otherItem}

              size='small'

              onBlur={onBlur}

              onFocus={onFocus}

              color='tertiary'

              OnIcon={<IconMaterialFormatTextdirectionLToRRounded />}

              OffIcon={<IconMaterialFormatTextdirectionRToLRounded />}

              checked={theme.direction === 'ltr'}

              onChange={(valueNew: boolean) => update('direction', valueNew)}
            />
          </Tooltip>
        )}
      />
    </SpeedDial>
  );
});

export default WidgetAside;
