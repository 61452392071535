import React from 'react';
import { Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { Line } from '@amaui/ui-react';
import { classNames, useAmauiTheme } from '@amaui/style-react';

const UserSettings = React.forwardRef((props: any, ref: any) => {
  const {
    className,

    ...other
  } = props;

  const theme = useAmauiTheme();

  return <>
    <Helmet>
      <title>User settings</title>

      <link rel='icon' type='image/svg' sizes='32x32' href={`/assets/svg/logo-${theme.palette.light ? 'light' : 'dark'}.svg`} />
      <link rel='icon' type='image/svg' sizes='16x16' href={`/assets/svg/logo-${theme.palette.light ? 'light' : 'dark'}.svg`} />
      <meta name='theme-color' content={theme.palette.light ? '#fafa00' : '#675C00'} />
    </Helmet>

    <Line
      ref={ref}

      gap={4}

      flex

      fullWidth

      className={classNames([
        className
      ])}

      {...other}
    >
      <Outlet />
    </Line>
  </>;
});

export default UserSettings;
