import React from 'react';

import { capitalize, is, textToInnerHTML } from '@amaui/utils';
import { Form, FormRow, Line, Link, Medias, Type, useForm, useSnackbars } from '@amaui/ui-react';
import { AmauiDate, format } from '@amaui/date';

import { ModalForm, PropertiesContact, SelectMedia, SmartTextField, TextField } from 'ui';
import { AppService, ContactService } from 'services';
import { formats, getErrorMessage } from 'other';

// export const priorities = ['low', 'regular', 'high'];

// export const types = ['regular', 'personal', 'home', 'work', 'school', 'pager'];

export const versions = ['tels', 'emails', 'titles', 'urls', 'addresses', 'companies', 'birthdays', 'dates', 'custom'];

const toName = (value_: any, toCapitalize = true) => {
  let valueNew = value_;

  if (value_ === 'urls') valueNew = 'links';

  if (value_ === 'custom') valueNew = 'more';

  return toCapitalize ? capitalize(valueNew) : valueNew;
};

const getProperty = (item: any) => {
  let Element = <Type version='b2'>{item.value}</Type>

  const propsLink = {
    version: 'b2',
    target: 'blank'
  };

  if (item.version === 'email') Element = (
    <Link
      href={`mailto:${item.value}`}

      {...propsLink}
    >
      {item.value}
    </Link>
  );

  if (['mobile', 'tel', 'fax'].includes(item.version)) Element = (
    <Link
      href={`tel:${item.value}`}

      {...propsLink}
    >
      {item.value}
    </Link>
  );

  if (item.version === 'url') Element = (
    <Link
      href={item.value}

      {...propsLink}
    >
      {item.value}
    </Link>
  );

  if (['birthday', 'date'].includes(item.version)) Element = (
    <Type
      version='b2'
    >
      {format(new AmauiDate(item.value), formats.date)}
    </Type>
  );

  return (
    <Line
      key={item.id}
    >
      {React.cloneElement(Element)}
    </Line>
  );
};

export const Contact = React.forwardRef((props: any, ref: any) => {
  const {
    contact,

    ...other
  } = props;

  const info = contact && (contact.nickname || contact.description || contact.image || Object.keys(contact.properties || {})?.length);

  return (
    <Line
      fullWidth

      {...other}
    >
      {!info && (
        <Type
          version='b2'
        >
          No info
        </Type>
      )}

      {contact?.image && (
        <Medias
          values={{
            value: contact.image
          }}

          noName
        />
      )}

      {contact?.nickname && (
        <Type
          version='b2'
        >
          {contact.nickname}
        </Type>
      )}

      {contact?.description && (
        <Type
          version='b3'

          dangerouslySetInnerHTML={{
            __html: textToInnerHTML(contact.description)
          }}
        />
      )}

      {!!Object.keys(contact?.properties || {})?.length && (
        <Line
          gap={1}

          fullWidth
        >
          {versions.filter(version => !!contact?.properties[version]?.length).map((version: string) => (
            <Form
              key={version}

              name={toName(version)}

              gap={0.5}

              size='small'

              wrapper
            >
              <Line
                gap={0.5}

                fullWidth
              >
                {contact.properties[version]?.map((item: any) => getProperty(item))}
              </Line>
            </Form>
          ))}
        </Line>
      )}
    </Line>
  );
});

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    object: object_,

    onConfirm
  } = props;

  const snackbars = useSnackbars();

  const [object, setObject] = React.useState(object_);
  const [loading, setLoading] = React.useState<any>(false);

  const form = useForm({
    values: {
      'name': {
        name: 'Name',
        value: object?.name,
        required: true,
        max: 1400,
        messages: {
          min: 'Name has to be min 1 characters',
          max: 'Name can be max 1400 characters'
        }
      },
      'nickname': {
        name: 'Nickname',
        value: object?.nickname,
        required: false,
        max: 1400,
        messages: {
          min: 'Nickname has to be min 1 characters',
          max: 'Nickname can be max 1400 characters'
        }
      },
      'description': {
        name: 'Description',
        value: object?.description,
        max: 4400,
        messages: {
          min: 'Description has to be min 1 characters',
          max: 'Description can be max 4400 characters'
        }
      },
      properties: {
        name: 'Properties',
        is: 'object',
        value: object?.properties || {}
      },
      image: {
        name: 'Image',
        is: 'object',
        value: object?.image
      }
    }
  });

  const refs = {
    form: React.useRef(form)
  };

  refs.form.current = form;

  const init = React.useCallback(async () => {
    // init form
    initForm();
  }, [object]);

  const initForm = React.useCallback(() => {
    form.onChange([
      ['seo.name', object?.seo?.name],
      ['seo.description', object?.seo?.description],
    ]);
  }, [object, form]);

  React.useEffect(() => {
    // init
    init();
  }, []);

  const onClose = React.useCallback(() => {
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });
  }, []);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();
  }, []);

  const onNext = React.useCallback(async () => {
    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const body = {
      ...refs.form.current.value,

      apps: ['contact']
    };

    const result = !object?.id ? await ContactService.add(body) : await ContactService.update(object?.id, body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Contact ${!object?.id ? 'added' : 'updated'}`
      });

      setObject(result.response.response);

      if (is('function', onConfirm)) onConfirm();

      if (!object?.id) onClose();
    }

    setLoading(false);
  }, [object, onConfirm, onClose]);

  const contact = object ? form.value : object;

  const modal: any = {
    read: <>
      <Line
        fullWidth
      >
        <Contact
          contact={contact}
        />
      </Line>
    </>,

    write: <>
      <Line
        fullWidth
      >
        <FormRow
          fullWidth

          style={{
            marginBottom: 24
          }}
        >
          <TextField
            name='Name'

            valueDefault={textToInnerHTML(form.values['name'].value)}

            onChange={(valueNew: any) => form.onChange('name', valueNew, undefined, { rerenderOnUpdate: false })}

            fullWidth
          />

          <TextField
            name='Nickname'

            valueDefault={textToInnerHTML(form.values['nickname'].value)}

            onChange={(valueNew: any) => form.onChange('nickname', valueNew, undefined, { rerenderOnUpdate: false })}

            fullWidth
          />

          <SmartTextField
            placeholder='Description'

            valueDefault={textToInnerHTML(form.values['description'].value || '')}

            onChange={(valueNew: any) => form.onChange('description', valueNew, undefined, { rerenderOnUpdate: false })}

            minRows={4}

            multiline

            fullWidth

            edit
          />

          <SelectMedia
            name='Image'

            value={form.values['image'].value}

            mediaSelected={form.values['image'].value}

            onChange={(valueNew: any) => form.onChange('image', valueNew)}
          />

          <PropertiesContact
            form={form}
          />
        </FormRow>
      </Line>
    </>
  };

  return (
    <ModalForm
      {...props}

      object={object}

      add={!object}

      {...modal}

      onSubmit={onSubmit}

      onNext={onNext}

      onClose={onClose}

      loading={loading}

      smaller
    />
  );
});

export default Element;
