import React from 'react';
import { useNavigate } from 'react-router-dom';

import { hash } from '@amaui/utils';
import { Interaction, Line, Type, useSubscription } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';

import { ReactComponent as LogoWebsite } from 'assets/svg/logos/logo-website.svg';
import { ReactComponent as LogoResume } from 'assets/svg/logos/logo-resume.svg';
import { ReactComponent as LogoTasks } from 'assets/svg/logos/logo-task.svg';
import { ReactComponent as LogoNotes } from 'assets/svg/logos/logo-note.svg';
import { ReactComponent as LogoUrlShortener } from 'assets/svg/logos/logo-urlShortener.svg';
import { ReactComponent as LogoBlog } from 'assets/svg/logos/logo-blog.svg';
import { ReactComponent as LogoSupport } from 'assets/svg/logos/logo-support.svg';
import { ReactComponent as LogoChat } from 'assets/svg/logos/logo-chat.svg';
import { ReactComponent as LogoForm } from 'assets/svg/logos/logo-form.svg';
import { ReactComponent as LogoReservation } from 'assets/svg/logos/logo-reservation.svg';
import { ReactComponent as LogoContact } from 'assets/svg/logos/logo-contact.svg';
import { ReactComponent as LogoWorkDay } from 'assets/svg/logos/logo-workDay.svg';
import { ReactComponent as LogoLocation } from 'assets/svg/logos/logo-location.svg';

import { AuthService } from 'services';
import { ISignedIn, getAppRootURL } from 'other';

const useStyle = style(theme => ({
  root: {

  },

  items: {
    padding: 16
  },

  item: {
    position: 'relative',
    width: 94,
    padding: 8,
    borderRadius: 8,
    cursor: 'pointer',
    transition: theme.methods.transitions.make('transform'),
    userSelect: 'none',

    '& svg': {
      width: 41
    },

    '& .amaui-Type-root': {
      width: '100%',
      whiteSpace: 'normal',
      wordBreak: 'break-word'
    },

    '&:active': {
      transform: 'scale(0.94)'
    }
  }
}), { name: 'amaui-app-route-Main' });

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const navigate = useNavigate();

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const to = React.useCallback((value: string) => {
    navigate(value);
  }, []);

  const is = signedIn?.user?.is;

  const apps = React.useMemo(() => {
    return [
      ...(is?.user ? [{ name: 'Work days', logo: LogoWorkDay, to: getAppRootURL('app_workDay', '/work-days', signedIn), props: { className: 'amaui-logo-workDay' } }] : []),

      ...(is?.user ? [{ name: 'Location', logo: LogoLocation, to: getAppRootURL('app_location', '/locations', signedIn), props: { className: 'amaui-logo-location' } }] : []),

      ...(is?.user ? [{ name: 'Contact', logo: LogoContact, to: getAppRootURL('app_contact', '/contacts', signedIn), props: { className: 'amaui-logo-contact' } }] : []),

      ...(is?.user ? [{ name: 'Chat', logo: LogoChat, to: getAppRootURL('app_chat', '/chats', signedIn), props: { className: 'amaui-logo-chat' } }] : []),

      ...(is?.user ? [{ name: 'Reservation', logo: LogoReservation, to: getAppRootURL('app_reservation', '/reservations', signedIn), props: { className: 'amaui-logo-reservation' } }] : []),

      ...(is?.user ? [{ name: 'Tasks', logo: LogoTasks, to: getAppRootURL('app_task', '/tasks', signedIn), props: { className: 'amaui-logo-task' } }] : []),

      ...(is?.user ? [{ name: 'Notes', logo: LogoNotes, to: getAppRootURL('app_note', '/notes', signedIn), props: { className: 'amaui-logo-note' } }] : []),

      ...(is?.user ? [{ name: 'Website', logo: LogoWebsite, to: getAppRootURL('app_website', '/websites', signedIn), props: { className: 'amaui-logo-website' } }] : []),

      ...(is?.user ? [{ name: 'URL Shortener', logo: LogoUrlShortener, to: getAppRootURL('app_urlShortener', '/url-shorteners', signedIn), props: { className: 'amaui-logo-url-shortener' } }] : []),

      ...(is?.user ? [{ name: 'Support', logo: LogoSupport, to: getAppRootURL('app_support', '/support-pages', signedIn), props: { className: 'amaui-logo-support' } }] : []),

      ...(is?.user ? [{ name: 'Blog', logo: LogoBlog, to: getAppRootURL('app_blog', '/posts', signedIn), props: { className: 'amaui-logo-blog' } }] : []),

      ...(is?.user ? [{ name: 'Form', logo: LogoForm, to: getAppRootURL('app_form', '/forms', signedIn), props: { className: 'amaui-logo-form' } }] : []),

      ...(is?.user ? [{ name: 'Resume', logo: LogoResume, to: getAppRootURL('app_resume', '/resumes', signedIn), props: { className: 'amaui-logo-resume' } }] : [])
    ];
  }, [hash(is)]);

  return (
    <Line
      ref={ref}

      gap={2}

      direction='column'

      justify='center'

      align='center'

      flex

      fullWidth

      className={classNames([
        className,
        classes.root
      ])}

      {...other}
    >
      <Type
        version='t1'

        align='center'
      >
        Use an app
      </Type>

      <Line
        gap={0}

        direction='row'

        justify='center'

        wrap='wrap'

        className={classes.items}
      >
        {apps?.map(item => (
          <Line
            key={item.name}

            gap={1}

            align='center'

            onClick={() => to(item.to)}

            className={classes.item}
          >
            <item.logo />

            <Type
              version='b3'

              align='center'
            >
              {item.name}
            </Type>

            <Interaction />
          </Line>
        ))}
      </Line>
    </Line>
  );
});

export default Element;
