import React from 'react';

import { Form, FormRow, IconButton, Line, SmartTextField, Switch, TextField, Tooltip } from '@amaui/ui-react';
import { classNames, style, useAmauiTheme } from '@amaui/style-react';
import { arrayMoveItem, copy, getID, getObjectValue, setObjectValue, textToInnerHTML } from '@amaui/utils';

import IconMaterialKeyboardArrowUpRounded from '@amaui/icons-material-rounded-react/IconMaterialKeyboardArrowUp';
import IconMaterialKeyboardArrowDownRounded from '@amaui/icons-material-rounded-react/IconMaterialKeyboardArrowDown';
import IconMaterialAddRounded from '@amaui/icons-material-rounded-react/IconMaterialAdd';
import IconMaterialContentCopyRounded from '@amaui/icons-material-rounded-react/IconMaterialContentCopy';
import IconMaterialRemoveRounded from '@amaui/icons-material-rounded-react/IconMaterialRemove';

import { SelectAny, SelectColor, SelectMedia, SelectSize, SwitchInput } from './elements';
import { getMediaUrl } from 'other';

const useStyle = style(theme => ({
  root: {

  },

  type: {
    columnGap: '40px'
  },

  preview: {
    position: 'relative',
    width: 154,
    height: 77,
    borderRadius: 12,
    overflow: 'hidden',
    boxShadow: theme.shadows.values.default[1]
  },

  backgroundImage: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  },

  backgroundVideo: {
    position: 'absolute',
    inset: '0',
    minHeight: '100%',
    minWidth: '100%',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: '1'
  },

  backgroundOverlay: {
    position: 'absolute',
    inset: '0',
    zIndex: '4'
  },

  backgroundOverlayBlur: {
    backdropFilter: 'blur(12px)'
  },

  divider: {
    columnRule: `1px solid ${theme.palette.text.divider}`
  },

  h1: {
    ...theme.typography.values.h1,

    fontSize: `clamp(1.75rem, 1.5874vw, ${theme.typography.values.h1.fontSize})`
  },

  h2: {
    ...theme.typography.values.h2,

    fontSize: `clamp(1.5rem, 1.3394vw, ${theme.typography.values.h2.fontSize})`
  },

  h3: {
    ...theme.typography.values.h3,

    fontSize: `clamp(1.25rem, 1.1904vw, ${theme.typography.values.h3.fontSize})`
  },

  noData: {
    '& .amaui-FormRow-main': {
      display: 'none'
    }
  }
}), { name: 'amaui-app-OptionsSectionBoxes' });

const OptionsSectionBoxes = React.forwardRef((props: any, ref: any) => {
  const {
    value,

    element,

    index,

    onUpdate,

    className,

    children,

    ...other
  } = props;

  const { classes } = useStyle();

  const theme = useAmauiTheme();

  const refs = {
    value: React.useRef(value),
    index: React.useRef(index),
    onUpdate: React.useRef(onUpdate),
    element: React.useRef(element)
  };

  refs.value.current = value;

  refs.index.current = index;

  refs.onUpdate.current = onUpdate;

  refs.element.current = element;

  const update = React.useCallback((property: string, valueProperty: any) => {
    const valueNew = [...(refs.value.current || [])];

    if (refs.element.current) {
      setObjectValue(refs.element.current, property, valueProperty);

      refs.onUpdate.current(valueNew);
    }
  }, []);

  const enable = React.useCallback((valueSwitch: boolean, property: string, valueDefault: any) => {
    const valueNew = [...(refs.value.current || [])];

    if (refs.element.current) {
      setObjectValue(refs.element.current, property, !valueSwitch ? null : valueDefault);

      refs.onUpdate.current(valueNew);
    }
  }, []);

  const getItem = (item: any, index: number) => (
    <FormRow
      key={index}

      gap={2}

      name={item.name}

      start={!item.noSwitch && (
        <Switch
          tonal

          valueDefault={item.defaultSwitch !== undefined ? item.defaultSwitch : !!getObjectValue(element?.props, item.property)}

          onChange={(valueNew: any) => enable(valueNew, `props.${item.property}`, item.default)}

          size='small'
        />
      )}

      {...item?.props}

      className={classNames([
        item?.props?.className,
        (!item.show && [null, undefined].includes(getObjectValue(element?.props || {}, item.property))) && classes.noData
      ])}
    >
      {(item.show || ![null, undefined].includes(getObjectValue(element?.props || {}, item.property))) && item.value}
    </FormRow>
  );

  const onAddItem = React.useCallback((event: MouseEvent) => {
    event.stopPropagation();

    const values = refs.element.current?.props?.values || [];

    values.push({
      id: getID(),

      backgroundColor: 'primary',

      propsButton: {
        color: 'default',
        version: 'filled'
      }
    });

    update('props.values', values);
  }, []);

  const onUpdateItem = React.useCallback((property: string, valueNew: any, index: number) => {
    const values = refs.element.current?.props?.values || [];

    if (index > -1) {
      setObjectValue(values[index], property, valueNew);
    }

    update('props.values', values);
  }, []);

  const onMoveItem = React.useCallback((index: number, indexNew: number, event: MouseEvent) => {
    event.stopPropagation();

    const values = refs.element.current?.props?.values || [];

    arrayMoveItem(values, index, indexNew);

    update('props.values', values);
  }, []);

  const onCopyItem = React.useCallback((index: number, event: MouseEvent) => {
    event.stopPropagation();

    const values = refs.element.current?.props?.values || [];

    const item = copy(values[index]);

    item.name = `${item.name || ''} copy`.trim();

    values.push(item);

    update('props.values', values);
  }, []);

  const onRemoveItem = React.useCallback((index: number, event: MouseEvent) => {
    event.stopPropagation();

    const values = refs.element.current?.props?.values || [];

    if (index > -1) values.splice(index, 1);

    update('props.values', values);
  }, []);

  const options: any = [
    // size 
    {
      name: 'Size',
      property: 'size',
      default: 'regular',
      noSwitch: true,
      show: true,
      value: (
        <SelectSize
          value={element?.props?.size || 'regular'}

          onChange={(valueNew: any) => update('props.size', valueNew)}
        />
      )
    }
  ];

  return (
    <Line
      fullWidth

      className={classNames([
        className,
        classes.root
      ])}

      {...other}
    >
      <Form
        size='small'

        wrapper
      >
        <Line
          fullWidth
        >
          {options.map((item: any, index: number) => getItem(item, index))}

          <SwitchInput
            name='Responsive'

            checkedDefault={element?.props?.responsive !== undefined ? element?.props?.responsive : true}

            onChange={(valueNew: any) => update('props.responsive', valueNew)}
          />

          <Form
            name='Items'

            openDefault

            accordion

            wrapper

            end={(
              <Tooltip
                name='Add item'
              >
                <IconButton
                  onClick={onAddItem}

                  disabled={element?.props?.values?.length >= 4}
                >
                  <IconMaterialAddRounded />
                </IconButton>
              </Tooltip>
            )}
          >
            {(element?.props?.values || []).map((item: any, index: number) => {
              const paletteBackgroundColor = item?.backgroundColor && (theme.palette.color[item?.backgroundColor] || theme.methods.color(item?.backgroundColor));

              const paletteBackgroundGradient1 = item?.backgroundGradient && (theme.palette.color[item?.backgroundGradient![0]] || theme.methods.color(item?.backgroundGradient![0]));
              const paletteBackgroundGradient2 = item?.backgroundGradient && (theme.palette.color[item?.backgroundGradient![1]] || theme.methods.color(item?.backgroundGradient![1]));
              const paletteBackgroundGradient3 = item?.backgroundGradient && (theme.palette.color[item?.backgroundGradient![2]] || theme.methods.color(item?.backgroundGradient![2]));

              const paletteOverlay = item?.overlay && (theme.palette.color[item.overlay] || theme.methods.color(item.overlay));

              const urlImage = getMediaUrl(item?.backgroundImage);

              const urlVideo = getMediaUrl(item?.backgroundVideo, item?.overlayBlur ? 140 : undefined);

              const optionsBackground: any = [
                // backgroundColor 
                {
                  name: 'Background color',
                  property: `values.${index}.backgroundColor`,
                  default: 'default',
                  value: (
                    <Line
                      gap={1.5}

                      direction='column'

                      fullWidth
                    >
                      <SelectColor
                        value={item?.backgroundColor}

                        onChange={(valueNew: any) => update(`props.values.${index}.backgroundColor`, valueNew)}
                      />

                      <Line
                        className={classes.preview}

                        style={{
                          backgroundColor: item?.backgroundColor && theme.methods.palette.color.value(undefined as any, 95, true, paletteBackgroundColor)
                        }}
                      />
                    </Line>
                  )
                },

                // backgroundGradient
                {
                  name: 'Background gradient',
                  property: `values.${index}.backgroundGradient`,
                  default: ['primary', 'secondary', 'warning'],
                  value: (
                    <Line
                      gap={1.5}

                      direction='column'

                      fullWidth
                    >
                      <Line
                        direction='row'

                        align='center'

                        fullWidth
                      >
                        <SelectColor
                          value={item?.backgroundGradient?.[0]}

                          onChange={(valueNew: any) => update(`props.values.${index}.backgroundGradient.0`, valueNew)}

                          except={['default']}
                        />

                        <SelectColor
                          value={item?.backgroundGradient?.[1]}

                          onChange={(valueNew: any) => update(`props.values.${index}.backgroundGradient.1`, valueNew)}

                          except={['default']}
                        />

                        <SelectColor
                          value={item?.backgroundGradient?.[2]}

                          onChange={(valueNew: any) => update(`props.values.${index}.backgroundGradient.2`, valueNew)}

                          except={['default']}
                        />
                      </Line>

                      <Line
                        className={classes.preview}

                        style={{
                          backgroundImage: item?.backgroundGradient && `linear-gradient(${theme.palette.light ? '130deg' : '330deg'}, ${theme.methods.palette.color.colorToRgb(paletteBackgroundGradient1.main, 0.14)} 0%, ${theme.methods.palette.color.colorToRgb(paletteBackgroundGradient2.main, 0.14)} 40%, ${theme.methods.palette.color.colorToRgb(paletteBackgroundGradient3.main, 0.14)} 100%)`
                        }}
                      />
                    </Line>
                  ),
                },

                // backgroundImage
                {
                  name: 'Background image',
                  property: `values.${index}.backgroundImage`,
                  default: true,
                  value: (
                    <Line
                      gap={1.5}

                      direction='column'

                      fullWidth
                    >
                      <SelectMedia
                        value={item?.backgroundImage}

                        onChange={(valueNew: any) => update(`props.values.${index}.backgroundImage`, valueNew)}
                      />

                      {urlImage && (
                        <Line
                          className={classNames([
                            classes.preview,
                            classes.backgroundImage
                          ])}

                          style={{
                            backgroundImage: item?.backgroundImage && `url('${urlImage}')`
                          }}
                        />
                      )}
                    </Line>
                  )
                },

                // backgroundVideo
                {
                  name: 'Background video',
                  property: `values.${index}.backgroundVideo`,
                  default: true,
                  value: (
                    <Line
                      gap={1.5}

                      direction='column'

                      fullWidth
                    >
                      <SelectMedia
                        value={item?.backgroundVideo}

                        mime='video'

                        onChange={(valueNew: any) => update(`props.values.${index}.backgroundVideo`, valueNew)}
                      />

                      {urlVideo && (
                        <Line
                          className={classes.preview}
                        >
                          {item?.backgroundVideo?.id && (
                            <video
                              autoPlay
                              muted
                              loop

                              className={classes.backgroundVideo}
                            >
                              <source
                                src={urlVideo}
                              />
                            </video>
                          )}
                        </Line>
                      )}
                    </Line>
                  )
                },

                // overlay    
                {
                  name: 'Overlay',
                  property: `values.${index}.overlay`,
                  default: 'secondary',
                  value: (
                    <Line
                      gap={1.5}

                      direction='column'

                      fullWidth
                    >
                      <SelectColor
                        value={item?.overlay}

                        onChange={(valueNew: any) => update(`props.values.${index}.overlay`, valueNew)}

                        except={['default']}
                      />

                      <Line
                        className={classNames([
                          classes.preview,
                          classes.backgroundImage
                        ])}

                        style={{
                          backgroundColor: item?.backgroundGradient && theme.methods.palette.color.value(undefined as any, 95, true, paletteBackgroundColor),

                          backgroundImage: item?.backgroundImage ? `url('${urlImage}')` : item?.backgroundGradient && `linear-gradient(${theme.palette.light ? '130deg' : '330deg'}, ${theme.methods.palette.color.colorToRgb(paletteBackgroundGradient1.main, 0.14)} 0%, ${theme.methods.palette.color.colorToRgb(paletteBackgroundGradient2.main, 0.14)} 40%, ${theme.methods.palette.color.colorToRgb(paletteBackgroundGradient3.main, 0.14)} 100%)`
                        }}
                      >
                        {item?.backgroundVideo?.id && (
                          <video
                            autoPlay
                            muted
                            loop

                            className={classes.backgroundVideo}
                          >
                            <source
                              src={urlVideo}
                            />
                          </video>
                        )}

                        {item?.overlay && (
                          <div
                            className={classNames([
                              classes.backgroundOverlay
                            ])}

                            style={{
                              backgroundColor: theme.methods.palette.color.alpha(theme.methods.palette.color.value(undefined as any, 95, true, paletteOverlay), 0.74)
                            }}
                          />
                        )}
                      </Line>
                    </Line>
                  )
                },

                // overlayBlur 
                {
                  name: 'Overlay blur',
                  property: `values.${index}.overlayBlur`,
                  default: true,
                  value: (
                    <Line
                      gap={1.5}

                      direction='column'

                      fullWidth
                    >
                      {item?.overlay && (
                        <Line
                          className={classNames([
                            classes.preview,
                            classes.backgroundImage
                          ])}

                          style={{
                            backgroundColor: item?.backgroundGradient && theme.methods.palette.color.value(undefined as any, 95, true, paletteBackgroundColor),

                            backgroundImage: item?.backgroundImage ? `url('${urlImage}')` : item?.backgroundGradient && `linear-gradient(${theme.palette.light ? '130deg' : '330deg'}, ${theme.methods.palette.color.colorToRgb(paletteBackgroundGradient1.main, 0.14)} 0%, ${theme.methods.palette.color.colorToRgb(paletteBackgroundGradient2.main, 0.14)} 40%, ${theme.methods.palette.color.colorToRgb(paletteBackgroundGradient3.main, 0.14)} 100%)`
                          }}
                        >
                          {item?.backgroundVideo?.id && (
                            <video
                              autoPlay
                              muted
                              loop

                              className={classes.backgroundVideo}
                            >
                              <source
                                src={urlVideo}
                              />
                            </video>
                          )}

                          {item?.overlay && (
                            <div
                              className={classNames([
                                classes.backgroundOverlay,
                                classes.backgroundOverlayBlur
                              ])}

                              style={{
                                backgroundColor: theme.methods.palette.color.alpha(theme.methods.palette.color.value(undefined as any, 95, true, paletteOverlay), 0.74)
                              }}
                            />
                          )}
                        </Line>
                      )}
                    </Line>
                  )
                }
              ];

              return (
                <Form
                  key={item.id}

                  gap={1}

                  name={item?.name ? textToInnerHTML(item.name) : `Item ${index + 1}`}

                  size='small'

                  fullWidth={false}

                  row

                  MainProps={{
                    direction: 'column'
                  }}

                  HeaderProps={{
                    align: 'center'
                  }}

                  end={(
                    <Line
                      gap={0}

                      direction='row'

                      align='center'
                    >
                      <Tooltip
                        name='Move up'
                      >
                        <IconButton
                          onClick={(event: MouseEvent) => onMoveItem(index, index - 1, event)}

                          size='small'

                          disabled={!index}
                        >
                          <IconMaterialKeyboardArrowUpRounded />
                        </IconButton>
                      </Tooltip>

                      <Tooltip
                        name='Move down'
                      >
                        <IconButton
                          onClick={(event: MouseEvent) => onMoveItem(index, index + 1, event)}

                          size='small'

                          disabled={index === value?.length - 1}
                        >
                          <IconMaterialKeyboardArrowDownRounded />
                        </IconButton>
                      </Tooltip>

                      <Tooltip
                        name='Copy item'
                      >
                        <IconButton
                          onClick={(event: MouseEvent) => onCopyItem(index, event)}

                          size='small'
                        >
                          <IconMaterialContentCopyRounded />
                        </IconButton>
                      </Tooltip>

                      <Tooltip
                        name='Remove item'
                      >
                        <IconButton
                          onClick={(event: MouseEvent) => onRemoveItem(index, event)}

                          size='small'
                        >
                          <IconMaterialRemoveRounded />
                        </IconButton>
                      </Tooltip>
                    </Line>
                  )}

                  accordion

                  wrapper
                >
                  <FormRow
                    fullWidth

                    style={{
                      marginBottom: 24
                    }}
                  >
                    <SmartTextField
                      placeholder='Name'

                      valueDefault={textToInnerHTML(item?.name || '')}

                      onChange={(valueNew: string) => onUpdateItem('name', valueNew, index)}

                      additional={{
                        version: 't2'
                      }}

                      edit
                    />

                    <SmartTextField
                      placeholder='Description'

                      valueDefault={textToInnerHTML(item?.description || '')}

                      onChange={(valueNew: string) => onUpdateItem('description', valueNew, index)}

                      additional={{
                        version: 'b2'
                      }}

                      multiline

                      edit
                    />
                  </FormRow>

                  <FormRow
                    name='Button'
                  >
                    <TextField
                      name='Button text'

                      valueDefault={textToInnerHTML(item?.buttonText || '')}

                      onChange={(valueNew: string) => onUpdateItem('buttonText', valueNew, index)}
                    />

                    <SelectAny
                      name='Button version'

                      value={item.propsButton?.version || 'filled'}

                      options={['filled', 'outlined', 'text']}

                      onChange={(valueNew: any) => onUpdateItem('propsButton.version', valueNew, index)}
                    />

                    <SelectColor
                      name='Button color'

                      value={item.propsButton?.color || 'primary'}

                      onChange={(valueNew: any) => onUpdateItem('propsButton.color', valueNew, index)}
                    />

                    <FormRow
                      name='To'

                      description={`If you want to navigate to an existing website page`}
                    >
                      <TextField
                        name='To'

                        placeholder='/about'

                        valueDefault={textToInnerHTML(item?.to || '')}

                        onChange={(valueNew: string) => onUpdateItem('to', valueNew, index)}

                        type='url'
                      />
                    </FormRow>

                    <FormRow
                      name='Link'

                      description={`If you want to navigate to an external website`}
                    >
                      <TextField
                        name='Link'

                        placeholder='https://amaui.me'

                        valueDefault={textToInnerHTML(item?.link || '')}

                        onChange={(valueNew: string) => onUpdateItem('link', valueNew, index)}

                        type='url'
                      />
                    </FormRow>
                  </FormRow>

                  <FormRow
                    name='Background'
                  >
                    {optionsBackground.map((item: any, indexOptionBackground: number) => getItem(item, indexOptionBackground))}
                  </FormRow>
                </Form>
              );
            })}
          </Form>
        </Line>
      </Form>
    </Line>
  );
});

export default OptionsSectionBoxes;
