import { Theme } from '@amaui/api-utils';
import { Query } from '@amaui/models';
import { IAdditional } from '@amaui/sdk/other';
import AmauiSubscription from '@amaui/subscription';

import { BaseService } from './base';
import { AmauiService } from 'services';
import { IQuerySubscription, ISelectedSubscription } from 'other';

class ThemeService extends BaseService<Theme> {
  public queryItems = new AmauiSubscription<IQuerySubscription>();
  public selectedItems = new AmauiSubscription<ISelectedSubscription>();
  public queryThemesPublic = new AmauiSubscription<IQuerySubscription>();

  public constructor() {
    super('themes');

    this.queryObjectsInit(this.queryItems);

    this.selectedObjectsInit(this.selectedItems);

    this.queryObjectsInit(this.queryThemesPublic, 'queryPostPublic');
  }

  public async queryPublic(options?: IAdditional) {
    return AmauiService.sdk.themes.queryPublic(options);
  }

  public async queryPostPublic(body?: Partial<Query>, options?: IAdditional) {
    return AmauiService.sdk.themes.queryPostPublic(body, options);
  }

  public async copy(id: string, options?: IAdditional) {
    return AmauiService.sdk.themes.copy(id, options);
  }

}

export default new ThemeService();
