import { Page } from '@amaui/api-utils';
import AmauiSubscription from '@amaui/subscription';
import { IAdditional, IResponse } from '@amaui/sdk/other';
import { Query } from '@amaui/models';

import { BaseService } from './base';
import { AmauiService } from 'services';
import { IQuerySubscription, ISelectedSubscription } from 'other';

class PageService extends BaseService<Page> {
  public queryPages = new AmauiSubscription<IQuerySubscription>();
  public selectedPages = new AmauiSubscription<ISelectedSubscription>();
  public queryPageVersions = new AmauiSubscription<IQuerySubscription>();
  public selectedPageVersions = new AmauiSubscription<ISelectedSubscription>();

  public constructor() {
    super('pages');

    this.queryObjectsInit(this.queryPages);

    this.selectedObjectsInit(this.selectedPages);

    this.queryObjectsInit(this.queryPageVersions, 'queryVersionPost');

    this.selectedObjectsInit(this.selectedPageVersions);
  }

  // version 
  public async getVersion(id: string, version_id: string, options?: IAdditional): Promise<IResponse> {
    return AmauiService.sdk.pages.getVersion(id, version_id, options);
  }

  public async queryVersion(id: string, options?: IAdditional): Promise<IResponse> {
    return AmauiService.sdk.pages.queryVersion(id, options);
  }

  public async queryVersionPost(id: string, body?: Partial<Query>, options?: IAdditional): Promise<IResponse> {
    return AmauiService.sdk.pages.queryVersionPost(id, body, options);
  }

  public async applyVersion(id: string, id_version: string, options?: IAdditional): Promise<IResponse> {
    return AmauiService.sdk.pages.applyVersion(id, id_version, options);
  }

}

export default new PageService();
