import { Line, Type } from '@amaui/ui-react';

export const Element = (props: any) => {

  return (
    <Line
      fullWidth

      {...props}
    >
      <Line
        gap={0.75}

        direction='row'

        align='center'

        justify='center'

        fullWidth

        style={{
          paddingTop: 54
        }}
      >
        <Type
          version='t3'

          align='center'
        >
          No results found
        </Type>

        <Type
          version='t1'
        >
          🫠
        </Type>
      </Line>
    </Line>
  );
};

export default Element; 
