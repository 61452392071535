import React from 'react';

import { is, setObjectValue } from '@amaui/utils';
import { Form, Line } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';

import { ResumeService } from 'services';
import { AutoCompleteObjects } from 'ui';

const useStyle = style(theme => ({
  root: {

  }
}), { name: 'amaui-app-OptionsResume' });

const OptionsResume = React.forwardRef((props: any, ref: any) => {
  const {
    value,

    element,

    index,

    onUpdate,

    className,

    children,

    ...other
  } = props;

  const { classes } = useStyle();

  const refs = {
    value: React.useRef(value),
    index: React.useRef(index),
    onUpdate: React.useRef(onUpdate),
    element: React.useRef(element)
  };

  refs.value.current = value;

  refs.index.current = index;

  refs.onUpdate.current = onUpdate;

  refs.element.current = element;

  const update = React.useCallback((property: string, valueProperty: any) => {
    const valueNew = [...(refs.value.current || [])];

    if (refs.element.current) {
      setObjectValue(refs.element.current, property, valueProperty);

      refs.onUpdate.current(valueNew);
    }
  }, []);

  const onChangeAutoComplete = React.useCallback((valueNew_: any) => {
    const valueNew = is('array', valueNew_) ? valueNew_[0] : valueNew_;

    update('props.response', valueNew ? {
      id: valueNew?.value,
      name: valueNew?.name
    } : null);
  }, []);

  return (
    <Line
      fullWidth

      className={classNames([
        className,
        classes.root
      ])}

      {...other}
    >
      <Form
        size='small'

        wrapper
      >
        <AutoCompleteObjects
          name='Resumes'

          valueInputDefault={element?.props?.response?.name}

          value={{
            ...element?.props?.response,

            value: element?.props?.response?.id
          }}

          onChange={onChangeAutoComplete}

          service={ResumeService}

          query={{
            active: true
          }}
        />
      </Form>
    </Line>
  );
});

export default OptionsResume;
