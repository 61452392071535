import React from 'react';
import audioFix from 'webm-duration-fix';

import { capitalize, cleanValue, copy, getID, getObjectValue, is, setObjectValue, textToInnerHTML } from '@amaui/utils';
import { AudioRecorder, DateTimeRangePicker, Form, FormRow, IconButton, Label, Line, ListItem, Medias, Select, SmartTextField, Switch, TimeRangePicker, Tooltip, Type, useForm, useSnackbars } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';
import { Available, IMedia } from '@amaui/api-utils';
import { AmauiDate, add, format } from '@amaui/date';

import IconMaterialContentCopyRounded from '@amaui/icons-material-rounded-react/IconMaterialContentCopy';
import IconMaterialAddRounded from '@amaui/icons-material-rounded-react/IconMaterialAdd';
import IconMaterialRemoveRounded from '@amaui/icons-material-rounded-react/IconMaterialRemove';

import { AutoCompleteObjects, Button, ModalForm, NumericTextField, OptionsWebsites, TextField, useMedia } from 'ui';
import { AppService, AvailableService, MediaService, ReservationOptionService } from 'services';
import { formats, getErrorMessage, mediasToValue } from 'other';

const useStyle = style(theme => ({
  root: {

  },

  times: {
    paddingBottom: '34vh !important'
  },

  ...theme.classes(theme)
}), { name: 'amaui-app-Available' });

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    object: object_,

    onConfirm
  } = props;

  const { classes } = useStyle();

  const media = useMedia();
  const snackbars = useSnackbars();

  const [object, setObject] = React.useState(object_);
  const [tab, setTab] = React.useState(!object ? 'Options' : 'Available');
  const [loading, setLoading] = React.useState<any>(false);

  const form = useForm({
    values: {
      'name': {
        name: 'Name',
        value: object?.name,
        required: true,
        max: 1400,
        messages: {
          min: 'Name has to be min 1 characters',
          max: 'Name can be max 1400 characters'
        }
      },
      'description': {
        name: 'Description',
        value: object?.description,
        max: 4400,
        messages: {
          min: 'Description has to be min 1 characters',
          max: 'Description can be max 4400 characters'
        }
      },
      'version': {
        name: 'Version',
        is: 'string',
        in: ['start-calendar', 'start', 'calendar'],
        value: object?.version || 'start-calendar'
      },
      'times': {
        name: 'Times',
        is: 'object',
        value: object?.times || {
          weekly: {
            days: {
              1: { active: true },
              2: { active: true },
              3: { active: true },
              4: { active: true },
              5: { active: true },
              6: { active: false },
              7: { active: false }
            }
          }
        }
      },
      'start': {
        name: 'Start',
        is: 'object',
        value: object?.start || {}
      },
      'personal': {
        name: 'Personal',
        is: 'boolean',
        value: false
      },
      'websites': {
        name: 'Websites',
        value: object?.websites,
        is: 'array',
        of: 'object'
      }
    }
  });

  const refs = {
    form: React.useRef(form)
  };

  refs.form.current = form;

  const formOnChangeOptions: any = {
    rerenderOnUpdate: false
  };

  const init = React.useCallback(async () => {
    // init form
    initForm();
  }, [object]);

  const initForm = React.useCallback(() => {
    if (is('array', object?.websites)) object.websites = object?.websites.map((objectWebsite: any) => ({
      ...objectWebsite,

      value: objectWebsite?.id
    }));

    form.onChange([
      ['websites', object?.websites || []]
    ]);
  }, [object, form]);

  React.useEffect(() => {
    // init
    init();
  }, []);

  const onClose = React.useCallback(() => {
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });
  }, []);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();
  }, []);

  const onNext = React.useCallback(async (event: SubmitEvent) => {
    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const body: Available = copy({
      ...refs.form.current.value
    });

    // if (body.timezone) {
    //   delete body.timezone.value
    // } 

    if (body.times.weekly?.days) {
      Object.values(body.times.weekly.days).forEach(day => {
        day.values = day.values?.map(itemValue => {
          if (itemValue.reservation_options) itemValue.reservation_options = itemValue.reservation_options.filter(Boolean).map(item => {

            return {
              id: item.id,
              name: item.name,
              total: item.total || 0
            };
          });

          return itemValue;
        });
      });
    }

    if (body.times?.dates?.values) {
      body.times.dates.values = body.times.dates.values.map(itemValue => {
        if (itemValue.reservation_options) itemValue.reservation_options = itemValue.reservation_options.filter(Boolean).map(item => {

          return {
            id: item.id,
            name: item.name,
            total: item.total || 0
          };
        });

        return itemValue;
      });
    }

    if (is('array', body.websites)) body.websites = body.websites.map((item: any) => ({
      id: item.id,
      name: item.name
    }));

    const result = !object?.id ? await AvailableService.add(body) : await AvailableService.update(object?.id, body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Available ${!object?.id ? 'added' : 'updated'}`
      });

      setObject(result.response.response);

      if (is('function', onConfirm)) onConfirm();

      if (!object?.id) onClose();
    }

    setLoading(false);
  }, [object, form, onConfirm, onClose]);

  const onChangeTab = React.useCallback(async (value: any) => {
    await refs.form.current.validate();

    setTab(value);
  }, []);

  const onStopPropagation = React.useCallback((event: MouseEvent) => {
    event.stopPropagation();
  }, []);

  const onAudioRecorderConfirmValue = React.useCallback(async (value: Blob, path: string) => {
    setLoading('voice recording');

    const media = await audioFix(value);

    // validate
    // 140 mb maximum
    if (media.size > (140 * 1e6)) {
      snackbars.add({
        color: 'error',
        primary: `Maximum allowed file size is 140 mb`
      });

      return;
    }

    const name = `Voice recording, ${format(new AmauiDate(), formats.entire)}`;

    // meta
    const meta: any = {};

    const result = await MediaService.add({
      name,
      meta,

      app: 'note',

      // media
      media
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      const mediaMongo = result?.response?.response;

      const value = {
        ...mediasToValue(mediaMongo)
      };

      const start = refs.form.current.values.start.value;

      setObjectValue(start, path, value);

      refs.form.current.onChange('start', start);
    }

    setLoading(false);
  }, []);

  const onMediaConfirmValue = React.useCallback((valueNew: IMedia[], path: string, type: string = 'image') => {
    let value: any = [];

    const item = valueNew;

    if (item) {
      value = item;
    }

    const start = refs.form.current.values.start.value;

    setObjectValue(start, path, value);

    refs.form.current.onChange('start', start);
  }, []);

  const onMediaValue = React.useCallback((path: string, type: string = 'image') => {
    media.open({
      allowed: [type],
      filters: {
        mime: type
      },
      selected: [getObjectValue(refs.form.current.values.start.value, path)],
      onConfirm: (valueNew) => onMediaConfirmValue(valueNew, path, type)
    });
  }, [media, onMediaConfirmValue]);

  const onAddDate = React.useCallback(() => {
    const values = [...(refs.form.current.values.times.value?.dates?.values || [])];

    values.push({
      id: getID(),
      from: AmauiDate.milliseconds,
      to: add(1, 'hour', AmauiDate.amauiDate).milliseconds,
      auto_approve: true,
      skip: false
    });

    refs.form.current.onChange('times', values, `dates.values`);
  }, []);

  const onRemoveDate = React.useCallback((index: number) => {
    const values = [...(refs.form.current.values.times.value?.dates?.values || [])];

    if (index > -1) values.splice(index, 1);

    refs.form.current.onChange('times', values, `dates.values`);
  }, []);

  const onAddDay = React.useCallback((path: string) => {
    const values = [...(getObjectValue(refs.form.current.values.times.value, `weekly.days.${path}.values`) || [])];

    values.push({
      id: getID(),
      from: AmauiDate.milliseconds,
      to: add(1, 'hour', AmauiDate.amauiDate).milliseconds,
      auto_approve: true,
      skip: false
    });

    refs.form.current.onChange('times', values, `weekly.days.${path}.values`);
  }, []);

  const onRemoveDay = React.useCallback((index: number, path: string) => {
    const values = [...(getObjectValue(refs.form.current.values.times.value, `weekly.days.${path}.values`) || [])];

    if (index > -1) values.splice(index, 1);

    refs.form.current.onChange('times', values, `weekly.days.${path}.values`);
  }, []);

  const onCopyTimes = React.useCallback((path: string) => {
    const values = [...(getObjectValue(refs.form.current.values.times.value, `weekly.days.${path}.values`) || [])];

    const times = copy(refs.form.current.values.times.value);

    Object.values(times.weekly.days).forEach((day: any) => {
      day.values = values.map(item => ({
        ...item,

        id: getID()
      }));
    });

    refs.form.current.onChange('times', times);
  }, []);

  const formAccordion: any = {
    AccordionProps: {
      mainPaddingVertical: 'end',

      WrapperHeaderProps: {
        gap: 1
      },

      ExpandProps: {
        removeOnExited: false
      }
    },
    AccordionMainProps: {
      gap: 1
    },
    accordion: true,
    wrapper: true,
    size: 'small'
  };

  const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

  const getTimeValue = (item: any, index: number, property: string, path: string) => {
    const pathMain = `${property}.${path}.${index}`;

    const weekly = property === 'weekly';

    let ElementDate = weekly ? TimeRangePicker : DateTimeRangePicker;

    return (
      <Form
        key={index + pathMain}

        name={(
          <Type>
            {item.from ? `${format(new AmauiDate(item.from), weekly ? formats.time : formats.entire)} — ${format(new AmauiDate(item.to), weekly ? formats.time : formats.entire)}` : 'No time selected'}
          </Type>
        )}

        size='small'

        end={(
          <Tooltip
            name='Remove date'
          >
            <IconButton
              onClick={(event: MouseEvent) => {
                event.stopPropagation();

                weekly ? onRemoveDay(index, path.replace('days.', '').replace('.values', '')) : onRemoveDate(index);
              }}

              size='small'
            >
              <IconMaterialRemoveRounded />
            </IconButton>
          </Tooltip>
        )}

        {...formAccordion}

        AccordionMainProps={{
          gap: 1.5
        }}
      >
        <ElementDate
          name='Time'

          color='themed'

          size='small'

          value={[item.from, item.to].filter(Boolean).map(item => new AmauiDate(item)) as any}

          onChange={(valueNew: any) => {
            const from = valueNew?.[0]?.milliseconds;
            const to = valueNew?.[1]?.milliseconds;

            form.onChange([
              ['times', from, `${pathMain}.from`],
              ['times', to, `${pathMain}.to`]
            ]);
          }}

          TooltipProps={{
            switch: true
          }}
        />

        {!getObjectValue(form.values.times.value, `${pathMain}.skip`) && <>
          <FormRow
            name='Reservation options'

            description='Reservation options available for this time'
          >
            <AutoCompleteObjects
              name='Reservation options'

              value={getObjectValue(form.values.times.value, `${pathMain}.reservation_options`)}

              onChange={(valueNew: any) => form.onChange('times', copy(valueNew), `${pathMain}.reservation_options`)}

              service={ReservationOptionService}

              multiple

              chip
            />

            {(getObjectValue(form.values.times.value, `${pathMain}.reservation_options`) || []).map((reservationOption: any, indexReservationOption: number) => (
              <FormRow
                key={reservationOption.id || indexReservationOption}

                name={reservationOption.name}
              >
                <NumericTextField
                  name='Total'

                  value={reservationOption.total}

                  onChange={(valueNew: any) => form.onChange('times', valueNew, `${pathMain}.reservation_options.${indexReservationOption}.total`)}

                  min={0}

                  max={1e7}
                />
              </FormRow>
            ))}
          </FormRow>

          <Label
            checkedDefault={getObjectValue(form.values.times.value, `${pathMain}.auto_approve`)}

            onChange={(valueNew: any) => {
              form.onChange('times', valueNew, `${pathMain}.auto_approve`);
            }}
          >
            <Switch />

            Auto approve
          </Label>
        </>}

        {!weekly && (
          <Label
            checkedDefault={getObjectValue(form.values.times.value, `${pathMain}.skip`)}

            onChange={(valueNew: any) => {
              form.onChange('times', valueNew, `${pathMain}.skip`);
            }}
          >
            <Switch />

            Skip
          </Label>
        )}
      </Form>
    );
  };

  const modal: any = {
    'Available': <>
      <Line
        gap={2}

        fullWidth

        className={classes.page}
      >
        <FormRow
          gap={2}

          fullWidth
        >
          <FormRow
            fullWidth
          >
            <TextField
              name='Name'

              valueDefault={textToInnerHTML(form.values['name'].value)}

              onChange={(valueNew: any) => form.onChange('name', valueNew, undefined, { rerenderOnUpdate: false })}
            />

            <SmartTextField
              placeholder='Description'

              valueDefault={textToInnerHTML(form.values['description'].value || '')}

              onChange={(valueNew: any) => form.onChange('description', valueNew, undefined, { rerenderOnUpdate: false })}

              minRows={4}

              multiline

              edit
            />
          </FormRow>

          <FormRow
            name='Version'

            description={(
              <Type
                version='b3'
              >
                Select <b>Start calendar</b> if you want start screen to show first, and then user can choose calendar. <br />
                Select <b>Start</b> if you only want the start screen to show and <b>Calendar</b> if you only want the calendar to show.
              </Type>
            )}
          >
            <Select
              name='Version'

              value={form.values.version.value || ''}

              onChange={(valueNew: any) => form.onChange('version', valueNew)}

              error={form.values['version'].error}

              helperText={form.values['version'].error}
            >
              {['start-calendar', 'start', 'calendar'].map((item: any, index) => (
                <ListItem
                  key={index}

                  primary={cleanValue(item, { capitalize: true })}

                  value={item}

                  button
                />
              ))}
            </Select>
          </FormRow>
        </FormRow>
      </Line>
    </>,

    'Week': <>
      <Form
        gap={1.5}

        name='Weekly'

        description='Weekly repeating pattern of time availability'

        size='small'

        start={(
          <Label
            checkedDefault={form.values.times.value?.weekly?.active}

            onChange={(valueNew: any) => form.onChange('times', valueNew, `weekly.active`)}

            onClick={onStopPropagation}
          >
            <Switch />
          </Label>
        )}

        className={classNames([
          classes.page,
          classes.times
        ])}

        wrapper
      >
        <Line
          gap={1}

          fullWidth
        >
          {days.map((day: string, index: number) => {
            const itemDay = form.values.times.value?.weekly?.days?.[index + 1];

            return (
              <Form
                key={day}

                name={`${capitalize(day)}${itemDay?.values?.length ? ` (${itemDay.values.length})` : ''}`}

                size='small'

                start={(
                  <Label
                    checkedDefault={itemDay?.active}

                    onChange={(valueNew: any) => form.onChange('times', valueNew, `weekly.days.${index + 1}.active`)}

                    onClick={onStopPropagation}
                  >
                    <Switch />
                  </Label>
                )}

                end={<>
                  <Tooltip
                    name='Copy to other days'
                  >
                    <IconButton
                      onClick={(event: MouseEvent) => {
                        event.stopPropagation();

                        onCopyTimes(`${index + 1}`)
                      }}
                    >
                      <IconMaterialContentCopyRounded />
                    </IconButton>
                  </Tooltip>

                  <Tooltip
                    name='Add time'
                  >
                    <IconButton
                      onClick={(event: MouseEvent) => {
                        event.stopPropagation();

                        onAddDay(`${index + 1}`)
                      }}
                    >
                      <IconMaterialAddRounded />
                    </IconButton>
                  </Tooltip>
                </>}

                {...formAccordion}

                AccordionMainProps={{
                  gap: 0,
                  style: {
                    paddingInlineStart: 16
                  }
                }}
              >
                {(itemDay?.values || []).map((item: any, index_: number) => getTimeValue(item, index_, 'weekly', `days.${index + 1}.values`))}
              </Form>
            );
          })}
        </Line>
      </Form>
    </>,

    'Dates': <>
      <Form
        name={`Dates${form.values.times.value?.dates?.values?.length ? ` (${form.values.times.value.dates.values.length})` : ''}`}

        description='Specific time of availability or when to skip it'

        size='small'

        start={(
          <Label
            checkedDefault={form.values.times.value?.dates?.active}

            onChange={(valueNew: any) => form.onChange('times', valueNew, `dates.active`)}

            onClick={onStopPropagation}
          >
            <Switch />
          </Label>
        )}

        end={(
          <Tooltip
            name='Add date'
          >
            <IconButton
              onClick={(event: MouseEvent) => {
                event.stopPropagation();

                onAddDate();
              }}
            >
              <IconMaterialAddRounded />
            </IconButton>
          </Tooltip>
        )}

        className={classNames([
          classes.page,
          classes.times
        ])}

        wrapper
      >
        <Line
          gap={0}

          fullWidth
        >
          {(form.values.times.value?.dates?.values || []).map((item: any, index: number) => getTimeValue(item, index, 'dates', `values`))}
        </Line>
      </Form>
    </>,

    'Start': <>
      <Form
        name='Start'

        size='small'

        start={(
          <Label
            checkedDefault={form.values.start.value?.active}

            onChange={(valueNew: any) => form.onChange('start', valueNew, `active`)}

            onClick={onStopPropagation}
          >
            <Switch />
          </Label>
        )}

        description='View user sees before he sees the calendar. You can use it for additional information and call to actions.'

        className={classes.page}

        wrapper
      >
        <FormRow
          fullWidth

          style={{
            marginBottom: 24
          }}
        >
          <SmartTextField
            placeholder='Name'

            valueDefault={textToInnerHTML(form.values.start.value?.name)}

            onChange={(valueNew: any) => form.onChange('start', valueNew, `name`, formOnChangeOptions)}

            additional={{
              version: 't2'
            }}
          />

          <SmartTextField
            placeholder='Summary'

            valueDefault={textToInnerHTML(form.values.start.value?.short_description)}

            onChange={(valueNew: any) => form.onChange('start', valueNew, `short_description`, formOnChangeOptions)}

            minRows={4}

            multiline

            edit
          />

          <SmartTextField
            placeholder='Description'

            valueDefault={textToInnerHTML(form.values.start.value?.description)}

            onChange={(valueNew: any) => form.onChange('start', valueNew, `description`, formOnChangeOptions)}

            minRows={7}

            multiline

            edit
          />
        </FormRow>

        <TextField
          name='Email'

          valueDefault={form.values.start.value?.email}

          onChange={(valueNew: any) => form.onChange('start', valueNew, `email`, formOnChangeOptions)}

          type='email'
        />

        <TextField
          name='Mobile'

          valueDefault={form.values.start.value?.mobile}

          onChange={(valueNew: any) => form.onChange('start', valueNew, `mobile`, formOnChangeOptions)}

          type='tel'
        />

        <FormRow
          name='Actions'

          description='Call to actions shown in the start view'
        >
          {['call', 'whatsapp', 'viber', 'email'].map((item: string, index: number) => (
            <Label
              key={item}

              checkedDefault={form.values.start.value?.actions?.[item]}

              onChange={(valueNew: any) => form.onChange('start', valueNew, `actions.${item}`)}
            >
              <Switch />

              {capitalize(item)}
            </Label>
          ))}
        </FormRow>

        <Line
          gap={1}
        >
          {form.values.start.value?.image?.id && (
            <Medias
              values={[form.values.start.value?.image].map((item: any) => ({
                value: item
              }))}
            />
          )}

          <Button
            color='inherit'

            version='outlined'

            onClick={() => onMediaValue(`image`, 'image')}

            size='small'
          >
            Choose image
          </Button>
        </Line>

        <Line
          gap={1}
        >
          {form.values.start.value?.audio?.id && (
            <Medias
              values={[form.values.start.value?.audio].map((item: any) => ({
                value: item
              }))}
            />
          )}

          <Line
            gap={1}

            direction='row'

            align='center'
          >
            <Button
              color='inherit'

              version='outlined'

              onClick={() => onMediaValue(`audio`, 'audio')}

              size='small'
            >
              Choose audio
            </Button>

            <AudioRecorder
              onConfirm={(valueNew: any) => onAudioRecorderConfirmValue(valueNew, `audio`)}

              disabled={loading}
            />
          </Line>
        </Line>

        <Line
          gap={1}
        >
          {form.values.start.value?.video?.id && (
            <Medias
              values={[form.values.start.value?.video].map((item: any) => ({
                value: item
              }))}
            />
          )}

          <Button
            color='inherit'

            version='outlined'

            onClick={() => onMediaValue(`video`, 'video')}

            size='small'
          >
            Choose video
          </Button>
        </Line>
      </Form>
    </>,

    'Options': <>
      <Line
        gap={2}

        fullWidth

        className={classes.page}
      >
        <OptionsWebsites
          description='Websites this available can be visible at'

          value={form.values.websites.value}

          onChange={(valueNew: any[]) => form.onChange('websites', valueNew)}
        />
      </Line>
    </>
  };

  return (
    <ModalForm
      {...props}

      object={object}

      add

      tabDefault='Available'

      tab={tab}

      tabs={['Available', 'Week', 'Dates', 'Start', 'Options']}

      onChangeTab={onChangeTab}

      onSubmit={onSubmit}

      onNext={onNext}

      onClose={onClose}

      loading={loading}
    >
      {modal[tab]}
    </ModalForm>
  );
});

export default Element;
