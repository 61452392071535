import React from 'react';

import { FormTag, Page } from 'ui';
import { TagService } from 'services';

const Tags = React.forwardRef(() => {

  const properties = React.useMemo(() => ({
    name: { name: 'Name' },
    description: { name: 'Description' },
    color: { name: 'Color' },
    apps: { name: 'Apps' }
  }), []);

  return (
    <Page
      name='Tags'

      service={TagService}

      columns={[
        properties.name,
        properties.color,
        properties.apps
      ]}

      search={['apps', 'color']}

      properties={properties}

      app='amaui'

      collection='tags'

      route='/tags'

      Form={FormTag}

      noTags
    />
  );
});

export default Tags;
