import React from 'react';

import { capitalize, imageToPalette, innerHTMLToText, rgbToHex, textToInnerHTML } from '@amaui/utils';
import { AutoCompleteCurrency, ColorTextField, Form, FormRow, Line, SmartTextField, Type, useForm, useSnackbars } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';

import { Button, LocationForm, PropertiesContact, SelectMedia, TextField, useSubscription } from 'ui';
import { AuthService, OrganizationService } from 'services';
import { ISignedIn, getErrorMessage, getMediaUrl, mediaToObject } from 'other';

const useStyle = style(theme => ({
  root: {

  },

  ...theme.classes(theme)
}), { name: 'amaui-app-route-OrganizationSettingsInfo' });

const OrganizationSettingsInfo = React.forwardRef((props: any, ref: any) => {
  const {
    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const snackbars = useSnackbars();
  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const [loading, setLoading] = React.useState<any>(false);

  const object = signedIn?.organization;

  const form = useForm({
    values: {
      'name': {
        name: 'Organization name',
        value: object?.name,
        is: 'string',
        min: 2,
        max: 1400,
        messages: {
          min: 'Organization name must be min 2 characters',
          max: 'Organization name can be max 1400 characters'
        },
        required: true,
      },
      'description': {
        name: 'Description',
        value: object?.description,
        max: 4400,
        messages: {
          min: 'Description has to be min 1 characters',
          max: 'Description can be max 4400 characters'
        }
      },
      'properties': {
        name: 'Properties',
        is: 'object',
        value: object?.properties || {}
      },
      'location': {
        name: 'Location',
        is: 'object',
        value: object?.location
      },
      'coordinates': {
        name: 'Coordinates',
        is: 'string',
        value: object?.location?.coordinates?.join(', ')
      },

      'settings.logos.logo_light': {
        name: 'Logo light',
        value: object?.settings?.logos?.logo_light,
        is: 'object'
      },
      'settings.logos.logo_dark': {
        name: 'Logo dark',
        value: object?.settings?.logos?.logo_dark,
        is: 'object'
      },
      'settings.logos.favicon_light': {
        name: 'Favicon light',
        value: object?.settings?.logos?.favicon_light,
        is: 'object'
      },
      'settings.logos.favicon_dark': {
        name: 'Favicon dark',
        value: object?.settings?.logos?.favicon_dark,
        is: 'object'
      },

      'settings.theme.palette.image': {
        name: 'Theme image',
        value: object?.settings?.theme?.palette?.image,
        is: 'object'
      },

      'settings.theme.palette.color.primary.main': {
        name: 'Theme color primary',
        value: object?.settings?.theme?.palette?.color?.primary?.main,
        is: 'string'
      },
      'settings.theme.palette.color.secondary.main': {
        name: 'Theme color secondary',
        value: object?.settings?.theme?.palette?.color?.secondary?.main,
        is: 'string'
      },
      'settings.theme.palette.color.tertiary.main': {
        name: 'Theme color tertiary',
        value: object?.settings?.theme?.palette?.color?.tertiary?.main,
        is: 'string'
      },
      'settings.theme.palette.color.quaternary.main': {
        name: 'Theme color quaternary',
        value: object?.settings?.theme?.palette?.color?.quaternary?.main,
        is: 'string'
      },

      'settings.currency': {
        name: 'Settings currency',
        value: object?.settings?.currency || 'eur',
        is: 'string'
      }
    }
  });

  const refs = {
    form: React.useRef(form)
  };

  refs.form.current = form;

  const onNext = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();

    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading('info');

    const body = {
      ...refs.form.current.value
    };

    if (body.settings) {
      if (body.settings.logos?.logo_light) body.settings.logos.logo_light = mediaToObject(body.settings.logos?.logo_light);

      if (body.settings.logos?.logo_dark) body.settings.logos.logo_dark = mediaToObject(body.settings.logos?.logo_dark);

      if (body.settings.logos?.favicon_light) body.settings.logos.favicon_light = mediaToObject(body.settings.logos?.favicon_light);

      if (body.settings.logos?.favicon_dark) body.settings.logos.favicon_dark = mediaToObject(body.settings.logos?.favicon_dark);
    }

    if (body.coordinates) {
      body.location.coordinates = body.coordinates.split(',').filter(Boolean).map((item: any) => Number(item.trim()));
    }

    delete body.coordinates;

    const result = await OrganizationService.update(object?.id, body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: result.response?.meta?.message || 'Organization updated'
      });

      // refresh organization
      // in the entire app
      await AuthService.me();
    }

    setLoading(false);
  }, [object, form]);

  const onThemeImageConfirm = React.useCallback(async (values: any) => {
    const value = values;

    const palette = await imageToPalette(getMediaUrl(value, 480), { amount: 4, size: 140, allowCrossOrigin: true });

    form.onChange([
      ['settings.theme.palette.image', value],
      ['settings.theme.palette.color.primary.main', rgbToHex(palette[0])],
      ['settings.theme.palette.color.secondary.main', rgbToHex(palette[1])],
      ['settings.theme.palette.color.tertiary.main', rgbToHex(palette[2])],
      ['settings.theme.palette.color.quaternary.main', rgbToHex(palette[3])]
    ]);
  }, [form]);

  const colorOptions = [
    { name: 'primary' },
    { name: 'secondary' },
    { name: 'tertiary' },
    { name: 'quaternary' }
  ];

  const logosSelectMediaProps: any = {
    style: {
      // backgroundSize: 'auto 77%',
      // backgroundColor: theme.palette.background.default.primary
    }
  };

  const formProps: any = {
    size: 'small'
  };

  return (
    <Line
      ref={ref}

      gap={4}

      fullWidth

      className={classNames([
        className,
        classes.wrapper
      ])}

      {...other}
    >
      <Form
        onSubmit={onNext}

        name='Info'

        description='Basic organization information'

        footer={(
          <Button
            loading={loading === 'info'}

            type='submit'

            size='small'
          >
            Update
          </Button>
        )}
      >
        <TextField
          name='Name'

          valueDefault={object?.name || ''}

          error={!!form.values['name'].error}

          helperText={form.values['name'].error}

          onChange={(valueNew: string) => form.onChange('name', valueNew, undefined, { rerenderOnUpdate: false })}
        />

        <SmartTextField
          name='Description'

          valueDefault={textToInnerHTML(form.values['description'].value || '')}

          error={form.values['description'].error}

          helperText={form.values['description'].error}

          onChange={(valueNew: any) => form.onChange('description', innerHTMLToText(valueNew), undefined, { rerenderOnUpdate: false })}

          minRows={4}

          multiline

          edit
        />

        <AutoCompleteCurrency
          value={form.values['settings.currency'].value}

          onChange={(valueNew: any) => form.onChange('settings.currency', valueNew?.value)}

          error={!!form.values['settings.currency'].error}

          helperText={form.values['settings.currency'].error}
        />

        <Form
          name='Logos'

          description='Images used for the logo in light, dark mode, etc.'

          accordion

          wrapper

          {...formProps}
        >
          <FormRow
            name={(
              <Type
                version='l3'
              >
                Logo light
              </Type>
            )}

            description='Used with the light theme'
          >
            <SelectMedia
              value={form.values['settings.logos.logo_light'].value}

              mediaSelected={form.values['settings.logos.logo_light'].value}

              onChange={(valueNew: any) => form.onChange('settings.logos.logo_light', valueNew)}

              {...logosSelectMediaProps}
            />
          </FormRow>

          <FormRow
            name={(
              <Type
                version='l3'
              >
                Logo dark
              </Type>
            )}

            description='Used with the dark theme'
          >
            <SelectMedia
              value={form.values['settings.logos.logo_dark'].value}

              mediaSelected={form.values['settings.logos.logo_dark'].value}

              onChange={(valueNew: any) => form.onChange('settings.logos.logo_dark', valueNew)}

              {...logosSelectMediaProps}
            />
          </FormRow>

          <FormRow
            name={(
              <Type
                version='l3'
              >
                Favicon light
              </Type>
            )}

            description='Used in the browser tab with the light theme'
          >
            <SelectMedia
              value={form.values['settings.logos.favicon_light'].value}

              mediaSelected={form.values['settings.logos.favicon_light'].value}

              onChange={(valueNew: any) => form.onChange('settings.logos.favicon_light', valueNew)}

              {...logosSelectMediaProps}
            />
          </FormRow>

          <FormRow
            name={(
              <Type
                version='l3'
              >
                Favicon dark
              </Type>
            )}

            description='Used in the browser tab with the dark theme'
          >
            <SelectMedia
              value={form.values['settings.logos.favicon_dark'].value}

              mediaSelected={form.values['settings.logos.favicon_dark'].value}

              onChange={(valueNew: any) => form.onChange('settings.logos.favicon_dark', valueNew)}

              {...logosSelectMediaProps}
            />
          </FormRow>
        </Form>

        <Form
          name='Theme'

          accordion

          wrapper

          {...formProps}
        >
          <FormRow
            name='Palette'
          >
            <FormRow
              description='Make the palette from an image'
            >
              <SelectMedia
                value={form.values[`settings.theme.palette.image`].value}

                onChange={onThemeImageConfirm}
              />
            </FormRow>

            {colorOptions.map((item: any, index: number) => (
              <ColorTextField
                key={index}

                name={`${capitalize(item.name)} color`}

                value={form.values[`settings.theme.palette.color.${item.name}.main`].value || ''}

                onChange={(valueNew: any) => form.onChange(`settings.theme.palette.color.${item.name}.main`, valueNew)}
              />
            ))}
          </FormRow>
        </Form>

        <LocationForm
          form={form}
        />

        <PropertiesContact
          form={form}

          versions={['tels', 'emails', 'urls', 'addresses', 'custom']}
        />
      </Form>
    </Line>
  );
});

export default OrganizationSettingsInfo;
