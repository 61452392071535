import React from 'react';

import { IResume } from '@amaui/api-utils';

import IconMaterialDownloadRounded from '@amaui/icons-material-rounded-react/IconMaterialDownload';

import { FormResume, Page } from 'ui';
import { ResumeService } from 'services';

const Resumes = React.forwardRef(() => {

  const onDownload = React.useCallback(async (value: IResume) => {
    const response = await ResumeService.read(value.id);

    const url = URL.createObjectURL(new Blob([response.response], { type: 'application/json' }));

    const a = window.document.createElement('a');

    a.download = `${value.name}.pdf`;

    a.href = url;

    window.document.body.appendChild(a);

    // Trigger data download
    a.click();

    // Clean up
    a.remove();
  }, []);

  const properties = React.useMemo(() => ({
    name: { name: 'Name' },
    short_description: { name: 'Summary' },
    description: { name: 'Description' },
    active: { name: 'Active' },
    archived: { name: 'Archived' },
    read_time: { name: 'Read time' }
  }), []);

  return (
    <Page
      name='Resumes'

      service={ResumeService}

      columns={[
        properties.name,
        properties.active,
        properties.archived
      ]}

      search={['active', 'archived']}

      options={['active', 'archived']}

      properties={properties}

      getItemActions={(item: any) => {
        const IconProps: any = {
          color: 'inherit',
          size: 'small'
        };

        const values = [
          { primary: 'Download', onClick: () => onDownload(item), start: <IconMaterialDownloadRounded  {...IconProps} /> },
        ];

        return values;
      }}

      app='resume'

      collection='resumes'

      route='/resumes'

      Form={FormResume}

      updateEntire
    />
  );
});

export default Resumes;
