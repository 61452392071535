import React from 'react';

import { Label, Switch } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';

const useStyle = style(theme => ({
  root: {
    flex: '0 0 auto'
  }
}), { name: 'amaui-app-SwitchInput' });

const SwitchInput = React.forwardRef((props: any, ref: any) => {
  const {
    name,

    children,

    className,

    ...other
  } = props;

  const { classes } = useStyle();

  return (
    <Label
      ref={ref}

      position='right'

      {...other}

      className={classNames([
        className,
        classes.root
      ])}
    >
      <Switch />

      {name || children || 'Tonal'}
    </Label>
  );
});

export default SwitchInput;
