import React from 'react';

import { AutoCompleteGooglePlaces, Button, Form, FormRow } from '@amaui/ui-react';

import { TextField } from 'ui';

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    form,

    Component,

    ...other
  } = props;

  const refs = {
    form: React.useRef(form),
    geocoder: React.useRef<any>()
  };

  refs.form.current = form;

  const init = React.useCallback(async () => {
    if (!(window as any)?.google) return;

    const google = (window as any).google;

    const { Geocoder } = await google.maps.importLibrary('geocoding');

    refs.geocoder.current = new Geocoder();
  }, []);

  React.useEffect(() => {
    // init 
    init();
  }, []);

  const onChangeAddress = React.useCallback(async (valueNew: any) => {
    const address = valueNew?.value;

    const result = await refs.geocoder.current?.geocode({ address });

    const locationGoogle = result?.results?.[0]?.geometry?.location;

    const coordinates = [locationGoogle?.lat(), locationGoogle?.lng()].filter(Boolean);

    const location = {
      name: address,
      type: 'Point',
      coordinates
    };

    form.onChange([
      ['location', location],
      ['coordinates', coordinates?.join(', ')]
    ]);
  }, []);

  const onOpenMap = React.useCallback(() => {
    const coordinates = refs.form.current.values.coordinates.value;

    window.open(`https://maps.google.com/?q=${coordinates.replace(' ', '')}`);
  }, []);

  const Root = Component || Form;

  return (
    <Root
      gap={1}

      name='Location'

      description='Location information'

      size='small'

      wrapper

      accordion

      fullWidth

      {...other}
    >
      <FormRow
        name='Address'
      >
        <AutoCompleteGooglePlaces
          name='Address'

          valueInputDefault={form.values.location.value?.name}

          onChange={onChangeAddress}

          error={form.values['location'].error}

          helperText={form.values['location'].error}
        />
      </FormRow>

      <FormRow
        name='Coordinates'

        description='If you want to add precise location. We will try to estimate ourselves based on the address name.'

        end={(
          <Button
            onClick={onOpenMap}

            color='primary'

            version='filled'

            size='small'

            disabled={!form.values.coordinates.value}
          >
            Open map
          </Button>
        )}
      >
        <TextField
          name='Coordinates'

          value={form.values['coordinates'].value}

          error={form.values['coordinates'].error}

          helperText={form.values['coordinates'].error || 'Latitude, Longitude separated with comma ie. 24, 44'}

          onChange={(valueNew: any) => form.onChange('coordinates', valueNew, undefined, { rerenderOnUpdate: false })}
        />
      </FormRow>
    </Root>
  );
});

export default Element;
