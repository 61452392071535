import { ContactGroup } from '@amaui/api-utils';
import AmauiSubscription from '@amaui/subscription';

import { BaseService } from './base';
import { IQuerySubscription, ISelectedSubscription } from 'other';

class ContactGroupService extends BaseService<ContactGroup> {
  public queryItems = new AmauiSubscription<IQuerySubscription>();
  public selectedItems = new AmauiSubscription<ISelectedSubscription>();

  public get paginationLimit() { return 24; }

  public constructor() {
    super('contactGroups');

    this.queryObjectsInit(this.queryItems);

    this.selectedObjectsInit(this.selectedItems);
  }

}

export default new ContactGroupService();
