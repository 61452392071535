import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useStripe } from '@stripe/react-stripe-js';

import { capitalize, cleanValue, numberWithCommas, to, wait } from '@amaui/utils';
import { Button, Divider, Form, FormRow, IconButton, Label, Line, LinearProgress, List, ListItem, Modal, NumericTextField, Select, Slide, Switch, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, Tooltip, Type, useForm, useSnackbars } from '@amaui/ui-react';
import { cammelCaseToKebabCase, classNames, style } from '@amaui/style-react';
import { Plan } from '@amaui/api-utils';

import IconMaterialAutoAwesomeRounded from '@amaui/icons-material-rounded-react/IconMaterialAutoAwesome';
import IconMaterialCloseRounded from '@amaui/icons-material-rounded-react/IconMaterialClose';

import { AuthService, OrganizationService } from 'services';
import { getErrorMessage } from 'other';

const useStyle = style(theme => ({
  root: {
    '& .amaui-Table-root': {
      '& > *': {
        whiteSpace: 'nowrap !important'
      }
    }
  },

  update: {
    padding: '0 0 140px',

    '& .amaui-Divider-root': {
      marginBlock: 24
    }
  },

  mostPopular: {
    padding: '1px 8px',
    borderRadius: 140,
    color: '#fff',
    backgroundImage: `linear-gradient(${theme.palette.light ? '130deg' : '330deg'}, ${theme.methods.palette.color.colorToRgb(theme.palette.color.primary.main, 1)} 0%, ${theme.methods.palette.color.colorToRgb(theme.palette.color.secondary.main, 1)} 40%, ${theme.methods.palette.color.colorToRgb(theme.palette.color.tertiary.main, 1)} 100%)`,
    cursor: 'default',
    userSelect: 'none'
  },

  asterix: {
    color: '#fff',
    background: theme.palette.color.success[50],
    padding: '0 2px'
  },

  actions: {
    marginTop: 16
  },

  ...theme.classes(theme)
}), { name: 'amaui-app-route-OrganizationSettingsSubscriptionsAmaui' });

const OrganizationSettingsSubscriptionsAmaui = React.forwardRef((props: any, ref: any) => {
  const {
    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const snackbars = useSnackbars();
  const navigate = useNavigate();

  const [response, setResponse] = React.useState<any>({});
  const [openModal, setOpenModal] = React.useState(false);
  const [plans, setPlans] = React.useState<Plan[]>();
  const [upcomingInvoice, setUpcomingInvoice] = React.useState<any>();
  const [paymentMethod, setPaymentMethod] = React.useState<any>();
  const [tab, setTab] = React.useState('update');
  const [loading, setLoading] = React.useState<any>();
  const [loaded, setLoaded] = React.useState(false);

  const {
    subscription,
    paymentMethods = []
  } = response;

  const stripe = useStripe();

  const form = useForm({
    values: {
      plan: {
        name: 'Plan'
      },

      user: {
        name: 'Users'
      },

      media: {
        name: 'Media'
      },

      website: {
        name: 'Websites'
      },

      recurring: {
        name: 'Recurring',
        is: 'string',
        in: ['month', 'year'],
        value: 'month'
      },

      cancel: {
        name: 'Cancel',
        is: 'boolean'
      }
    }
  });

  const refs = {
    initial: React.useRef<any>({}),
    form: React.useRef(form)
  };

  refs.form.current = form;

  const tabs = ['update', 'cancel'];

  const init = React.useCallback(async () => {
    setLoading('initial');

    const result = await OrganizationService.getSubscription();

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      const response = result.response.response;

      // plans 
      setPlans(response.plans);

      // form
      const planPrice = response.subscription?.items?.data?.find((item: any) => {
        return item.metadata.lookup_key.includes('--plan--');
      });

      // payment method
      const paymentMethod_ = response.paymentMethods?.find((item: any) => item.default) || response.paymentMethods[0];

      if (paymentMethod_) setPaymentMethod(paymentMethod_);

      // plan
      refs.initial.current.plan = response.plan;

      refs.initial.current.recurring = planPrice?.price?.recurring?.interval;

      const items = response.subscription.items.data || [];

      const itemsSubscription: any = {};

      items.forEach((item: any) => {
        if (item.price.lookup_key.includes('user')) itemsSubscription.user = item;
        else if (item.price.lookup_key.includes('media')) itemsSubscription.media = item;
        else if (item.price.lookup_key.includes('website')) itemsSubscription.website = item;
      });

      form.onChange([
        ['plan', response.plan],

        // user 
        ['user', itemsSubscription.user?.quantity || 0],

        // media 
        ['media', itemsSubscription.media?.quantity || 0],

        // website 
        ['website', itemsSubscription.website?.quantity || 0],

        ['cancel', response.organizationPlan.plan?.cancel_at_period_end]
      ]);

      setResponse(response);
    }

    setLoading(false);

    setLoaded(true);
  }, [form]);

  React.useEffect(() => {
    // init
    init();
  }, []);

  const updated = React.useMemo(() => {
    if (!response?.subscription) return false;

    const items = response.subscription.items.data || [];

    const itemsSubscription: any = {};

    items.forEach((item: any) => {
      if (item.price.lookup_key.includes('user')) itemsSubscription.user = item;
      else if (item.price.lookup_key.includes('media')) itemsSubscription.media = item;
      else if (item.price.lookup_key.includes('website')) itemsSubscription.website = item;
    });

    const result = (
      response.plan.id === form.values.plan.value.id &&
      itemsSubscription.user?.quantity === form.values.user.value &&
      itemsSubscription.media?.quantity === form.values.media.value &&
      itemsSubscription.website?.quantity === form.values.website.value
    );

    return !result;
  }, [form, response]);

  const onUpdatePreview = async () => {
    setLoading('update');

    try {
      // Preview the invoice
      const invoiceResponse = await OrganizationService.updateSubscription({
        plan: {
          id: refs.form.current.values.plan.value?.id
        },

        resources: {
          user: refs.form.current.values.user.value || 0,
          media: refs.form.current.values.media.value || 0,
          website: refs.form.current.values.website.value || 0
        },

        upcoming_invoice: true
      });

      if (invoiceResponse.status >= 400) {
        snackbars.add({
          color: 'error',
          primary: getErrorMessage(invoiceResponse)
        });
      }
      else {
        setUpcomingInvoice(invoiceResponse.response.response);

        setOpenModal(true);
      }
    }
    catch (error) {
    }

    setLoading(false);
  };

  const onUpdate = async () => {
    setLoading('pay');

    const subscriptionResponse = await OrganizationService.updateSubscription({
      plan: {
        id: refs.form.current.values.plan.value?.id
      },

      resources: {
        user: refs.form.current.values.user.value || 0,
        media: refs.form.current.values.media.value || 0,
        website: refs.form.current.values.website.value || 0
      }
    });

    if (subscriptionResponse.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(subscriptionResponse)
      });
    }
    else {
      // Payment intent
      const subscriptionUpdateRequest = subscriptionResponse.response.response;

      const payment_intent = subscriptionUpdateRequest.latest_invoice.payment_intent;

      if (payment_intent && payment_intent.status !== 'succeeded') {
        const payment_method = paymentMethod;

        const result = await stripe!.confirmCardPayment(payment_intent?.client_secret, {
          payment_method: payment_method.id,
        });

        if (result.error) {
          snackbars.add({
            color: 'error',
            primary: result.error.message
          });
        }
        else {
          await wait(4400);

          // init 
          init();

          snackbars.add({
            primary: 'Subscription updated'
          });

          onCloseModal();
        }
      }
      else {
        await wait(4400);

        // init 
        await init();

        await AuthService.me();

        snackbars.add({
          primary: 'Subscription updated'
        });

        onCloseModal();
      }
    }

    setLoading(false);
  };

  const onUpdateCancel = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();

    setLoading('update-cancel');

    const result = await OrganizationService.updateSubscription({
      plan: {
        id: refs.form.current.values.plan.value?.id
      },

      cancel_at_period_end: !!refs.form.current.values.cancel.value
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: 'Subscription updated'
      });
    }

    setLoading(false);
  }, [form]);

  const onCloseModal = React.useCallback(() => {
    setOpenModal(false);
  }, []);

  const onTabsChange = React.useCallback((value: any) => setTab(value), []);

  const plans_ = React.useMemo(() => {
    const value: Record<string, Plan> = {};

    plans?.forEach(item => value[item.name] = item);

    return value;
  }, [plans]);

  const itemsPlans = ['Free', 'Premium', 'Pro'];

  const itemsObjects = ['media', 'project', 'user', 'integration', 'organization', 'version', 'filter', 'list', 'tag', 'userGroup', 'task', 'note', 'post', 'resume', 'skill', 'portfolio', 'urlShortener', 'form', 'supportPage', 'supportArticle', 'chat', 'message', 'website', 'page', 'template', 'available', 'reservationOption', 'reservation', 'contact', 'contactGroup', 'location', 'workDay'];

  const getItemName = (item_: string) => {
    let item = cleanValue(cammelCaseToKebabCase(item_), { capitalize: true });

    if (item.toLowerCase().includes('url')) item = `URL shortener`;

    if (item === 'Media') return `Media (base storage)`;

    return `${item}s`;
  };

  const plan = form.values.plan.value;

  const features: any = loaded && Object.keys(plan.provided.use).map(object => [object, plan.provided.use[object].total]).filter(item => itemsObjects.includes(item[0]));

  const toValue = (item: any, property_: string) => {
    const property = property_.toLowerCase();

    if (property?.toLowerCase() === 'media') return to(item, 'size-format');

    if (item === 'unlimited') return 'Unlimited';

    return numberWithCommas(item);
  };

  const onSelect = React.useCallback((item: any) => {
    refs.form.current.onChange('plan', item);
  }, []);

  const priceMap = React.useMemo(() => {
    const result: any = {};

    if (!plan) return result;

    plan.prices.forEach((price: any) => {
      const [, v] = price.lookup_key.split('--');

      result[v] = price;
    });

    return result;
  }, [plan]);

  const priceCurrent = React.useMemo(() => {
    if (!subscription) return 0;

    const value = subscription.items.data.reduce((result: number, item: any) => result += item.price.unit_amount * item.quantity, 0);

    return value / 100;
  }, [subscription]);

  const ListProps: any = {
    size: 'small',
    paddingVertical: 'none',
    noBackground: true
  };

  const modal: any = {
    'update': loaded && <>
      <Line
        flex

        fullWidth

        className={classes.update}
      >
        <Line
          gap={4}

          align='center'

          fullWidth
        >
          <Line
            gap={1}

            align='center'

            fullWidth
          >
            <Type
              version='h2'

              align='center'
            >
              Your subscription
            </Type>

            <Type
              version='b2'

              align='center'
            >
              Review, update your subscription
            </Type>
          </Line>

          <Line
            gap={1}

            flex

            fullWidth
          >
            <Type
              style={{
                marginBottom: 2
              }}
            >
              Current subscription: {priceCurrent} EUR/mo
            </Type>

            <Type
              version='b3'
            >
              <span className={classes.asterix}>*</span> You have access to all the platform features in every plan, the difference is mostly the amount of resources (websites, tasks, etc.) you can create. <br /> Update to a bigger plan to increase the limits. All of the prices are in EUR per month.
            </Type>

            <Table
              style={{
                maxHeight: 440
              }}
            >
              <TableHead
                sticky
              >
                <TableRow>
                  <TableCell>
                    <Type
                      version='b2'
                    >
                      Features
                    </Type>
                  </TableCell>

                  {itemsPlans.map((item: any, index: number) => {
                    const selected = plan.id === plans_[item].id;
                    const plan_ = plans_[item];
                    const mostPopular = plan_.name === 'Premium';

                    const pricePlan = plan_.prices.find(itemPrice => itemPrice.lookup_key.includes('--plan--'));

                    return (
                      <TableCell
                        key={index}

                        justify='center'
                      >
                        <Line
                          gap={1}

                          direction='column'

                          align='center'
                        >
                          <Line
                            gap={0.5}

                            direction='column'

                            align='center'
                          >
                            {mostPopular && (
                              <Type
                                version='l3'
                              >
                                Most popular
                              </Type>
                            )}

                            <Line
                              gap={0.5}

                              direction='row'

                              align='center'

                              className={classNames([
                                mostPopular && classes.mostPopular
                              ])}
                            >
                              {mostPopular && <IconMaterialAutoAwesomeRounded size='small' />}

                              <Type
                                version='t3'
                              >
                                {plans_[item].name}
                              </Type>
                            </Line>

                            <Type
                              version='b3'
                            >
                              {pricePlan?.unit_amount / 100} EUR/mo
                            </Type>
                          </Line>

                          <Button
                            size='small'

                            onClick={() => onSelect(plan_)}

                            disabled={selected}

                            LabelProps={{
                              style: {
                                whiteSpace: 'nowrap'
                              }
                            }}
                          >
                            {`Select${selected ? 'ed' : ''}`}
                          </Button>
                        </Line>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>

              <TableBody>
                {features.map((item: any, index: number) => (
                  <TableRow
                    key={index}

                    size='small'
                  >
                    <TableCell>
                      <Type
                        version='b3'
                      >
                        {getItemName(item[0])}
                      </Type>
                    </TableCell>

                    {itemsPlans.map((itemPlan: string, itemPlanIndex: number) => (
                      <TableCell
                        key={itemPlan + itemPlanIndex}

                        justify='center'
                      >
                        <Type
                          version='b3'
                        >
                          {toValue((plans_[itemPlan].provided as any).use[item[0]].total, item[0])}
                        </Type>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            <Divider
              color='inherit'
            />

            <Line
              fullWidth
            >
              <Type
                version='t2'
              >
                Additional resources
              </Type>

              <FormRow
                name='Media'

                description={`Additional media storage in gigabytes your organization may use. Price ${priceMap['media']?.unit_amount_decimal / 100} EUR per 1GB / month.`}
              >
                <NumericTextField
                  value={form.values.media.value || 0}

                  onChange={(valueNew: string) => form.onChange('media', valueNew)}

                  min={0}

                  max={140}

                  sufix='GBs'
                />
              </FormRow>
            </Line>

            <Line
              direction='row'

              justify='center'

              fullWidth

              className={classes.actions}
            >
              <Button
                version='filled'

                color='primary'

                size='small'

                onClick={onUpdatePreview}

                loading={loading === 'update'}

                disabled={!updated}
              >
                Preview update
              </Button>
            </Line>
          </Line>
        </Line>

      </Line>
    </>,

    'cancel': <>
      <Form
        onSubmit={onUpdateCancel}

        footer={(
          <Button
            version='filled'

            color='primary'

            size='small'

            type='submit'

            disabled={!loaded}

            loading={loading === 'update-cancel'}
          >
            Update
          </Button>
        )}
      >
        <FormRow
          name='Cancel'
          description='Your subscription will be canceled once it expires (if cancel is selected). You can use it until then.'
        >
          <Label
            checked={form.values.cancel.value !== undefined ? form.values.cancel.value : false}

            onChange={(valueNew: boolean) => form.onChange('cancel', valueNew)}
          >
            <Switch />
          </Label>
        </FormRow>
      </Form>
    </>,

    modal: loaded && <>
      <Modal
        open={openModal}

        minWidth='rg'

        onClose={onCloseModal}

        TransitionComponent={Slide}

        SurfaceProps={{
          color: 'primary',
          tonal: true,
          className: classes.surface
        }}
      >
        <Line
          direction='row'

          justify='space-between'

          align='center'
        >
          <Type
            version='t2'

            align='center'
          >
            Update preview
          </Type>

          <Tooltip
            name='Close'
          >
            <IconButton
              color='inherit'

              onClick={onCloseModal}
            >
              <IconMaterialCloseRounded />
            </IconButton>
          </Tooltip>
        </Line>

        <Line
          gap={1}

          align='unset'

          fullWidth
        >
          <List
            {...ListProps}

            style={{
              marginTop: 12
            }}
          >
            {plan.prices.map((item: any, index: number) => {
              const [, v] = item.lookup_key.split('--');

              let name = v;
              let quantity = v;

              if (quantity === 'plan') quantity = 1;
              else quantity = form.values[v]?.value || 0;

              if (name === 'media') name = `Media storage in GB`;
              else if (name === 'plan') name = `${plan.name} plan`;

              const price = ((item.unit_amount || item.unit_amount_decimal) / 100) * quantity;

              if (['user', 'website'].includes(name)) return null;

              return (
                <ListItem
                  key={index}

                  primary={(
                    <Type
                      version='l3'
                    >
                      {capitalize(name)}{v === 'plan' ? '' : ` x ${form.values[v]?.value || 0}`}
                    </Type>
                  )}

                  end={(
                    <Type
                      version='b3'
                    >
                      {!Number.isInteger(price) ? Number(price).toFixed(2) : price} EUR/mo
                    </Type>
                  )}

                  noPadding

                  noBackground
                />
              );
            })}
          </List>

          <Divider
            color='inherit'
          />

          <Line
            gap={4}

            fullWidth
          >
            <Line
              gap={1}

              fullWidth
            >
              <Line
                direction='row'

                align='center'

                justify='space-between'

                fullWidth
              >
                <Type
                  version='l3'
                >
                  Total
                </Type>

                <Type
                  version='b3'
                >
                  {upcomingInvoice?.total / 100} {capitalize(upcomingInvoice?.currency)}
                </Type>
              </Line>

              {(upcomingInvoice?.total !== upcomingInvoice?.amount_due) && (
                <Line
                  direction='row'

                  align='center'

                  justify='space-between'

                  fullWidth
                >
                  <Type
                    version='l3'
                  >
                    {upcomingInvoice?.total < 0 ? 'Future credits' : 'Starting balance'}
                  </Type>

                  <Type
                    version='b3'
                  >
                    {Math.abs((upcomingInvoice?.total - upcomingInvoice?.amount_due) / 100)} {capitalize(upcomingInvoice?.currency)}
                  </Type>
                </Line>
              )}

              <Line
                direction='row'

                align='center'

                justify='space-between'

                fullWidth
              >
                <Type
                  version='l3'
                >
                  Pay now
                </Type>

                <Type
                  version='b3'
                >
                  {upcomingInvoice?.amount_due / 100} {upcomingInvoice?.currency}
                </Type>
              </Line>
            </Line>

            {!paymentMethods.length ? (
              <Line
                fullWidth
              >
                <Button
                  onClick={() => navigate('/organization/settings/payment-methods/add')}

                  size='small'
                >
                  Add payment method
                </Button>
              </Line>
            ) : <>
              <FormRow
                name='Payment method'
                description='Choose a payment method'
              >
                <Select
                  version='outlined'

                  valueDefault={paymentMethod}

                  value={paymentMethod}

                  onChange={(valueNew: any) => setPaymentMethod(valueNew)}

                  getname={(item: any) => item.props.primary}

                  fullWidth
                >
                  {paymentMethods.map((item: any, index: number) => (
                    <ListItem
                      key={index}

                      primary={`**** **** **** ${item.card.last4}`}

                      value={item}

                      button

                      noBackground
                    />
                  ))}
                </Select>
              </FormRow>

              <Line
                direction='row'

                justify='flex-end'

                fullWidth
              >
                <Button
                  version='filled'

                  color='primary'

                  onClick={onUpdate}

                  loading={loading === 'pay'}

                  size='small'
                >
                  Confirm
                </Button>
              </Line>
            </>}
          </Line>
        </Line>
      </Modal>
    </>
  };

  return (
    <Line
      ref={ref}

      gap={6}

      fullWidth

      className={classNames([
        className,
        classes.wrapper
      ])}

      {...other}
    >
      {!loaded && <LinearProgress />}

      {loaded && <>
        <Tabs
          value={tab}

          onChange={onTabsChange}

          size='small'

          justify='center'
        >
          {tabs.map(item => (
            <Tab
              name={capitalize(item)}

              value={item as any}
            />
          ))}
        </Tabs>

        <Line
          flex

          fullWidth
        >
          {modal[tab]}
        </Line>

        {modal.modal}
      </>}
    </Line>
  );
});

export default OrganizationSettingsSubscriptionsAmaui;
