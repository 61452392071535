import React from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

import { Try, capitalize, clamp, copy, debounce, decodeObjectValue, download, equalDeep, fileToValue, getObjectValue, hash, is, numberWithCommas, parse, setObjectValue, stringToColor, stringify, textToInnerHTML } from '@amaui/utils';
import { classNames, style, useAmauiTheme } from '@amaui/style-react';
import { IBaseElement } from '@amaui/ui-react/types';
import { AutoCompleteCountry, Button, Checkbox, Chip, Expand, FileChoose, IconButton, Line, LinearProgress, Link, ListItem, Menu, MenuItem, Pagination, Select, Table, TableBody, TableCell, TableHead, TableHeader, TableRow, Tooltip, Type, useConfirm, useForm, useLocation, useSnackbars, useSubscription } from '@amaui/ui-react';
import { AmauiDate, duration, format } from '@amaui/date';
import { Filter, IApp } from '@amaui/api-utils';

import IconMaterialPanoramaFishEyeRounded from '@amaui/icons-material-rounded-react/IconMaterialPanoramaFishEye';
import IconMaterialSunnyRounded from '@amaui/icons-material-rounded-react/IconMaterialSunny';
import IconMaterialUploadRounded from '@amaui/icons-material-rounded-react/IconMaterialUpload';
import IconMaterialDownloadRounded from '@amaui/icons-material-rounded-react/IconMaterialDownload';
import IconMaterialFileCopyRounded from '@amaui/icons-material-rounded-react/IconMaterialFileCopy';
import IconMaterialHomeRounded from '@amaui/icons-material-rounded-react/IconMaterialHome';
import IconMaterialPushPinRounded from '@amaui/icons-material-rounded-react/IconMaterialPushPin';
import IconMaterialExpandMoreRounded from '@amaui/icons-material-rounded-react/IconMaterialExpandMore';
import IconMaterialMoreVertRounded from '@amaui/icons-material-rounded-react/IconMaterialMoreVert';
import IconMaterialNavigateBeforeRounded from '@amaui/icons-material-rounded-react/IconMaterialNavigateBefore';
import IconMaterialNavigateNextRounded from '@amaui/icons-material-rounded-react/IconMaterialNavigateNext';
import IconMaterialFilterListRounded from '@amaui/icons-material-rounded-react/IconMaterialFilterList';
import IconMaterialDomainVerificationRounded from '@amaui/icons-material-rounded-react/IconMaterialDomainVerification';
import IconMaterialDomainVerificationOffRounded from '@amaui/icons-material-rounded-react/IconMaterialDomainVerificationOff';
import IconMaterialArchiveRounded from '@amaui/icons-material-rounded-react/IconMaterialArchive';
import IconMaterialUnarchiveRounded from '@amaui/icons-material-rounded-react/IconMaterialUnarchive';
import IconMaterialOpenInNewRounded from '@amaui/icons-material-rounded-react/IconMaterialOpenInNew';
import IconMaterialTagRounded from '@amaui/icons-material-rounded-react/IconMaterialTag';
import IconMaterialDeleteRounded from '@amaui/icons-material-rounded-react/IconMaterialDelete';

import { APP, ICollection, IQuerySubscription, ISelectedSubscription, ISignedIn, appToName, booleans, dates, formats, getErrorMessage, getParamID, itemFromCollection, menuItemProps, nameFromCollection } from 'other';
import { AppService, AuthService, ViewService } from 'services';
import { AutoCompleteObjects, AutoCompleteSearch, ClearSearch, Color, DateSearch, FormTags, Limited, MoreMenu, NoResults, NumericTextField, SavedFilters, SelectAColor, Tags, TextSearch, Views } from 'ui';

const cleanValue = (value_: string) => {
  let value = value_;

  if (typeof value !== 'string') return value;

  ['-', '_'].forEach(filter => {
    const expression = `\\${filter}`;
    const regexp = new RegExp(expression, 'g');

    value = value ? value.replace(regexp, ' ') : '';
  });

  return `${value.charAt(0).toUpperCase()}${value.slice(1)}`;
};

const useStyle = style(theme => ({
  root: {
    paddingBottom: 24,

    '& [data-name]': {
      cursor: 'pointer'
    },

    '& .amaui-Select-wrapper': {
      '& .amaui-ListItem-text-primary': {
        flex: '0 0 auto',
        width: '100%',
        whiteSpace: 'unset',
        textOverflow: 'unset',
        overflow: 'unset'
      }
    }
  },

  name: {
    cursor: 'pointer'
  },

  table: {
    '&.amaui-Table-root': {
      background: theme.palette.light ? theme.palette.background.default.primary : 'hsl(53 100% 7% / 1)'
    },

    '& .amaui-TableCell-root': {
      maxWidth: 340,

      '& > *': {
        overflow: 'auto hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
      }
    }
  },

  tableHead: {
    position: 'relative',

    '&.amaui-TableHead-root': {
      background: 'inherit'
    }
  },

  tableBody: {
    '&.amaui-TableBody-root': {
      background: 'inherit'
    }
  },

  tableHeader: {
    '&.amaui-TableHeader-root': {
      background: 'inherit'
    }
  },

  tableRow: {
    '&.amaui-TableRow-root': {
      background: 'inherit'
    }
  },

  linearProgress: {
    '&.amaui-LinearProgress-root': {
      position: 'absolute',
      left: 0,
      bottom: '-4px',
      zIndex: 1
    }
  },

  tableCellCheckbox: {
    width: 46,

    '& > *': {
      paddingInlineEnd: '0px !important'
    }
  },

  usage: {
    cursor: 'default',
    userSelect: 'none'
  },

  textNotFound: {
    height: '100%',
    marginTop: 54,
    flex: '0 0'
  },

  ...theme.classes(theme)
}), { name: 'amaui-app-layout-Page' });

export interface IPageProperty {
  name: string;
  type?: 'string' | 'number' | 'boolean' | 'array' | 'color' | 'date' | 'duration';
  method?: (item: any) => any;
}

export interface IPageProperties {
  [p: string]: IPageProperty;
}

export interface IPageMethodOptions {
  queryItems: IQuerySubscription;
  queryItemsPinned: IQuerySubscription;

  selectedItems: ISelectedSubscription;

  loading: any;
  setLoading: any;

  onOpen: (item: any) => any;
  onClose: (item: any) => any;
}

export interface IPageItemMethodOptions extends IPageMethodOptions {
  getMenuItems: (item: any) => any;

  props: (item: any) => any;

  formSearch: any;

  onDefault: (item: any) => any;
  onMain: (item: any) => any;
  onActive: (item: any) => any;
  onArchive: (item: any) => any;
  onPin: (item: any) => any;
  onRemove: (item: any) => any;
}

export type IPageSearch = Array<string | {
  type?: 'text' | 'select' | 'date' | 'auto-complete-objects' | 'select-country';
  name: string;
  property: string;
  default: any;
  validation: { name: string, is: any, value: any };
  options?: Array<{ name: string, value: any }>;
  props?: any;
}>;

export interface IPage extends IBaseElement {
  name: string;

  service: any;

  serviceAdd?: any;

  serviceUpdate?: any;

  serviceRemove?: any;

  parent?: any;

  properties?: IPageProperties;

  queryItemsName?: string;
  queryItemsPinnedName?: string;

  selectedItemsName?: string;

  addOneName?: string;
  addManyName?: string;
  updateOneName?: string;
  updateManyName?: string;
  removeOneName?: string;
  removeManyName?: string;

  formSearch?: any;
  formSearchValueDefault?: any;

  getItems?: (options: IPageItemMethodOptions) => any;

  rows?: any;
  columns?: any;

  Form?: any;

  search?: IPageSearch;
  options?: string[];

  queryDefault?: any;

  app: IApp;
  collection: ICollection;
  route?: string;
  singular?: string;
  plural?: string;
  addVersion?: string;

  additionalMenu?: boolean;
  isStripe?: boolean;
  pinned?: boolean;
  nested?: any;
  paginationSimple?: boolean;
  onOpenNavigate?: boolean;
  updateEntire?: boolean;
  filtersPrimaryStart?: any;
  filtersPrimaryEnd?: any;
  addButtonStart?: any;
  addButtonEnd?: any;

  onDefault?: (item: any, options: IPageMethodOptions) => any;
  onMain?: (item: any, options: IPageMethodOptions) => any;
  onActive?: (item: any, options: IPageMethodOptions) => any;
  onArchive?: (item: any, options: IPageMethodOptions) => any;
  onAmaui?: (item: any, options: IPageMethodOptions) => any;
  onPin?: (item: any, options: IPageMethodOptions) => any;
  onRemove?: (item: any, options: IPageMethodOptions) => any;
  onRemoveMethod?: (item: any, options: IPageMethodOptions) => any;

  isEnabledAction?: (item: any, action: string) => boolean;

  getItemActions?: (item: any, options: IPageMethodOptions) => any;
  getManyActions?: (options: IPageMethodOptions) => any;
  getMoreActions?: (options: IPageMethodOptions) => any;

  noUsage?: boolean;
  noAdd?: boolean;
  noTotal?: boolean;
  noSavedFilters?: boolean;
  noActions?: boolean;
  noTextSearch?: boolean;
  noTags?: boolean;
  noUpdateProject?: boolean;
  noRemove?: boolean;
  noManage?: boolean;
  noSelect?: boolean;
  noSearch?: boolean;
  noFilter?: boolean;
  noOpen?: boolean;
  noAddedAt?: boolean;
  noOnInit?: boolean;
  noOnSearch?: boolean;
  noViews?: boolean;
  noImport?: boolean;
  noLinearProgress?: boolean;
  noExport?: boolean;

  TableProps?: any;
  TableHeadProps?: any;
  TableBodyProps?: any;
  TableRowProps?: any;
  TableCellProps?: any;
  onAddName?: any;
  onAddProps?: any;
  onAddFormProps?: any;
  onConfirmProps?: any;
}

const Page: React.FC<IPage> = React.forwardRef((props, ref: any) => {
  const {
    name,

    service,

    serviceAdd,

    serviceUpdate,

    serviceRemove,

    parent,

    properties = [],

    queryItemsName = 'queryItems',
    queryItemsPinnedName = 'queryItemsPinned',

    selectedItemsName = 'selectedItems',

    addOneName = 'add',
    addManyName = 'addMany',
    updateOneName = 'update',
    updateManyName = 'updateMany',
    removeOneName = 'remove',
    removeManyName = 'removeMany',

    rows,
    columns: columns_,

    Form,

    search = [],
    options = ['copy'],

    queryDefault: queryDefault_,

    formSearch: formSearch_,

    formSearchValueDefault: formSearchValueDefault_,

    getItems,

    app,
    popup,
    collection,
    route,

    singular: singular_,
    plural: plural_,
    addVersion = 'mid',

    additionalMenu = true,
    pinned,
    isStripe,
    nested,
    paginationSimple,
    onOpenNavigate,
    updateEntire,
    filtersPrimaryStart,
    filtersPrimaryEnd,
    addButtonStart,
    addButtonEnd,

    onDefault: onDefault_,
    onMain: onMain_,
    onActive: onActive_,
    onArchive: onArchive_,
    onAmaui: onAmaui_,
    onPin: onPin_,
    onRemove: onRemove_,
    onRemoveMethod,

    isEnabledAction,

    getItemActions,
    getManyActions,
    getMoreActions,

    noAdd,
    noUsage,
    noTotal,
    noSavedFilters,
    noActions,
    noTextSearch,
    noTags,
    noUpdateProject,
    noRemove,
    noSelect,
    noManage,
    noSearch,
    noFilters,
    noOpen,
    noAddedAt,
    noOnInit,
    noOnSearch,
    noViews,
    noImport,
    noLinearProgress,
    noExport,

    TableProps,
    TableHeadProps,
    TableBodyProps,
    TableRowProps,
    TableCellProps,
    onAddName,
    onAddProps,
    onAddFormProps,
    onConfirmProps = [],

    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const theme = useAmauiTheme();
  const snackbars = useSnackbars();
  // const mainProgress = useMainProgress();
  const confirm = useConfirm();
  const location = useLocation();
  const navigate = useNavigate();

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  // items 
  const queryItems = useSubscription<IQuerySubscription>(service[queryItemsName]);
  const selectedItems = useSubscription<ISelectedSubscription>(service[selectedItemsName]);

  // items pinned 
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const queryItemsPinned = pinned && useSubscription<IQuerySubscription>(service[queryItemsPinnedName]);

  // views 
  const queryItemsViews = useSubscription<IQuerySubscription>(ViewService.queryItems);

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState<'query' | 'pin' | boolean>(false);
  const [moreMenu, setMoreMenu] = React.useState<any>();
  const [loaded, setLoaded] = React.useState(false);
  const [sort, setSort] = React.useState<any>({ added_at: -1 });

  const queryDefault = React.useMemo(() => {
    return queryDefault_ || {};
  }, [queryDefault_]);

  const formSearchValueDefault = React.useMemo(() => {
    if (formSearchValueDefault_) return formSearchValueDefault_;

    const result: any = {
      text: '',
      added_at: []
    };

    const getSearchDefault = (item_: any) => {
      const item = item_?.toLowerCase();

      // Active, archived, pinned, private  
      if (['amaui', 'apps', 'active', 'archived', 'pinned', 'private', 'properties_gender'].includes(item)) return 'all';

      if (['color']) return [];

      return '';
    };

    if (is('array', search)) {
      search.forEach((item: any) => {
        result[item?.property || item] = item?.default !== undefined ? item.default : getSearchDefault(item?.name || item?.property || item);

        if (result[item?.property || item] === undefined) delete result[item?.property || item];
      });
    }

    // Tags 
    if (!noTags) {
      result.tags = [];
    }

    return result;
  }, [formSearchValueDefault_, search, noTags]);

  const formSearchValues = React.useMemo(() => {
    const result: any = {
      text: {
        name: 'Text',
        is: 'string',
        value: ''
      },

      added_at: {
        name: 'Added at',
        value: []
      }
    };

    const getSearchValidation = (item_: any) => {
      const item = item_?.toLowerCase();

      const itemName = capitalize(item);

      // Active, archived, amaui, pinned, private  
      if (['active', 'archived', 'amaui', 'pinned', 'private'].includes(item)) return {
        name: itemName,
        value: 'all',
        in: [true, false, 'all']
      };

      if (item === 'color') return {
        name: 'Color',
        value: []
      };

      // Apps  
      if (item === 'apps') return {
        name: itemName,
        value: 'all',
        in: APP
      };

      return {
        name: itemName
      };
    };

    if (is('array', search)) {
      search.forEach((item: any) => {
        result[item?.property || item] = item?.validation !== undefined ? item.validation : getSearchValidation(item?.name || item?.property || item)
      });
    }

    // Tags 
    if (!noTags) {
      result.tags = {
        name: 'Tags',
        is: 'array',
        of: 'object',
        value: []
      };
    }

    return result;
  }, [search, noTags]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const formSearch = formSearch_ ? formSearch_ : useForm({
    values: {
      ...formSearchValues
    },

    valueDefault: {
      ...formSearchValueDefault
    },

    autoValidate: true
  });

  const formSearchOperator = useForm({
    values: {
      v: {
        name: 'Operators',
        value: {}
      }
    },

    autoValidate: true
  });

  const refs = {
    parent: React.useRef(parent),
    properties: React.useRef(properties),
    onAdd: React.useRef<any>(),
    formSearch: React.useRef(formSearch),
    formSearchOperator: React.useRef(formSearchOperator),
    queryDefault: React.useRef(queryDefault),
    queryItems: React.useRef(queryItems),
    queryItemsPinned: React.useRef(queryItemsPinned),
    selectedItems: React.useRef(selectedItems),
    service: React.useRef(service),
    serviceAdd: React.useRef(serviceAdd),
    serviceUpdate: React.useRef(serviceUpdate),
    serviceRemove: React.useRef(serviceRemove),
    queryItemsName: React.useRef(queryItemsName),
    queryItemsPinnedName: React.useRef(queryItemsPinnedName),
    selectedItemsName: React.useRef(selectedItemsName),
    addOneName: React.useRef('add'),
    addManyName: React.useRef('addMany'),
    updateOneName: React.useRef('update'),
    updateManyName: React.useRef('updateMany'),
    removeOneName: React.useRef('remove'),
    removeManyName: React.useRef('removeMany'),
    parentFilters: React.useRef<any>(),
    onOpenNavigate: React.useRef(onOpenNavigate),
    nested: React.useRef(nested),
    pinned: React.useRef(pinned),
    isEnabledAction: React.useRef(isEnabledAction),
    noOnInit: React.useRef(noOnInit),
    noOnSearch: React.useRef(noOnSearch),
    sort: React.useRef(sort),
    loading: React.useRef(loading),
    toRemove: React.useRef(['id', '_id', 'country', 'user', 'organization', 'project', 'added_at', 'updated_at']),
    model: React.useRef<string>(),
    onAddProps: React.useRef(onAddProps),
    onAddFormProps: React.useRef(onAddFormProps),
    onConfirmProps: React.useRef(onConfirmProps),
    opened: React.useRef<any>(),
    addVersion: React.useRef<any>(addVersion),
    onRemoveMethod: React.useRef<any>(onRemoveMethod)
  };

  refs.parent.current = parent;

  refs.properties.current = properties;

  refs.queryDefault.current = queryDefault;

  refs.queryItems.current = queryItems;

  refs.queryItemsPinned.current = queryItemsPinned;

  refs.selectedItems.current = selectedItems;

  refs.addOneName.current = addOneName;

  refs.addManyName.current = addManyName;

  refs.updateOneName.current = updateOneName;

  refs.updateManyName.current = updateManyName;

  refs.removeOneName.current = removeOneName;

  refs.removeManyName.current = removeManyName;

  refs.formSearch.current = formSearch;

  refs.formSearchOperator.current = formSearchOperator;

  refs.service.current = service;

  refs.serviceAdd.current = serviceAdd || service;

  refs.serviceUpdate.current = serviceUpdate || service;

  refs.serviceRemove.current = serviceRemove || service;

  refs.onOpenNavigate.current = onOpenNavigate;

  refs.nested.current = nested;

  refs.pinned.current = pinned;

  refs.isEnabledAction.current = isEnabledAction;

  refs.noOnInit.current = noOnInit;

  refs.noOnSearch.current = noOnSearch;

  refs.sort.current = sort;

  refs.loading.current = loading;

  refs.onAddProps.current = onAddProps;

  refs.onAddFormProps.current = onAddFormProps;

  refs.onConfirmProps.current = onConfirmProps;

  refs.onRemoveMethod.current = onRemoveMethod;

  const model = collection;

  refs.model.current = model;

  refs.addVersion.current = addVersion;

  const singular = React.useMemo(() => {
    return singular_ !== undefined ? singular_ : nameFromCollection(collection!);
  }, [singular_, collection]);

  const singularCapitalized = React.useMemo(() => capitalize(singular), [singular]);

  const plural = React.useMemo(() => {
    return plural_ !== undefined ? plural_ : nameFromCollection(collection!, false);
  }, [plural_, collection]);

  const pluralCapitalized = React.useMemo(() => capitalize(plural), [plural]);

  const refresh = React.useCallback(() => {
    // reset
    refs.service.current[refs.selectedItemsName.current]?.value!.reset();

    // refetch
    refs.service.current[refs.queryItemsName.current]?.value!.refetch();

    // pinned 
    if (refs.pinned.current) refs.service.current[refs.queryItemsPinnedName.current]?.value!.refetch();
  }, []);

  const onClose = React.useCallback(() => {
    refs.opened.current = null;

    // initial
    // unopened
    AppService.pages[(refs.nested.current && refs.nested.current !== 'add') ? 'addSecondary' : 'add'].emit({
      ...AppService.pages[(refs.nested.current && refs.nested.current !== 'add') ? 'addSecondary' : 'add'].value,

      open: false
    });

    if (!refs.nested.current) {
      const previous = route;

      if (previous !== location.pathname) {
        window.history.pushState(undefined, '', previous);
      }
    }
  }, [route, location]);

  const onOpen = React.useCallback(async (item: any) => {
    const id = item?.id;

    if (!(id && !['add'].includes(id))) return;

    const object = id ? refs.queryItems.current.response?.find((item: any) => id === item.id) || (id && (await refs.service.current.get(id)).response?.response) : null;

    if (!(object && Form)) {
      refs.opened.current = null;

      onClose();

      return;
    }

    refs.opened.current = object;

    const to = `${route}/${object.id}`;

    if (refs.onOpenNavigate.current) {
      navigate(to);

      return;
    }

    AppService.pages[(refs.nested.current && refs.nested.current !== 'add') ? 'addSecondary' : 'add'].emit({
      id: object.id,
      version: updateEntire ? 'entire' : refs.addVersion.current,
      open: true,
      route: !refs.nested.current ? {
        to,
        previous: route
      } : undefined,
      children: (
        <Form
          object={object}

          parent={parent}

          onConfirm={() => refs.service.current[refs.queryItemsName.current].value!.refetch.bind(refs.service.current[refs.queryItemsName.current].value)(...refs.onConfirmProps.current)}

          singular={singular}

          plural={plural}

          {...refs.onAddFormProps.current}
        />
      ),
      noPadding: true
    });
  }, [parent, route, Form, updateEntire, singular, plural]);

  const onAdd = React.useCallback(() => {
    if (!Form) return;

    AppService.pages[(refs.nested.current && refs.nested.current !== 'add') ? 'addSecondary' : 'add'].emit({
      open: true,
      version: updateEntire ? 'entire' : refs.addVersion.current,
      route: !refs.nested.current ? {
        previous: route,
        to: `${route}/add`
      } : undefined,
      children: (
        <Form
          parent={parent}

          onConfirm={() => refs.service.current[refs.queryItemsName.current].value!.refetch.bind(refs.service.current[refs.queryItemsName.current].value)(...refs.onConfirmProps.current)}

          singular={singular}

          plural={plural}

          nested={refs.nested.current}

          {...refs.onAddFormProps.current}
        />
      ),
      noPadding: true,

      ...refs.onAddProps.current
    });
  }, [parent, route, Form, updateEntire, AppService.pages.add, AppService.pages.addSecondary, singular, plural]);

  refs.onAdd.current = onAdd;

  const init = React.useCallback(async () => {
    // mainProgress.start();

    // items 
    const result = await refs.service.current[refs.queryItemsName.current].value!.query({
      id: refs.parent.current?.id,

      query: {
        query: {
          ...refs.queryDefault.current,

          ...(refs.pinned.current && { pinned: false })
        },

        sort: {
          ...refs.sort.current
        }
      }
    });

    // items pinned 
    if (refs.pinned.current) {
      // Pinned 
      await refs.service.current[refs.queryItemsPinnedName.current].value!.query({
        id: refs.parent.current?.id,

        query: {
          query: {
            ...refs.queryDefault.current,

            pinned: true
          },

          limit: 40
        }
      });
    }

    // views 
    await ViewService.queryItems.value!.query({
      query: {
        query: {
          model: refs.model.current
        }
      }
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      // For now don't open nested modals 
      if (!refs.nested.current) {
        const id = getParamID();

        if (id) onOpen({ id });
      }
    }

    setLoaded(true);

    // mainProgress.done();
  }, [service, hash(queryDefault)]);

  React.useEffect(() => {
    if (noOnInit) {
      setLoaded(queryItems.inited);
    }
  }, [noOnInit, queryItems?.response]);

  const onPagination = React.useCallback(async (value: any) => {
    setLoading('query');

    const result = await refs.service.current[refs.queryItemsName.current].value!.query({
      id: refs.parent.current?.id,

      query: {
        ...refs.service.current[refs.queryItemsName.current].value!.previousQuery,

        sort: {
          ...refs.sort.current
        },

        next: undefined,
        previous: undefined,
        skip: undefined,
        total: undefined,

        ...value
      }
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }

    setLoading(false);
  }, []);

  const onSearch = React.useCallback(debounce(async () => {
    setLoading('query');

    const formSearchValue = refs.formSearch.current.value;

    const searchObject: any = {};

    if (formSearchValue.text) searchObject.text = formSearchValue.text;

    // active 
    if (search?.includes('active')) {
      if (is('boolean', formSearchValue.active)) searchObject.active = formSearchValue.active;

      if (formSearchValue.active === 'all') searchObject.active = undefined;
    }

    // archived 
    if (search?.includes('archived')) {
      if (is('boolean', formSearchValue.archived)) searchObject.archived = formSearchValue.archived;

      if (formSearchValue.archived === 'all') searchObject.archived = undefined;
    }

    // amaui 
    if (search?.includes('amaui')) {
      if (is('boolean', formSearchValue.amaui)) searchObject.amaui = formSearchValue.amaui;

      if (formSearchValue.amaui === 'all') searchObject.amaui = undefined;
    }

    // mime 
    if (search?.includes('mime')) {
      if (is('boolean', formSearchValue.mime)) searchObject.mime = formSearchValue.mime;

      if (formSearchValue.mime === 'all') searchObject.mime = undefined;
    }

    // apps  
    if (search?.includes('apps')) {
      if (is('string', formSearchValue.apps)) searchObject.apps = formSearchValue.apps;

      if (formSearchValue.apps === 'all') searchObject.apps = undefined;
    }

    // color 
    if (search?.includes('color')) {
      if (is('array', formSearchValue.color) && !!formSearchValue.color.length) searchObject.color = formSearchValue.color;

      if (!formSearchValue.color) searchObject.color = undefined;
    }

    // tags 
    if (!noTags && !!formSearchValue.tags?.length) {
      searchObject.tags = formSearchValue.tags.map((item: any) => item?.id || item);

      searchObject.tags_operator = 'and';
    }

    // other 
    search.filter((item: any) => is('object', item) && ['select', undefined].includes(item.version)).forEach((item: any) => {
      const valueSearch = formSearchValue[item.property];

      // regular 
      if (is('string', valueSearch)) searchObject[item.property] = valueSearch;

      if (item.type === 'auto-complete-objects') {
        searchObject[item.property] = is('array', valueSearch) ? valueSearch.map((itemValue: any) => itemValue?.id || itemValue?._id || itemValue).filter(Boolean) : (valueSearch?.id || valueSearch?._id || valueSearch);

        if (!searchObject[item.property]?.length) delete searchObject[item.property];
      }

      // country 
      if (item.type === 'select-country' && valueSearch) searchObject[item.property] = valueSearch;

      // date  
      if (item.type === 'date') {
        if (is('array', valueSearch)) {
          const [dateFrom, dateTo] = valueSearch;

          if (dateFrom) searchObject[`${item.property}_from`] = dateFrom;

          if (dateTo) searchObject[`${item.property}_to`] = dateTo;
        }
      }

      // empty value 
      if (['', 'all'].includes(valueSearch)) searchObject[item.property] = undefined;
    });

    if (formSearchValue.added_at) {
      const [addedAtFrom, addedAtTo] = formSearchValue.added_at;

      if (addedAtFrom) searchObject.added_at_from = addedAtFrom;

      if (addedAtTo) searchObject.added_at_to = addedAtTo;
    }

    const result = await refs.service.current[refs.queryItemsName.current].value!.query({
      id: refs.parent.current?.id,

      query: {
        ...refs.service.current[refs.queryItemsName.current].value!.previousQuery,

        query: {
          ...refs.queryDefault.current,

          ...searchObject,

          ...(refs.pinned.current && { pinned: false })
        },

        sort: {
          ...refs.sort.current
        },

        next: undefined,
        previous: undefined,
        skip: undefined,
        total: undefined
      }
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }

    setLoading(false);
  }, 440), [noTags, search]);

  const onSort = React.useCallback((item: any, sortedBy: any) => {
    setSort((previous: any) => {
      const valueNew = { ...previous };

      valueNew[item] = sortedBy === 'asc' ? 1 : -1;

      return valueNew;
    });

    onSearch();
  }, []);

  const hashForm = React.useCallback((valueNew_: any) => {
    const valueNew = copy(valueNew_);

    Object.keys(valueNew).forEach(key => {
      if (valueNew[key] === undefined) delete valueNew[key];
    });

    return hash(valueNew);
  }, []);

  React.useEffect(() => {
    if (!refs.noOnSearch.current && (refs.queryItems.current.loaded || formSearch_) && loaded) onSearch();
  }, [hashForm(formSearch.value), hashForm(formSearchOperator.value)]);

  const onClearSearch = React.useCallback(() => {
    refs.formSearch.current.clear();
    refs.formSearchOperator.current.clear();
  }, []);

  const onChangeFilter = React.useCallback((filter: Filter) => {
    refs.formSearch.current.clear();

    setTimeout(() => {
      refs.formSearch.current.updateForm((previous: any) => {
        const valueNew = { ...previous };

        const value = filter.value || {};

        Object.keys(value).forEach(item => {
          if (valueNew.values[item]) {
            valueNew.values[item].value = value[item];

            setObjectValue(valueNew.value, item, value[item]);
          }
        });

        return valueNew;
      })
    }, 14);
  }, []);

  const cleanUp = React.useCallback(() => {
    if (refs.service.current[refs.queryItemsName.current]) refs.service.current[refs.queryItemsName.current].value!.reset();

    if (refs.service.current[refs.selectedItemsName.current]) refs.service.current[refs.selectedItemsName.current].value.reset();
  }, []);

  React.useEffect(() => {
    if (loaded) {
      if (refs.nested.current) return;

      const id = getParamID();

      if ((id && id !== refs.opened.current?.id) || window.location.pathname.endsWith('/add')) onOpen({ id });
      else if (!id) onClose();
    }
  }, [location]);

  React.useEffect(() => {
    const isSomeSelected = queryItems.response?.some((item: any) => selectedItems?.isSelected(item));

    if (isSomeSelected) selectedItems.updateMany(queryItems.response);
  }, [stringify(queryItems?.response)]);

  React.useEffect(() => {
    setLoaded(false);

    // init 
    if (!refs.noOnInit.current) init();

    // add
    setTimeout(() => {
      if (window.location.pathname.endsWith(`${route}/add`)) refs.onAdd.current();
    }, 14);

    return () => {
      cleanUp();
    };
  }, [route, service, hash(queryDefault)]);

  const optionsMethod: IPageMethodOptions = React.useMemo(() => {
    return {
      queryItems,
      queryItemsPinned: queryItemsPinned as any,

      selectedItems,

      loading,
      setLoading,

      onOpen,
      onClose
    };
  }, [queryItems?.response, (queryItemsPinned as any)?.response, selectedItems, loading, onOpen, onClose]);

  const onPin = React.useCallback(async (item: any) => {
    if (is('function', onPin_)) return onPin_!(item, optionsMethod);

    setLoading('pin');

    const result = await refs.serviceUpdate.current[refs.updateOneName.current](item.id, {
      pinned: !item.pinned
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `${singularCapitalized} ${item.pinned ? 'unpinned' : 'pinned'}`
      });
    }

    // refresh 
    refresh();

    setLoading(false);
  }, [onPin_, optionsMethod]);

  const onDefault = React.useCallback(async (item: any) => {
    if (is('function', onDefault_)) return onDefault_!(item, optionsMethod);

    const args: any = [
      item.id,
      {
        default: true
      }
    ];

    if (refs.parent.current) args.unshift(refs.parent.current?.id);

    const result = await refs.serviceUpdate.current[refs.updateOneName.current](...args);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `${singularCapitalized} updated to the default`
      });
    }

    // refresh 
    refresh();
  }, [onDefault_, optionsMethod]);

  const onMain = React.useCallback(async (item: any) => {
    if (is('function', onMain_)) return onMain_!(item, optionsMethod);

    const args: any = [
      item.id,
      {
        main: true
      }
    ];

    if (refs.parent.current) args.unshift(refs.parent.current?.id);

    const result = await refs.serviceUpdate.current[refs.updateOneName.current](...args);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `${singularCapitalized} updated to the main`
      });
    }

    // refresh 
    refresh();
  }, [onMain_, optionsMethod]);

  const onActive = React.useCallback(async (item: any) => {
    if (is('function', onActive_)) return onActive_!(item, optionsMethod);

    const args: any = [
      item.id,
      {
        active: !item.active
      }
    ];

    if (refs.parent.current) args.unshift(refs.parent.current?.id);

    const result = await refs.serviceUpdate.current[refs.updateOneName.current](...args);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `${singularCapitalized} ${item.active ? 'inactivated' : 'active'}`
      });
    }

    // refresh 
    refresh();
  }, [onActive_, optionsMethod]);

  const onArchive = React.useCallback(async (item: any) => {
    if (is('function', onArchive_)) return onArchive_!(item, optionsMethod);

    const args: any = [
      item.id,
      {
        archived: !item.archived
      }
    ];

    if (refs.parent.current) args.unshift(refs.parent.current?.id);

    const result = await refs.serviceUpdate.current[refs.updateOneName.current](...args);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `${singularCapitalized} ${item.archived ? 'unarchived' : 'archived'}`
      });
    }

    // refresh 
    refresh();
  }, [onArchive_, optionsMethod]);

  const onAmaui = React.useCallback(async (item: any) => {
    if (is('function', onArchive_)) return onAmaui_!(item, optionsMethod);

    const args: any = [
      item.id,
      {
        amaui: !item.amaui
      }
    ];

    if (refs.parent.current) args.unshift(refs.parent.current?.id);

    const result = await refs.serviceUpdate.current[refs.updateOneName.current](...args);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `${singularCapitalized} ${item.amaui ? 'removed amaui' : 'added amaui'}`
      });
    }

    // refresh 
    refresh();
  }, [onAmaui_, optionsMethod]);

  const onRemove = React.useCallback(async (item: any) => {
    if (is('function', onRemove_)) return onRemove_!(item, optionsMethod);

    if (!(await confirm.open({ name: `Removing a ${singular.toLowerCase()}` }))) return;

    const args: any = [
      item.id
    ];

    if (refs.parent.current) args.unshift(refs.parent.current?.id);

    const result = await (
      refs.onRemoveMethod.current ?
        refs.onRemoveMethod.current(item, optionsMethod) :
        refs.serviceRemove.current[refs.removeOneName.current](...args)
    );

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `${singularCapitalized} removed`
      });
    }

    // refresh 
    refresh();
  }, [onRemove_, optionsMethod]);

  const onActivateMany = React.useCallback(async (value: any = refs.service.current[refs.selectedItemsName.current].value!.objects) => {
    const values = is('array', value) ? value : [];

    const isEnabled = (item: any) => is('function', refs.isEnabledAction.current) ? refs.isEnabledAction.current!(item, 'active') === true : true;

    const toUpdate = values.filter((item: any) => !item.active && isEnabled(item));

    const length = toUpdate.length;

    if (!length) return;

    const confirmed = await confirm.open({
      name: `Activate ${plural.toLowerCase()}`,
      description: `You are about to activate ${length} ${(length === 1 ? singular : plural).toLowerCase()}, are you sure?`
    });

    if (!confirmed) return;

    const args: any = [
      {
        ids: toUpdate.map((item: any) => item.id),

        update: {
          active: true
        } as Partial<any>
      }
    ];

    if (refs.parent.current) args.unshift(refs.parent.current?.id);

    const result = await refs.serviceUpdate.current[refs.updateManyName.current](...args);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `${pluralCapitalized} updated`
      });
    }

    // refresh 
    refresh();
  }, []);

  const onInActivateMany = React.useCallback(async (value: any = refs.service.current[refs.selectedItemsName.current].value!.objects) => {
    const values = is('array', value) ? value : [];

    const isEnabled = (item: any) => is('function', refs.isEnabledAction.current) ? refs.isEnabledAction.current!(item, 'active') === true : true;

    const toUpdate = values.filter((item: any) => item.active && isEnabled(item));

    const length = toUpdate.length;

    if (!length) return;

    const confirmed = await confirm.open({
      name: `Inactivate ${plural.toLowerCase()}`,
      description: `You are about to inactivate ${length} ${(length === 1 ? singular : plural).toLowerCase()}, are you sure?`
    });

    if (!confirmed) return;

    const args: any = [
      {
        ids: toUpdate.map((item: any) => item.id),

        update: {
          active: false
        } as Partial<any>
      }
    ];

    if (refs.parent.current) args.unshift(refs.parent.current?.id);

    const result = await refs.serviceUpdate.current[refs.updateManyName.current](...args);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `${pluralCapitalized} updated`
      });
    }

    // refresh 
    refresh();
  }, []);

  const onArchiveMany = React.useCallback(async (value: any = refs.service.current[refs.selectedItemsName.current].value!.objects) => {
    const values = is('array', value) ? value : [];

    const isEnabled = (item: any) => is('function', refs.isEnabledAction.current) ? refs.isEnabledAction.current!(item, 'archive') === true : true;

    const toUpdate = values.filter((item: any) => !item.archived && isEnabled(item));

    const length = toUpdate.length;

    if (!length) return;

    const confirmed = await confirm.open({
      name: `Archive ${plural.toLowerCase()}`,
      description: `You are about to archive ${length} ${(length === 1 ? singular : plural).toLowerCase()}, are you sure?`
    });

    if (!confirmed) return;

    const args: any = [
      {
        ids: toUpdate.map((item: any) => item.id),

        update: {
          archived: true
        } as Partial<any>
      }
    ];

    if (refs.parent.current) args.unshift(refs.parent.current?.id);

    const result = await refs.serviceUpdate.current[refs.updateManyName.current](...args);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `${pluralCapitalized} updated`
      });
    }

    // refresh 
    refresh();
  }, []);

  const onUnArchiveMany = React.useCallback(async (value: any = refs.service.current[refs.selectedItemsName.current].value!.objects) => {
    const values = is('array', value) ? value : [];

    const isEnabled = (item: any) => is('function', refs.isEnabledAction.current) ? refs.isEnabledAction.current!(item, 'archive') === true : true;

    const toUpdate = values.filter((item: any) => item.archived && isEnabled(item));

    const length = toUpdate.length;

    if (!length) return;

    const confirmed = await confirm.open({
      name: `Unarchive ${plural.toLowerCase()}`,
      description: `You are about to unarchive ${length} ${(length === 1 ? singular : plural).toLowerCase()}, are you sure?`
    });

    if (!confirmed) return;

    const args: any = [
      {
        ids: toUpdate.map((item: any) => item.id),

        update: {
          archived: false
        } as Partial<any>
      }
    ];

    if (refs.parent.current) args.unshift(refs.parent.current?.id);

    const result = await refs.serviceUpdate.current[refs.updateManyName.current](...args);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `${pluralCapitalized} updated`
      });
    }

    // refresh 
    refresh();
  }, []);

  const onRemoveMany = React.useCallback(async (value: any = refs.service.current[refs.selectedItemsName.current].value!.objects) => {
    const values = is('array', value) ? value : [];

    const isEnabled = (item: any) => is('function', refs.isEnabledAction.current) ? refs.isEnabledAction.current!(item, 'remove') === true : true;

    const toUpdate = values.filter((item: any) => isEnabled(item));

    const length = toUpdate.length;

    if (!length) return;

    const confirmed = await confirm.open({
      name: `Remove ${plural.toLowerCase()}`,
      description: `You are about to remove ${length} ${(length === 1 ? singular : plural).toLowerCase()}, are you sure?`
    });

    if (!confirmed) return;

    const args: any = [
      {
        ids: toUpdate.map((item: any) => item.id)
      }
    ];

    if (refs.parent.current) args.unshift(refs.parent.current?.id);

    const result = await refs.serviceRemove.current[refs.removeManyName.current](...args);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `${pluralCapitalized} removed`
      });
    }

    // refresh 
    refresh();
  }, []);

  const onMakeCopy = React.useCallback(async (value: any) => {
    const object = copy(value);

    const toRemove = refs.toRemove.current;

    toRemove.forEach(item => delete object[item]);

    // update 
    if (object.name) object.name = capitalize(`${value.name || ''} copy`.trim());

    if (object.url) object.url = `${object.url}-${AmauiDate.milliseconds}`;

    const args: any = [
      object
    ];

    if (refs.parent.current) args.unshift(refs.parent.current?.id);

    const result = await refs.service.current[refs.addOneName.current](...args);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `${singularCapitalized} copied`
      });
    }

    // refresh 
    refresh();
  }, []);

  const onUpdateTags = React.useCallback((item?: any) => {
    const isEnabled = (item: any) => is('function', refs.isEnabledAction.current) ? refs.isEnabledAction.current!(item, 'tags') === true : true;

    const objects = [...(item ? [item] : refs.service.current[refs.selectedItemsName.current].value!.objects || [])].filter(Boolean).filter((item: any) => isEnabled(item));

    AppService.pages.addTertiary.emit({
      version: 'mid',
      open: true,
      title: 'Update tags',
      children: (
        <FormTags
          objects={objects}

          app={app}

          service={refs.service.current}

          onConfirm={() => refs.service.current[refs.queryItemsName.current].value!.refetch.bind(refs.service.current[refs.queryItemsName.current].value)(...refs.onConfirmProps.current)}

          singular='tag'

          plural='tags'
        />
      )
    });
  }, [app, singular, plural]);

  // const onUpdateProject = React.useCallback((item?: any) => {
  //   const isEnabled = (item: any) => is('function', refs.isEnabledAction.current) ? refs.isEnabledAction.current!(item, 'project') === true : true;

  //   const objects = [...(item ? [item] : refs.service.current[refs.selectedItemsName.current].value!.objects || [])].filter(Boolean).filter((item: any) => isEnabled(item));

  //   AppService.pages.addTertiary.emit({
  //     version: 'mid',
  //     open: true,
  //     title: 'Update project',
  //     children: (
  //       <FormProjectUpdate
  //         objects={objects}

  //         service={refs.service.current}

  //         onConfirm={() => {
  //           // refresh 
  //           refresh();
  //         }}

  //         singular='project'

  //         plural='projects'
  //       />
  //     )
  //   });
  // }, [singular, plural]);

  const onMoreMenuClose = React.useCallback(() => {
    setMoreMenu((previous: any) => ({
      ...previous,

      open: false
    }));
  }, []);

  const onContextMenuPrevent = React.useCallback((event: MouseEvent, values: any) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const onContextMenu = React.useCallback((event: MouseEvent, values: any) => {
    event.preventDefault();

    const x = event.pageX;

    const y = event.pageY;

    const target = event.target;

    setTimeout(() => {
      const anchor = {
        width: 14,
        height: 14,
        x,
        y
      };

      setMoreMenu({
        open: true,

        ...values,

        element: target,

        anchor
      });
    }, 140);
  }, []);

  const onImport = React.useCallback(async (files: any) => {
    if (!!files.length) {
      let valueImported: any = await fileToValue(files[0], 'text');

      if (valueImported) {
        valueImported = Try(() => parse(valueImported));

        // import only from this collection 
        if (valueImported?.objects === collection) {
          const values = valueImported?.values;

          if (is('array', values) && values.length) {
            const objects = values;

            setLoading(true);

            const result = await refs.service.current.addMany({ objects });

            if (result.status >= 400) {
              snackbars.add({
                color: 'error',
                primary: getErrorMessage(result)
              });
            }
            else {
              snackbars.add({
                primary: `${pluralCapitalized} imported`
              });
            }

            // refresh 
            refresh();

            setLoading(false);
          }
        }
      }
    }
  }, [collection]);

  const onExport = React.useCallback(async (objects_: any = refs.selectedItems.current?.objects) => {
    const objects = copy((is('array', objects_) ? objects_ : [objects_]).filter(Boolean));

    if (!objects.length) return;

    const toRemove = refs.toRemove.current;

    objects.forEach((object: any) => {
      toRemove.forEach(item => delete object[item]);
    });

    const valueExport = {
      objects: collection,
      date: AmauiDate.milliseconds,
      values: objects
    };

    download(`Export - ${plural} ${format(AmauiDate.amauiDate, formats.entire)}`, JSON.stringify(valueExport, null, 2), 'application/json');
  }, [collection]);

  const moreMenuItems = React.useMemo(() => {
    const IconProps: any = {
      color: 'inherit',
      size: 'small'
    };

    const isEnabled = (action: string) => is('function', refs.isEnabledAction.current) ? refs.isEnabledAction.current!({}, action) === true : true;

    const values: any = [
      ...(!noImport && isEnabled('import') && queryItems?.length ? [
        {
          primary: (
            <FileChoose
              version='b3'

              onChange={onImport}

              accept='application/json'

              start={null}

              Component='span'
            >
              Import
            </FileChoose>
          ),
          start: <IconMaterialUploadRounded {...IconProps} />,
          Component: 'label',

          menuCloseOnClick: false
        }
      ] : []),

      ...(isEnabled('export-selected') && selectedItems?.length ? [
        { primary: 'Export selected', onClick: () => onExport(refs.selectedItems.current?.objects), start: <IconMaterialDownloadRounded {...IconProps} /> }
      ] : []),

      ...(isEnabled('export-page') ? [
        { primary: 'Export page', onClick: () => onExport(refs.queryItems.current?.response), start: <IconMaterialDownloadRounded {...IconProps} /> }
      ] : [])
    ];

    return values.map((item_: any, index: number) => item_.entire !== undefined ? item_.entire : (
      <MenuItem
        key={index}

        {...menuItemProps(item_)}
      />
    ));
  }, [Form, queryItems?.response, selectedItems?.objects, getItemActions, optionsMethod, options, noImport, noOpen, noTags, noUpdateProject, noRemove]);

  const getMenuItems = React.useCallback((item: any) => {
    const IconProps: any = {
      color: 'inherit',
      size: 'small'
    };

    const isEnabled = (action: string) => is('function', refs.isEnabledAction.current) ? refs.isEnabledAction.current!(item, action) === true : true;

    const values: any = [
      ...(Form && !noOpen && isEnabled('open') ? [
        { primary: 'Open', onClick: () => onOpen(item), start: <IconMaterialOpenInNewRounded {...IconProps} /> }
      ] : []),

      ...((options.includes('default') && !item.default) && isEnabled('default') ? [
        { primary: 'Default', onClick: () => onDefault(item), start: <IconMaterialHomeRounded {...IconProps} /> }
      ] : []),

      ...((options.includes('main') && !item.main) && isEnabled('main') ? [
        { primary: 'Main', onClick: () => onMain(item), start: <IconMaterialHomeRounded {...IconProps} /> }
      ] : []),

      ...(options.includes('active') && isEnabled('active') ? [
        { primary: item.active ? 'Inactivate' : 'Activate', onClick: () => onActive(item), start: item.active ? <IconMaterialDomainVerificationOffRounded {...IconProps} /> : <IconMaterialDomainVerificationRounded {...IconProps} /> }
      ] : []),

      ...(options.includes('archived') && isEnabled('archive') ? [
        { primary: item.archived ? 'Unarchive' : 'Archive', onClick: () => onArchive(item), start: item.archived ? <IconMaterialUnarchiveRounded {...IconProps} /> : <IconMaterialArchiveRounded {...IconProps} /> }
      ] : []),

      ...(options.includes('amaui') && isEnabled('amaui') ? [
        { primary: item.amaui ? 'Remove amaui' : 'Add amaui', onClick: () => onAmaui(item), start: item.amaui ? <IconMaterialPanoramaFishEyeRounded {...IconProps} /> : <IconMaterialSunnyRounded {...IconProps} /> }
      ] : []),

      ...(pinned && isEnabled('pin') ? [
        { primary: item.pinned ? 'Unpin' : 'Pin', onClick: () => onPin(item), start: <IconMaterialPushPinRounded {...IconProps} /> }
      ] : []),

      ...(options.includes('copy') && isEnabled('copy') ? [
        { primary: 'Make a copy', onClick: () => onMakeCopy(item), start: <IconMaterialFileCopyRounded {...IconProps} /> }
      ] : []),

      ...(isEnabled('export') && !noExport ? [
        { primary: 'Export', onClick: () => onExport(item), start: <IconMaterialDownloadRounded {...IconProps} /> }
      ] : []),

      ...(is('function', getItemActions) ? getItemActions!(item, optionsMethod) : []),

      ...(!noTags && isEnabled('update-tags') ? [
        { primary: 'Update tags', onClick: () => onUpdateTags(item), start: <IconMaterialTagRounded {...IconProps} /> }
      ] : []),

      // ...(!noUpdateProject && isEnabled('update-project') ? [
      //   { primary: 'Update project', onClick: () => onUpdateProject(item), start: <IconMaterialLensBlurRounded {...IconProps} /> }
      // ] : []),

      ...(!noRemove && isEnabled('remove') ? [
        { primary: 'Remove', onClick: () => onRemove(item), start: <IconMaterialDeleteRounded {...IconProps} color='error' /> }
      ] : [])
    ];

    return values.map((item_: any, index: number) => (
      <MenuItem
        key={index}

        {...menuItemProps(item_)}
      />
    ));
  }, [Form, getItemActions, optionsMethod, options, noOpen, noTags, noUpdateProject, noExport, noRemove]);

  const manyActionsItems = React.useMemo(() => {
    const IconProps: any = {
      color: 'inherit',
      size: 'small'
    };

    const isEnabled = (item: any, action: string) => is('function', refs.isEnabledAction.current) ? refs.isEnabledAction.current!(item, action) === true : true;

    const enabled: any = {};

    selectedItems?.objects?.forEach((item: any) => {
      if (options.includes('active') && isEnabled(item, 'active')) {
        enabled.activate = enabled.activate || !item.active;
        enabled.inactivate = enabled.inactivate || item.active;
      }

      if (options.includes('archived') && isEnabled(item, 'archive')) {
        enabled.archive = enabled.archive || !item.archived;
        enabled.unarchive = enabled.unarchive || item.archived;
      }

      if (!noTags && isEnabled(item, 'tags')) {
        enabled.tags = true;
      }

      if (isEnabled(item, 'project')) {
        enabled.project = true;
      }

      if (isEnabled(item, 'remove')) {
        enabled.remove = true;
      }
    });

    const values: any = [
      ...(options.includes('active') ? [
        { primary: 'Activate', onClick: () => onActivateMany(), disabled: !enabled.activate, start: <IconMaterialDomainVerificationRounded {...IconProps} /> },
        { primary: 'Inactivate', onClick: () => onInActivateMany(), disabled: !enabled.inactivate, start: <IconMaterialDomainVerificationOffRounded {...IconProps} /> }
      ] : []),

      ...(options.includes('archived') ? [
        { primary: 'Archive', onClick: () => onArchiveMany(), disabled: !enabled.archive, start: <IconMaterialArchiveRounded {...IconProps} /> },
        { primary: 'Unarchive', onClick: () => onUnArchiveMany(), disabled: !enabled.unarchive, start: <IconMaterialUnarchiveRounded {...IconProps} /> }
      ] : []),

      ...(is('function', getManyActions) ? getManyActions!(optionsMethod) : []),

      ...(!noTags ? [
        { primary: 'Update tags', onClick: () => onUpdateTags(), disabled: !enabled.tags, start: <IconMaterialTagRounded {...IconProps} /> }
      ] : []),

      // ...(!noUpdateProject ? [
      //   { primary: 'Update project', onClick: () => onUpdateProject(), disabled: !enabled.project, start: <IconMaterialLensBlurRounded {...IconProps} /> }
      // ] : []),

      ...(!noRemove ? [
        { primary: 'Remove', onClick: () => onRemoveMany(), disabled: !enabled.remove, start: <IconMaterialDeleteRounded {...IconProps} color='error' /> }
      ] : [])
    ];

    return values.map((item_: any, index: number) => (
      <MenuItem
        key={index}

        {...menuItemProps(item_)}
      />
    ));
  }, [getManyActions, optionsMethod, options, selectedItems?.objects, noTags, noUpdateProject, noRemove]);

  const columns = React.useMemo(() => {
    const result: any = [];

    if (!noManage && !noSelect) {
      result.push({
        name: 'Select'
      });
    }

    // columns 
    const view = queryItemsViews?.response?.find((item: any) => item?.value?.selected);

    const getColumnName = (item: any) => ['amaui'].includes(item?.name || item) ? item?.name || item : cleanValue(item?.name || item);

    // view 
    if (view && view.value?.columns?.length) {
      // decode view columns 
      if (is('string', view.value?.columns)) view.value.columns = decodeObjectValue(view.value.columns) || [];

      view.value.columns.forEach((item: any) => {
        // method parse 
        // eslint-disable-next-line no-eval
        if (is('string', item.method)) item.method = eval(item.method);

        result.push({
          ...(is('object', item) && item),

          name: getColumnName(item)
        });
      });
    }
    // columns 
    else if (is('array', columns_)) {
      columns_.filter(Boolean).forEach((item: any) => {
        result.push({
          ...(is('object', item) && item),

          name: getColumnName(item)
        });
      });
    }

    // tags 
    if (!noTags) {
      result.push({
        name: 'Tags',
        property: 'tags'
      });
    }

    // other  
    if (!noAddedAt) result.push({
      name: 'Added at',
      property: isStripe ? 'created' : 'added_at'
    });

    if (!noManage) {
      result.push({
        name: 'Actions'
      });
    }

    return result;
  }, [hash(columns_), queryItemsViews?.response, properties, isStripe, noManage, noSelect, noTags, noAddedAt]);

  const getColumn = React.useCallback((item: any, index: number) => {
    const sticky = item.sticky;
    const stickyPosition = item.stickyPosition;
    const stickyOffset = item.stickyOffset;

    if (item.name === 'Select' && !noManage && !noSelect) {
      return (
        <TableCell
          key={index}

          className={classes.tableCellCheckbox}
        />
      );
    }

    const itemProps = { ...item?.props };

    if (item?.name === 'Added at') {
      itemProps.sort = refs.sort.current.added_at !== undefined;

      itemProps.sortedbyDefault = 'desc';

      itemProps.sortedBy = refs.sort.current.added_at === 1 ? 'asc' : 'desc';

      itemProps.onSort = (valueNew: any) => onSort('added_at', valueNew);

      itemProps.disabled = !!refs.loading.current;
    }

    return (
      <TableCell
        key={index}

        justify={!['Actions', 'Added at'].includes(item.name) ? 'flex-start' : 'flex-end'}

        sticky={sticky}

        stickyPosition={stickyPosition}

        stickyOffset={stickyOffset}

        style={{
          textAlign: !['Actions', 'Added at'].includes(item.name) ? 'start' : 'end',

          ...item.style
        }}

        {...itemProps}
      >
        {item?.name}
      </TableCell>
    );
  }, [queryItems?.response, queryItemsViews?.response, properties, noManage, noSelect]);

  const getCell = React.useCallback((column: any, item: any, index: number) => {
    const { name: nameColumn, property: propertyColumn, method, type } = column;

    const property = propertyColumn || Object.keys(properties).find(key => (properties as any)[key].name === nameColumn);

    const isCustom = is('function', method);

    const sticky = item.sticky;
    const stickyPosition = item.stickyPosition;
    const stickyOffset = item.stickyOffset;

    const selected = !!selectedItems?.isSelected(item);

    const first = (noManage || noSelect) ? !index : index === 1;

    const toOpen = first && ['name', 'user', 'device'].includes(nameColumn?.toLowerCase());

    const openProps = {
      onClick: () => onOpen(item),

      className: classes.name
    };

    const value = is('function', method) ? method(item, { ...optionsMethod, openProps }) : property && getObjectValue(item, property);

    let element = value;

    const menuItems = getMenuItems(item);

    // method 
    if (isCustom) element = (is('simple', value) && ![undefined, null].includes(value)) ? String(value) : value;
    // first  
    else if (toOpen) {
      element = (
        <Type
          version='l2'

          {...(Form && openProps)}

          dangerouslySetInnerHTML={{
            __html: textToInnerHTML(value)
          }}
        />
      );
    }
    // boolean 
    else if (
      type === 'boolean' ||
      booleans.includes(nameColumn?.toLowerCase())
    ) {
      element = (
        <Type
          version='b2'
        >
          {value ? 'Yes' : 'No'}
        </Type>
      );
    }
    // color 
    else if (
      type === 'color' ||
      nameColumn === 'Color'
    ) {
      element = value ? (
        <Color
          color={value}

          onClick={() => formSearch.onChange('color', [value])}
        />
      ) : null;
    }
    // link 
    else if (
      type === 'link' ||
      nameColumn?.toLowerCase()?.includes('url')
    ) {
      element = (
        <Link
          version='b2'

          href={value}

          target='blank'
        >
          {value}
        </Link>
      );
    }
    // date 
    else if (
      type === 'date' ||
      dates.includes(property?.toLowerCase())
    ) {
      element = (
        <Type
          version='b2'
        >
          {format(new AmauiDate(isStripe ? value * 1e3 : value), formats.entire)}
        </Type>
      );
    }
    // duration 
    else if (
      type === 'duration' ||
      ['read_time'].includes(property?.toLowerCase())
    ) {
      element = (
        <Type
          version='b2'
        >
          {duration(value)}
        </Type>
      );
    }
    // app 
    else if (['App'].includes(nameColumn)) {
      element = (
        <Type
          version='b2'
        >
          {appToName(value)}
        </Type>
      );
    }
    // apps 
    else if (['Apps'].includes(nameColumn)) {
      element = (
        <Type
          version='b2'
        >
          {value?.map((item: any) => appToName(item)).join(', ')}
        </Type>
      );
    }
    // tags 
    else if (nameColumn === 'Tags') {
      element = (
        <Tags
          form={refs.formSearch.current}

          value={value}
        />
      );
    }
    // menu 
    else if (nameColumn === 'Actions') {
      element = (
        <Menu
          alignment='center'

          onContextMenu={onContextMenuPrevent}

          menuItems={menuItems}

          ListProps={{
            color: 'themed',
            size: 'small',

            onContextMenu: onContextMenuPrevent,

            ...item?.props?.ListProps
          }}
        >
          <IconButton
            color='inherit'

            size='small'

            onContextMenu={onContextMenuPrevent}

            disabled={!menuItems?.length}

            {...item?.IconButtonProps}
          >
            <IconMaterialMoreVertRounded />
          </IconButton>
        </Menu>
      );
    }
    // select 
    else if (nameColumn === 'Select') {
      return (
        <TableCell
          className={classes.tableCellCheckbox}
        >
          <Checkbox
            checked={selected}

            onChange={() => selectedItems?.select(item, true)}

            size='small'
          />
        </TableCell>
      );
    }
    else {
      element = (
        <Type
          version='b2'

          dangerouslySetInnerHTML={{
            __html: textToInnerHTML(cleanValue(value))
          }}
        />
      );
    }

    return (
      <TableCell
        key={index}

        justify={!['Actions', 'Added at'].includes(nameColumn) ? 'flex-start' : 'flex-end'}

        sticky={sticky}

        stickyPosition={stickyPosition}

        stickyOffset={stickyOffset}

        TypeProps={{
          version: 'b2'
        }}

        style={{
          textAlign: !['Actions', 'Added at'].includes(nameColumn) ? 'start' : 'end',

          ...item.style
        }}
      >
        {element}
      </TableCell>
    );
  }, [queryItemsViews?.response, properties, Form, optionsMethod, selectedItems?.objects, columns, isStripe, noManage, noSelect]);

  const optionsTags = React.useMemo(() => {

    return !noTags ? queryItems?.meta?.options?.tags?.map((item: any) => {
      const palette = theme.methods.color(stringToColor(item.name));

      item.palette = palette;
      item.color = palette[50];

      return {
        ...item,

        value: item.id
      };
    }) : [];
  }, [noTags, queryItems?.response]);

  const usage = React.useMemo(() => {
    const item: any = itemFromCollection(collection!);

    return {
      used: (signedIn?.organizationPlan?.used as any)?.[item]?.total,
      provided: (signedIn?.organizationPlan?.provided as any)?.[item]?.total
    };
  }, [collection, signedIn]);

  const usageUI = !!(usage?.provided !== undefined && !['unlimited'].includes(usage.provided) && !noUsage) && (
    <Line
      direction='row'

      align='center'

      justify='flex-end'

      fullWidth

      className={classes.usage}
    >
      <Type
        version='b3'

        priority='secondary'

        align='center'
      >
        {numberWithCommas(usage.used || 0)} {(usage.used === 1 ? singular : plural).toLowerCase()} used out of {numberWithCommas(usage.provided)}
      </Type>
    </Line>
  );

  const response = queryItems?.response || [];
  const responsePinned = (queryItemsPinned as any)?.response || [];

  const responseAll = [...response, ...responsePinned];

  const pagination = queryItems?.pagination;

  const someSelected = selectedItems?.all || selectedItems?.length;

  const onPaginationChange = React.useCallback((value: number | 'previous' | 'next' | 'more') => {
    const body: any = {};

    // stripe
    if (isStripe) {
      if (value === 'more') body.starting_after = response?.slice(-1)?.[0]?.id;
    }
    // api
    else {
      if (is('number', value)) body.skip = clamp((((value as number) - 1) * pagination?.limit), 0);
      else if (value === 'previous') body.previous = pagination.previous;
      else if (value === 'next') body.next = pagination.next;
    }

    if (is('function', onPagination)) onPagination(body);
  }, [response, pagination, onPagination, isStripe]);

  const onOpenToggle = React.useCallback(() => {
    setOpen(previous => !previous);
  }, []);

  const menuItemsSelect = React.useMemo(() => {
    const values: any = [
      // {
      //   primary: 'Select all', onClick: () => {
      //     selectedItems?.selectMany(response);
      //     selectedItems?.selectAll();
      //   }
      // },
      { primary: 'Unselect all', disabled: !someSelected, onClick: () => selectedItems?.unselectAll() }
    ];

    return values.map((item_: any, index: number) => (
      <MenuItem
        key={index}

        {...menuItemProps(item_)}
      />
    ));
  }, [someSelected, selectedItems]);

  const hasAnyResponse = !!response?.length || !!responsePinned.length;

  const hasPagination = pagination;

  const hasPrimary = loaded;

  const limited = usage?.provided !== 'unlimited' && usage?.used >= usage?.provided;

  const formSearchHasValue = !equalDeep(formSearch.value, formSearchValueDefault);

  const checkboxAllProps: any = {};

  const pageChecked = responseAll?.filter((item: any) => selectedItems?.isSelected(item)) || [];

  const pageCheckedAll = pageChecked.length === responseAll?.length;

  const pageCheckedSome = !pageCheckedAll && !!pageChecked.length;

  if (selectedItems?.all || pageCheckedAll) {
    checkboxAllProps.checked = true;
  }
  else if (pageCheckedSome) {
    checkboxAllProps.indeterminate = true;
  }
  else {
    checkboxAllProps.checked = false;
    checkboxAllProps.indeterminate = false;
  }

  const filtersPrimary = React.useMemo(() => {
    return !noSearch && <>
      {filtersPrimaryStart}

      {formSearchHasValue && (
        <ClearSearch
          onClick={onClearSearch}
        />
      )}

      {!noTextSearch && (
        <TextSearch
          name='Search by text'

          placeholder={`My ${singular.toLowerCase()}`}

          value={formSearch.value.text || ''}

          onChange={(valueNew: string) => formSearch.onChange('text', valueNew)}

          helperText={formSearch.values.text.error}

          error={!!formSearch.values.text.error}
        />
      )}

      {filtersPrimaryEnd}
    </>;
  }, [formSearch, filtersPrimaryStart, filtersPrimaryEnd, noSearch, noTextSearch]);

  const getSelectOperatorCompare = React.useCallback((item: any) => {
    return (
      <Select
        valueDefault='$lte'

        value={refs.formSearchOperator.current.value.v?.[item]}

        onChange={(valueNew: any) => refs.formSearchOperator.current.onChange('v', valueNew, item)}

        options={[
          { name: '=', value: '$eq' },
          { name: '>=', value: '$gte' },
          { name: '<=', value: '$lte' }
        ]}

        minWidth={40}
      />
    );
  }, []);

  const numericTextFieldProps: any = {
    minWidth: 40,

    style: {
      width: 154
    }
  };

  const filtersSecondary = React.useMemo(() => {
    return !noSearch && !noFilters && <>
      <DateSearch
        name='Added at'

        value={(formSearch.value.added_at || []).filter(Boolean).map((item: number) => new AmauiDate(item))}

        onChange={(valueNew: [AmauiDate, AmauiDate]) => formSearch.onChange('added_at', valueNew.map(item => item?.milliseconds))}

        error={formSearch.values.added_at.error}

        helperText={formSearch.values.added_at.error}

        today
      />

      {/* text */}
      {search.filter((item: any) => is('object', item) && ['text'].includes(item.type)).map((item: any, index: number) => (
        <TextSearch
          key={item.name + index}

          name={`Search by ${item.name?.toLowerCase()}`}

          value={formSearch.value[item.property] !== undefined ? formSearch.value[item.property] : ''}

          onChange={(valueNew: any) => formSearch.onChange(item.property, valueNew)}

          {...item.props}
        />
      ))}

      {/* select  */}
      {search.filter((item: any) => is('object', item) && ['select', undefined].includes(item.type)).map((item: any, index: number) => (
        <Select
          key={item.name + index}

          name={item.name}

          value={formSearch.value[item.property] !== undefined ? formSearch.value[item.property] : ''}

          onChange={(valueNew: any) => formSearch.onChange(item.property, valueNew)}

          options={item.options}

          {...item.props}
        />
      ))}

      {/* auto complete objects */}
      {search.filter((item: any) => is('object', item) && ['auto-complete-objects'].includes(item.type)).map((item: any, index: number) => (
        <AutoCompleteObjects
          key={item.name + index}

          name={item.name}

          value={formSearch.value[item.property]}

          onChange={(valueNew: any) => formSearch.onChange(item.property, valueNew)}

          chip

          {...item.props}
        />
      ))}

      {/* select country */}
      {search.filter((item: any) => is('object', item) && ['select-country'].includes(item.type)).map((item: any, index: number) => {
        const valueOption = formSearch.value[item.property];

        return (
          <AutoCompleteCountry
            key={item.name + index}

            value={valueOption?.value}

            onChange={(valueNew: any) => formSearch.onChange(item.property, valueNew?.value)}

            size='small'
          />
        );
      })}

      {/* date */}
      {search.filter((item: any) => is('object', item) && ['date'].includes(item.type)).map((item: any, index: number) => (
        <DateSearch
          key={item.name + index}

          name={`Search by ${item.name?.toLowerCase()}`}

          value={(formSearch.value[item.property] !== undefined ? formSearch.value[item.property] : []).filter(Boolean).map((item: number) => new AmauiDate(item))}

          onChange={(valueNew: [AmauiDate, AmauiDate]) => formSearch.onChange(item.property, valueNew.map(item => item?.milliseconds))}

          today
        />
      ))}

      {search?.includes('active') && (
        <Select
          name='Active'

          value={formSearch.value.active}

          onChange={(valueNew: any) => formSearch.onChange('active', valueNew)}

          options={[
            { name: 'All', value: 'all' },
            { name: 'Active', value: true },
            { name: 'Inactive', value: false }
          ]}
        />
      )}

      {search?.includes('archived') && (
        <Select
          name='Archived'

          value={formSearch.value.archived}

          onChange={(valueNew: any) => formSearch.onChange('archived', valueNew)}

          options={[
            { name: 'All', value: 'all' },
            { name: 'Archived', value: true },
            { name: 'Unarchived', value: false }
          ]}
        />
      )}

      {search?.includes('amaui') && (
        <Select
          name='amaui'

          value={formSearch.value.amaui}

          onChange={(valueNew: any) => formSearch.onChange('amaui', valueNew)}

          options={[
            { name: 'All', value: 'all' },
            { name: 'amaui', value: true },
            { name: 'No amaui', value: false }
          ]}
        />
      )}

      {search?.includes('color') && (
        <SelectAColor
          value={formSearch.value.color || []}

          onChange={(valueNew: any) => formSearch.onChange('color', valueNew)}

          multiple

          noAny
        />
      )}

      {search?.includes('properties_calories') && (
        <Line
          gap={0.5}

          direction='row'

          align='center'
        >
          <NumericTextField
            name='Calories'

            value={formSearch.value.properties_calories || []}

            onChange={(valueNew: any) => formSearch.onChange('properties_calories', valueNew)}

            min={0}

            {...numericTextFieldProps}
          />

          {getSelectOperatorCompare('properties_calories')}
        </Line>
      )}

      {search?.includes('properties_protein') && (
        <Line
          gap={0.5}

          direction='row'

          align='center'
        >
          <NumericTextField
            name='Protein'

            value={formSearch.value.properties_protein || []}

            onChange={(valueNew: any) => formSearch.onChange('properties_protein', valueNew)}

            min={0}

            {...numericTextFieldProps}
          />

          {getSelectOperatorCompare('properties_protein')}
        </Line>
      )}

      {search?.includes('properties_fat') && (
        <Line
          gap={0.5}

          direction='row'

          align='center'
        >
          <NumericTextField
            name='Fat'

            value={formSearch.value.properties_fat || []}

            onChange={(valueNew: any) => formSearch.onChange('properties_fat', valueNew)}

            min={0}

            {...numericTextFieldProps}
          />

          {getSelectOperatorCompare('properties_fat')}
        </Line>
      )}

      {search?.includes('properties_carbohydrates') && (
        <Line
          gap={0.5}

          direction='row'

          align='center'
        >
          <NumericTextField
            name='Carbohydrates'

            value={formSearch.value.properties_carbohydrates || []}

            onChange={(valueNew: any) => formSearch.onChange('properties_carbohydrates', valueNew)}

            min={0}

            {...numericTextFieldProps}

            style={{
              width: 184
            }}
          />

          {getSelectOperatorCompare('properties_carbohydrates')}
        </Line>
      )}

      {search?.includes('properties_gender') && (
        <Select
          name='Gender'

          value={formSearch.value.properties_gender}

          onChange={(valueNew: any) => formSearch.onChange('properties_gender', valueNew)}

          options={[
            { name: 'All', value: 'all' },
            { name: 'Male', value: 'male' },
            { name: 'Female', value: 'female' },
            { name: 'Other', value: 'other' }
          ]}
        />
      )}

      {search?.includes('properties_age_from') && (
        <NumericTextField
          name='Age from'

          value={formSearch.value.properties_age_from || []}

          onChange={(valueNew: any) => formSearch.onChange('properties_age_from', valueNew)}

          min={0}

          {...numericTextFieldProps}
        />
      )}

      {search?.includes('properties_age_to') && (
        <NumericTextField
          name='Age to'

          value={formSearch.value.properties_age_to || []}

          onChange={(valueNew: any) => formSearch.onChange('properties_age_to', valueNew)}

          min={0}

          {...numericTextFieldProps}
        />
      )}

      {search?.includes('properties_weight_from') && (
        <NumericTextField
          name='Weight from'

          value={formSearch.value.properties_weight_from || []}

          onChange={(valueNew: any) => formSearch.onChange('properties_weight_from', valueNew)}

          min={0}

          {...numericTextFieldProps}
        />
      )}

      {search?.includes('properties_weight_to') && (
        <NumericTextField
          name='Weight to'

          value={formSearch.value.properties_weight_to || []}

          onChange={(valueNew: any) => formSearch.onChange('properties_weight_to', valueNew)}

          min={0}

          {...numericTextFieldProps}
        />
      )}

      {search?.includes('properties_height_from') && (
        <NumericTextField
          name='Height from'

          value={formSearch.value.properties_height_from || []}

          onChange={(valueNew: any) => formSearch.onChange('properties_height_from', valueNew)}

          min={0}

          {...numericTextFieldProps}
        />
      )}

      {search?.includes('properties_height_to') && (
        <NumericTextField
          name='Height to'

          value={formSearch.value.properties_height_to || []}

          onChange={(valueNew: any) => formSearch.onChange('properties_height_to', valueNew)}

          min={0}

          {...numericTextFieldProps}
        />
      )}

      {search?.includes('apps') && (
        <Select
          name='App'

          value={formSearch.value.apps}

          onChange={(valueNew: any) => formSearch.onChange('apps', valueNew)}

          options={[
            { name: 'All', value: 'all' },

            ...APP.map((item: any) => ({ name: appToName(item), value: item }))
          ]}
        />
      )}

      {!noTags && !!optionsTags?.length && (
        <AutoCompleteSearch
          name='Tags'

          value={formSearch.value.tags || []}

          onChange={(valueNew: any[]) => formSearch.onChange('tags', valueNew)}

          renderOption={(item: any, index: number, props: any) => (
            <ListItem
              key={index}

              {...props}

              primary={(
                <Chip
                  color={item.color}

                  size='small'

                  style={{
                    color: item.color,
                    boxShadow: `${item.color} 0px 0px 0px 1px inset`,
                    alignSelf: 'flex-start'
                  }}
                >
                  {item.name}
                </Chip>
              )}
            />
          )}

          renderChip={(item: any, valueChip: any, propsChip: any) => {
            return (
              <Chip
                {...propsChip}

                color={item?.color}

                size='small'

                style={{
                  alignSelf: 'flex-start'
                }}
              >
                {valueChip}
              </Chip>
            );
          }}

          options={optionsTags}

          clearInputOnSelect

          closeOnSelect

          multiple

          chip
        />
      )}
    </>
  }, [optionsTags, search, formSearch, noSearch, noFilters, noTags]);

  const ui = {
    selectAll: (
      !noSelect && hasAnyResponse && <>
        <Checkbox
          size='small'

          onChange={() => {
            if (selectedItems) {
              if (pageCheckedAll || pageCheckedSome) selectedItems.unselectMany(responseAll);
              else selectedItems.selectMany(responseAll, false);
            }
          }}

          {...checkboxAllProps}
        />

        <Menu
          menuItems={menuItemsSelect}

          ListProps={{
            size: 'small',

            style: {
              maxHeight: 240,
              overflowY: 'auto'
            }
          }}
        >
          <IconButton
            size='small'
          >
            <IconMaterialExpandMoreRounded />
          </IconButton>
        </Menu>

        {(selectedItems?.all || !!selectedItems?.length) && (
          <Type
            version='b3'

            priority='secondary'

            style={{
              marginInlineStart: 8
            }}
          >
            {selectedItems?.all ? 'All' : selectedItems?.length} selected
          </Type>
        )}
      </>
    ),

    actions: !noActions && <>
      {getMoreActions?.(optionsMethod)}

      {hasAnyResponse && <>
        {!!manyActionsItems?.length && (
          <Menu
            menuItems={manyActionsItems}

            ListProps={{
              size: 'small'
            }}
          >
            <Chip
              version='text'

              size='small'

              disabled={!someSelected}
            >
              <IconMaterialExpandMoreRounded
                size='small'
              />

              Actions
            </Chip>
          </Menu>
        )}
      </>}

      {!noViews && (
        <Views
          model={model}

          properties={properties}
        />
      )}

      <Menu
        menuItems={moreMenuItems}

        ListProps={{
          size: 'small'
        }}
      >
        <span>
          <Tooltip
            name='More'
          >
            <IconButton
              size='small'
            >
              <IconMaterialMoreVertRounded
                size='small'
              />
            </IconButton>
          </Tooltip>
        </span>
      </Menu>
    </>
  }

  const optionsMethodItem: IPageItemMethodOptions = React.useMemo(() => {
    return {
      queryItems,
      queryItemsPinned: queryItemsPinned as any,

      selectedItems,

      getMenuItems,

      props: (item: any) => ({
        onContextMenu: (event: MouseEvent) => additionalMenu && onContextMenu(event, { object: item, items: getMenuItems?.(item) })
      }),

      formSearch: refs.formSearch.current,

      loading,
      setLoading,

      onOpen,
      onClose,
      onDefault,
      onMain,
      onActive,
      onArchive,
      onPin,
      onRemove
    };
  }, [queryItems?.response, (queryItemsPinned as any)?.response, selectedItems, loading, onOpen, onClose]);

  const elements: any = {
    primary: hasPrimary && (
      <Line
        gap={1}

        direction={{
          xxs: 'column',
          xs: 'column',
          sm: 'column',
          default: 'row'
        }}

        align={{
          xxs: 'unset',
          xs: 'unset',
          sm: 'unset',
          default: 'flex-end'
        }}

        justify={{
          xxs: 'flex-end',
          xs: 'flex-end',
          sm: 'flex-end',
          default: 'space-between'
        }}

        fullWidth
      >
        {hasPagination ? (
          <Line
            gap={1}

            direction='column'

            align='flex-start'
          >
            <Line
              gap={2}

              direction='row'

              align='center'
            >
              {!noTotal && (
                <Type
                  version='t3'
                >
                  {pagination?.total || 0} {(pagination?.total === 1 ? singular : plural).toLowerCase() || 'results'} found
                </Type>
              )}
            </Line>

            {isStripe && (
              <Button
                onClick={() => onPaginationChange('more')}

                version='text'

                size='small'

                disabled={!!(!pagination?.has_more || loading)}
              >
                Load more
              </Button>
            )}

            {!isStripe && <>
              {paginationSimple ? (
                <Line
                  gap={1}

                  direction='row'

                  align='center'
                >
                  <IconButton
                    onClick={() => onPaginationChange('previous')}

                    size='small'

                    disabled={!!(!pagination?.hasPrevious || loading)}
                  >
                    <IconMaterialNavigateBeforeRounded />
                  </IconButton>

                  <IconButton
                    onClick={() => onPaginationChange('next')}

                    size='small'

                    disabled={!!(!pagination?.hasNext || loading)}
                  >
                    <IconMaterialNavigateNextRounded />
                  </IconButton>
                </Line>
              ) : (
                <Pagination
                  total={pagination?.total ? Math.ceil((pagination?.total || 0) / pagination?.limit) : undefined}

                  value={pagination?.total ? (((pagination?.skip || 0) / (pagination?.limit || 1)) + 1) : 1}

                  onChange={onPaginationChange}

                  first

                  last

                  size='small'

                  disabled={!!(loading || !pagination?.total)}
                />
              )}
            </>}
          </Line>
        ) : <span />}

        {loaded && (
          <Line
            gap={1}

            direction={{
              xxs: 'column-reverse',
              xs: 'column-reverse',
              default: 'row'
            }}

            align={{
              xxs: 'unset',
              xs: 'unset',
              default: 'center'
            }}

            justify='flex-end'

            fullWidth={{
              xxs: true,
              xs: true
            }}
          >
            {filtersPrimary}

            <Line
              gap={1}

              direction={{
                default: 'row'
              }}

              align={{
                default: 'center'
              }}

              justify={{
                xxs: 'flex-end',
                xs: 'flex-end',
                default: 'flex-start'
              }}

              fullWidth={{
                xxs: true,
                xs: true,
                default: false
              }}
            >
              {!noSearch && !noSavedFilters && (
                <SavedFilters
                  model={model}

                  query={formSearch.value}

                  onChange={onChangeFilter}
                />
              )}

              {filtersSecondary && (
                <Chip
                  version='text'

                  color='inherit'

                  onClick={onOpenToggle}

                  selected={open}

                  size='small'
                >
                  <IconMaterialFilterListRounded /> Filters
                </Chip>
              )}
            </Line>

            {addButtonStart}

            {limited && !(signedIn.user.is.amaui || signedIn.organization.amaui) && (
              <Limited
                name={plural.toLowerCase()}

                total={usage?.provided || 0}
              />
            )}

            {((signedIn.user.is.amaui || signedIn.organization.amaui) || !limited) && !noAdd && (
              <Line
                direction='row'

                justify='flex-end'

                flex
              >
                <Button
                  color='primary'

                  version='filled'

                  size='small'

                  onClick={onAdd}
                >
                  {onAddName || `Add ${singular.toLowerCase()}`}
                </Button>
              </Line>
            )}

            {addButtonEnd}
          </Line>
        )}
      </Line>
    ),

    filters: filtersSecondary && (
      <Line
        ref={refs.parentFilters}

        align='unset'

        fullWidth
      >
        <Expand
          in={open}

          parent={refs.parentFilters.current}

          removeOnExited={false}
        >
          <Line
            gap={1}

            direction={{
              xxs: 'column',
              xs: 'column',
              default: 'row'
            }}

            wrap='wrap'

            align={{
              xxs: 'flex-start',
              xs: 'flex-start',
              default: 'center'
            }}

            fullWidth

            style={{
              paddingTop: 8
            }}
          >
            {filtersSecondary}
          </Line>
        </Expand>
      </Line>
    ),

    select: !noManage && (ui.selectAll && ui.actions) && (
      <Line
        gap={1}

        direction={{
          xxs: 'column',
          xs: 'column',
          default: 'row'
        }}

        wrap='wrap'

        align={{
          xxs: 'flex-start',
          xs: 'flex-start',
          default: 'center'
        }}

        justify={{
          xxs: 'flex-start',
          xs: 'flex-start',
          default: 'space-between'
        }}

        fullWidth

        style={{
          paddingTop: 8
        }}
      >
        <Line
          gap={0}

          direction='row'

          align='center'

          className={classes.actions}
        >
          {ui.selectAll}
        </Line>

        <Line
          gap={0}

          direction='row'

          align='center'

          className={classes.actions}
        >
          {ui.actions}
        </Line>
      </Line>
    ),

    items: <>
      {loaded && (is('function', getItems) ? getItems!(optionsMethodItem) : <> {(loading || hasAnyResponse) &&
        <Table
          color='primary'

          size='small'

          header={name && (
            <TableHeader
              className={classes.tableHeader}
            >
              <Type
                version='t2'
              >
                {name}
              </Type>
            </TableHeader>
          )}

          className={classes.table}
        >
          {/* head  */}
          <TableHead
            loading={loading as any}

            className={classes.tableHead}

            {...TableHeadProps}
          >
            <TableRow
              className={classes.tableRow}
            >
              {columns?.map((item: any, index: number) => getColumn(item, index))}
            </TableRow>
          </TableHead>

          {/* body  */}
          <TableBody
            loading={loading as any}

            className={classes.tableBody}
          >
            {response?.map((item: any, index: number) => {
              const selected = !!selectedItems?.isSelected(item);

              return (
                <TableRow
                  key={index}

                  selected={selected}

                  onContextMenu={(event: MouseEvent) => additionalMenu && onContextMenu(event, { object: item, items: getMenuItems?.(item) })}

                  {...item.props}

                  className={classNames([
                    item.props?.className,
                    classes.tableRow
                  ])}
                >
                  {columns?.map((column: any, indexColumn: number) => getCell(column, item, indexColumn))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      }</>)}

      {/* no results */}
      {!loading && loaded && !getItems && !hasAnyResponse && <NoResults />}
    </>
  };

  return <>
    <Helmet>
      <title>{name}</title>
      <link rel='icon' type='image/svg' sizes='32x32' href={`/assets/svg/logos/${theme.palette.light ? 'light' : 'dark'}/logo-${app}.svg`} />
      <link rel='icon' type='image/svg' sizes='16x16' href={`/assets/svg/logos/${theme.palette.light ? 'light' : 'dark'}/logo-${app}.svg`} />
      <meta name='theme-color' content={theme.palette.light ? (theme.palette.color[app as any] as any).main : theme.palette.color[app][20]} />
    </Helmet>

    <Line
      ref={ref}

      gap={1.4}

      direction='column'

      justify='unset'

      align='unset'

      flex

      fullWidth

      className={classNames([
        'amaui-app-Page-root',

        className,
        classes.root,
        classes.wrapper
      ])}

      {...other}
    >
      {/* meta */}
      {loaded && usageUI}

      {loaded && (elements.primary || elements.filters || elements.select) && (
        <Line
          gap={0}

          align='unset'

          justify='unset'

          fullWidth
        >
          {/* primary */}
          {elements.primary}

          {/* secondary */}
          {elements.filters}

          {elements.select}
        </Line>
      )}

      {/* items */}
      {elements.items && <>
        {!loaded && getItems && !noLinearProgress && <LinearProgress />}

        {elements.items}
      </>}
    </Line>

    {/* more menu */}
    {!noManage && (
      <MoreMenu
        open={!!moreMenu?.open}

        onClose={onMoreMenuClose}

        anchor={moreMenu?.anchor}

        element={moreMenu?.element}

        items={moreMenu?.items}
      />
    )}
  </>;
});

export default Page; 
