import AmauiRequest from '@amaui/request';
import { AmauiRequestResponse, IOptions } from '@amaui/request/AmauiRequest';

import config from 'config';

export class RequestService {
  public amauiRequest: AmauiRequest;

  public constructor() {
    this.amauiRequest = new AmauiRequest({
      request: {
        // urlStart: config.value.apps.api.url
      },

      response: {
        resolveOnError: true
      }
    });

    // interceptors
    this.amauiRequest.interceptors.request.pre.subscribe((value: any) => {
      // Add amaui token
      // to authorization
      if (config.value.amaui_token) value.request.headers['Authorization'] = `amaui ${config.value.amaui_token}`;
    });
  }

  public get(url: string, options?: IOptions): Promise<AmauiRequestResponse> {
    return this.amauiRequest.get(url, options);
  }

  public post(url: string, body: any, options?: IOptions): Promise<AmauiRequestResponse> {
    return this.amauiRequest.post(url, body, options);
  }

  public put(url: string, body: any, options?: IOptions): Promise<AmauiRequestResponse> {
    return this.amauiRequest.put(url, body, options);
  }

  public delete(url: string, options?: IOptions): Promise<AmauiRequestResponse> {
    return this.amauiRequest.delete(url, options);
  }

}

export default new RequestService();
