import React from 'react';

import { is, textToInnerHTML } from '@amaui/utils';
import { FormRow, IconButton, Label, Line, ListItem, SmartTextField, Switch, Tooltip, Type, useForm, useSnackbars } from '@amaui/ui-react';
import { Contact as ContactModel } from '@amaui/api-utils';

import IconMaterialRemoveRounded from '@amaui/icons-material-rounded-react/IconMaterialRemove';

import { AutoCompleteObjects, Avatar, List, ModalForm, ReadProperties, SelectAColor, TextField } from 'ui';
import { AppService, ContactService, ContactGroupService } from 'services';
import { getErrorMessage } from 'other';

const Element = React.forwardRef((props: any) => {
  const {
    object: object_,

    onConfirm
  } = props;

  const snackbars = useSnackbars();

  const [object, setObject] = React.useState(object_);
  const [loading, setLoading] = React.useState<any>(false);

  const form = useForm({
    values: {
      'name': {
        name: 'Name',
        value: object?.name,
        required: true,
        max: 1400,
        messages: {
          min: 'Name has to be min 1 characters',
          max: 'Name can be max 1400 characters'
        }
      },
      'description': {
        name: 'Description',
        value: object?.description,
        max: 4400,
        messages: {
          min: 'Description has to be min 1 characters',
          max: 'Description can be max 4400 characters'
        }
      },
      'contacts': {
        name: 'Contacts',
        value: object?.contacts,
        is: 'array',
        of: 'object'
      },
      'color': {
        name: 'Color',
        value: object?.color || null,
      },
      'active': {
        name: 'Active',
        value: object?.active !== undefined ? object.active : true,
        is: 'boolean'
      },
      'archived': {
        name: 'Archived',
        value: object?.archived,
        is: 'boolean'
      }
    }
  });

  const refs = {
    form: React.useRef(form)
  };

  refs.form.current = form;

  const onClose = React.useCallback(() => {
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });
  }, []);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();
  }, []);

  const onNext = React.useCallback(async () => {
    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const body = {
      ...refs.form.current.value,

      apps: ['contact']
    };

    if (is('array', body.contacts)) body.contacts = body.contacts.map((item: any) => ({
      id: item.id,
      name: item.name
    }));

    const result = !object?.id ? await ContactGroupService.add(body) : await ContactGroupService.update(object?.id, body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Contact group ${!object?.id ? 'added' : 'updated'}`
      });

      setObject(result.response.response);

      if (is('function', onConfirm)) onConfirm();

      if (!object?.id) onClose();
    }

    setLoading(false);
  }, [object, form, onConfirm, onClose]);

  const onAddContact = React.useCallback((contact: ContactModel) => {
    const exists = form.values.contacts.value?.find((item: any) => item.id === contact.id);

    if (!exists) {
      const valueNew = [...(form.values.contacts.value || [])];

      valueNew.push(contact);

      form.onChange('contacts', valueNew);
    }
  }, [form]);

  const onRemoveContact = React.useCallback((index: number) => {
    const valueNew = [...(form.values.contacts.value || [])];

    valueNew.splice(index, 1);

    form.onChange('contacts', valueNew);
  }, [form]);

  const modal: any = {
    read: (
      <Line
        fullWidth
      >
        <ReadProperties
          object={object}

          values={[
            'color',
            'description',

            'contacts',

            'active',
            'archived'
          ]}
        />
      </Line>
    ),

    write: <>
      <FormRow
        gap={2}

        fullWidth
      >
        <Line
          gap={1}

          fullWidth
        >
          <TextField
            name='Name'

            valueDefault={textToInnerHTML(form.values['name'].value)}

            onChange={(valueNew: any) => form.onChange('name', valueNew, undefined, { rerenderOnUpdate: false })}
          />

          <SmartTextField
            placeholder='Description'

            valueDefault={textToInnerHTML(form.values['description'].value || '')}

            onChange={(valueNew: any) => form.onChange('description', valueNew, undefined, { rerenderOnUpdate: false })}

            minRows={4}

            multiline

            edit
          />

          <SelectAColor
            valueDefault={form.values['color'].value}

            error={!!form.values['color'].error}

            helperText={form.values['color'].error}

            onChange={(valueNew: any) => form.onChange('color', valueNew, undefined)}
          />
        </Line>

        <Line
          gap={0}

          fullWidth
        >
          <AutoCompleteObjects
            name='Contacts'

            value={refs.form.current.values.contacts.value}

            onChange={onAddContact}

            service={ContactService}
          />

          {!!form.values.contacts.value?.length && (
            <List>
              {form.values.contacts.value?.map((item: any, index: number) => (
                <ListItem
                  startAlign='center'

                  start={(
                    <Avatar
                      tonal

                      color='secondary'

                      size='small'
                    >
                      {item?.name?.slice(0, 1)}
                    </Avatar>
                  )}

                  primary={(
                    <Type
                      version='b2'
                    >
                      {item.name}
                    </Type>
                  )}

                  end={(
                    <Tooltip
                      name='Remove'

                      color='secondary'
                    >
                      <IconButton
                        onClick={() => onRemoveContact(index)}
                      >
                        <IconMaterialRemoveRounded />
                      </IconButton>
                    </Tooltip>
                  )}

                  size='small'
                />
              ))}
            </List>
          )}
        </Line>
      </FormRow>
    </>,

    more: <>
      <Label
        checked={form.values.active?.value}

        onChange={(valueNew: boolean) => form.onChange('active', valueNew)}

        error={!!form.values.active.error}

        helperText={form.values.active.error}
      >
        <Switch />

        Active
      </Label>

      <Label
        checked={form.values.archived?.value}

        onChange={(valueNew: boolean) => form.onChange('archived', valueNew)}

        error={!!form.values.archived.error}

        helperText={form.values.archived.error}
      >
        <Switch />

        Archived
      </Label>
    </>
  };

  return (
    <ModalForm
      {...props}

      object={object}

      add={!object}

      {...modal}

      onSubmit={onSubmit}

      onNext={onNext}

      onClose={onClose}

      loading={loading}

      smaller
    />
  );
});

export default Element;
