import React from 'react';

import { ImageListItem, Line, LinearProgress, Masonry } from '@amaui/ui-react';
import { IBaseElement } from '@amaui/ui-react/types';
import { style } from '@amaui/style-react';

import IconMaterialOpenInNewRounded from '@amaui/icons-material-rounded-react/IconMaterialOpenInNew';

import { Page } from 'ui';
import { MediaService } from 'services';
import { IPageItemMethodOptions } from 'ui/layout/Page';
import MediaItem from './MediaItem';
import { getMediaUrl } from 'other';

const useStyle = style(theme => ({
  root: {
    position: 'relative',
    overflow: 'unset !important'
  },

  imageList: {
    '&.amaui-ImageList-root': {
      '& .amaui-ImageListItem-root': {
        transition: theme.methods.transitions.make(['padding', 'box-shadow'], { duration: 'sm' }),

        // '&:active': {
        //   transform: 'scale(0.94)'
        // },
      }
    }
  },

  imageListItemSelected: {
    padding: '14px',
    boxShadow: `inset 0px 0px 0px 14px ${theme.methods.palette.color.value('primary', 80)}`
  },

  linearProgress: {
    '&.amaui-LinearProgress-root': {
      position: 'absolute',
      top: '0px',
      left: '0px',
      zIndex: 1
    }
  },

  table: {
    position: 'relative',
    minHeight: '100%',

    '& > .amaui-Masonry-root': {
      padding: 2
    }
  }
}), { name: 'amaui-app-route-MediaItems' });

export interface IMediaItems extends IBaseElement {
  options: IPageItemMethodOptions;

  MediaItemProps?: any;
}

export const MediaItems: React.FC<IMediaItems> = React.forwardRef((props) => {
  const {
    options,

    mention,

    MediaItemProps,

    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const {
    queryItems,

    selectedItems,

    loading,

    getMenuItems,

    props: itemPropsMethod
  } = options;

  if (!queryItems?.response?.length) return null;

  return (
    <Line
      align='unset'

      justify='unset'

      flex

      fullWidth

      className={classes.table}

      {...other}
    >
      {loading && (
        <LinearProgress
          className={classes.linearProgress}
        />
      )}

      {queryItems?.loaded && <>
        {!!queryItems?.response?.length && (
          <Masonry
            gap={1}

            columns={{
              xxs: 1,
              xs: 1,
              sm: 2,
              md: 3,
              lg: 4,
              default: 4
            }}

            NoMasonryProps={{
              flex: true,

              style: {
                overflow: 'hidden auto',
                height: 0
              }
            }}
          >
            {queryItems?.response.map((item: any, index: number) => {
              const itemProps = itemPropsMethod(item);
              const mediaItemProps = MediaItemProps ? MediaItemProps(item) : undefined;

              const selected = !!selectedItems.isSelected(item);

              return (
                <ImageListItem
                  key={index}

                  Component='div'
                >
                  <MediaItem
                    media={item}

                    menuItems={getMenuItems(item)}

                    selectedObjects={selectedItems}

                    SelectProps={{
                      checked: selected,
                      onChange: () => selectedItems.select(item)
                    }}

                    select

                    {...itemProps}

                    {...mediaItemProps}
                  />
                </ImageListItem>
              );
            })}
          </Masonry>
        )}
      </>}
    </Line>
  );
});

const Medias = React.forwardRef(() => {
  const getItems = React.useCallback((options: IPageItemMethodOptions) => {
    return (
      <MediaItems
        options={options}
      />
    );
  }, []);

  const IconProps: any = {
    color: 'inherit',
    size: 'small'
  };

  const properties = React.useMemo(() => ({
    name: { name: 'Name' },
    description: { name: 'Description' },
    mime: { name: 'Type' },
    origin: { name: 'Origin' }
  }), []);

  return (
    <Page
      name='Medias'

      service={MediaService}

      getItems={getItems}

      columns={[
        properties.name
      ]}

      properties={properties}

      search={[
        'apps',
        {
          name: 'Type',
          property: 'mime',
          default: 'all',
          validation: { name: 'Type', is: 'string', value: 'all' },
          options: [
            { name: 'All', value: 'all' },
            { name: 'Images', value: 'image' },
            { name: 'Audio', value: 'audio' },
            { name: 'Video', value: 'video' }
          ]
        },
        {
          name: 'Origin',
          property: 'origin',
          default: 'all',
          validation: { name: 'Origin', is: 'string', value: 'all' },
          options: [
            { name: 'All', value: 'all' },
            { name: 'Internal', value: 'internal' },
            { name: 'External', value: 'external' }
          ]
        }
      ]}

      isEnabledAction={(item, action) => {
        if (['copy', 'export'].includes(action)) return false;

        return true;
      }}

      getItemActions={(item: any, options: any) => [
        { primary: 'Open', onClick: () => window.open(getMediaUrl({ id: item?.id })), start: <IconMaterialOpenInNewRounded {...IconProps} /> }
      ]}

      app='amaui'

      collection='medias'

      route='/medias'

      noTags

      noUsage

      noAdd

      noUpdateProject
    />
  );
});

export default Medias;
