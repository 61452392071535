import React from 'react';
import { Helmet } from 'react-helmet';

import { debounce, innerHTMLToText, numberWithCommas, textToInnerHTML } from '@amaui/utils';
import { Button, Checkbox, IconButton, Line, ListItem, Menu, MenuItem, Tooltip, Type, useConfirm, useLocation, useMediaQuery, useSnackbars } from '@amaui/ui-react';
import { classNames, style, useAmauiTheme } from '@amaui/style-react';
import AmauiSubscription from '@amaui/subscription';
import { IList, ITask } from '@amaui/api-utils';

import IconMaterialDriveFileMoveOutlineRounded from '@amaui/icons-material-rounded-react/IconMaterialDriveFileMoveOutline';
import IconMaterialChevronLeftRounded from '@amaui/icons-material-rounded-react/IconMaterialChevronLeft';
import IconMaterialChevronRightRounded from '@amaui/icons-material-rounded-react/IconMaterialChevronRight';
import IconMaterialMoreVertRounded from '@amaui/icons-material-rounded-react/IconMaterialMoreVert';
import IconMaterialAddRounded from '@amaui/icons-material-rounded-react/IconMaterialAdd';
import IconMaterialDeleteRounded from '@amaui/icons-material-rounded-react/IconMaterialDelete';

import { NoResults, Select, SmartTextField, FormTask, useSubscription } from 'ui';
import { IQuerySubscription, ISignedIn, getErrorMessage, getParamID, menuItemProps } from 'other';
import { AppService, AuthService, ListService, TaskService } from 'services';

const useStyle = style(theme => ({
  root: {
    padding: '24px 24px 16px'
  },

  lists: {
    overflow: 'auto hidden'
  },

  listWrapper: {
    width: 270,
    height: '100%',
    flex: '0 0 auto'
  },

  list: {
    height: 0,
    paddingInline: 4,
    paddingBottom: 27,
    overflow: 'hidden auto',
    borderRadius: 16
  },

  task: {
    minHeight: 104,
    padding: 16,
    border: `1px solid transparent`,
    background: theme.palette.light ? theme.palette.background.default.primary : theme.palette.background.default.tertiary,
    borderRadius: 16,
    boxShadow: theme.shadows.values.default[1],
    transition: theme.methods.transitions.make('opacity'),
    cursor: 'pointer',
    userSelect: 'none'
  },

  taskDone: {
    opacity: 0.54
  },

  taskSelected: {
    border: `1px solid ${theme.palette.text.primary.primary}`
  },

  taskName: {
    wordBreak: 'break-word'
  },

  hidden: {
    opacity: 0,
    pointerEvents: 'none'
  }
}), { name: 'amaui-app-route-TasksLists' });

const TasksLists = React.forwardRef((props: any, ref: any) => {
  const {
    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const confirm = useConfirm();
  const snackbars = useSnackbars();
  const theme = useAmauiTheme();
  const location = useLocation();

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const queryListsTasks = useSubscription<IQuerySubscription>(ListService.queryListsTasks);

  const touch = useMediaQuery('(pointer: coarse)');

  const [tasks, setTasks] = React.useState<Record<string, IQuerySubscription>>({});
  const [tasksUnlisted, setTasksUnlisted] = React.useState<Record<string, IQuerySubscription>>({});
  const [loading, setLoading] = React.useState<any>(false);
  const [loaded, setLoaded] = React.useState(false);
  const [selected, setSelected] = React.useState<ITask>();
  const [, setUpdatedTask] = React.useState<any>();

  const refs = {
    queryListsTasks: React.useRef(queryListsTasks),
    lists: React.useRef<any[]>([]),
    tasks: React.useRef(tasks),
    tasksUnlisted: React.useRef(tasksUnlisted),
    opened: React.useRef<any>()
  };

  refs.queryListsTasks.current = queryListsTasks;

  refs.tasks.current = tasks;

  refs.tasksUnlisted.current = tasksUnlisted;

  React.useEffect(() => {
    const id = getParamID();

    if (id !== refs.opened.current?.id) onOpen(undefined, { id });
  }, [location]);

  const onRemove = React.useCallback(async (value: ITask) => {
    if (!(await confirm.open({ name: `Removing ${textToInnerHTML(value.name) || 'a'} task` }))) return;

    const result = await TaskService.remove(value.id);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });

      return true;
    }
    else {
      snackbars.add({
        primary: `Task removed`
      });
    }

    // reset
    TaskService.selectedTasks.value!.reset();

    // refresh
    TaskService.queryTasks.value!.refetch();
  }, []);

  const onOpen = React.useCallback(async (list: any = undefined, task_: ITask) => {
    const id = task_?.id;

    let object = id && list ? refs.tasks.current[list?.id]?.value.response?.find((item: ITask) => id === item.id) : null;

    if (id && !object) object = id && (await TaskService.get(id)).response?.response;

    if (!object) {
      refs.opened.current = null;

      onClose();

      return;
    }

    refs.opened.current = object;

    const refetch = () => {
      // refetch tasks for the list 
      if (list) refs.tasks.current[list?.id]?.value?.refetch(true);
      else refs.tasksUnlisted.current!.unlisted?.value?.refetch(true);
    };

    AppService.pages.add.emit({
      id: object.id,
      open: true,
      version: touch ? 'entire' : 'inline',
      route: {
        to: `/tasks/lists/${object.id}`,
        previous: `/tasks/lists`
      },
      children: (
        <FormTask
          key={object.id}

          object={object}

          onRemove={async () => {
            const removed = await onRemove(object);

            if (removed) refetch();
          }}

          onConfirm={refetch}

          modal={touch}
        />
      )
    });

    setSelected(object);
  }, [touch]);

  const onClose = React.useCallback(() => {
    // initial
    // unopened
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });

    const previous = '/tasks/lists';

    if (previous !== location.pathname) {
      window.history.pushState(undefined, '', previous);
    }
  }, [location]);

  const lists = React.useMemo(() => {
    const valueNew: any = refs.queryListsTasks.current?.response || [];

    valueNew.sort((a: any, b: any) => a.order - b.order);

    return valueNew;
  }, [(refs.queryListsTasks.current?.response || []).reduce((result: string, item: any) => result += item.id + item.order, '')]);

  refs.lists.current = lists;

  const initLists = React.useCallback(async () => {
    const lists_ = refs.lists.current;

    const object: Record<string, IQuerySubscription> = {};

    for (const list of lists_) {
      const subscription = new AmauiSubscription<IQuerySubscription>();

      TaskService.queryObjectsInit(subscription);

      object[list.id] = subscription as any;
    }

    // unlisted 
    const subscriptionUnlisted = new AmauiSubscription<IQuerySubscription>();

    TaskService.queryObjectsInit(subscriptionUnlisted);

    const tasksUnlisted_ = { unlisted: subscriptionUnlisted };

    await Promise.all([
      tasksUnlisted_.unlisted.value?.query({
        query: {
          query: {
            lists_empty: true
          }
        }
      }),

      ...lists_.map(list => (
        object[list.id].value.query({
          query: {
            query: {
              lists: [list.id]
            }
          }
        })
      ))
    ]);

    const id = getParamID();

    if (id) {
      const tasks_ = Object.values(object).flatMap(item => item.value.response || []);

      let exists = false;

      for (const listID of Object.keys(object)) {
        const task = tasks_.find((item: ITask) => id === item.id);

        if (task) {
          exists = true;

          onOpen({ id: listID }, task);
        }
      }

      if (!exists) {
        const task = (id && (await TaskService.get(id)).response?.response);

        if (task) onOpen({ id: '' }, task);
        else onClose();
      }
    }

    setTasksUnlisted(tasksUnlisted_ as any);

    setTasks(object);

    setLoaded(true);
  }, []);

  const init = React.useCallback(async () => {
    const result = await ListService.queryListsTasks.value!.query({
      query: {
        query: {
          model: 'tasks'
        }
      }
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
  }, []);

  React.useEffect(() => {
    // init 
    init();

    const subscription = TaskService.task.subscribe(item => {
      Object.values(refs.tasks.current).forEach((sub: any) => {
        sub.value.updateObject(item.id, item);
      });

      Object.values(refs.tasksUnlisted.current).forEach((sub: any) => {
        sub.value.updateObject(item.id, item);
      });

      setUpdatedTask(item);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const updatedLists = [...(refs.lists.current || [])].sort((a, b) => b.added_at - a.added_at).reduce((result, item: any) => result += item.id, '');

  React.useEffect(() => {
    // init lists 
    initLists();
  }, [updatedLists]);

  React.useEffect(() => {
    if (refs.tasks.current) {
      const listIDs = Object.keys(refs.tasks.current);

      for (const listID of listIDs) {
        const querySubscription = refs.tasks.current[listID];

        querySubscription.subscribe(() => {
          setTasks(previous => {
            const valueNewTasks = {
              ...previous,

              [listID]: querySubscription
            };

            return valueNewTasks;
          });
        });
      }
    }
  }, [Object.keys(tasks || {}).reduce((result, item) => result += item, '')]);

  React.useEffect(() => {
    if (refs.tasksUnlisted.current) {
      const querySubscription = refs.tasksUnlisted.current.unlisted;

      if (querySubscription) querySubscription.subscribe(() => {
        setTasksUnlisted(previous => {
          const valueNewTasks = {
            ...previous,

            unlisted: querySubscription
          };

          return valueNewTasks;
        });
      });
    }
  }, [Object.keys(tasksUnlisted || {}).reduce((result, item) => result += item, '')]);

  const onAddListTask = React.useCallback(async (list: any) => {
    setLoading(true);

    const result = await TaskService.add({
      name: innerHTMLToText('New task'),
      lists: [
        {
          id: list.id,
          name: list.name
        }
      ]
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      // refetch tasks for the list 
      refs.tasks.current[list.id]?.value?.refetch(true);
    }

    setLoading(false);
  }, []);

  const onUpdateListTaskDone = React.useCallback(async (list: IList, task: ITask) => {
    setLoading(true);

    const result = await TaskService.update(task.id, {
      done: !task.done
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      // refetch tasks for the list 
      if (list) refs.tasks.current[list.id]?.value?.refetch(true);
      else refs.tasksUnlisted.current!.unlisted?.value?.refetch(true);

      // update UpdateTask modal
      if (AppService.pages.add.value?.id === task.id) {
        const refetch = () => {
          // refetch tasks for the list 
          if (list) refs.tasks.current[list?.id]?.value?.refetch(true);
          else refs.tasksUnlisted.current!.unlisted?.value?.refetch(true);
        };

        AppService.pages.add.emit({
          ...AppService.pages.add.value,

          children: (
            <FormTask
              object={result.response.response}

              onRemove={async () => {
                const removed = await onRemove(task);

                if (removed) refetch();
              }}

              onConfirm={refetch}
            />
          )
        });
      }
    }

    setLoading(false);
  }, []);

  const onRemoveListTask = React.useCallback(async (list: IList, task: ITask) => {
    if (!(await confirm.open({ name: `Removing ${textToInnerHTML(list.name)} list's ${textToInnerHTML(task.name) || ''} task` }))) return;

    setLoading(true);

    const result = await TaskService.remove(task.id);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      // refetch tasks for the list 
      if (list) refs.tasks.current[list.id]?.value?.refetch(true);
      else refs.tasksUnlisted.current!.unlisted?.value?.refetch(true);

      // update UpdateTask modal
      if (AppService.pages.add.value?.id === task.id) {
        onClose();
      }
    }

    setLoading(false);
  }, []);

  const onMoveListTask = React.useCallback(async (listPrevious: IList, listNew_: IList, task: ITask) => {
    setLoading(true);

    const listNew = refs.lists.current.find(item => item.id === listNew_.id) || listNew_;

    const result = await TaskService.update(task.id, {
      lists: [
        {
          id: listNew.id,
          name: listNew.name
        }
      ]
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      // refetch tasks for the list 
      if (listPrevious) refs.tasks.current[listPrevious.id]?.value?.refetch(true);

      if (listNew) refs.tasks.current[listNew.id]?.value?.refetch(true);

      if (!listPrevious || !listNew) refs.tasksUnlisted.current!.unlisted?.value?.refetch(true);
    }

    setLoading(false);
  }, []);

  const onAddList = React.useCallback(async () => {
    const lists_ = refs.lists.current;

    setLoading(true);

    const result = await ListService.add({
      name: innerHTMLToText('New list'),
      order: Math.max(...lists_.map(item => item.order), 0) + 1,
      model: 'tasks'
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      // refetch
      ListService.queryListsTasks.value!.refetch(true);
    }

    setLoading(false);
  }, []);

  const onUpdateListName = React.useCallback(debounce(async (item: any, valueNew: string) => {
    const result = await ListService.update(item.id, {
      name: valueNew
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      // update 
      // in-memory  
      refs.queryListsTasks.current.updateObject(item.id, { name: valueNew });
    }
  }, 440), []);

  const onUpdateListsOrder = React.useCallback(async (item: any, itemOther: any) => {
    if (!(item && itemOther)) return;

    const result = await ListService.updateMany({
      objects: [
        { id: item.id, order: itemOther.order },
        { id: itemOther.id, order: item.order }
      ]
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      // refetch
      ListService.queryListsTasks.value!.refetch(true);
    }
  }, []);

  const onRemoveList = React.useCallback(async (item: any, include_objects = false) => {
    const confirmed = await confirm.open({
      title: include_objects ? 'Remove including tasks' : 'Remove',
      description: `Are you sure you want to remove the list? ${include_objects ? 'Tasks within it will also be removed' : 'Tasks within it will be tasks without a list'}.`
    });

    if (!confirmed) return;

    setLoading(true);

    const query: any = {};

    if (include_objects) query.remove_included_objects = true;

    const result = await ListService.remove(item.id, {
      query
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      // refetch
      ListService.queryListsTasks.value!.refetch(true);

      // refetch unlisted 
      refs.tasksUnlisted.current!.unlisted?.value?.refetch(true);
    }

    setLoading(false);
  }, []);

  const onStopPropagation = React.useCallback((event: MouseEvent) => {
    event.stopPropagation();
  }, []);

  const usage = {
    used: signedIn?.organizationPlan?.used?.task?.total,
    provided: signedIn?.organizationPlan?.provided?.task?.total
  };

  const usageUI = !!(usage?.used !== undefined && usage?.provided !== undefined && !['unlimited'].includes(usage.provided as any)) && (
    <Line
      direction='row'

      align='center'

      justify='flex-end'

      fullWidth

      className={classes.usage}
    >
      <Type
        version='b3'

        priority='secondary'

        align='center'
      >
        {numberWithCommas(usage.used)} tasks used out of {numberWithCommas(usage.provided)}
      </Type>
    </Line>
  );

  const onLoadMore = React.useCallback(async (queryAmauiSubscription: IQuerySubscription) => {
    setLoading('query');

    const result = await queryAmauiSubscription.value!.query({
      query: {
        ...queryAmauiSubscription.value!.previousQuery,

        next: queryAmauiSubscription.value?.pagination?.next,
        previous: undefined,
        skip: undefined,
        total: undefined,

        loadMore: true
      }
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }

    setLoading(false);
  }, []);

  const getMenuItemsTasks = (list: any, task: any, index: number) => {
    const IconProps: any = {
      color: 'inherit',
      size: 'small'
    };

    const values: any = [
      { primary: 'Remove', onClick: () => onRemoveListTask(list, task), start: <IconMaterialDeleteRounded {...IconProps} color='error' /> }
    ];

    const items = [
      <ListItem
        key='list'

        start={(
          <IconMaterialDriveFileMoveOutlineRounded
            {...IconProps}
          />
        )}

        startAlign='center'

        primary={(
          <Select
            name='Move to list'

            onChange={(valueNew: string) => onMoveListTask(list, { id: valueNew }, task)}

            fullWidth

            disabled={loading}
          >
            {refs.lists.current.filter(item => item.id !== list?.id).map((item: any, indexList: number) => (
              <ListItem
                key={indexList}

                primary={(
                  <Type
                    version='b3'

                    dangerouslySetInnerHTML={{
                      __html: textToInnerHTML(item.name)
                    }}
                  />
                )}

                value={item.id}

                button
              />
            ))}
          </Select>
        )}
      />
    ];

    items.push(...values.map((item_: any, index: number) => (
      <MenuItem
        key={index}

        {...menuItemProps(item_)}
      />
    )));

    return items;
  };

  const getMenuItems = (item: any, index: number) => {
    const IconProps: any = {
      color: 'inherit',
      size: 'small'
    };

    const values: any = [
      { primary: 'Remove including tasks', onClick: () => onRemoveList(item, true), start: <IconMaterialDeleteRounded {...IconProps} color='error' /> },

      { primary: 'Remove', onClick: () => onRemoveList(item), start: <IconMaterialDeleteRounded {...IconProps} color='error' /> }
    ];

    if (index < lists.length - 1) values.unshift({ primary: 'Move right', onClick: () => onUpdateListsOrder(item, lists[index + 1]), start: <IconMaterialChevronRightRounded {...IconProps} /> });

    if (index > 0) values.unshift({ primary: 'Move left', onClick: () => onUpdateListsOrder(item, lists[index - 1]), start: <IconMaterialChevronLeftRounded {...IconProps} /> });

    return values.map((item_: any, index: number) => (
      <MenuItem
        key={index}

        {...menuItemProps(item_)}
      />
    ));
  };

  const getList = (list: IList, index: number, unlisted = false) => {
    const queryAmauiSubscription: IQuerySubscription = unlisted ? refs.tasksUnlisted.current?.unlisted : refs.tasks.current[list.id];

    return (
      <Line
        key={list?.id || index}

        gap={1}

        className={classes.listWrapper}
      >
        <Line
          direction='row'

          align='center'

          justify='space-between'

          fullWidth
        >
          {!unlisted ? (
            <SmartTextField
              valueDefault={textToInnerHTML(list.name)}

              onChange={(valueNew: any) => onUpdateListName(list, valueNew)}

              placeholder='List name'

              additional={{
                version: 'l2'
              }}
            />
          ) : (
            <Type
              version='l2'
            >
              Unlisted
            </Type>
          )}

          <Line
            gap={0}

            direction='row'

            align='center'

            className={classNames([
              unlisted && classes.hidden
            ])}
          >
            <Tooltip
              name='Add task'
            >
              <IconButton
                color='inherit'

                onClick={() => onAddListTask(list)}

                size='small'
              >
                <IconMaterialAddRounded />
              </IconButton>
            </Tooltip>

            <Menu
              menuItems={getMenuItems(list, index)}

              ListProps={{
                size: 'small'
              }}
            >
              <IconButton
                color='inherit'

                size='small'
              >
                <IconMaterialMoreVertRounded />
              </IconButton>
            </Menu>
          </Line>
        </Line>

        <Line
          key={index}

          gap={1}

          align='center'

          flex

          fullWidth

          className={classes.list}
        >
          {((queryAmauiSubscription?.value?.response || [])).map((task: any, indexTask: number) => (
            <Line
              key={task.id}

              onClick={() => onOpen(list, task)}

              fullWidth

              className={classNames([
                classes.task,
                task.done && classes.taskDone,
                task.id === selected?.id && classes.taskSelected
              ])}
            >
              {/* Header */}
              <Line
                direction='row'

                align='center'

                justify='space-between'

                fullWidth
              >
                <Line
                  gap={0.5}

                  direction='row'

                  align='center'

                  fullWidth
                >
                  <Checkbox
                    value={task.done}

                    onChange={() => onUpdateListTaskDone(list, task)}

                    onClick={onStopPropagation}

                    size='small'

                    readOnly={loading}
                  />

                  <Type
                    version='l3'

                    className={classes.taskName}

                    dangerouslySetInnerHTML={{
                      __html: textToInnerHTML(task.name)
                    }}
                  />
                </Line>

                <Line
                  gap={0}

                  direction='row'

                  align='center'
                >
                  <Menu
                    menuItems={getMenuItemsTasks(list, task, index)}

                    onClick={onStopPropagation}

                    ListProps={{
                      size: 'small'
                    }}
                  >
                    <IconButton
                      color='inherit'

                      size='small'

                      onClick={onStopPropagation}
                    >
                      <IconMaterialMoreVertRounded />
                    </IconButton>
                  </Menu>
                </Line>
              </Line>

              {/* Main */}
            </Line>
          ))}

          {queryAmauiSubscription?.value?.pagination?.hasNext && (
            <Button
              version='text'

              onClick={() => onLoadMore(queryAmauiSubscription)}

              size='small'
            >
              Load more
            </Button>
          )}
        </Line>
      </Line>
    );
  };

  return <>
    <Helmet>
      <title>Task lists</title>
      <link rel='icon' type='image/svg' sizes='32x32' href={`/assets/svg/logos/${theme.palette.light ? 'light' : 'dark'}/logo-task.svg`} />
      <link rel='icon' type='image/svg' sizes='16x16' href={`/assets/svg/logos/${theme.palette.light ? 'light' : 'dark'}/logo-task.svg`} />
      <meta name='theme-color' content={theme.palette.light ? (theme.palette.color.task as any).main : theme.palette.color.task[20]} />
    </Helmet>

    <Line
      ref={ref}

      gap={0}

      direction='column'

      justify='unset'

      align='unset'

      flex

      fullWidth

      className={classNames([
        className,
        classes.root
      ])}

      {...other}
    >
      <Line
        gap={2}

        justify='unset'

        align='unset'

        flex

        fullWidth
      >
        {/* Header */}
        <Line
          gap={1}

          fullWidth
        >
          <Line
            direction='row'

            align='center'

            justify='flex-end'

            fullWidth
          >
            {usageUI}
          </Line>

          <Line
            direction='row'

            align='center'

            justify='space-between'

            fullWidth
          >
            <Type
              version='t2'
            >
              Lists
            </Type>

            <Tooltip
              name='Add list'
            >
              <IconButton
                onClick={onAddList}
              >
                <IconMaterialAddRounded />
              </IconButton>
            </Tooltip>
          </Line>
        </Line>

        {/* Main */}
        <Line
          justify='unset'

          align='unset'

          flex

          fullWidth
        >
          {loaded && !!lists.length && (
            <Line
              gap={2}

              direction='row'

              flex

              fullWidth

              className={classNames([
                classes.lists
              ])}
            >
              {!!tasksUnlisted?.unlisted?.value?.length && getList(null as any, 'unlisted' as any, true)}

              {lists.map((list: any, index: number) => getList(list, index))}
            </Line>
          )}

          {loaded && !lists.length && (
            <NoResults />
          )}
        </Line>
      </Line>
    </Line>
  </>;
});

export default TasksLists;
