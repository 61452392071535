import React from 'react';

import { ListItem, Select, Type } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';

const useStyle = style(theme => ({
  root: {
    '&.amaui-TextField-root': {
      minWidth: 240
    }
  }
}), { name: 'amaui-app-SelectGridWidth' });

const SelectGridWidth = React.forwardRef((props: any, ref: any) => {
  const {
    name,

    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  const options: any = [];

  items.forEach(item => options.push({ name: `${((item / 12) * 100).toFixed(0)}%`, value: item }));

  const selectProps: any = {
    size: 'small',

    autoWidth: false,

    MenuProps: {
      portal: true
    },

    ListProps: {
      style: {
        maxHeight: 240,
        overflowY: 'auto',
        overflowX: 'hidden'
      }
    }
  };

  return (
    <Select
      ref={ref}

      name={name || 'Width'}

      {...selectProps}

      {...other}

      className={classNames([
        className,
        classes.root
      ])}
    >
      {options.map((item: any, index: number) => (
        <ListItem
          key={index}

          primary={(
            <Type
              version='b3'
            >
              {item.name}
            </Type>
          )}

          value={item.value}

          size='small'

          button
        />
      ))}
    </Select>
  );
});

export default SelectGridWidth;
