import React from 'react';

import { FormPage, Page } from 'ui';
import { PageService, WebsiteService } from 'services';

const Pages = React.forwardRef((props: any, ref: any) => {
  const {
    website,

    className,

    ...other
  } = props;

  const properties = React.useMemo(() => ({
    name: { name: 'Name' },
    description: { name: 'Description' },
    main: { name: 'Main' },
    active: { name: 'Active' },
    archived: { name: 'Archived' },
    'website.name': { name: 'Website' }
  }), []);

  return (
    <Page
      ref={ref}

      name='Pages'

      parent={website}

      service={WebsiteService}

      serviceUpdate={PageService}

      serviceRemove={PageService}

      columns={[
        properties.name,
        properties.main,
        properties.active,
        properties.archived,
        properties['website.name']
      ]}

      properties={properties}

      search={['active', 'archived']}

      options={['active', 'archived', 'main', 'copy']}

      propertes={properties}

      queryItemsName='queryWebsitePages'

      selectedItemsName='selectedWebsitePages'

      addOneName='addPage'

      app='website'

      collection='pages'

      Form={FormPage}

      nested='add'

      updateEntire

      noUpdateProject

      {...other}
    />
  );
});

export default Pages; 
