import React from 'react';

import { innerHTMLToText, is, textToInnerHTML } from '@amaui/utils';
import { IconButton, Label, Line, ListItem, Switch, Tooltip, Type, useForm, useSnackbars } from '@amaui/ui-react';
import { User } from '@amaui/api-utils';

import IconMaterialRemoveRounded from '@amaui/icons-material-rounded-react/IconMaterialRemove';

import { AutoCompleteObjects, Avatar, List, ModalForm, ReadProperties, SelectAColor, SelectRoles, SmartTextField, TextField } from 'ui';
import { AppService, OrganizationService, UserGroupService } from 'services';
import { getErrorMessage } from 'other';

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    object: object_,

    onConfirm
  } = props;

  const snackbars = useSnackbars();

  const [object, setObject] = React.useState(object_);
  const [loading, setLoading] = React.useState<any>(false);

  const form = useForm({
    values: {
      'name': {
        name: 'Name',
        value: object?.name,
        required: true,
        max: 1400,
        messages: {
          min: 'Name has to be min 1 characters',
          max: 'Name can be max 1400 characters'
        }
      },
      'description': {
        name: 'Description',
        value: object?.description,
        max: 4400,
        messages: {
          min: 'Description has to be min 1 characters',
          max: 'Description can be max 4400 characters'
        }
      },
      'users': {
        name: 'Users',
        value: object?.users,
        is: 'array',
        of: 'object'
      },
      'roles': {
        name: 'Roles',
        value: object?.roles,
        is: 'array',
        of: 'string',
        min: 1
      },
      'color': {
        name: 'Color',
        value: object?.color || null,
      },
      'active': {
        name: 'Active',
        value: object?.active,
        is: 'boolean'
      }
    }
  });

  const refs = {
    form: React.useRef(form)
  };

  refs.form.current = form;

  const onClose = React.useCallback(() => {
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });
  }, []);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();
  }, []);

  const onNext = React.useCallback(async (event: SubmitEvent) => {
    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const body = {
      ...refs.form.current.value
    };

    if (is('array', body.users)) body.users = body.users.map((item: any) => ({
      id: item.id,
      name: item.name
    }));

    const result = !object?.id ? await UserGroupService.add(body) : await UserGroupService.update(object?.id, body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `User group ${!object?.id ? 'added' : 'updated'}`
      });

      setObject(result.response.response);

      if (is('function', onConfirm)) onConfirm();

      if (!object?.id) onClose();
    }

    setLoading(false);
  }, [object, form, onConfirm, onClose]);

  const onAddUser = React.useCallback((user: User) => {
    const exists = form.values.users.value?.find((item: any) => item.id === user.id);

    if (!exists) {
      const valueNew = [...(form.values.users.value || [])];

      valueNew.push(user);

      form.onChange('users', valueNew);
    }
  }, [form]);

  const onRemoveUser = React.useCallback((index: number) => {
    const valueNew = [...(form.values.users.value || [])];

    valueNew.splice(index, 1);

    form.onChange('users', valueNew);
  }, [form]);

  const modal: any = {
    read: (
      <Line
        fullWidth
      >
        <ReadProperties
          object={object}

          values={[
            'color',
            'description',
            'users',
            'roles',
            'active'
          ]}
        />
      </Line>
    ),

    write: <>
      <Line
        gap={1}

        fullWidth
      >
        <TextField
          name='Name'

          valueDefault={form.values['name'].value}

          error={form.values['name'].error}

          helperText={form.values['name'].error}

          onChange={(valueNew: any) => form.onChange('name', valueNew, undefined, { rerenderOnUpdate: false })}
        />

        <SmartTextField
          name='Description'

          valueDefault={textToInnerHTML(form.values['description'].value || '')}

          error={form.values['description'].error}

          helperText={form.values['description'].error}

          onChange={(valueNew: any) => form.onChange('description', innerHTMLToText(valueNew), undefined, { rerenderOnUpdate: false })}

          minRows={4}

          multiline

          edit
        />

        <SelectAColor
          valueDefault={form.values['color'].value}

          error={!!form.values['color'].error}

          helperText={form.values['color'].error}

          onChange={(valueNew: any) => form.onChange('color', valueNew, undefined)}
        />
      </Line>

      <AutoCompleteObjects
        name='Users'

        value={form.values.users.value}

        onChange={onAddUser}

        service={OrganizationService}

        method='queryUsersPost'
      />

      {!!form.values.users.value?.length && (
        <List>
          {form.values.users.value?.map((item: any, index: number) => (
            <ListItem
              startAlign='center'

              start={(
                <Avatar
                  tonal

                  color='secondary'

                  size='small'
                >
                  {item?.name?.slice(0, 1)}
                </Avatar>
              )}

              primary={(
                <Type
                  version='b2'
                >
                  {item.name}
                </Type>
              )}

              end={(
                <Tooltip
                  name='Remove'

                  color='secondary'
                >
                  <IconButton
                    onClick={() => onRemoveUser(index)}
                  >
                    <IconMaterialRemoveRounded />
                  </IconButton>
                </Tooltip>
              )}

              size='small'
            />
          ))}
        </List>
      )}

      <SelectRoles
        value={form.values.roles?.value}

        onChange={(valueNew: boolean) => form.onChange('roles', valueNew)}

        error={!!form.values.roles.error}

        helperText={form.values.roles.error}
      />
    </>,

    more: <>
      <Label
        checked={form.values.active?.value}

        onChange={(valueNew: boolean) => form.onChange('active', valueNew)}

        error={!!form.values.active.error}

        helperText={form.values.active.error}
      >
        <Switch />

        Active
      </Label>
    </>
  };

  return (
    <ModalForm
      {...props}

      object={object}

      add={!object}

      {...modal}

      onSubmit={onSubmit}

      onNext={onNext}

      onClose={onClose}

      loading={loading}

      smaller
    />
  );
});

export default Element;
