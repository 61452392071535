import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Form, Line, Type, useConfirm, useSnackbars, useSubscription } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';

import { Button } from 'ui';
import { AuthService, OrganizationService } from 'services';
import { ISignedIn, getErrorMessage } from 'other';

const useStyle = style(theme => ({
  root: {

  },

  remove: {
    padding: 24,
    borderRadius: 24,
    backgroundColor: theme.methods.palette.color.colorToRgb(theme.methods.palette.color.value('error', 50), theme.palette.light ? 4 : 14)
  },

  ...theme.classes(theme)
}), { name: 'amaui-app-route-OrganizationSettingsManage' });

const OrganizationSettingsManage = React.forwardRef((props: any, ref: any) => {
  const {
    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const confirm = useConfirm();
  const snackbars = useSnackbars();
  const navigate = useNavigate();

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const [loading, setLoading] = React.useState<any>(false);

  const onRemove = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();

    setLoading('remove');

    const confirmed = await confirm.open({
      name: 'Removing the organization',
      description: `Are you sure you want to remove the entire organization?`,

      buttons: {
        positive: {
          text: 'Remove'
        },
        negative: {
          text: 'Cancel'
        }
      },

      ButtonPositiveProps: {
        color: 'error'
      }
    });

    if (!confirmed) {
      setLoading(false);

      return;
    }

    const result = await OrganizationService.remove();

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: result.response?.meta?.message || 'Removed'
      });

      // user sign out
      await AuthService.signOut();
    }

    setLoading(false);
  }, []);

  const oneOrganization = signedIn?.organizations?.length <= 1;

  return (
    <Line
      ref={ref}

      gap={4}

      fullWidth

      className={classNames([
        className,
        classes.wrapper
      ])}

      {...other}
    >
      {oneOrganization ? (
        <Form
          name='Only organization'

          description='This is your only organization left, where you are an owner, deleting it is the same as removing an entire account'
        >
          <Button
            onClick={() => navigate('/settings/manage')}

            color='inherit'

            version='outlined'

            size='small'
          >
            Remove account
          </Button>
        </Form>
      ) : (
        <Form
          name='Remove organization'

          description={(
            <Type
              version='b2'

              style={{
                marginTop: 4
              }}
            >
              Removing the organization, we will remove all created content within it, including, tasks, notes, resumes, websites, etc. <br /><br />

              All your organization subscriptions will be removed. <br /><br />

              This action cannot be undone.
            </Type>
          )}

          className={classes.remove}
        >
          <Button
            version='outlined'

            color='error'

            onClick={onRemove}

            size='small'

            disabled={loading}
          >
            Remove organization
          </Button>
        </Form>
      )}
    </Line>
  );
});

export default OrganizationSettingsManage;
