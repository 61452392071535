import { Version } from '@amaui/api-utils';
import AmauiSubscription from '@amaui/subscription';

import { BaseService } from './base';
import { IQuerySubscription, ISelectedSubscription } from 'other';

class VersionService extends BaseService<Version> {
  public queryVersions = new AmauiSubscription<IQuerySubscription>();
  public selectedVersions = new AmauiSubscription<ISelectedSubscription>();

  public constructor() {
    super('versions');

    this.queryObjectsInit(this.queryVersions);

    this.selectedObjectsInit(this.selectedVersions);
  }

}

export default new VersionService();
