import React from 'react';

import { download, getCountry, innerHTMLToText, is, isValid, stringToColor, textToInnerHTML } from '@amaui/utils';
import { Form, FormRow, IconButton, Label, Line, Link, ListItem, PieChart, Switch, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Type, useForm, useSnackbars } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';
import { ValidationError } from '@amaui/errors';
import { AmauiDate, format } from '@amaui/date';
import { Analytic } from '@amaui/api-utils';

import IconMaterialIosShareRounded from '@amaui/icons-material-rounded-react/IconMaterialIosShare';
import IconMaterialRefreshRounded from '@amaui/icons-material-rounded-react/IconMaterialRefresh';

import { AnalyticValue, Analytics, ModalForm, Select, SmartTextField, TextField, useSubscription } from 'ui';
import { AnalyticService, AppService, UrlShortenerService } from 'services';
import { IQuerySubscription, formats, getErrorMessage } from 'other';
import config from 'config';

const useStyle = style(theme => ({
  root: {

  },

  surface: {
    '&.amaui-Surface-root': {
      background: theme.palette.color.primary[theme.palette.light ? 99 : 5]
    }
  },

  chart: {
    width: '100%'
  },

  ...theme.classes(theme)
}), { name: 'amaui-app-UrlShortener' });

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    object: object_,

    onConfirm
  } = props;

  const { classes } = useStyle();

  const snackbars = useSnackbars();
  const queryUrlShortenerAnalytics = useSubscription<IQuerySubscription>(AnalyticService.queryUrlShortenerAnalytics);

  const [object, setObject] = React.useState(object_);
  const [loading, setLoading] = React.useState<any>(false);
  const [analyticsPer, setAnalyticsPer] = React.useState<any>('');

  const form = useForm({
    values: {
      'name': {
        name: 'Name',
        value: object?.name,
        required: true,
        max: 1400,
        messages: {
          min: 'Name has to be min 1 characters',
          max: 'Name can be max 1400 characters'
        }
      },
      'description': {
        name: 'Description',
        value: object?.description,
        max: 4400,
        messages: {
          min: 'Description has to be min 1 characters',
          max: 'Description can be max 4400 characters'
        }
      },
      'url': {
        name: 'URL',
        value: object?.url,
        required: true,
        method: [
          (value_: string) => {
            const response = isValid('url-path', value_?.startsWith('/') ? value_ : `/${value_}`);

            if (!response) throw new ValidationError(`URL has to be a valid url path`);
          }
        ]
      },
      'to.url': {
        name: 'To URL',
        value: object?.to?.url,
        required: true,
        isValid: 'url'
      },
      'active': {
        name: 'Active',
        value: object?.active,
        is: 'boolean'
      },
      'archived': {
        name: 'Archived',
        value: object?.archived,
        is: 'boolean'
      }
    }
  });

  const refs = {
    form: React.useRef(form),
    queryUrlShortenerAnalytics: React.useRef(queryUrlShortenerAnalytics)
  };

  refs.form.current = form;

  refs.queryUrlShortenerAnalytics.current = queryUrlShortenerAnalytics;

  const onClose = React.useCallback(() => {
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });
  }, []);

  const dates = React.useMemo(() => {
    const today = AmauiDate.utc;
    const myToday = new AmauiDate();

    return [
      { name: 'Total', value: '' },
      { name: format(myToday, `'Hour' HH'h'`), value: format(today, 'YYYY-MM-DD HH') },
      { name: 'Today', value: format(today, 'YYYY-MM-DD') },
      { name: 'Month', nameMore: format(today, 'MMM, YYYY'), value: format(today, 'YYYY-MM') },
      { name: 'Year', nameMore: format(today, 'YYYY'), value: format(today, 'YYYY') }
    ];
  }, []);

  const init = React.useCallback(async () => {
    if (object) {
      const result = await AnalyticService.queryUrlShortenerAnalytics.value!.query({
        query: {
          query: {
            user: null,
            version: [`urlShorteners-${object?.id}-visit`, `urlShorteners-${object?.id}-visit-location`],
            date: dates.map(item => item.value)
          }
        }
      });

      if (result.status >= 400) {
        snackbars.add({
          color: 'error',
          primary: getErrorMessage(result)
        });
      }
    }
  }, [object, dates]);

  React.useEffect(() => {
    // init
    init();
  }, []);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();
  }, []);

  const onRefresh = React.useCallback(async () => {
    setLoading('refresh');

    await AnalyticService.queryUrlShortenerAnalytics.value!.refetch();

    setLoading(false);
  }, []);

  const onExport = React.useCallback(async () => {
    const value = queryUrlShortenerAnalytics?.response;

    let values = is('array', value) ? value : [value];

    const properties = ['value', 'date', 'version', 'added_at', 'updated_at'];

    values = values.map((item: any) => {
      const object: any = {};

      properties.forEach((property: any) => {
        if (item[property]) object[property] = item[property];
      });

      return object;
    });

    const valueExport = {
      version: 'export',
      objects: 'url-shortener-analytics',
      value: values
    };

    download(`Form responses ${format(AmauiDate.amauiDate, formats.entire)}`, JSON.stringify(valueExport, null, 2), 'application/json');
  }, []);

  const onNext = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();

    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const body = {
      ...refs.form.current.value
    };

    const result = !object?.id ? await UrlShortenerService.add(body) : await UrlShortenerService.update(object?.id, body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Url shortener ${!object?.id ? 'added' : 'updated'}`
      });

      setObject(result.response.response);

      if (is('function', onConfirm)) onConfirm();

      if (!object?.id) onClose();
    }

    setLoading(false);
  }, [object, form, onConfirm, onClose]);

  const onChangeAnalyticsPer = React.useCallback((value: any) => {
    setAnalyticsPer(value);
  }, []);

  const getAnalytic = (version: string, date: string): any => {
    return (queryUrlShortenerAnalytics?.response || []).find((item: Analytic) => item.version === version && item.date === date);
  };

  const getPieChart = () => {
    const analytic = getAnalytic(`urlShorteners-${object?.id}-visit-location`, analyticsPer);

    if (analytic) {
      const countries = Object.keys(analytic.value);

      return (
        <PieChart
          title='Clicks per country'

          subtitle='In %'

          values={countries.map(item => ({
            color: stringToColor(getCountry(item)?.name) as any,
            name: getCountry(item)?.name,
            values: [analytic.value[item]]
          }))}

          className={classNames([
            classes.chart,
            classes.pieChart,
            countries.length === 1 && classes.pieChart100
          ])}
        />
      );
    }

    return null;
  };

  const getCountryTable = () => {
    const analytic = getAnalytic(`urlShorteners-${object?.id}-visit-location`, analyticsPer);

    if (analytic) {
      const countries = Object.keys(analytic.value);

      return (
        <Table
          size='small'
        >
          <TableHead>
            <TableRow>
              {['Name', 'Visits'].map((item: string, index: number) => (
                <TableCell
                  key={index}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {countries.map((item: any, index: number) => (
              <TableRow
                key={index}
              >
                <TableCell
                  key={index}
                >
                  {getCountry(item)?.name}
                </TableCell>

                <TableCell
                  key={index}
                >
                  {analytic.value[item]}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      );
    }

    return null;
  };

  const analyticSelected = getAnalytic(`urlShorteners-${object?.id}-visit-location`, analyticsPer);

  const modal: any = {
    read: <>
      <FormRow
        name='URL'
      >
        <Link
          href={`${config.value.apps.public.url}/url/${form.value.url}`}

          target='blank'
        >
          {config.value.apps.public.url}/url/{form.value.url}
        </Link>
      </FormRow>

      <Line
        fullWidth
      >
        <FormRow
          gap={1}

          name='Clicks'

          fullWidth

          end={(
            <Line
              gap={0}

              direction='row'

              align='center'

              justify='flex-end'

              fullWidth
            >
              <Tooltip
                name='Export'
              >
                <IconButton
                  onClick={onExport}

                  size='small'

                  disabled={loading}
                >
                  <IconMaterialIosShareRounded />
                </IconButton>
              </Tooltip>

              <Tooltip
                name='Refresh'
              >
                <IconButton
                  onClick={onRefresh}

                  size='small'

                  disabled={loading}
                >
                  <IconMaterialRefreshRounded />
                </IconButton>
              </Tooltip>
            </Line>
          )}
        >
          <Analytics
            align='flex-end'
          >
            {dates.map((item, index: number) => (
              <AnalyticValue
                key={index}

                name={`${item.name} ${item.nameMore || ''}`.trim()}

                value={getAnalytic(`urlShorteners-${object?.id}-visit`, item.value)?.value || 0}
              />
            ))}
          </Analytics>
        </FormRow>

        <FormRow
          gap={1}

          name='Analytics'

          fullWidth

          style={{
            overflowX: 'hidden'
          }}
        >
          <Line
            align='flex-end'

            fullWidth
          >
            <Select
              valueDefault=''

              value={analyticsPer}

              onChange={onChangeAnalyticsPer}

              MenuProps={{
                AppendProps: {
                  switch: false
                }
              }}
            >
              {dates.map((item, index: number) => (
                <ListItem
                  key={index}

                  primary={(
                    <Type
                      version='b3'
                    >
                      {`${item.name} ${item.nameMore || ''}`.trim()}
                    </Type>
                  )}

                  value={item.value}

                  button
                />
              ))}
            </Select>
          </Line>

          <Analytics
            gap={2}

            align='center'

            direction='column'

            style={{
              padding: '16px 8px 8px'
            }}
          >
            {!analyticSelected && (
              <Line
                align='center'

                justify='center'

                style={{
                  height: 140
                }}
              >
                <Type
                  align='center'
                >
                  No visits recorded at the moment
                </Type>
              </Line>
            )}

            {getPieChart()}

            {getCountryTable()}
          </Analytics>
        </FormRow>
      </Line>
    </>,

    write: <>
      <Line
        gap={2}

        fullWidth
      >
        <TextField
          name='Name'

          valueDefault={form.values['name'].value}

          error={form.values['name'].error}

          helperText={form.values['name'].error}

          onChange={(valueNew: any) => form.onChange('name', valueNew, undefined, { rerenderOnUpdate: false })}
        />

        <SmartTextField
          name='Description'

          valueDefault={textToInnerHTML(form.values['description'].value)}

          error={form.values['description'].error}

          helperText={form.values['description'].error}

          onChange={(valueNew: any) => form.onChange('description', innerHTMLToText(valueNew), undefined, { rerenderOnUpdate: false })}

          minRows={4}

          multiline

          edit
        />

        <TextField
          name='URL'

          placeholder='i-love-trees'

          valueDefault={form.values.url?.value || ''}

          onChange={(valueNew: string) => form.onChange('url', valueNew, undefined, { rerenderOnUpdate: false })}

          error={!!form.values.url.error}

          helperText={form.values.url.error}

          fullWidth
        />

        <TextField
          name='To URL'

          placeholder='https://example.com/shop/my-product'

          valueDefault={form.values['to.url']?.value || ''}

          onChange={(valueNew: string) => form.onChange('to.url', valueNew, undefined, { rerenderOnUpdate: false })}

          error={!!form.values['to.url'].error}

          helperText={form.values['to.url'].error}

          type='url'

          fullWidth
        />
      </Line>
    </>,

    more: <>
      <Line
        gap={2}

        fullWidth
      >
        <Form
          gap={2}
        >
          <Label
            checked={form.values.active?.value}

            onChange={(valueNew: boolean) => form.onChange('active', valueNew)}

            error={!!form.values.active.error}

            helperText={form.values.active.error}
          >
            <Switch />

            Active
          </Label>

          <Label
            checked={form.values.archived?.value}

            onChange={(valueNew: boolean) => form.onChange('archived', valueNew)}

            error={!!form.values.archived.error}

            helperText={form.values.archived.error}
          >
            <Switch />

            Archived
          </Label>
        </Form>
      </Line>
    </>
  };

  return (
    <ModalForm
      {...props}

      object={object}

      add={!object}

      {...modal}

      onSubmit={onSubmit}

      onNext={onNext}

      onClose={onClose}

      loading={loading === true}

      smaller
    />
  );
});

export default Element;
