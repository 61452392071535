import React from 'react';

import { stringToColor } from '@amaui/utils';
import { Avatar, Line, List, Tooltip } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';
import { IMessage, User } from '@amaui/api-utils';

import { useSubscription } from 'ui';
import { ISignedIn } from 'other';
import { AuthService } from 'services';
import Message from './Message';

const useStyle = style(theme => ({
  root: {
    height: 0,
    overflow: 'hidden auto',
    padding: '0 12px',
    transition: theme.methods.transitions.make('opacity', { duration: 'xxs' }),

    '& > ul': {
      gap: 2,
      flex: '1 1 auto',
      justifyContent: 'flex-end'
    }
  },

  simple: {
    padding: 0,
    height: 'auto',
    flex: '0 0 auto'
  },

  messageGroup: {
    '& .amaui-app-Message-text': {
      borderRadius: '8px 24px 24px 8px'
    },

    '& > div:first-child': {
      '& .amaui-app-Message-text': {
        '&.amaui-Type-root': {
          borderRadius: '24px 24px 24px 8px'
        }
      }
    },

    '& > div:last-child': {
      '& .amaui-app-Message-text': {
        '&.amaui-Type-root': {
          borderRadius: '8px 24px 24px 24px'
        }
      }
    },

    '& > div:only-child': {
      '& .amaui-app-Message-text': {
        '&.amaui-Type-root': {
          borderRadius: '24px 24px 24px 24px'
        }
      }
    },

    '& .amaui-app-Message-text-with-reply-to': {
      '&.amaui-Type-root': {
        borderRadius: '24px !important'
      }
    }
  },

  messageGroup_my: {
    '& .amaui-app-Message-text': {
      borderRadius: '24px 8px 8px 24px'
    },

    '& > div:first-child': {
      '& .amaui-app-Message-text': {
        '&.amaui-Type-root': {
          borderRadius: '24px 24px 8px 24px'
        }
      }
    },

    '& > div:last-child': {
      '& .amaui-app-Message-text': {
        '&.amaui-Type-root': {
          borderRadius: '24px 8px 24px 24px'
        }
      }
    },

    '& > div:only-child': {
      '& .amaui-app-Message-text': {
        '&.amaui-Type-root': {
          borderRadius: '24px 24px 24px 24px'
        }
      }
    },

    '& .amaui-app-Message-text-with-reply-to': {
      '&.amaui-Type-root': {
        borderRadius: '24px !important'
      }
    }
  }
}), { name: 'amaui-app-element-Messages' });

const Messages = React.forwardRef((props: any, ref: any) => {
  const {
    messages,

    simple,

    start,

    end,

    noAvatar,

    MessageProps,

    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const refs = {
    signedIn: React.useRef(signedIn)
  };

  refs.signedIn.current = signedIn;

  const messageGroups: any[] = React.useMemo(() => {
    const groups: any = [];
    let previousUserID: string;
    let index = -1;

    messages.forEach((message: IMessage) => {
      const messageUser = message.user;

      if (previousUserID !== messageUser?.id) {
        groups.push({
          user: messageUser,
          messages: []
        });

        previousUserID = messageUser?.id;
        index++;
      }

      groups[index].messages.push(message);
    });

    return groups;
  }, [messages]);

  const images = messages.filter((item: IMessage) => item.value![0].version === 'image').map((item: IMessage) => item.value![0].props);

  const getMessageGroup = (value: { user: User, messages: IMessage[] }, index: number) => {
    if (!value) return null;

    const user = value.user;

    const my = user?.id === refs.signedIn.current?.user?.id;

    const name = user?.name || 'User';

    const messages_ = value.messages;

    return (
      <Line
        key={index}

        gap={1}

        direction='row'

        align='center'

        justify={!my ? 'flex-start' : 'flex-end'}

        fullWidth

        Component='li'
      >
        {!my && !noAvatar && (
          <Tooltip
            name={name}
          >
            <Avatar
              color={stringToColor(name)}

              size='small'

              style={{
                alignSelf: 'flex-end',
                flex: '0 0 auto'
              }}
            >
              {name?.slice(0, 2)}
            </Avatar>
          </Tooltip>
        )}

        <Line
          gap={0.25}

          fullWidth

          className={classNames([
            classes[my ? 'messageGroup_my' : 'messageGroup']
          ])}
        >
          {messages_.map((item, index) => (
            <Message
              key={index}

              value={item}

              images={images}

              index={index}

              {...MessageProps}
            />
          ))}
        </Line>
      </Line>
    );
  };

  return (
    <Line
      ref={ref}

      gap={0}

      flex

      fullWidth

      className={classNames([
        className,
        classes.root,
        simple && classes.simple
      ])}

      {...other}
    >
      {start}

      {!!messages?.length && (
        <List
          noBackground
        >
          {messageGroups.map((item, index) => getMessageGroup(item, index))}
        </List>
      )}

      {end}
    </Line>
  );
});

export default Messages;