import React from 'react';

import { capitalize, getID, setObjectValue, textToInnerHTML } from '@amaui/utils';
import { Form, FormRow, IconButton, Line, ListItem, Tooltip, Type } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';
import { IMedia } from '@amaui/api-utils';

import IconMaterialAddRounded from '@amaui/icons-material-rounded-react/IconMaterialAdd';
import IconMaterialRemoveRounded from '@amaui/icons-material-rounded-react/IconMaterialRemove';

import { Select, SelectMedia, SmartTextField, SwitchInput, TextField } from 'ui';

const useStyle = style(theme => ({
  root: {

  },

  main: {
    overflowY: 'hidden',
    overflowX: 'auto',
    padding: 4
  },

  formRow: {
    '& > * > *': {
      flex: '0 0 auto'
    }
  }
}), { name: 'amaui-app-OptionsLinks' });

const OptionsLinks = React.forwardRef((props: any, ref: any) => {
  const {
    value,

    element,

    index,

    onUpdate,

    className,

    children,

    ...other
  } = props;

  const { classes } = useStyle();

  const refs = {
    value: React.useRef(value),
    index: React.useRef(index),
    onUpdate: React.useRef(onUpdate),
    element: React.useRef(element)
  };

  refs.value.current = value;

  refs.index.current = index;

  refs.onUpdate.current = onUpdate;

  refs.element.current = element;

  const update = React.useCallback((property: string, valueProperty: any) => {
    const valueNew = [...(refs.value.current || [])];

    if (refs.element.current) {
      setObjectValue(refs.element.current, property, valueProperty);

      refs.onUpdate.current(valueNew);
    }
  }, []);

  const onConfirmMedia = React.useCallback((valueNew: IMedia, property = 'profile') => {
    const media = valueNew;

    let mediaObject: any;

    if (media) {
      mediaObject = media;
    }

    update(`props.${property}`, mediaObject || null);
  }, []);

  const onAddLink = React.useCallback((event: MouseEvent) => {
    event.stopPropagation();

    const links = refs.element.current?.props?.links || [];

    links.push({
      id: getID(),
      name: 'Link',
      url: '',
      version: 'default',
      sensitivity: 'none',
      share: true
    });

    update('props.links', links);
  }, []);

  const onUpdateLink = React.useCallback((property: string, valueNew: any, index: number) => {
    const links = refs.element.current?.props?.links || [];

    if (index > -1) links[index][property] = valueNew;

    update('props.links', links);
  }, []);

  const onRemoveLink = React.useCallback((index: number) => {
    const links = refs.element.current?.props?.links || [];

    if (index > -1) links.splice(index, 1);

    update('props.links', links);
  }, []);

  const selectProps: any = {
    size: 'small',

    autoWidth: false,

    MenuProps: {
      portal: true,
      alignment: 'center'
    },

    ListProps: {
      style: {
        maxHeight: 240,
        overflowY: 'auto',
        overflowX: 'hidden'
      }
    }
  };

  return (
    <Line
      fullWidth

      className={classNames([
        className,
        classes.root
      ])}

      {...other}
    >
      <FormRow
        fullWidth

        style={{
          marginBottom: 24
        }}
      >
        <SmartTextField
          placeholder='Name'

          valueDefault={textToInnerHTML(element?.props?.name) || ''}

          onChange={(valueNew: any) => update('props.name', valueNew)}

          additional={{
            version: 'h3'
          }}
        />

        <SmartTextField
          placeholder='Summary'

          valueDefault={textToInnerHTML(element?.props?.short_description) || ''}

          onChange={(valueNew: any) => update('props.short_description', valueNew)}

          minRows={3}

          multiline

          edit
        />
      </FormRow>

      <FormRow
        size='small'

        wrapper
      >
        <SelectMedia
          name='Profile'

          value={element?.props?.media}

          mediaSelected={element?.props?.profile}

          mime='image'

          onChange={(valueNew: any) => onConfirmMedia(valueNew, 'profile')}
        />

        <SelectMedia
          name='Cover'

          value={element?.props?.media}

          mediaSelected={element?.props?.cover}

          mime='image'

          onChange={(valueNew: any) => onConfirmMedia(valueNew, 'cover')}
        />

        <SwitchInput
          name='Page share'

          checkedDefault={element?.props?.share !== undefined ? element?.props?.share : true}

          onChange={(valueNew: any) => update('props.share', valueNew)}
        />
      </FormRow>

      <Form
        name='Links'

        openDefault

        accordion

        wrapper

        end={(
          <Tooltip
            name='Add link'
          >
            <IconButton
              onClick={onAddLink}
            >
              <IconMaterialAddRounded />
            </IconButton>
          </Tooltip>
        )}
      >
        {(element?.props?.links || []).map((item: any, index: number) => (
          <FormRow
            key={item.id}

            gap={1}

            name={item.name || `Link ${index + 1}`}

            fullWidth

            row

            size='small'

            MainProps={{
              direction: 'row'
            }}

            HeaderProps={{
              align: 'center'
            }}

            end={(
              <Tooltip
                name='Remove link'
              >
                <IconButton
                  onClick={() => onRemoveLink(index)}

                  size='small'
                >
                  <IconMaterialRemoveRounded />
                </IconButton>
              </Tooltip>
            )}
          >
            <TextField
              name='Name'

              valueDefault={item?.name || ''}

              onChange={(valueNew: string) => onUpdateLink('name', valueNew, index)}
            />

            <TextField
              name='URL'

              valueDefault={item?.url || ''}

              onChange={(valueNew: string) => onUpdateLink('url', valueNew, index)}

              type='url'
            />

            <Select
              name='Version'

              valueDefault={item?.version !== undefined ? item.version : 'default'}

              onChange={(valueNew: string) => onUpdateLink('version', valueNew, index)}

              {...selectProps}
            >
              {['primary', 'secondary', 'default'].map((item_: any, index_: number) => (
                <ListItem
                  key={index_}

                  primary={(
                    <Type
                      version='b3'
                    >
                      {capitalize(item_)}
                    </Type>
                  )}

                  value={item_}

                  button
                />
              ))}
            </Select>

            <Select
              name='Sensitivity'

              valueDefault={item?.sensitivity !== undefined ? item?.sensitivity : 'none'}

              onChange={(valueNew: string) => onUpdateLink('sensitivity', valueNew, index)}

              {...selectProps}
            >
              {['none', 'sensitive 18+'].map((item_: any, index_: number) => (
                <ListItem
                  key={index_}

                  primary={(
                    <Type
                      version='b3'
                    >
                      {capitalize(item_)}
                    </Type>
                  )}

                  value={item_}

                  button
                />
              ))}
            </Select>

            <SwitchInput
              name='Link share'

              checked={item?.share !== undefined ? item?.share : true}

              onChange={(valueNew: any) => onUpdateLink('share', valueNew, index)}

              style={{
                flex: '0 0 auto'
              }}
            />
          </FormRow>
        ))}
      </Form>
    </Line>
  );
});

export default OptionsLinks;
