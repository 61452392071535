import React from 'react';

import { debounce, is, textToInnerHTML } from '@amaui/utils';
import { AutoComplete, Avatar, ListItem, Type, useSnackbars } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';
import { IAutoComplete } from '@amaui/ui-react/AutoComplete/AutoComplete';

import { getErrorMessage } from 'other';

const useStyle = style(theme => ({
  root: {

  }
}), { name: 'amaui-app-AutoCompleteObjects' });


export interface IAutoCompleteObjects extends IAutoComplete {
  value?: any;

  onChange: (objects?: any) => any;

  service: any;

  method?: string;

  query?: any;

  avatar?: boolean;
}

const Element: React.FC<IAutoCompleteObjects> = React.forwardRef((props, ref: any) => {
  const {
    value: value_,

    onChange: onChange_,

    onChangeInput: onChangeInput_,

    service,

    method = 'queryPost',

    query,

    avatar = true,

    className,

    ...other
  } = props;

  const { classes } = useStyle(props);

  const snackbars = useSnackbars();

  const [objects, setObjects] = React.useState<any>([]);
  const [, setLoading] = React.useState(false);

  const refs = {
    objects: React.useRef(objects),
    method: React.useRef(method),
    query: React.useRef(query)
  };

  refs.objects.current = objects;

  refs.method.current = method;

  refs.query.current = query;

  const queryObjects = React.useMemo(() => {
    return debounce(async (valueNew: string) => {
      setLoading(true);

      const methodService = service[refs.method.current].bind(service);

      const response = await methodService({
        query: {
          text: valueNew,

          ...refs.query.current
        }
      });

      if (response.status >= 400) {
        snackbars.add({
          color: 'error',
          primary: getErrorMessage(response)
        });
      }
      else {
        setObjects(response.response.response?.map((item: any) => ({ ...item, version: undefined, value: item.id })));
      }

      setLoading(false);
    }, 140);
  }, [service]);

  const init = React.useCallback(() => {
    // initial  
    queryObjects('');
  }, []);

  React.useEffect(() => {
    // init 
    init();
  }, []);

  const onChange = React.useCallback((valueNew: any) => {
    if (is('function', onChange_)) onChange_(valueNew);
  }, [onChange_]);

  const onChangeInput = React.useCallback((valueNew: any) => {
    // query 
    queryObjects(valueNew);

    if (is('function', onChangeInput_)) onChangeInput_!(valueNew);
  }, [onChangeInput_]);

  const autoCompleteProps: any = {
    size: 'small',

    autoWidth: false,

    MenuProps: {
      portal: true
    },

    ListProps: {
      style: {
        maxHeight: 240,
        overflowY: 'auto',
        overflowX: 'hidden'
      }
    }
  };

  const value = (is('array', value_) ? value_ : [value_]).filter(Boolean);

  return (
    <AutoComplete
      ref={ref}

      value={value || null}

      valueInputDefault={!other.multiple && !is('array', value_) && value_ ? (value_.name || value_) : undefined}

      onChange={onChange}

      onChangeInput={onChangeInput}

      renderOption={(item: any, index: number, props: any) => (
        <ListItem
          key={index}

          {...props}

          selected={!!value?.find((item_: any) => item_?.value !== undefined ? item_.value === item.value : (item_.id || item_._id) === (item.id || item._id))}

          startAlign='center'

          start={avatar && (
            <Avatar
              tonal

              color='secondary'

              size='small'
            >
              {item?.name?.slice(0, 1)}
            </Avatar>
          )}

          primary={(
            <Type
              version='b2'

              dangerouslySetInnerHTML={{
                __html: textToInnerHTML(item?.name || '')
              }}
            />
          )}
        />
      )}

      options={objects}

      {...autoCompleteProps}

      className={classNames([
        classes.root,
        className
      ])}

      {...other}
    />
  );
});

Element.displayName = 'amaui-app-AutoCompleteObjects';

export default Element;
